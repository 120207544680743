import axios from 'axios';
import { toast } from "react-toastify";
export const GetDealBoughtData = (data) => {

    return ({
        type: "get_dealboughtdata",
        payload: data,
        loader: false
    })
}
export function getdealboughtpopulatetable() {
    return (dispatch) => {
        // let pgurl = `${window.$url}deals/Bought/GetAll?ProjectId=${localStorage.getItem("ProjectId")}`;
        // if (localStorage.getItem("ProjectId") === null) {
        //     pgurl = `${window.$url}deals/Bought/GetAll`
        // }
        axios({
            url: `${window.$url}deals/Bought/GetAll`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetDealBoughtData(result.data.Data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}

import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getallrecentlyadded, getheadingdata, GetRecentlyAdded } from "../../../actions/getheading";
import AddAccountModal from "./AddAccountModal";

const Account = () => {
    const resultsheading = useSelector(state => state.getHeadingReducer);
    const resultsallaccount = useSelector(state => state.getRecentlyAccRed);



    const dispatch = useDispatch();
    // state for select head category
    const [stateheadcat, setheadcat] = useState();
    const [stateheadname, setheadname] = useState();
    const [editheadingcat, seteditheadingcat] = useState();
    const [editbalancetype, seteditbalancetype] = useState();

    const [accountId, setaccountid] = useState(0);
    // state for select head for modal
    const [stateheadcatmodal, setheadcatmodal] = useState();
    const [istrue, setistrue] = useState(false);
    // state for addaccount api data 
    const [state, setState] = useState({
        AccountId: accountId,
        AccountTitle: null,
        AccountDescription: null,
        OpeningBalance: null,
        Heading: 0,
        ProjectId: 0

    });
    // state for addheading api data 
    const [stateheading,] = useState({
        HeadingTitle: null,
        ParentId: 0,
        HeadingDescription: null,
        ProjectId: 0

    });

    const [balancetype, setbalancetype] = useState("None");
    function modalclose() {
        document.getElementById("headingModal").style.display = "none";
    };
    //Table Data fiter

    var suggestionlist = [];

    const onTextChange = (e) => {
        const value = e.target.value;
        if (value === "") {

            dispatch(getallrecentlyadded());
        } else {
            let suggestions = [];
            if (value.length > 0) {
                const regex = new RegExp(`${value}`, "i");

                suggestions = resultsallaccount.data
                    .sort()
                    .filter((val) =>
                        (regex.test(val.AccountTitle)));

            }
            suggestionlist = suggestions;
            dispatch(GetRecentlyAdded(suggestionlist));
        }
    };
    useEffect(() => {
        dispatch(getheadingdata())
        dispatch(getallrecentlyadded())

    }, [dispatch]);
    // api for adding account start
    function addAccount() {

        if (document.getElementById(
            "balancetype"
        ).options[
            document.getElementById("balancetype").selectedIndex
        ].value == "" || document.getElementById("AccountName").value === "" || document.getElementById("AccountCategory").options[document.getElementById("AccountCategory").selectedIndex].value === "Select" || document.getElementById("AccountCategory").options[document.getElementById("AccountCategory").selectedIndex].value === "Add New") {
            toast.info("Heading, Balance type and Account Name are mandatory")
        }
        else {
            var AccountDetail = state;

            AccountDetail.AccountTitle = document.getElementById("AccountName").value;

            AccountDetail.Heading = parseInt(document.getElementById(
                "AccountCategory"
            ).options[
                document.getElementById("AccountCategory").selectedIndex
            ].dataset.id);
            AccountDetail.AccountDescription = document.getElementById("accountDescription").value;
            AccountDetail.ProjectId = parseInt(`${localStorage.getItem("ProjectId")}`);
            AccountDetail.OpeningBalance = parseInt(document.getElementById("openingBalance").value.replace(/,/g, ''));

            document.getElementById("accountsave").disabled = true;
            axios({
                url: `${window.$url}Accounts/Add?OpeningBalanceType=${balancetype}`,
                method: "Post",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "content-type": "application/json",
                },
                data: JSON.stringify(

                    AccountDetail

                ),
            }).then(
                (result) => {
                    document.getElementById("accountsave").disabled = false;
                    toast.info(result.data.Message);
                    if (result.data.IsSuccess === true) {
                        dispatch(getallrecentlyadded())
                        document.getElementById("AccountCategory").value = "Select";
                        document.getElementById("balancetype").value = "Select";

                        document.getElementById("accounttype").selectedIndex = 0;
                        document.getElementById("accountDescription").value = ""
                        document.getElementById("openingBalance").value = ""
                        document.getElementById("AccountName").value = "";
                        document.getElementById("accounttype").disabled = false;
                        document.getElementById("AccountCategory").disabled = false;
                        setState({
                            AccountId: 0,
                            AccountTitle: null,
                            AccountDescription: null,
                            OpeningBalance: null,

                            Heading: 0,
                            ProjectId: 0

                        })
                        // if (accountId !== 0)
                        //     document.getElementById(
                        //         `${accountId}`
                        //     ).checked = false;
                        setheadname();
                        setheadcat();
                        setaccountid(0)
                        seteditheadingcat();
                        setistrue(false)
                        seteditbalancetype();

                        document.getElementById("accounttype").disabled = false;
                        document.getElementById("AccountCategory").disabled = false;


                    }




                },
                (error) => {

                    toast.info("Something went wrong!");
                    document.getElementById("accountsave").disabled = false;
                }
            );



        }
    }
    // api for adding account end
    // api for adding headdings
    function addheading() {

        if (document.getElementById("HeadingTitle").value === "" || document.getElementById("subheadingcategory").options[document.getElementById("subheadingcategory").selectedIndex].value === "Select" || document.getElementById("subheadingcategory").options[document.getElementById("subheadingcategory").selectedIndex].value === "Add New") {
            toast.info("Heading Title and Sub Heading are mandatory")
        } else {
            var HeadingDetail = stateheading;

            HeadingDetail.HeadingTitle = document.getElementById("HeadingTitle").value?.toUpperCase();

            HeadingDetail.ParentId = parseInt(document.getElementById(
                "subheadingcategory"
            ).options[
                document.getElementById("subheadingcategory").selectedIndex
            ].dataset.id);
            HeadingDetail.HeadingDescription = document.getElementById("headingDescription").value?.toUpperCase();
            HeadingDetail.ProjectId = parseInt(`${localStorage.getItem("ProjectId")}`)

            document.getElementById("headingsave").disabled = true;
            axios({
                url: `${window.$url}Accounts/Heading/Add`,
                method: "Post",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "content-type": "application/json",
                },
                data: JSON.stringify(

                    HeadingDetail

                ),
            }).then(
                (result) => {
                    document.getElementById("headingsave").disabled = false;
                    if (result.data.IsSuccess === true) {
                        document.getElementById("subheadingcategory").selectedIndex = 0;
                        document.getElementById("accountheadingtype").selectedIndex = 0;
                        document.getElementById("headingDescription").value = ""
                        document.getElementById("HeadingTitle").value = "";
                        dispatch(getheadingdata())
                        toast.info(result.data.Message);
                        modalclose();
                    } else {
                        toast.info(result.data.Message);
                    }




                },
                (error) => {

                    toast.info("Something went wrong!");
                    document.getElementById("headingsave").disabled = false;

                }
            );


        }
    }
    function deleteaccount(accid) {
        axios({
            url: `${window.$url}Accounts/DeleteUserAdded?AccountId=${accid}`,
            method: "delete",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },

        }).then(
            (result) => {

                if (result.data.IsSuccess === true) {

                    toast.info(result.data.Message);
                    dispatch(getallrecentlyadded())


                } else {
                    toast.info(result.data.Message);
                }




            },
            (error) => {

                toast.info("Something went wrong!");

            })
    }
    return (
        <>

            <div className="row  ">
                <div className="dash_background col-12 ">
                    <div className="row ">
                        <div className="col-md-12  text-left page_heading">
                            <h4 className="m-0">Add Account</h4>
                            <AddAccountModal/>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-2 mt-4  text-left p-0">
                            <label className="input_label m-0 " >Account Type:</label>
                        </div>
                        <div className="col-md-3 mt-4  p-0">
                            <select className="input_styling ml-md-3" id="accounttype" onChange={() => setheadcat(document.getElementById("accounttype").options[document.getElementById("accounttype").selectedIndex].value)}>
                                {editheadingcat !== undefined ? <option selected >{editheadingcat}</option> :
                                    <option selected>Select</option>}
                                <option>AS</option>
                                <option>LI</option>
                                <option>EQ</option>
                                <option>IN</option>
                                <option>EX</option>
                            </select>

                        </div>
                        <div className="col-md-2 mt-4   p-0">
                        </div>
                        <div className="col-md-2 mt-4  text-left p-0">
                            <label className="input_label m-0">Account Name:</label>
                        </div>
                        <div className="col-md-3 mt-4   p-0">

                            <input type="text" className="input_styling ml-md-3 " disabled={istrue} id="AccountName" key={state.AccountTitle} defaultValue={state.AccountTitle} />

                        </div>

                    </div>
                    <div className="row ">
                        <div className="col-md-2 mt-4  text-left p-0">
                            <label className="input_label m-0 " >Heading:</label>
                        </div>
                        <div className="col-md-3 mt-4  p-0">
                            <select className="input_styling ml-md-3" id="AccountCategory" onChange={(e) => { e.target.value === "Add New" ? document.getElementById("headingModal").style.display = "block" : document.getElementById("headingModal").style.display = "none"; }}>
                                <option>Select</option>
                                <option>Add New</option>
                                {resultsheading.map((data, index) => (
                                    stateheadcat === data.HeadingCategory ? editheadingcat !== undefined && parseInt(stateheadname) === parseInt(data.HeadingId) ? <option key={index} selected data-id={data.HeadingId}>{data.HeadingTitle}</option> : <option key={index} data-id={data.HeadingId}>{data.HeadingTitle}</option> :
                                        null
                                ))}
                            </select>
                        </div>
                        <div className="col-md-2 mt-4   p-0">

                        </div>
                        <div className="col-md-2 mt-4  text-left p-0">
                            <label className="input_label m-0">Opening Balance:</label>
                        </div>
                        <div className="col-md-3 mt-4   p-0">
                            <input type="text" className="input_styling ml-md-3 " id="openingBalance" key={state.OpeningBalance} defaultValue={state.OpeningBalance !== null ? state.OpeningBalance.toLocaleString() : state.OpeningBalance}
                                onChange={(e) => { if (e.target.value.length > 0) document.getElementById("openingBalance").value = parseInt(e.target.value.replace(/,/g, '')).toLocaleString() }}
                            ></input>
                            {/* disabled={istrue} */}
                        </div>

                    </div>
                    <div className="row ">
                        <div className="col-md-2 mt-4  text-left p-0">
                            <label className="input_label m-0 " >Opening-Balance Type:</label>
                        </div>
                        <div className="col-md-3 mt-4  p-0">
                            <select className="input_styling ml-md-3" id="balancetype" onChange={(e) => setbalancetype(e.target.options[e.target.selectedIndex].value)}>
                                <option>Select</option>
                                <option>Debit</option>
                                <option>Credit</option>

                            </select>

                        </div>
                        <div className="col-md-2 mt-4   p-0">

                        </div>
                        <div className="col-md-2 mt-4  text-left p-0">
                            <label className="input_label m-0">Description:</label>
                        </div>
                        <div className="col-md-3 mt-4   p-0">
                            <textarea className="input_address ml-md-3 " id="accountDescription" key={state.AccountDescription} defaultValue={state.AccountDescription} ></textarea>

                        </div>
                    </div>

                    <div className="row ">
                        <div className=" col-6 pr-0 pl-0  mt-md-5 ">

                        </div>
                        <div className=" col-6 pr-0 pl-0  text-right  mt-md-5 ">

                            <span><button className="save_btn ml-2" id="accountsave" onClick={addAccount} ><BsCloudUpload size="1.3em" />Save</button></span>
                        </div>
                    </div>
                    {/* <!-- The Modal --> */}
                    <div id="headingModal" className="modal">
                        <div className="modal-content">
                            <div className="modal_heading">
                                <span className="head_text pl-3">Add Heading</span>
                                <span className="close pr-3" onClick={modalclose}>&times;</span>
                            </div>
                            <div className="row ">
                                <div className="col-md-2 mt-4  pl-4 text-left p-0">
                                    <label className="input_label m-0 " >Account Type:</label>
                                </div>
                                <div className="col-md-3 mt-4    p-0">
                                    <select className="input_styling ml-md-3" id="accountheadingtype" onChange={() => setheadcatmodal(document.getElementById("accountheadingtype").options[document.getElementById("accountheadingtype").selectedIndex].value)} >
                                        <option>Select</option>
                                        <option>AS</option>
                                        <option>LI</option>
                                        <option>EQ</option>
                                        <option>IN</option>
                                        <option>EX</option>
                                    </select>

                                </div>
                                <div className="col-md-2 mt-4   p-0">
                                </div>
                                <div className="col-md-2 mt-4  text-left p-0">
                                    <label className="input_label m-0">Heading Title:</label>
                                </div>
                                <div className="col-md-3 mt-4   p-0">

                                    <input type="text" onChange={(e) => {
                                        if (e.target.value) {
                                            e.target.value = e.target.value?.toUpperCase();

                                        }
                                    }} className="input_styling ml-md-3 " id="HeadingTitle"></input>

                                </div>

                            </div>
                            <div className="row  ">
                                <div className="col-md-2 mt-4   pl-4 text-left p-0">
                                    <label className="input_label m-0 " >Sub Heading:</label>
                                </div>
                                <div className="col-md-3 mt-4  p-0">
                                    <select className="input_styling ml-md-3" id="subheadingcategory" >
                                        <option>Select</option>

                                        {resultsheading.map((data, index) => (
                                            stateheadcatmodal === data.HeadingCategory ? <option key={index} data-id={data.HeadingId}>{data.HeadingTitle}</option> :
                                                null
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-2 mt-4   p-0">
                                </div>
                                <div className="col-md-2 mt-4  text-left p-0">
                                    <label className="input_label m-0">Description:</label>
                                </div>
                                <div className="col-md-3 mt-4   p-0">

                                    <textarea onChange={(e) => {
                                        if (e.target.value) {
                                            e.target.value = e.target.value?.toUpperCase();

                                        }
                                    }} className="input_address ml-md-3 " id="headingDescription" ></textarea>

                                </div>

                            </div>

                            <div className="row ">
                                <div className=" col-6 pr-0 pl-0   mt-md-4 ">

                                </div>
                                <div className=" col-6 pr-0 pl-0   mt-md-4 text-right ">

                                    <span><button className="save_btn mr-4" id="headingsave" onClick={addheading}><BsCloudUpload size="1.3em" />Save</button></span>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* modal close */}
                    <hr></hr>
                    <div className="row ">
                        <div className=" col-6  pl-0  pr-md-2 mt-md-3 ">
                            <span className="headings">Recently Added Account</span>
                        </div>


                    </div>
                    <div className="row pt-3 ">
                        <div className="col-md-1 pr-0 pl-0">


                            <label className="input_label m-0">Select:</label>
                        </div>
                        <div className="col-md-3 pr-0 pl-0" >


                            <input type="text " className="input_styling " onChange={onTextChange} placeholder="Account"></input>
                        </div>
                        {/* <div className="col-md-3 text-center  pr-0 pl-0">
                            <label className="input_label m-0">From:</label>
                            <input type="date" className="input_date ml-3" id="from date"></input>
                        </div>
                        <div className="col-md-3 text-center pr-0 pl-0">
                            <label className="input_label m-0">To:</label>
                            <input type="date" className="input_date ml-3" id="todate"></input>
                        </div>
                        <div className="col-md-1 pr-0 pl-0">
                            <button className="btn_Go">Go</button>
                        </div> */}
                        <div className="col-md-1 pr-0 pl-0">
                            {resultsallaccount.loading ? <Loader type="ThreeDots"
                                color="green"
                                height={40}
                                width={40} /> : null}
                        </div>
                    </div>
                    <div className="row  ">
                        <div className=" col-12 pt-2  pl-0 pr-md-2 mt-2">
                            <div className="table-responsive ">
                                <table className="table table-borderless m-0">
                                    <thead>
                                        <tr>
                                            <th>Account Type</th>
                                            <th>Account Name</th>
                                            <th>Description</th>
                                            <th>Opening Balance</th>
                                            <td> <button className="btn_Go"
                                                onClick={() => {
                                                    if (accountId !== 0)
                                                        document.getElementById(
                                                            `${accountId}`
                                                        ).checked = false;
                                                    setheadname();
                                                    setheadcat();
                                                    setaccountid(0);
                                                    seteditheadingcat();
                                                    seteditbalancetype();
                                                    setState({
                                                        AccountId: accountId,
                                                        AccountTitle: null,
                                                        AccountDescription: null,
                                                        OpeningBalance: null,

                                                        Heading: 0,
                                                        ProjectId: 0

                                                    });
                                                    document.getElementById("accounttype").disabled = false;
                                                    document.getElementById("AccountCategory").disabled = false;
                                                    setistrue(false);

                                                }}>UnSelect</button></td>
                                            <th>Del</th>



                                        </tr>
                                    </thead>
                                    <tbody>
                                        {resultsallaccount.data.map((val, index) => (
                                            val.OpeningBalance == 0 ?
                                                <tr key={index}>

                                                    <td className="table_data" >{val.AccountCategory}</td>
                                                    <td className="table_data">{val.AccountTitle}</td>
                                                    <td className="table_data">{val.AccountDescription}</td>
                                                    <td className="table_data">{val.OpeningBalance.toLocaleString()}</td>

                                                    <td> <input
                                                        type="radio"
                                                        name="radio"
                                                        id={val.AccountId}
                                                        onClick={() => {
                                                            setState(val);
                                                            seteditheadingcat(val.AccountCategory);
                                                            seteditbalancetype(val.BalanceType)
                                                            setaccountid(val.AccountId);
                                                            setheadcat(val.AccountCategory);
                                                            setheadname(val.HeadingNavigation.HeadingId);
                                                            setistrue(true);

                                                            document.getElementById("accounttype").disabled = true;
                                                            document.getElementById("AccountCategory").disabled = true;


                                                        }} />
                                                    </td>
                                                    <td style={{ cursor: "pointer" }} onClick={() => deleteaccount(val.AccountId)}>
                                                        {(val.AddedByNavigationId !== null) ?
                                                            <MdDeleteForever size="1.6em" /> : <></>}
                                                    </td>

                                                </tr> :
                                                <tr key={index}>

                                                    <td>{val.AccountCategory}</td>
                                                    <td>{val.AccountTitle}</td>
                                                    <td>{val.AccountDescription}</td>
                                                    <td>{val.OpeningBalance.toLocaleString()}</td>

                                                    <td></td>

                                                    {/* <td> <input
                                                    type="radio"
                                                    name="radio"
                                                    id={val.AccountId}
                                                    onClick={() => {
                                                        setState(val);
                                                        seteditheadingcat(val.AccountCategory);
                                                        setaccountid(val.AccountId);
                                                        setheadcat(val.AccountCategory);
                                                        setheadname(val.HeadingNavigation.HeadingId);
                                                        setistrue(true);

                                                        document.getElementById("accounttype").disabled = true;
                                                        document.getElementById("AccountCategory").disabled = true;


                                                    }} />
                                                </td> */}
                                                    <td style={{ cursor: "pointer" }} onClick={() => deleteaccount(val.AccountId)}><MdDeleteForever size="1.6em" /></td>

                                                </tr>
                                        ))}



                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </>
    )
}

export default Account;
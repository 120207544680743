import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getallunits, GetAllUnits } from "../actions/getunit";
import { getallTableAssets } from "../../actions/assetsaction/addassets";
import { getallMaintenence } from "../../actions/assetsaction/maintenence";

import axios from "axios";
import Loader from "react-loader-spinner";
import { BsCloudUpload } from "react-icons/bs";
import moment from "moment";
import { TiPrinter } from "react-icons/ti";
import { useReactToPrint } from "react-to-print";
import {toast} from 'react-toastify'
// import AvailabilityPrint from "./availabilityprint";
const MaintainenceComp = () => {


    const showassetstable = () => {
        stateshowassets("AssetsAdd");
    }
    const cancelassetstable = () => {
        stateshowassets("AssetsTable");


    }
    const [showassets, stateshowassets] = useState("AssetsTable");

    return (

        <>
            <div className="row ">
                <div className="dash_background col-12 p-0">
                    <div className="row ">

                        <div className="col-md-12">
                            {showassets == "AssetsTable" ? <MaintenenceAssetsTable showassetstable={showassetstable} cancelassetstable={cancelassetstable} /> : <MaintenenceAssestsAdd cancelassetstable={cancelassetstable} />}
                        </div>


                    </div>


                </div>
            </div>
        </>

    )
}
export default MaintainenceComp;
export const MaintenenceAssestsAdd = (props) => {

    const assetsdatastate = useSelector(state => state.GetTableAssetsReducer);

    const [filerunits, setfilterunits] = useState()
    const [assetlist, setassetlist] = useState([]);

    const dispatch = useDispatch();
    const [state, setDetail] = useState({

        Title: null,
        DueDate: null,
        CompletedDate: null,
        MaintenanceBy: null,
        CostOfRepair: null,
        Detail: null,
        Status: null


    });
    // print function
    const componentRef = useRef();

    const printPaymentRec = useReactToPrint({
        content: () => componentRef.current,
    });
    //Table Data fiter




    const onTextChange = (e) => {

        if (document.getElementById("projectfilter").value === "") {
            setfilterunits(assetsdatastate.data.Assets);

        } else {
            let suggestions = [];
            {
                const regex = new RegExp(document.getElementById("projectfilter").value, "i");



                suggestions = assetsdatastate.data.Assets
                    .sort()
                    .filter((val) =>
                        (document.getElementById("projectfilter").value == "" || regex.test(val.AssetName))

                    );

            }
            setfilterunits(suggestions);
        }
    };
    useEffect(() => {
        setfilterunits(assetsdatastate.data.Assets);

    }, [assetsdatastate]
    )

    useEffect(() => {

        dispatch(getallTableAssets());

    }, [dispatch]);

    const openmodalleadsedit = (x) => {
        // setunitdetail(x)

        document.getElementById("modalofleadedit").style.display = "block";

    };
    const savedata = () => {
        // if (document.getElementById("name").value === "" || document.getElementById("subcat").options[document.getElementById("subcat").selectedIndex].value === "Select" || document.getElementById("sectiontype").options[document.getElementById("sectiontype").selectedIndex].value === "Select" || document.getElementById("subcat").options[document.getElementById("subcat").selectedIndex].value === "Select" || document.getElementById("cost").value === "") {
        //     toast.info("All fields are required")
        // }
        // else if (
        //     document.getElementById("radiobutton").checked === true && (
        //         document.getElementById("depcost").value === "" || document.getElementById("DeprecatioId").selectedIndex === 0 || document.getElementById("Salvage").value === "")
        // ) {
        //     toast.info("All fields are required ")
        // }

        // else {
        let Detail = state;
        let AssetIds = assetlist;

        Detail.Title = document.getElementById("Title").value;

        Detail.DueDate = document.getElementById("DueDate").value;
        Detail.CompletedDate = document.getElementById("CompletedDate").value;
        Detail.MaintenanceBy = document.getElementById("MaintenanceBy").value;
        Detail.CostOfRepair = document.getElementById("CostOfRepair").value;
        Detail.Detail = document.getElementById("Detail").value;
        Detail.Status = document.getElementById("Status").options[document.getElementById("Status").selectedIndex].value

        document.getElementById("assetsave").disabled = true;

        // setloader(true);

        axios({
            method: "post",
            url: `${window.$url}Maintenance/Add`,
            data: JSON.stringify({ Detail, AssetIds }),
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {



                    // dispatch(getallSection());



                    toast.info(result.data.Message);

                    document.getElementById("modalofleadedit").style.display =
                        "none";

                    props.cancelassetstable();
                    // setloader(false);

                } else {
                    toast.info(result.data.Message);
                    document.getElementById("assetsave").disabled = false;
                    // setloader(false);

                }


            },
            (error) => {
                document.getElementById("assetsave").disabled = false;

              // setloader(false);


            }
        );


        // }
    }
    return (
        <>
            {/* <div hidden>
                <AvailabilityPrint
                    ref={componentRef}

                    printData={assetsdatastate}

                /></div> */}
            <div className="row  app_font">
                <div className="dash_background col-12 pt-2">
                    <div className="row pt-3 ">
                        <div className="col-md-1 pr-0 pl-0">


                            <label className="input_label m-0">Search:</label>
                        </div>
                        <div className="col-md-3 pr-0 pl-0">


                            <input type="text " className="input_styling " id="projectfilter" placeholder="Asset Name " onChange={onTextChange}></input>
                        </div>

                        <div className="col-md-6 pr-0 pl-0">
                            {/* {filerunits !== undefined ? filerunits.loading ? <Loader type="ThreeDots"
                                color="green"
                                height={40}
                                width={40} /> : null : null} */}
                        </div>
                        <div className="col-md-2 text-right pr-0 pl-0">
                            <button
                                className="cancel_btn ml-2  "
                                onClick={props.cancelassetstable}
                            >

                                Cancel
                            </button>
                            <button className="save_btn ml-2  " onClick={openmodalleadsedit}>

                                Send
                            </button>
                        </div>

                    </div>

                    <div className="row ">
                        <div className=" col-12 pr-0 pl-0 table_height">

                            <div className="table-responsive mt-4">

                                <table className="table table-borderless m-0">
                                    <thead>

                                        <tr >

                                            <th>Sr</th>

                                            <th>AssetName</th>

                                            <th> EntryDate</th>
                                            <th>PurchaseDate</th>
                                            <th>Brand</th>
                                            <th>Model</th>
                                            <th>Cost</th>
                                            <th>Select</th>










                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filerunits !== undefined ? filerunits.map((val, index) => (
                                            val.AvailabilityStatus === "AVAILABLE" ?
                                                <tr key={index + 1}>
                                                    <td className="cur_sor" >{index + 1}</td>

                                                    <td className="cur_sor" >{val.AssetName !== undefined && val.AssetName !== null ? val.AssetName : null}</td>

                                                    <td className="cur_sor" >{val.EntryDate !== null ?moment(val.EntryDate.split("T")[0]).format("DD-MMM-YYYY") : null}</td>
                                                    <td className="cur_sor" >{val.PurchaseDate !== null ?moment(val.PurchaseDate.split("T")[0]).format("DD-MMM-YYYY") : null}</td>
                                                    <td className="cur_sor" >{val.Brand !== undefined ? val.Brand : null}</td>
                                                    <td className="cur_sor" >{val.Model !== undefined ? val.Model : null}</td>

                                                    <td className="cur_sor" >{val.Cost !== undefined ? val.Cost : null}</td>

                                                    <td>
                                                        <input id={"advCheck" + val.AssetId} type="checkbox" name="check2" onChange={(e) => {
                                                            if (e.target.checked == true) {

                                                                // let taxobject =  val.AssetId
                                                                setassetlist([...assetlist, val.AssetId]);

                                                            } else {
                                                                const rows = [...assetlist];

                                                                rows.splice(index, 1);

                                                                setassetlist(rows)


                                                            }





                                                        }} >

                                                        </input>
                                                    </td>



                                                </tr> : null
                                        )) : null}


                                    </tbody>

                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div id="modalofleadedit" class="modalscreen ">
                <div class="modal-content-screen1 pt-1">
                    <div className="row ">
                        <div className="col-12  page_heading">
                            <h4 className="  text-center ">Maintenance Detail</h4>
                            <span
                                class="close pr-2  "
                                onClick={() => {
                                    document.getElementById("modalofleadedit").style.display =
                                        "none";



                                }}
                            >
                                &times;
                            </span>

                        </div>
                    </div>
                    <div className="container">



                        <div className="row " >

                            <div className="col-md-2 mt-md-4  text-left p-0">
                                <label className="input_label m-0 " >Title:</label>
                            </div>
                            <div className="col-md-3 mt-md-4  p-0">
                                <input type="text" className="input_styling  ml-md-3" id="Title"></input>





                            </div>
                            <div className="col-md-2 mt-md-4   p-0">
                            </div>
                            <div className="col-md-2 mt-md-4  text-left p-0">
                                <label className="input_label m-0 " >Status:</label>
                            </div>
                            <div className="col-md-3 mt-md-4  p-0">
                                <select className="input_styling ml-md-3" id="Status" >
                                    <option>Select</option>
                                    <option>Pending</option>

                                    <option>Compeleted</option>



                                </select>







                            </div>
                        </div>

                        <div className="row " >


                            <div className="col-md-2 mt-md-4  text-left p-0">
                                <label className="input_label m-0 " >Due Date:</label>
                            </div>
                            <div className="col-md-3 mt-md-4  p-0">
                                <input type="date" className="input_date_styling  ml-md-3" id="DueDate"
                                ></input>





                            </div>

                            <div className="col-md-2 mt-md-4   p-0">
                            </div>
                            <div className="col-md-2 mt-md-4  text-left p-0">
                                <label className="input_label m-0 " >Compeleted Date:</label>
                            </div>
                            <div className="col-md-3 mt-md-4  p-0">
                                <input type="date" className="input_date_styling  ml-md-3" id="CompletedDate"
                                ></input>





                            </div>

                        </div>


                        <div className="row " >

                            <div className="col-md-2 mt-md-4  text-left p-0">
                                <label className="input_label m-0 " >Maintenance By:</label>
                            </div>
                            <div className="col-md-3 mt-md-4  p-0">
                                <input type="text" className="input_styling  ml-md-3" id="MaintenanceBy" ></input>





                            </div>
                            <div className="col-md-2 mt-md-4   p-0">
                            </div>
                            <div className="col-md-2 mt-md-4  text-left p-0">
                                <label className="input_label m-0 " >Cost of Repair:</label>
                            </div>
                            <div className="col-md-3 mt-md-4  p-0">
                                <input type="text" className="input_styling  ml-md-3" id="CostOfRepair" ></input>





                            </div>
                        </div>


                        <div className="row " >

                            <div className="col-md-2 mt-md-4  text-left p-0">
                                <label className="input_label m-0 " >Detail:</label>
                            </div>
                            <div className="col-md-3 mt-md-4  p-0">
                                <textarea className="input_address ml-md-3" id="Detail"></textarea>





                            </div>

                        </div>


                        {/* 
                            <div className="col-md-2 mt-md-4  text-left p-0">
                                <label className="input_label m-0 " >Lead:</label>
                            </div>
                            <div className="col-md-3 mt-md-4  p-0">
                                <input type="text" className="input_styling  ml-md-3" id="inittiate" onChange={LeadSelecttion} ></input>
                                <span id="unitdemo"></span>



                            </div> */}








                        <div className="row ">



                            <div className=" col-12 text-right mt-md-4 ">



                                <button className="save_btn ml-2  " id="assetsave" onClick={savedata} >
                                    <BsCloudUpload size="1.3em" />
                                    Save
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};
export const MaintenenceAssetsTable = (props) => {
    const maintenencedatastate = useSelector(state => state.GetAssetsMaintenence);

    const [allmodalData, setallmodalData] = useState([]);
    const [filerunits, setfilterunits] = useState();



    let dispatch = useDispatch();

    var suggestionlist = [];

    const onTextChange = (e) => {
        const value = e.target.value;
        if (value === "") {

            setfilterunits(maintenencedatastate.data);
        } else {
            let suggestions = [];
            if (value.length > 0) {
                const regex = new RegExp(`${value}`, "i");

                suggestions = filerunits
                    .sort()
                    .filter((val) =>
                        (regex.test(val.Title !== null ? val.Title : null)));

            }
            suggestionlist = suggestions;
            setfilterunits(suggestionlist);
        }
    };

    const openmodalallowancetable = (x) => {

        setallmodalData(x);

        document.getElementById("modalofleadtable").style.display = "block";

    };

    useEffect(() => {

        dispatch(getallMaintenence());

    }, [dispatch]);
    useEffect(() => {
        setfilterunits(maintenencedatastate.data);

    }, [maintenencedatastate]
    )
    return (
        <>

            <div className="row  ">
                <div className="dash_background col-12 p-0">
                    {/* < div className="row ">
                        <div className="col-md-12   text-left page_heading">
                            <h4 className="m-0">Salaries</h4>
                        </div>
                    </div> */}
                    <div className="row pt-3 ">
                        <div className="col-md-1 pr-0 pl-0">


                            <label className="input_label  m-0">Search:</label>
                        </div>
                        <div className="col-md-3   pr-0 pl-0">


                            <input type="text " className="input_styling " placeholder="Asset" onChange={onTextChange} ></input>
                        </div>

                        <div className="col-md-1 pr-0 pl-0">
                            {maintenencedatastate.loading ? <Loader type="ThreeDots"
                                color="green"
                                height={40}
                                width={40} /> : null}
                        </div>
                    </div>




                    <div className="table-responsive mt-4">

                        <table className="table table-borderless  table-hover m-0">
                            <thead>

                                <tr >

                                    <th>Sr</th>

                                    <th>Title</th>

                                    <th> Maintenance By</th>
                                    <th>Due Date</th>
                                    <th>Completed Date</th>
                                    <th>Cost Of Repair</th>
                                    <th>Detail</th>
                                    <th>Status</th>






                                    <th className="text-center">
                                        <button
                                            className="save_btn"
                                            onClick={() => {
                                                props.showassetstable();
                                            }}
                                        >
                                            New+
                                        </button>

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {filerunits !== undefined ? filerunits.map((val, index) => (
                                    <tr key={index + 1}>
                                        <td className="cur_sor" onClick={() => { openmodalallowancetable(val.AssetMaintenance); }}>{index + 1}</td>

                                        <td className="cur_sor" onClick={() => { openmodalallowancetable(val.AssetMaintenance); }}>{val.Title !== undefined && val.Title !== null ? val.Title : null}</td>

                                        <td className="cur_sor" onClick={() => { openmodalallowancetable(val.AssetMaintenance); }}>{val.MaintenanceBy !== undefined ? val.MaintenanceBy : null}</td>
                                        <td className="cur_sor" onClick={() => { openmodalallowancetable(val.AssetMaintenance); }}>{val.DueDate !== undefined ? moment(val.DueDate.split("T")[0]).format("DD-MMM-YYYY") : null}</td>
                                        <td className="cur_sor" onClick={() => { openmodalallowancetable(val.AssetMaintenance); }}>{val.CompletedDate !== undefined ?moment(val.CompletedDate.split("T")[0]).format("DD-MMM-YYYY") : null}</td>
                                        <td className="cur_sor" onClick={() => { openmodalallowancetable(val.AssetMaintenance); }}>{val.CostOfRepair !== undefined ? val.CostOfRepair.toLocaleString() : null}</td>
                                        <td className="cur_sor" onClick={() => { openmodalallowancetable(val.AssetMaintenance); }}>{val.Detail !== undefined ? val.Detail : null}</td>
                                        <td className="cur_sor" onClick={() => { openmodalallowancetable(val.AssetMaintenance); }}>{val.Status !== null ? val.Status : null}</td>


                                        <td></td>

                                        {/* <td><FiEdit2 title="Edit" size="1.1em" className="cur_sor" onClick={() => { props.setallallowances(val.EmployeeSalaryAllowances); props.setstate(val); props.setempid(val.Employee.EmployeeId); props.showdesignationtable(); props.setedit(true); }} /></td> */}



                                    </tr>
                                )) : null}


                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

            {/* model for show information*/}
            <div id="modalofleadtable" class="modalscreen ">
                <div class="modal-content-screen1 pt-1">
                    <div className="row ">
                        <div className="col-12  page_heading">
                            <h4 className="  text-center ">Asset Detail</h4>
                            <span
                                class="close pr-2  "
                                onClick={() => {
                                    document.getElementById("modalofleadtable").style.display =
                                        "none";
                                }}
                            >
                                &times;
                            </span>

                        </div>
                    </div>
                    <div className="container modal_container">



                        <div className="table-responsive mt-4">
                            <table class="table mt-2">
                                <thead class="purple whiteText">
                                    <tr>
                                        <th scope="col">Asset Name</th>
                                        <th scope="col">Cost</th>
                                        <th scope="col">CurrentValues</th>




                                    </tr>
                                </thead>
                                <tbody>
                                    {allmodalData !== undefined && allmodalData !== null

                                        // allmodalData.length == 0
                                        ?
                                        allmodalData.map((x, id) => (
                                            <tr key={id}>
                                                <td>{x.Asset.AssetName}</td>
                                                <td>{x.Asset.Cost !== undefined ? x.Asset.Cost.toLocaleString() : null}</td>

                                                <td>{x.Asset.CurrentValue !== undefined ? x.Asset.CurrentValue.toLocaleString() : null}</td>



                                            </tr>
                                        )) : null}
                                </tbody>
                            </table>
                        </div>







                        {/* <div className="row">
                            <div className=" col-12 text-right pr-0 pl-0">
                                <button className="print_btn float-right" ><TiPrinter size="1.3em" />Print</button>


                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

        </>
    )
}

import moment from "moment";
import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
class MainContractPrint extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "auto" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Contracts</u>
            </h4>

            <div className="mt-5">
              <div className="col-12">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>date</th>

                      <th> Buyer</th>
                      <th> Broker</th>
                      {/* <th>Manager</th> */}
                      <th> Unit</th>
                      <th> SaleRate</th>
                      <th> Total</th>
                      <th> Received</th>
                      <th> Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.props.printData !== undefined
                      ? this.props.printData?.map((val, index) =>
                          val.ContractStatus == "TRANSFERRED" ? null : (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {moment(val.EntryDate?.split("T")[0]).format(
                                  "DD-MMM-YYYY"
                                )}
                              </td>

                              <td>
                                {val.CustomerCnicNavigation?.CustomerName}
                              </td>
                              <td>
                                {val.AffiliateCnicNavigation?.AffiliateName}
                              </td>
                              {/* <td>{val.Manager?.Employee?.EmpName}</td> */}
                              <td>{val.Unit?.UnitName}</td>
                              <td>{val.SaleRate?.toLocaleString()}</td>
                              <td>{val.TotalPrice?.toLocaleString()}</td>
                              <td>
                                {val.CustomerReceivable?.toLocaleString()}
                              </td>
                              <td>{val.ContractStatus}</td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MainContractPrint;

import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { GrPrint } from "react-icons/gr";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getAllTransferContractTable } from "../../../actions/accountsactions/transfercontract";
import MainTransferPrint from "../TransferPrint/MainTransferPrint";
import { TransferIndvidualPrint } from "../TransferPrint/TransferIndvidualPrint";
import { TransferOfficalPrint } from "../TransferPrint/TransferOfficalPrint";
import { CSVLink } from "react-csv";
import TransferContractModal from "./TransferContractModal";



export const TransferContractTable = (props) => {
    const resultsallcontracts = useSelector((state) => state.GetTransferContractTable);
    const dispatch = useDispatch();
    const [contractinstallment, setcontractinstallment] = useState();
    const [printData, SetPrintData] = useState();
    const [printOfficalContractData, SetprintOfficalContractData] = useState();
    const [statetoggle, settoggle] = useState(true);
    const [filerunits, setfilterunits] = useState();

    // print function
    const componentRef = useRef();

    const printIndvidualContract = useReactToPrint({
        content: () => componentRef.current,
    });
    // useEffect(() => {
    //     if (printData !== undefined) {
    //         printRecoveryBooking();
    //     }
    // }, [printData]);
    // // all projects print

    const AllcomponentRef = useRef();

    const AllprintPaymentRec = useReactToPrint({
        content: () => AllcomponentRef.current,
    });
    // contract offical print
    // print function
    const OfficalContractRef = useRef();

    const printOfficalContract = useReactToPrint({
        content: () => OfficalContractRef.current,
    });
    useEffect(() => {
        if (printOfficalContractData !== undefined) {
            printOfficalContract();
        }
    }, [printOfficalContractData, statetoggle]);
    //Table Data fiter

    let suggestionlist = [];

    // const onTextChange = (e) => {
    //     const value = e.target.value;
    //     if (value === "") {

    //         dispatch(getAllTransferContractTable({}));
    //     } else {
    //         let suggestions = [];
    //         if (value.length > 0) {
    //             const regex = new RegExp(`${value}`, "i");

    //             suggestions = resultsallcontracts.data
    //                 .sort()
    //                 .filter((val) =>
    //                     (regex.test(val.NewContract.Unit.UnitName) || regex.test(val.NewContract.ContractStatus) || regex.test(val.NewContract.CustomerCnicNavigation.CustomerName)));

    //         }
    //         suggestionlist = suggestions;
    //         dispatch(GetAllTransferContractTableData(suggestionlist));
    //     }
    // };
    const onTextChange = (e) => {

        if (document.getElementById("projectfilter").value === "" && document.getElementById("sectionfilter").value === "" && document.getElementById("reservedfilter").value === "") {
            setfilterunits(resultsallcontracts.data);

        } else {
            let suggestions = [];
            {
                const regex = new RegExp(document.getElementById("projectfilter").value, "i");
                const regex1 = new RegExp(document.getElementById("sectionfilter").value, "i");

                const regex4 = new RegExp(document.getElementById("reservedfilter").value, "i");


                suggestions = resultsallcontracts.data
                    .sort()
                    .filter((val) =>
                        (document.getElementById("projectfilter").value == "" || regex.test(val.NewContract.Unit.UnitName)) &&
                        (document.getElementById("sectionfilter").value == "" || regex1.test(val.NewContract.CustomerCnicNavigation.CustomerName)) &&

                        (document.getElementById("reservedfilter").value == "" || regex4.test(val.NewContract.ContractStatus))
                    );

            }
            setfilterunits(suggestions);
        }
    };
    useEffect(() => {

        let data = {}
        dispatch(getAllTransferContractTable(data));
    }, [dispatch]
    )
    const openmodalcontracttable = (x) => {

        document.getElementById("modalofcontracttable").style.display = "block";
        setcontractinstallment(x);
    };
    function ongo() {
        let data = {
            ProjectId: `${localStorage.getItem("ProjectId")}`,
            From: document.getElementById("fromdate").value,
            To: document.getElementById("todate").value

        }

        dispatch(getAllTransferContractTable(data));

    }
    useEffect(() => {
        setfilterunits(resultsallcontracts.data);
    }, [resultsallcontracts]
    );
    const [CsvData, setCsvData] = useState([]);
    useEffect(() => {
      let arr = [];

      filerunits?.forEach((val, index) => {
        let year = val.EntryDate.slice(0, 4);
        let day = val.EntryDate.slice(8, 10);
        let month = val.EntryDate.slice(5, 7);
        arr.push({
          Sr: index + 1,
          Date:
            val.NewContract.EntryDate !== undefined
              ? day + "-" + month + "-" + year
              : null,
          Buyer: val.NewContract.CustomerCnicNavigation.CustomerName,
          //   Affiliate: val.NewContract.AffiliateCnicNavigation.AffiliateName,
          //   Manager: val.NewContract.Manager.Employee.EmpName,
          Unit: val.NewContract.Unit.UnitName,
          SaleRate: val.NewContract.SaleRate.toLocaleString(),
          Total: val.NewContract.TotalPrice.toLocaleString(),
          Receivable: val.NewContract.CustomerReceivable.toLocaleString(),
          Status: val.NewContract.ContractStatus,
        });
      });
      setCsvData(arr);
    }, [filerunits]);
    return (
      <>
        <div hidden>
          <TransferOfficalPrint
            ref={OfficalContractRef}
            printData={printOfficalContractData}
          />
        </div>
        <div hidden>
          <TransferIndvidualPrint ref={componentRef} printData={printData} />
        </div>

        <div hidden>
          <MainTransferPrint
            ref={AllcomponentRef}
            printData={resultsallcontracts}
          />
        </div>
        <div className="row pt-3 ">
          <div className="col-md-1 pr-0 pl-0">
            <label className="input_label m-0">Search:</label>
          </div>
          <div className="col-md-2 pr-0 pl-0">
            <input
              type="text "
              className="input_styling "
              id="projectfilter"
              onChange={onTextChange}
              placeholder="Unit "
            ></input>
          </div>
          <div className="col-md-2 pr-0 pl-0">
            <input
              type="text "
              className="input_styling "
              id="sectionfilter"
              onChange={onTextChange}
              placeholder="Buyer"
            ></input>
          </div>

          <div className="col-md-2 pr-0 pl-0">
            <input
              type="text "
              className="input_styling "
              id="reservedfilter"
              onChange={onTextChange}
              placeholder="Status "
            ></input>
          </div>
          <div className="col-md-1 pr-0 pl-0">
            <TransferContractModal />
          </div>
          <div className="col-md-1 pr-0 pl-0">
            {resultsallcontracts.loading ? (
              <Loader type="ThreeDots" color="green" height={40} width={40} />
            ) : null}
          </div>
        </div>

        <div className="row pt-2 ">
          <div className="col-md-3  pr-0 pl-0">
            <label className="input_label m-0">From:</label>
            <input
              type="date"
              className="input_date ml-3"
              id="fromdate"
            ></input>
          </div>
          <div className="col-md-3 text-center pr-0 pl-0">
            <label className="input_label m-0">To:</label>
            <input
              type="date"
              className="input_date ml-3"
              id="todate"
              defaultValue={moment().format("YYYY-MM-DD")}
            ></input>
          </div>
          <div className="col-md-1 pr-0 pl-0">
            <button className="btn_Go" onClick={ongo}>
              Go
            </button>
          </div>
          <div className="col-md-1 pr-0 pl-0"></div>
        </div>
        <div className="row ">
          <div className=" col-12  table_height pr-0 pl-0">
            <div className="table-responsive mt-3">
              <table className="table table-borderless m-0 table-hover cur_sor">
                <thead>
                  <tr>
                    <th>Sr</th>
                    <th>Date</th>
                    <th> Buyer</th>
                    {/* <th> Affiliate</th>
                                    <th>Manager</th> */}
                    <th> Unit</th>
                    <th> SaleRate</th>
                    <th> Total</th>
                    <th> Receivable</th>
                    <th> Status</th>

                    <th className="text-center">
                      <button
                        className="save_btn"
                        onClick={() => {
                          props.changecomponent();
                        }}
                      >
                        New+
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filerunits !== undefined
                    ? filerunits.map((val, index) => {
                        let year = val.NewContract?.EntryDate.slice(0, 4);
                        let day = val.NewContract?.EntryDate.slice(8, 10);
                        let month = val.NewContract?.EntryDate.slice(5, 7);
                        switch (month) {
                          case "01":
                            month = "Jan";

                            break;
                          case "02":
                            month = "Feb";

                            break;
                          case "03":
                            month = "Mar";

                            break;
                          case "04":
                            month = "Apr";

                            break;
                          case "05":
                            month = "May";

                            break;
                          case "06":
                            month = "Jun";

                            break;
                          case "07":
                            month = "Jul";

                            break;
                          case "08":
                            month = "Aug";

                            break;
                          case "09":
                            month = "Sep";

                            break;
                          case "10":
                            month = "Oct";
                            break;
                          case "11":
                            month = "Nov";
                            break;
                          case "12":
                            month = "Dec";
                            break;
                          default:
                            break;
                        }
                        return (
                          <tr key={index}>
                            <td
                              onClick={() => {
                                openmodalcontracttable(val);
                                SetPrintData(val);
                              }}
                            >
                              {index + 1}
                            </td>
                            <td
                              onClick={() => {
                                openmodalcontracttable(val);
                                SetPrintData(val);
                              }}
                            >
                              {val.NewContract.EntryDate !== undefined
                                ? day + "-" + month + "-" + year
                                : null}
                            </td>

                            <td
                              onClick={() => {
                                openmodalcontracttable(val);
                                SetPrintData(val);
                              }}
                            >
                              {
                                val.NewContract.CustomerCnicNavigation
                                  .CustomerName
                              }
                            </td>
                            {/* <td onClick={() => {
                                            openmodalcontracttable(val);
                                            SetPrintData(val);
                                        }}>{val.NewContract.AffiliateCnicNavigation.AffiliateName}</td> */}
                            {/* <td onClick={() => {
                                            openmodalcontracttable(val);
                                            SetPrintData(val);
                                        }}>{val.NewContract.Manager.Employee.EmpName}</td> */}
                            <td
                              onClick={() => {
                                openmodalcontracttable(val);
                                SetPrintData(val);
                              }}
                            >
                              {val.NewContract.Unit.UnitName}
                            </td>
                            <td
                              onClick={() => {
                                openmodalcontracttable(val);
                                SetPrintData(val);
                              }}
                            >
                              {val.NewContract.SaleRate.toLocaleString()}
                            </td>
                            <td
                              onClick={() => {
                                openmodalcontracttable(val);
                                SetPrintData(val);
                              }}
                            >
                              {val.NewContract.TotalPrice.toLocaleString()}
                            </td>
                            <td
                              onClick={() => {
                                openmodalcontracttable(val);
                                SetPrintData(val);
                              }}
                            >
                              {val.NewContract.CustomerReceivable.toLocaleString()}
                            </td>
                            <td
                              onClick={() => {
                                openmodalcontracttable(val);
                                SetPrintData(val);
                              }}
                            >
                              {val.NewContract.ContractStatus}
                            </td>

                            {/* 
                                        <td className="text-center cur_sor" onClick={() => { if (parseFloat(val.NewContract.AdvanceReceived) >= parseFloat(val.NewContract.AdvanceAmount)) { SetprintOfficalContractData(val); settoggle(!statetoggle) } else { toast.info(`${val.NewContract.AdvanceAmount - val.NewContract.AdvanceReceived}-PKR remaining to unlock file`) } }} >
                                            <GrPrint size="1.3em" />
                                        </td> */}

                            <td
                              className="text-center cur_sor"
                              onClick={() => {
                                SetprintOfficalContractData(val);
                                settoggle(!statetoggle);
                              }}
                            >
                              <GrPrint size="1.3em" />
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-6  text-right pr-0 pl-0">
            <button
              className="print_btn float-left"
              onClick={() => {
                AllprintPaymentRec();
              }}
            >
              <TiPrinter size="1.3em" />
              Print
            </button>
            <button
              style={{ color: "black !important" }}
              className="print_btn ml-2 float-left"
            >
              <CSVLink
                style={{ color: "black" }}
                filename="Transfer_Contract_Csv"
                data={CsvData}
              >
                Download CSV
              </CSVLink>
            </button>
          </div>
        </div>
        <div id="modalofcontracttable" class="modalscreen ">
          <div class="modal-content-screen1 pt-1">
            <div className="row ">
              <div className="col-12  page_heading">
                <h4 className="  text-center ">Contract Detail</h4>
                <span
                  class="close pr-2  "
                  onClick={() => {
                    document.getElementById(
                      "modalofcontracttable"
                    ).style.display = "none";
                  }}
                >
                  &times;
                </span>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-6 mt-2">
                  {/* <ul>
                                    <li>
                                        <b>Project:</b>{" "}
                                        {contractinstallment !== undefined && contractinstallment.Project !== undefined ? contractinstallment.Project.ProjectName : null}
                                    </li>
                                    <li>
                                        <b> Unit:</b>{" "}
                                        {contractinstallment !== undefined && contractinstallment.Unit !== undefined ? contractinstallment.Unit.UnitName : null}
                                    </li>
                                    <li>
                                        <b> Affiliate:</b>{" "}
                                        {contractinstallment !== undefined && contractinstallment.AffiliateCnicNavigation !== undefined ? contractinstallment.AffiliateCnicNavigation.AffiliateName : null}
                                    </li>
                                    <li>
                                        <b> Customer:</b>{" "}
                                        {contractinstallment !== undefined && contractinstallment.CustomerCnicNavigation !== undefined ? contractinstallment.CustomerCnicNavigation.CustomerName : null}

                                    </li>
                                    <li>
                                        <b> Manger:</b>{" "}
                                        {contractinstallment !== undefined && contractinstallment.Manager !== undefined ? contractinstallment.Manager.Employee.EmployeeName : null}

                                    </li>
                                    <li>
                                        <b>Area:</b>{" "}
                                        {contractinstallment !== undefined && contractinstallment.Unit !== undefined ? contractinstallment.Unit.Marla + "M " + contractinstallment.Unit.Sarsai + " S" : null}
                                    </li>
                                    <li>
                                        <b>Sqft:</b>{" "}
                                        {contractinstallment !== undefined && contractinstallment.Unit !== undefined ? contractinstallment.Unit.NetSqft : null}
                                    </li>
                                    <li>
                                        <b>Dimension(LxW):</b>{" "}
                                        {contractinstallment !== undefined && contractinstallment.Unit !== undefined ? contractinstallment.Unit.DimensionLength + "x" + contractinstallment.Unit.DimensionWidth : null}
                                    </li>
                                    <li>
                                        {contractinstallment !== undefined && contractinstallment.Unit !== undefined && contractinstallment.Unit.RatePerMarlaOrsqft == "MARLA" ? <b>Rate/Marla:</b> : <b>Rate/Sqft:</b>} {" "}
                                        {contractinstallment !== undefined && contractinstallment.Unit !== undefined ? contractinstallment.Unit.RatePerMarla.toLocaleString() : null}
                                    </li>
                                    <li>
                                        <b> Advance:</b>{" "}
                                        {contractinstallment !== undefined && contractinstallment.AdvanceAmount !== null ? contractinstallment.AdvanceAmount.toLocaleString() : null}

                                    </li>
                                    <li>
                                        <b> Status:</b>{" "}
                                        {contractinstallment !== undefined && contractinstallment.ContractStatus !== null ? contractinstallment.ContractStatus : null}

                                    </li>
                                </ul> */}
                </div>
                <div className="col-6 mt-2">
                  {/* <ul>


                                    <li>
                                        <b>Date:</b>{" "}
                                        {contractinstallment !== undefined && contractinstallment.EntryDate !== undefined ? contractinstallment.EntryDate.split("T")[0] : null}
                                    </li>
                                    <li>
                                        <b> Sale Rate:</b>{" "}
                                        {contractinstallment !== undefined && contractinstallment.SaleRate !== null ? contractinstallment.SaleRate.toLocaleString() : null}

                                    </li>
                                    <li>
                                        <b>Total Price:</b>{" "}
                                        {contractinstallment !== undefined && contractinstallment.TotalPrice !== null ? contractinstallment.TotalPrice.toLocaleString() : null}

                                    </li>
                                    <li>
                                        <b>Receivable:</b>{" "}
                                        {contractinstallment !== undefined && contractinstallment.CustomerReceivable !== null ? contractinstallment.CustomerReceivable.toLocaleString() : null}

                                    </li>
                                    <li>
                                        <b> Commission Exp%:</b>{" "}
                                        {contractinstallment !== undefined && contractinstallment.ComissionPer !== null ? contractinstallment.ComissionPer : null}

                                    </li>
                                    <li>
                                        <b> Commission Exp:</b>{" "}
                                        {contractinstallment !== undefined && contractinstallment.ComissionAmount !== null ? contractinstallment.ComissionAmount.toLocaleString() : null}

                                    </li>
                                    <li>
                                        <b> Commission Inc%:</b>{" "}
                                        {contractinstallment !== undefined && contractinstallment.CompanyCommissionPer !== null ? contractinstallment.CompanyCommissionPer.toLocaleString() : null}

                                    </li>
                                    <li>
                                        <b> Commission Inc:</b>{" "}
                                        {contractinstallment !== undefined && contractinstallment.CompanyCommissionAmount !== null ? contractinstallment.CompanyCommissionAmount : null}

                                    </li>
                                    <li>
                                        <b> Additional Margin%:</b>{" "}
                                        {contractinstallment !== undefined && contractinstallment.AdditionalMarginPer !== null ? contractinstallment.AdditionalMarginPer : null}

                                    </li>
                                    <li>
                                        <b> Additional Margin:</b>{" "}
                                        {contractinstallment !== undefined && contractinstallment.AdditionalMarginAmount !== null ? contractinstallment.AdditionalMarginAmount.toLocaleString() : null}

                                    </li>
                                </ul> */}
                </div>
              </div>

              <div className="text-center">
                <b>Advance</b>
              </div>
              <table class="table table-hover">
                <thead class="purple whiteText tableCash">
                  <tr className="purple whiteText">
                    <th>Total</th>

                    <th scope="col">Pending </th>
                    <th scope="col">Received</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {contractinstallment !== undefined ? (
                    <tr>
                      <td>
                        {contractinstallment.NewContract.AdvanceAmount !==
                        undefined
                          ? parseFloat(
                              contractinstallment.NewContract.AdvanceAmount
                            ).toLocaleString()
                          : 0}
                      </td>
                      <td>
                        {parseFloat(
                          contractinstallment.NewContract.AdvanceAmount -
                            contractinstallment.NewContract.AdvanceReceived
                        ).toLocaleString()}
                      </td>
                      <td>
                        {contractinstallment.NewContract.AdvanceReceived !==
                        undefined
                          ? parseFloat(
                              contractinstallment.NewContract.AdvanceReceived
                            ).toLocaleString()
                          : 0}
                      </td>
                      <td className="ml-2">
                        {contractinstallment.NewContract.AdvanceAmount -
                          contractinstallment.NewContract.AdvanceReceived !==
                        0
                          ? "Pending"
                          : "Paid"}
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
              <div className="text-center">
                <b>Installments</b>
              </div>
              <table class="table ">
                <thead class="purple whiteText">
                  <tr>
                    <th scope="col">Install No.</th>
                    <th scope="col">Due Date</th>
                    <th scope="col">Total</th>
                    <th scope="col">Pending </th>
                    <th scope="col">Received</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {contractinstallment === undefined ||
                  contractinstallment.NewContract.ContractInstallments ===
                    undefined
                    ? // contractinstallment.length == 0
                      null
                    : contractinstallment.NewContract.ContractInstallments.map(
                        (x, id) => (
                          <tr key={id}>
                            <td>{x.InstallmentNo}</td>
                            <td>
                              {moment(x.DueDate.split("T")[0]).format(
                                "DD-MMM-YYYY"
                              )}
                            </td>
                            <td>{x.DueAmount.toLocaleString()}</td>
                            <td>
                              {(
                                x.DueAmount - x.ReceivedAmount
                              ).toLocaleString()}
                            </td>
                            <td>{x.ReceivedAmount.toLocaleString()}</td>
                            <td>{x.InstallmentStatus}</td>
                          </tr>
                        )
                      )}
                </tbody>
              </table>
              <div className="row">
                <div className=" col-12 text-right pr-0 pl-0">
                  <button
                    className="print_btn float-right"
                    onClick={() => {
                      printIndvidualContract();
                    }}
                  >
                    <TiPrinter size="1.3em" />
                    Print
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};
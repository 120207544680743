const allcontractPopulate = { data: [], loading: true };
const contracttable = { data: [], loading: true };
const contracttablePopulate = { data: [], loading: true };
export const GetAllContractPopulateRed = (state = allcontractPopulate, action) => {
    switch (action.type) {

        case "get_allcontractpopulate":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetContractTable = (state = contracttable, action) => {
    switch (action.type) {

        case "get_contracttable":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetContractTablePopulate = (state = contracttablePopulate, action) => {
    switch (action.type) {

        case "get_contractPopulateData":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
import {
  Document,
  Image,
  PDFViewer,
  Page,
  View,
  Text,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import React from "react";
import gravitywatermark from "../../../../assets/Gravitywater.jpg";
import QuickSand from "./Quicksand-SemiBold.ttf";
Font.register({
  family: "Quicksand sans-serif",
  src: QuickSand,
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Quicksand sans-serif",
    padding: "0px 20px",
    paddingTop: "180px",
    paddingBottom: "120px",
    fontSize: "12px",
    fontWeight: "light",
  },
  section: {
    border: "1px solid black",
    padding: 3,
    marginBottom: 2,
  },
  infoParent: {
    display: "flex",
    width: "100%",
    gap: 3,
    flexDirection: "row",
    paddingBottom: 1,
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  infoChild: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },
  labelFont: {
    fontSize: "10px",
  },
  valueFont: {
    fontSize: "9px",
  },
  linePadding: {
    paddingBottom: 5,
  },
});
function ContractNewPrint({ printData }) {
  return (
    <PDFViewer width={"100%"} height={"800px"}>
      {" "}
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={{ width: "100%" }}>
            <Image
              style={{
                width: "400px",
                position: "absolute",
                maxHeight: "400px",
                display: "flex",
                alignSelf: "center",
              }}
              src={gravitywatermark}
            />
          </View>
          <View style={{ textAlign: "center", fontSize: "14px" }}>
            <Text>IQRAR NAMA</Text>
          </View>
          <View
            style={{
              display: "flex",
              width: "100%",
              gap: 3,
              flexDirection: "row",
              paddingBottom: 1,
            }}
          >
            <Text>Contract</Text>
            <Text
              style={{
                fontSize: "10px",
                borderBottom: "1px solid black ",
              }}
            >
              {printData?.ContractId}
            </Text>
          </View>
          <View style={styles.section}>
            <View style={{ fontWeight: "bold", padding: "5px 0" }}>
              <Text>Owner Inforamtion:</Text>
            </View>
            <View style={styles.infoParent}>
              <View style={{ ...styles.infoChild, width: "45%" }}>
                <Text
                  style={{
                    ...styles.labelFont,
                  }}
                >
                  Name:
                </Text>
                <Text style={{ ...styles.valueFont }}>
                  {printData?.CustomerCnicNavigation?.CustomerName}
                </Text>
              </View>
              <View style={{ ...styles.infoChild, width: "45%" }}>
                <Text style={styles.labelFont}>Father/Husb:</Text>
                <Text style={styles.valueFont}>
                  {printData?.CustomerCnicNavigation?.GuardianName}
                </Text>{" "}
              </View>
            </View>
            <View style={styles.infoParent}>
              <View style={{ ...styles.infoChild, width: "45%" }}>
                <Text style={styles.labelFont}>CNIC:</Text>
                <Text style={styles.valueFont}>
                  {printData?.CustomerCnicNavigation?.CustomerCnic2}
                </Text>
              </View>
              <View style={{ ...styles.infoChild, width: "45%" }}>
                <Text style={styles.labelFont}>Ph:</Text>
                <Text style={styles.valueFont}>
                  {printData?.CustomerCnicNavigation?.ContactNumber1}
                </Text>
              </View>
            </View>

            <View style={styles.infoParent}>
              <View style={styles.infoChild}>
                <Text style={styles.labelFont}>Address:</Text>
                <Text style={styles.valueFont}>
                  {printData?.CustomerCnicNavigation.CustomerAddress}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.section}>
            <View style={{ fontWeight: "bold", padding: "5px 0" }}>
              <Text>Nominee Inforamtion:</Text>
            </View>
            <View style={styles.infoParent}>
              <View style={{ ...styles.infoChild, width: "45%" }}>
                <Text style={styles.labelFont}>Name:</Text>
                <Text style={styles.valueFont}>
                  {printData?.Nominee?.NomineeName}
                </Text>
              </View>
              <View style={{ ...styles.infoChild, width: "45%" }}>
                <Text style={styles.labelFont}>Father/Husb:</Text>
                <Text style={styles.valueFont}>
                  {printData?.CustomerCnicNavigation?.GuardianName}
                </Text>{" "}
              </View>
            </View>
            <View style={styles.infoParent}>
              <View style={{ ...styles.infoChild, width: "45%" }}>
                <Text style={styles.labelFont}>CNIC:</Text>
                <Text style={styles.valueFont}>
                  {printData?.Nominee?.NomineeCnic}
                </Text>
              </View>
              <View style={{ ...styles.infoChild, width: "45%" }}>
                <Text style={styles.labelFont}>Ph:</Text>
                <Text style={styles.valueFont}>
                  {printData?.Nominee?.NomineeCellNoOne}
                </Text>
              </View>
            </View>
            <View style={styles.infoParent}>
              <View style={styles.infoChild}>
                <Text style={styles.labelFont}>Address:</Text>
                <Text style={styles.valueFont}>
                  {printData?.Nominee?.NomineeAddress}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              padding: "0",
              gap: "3px",
            }}
          >
            <View
              style={{
                width: "50%",
                paddingRight: "5px",

                padding: "3px",

                ...styles.section,
              }}
            >
              <View style={{ fontWeight: "bold", paddingBottom: "5px" }}>
                <Text>Property Inforamtion:</Text>
              </View>
              <View style={styles.infoParent}>
                <View style={{ ...styles.infoChild, width: "100%" }}>
                  <Text style={styles.labelFont}>Name:</Text>
                  <Text style={styles.valueFont}>
                    {localStorage.getItem("ProjectName") === "THE GRAND CITY"
                      ? "THE GRAND ICONIC CITY"
                      : localStorage.getItem("ProjectName")}
                  </Text>
                </View>{" "}
              </View>
              <View style={styles.infoParent}>
                <View style={{ ...styles.infoChild, width: "100%" }}>
                  <Text style={styles.labelFont}>Address:</Text>
                  <Text style={styles.valueFont}>
                    {printData?.Project?.ProjectLocation}
                  </Text>
                </View>{" "}
              </View>
              <View style={styles.infoParent}>
                <View style={{ ...styles.infoChild, width: "100%" }}>
                  <Text style={styles.labelFont}>kh #:</Text>
                  <Text style={styles.valueFont}>
                    {/* {printData?.Unit.LandPurchase?.KhewatNumber} */}
                    1010
                  </Text>
                </View>{" "}
              </View>
              <View style={styles.infoParent}>
                <View style={{ ...styles.infoChild, width: "100%" }}>
                  <Text style={styles.labelFont}>Sq #:</Text>
                  <Text style={styles.valueFont}>
                    {/* {printData?.Unit?.LandPurchase?.SqaNumber} */}
                    12
                  </Text>
                </View>{" "}
              </View>
            </View>
            <View
              style={{
                width: "50%",
                padding: "3px",
                ...styles.section,
                flexWrap: "wrap",
              }}
            >
              <View style={{ fontWeight: "bold", paddingBottom: "5px" }}>
                <Text>Unit Inforamtion:</Text>
              </View>
              <View style={styles.infoParent}>
                <View style={{ ...styles.infoChild, width: "45%" }}>
                  <Text style={styles.labelFont}>S/Date:</Text>
                  <Text style={styles.valueFont}>
                    {printData !== undefined &&
                    printData?.EntryDate !== undefined
                      ? moment(printData?.EntryDate.split("T")[0]).format(
                          "DD-MMM-YYYY"
                        )
                      : null}
                  </Text>
                </View>{" "}
                <View style={{ ...styles.infoChild, width: "45%" }}>
                  <Text style={styles.labelFont}>Res.:</Text>
                  <Text style={styles.valueFont}>
                    {printData !== undefined &&
                    printData.Unit?.Section !== undefined &&
                    printData.Unit?.Section?.Category.CategoryName !==
                      "COMMERCIAL" ? (
                      <input
                        type="checkbox"
                        checked
                        style={{ width: "20px", height: "20px" }}
                        className=" ml-2"
                      ></input>
                    ) : null}
                  </Text>
                  <Text style={styles.labelFont}>Comm.:</Text>
                  <Text style={styles.valueFont}>
                    {printData !== undefined &&
                    printData.Unit?.Section !== undefined &&
                    printData.Unit?.Section?.Category.CategoryName ===
                      "COMMERCIAL" ? (
                      <input
                        type="checkbox"
                        checked
                        style={{ width: "20px", height: "20px" }}
                        className=" ml-2"
                      ></input>
                    ) : null}
                  </Text>
                </View>{" "}
              </View>
              <View
                style={{
                  ...styles.infoParent,
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ ...styles.infoChild, width: "61%" }}>
                  <Text style={styles.labelFont}>Unit No:</Text>
                  <Text style={styles.valueFont}>
                    {printData?.Unit?.UnitName}
                  </Text>
                </View>{" "}
                <View style={{ ...styles.infoChild, width: "29%" }}>
                  <Text style={styles.labelFont}>Size:</Text>
                  <Text style={styles.valueFont}>
                    {printData !== undefined && printData.Unit !== undefined
                      ? printData.Unit.RatePerMarlaOrsqft == "MARLA"
                        ? printData.Unit.Marla +
                          "M " +
                          printData.Unit.Sarsai +
                          " S"
                        : printData.Unit.NetSqft.toFixed(2).toLocaleString() +
                          "SQFT"
                      : null}
                  </Text>
                </View>{" "}
              </View>

              <View style={styles.infoParent}>
                <View style={styles.infoChild}>
                  <Text style={styles.labelFont}>
                    {printData !== undefined &&
                    printData?.Unit !== undefined &&
                    printData?.Unit?.RatePerMarlaOrsqft == "MARLA"
                      ? "Per Marla:"
                      : "Per Sqft:"}
                  </Text>
                  <Text style={styles.valueFont}>
                    {printData !== undefined
                      ? printData?.SaleRate?.toLocaleString()
                      : null}
                  </Text>
                </View>{" "}
              </View>
              <View style={styles.infoParent}>
                <View style={styles.infoChild}>
                  <Text style={styles.labelFont}>Total Value:</Text>
                  <Text style={styles.valueFont}>
                    {printData?.TotalPrice?.toLocaleString()}
                  </Text>
                </View>{" "}
              </View>
            </View>
          </View>
          <View>
            <View style={{ fontWeight: "bold", paddingBottom: "5px" }}>
              <Text>Declaration:</Text>
            </View>
            <View style={styles.labelFont}>
              <Text style={styles.linePadding}>
                I/We do hereby confirm that I/We;
              </Text>
              <Text>
                {" "}
                a) have read and understood the terms and conditions and do
                hereby agree to abide by the same
              </Text>
              <Text style={styles.linePadding}>
                {" "}
                b) have read and understood the terms and conditions and do
                hereby agree to abide by the same.I/We do hereby further confirm
                that I/We shall abide by the existing and any future terms and
                conditions laid down by the Company,{" "}
                <Text style={{ fontSize: "12px" }}>
                  "Gravity Associates (Pvt.) Limited"
                </Text>
                .
              </Text>
              <Text style={styles.linePadding}>
                {" "}
                c) I/We do hereby further confirm that I/We shall pay the
                charges / fee as may be decided by the Company for Common and
                General sercives of the Society{" "}
                <Text style={{ fontSize: "12px" }}>
                  "{localStorage.getItem("ProjectName")}"
                </Text>
                .
              </Text>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <View style={{ width: "100%" }}>
              <View
                style={{
                  fontWeight: "bold",
                  paddingBottom: "5px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View>
                  <Text>Buyer:</Text>
                  <View
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "5px",
                      display: "flex",
                      flexDirection: "row",
                      padding: "5px",
                    }}
                  >
                    <Text style={{ fontWeight: "extrabold" }}>Sign </Text>
                    <Text
                      style={{
                        minWidth: "200px",
                        maxWidth: "200px",
                        borderBottom: "1px solid black",
                        paddingBottom: "10px",
                      }}
                    ></Text>
                  </View>
                  <View
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "5px",
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "20px",
                      padding: "5px",
                      paddingBottom: "10px",
                    }}
                  >
                    <Text style={{ fontWeight: "extrabold" }}>
                      Thumb Impresssion
                    </Text>
                    <Text
                      style={{
                        minWidth: "120px",
                        maxWidth: "120px",
                        borderBottom: "1px solid black",
                      }}
                    ></Text>
                  </View>
                </View>
                {printData?.CustomerCnicNavigation?.Image && (
                  <View style={{ paddingRight: "10px" }}>
                    <Image
                      style={{
                        width: "80px",

                        maxHeight: "100px",
                      }}
                      src={{
                        uri: printData?.CustomerCnicNavigation?.Image,
                        method: "GET",
                        headers: { "Cache-Control": "no-cache" },
                      }}
                    />
                  </View>
                )}
              </View>
            </View>
            <View style={{ width: "100%" }}>
              <View style={{ fontWeight: "bold", paddingBottom: "5px" }}>
                <Text style={{ fontWeight: "extrabold" }}>
                  Project Director
                </Text>
              </View>
              <View
                style={{
                  fontWeight: "bold",
                  paddingBottom: "5px",
                  display: "flex",
                  flexDirection: "row",
                  padding: "5px",
                }}
              >
                <Text style={{ fontWeight: "extrabold" }}>Sign </Text>
                <Text
                  style={{
                    minWidth: "200px",
                    maxWidth: "200px",
                    borderBottom: "1px solid black",
                  }}
                ></Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default ContractNewPrint;

import axios from 'axios';
import { toast } from "react-toastify";
export const GetAllTaxPayble = (data) => {

    return ({
        type: "get_allpayable",
        payload: data,
        loader: false
    })
}
export function getallTaxPayableTable() {
    return (dispatch) => {
        axios({
            url: `${window.$url}Vouchers/TaxPayables/Populate`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllTaxPayble(result.data.Data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}
export const GetAllTaxPaybleVoucher = (data) => {

    return ({
        type: "get_allpayableVoucher",
        payload: data,
        loader: false
    })
}
export function getallTaxPayableVoucherTable(data) {
    return (dispatch) => {
        axios({
            url: `${window.$url}Vouchers/TaxPayable/GetAll?ProjectId=${localStorage.getItem("ProjectId")}`,
            method: "Post",
            data: JSON.stringify(
                { data }
            ),
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllTaxPaybleVoucher(result.data.Data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BsCloudUpload } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";

import { getallMemberTable, getallMemberPopulate } from "../../actions/Crmactions/addteamaction";
import axios from "axios";
import { MdDeleteForever } from "react-icons/md";
import { toast } from "react-toastify";

import { FiEdit2 } from "react-icons/fi";






import Loader from "react-loader-spinner";
const MainMemberCom = () => {

    const showMemberTable = () => {
        stateshowmembers("MemberAdd");
    }
    const cancelMemberTable = () => {
        stateshowmembers("MemberTable");


    }
    const [showmembers, stateshowmembers] = useState("MemberTable");

    return (

        <>
            <div className="row ">
                <div className="dash_background col-12 p-0">
                    <div className="row ">

                        <div className="col-md-12">
                            {showmembers == "MemberTable" ? <MemberTable showMemberTable={showMemberTable} cancelMemberTable={cancelMemberTable} /> : <AddMember cancelMemberTable={cancelMemberTable} />}
                        </div>


                    </div>


                </div>
            </div>
        </>

    )
}
export default MainMemberCom;
export const AddMember = (props) => {
    const populateteams = useSelector(state => state.GetAllMemberPopulate);
    let dispatch = useDispatch();
    const [StateTeamId, SetTeamId] = useState(0);
    const [StateEmployeeId, SetEmployeeId] = useState(0);
    const [StateDesignationId, SetDesignationId] = useState(0);

    const [StateParentDesignationId, SetParentDesignationId] = useState(0);
    const [StateParentEmployee, SetParentEmployee] = useState([]);


    var teamsuggestionlist = [];

    const teamSelecttion = (e) => {
        const value = e.target.value;
        if (value == "") {
            ReactDOM.render(null, document.getElementById("teamdemo"));
        } else {
            let suggestions = [];
            if (value.length > 0) {
                const regex = new RegExp(`${value}`, "i");
                suggestions = populateteams.data.Teams
                    .sort()
                    .filter((v) =>
                        regex.test(v.TeamName !== null ? v.TeamName : null)
                    );
            }
            teamsuggestionlist = suggestions;

            if (teamsuggestionlist.length === 0) {
                ReactDOM.render(null, document.getElementById("teamdemo"));
            } else {
                ReactDOM.render(
                    <ul className="suggestbox">
                        {teamsuggestionlist.map((item) => (
                            <li className="listcss" onClick={() => teamsuggestionselected(item)}>
                                {item === undefined
                                    ? item
                                    : item.TeamName
                                }
                            </li>
                        ))}
                    </ul>,
                    document.getElementById("teamdemo")
                );
            }
        }
    };
    const onTextChangeTeamName = () => {
        var inputValue = document.getElementById("Team").value;
        var options = document.getElementById("teamnamelist").childNodes;
    
        let val;
        for (var i = 0; i < options.length; i++) {
          if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
            populateteams.data.Teams.forEach((x, idx) => {
              if (idx == options[i].attributes.index.value) {
                val = x;
              }
            });
            SetTeamId(val.CrmteamId);
        SetParentEmployee(val.CrmteamMembers);
    
            break;
          }
        }
      };
    const teamsuggestionselected = (val) => {

        document.getElementById("Team").value = val.TeamName;

        SetTeamId(val.CrmteamId);
        SetParentEmployee(val.CrmteamMembers);
        teamsuggestionlist = [];
        ReactDOM.render(null, document.getElementById("teamdemo"));
    };
    var employeesuggestionlist = [];

    const employeeSelecttion = (e) => {
        const value = e.target.value;
        if (value == "") {
            ReactDOM.render(null, document.getElementById("employeedemo"));
        } else {
            let suggestions = [];
            if (value.length > 0) {
                const regex = new RegExp(`${value}`, "i");
                suggestions = populateteams.data.Employees
                    .sort()
                    .filter((v) =>
                        regex.test(v.EmpName !== null ? v.EmpName : null)
                    );
            }
            employeesuggestionlist = suggestions;

            if (employeesuggestionlist.length === 0) {
                ReactDOM.render(null, document.getElementById("employeedemo"));
            } else {
                ReactDOM.render(
                    <ul className="suggestbox">
                        {employeesuggestionlist.map((item) => (
                            <li className="listcss" onClick={() => employeesuggestionselected(item)}>
                                {item === undefined
                                    ? item
                                    : item.EmpName
                                }
                            </li>
                        ))}
                    </ul>,
                    document.getElementById("employeedemo")
                );
            }
        }
    };
    const onTextChangeTeamEmployee = () => {
        var inputValue = document.getElementById("Employee").value;
        var options = document.getElementById("teamemployeelist").childNodes;
    
        let val;
        for (var i = 0; i < options.length; i++) {
          if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
            populateteams.data.Employees.forEach((x, idx) => {
              if (idx == options[i].attributes.index.value) {
                val = x;
              }
            });
            document.getElementById("Designation").value = val.EmpDesignation.DesignationName;
        SetDesignationId(val.EmpDesignation.DesignationId);
        // document.getElementById("Senior").value = val.EmpDesignation.SalesTeamHierarchyParentDesignation.length > 0 ? val.EmpDesignation.SalesTeamHierarchyParentDesignation[0].ParentDesignation.DesignationName : null;
        SetEmployeeId(val.EmployeeId)
        SetParentDesignationId(val.EmpDesignation.SalesTeamHierarchyParentDesignation.length > 0 ? val.EmpDesignation.SalesTeamHierarchyParentDesignation[0].ParentDesignation.DesignationId : null)
        
    
            break;
          }
        }
      };
    const employeesuggestionselected = (val) => {

        document.getElementById("Employee").value = val.EmpName;
        document.getElementById("Designation").value = val.EmpDesignation.DesignationName;
        SetDesignationId(val.EmpDesignation.DesignationId);
        // document.getElementById("Senior").value = val.EmpDesignation.SalesTeamHierarchyParentDesignation.length > 0 ? val.EmpDesignation.SalesTeamHierarchyParentDesignation[0].ParentDesignation.DesignationName : null;
        SetEmployeeId(val.EmployeeId)
        SetParentDesignationId(val.EmpDesignation.SalesTeamHierarchyParentDesignation.length > 0 ? val.EmpDesignation.SalesTeamHierarchyParentDesignation[0].ParentDesignation.DesignationId : null)
        teamsuggestionlist = [];
        ReactDOM.render(null, document.getElementById("employeedemo"));
    };
    const savedata = () => {
        if (document.getElementById("Employee").value === "") {
            toast.info("Select Team Name");
        }

        else if (document.getElementById("Employee").value === "") {
            toast.info("Select Employee");

        }



        else {



            document.getElementById("teamsave").disabled = true;
            axios({
                method: "post",
                url: `${window.$url}CRMTeams/Members/Add`,
                data: JSON.stringify({
                    CrmteamId: StateTeamId,
                    EmployeeId: StateEmployeeId,
                    DesignationId: StateDesignationId,
                    ParentMemberId: document.getElementById("Senior").options[document.getElementById("Senior").selectedIndex].dataset.id,
                }
                ),
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "content-type": "application/json",
                },
            }).then(
                (result) => {
                    document.getElementById("teamsave").disabled = false;
                    if (result.data.IsSuccess === true) {



                        toast.info(result.data.Message);
                        // props.setadvanceedit([]);
                        props.cancelMemberTable();
                    }
                    else {
                        toast.info(result.data.Message);
                    }


                },
                (error) => {
                    toast.info("Someting went wrong");
                    document.getElementById("teamsave").disabled = false;

                }
            );

        }
    };
    useEffect(() => {
        dispatch(getallMemberPopulate());
    }, [])

    return (
        <>
            <div className="row ">
                <div className="col-12  page_heading">
                    <h4 className="  text-center ">Add Team</h4>


                </div>
            </div>
            <div className="row ">
                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">Team Name</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">


                    <input type="text" list="teamnamelist" className="input_styling  ml-md-3" id="Team" placeholder="suggestion list" onChange={onTextChangeTeamName} ></input><span className="estaric">*</span>
                    <datalist id="teamnamelist">
            {populateteams?.data?.Teams?.map((x, idx) => (
              <option index={idx} value={x.TeamName}>
                {x.CrmteamId}
              </option>
            ))}
          </datalist>
                    <span id="teamdemo"></span>

                </div>
                <div className="col-md-2 mt-md-4   p-0">
                </div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">Employee.</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">


                    <input type="text" list="teamemployeelist" className="input_styling  ml-md-3" id="Employee" placeholder="suggestion list" onChange={onTextChangeTeamEmployee}></input><span className="estaric">*</span>
                    <datalist id="teamemployeelist">
            {populateteams?.data?.Employees?.map((x, idx) => (
              <option index={idx} value={x.EmpName}>
                {x.EmployeeId}
              </option>
            ))}
          </datalist>
                    <span id="employeedemo"></span>
                </div>

            </div>
            <div className="row ">
                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">Designation</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">


                    <input type="text" className="input_styling  ml-md-3" id="Designation" ></input><span className="estaric">*</span>


                </div>
                <div className="col-md-2 mt-md-4   p-0">
                </div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">Senior Employee</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">

                    <select className="input_styling  ml-md-3" id="Senior" >
                        <option>Select</option>
                        {StateParentEmployee.map((val) => (
                            parseInt(val.DesignationId) === parseInt(StateParentDesignationId) ? <option data-id={val.CrmteamMemberId}> {val.Employee.EmpName}
                            </option> : null
                        ))}
                    </select><span className="estaric">*</span>


                </div>

            </div>
            <div className=" float-right mt-md-4 ">
                <span>
                    <button
                        className="cancel_btn ml-2  "
                        onClick={props.cancelMemberTable}
                    >

                        Cancel
                    </button>
                </span>

                <span>
                    <button className="save_btn ml-2  " id="teamsave" onClick={savedata}>
                        <BsCloudUpload size="1.3em" />
                        Save
                    </button>
                </span>
            </div>
        </>
    )

}
export const MemberTable = (props) => {


    const tabledatastate = useSelector(state => state.GetAllMemberTable);


    const [filterloans, setfilterloans] = useState();



    let dispatch = useDispatch();

    var suggestionlist = [];

    const onTextChange = (e) => {
        const value = e.target.value;
        if (value === "") {

            setfilterloans(tabledatastate.data.TeamMembers);
        } else {
            let suggestions = [];
            if (value.length > 0) {
                const regex = new RegExp(`${value}`, "i");

                suggestions = filterloans
                    .sort()
                    .filter((val) =>
                        (regex.test(val.Crmteam !== null ? val.Crmteam.TeamName : null)));

            }
            suggestionlist = suggestions;
            setfilterloans(suggestionlist);
        }
    };


    useEffect(() => {

        dispatch(getallMemberTable());
    }, [dispatch]);
    useEffect(() => {
        setfilterloans(tabledatastate.data.TeamMembers);

    }, [tabledatastate]
    )
    return (

        <>



            <div className="row pt-3 ">
                <div className="col-md-1 pr-0 pl-0">


                    <label className="input_label  m-0">Search:</label>
                </div>
                <div className="col-md-3   pr-0 pl-0">


                    <input type="text " className="input_styling " placeholder="Team" onChange={onTextChange} ></input>
                </div>

                <div className="col-md-1 pr-0 pl-0">
                    {tabledatastate.loading ? <Loader type="ThreeDots"
                        color="green"
                        height={40}
                        width={40} /> : null}
                </div>
            </div>




            <div className="table-responsive mt-4">

                <table className="table table-borderless   m-0">
                    <thead>

                        <tr >

                            <th>Sr</th>

                            <th>Team</th>

                            <th>Member  Name</th>
                            <th>Designation</th>
                            <th>Parent</th>








                            <th className="text-center">
                                <button
                                    className="save_btn"
                                    onClick={() => {
                                        props.showMemberTable();
                                    }}
                                >
                                    New+
                                </button>

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filterloans !== undefined ? filterloans.map((val, index) => (

                            <tr key={index + 1}>
                                <td className="cur_sor" >{index + 1}</td>
                                <td className="cur_sor" >{val.Crmteam !== undefined ? val.Crmteam.TeamName : null}</td>
                                <td className="cur_sor" >{val.Employee !== undefined ? val.Employee.EmpName : null}</td>
                                <td className="cur_sor" >{val.Designation !== undefined ? val.Designation.DesignationName : null}</td>

                                <td className="cur_sor" >{val.ParentMember !== undefined ? val.ParentMember.Employee.EmpName : null}</td>

                                <td></td>







                            </tr>
                        )) : null}


                    </tbody>
                </table>
            </div>



        </>

    )
}

import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useReactToPrint } from "react-to-print";

import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { GrPrint } from "react-icons/gr";
import AdminSharePayableReportPrint from "../ReportPrints/AdminSharePayableReportPrint";
import AdminSharePayableReportPrintAll from "../ReportPrints/AdminSharePayableReportPrintAll";

const AdminSharePayableReport = () => {
  const [filterdata, setfilterdata] = useState([]);
  const [AdminSharePayments, setAdminSharePayments] = useState([]);

  const [csvData, setcsvData] = useState([]);
  const [ModalData, setModalData] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [dateLoading, setDateLoading] = useState(false);

  // print function
  const AllcomponentRef = useRef();

  const AllprintPaymentRec = useReactToPrint({
    content: () => AllcomponentRef.current,
  });

  const IndvidualcomponentRef = useRef();

  const IndividualprintPaymentRec = useReactToPrint({
    content: () => IndvidualcomponentRef.current,
    onAfterPrint: () => setModalData(null),
  });

  const onTextChange = (e) => {
    if (
      document.getElementById("affiliatefilter").value === "" &&
      document.getElementById("unitfilter").value === ""
    ) {
      setfilterdata(AdminSharePayments);
    } else {
      let suggestions = [];
      {
        const regex = new RegExp(
          document.getElementById("affiliatefilter").value,
          "i"
        );
        const regex1 = new RegExp(
          document.getElementById("unitfilter").value,
          "i"
        );

        suggestions = AdminSharePayments.sort().filter(
          (val) =>
            (document.getElementById("affiliatefilter").value === "" ||
              regex.test(val?.Reference)) &&
            (document.getElementById("unitfilter").value === "" ||
              regex1.test(val?.UnitName)
              )
        );
      }
      setfilterdata(suggestions);
    }
  };

  useEffect(() => {
    if (ModalData) {
      IndividualprintPaymentRec();
    }
  }, [ModalData]);

  function getAdminShareReport() {
    setDateLoading(true);
    document.getElementById("go").disabled = true;

    let pgurl = `${window.$url}Reports/AdminSharePayable`;
    axios({
      url: pgurl,
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
      data: JSON.stringify(
        fromDate && toDate
          ? {
              From: moment(fromDate).format("YYYY-MM-DD"),
              To: moment(toDate).format("YYYY-MM-DD"),
              ProjectId: localStorage.getItem("ProjectId")
                ? localStorage.getItem("ProjectId")
                : null,
            }
          : {
              ProjectId: localStorage.getItem("ProjectId")
                ? localStorage.getItem("ProjectId")
                : null,
            }
      ),
    }).then(
      (result) => {
        setDateLoading(false);
        document.getElementById("go").disabled = false;
        if (result.data.IsSuccess === true) {
          console.log(result.data);
          setAdminSharePayments(result?.data?.Data);
          setfilterdata(result?.data?.Data);
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        setDateLoading(false);
        document.getElementById("go").disabled = false;
        toast.info("Something went wrong!");
      }
    );
  }

  useEffect(() => {
    getAdminShareReport();
  }, []);

  let TotalValue = 0;
  let TotalAdminPayable = 0;
  let TotalPaid = 0;
  let TotalPayable = 0;

  if (filterdata !== undefined) {
    filterdata.map((val, index) => {
      TotalPaid += val?.Payments;
      TotalValue += parseFloat(val?.ContractPrice);
      TotalAdminPayable += val?.AdminShareAmount ? val?.AdminShareAmount : 0;
      TotalPayable +=
        (val?.AdminShareAmount ? val?.AdminShareAmount : 0) - val?.Payments;
    });
  }
  useEffect(() => {
    let arr = [];
    filterdata?.forEach((item) => {
      arr.push({
        Date: moment(item?.EntryDate).format("DD-MMM-YYYY"),
        Affiliate: item?.Reference,
        Unit: item?.UnitName,
        ["Total Value"]: item?.ContractPrice?.toLocaleString(),
        ["Admin Payable"]: item?.AdminShareAmount
          ? item?.AdminShareAmount.toLocaleString()
          : 0,
        Status: item?.ContractStatus,
      });
    });
    arr.push({
      Date: "",
      Affiliate: "",
      Unit: "Grand Total",
      ["Total Value"]: TotalValue,
      ["Admin Payable"]: TotalAdminPayable,
    });
    setcsvData(arr);
  }, [filterdata]);

  const savedata = () => {
    document.getElementById("go").disabled = true;
    // (fromDate === "" || toDate === "")
    if (
      (fromDate === "" && toDate !== "") ||
      (fromDate !== "" && toDate === "")
    ) {
      toast.info("Select From and To Date");
      document.getElementById("go").disabled = false;
    } else {
      getAdminShareReport();
      document.getElementById("go").disabled = false;
    }
  };

  console.log(filterdata);
  return (
    <>
      <div hidden>
        <AdminSharePayableReportPrint
          ref={IndvidualcomponentRef}
          Data={ModalData}
        />
      </div>

      <div hidden>
        <AdminSharePayableReportPrintAll
          ref={AllcomponentRef}
          Data={filterdata}
        />
      </div>

      <div className="row  app_font">
        <div className="dash_background col-12 pt-2">
          <div className="row mt-0">
     

            <div className="col-12 mt-0">
              <div className="row ">
                <div className="col-md-3 text-center  pr-0 pl-0">
                  <label className="input_label m-0">From:</label>
                  <input
                    type="date"
                    className="input_date ml-3"
                    id="fromdate"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  ></input>
                </div>
                <div className="col-md-3 text-center pr-0 pl-0">
                  <label className="input_label m-0">To:</label>
                  <input
                    type="date"
                    className="input_date ml-3"
                    id="todate"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </div>
                <div className="col-md-1 pr-0 pl-0">
                  <button className="btn_Go" onClick={savedata} id="go">
                    Go
                  </button>
                </div>
                <div className="col-md-1 pr-0 pl-0">
                  {dateLoading && (
                    <Loader
                      type="ThreeDots"
                      color="green"
                      height={40}
                      width={40}
                    />
                  )}
                </div>
              </div>
            </div>
            <div style={{backgroundColor:"#CAF5F5",height:"1px"}} className="col-12 m-2">

</div>
            <div className="col-12">
              <div className="row ">
                <div className="col-md-1 pr-0 pl-0">
                  <label className="input_label m-0">Search:</label>
                </div>

                <div className="col-md-3 pr-0 pl-0">
                  <input
                    type="text "
                    className="input_styling "
                    id="affiliatefilter"
                    onChange={onTextChange}
                    placeholder="Affiliate Name"
                  ></input>
                </div>

                <div className="col-md-3 pr-0 pl-0">
                  <input
                    type="text "
                    className="input_styling "
                    id="unitfilter"
                    onChange={onTextChange}
                    placeholder="Unit"
                  ></input>
                </div>
              </div>
            </div>
          </div>

          <div className=" col-12  table_height pr-0 pl-0">
            <div className="table-responsive mt-2">
              <table className="table table-borderless m-0">
                <thead>
                  <tr>
                    <th scope="col">Sr</th>
                    <th scope="col">Date</th>
                    <th scope="col">Affiliate</th>
                    <th scope="col">Unit</th>
                    <th scope="col">Total Value</th>
                    <th scope="col">Admin Payable</th>
                    <th scope="col">Status</th>
                    <th scope="col">Print</th>
                  </tr>
                </thead>
                <tbody>
                  {filterdata !== undefined
                    ? filterdata.map((x, id) => (
                        <>
                          <tr key={id}>
                            <td>{id + 1}</td>
                            <td>
                              {moment(x?.EntryDate).format("DD-MMM-YYYY")}
                            </td>
                            <td>{x?.Reference}</td>
                            <td>{x?.UnitName}</td>
                            <td>{x?.ContractPrice?.toLocaleString()}</td>
                            <td>
                              {x?.AdminShareAmount
                                ? x?.AdminShareAmount.toLocaleString()
                                : 0}
                            </td>
                            <td>{x?.ContractStatus}</td>
                            <td>
                              <GrPrint
                                className="reportcommissiondetails"
                                onClick={() => {
                                  setModalData(x);
                                }}
                              />
                            </td>
                          </tr>
                        </>
                      ))
                    : null}

                  <tr>
                    <td colSpan="2" className="text-center">
                      <b>Grand Total</b>
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      <b>{TotalValue?.toLocaleString()}</b>
                    </td>
                    <td>
                      <b>{TotalAdminPayable?.toLocaleString()}</b>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-6  text-right pr-0 pl-0">
            <button
              disabled={filterdata?.length > 0 ? false : true}
              className="print_btn float-left"
              onClick={() => {
                AllprintPaymentRec();
              }}
            >
              <TiPrinter size="1.3em" />
              Print
            </button>
            {filterdata?.length > 0 && (
              <button
                style={{ color: "black !important" }}
                className="print_btn ml-2 float-left"
              >
                <CSVLink
                  style={{ color: "black" }}
                  filename="Commission Payments"
                  data={csvData}
                >
                  Download CSV
                </CSVLink>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSharePayableReport;

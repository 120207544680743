
import React, { useState, useEffect, useRef } from "react";
import { BsCloudUpload } from "react-icons/bs";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getallEmployees, GetEmployeesData, GetAllEmployees, getalldesignation } from "../../actions/HRactions/getemployees";
import Loader from "react-loader-spinner";

import { FiEdit2 } from "react-icons/fi";
import { TiPrinter } from "react-icons/ti";
import { useReactToPrint } from "react-to-print";
import { EmployeePrint } from "./employeeprint";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
const MainAddEmployeeComp = () => {

    const showemployeetable = () => {
        stateshowemployee("Employee");
    }
    const cancelemployeetable = () => {
        stateshowemployee("EmployeeTable");
    }
    const [showemployee, stateshowemployee] = useState("EmployeeTable");

    return (

        <>
            <div className="row ">
                <div className="dash_background col-12 p-0">
                    <div className="row ">

                        <div className="col-md-12">
                            {showemployee == "EmployeeTable" ? <EmployeeTableComp showemployeetable={showemployeetable} cancelemployeetable={cancelemployeetable} /> : <AddEmployee cancelemployeetable={cancelemployeetable} />}
                        </div>


                    </div>


                </div>
            </div>
        </>

    )
}
export default MainAddEmployeeComp;
export const AddEmployee = (props) => {
    const employeedesignationstate = useSelector(state => state.getEmpDesignation);

    const employeedatastate = useSelector(state => state.getEmployeedatastate);


    let dispatch = useDispatch();
    let populatedata = {};
    if (employeedatastate.employeedata !== undefined || employeedatastate.employeedata !== null) {
        populatedata = employeedatastate.employeedata;
    } else {
        populatedata = employeedatastate;
    }
    function emptyfields() {
        document.getElementById("Country").selectedIndex = 0;
        document.getElementById("City").selectedIndex = 0;
        document.getElementById("Religion").selectedIndex = 0;
        document.getElementById("Designation").selectedIndex = 0;
        document.getElementById("Gender").selectedIndex = 0;
        document.getElementById("Maritalstatus").selectedIndex = 0;
        document.getElementById("Phone").value = "";
        document.getElementById("Address").value = "";
        document.getElementById("DOB").value = "";
        document.getElementById("Joiningdate").value = "";
        document.getElementById("employeeName").value = "";
        document.getElementById("employeeFather").value = "";
        // document.getElementById("comper").value = "";
        document.getElementById("CNIC").value = "";
        document.getElementById("Cell1").value = "";
        document.getElementById("Cell2").value = "";
        document.getElementById("Email").value = "";

    }
    function addEmployee() {
        let regexp = new RegExp('^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$');
        let regexphone = new RegExp('^[0-9+]{4}-[0-9+]{7}$');
        let regexptcl = new RegExp('^[0-9+]{3}-[0-9+]{7}$');

        let regexcompany = new RegExp('^[0-9+]{7}$');

        let check = document.getElementById("CNIC").value;
        let checkcell1 = document.getElementById("Cell1").value;
        let checkcell2 = document.getElementById("Cell2").value;
        let checkptcl = document.getElementById("Phone").value;
        if (document.getElementById("employeeName").value === "") {
            toast.info("Name is required");
        }
        else if (document.getElementById("employeeFather").value === "") { toast.info("Employee Father  is required") }
        else if (document.getElementById("CNIC").value === "") { toast.info("CNIC is required") }
        else if (!regexp.test(check)) {
            toast.info('Invalid CNIC. Format should be like xxxxx-xxxxxxx-x');


        }
        else if (document.getElementById("Address").value === "") { toast.info("Address is required") }
        else if (document.getElementById("Designation").options[document.getElementById("Designation").selectedIndex].value === "Select") { toast.info("Select Designation") }
        else if (document.getElementById("DOB").value === "") { toast.info("DOB is required") }
        else if (document.getElementById("Joiningdate").value === "") { toast.info("Joining date is required") }
        else if (document.getElementById("Address").value === "") { toast.info("Address is required") }

        else if (!regexptcl.test(checkptcl) && document.getElementById("Phone").value !== "") {
            toast.info('Invalid Phone. Format should be like xxx-xxxxxxx');


        }
        else if (document.getElementById("Cell1").value === "") { toast.info("cell no.1  is required") }
        else if (!regexphone.test(checkcell1)) {
            toast.info('Invalid Cell No. 1 Format should be like xxxx-xxxxxxx');


        }
        else if (!regexphone.test(checkcell2) && document.getElementById("Cell2").value !== "") {
            toast.info('Invalid Cell No. 2 Format should be like xxxx-xxxxxxx');


        }
        else if (document.getElementById("City").selectedIndex === 0) { toast.info("Select any city") }
        else if (document.getElementById("Country").selectedIndex === 0) { toast.info("Select any country") }
        // else if (document.getElementById("Designation").options[document.getElementById("Designation").selectedIndex].value === "Sales Manager") {
        //     if (document.getElementById("comper").value === "") { toast.info("add commission percentage") } 
        // }
        else {
            var Employee = employeedatastate;
            Employee.EmployeeId = populatedata.EmployeeId;
            Employee.EmpName = document.getElementById("employeeName").value;
            Employee.EmpFatherName = document.getElementById("employeeFather").value;
            Employee.EmpDesignationId = document.getElementById("Designation").options[document.getElementById("Designation").selectedIndex].dataset.id;
            Employee.EmpReligon = document.getElementById("Religion").options[document.getElementById("Religion").selectedIndex].value;
            Employee.EmpGender = document.getElementById("Gender").options[document.getElementById("Gender").selectedIndex].value;
            Employee.EmpMaritalStatus = document.getElementById("Maritalstatus").options[document.getElementById("Maritalstatus").selectedIndex].value;
            Employee.EmpDOB = document.getElementById("DOB").value;
            Employee.EmpJoiningDate = document.getElementById("Joiningdate").value;

            Employee.EmpAddress = document.getElementById("Address").value;
            Employee.EmpCnic = document.getElementById("CNIC").value;
            Employee.EmpCellNo1 = document.getElementById("Cell1").value;
            Employee.EmpCellNo2 = document.getElementById("Cell2").value;
            Employee.EmpPhoneNo = document.getElementById("Phone").value;
            Employee.EmpEmail = document.getElementById("Email").value;


            Employee.EmpCity = document.getElementById(
                "City"
            ).options[
                document.getElementById("City").selectedIndex
            ].value;
            Employee.EmpCountry = document.getElementById(
                "Country"
            ).options[
                document.getElementById("Country").selectedIndex
            ].value;


            document.getElementById("employeesave").disabled = true;
            axios({
                url: `${window.$url}Employees/Add`,
                method: "Post",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "content-type": "application/json",
                },
                data: JSON.stringify(
                    {

                        Employee,
                        // ManagerCommissionPer: document.getElementById("comper").value !== "" ? document.getElementById("comper").value : 0
                    }
                ),
            }).then(
                (result) => {
                    document.getElementById("employeesave").disabled = false;
                    if (result.data.IsSuccess === true) {
                        emptyfields();
                        dispatch(getallEmployees());
                        props.cancelemployeetable()

                        toast.info(result.data.Message);
                    } else {
                        toast.info(result.data.Message);
                    }




                },
                (error) => {

                    toast.info("Something went wrong!");
                    document.getElementById("employeesave").disabled = false;
                }
            );



        }
    }


    return (
        <>
            <div className="row ">
                <div className="col-md-12  text-left page_heading">
                    <h4 className="m-0">Add Employee</h4>
                </div>
            </div>
            <div className="row ">
                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0 " >Name :</label>
                </div>
                <div className="col-md-3 mt-md-4  p-0">
                    <input type="text" className="input_styling ml-md-3" id="employeeName" ke={populatedata.EmpName} defaultValue={populatedata.EmpName} /><span className="estaric">*</span>



                </div>
                <div className="col-md-2 mt-md-4   p-0">
                </div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0 " >Father Name :</label>
                </div>
                <div className="col-md-3 mt-md-4  p-0">
                    <input type="text" className="input_styling ml-md-3" id="employeeFather" defaultValue={populatedata.EmpFatherName} /><span className="estaric">*</span>



                </div>



            </div>
            <div className="row ">


                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">CNIC</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">

                    <input type="text" className="input_styling ml-md-3 " id="CNIC" placeholder="xxxxx-xxxxxxx-x" defaultValue={populatedata.EmpCnic} /><span className="estaric">*</span>

                </div>
                <div className="col-md-2 mt-md-4   p-0">
                </div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">Email</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">

                    <input type="email" className="input_styling ml-md-3 " id="Email" defaultValue={populatedata.EmpEmail} /><span className="estaric">*</span>

                </div>


            </div>
            <div className="row ">


                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">Designation</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">

                    <select className="input_styling ml-md-3 " id="Designation"
                    // onChange={(e) => {
                    //     if (e.target.value === "Sales Manager") {
                    //         document.getElementById("showhide").style.display = "block"
                    //     } else {
                    //         document.getElementById("showhide").style.display = "none"
                    //     }
                    // }}
                    >


                        {populatedata.EmpDesignation !== null && populatedata.EmpDesignation !== undefined ?
                            <option selected > {populatedata.EmpDesignation.DesignationName}</option> :
                            <option selected>Select</option>
                        }
                        {employeedesignationstate.data.map((val, index) => (
                            <option key={index} data-id={val.DesignationId}>{val.DesignationName}</option>
                        ))}



                    </select><span className="estaric">*</span>

                </div>
                <div className="col-md-2 mt-md-4   p-0">
                </div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">Religion</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">

                    <select className="input_styling ml-md-3 " id="Religion" >

                        {populatedata.EmpReligon !== null && populatedata.EmpReligon !== undefined ?
                            <option selected > {populatedata.EmpReligon}</option> :
                            <option selected>Select</option>
                        }
                        <option> Islam</option>
                        <option> Christain</option>
                        <option>Others</option>


                    </select>
                </div>


            </div>
            <div className="row ">


                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">DOB</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">

                    <input type="date" className="input_styling ml-md-3 " id="DOB" defaultValue={populatedata.EmpDob !== undefined && populatedata.EmpDob !== null ? moment(populatedata.EmpDob.split("T")[0]).format("DD-MMM-YYYY") : populatedata.EmpDob} /><span className="estaric">*</span>


                </div>
                <div className="col-md-2 mt-md-4   p-0">
                </div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">Gender</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">

                    <select className="input_styling ml-md-3 " id="Gender" >

                        {populatedata.EmpGender !== null && populatedata.EmpGender !== undefined ?
                            <option selected > {populatedata.EmpGender}</option> :
                            <option selected>Select</option>
                        }
                        <option> Male</option>
                        <option> Female</option>



                    </select><span className="estaric">*</span>
                </div>


            </div>
            <div className="row ">


                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">Joining Date</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">

                    <input type="date" className="input_styling ml-md-3 " id="Joiningdate" defaultValue={populatedata.EmpJoiningDate !== undefined && populatedata.EmpJoiningDate !== null ? moment(populatedata.EmpJoiningDate.split("T")[0]).format("DD-MMM-YYYY") : populatedata.EmpJoiningDate} /><span className="estaric">*</span>


                </div>
                <div className="col-md-2 mt-md-4   p-0">
                </div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">Marital Status</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">

                    <select className="input_styling ml-md-3 " id="Maritalstatus" >
                        <option> Select</option>
                        {populatedata.EmpMaritalStatus !== null && populatedata.EmpMaritalStatus !== undefined ?
                            <option selected > {populatedata.EmpMaritalStatus}</option> :
                            <option selected>Select</option>
                        }
                        <option> Married</option>
                        <option> UnMarried</option>



                    </select>
                </div>


            </div>
            <div className="row ">
                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0 " >City</label>
                </div>
                <div className="col-md-3 mt-md-4  p-0">
                    <select className="input_styling ml-md-3" id="City" >
                        <option selected>Select</option>
                        {populatedata.EmpCity !== null && populatedata.EmpCity !== undefined ?
                            <option selected > {populatedata.EmpCity}</option> :
                            <option selected>Select</option>}

                        <option value="Islamabad">Islamabad</option>
                        <option value="" disabled>Punjab Cities</option>
                        <option value="Ahmed Nager Chatha">Ahmed Nager Chatha</option>
                        <option value="Ahmadpur East">Ahmadpur East</option>
                        <option value="Ali Khan Abad">Ali Khan Abad</option>
                        <option value="Alipur">Alipur</option>
                        <option value="Arifwala">Arifwala</option>
                        <option value="Attock">Attock</option>
                        <option value="Bhera">Bhera</option>
                        <option value="Bhalwal">Bhalwal</option>
                        <option value="Bahawalnagar">Bahawalnagar</option>
                        <option value="Bahawalpur">Bahawalpur</option>
                        <option value="Bhakkar">Bhakkar</option>
                        <option value="Burewala">Burewala</option>
                        <option value="Chillianwala">Chillianwala</option>
                        <option value="Chakwal">Chakwal</option>
                        <option value="Chichawatni">Chichawatni</option>
                        <option value="Chiniot">Chiniot</option>
                        <option value="Chishtian">Chishtian</option>
                        <option value="Daska">Daska</option>
                        <option value="Darya Khan">Darya Khan</option>
                        <option value="Dera Ghazi Khan">Dera Ghazi Khan</option>
                        <option value="Dhaular">Dhaular</option>
                        <option value="Dina">Dina</option>
                        <option value="Dinga">Dinga</option>
                        <option value="Dipalpur">Dipalpur</option>
                        <option value="Faisalabad">Faisalabad</option>
                        <option value="Ferozewala">Ferozewala</option>
                        <option value="Fateh Jhang">Fateh Jang</option>
                        <option value="Ghakhar Mandi">Ghakhar Mandi</option>
                        <option value="Gojra">Gojra</option>
                        <option value="Gujranwala">Gujranwala</option>
                        <option value="Gujrat">Gujrat</option>
                        <option value="Gujar Khan">Gujar Khan</option>
                        <option value="Hafizabad">Hafizabad</option>
                        <option value="Haroonabad">Haroonabad</option>
                        <option value="Hasilpur">Hasilpur</option>
                        <option value="Haveli Lakha">Haveli Lakha</option>
                        <option value="Jatoi">Jatoi</option>
                        <option value="Jalalpur">Jalalpur</option>
                        <option value="Jattan">Jattan</option>
                        <option value="Jampur">Jampur</option>
                        <option value="Jaranwala">Jaranwala</option>
                        <option value="Jhang">Jhang</option>
                        <option value="Jhelum">Jhelum</option>
                        <option value="Kalabagh">Kalabagh</option>
                        <option value="Karor Lal Esan">Karor Lal Esan</option>
                        <option value="Kasur">Kasur</option>
                        <option value="Kamalia">Kamalia</option>
                        <option value="Kamoke">Kamoke</option>
                        <option value="Khanewal">Khanewal</option>
                        <option value="Khanpur">Khanpur</option>
                        <option value="Kharian">Kharian</option>
                        <option value="Khushab">Khushab</option>
                        <option value="Kot Addu">Kot Addu</option>
                        <option value="Jauharabad">Jauharabad</option>
                        <option value="Lahore">Lahore</option>
                        <option value="Lalamusa">Lalamusa</option>
                        <option value="Layyah">Layyah</option>
                        <option value="Liaquat Pur">Liaquat Pur</option>
                        <option value="Lodhran">Lodhran</option>
                        <option value="Malakwal">Malakwal</option>
                        <option value="Mamoori">Mamoori</option>
                        <option value="Mailsi">Mailsi</option>
                        <option value="Mandi Bahauddin">Mandi Bahauddin</option>
                        <option value="Mian Channu">Mian Channu</option>
                        <option value="Mianwali">Mianwali</option>
                        <option value="Multan">Multan</option>
                        <option value="Murree">Murree</option>
                        <option value="Muridke">Muridke</option>
                        <option value="Mianwali Bangla">Mianwali Bangla</option>
                        <option value="Muzaffargarh">Muzaffargarh</option>
                        <option value="Narowal">Narowal</option>
                        <option value="Nankana Sahib">Nankana Sahib</option>
                        <option value="Okara">Okara</option>
                        <option value="Renala Khurd">Renala Khurd</option>
                        <option value="Pakpattan">Pakpattan</option>
                        <option value="Pattoki">Pattoki</option>
                        <option value="Pir Mahal">Pir Mahal</option>
                        <option value="Qaimpur">Qaimpur</option>
                        <option value="Qila Didar Singh">Qila Didar Singh</option>
                        <option value="Rabwah">Rabwah</option>
                        <option value="Raiwind">Raiwind</option>
                        <option value="Rajanpur">Rajanpur</option>
                        <option value="Rahim Yar Khan">Rahim Yar Khan</option>
                        <option value="Rawalpindi">Rawalpindi</option>
                        <option value="Sadiqabad">Sadiqabad</option>
                        <option value="Safdarabad">Safdarabad</option>
                        <option value="Sahiwal">Sahiwal</option>
                        <option value="Sangla Hill">Sangla Hill</option>
                        <option value="Sarai Alamgir">Sarai Alamgir</option>
                        <option value="Sargodha">Sargodha</option>
                        <option value="Shakargarh">Shakargarh</option>
                        <option value="Sheikhupura">Sheikhupura</option>
                        <option value="Sialkot">Sialkot</option>
                        <option value="Sohawa">Sohawa</option>
                        <option value="Soianwala">Soianwala</option>
                        <option value="Siranwali">Siranwali</option>
                        <option value="Talagang">Talagang</option>
                        <option value="Taxila">Taxila</option>
                        <option value="Toba Tek Singh">Toba Tek Singh</option>
                        <option value="Vehari">Vehari</option>
                        <option value="Wah Cantonment">Wah Cantonment</option>
                        <option value="Wazirabad">Wazirabad</option>
                        <option value="" disabled>Sindh Cities</option>
                        <option value="Badin">Badin</option>
                        <option value="Bhirkan">Bhirkan</option>
                        <option value="Rajo Khanani">Rajo Khanani</option>
                        <option value="Chak">Chak</option>
                        <option value="Dadu">Dadu</option>
                        <option value="Digri">Digri</option>
                        <option value="Diplo">Diplo</option>
                        <option value="Dokri">Dokri</option>
                        <option value="Ghotki">Ghotki</option>
                        <option value="Haala">Haala</option>
                        <option value="Hyderabad">Hyderabad</option>
                        <option value="Islamkot">Islamkot</option>
                        <option value="Jacobabad">Jacobabad</option>
                        <option value="Jamshoro">Jamshoro</option>
                        <option value="Jungshahi">Jungshahi</option>
                        <option value="Kandhkot">Kandhkot</option>
                        <option value="Kandiaro">Kandiaro</option>
                        <option value="Karachi">Karachi</option>
                        <option value="Kashmore">Kashmore</option>
                        <option value="Keti Bandar">Keti Bandar</option>
                        <option value="Khairpur">Khairpur</option>
                        <option value="Kotri">Kotri</option>
                        <option value="Larkana">Larkana</option>
                        <option value="Matiari">Matiari</option>
                        <option value="Mehar">Mehar</option>
                        <option value="Mirpur Khas">Mirpur Khas</option>
                        <option value="Mithani">Mithani</option>
                        <option value="Mithi">Mithi</option>
                        <option value="Mehrabpur">Mehrabpur</option>
                        <option value="Moro">Moro</option>
                        <option value="Nagarparkar">Nagarparkar</option>
                        <option value="Naudero">Naudero</option>
                        <option value="Naushahro Feroze">Naushahro Feroze</option>
                        <option value="Naushara">Naushara</option>
                        <option value="Nawabshah">Nawabshah</option>
                        <option value="Nazimabad">Nazimabad</option>
                        <option value="Qambar">Qambar</option>
                        <option value="Qasimabad">Qasimabad</option>
                        <option value="Ranipur">Ranipur</option>
                        <option value="Ratodero">Ratodero</option>
                        <option value="Rohri">Rohri</option>
                        <option value="Sakrand">Sakrand</option>
                        <option value="Sanghar">Sanghar</option>
                        <option value="Shahbandar">Shahbandar</option>
                        <option value="Shahdadkot">Shahdadkot</option>
                        <option value="Shahdadpur">Shahdadpur</option>
                        <option value="Shahpur Chakar">Shahpur Chakar</option>
                        <option value="Shikarpaur">Shikarpaur</option>
                        <option value="Sukkur">Sukkur</option>
                        <option value="Tangwani">Tangwani</option>
                        <option value="Tando Adam Khan">Tando Adam Khan</option>
                        <option value="Tando Allahyar">Tando Allahyar</option>
                        <option value="Tando Muhammad Khan">Tando Muhammad Khan</option>
                        <option value="Thatta">Thatta</option>
                        <option value="Umerkot">Umerkot</option>
                        <option value="Warah">Warah</option>
                        <option value="" disabled>Khyber Cities</option>
                        <option value="Abbottabad">Abbottabad</option>
                        <option value="Adezai">Adezai</option>
                        <option value="Alpuri">Alpuri</option>
                        <option value="Akora Khattak">Akora Khattak</option>
                        <option value="Ayubia">Ayubia</option>
                        <option value="Banda Daud Shah">Banda Daud Shah</option>
                        <option value="Bannu">Bannu</option>
                        <option value="Batkhela">Batkhela</option>
                        <option value="Battagram">Battagram</option>
                        <option value="Birote">Birote</option>
                        <option value="Chakdara">Chakdara</option>
                        <option value="Charsadda">Charsadda</option>
                        <option value="Chitral">Chitral</option>
                        <option value="Daggar">Daggar</option>
                        <option value="Dargai">Dargai</option>
                        <option value="Darya Khan">Darya Khan</option>
                        <option value="Dera Ismail Khan">Dera Ismail Khan</option>
                        <option value="Doaba">Doaba</option>
                        <option value="Dir">Dir</option>
                        <option value="Drosh">Drosh</option>
                        <option value="Hangu">Hangu</option>
                        <option value="Haripur">Haripur</option>
                        <option value="Karak">Karak</option>
                        <option value="Kohat">Kohat</option>
                        <option value="Kulachi">Kulachi</option>
                        <option value="Lakki Marwat">Lakki Marwat</option>
                        <option value="Latamber">Latamber</option>
                        <option value="Madyan">Madyan</option>
                        <option value="Mansehra">Mansehra</option>
                        <option value="Mardan">Mardan</option>
                        <option value="Mastuj">Mastuj</option>
                        <option value="Mingora">Mingora</option>
                        <option value="Nowshera">Nowshera</option>
                        <option value="Paharpur">Paharpur</option>
                        <option value="Pabbi">Pabbi</option>
                        <option value="Peshawar">Peshawar</option>
                        <option value="Saidu Sharif">Saidu Sharif</option>
                        <option value="Shorkot">Shorkot</option>
                        <option value="Shewa Adda">Shewa Adda</option>
                        <option value="Swabi">Swabi</option>
                        <option value="Swat">Swat</option>
                        <option value="Tangi">Tangi</option>
                        <option value="Tank">Tank</option>
                        <option value="Thall">Thall</option>
                        <option value="Timergara">Timergara</option>
                        <option value="Tordher">Tordher</option>
                        <option value="" disabled>Balochistan Cities</option>
                        <option value="Awaran">Awaran</option>
                        <option value="Barkhan">Barkhan</option>
                        <option value="Chagai">Chagai</option>
                        <option value="Dera Bugti">Dera Bugti</option>
                        <option value="Gwadar">Gwadar</option>
                        <option value="Harnai">Harnai</option>
                        <option value="Jafarabad">Jafarabad</option>
                        <option value="Jhal Magsi">Jhal Magsi</option>
                        <option value="Kacchi">Kacchi</option>
                        <option value="Kalat">Kalat</option>
                        <option value="Kech">Kech</option>
                        <option value="Kharan">Kharan</option>
                        <option value="Khuzdar">Khuzdar</option>
                        <option value="Killa Abdullah">Killa Abdullah</option>
                        <option value="Killa Saifullah">Killa Saifullah</option>
                        <option value="Kohlu">Kohlu</option>
                        <option value="Lasbela">Lasbela</option>
                        <option value="Lehri">Lehri</option>
                        <option value="Loralai">Loralai</option>
                        <option value="Mastung">Mastung</option>
                        <option value="Musakhel">Musakhel</option>
                        <option value="Nasirabad">Nasirabad</option>
                        <option value="Nushki">Nushki</option>
                        <option value="Panjgur">Panjgur</option>
                        <option value="Pishin Valley">Pishin Valley</option>
                        <option value="Quetta">Quetta</option>
                        <option value="Sherani">Sherani</option>
                        <option value="Sibi">Sibi</option>
                        <option value="Sohbatpur">Sohbatpur</option>
                        <option value="Washuk">Washuk</option>
                        <option value="Zhob">Zhob</option>
                        <option value="Ziarat">Ziarat</option>
                    </select><span className="estaric">*</span>




                </div>
                <div className="col-md-2 mt-md-4   p-0">
                </div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">Country</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">
                    <select id="Country" className="input_styling ml-md-3" name="country">
                        <option selected>Select</option>
                        {populatedata.EmpCountry !== null && populatedata.EmpCountry !== undefined ?
                            <option selected > {populatedata.EmpCountry}</option> :
                            <option value="Pakistan">Pakistan</option>

                        }


                    </select><span className="estaric">*</span>

                </div>

            </div>

            <div className="row ">
                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0 " >Cell No 1:</label>
                </div>
                <div className="col-md-3 mt-md-4  p-0">
                    <input type="text" className="input_styling ml-md-3" id="Cell1" placeholder="xxxx-xxxxxxx" defaultValue={populatedata.EmpCellNo1} /><span className="estaric">*</span>



                </div>
                <div className="col-md-2 mt-md-4   p-0">
                </div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">Cell No 2: </label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">

                    <input type="text" className="input_styling ml-md-3 " id="Cell2" placeholder="xxxx-xxxxxxx" defaultValue={populatedata.EmpCellNo2} />

                </div>

            </div>
            <div className="row ">
                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">Phone No </label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">

                    <input type="text" className="input_styling ml-md-3 " id="Phone" placeholder="xxx-xxxxxxx" defaultValue={populatedata.EmpPhoneNo} />

                </div>
                <div className="col-md-2 mt-md-4   p-0">
                </div>

                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0 " >Address:</label>
                </div>
                <div className="col-md-3 mt-4   p-0">
                    <textarea className="input_address ml-md-3 " id="Address" defaultValue={populatedata.EmpAddress} ></textarea><span className="estaric">*</span>

                </div>


            </div>
            {/* {populatedata.Manager !== undefined && populatedata.Manager[0] !== undefined ?
                <span >
                    <div className="row " >
                        <div className="col-md-2 mt-md-4  text-left p-0">
                            <label className="input_label m-0">Commission%</label>
                        </div>
                        <div className="col-md-3 mt-md-4   p-0">

                            <input type="number" className="input_styling ml-md-3 " id="comper" placeholder="0-100" onChange={(e) => { if (e.target.value > 100) { toast.info("percentage should be between 0-100") } }} defaultValue={populatedata.Manager !== undefined && populatedata.Manager[0] !== undefined ? populatedata.Manager[0].CommissionPercentage : null} />

                        </div>



                    </div>
                </span> : <span style={{ display: "none" }} id="showhide">
                    <div className="row " >
                        <div className="col-md-2 mt-md-4  text-left p-0">
                            <label className="input_label m-0">Commission%</label>
                        </div>
                        <div className="col-md-3 mt-md-4   p-0">

                            <input type="number" className="input_styling ml-md-3 " id="comper" placeholder="0-100" onChange={(e) => { if (e.target.value > 100) { toast.info("percentage should be between 0-100") } }} defaultValue={populatedata.Manager !== undefined && populatedata.Manager[0] !== undefined ? populatedata.Manager[0].CommissionPercentage : null} />

                        </div>



                    </div>
                </span>} */}


            <div className="row pr-md-2 pt-md-4">

                <div className=" col-12 pr-0 pl-0  text-right ">
                    <span>
                        <button
                            className="cancel_btn ml-2  "
                            onClick={() => { props.cancelemployeetable(); emptyfields(); }}
                        >

                            Cancel
                        </button>
                    </span>
                    <span><button className="save_btn  ml-2" id="employeesave" onClick={addEmployee} ><BsCloudUpload size="1.3em" />Save</button></span>
                </div>
            </div>
            <ToastContainer theme="dark" autoClose={3000} />
        </>
    );
}
export const EmployeeTableComp = (props) => {
    const [employeedata, setemployeedata] = useState({});
    const resultallemployees = useSelector(state => state.getAllEmployees);
    const employeedatastate = useSelector(state => state.getEmployeedatastate);
    let dispatch = useDispatch();
    // print function
    const componentRef = useRef();

    const printPaymentRec = useReactToPrint({
        content: () => componentRef.current,
    });
    //Table Data fiter

    var suggestionlist = [];

    const onTextChange = (e) => {
        const value = e.target.value;
        if (value === "") {

            dispatch(getallEmployees());
        } else {
            let suggestions = [];
            if (value.length > 0) {
                const regex = new RegExp(`${value}`, "i");

                suggestions = resultallemployees.data
                    .sort()
                    .filter((val) =>
                        (regex.test(val.EmpName) || regex.test(val.EmpCnic)));

            }
            suggestionlist = suggestions;
            dispatch(GetAllEmployees(suggestionlist));
        }
    };

    useEffect(() => {
        dispatch(getallEmployees())
        dispatch(getalldesignation())

    }, [dispatch])

    function showmodal(data) {
        document.getElementById("mymodal").style.display = "block";
        setemployeedata(data);
    }
    function closemodal() {
        document.getElementById("mymodal").style.display = "none";

    }
  
    return (
        <>
            <div hidden>
                <EmployeePrint
                    ref={componentRef}

                    printData={resultallemployees}

                /></div>
            <div className="row pt-3 ">
                <div className="col-md-1 pr-0 pl-0">


                    <label className="input_label  m-0">Search:</label>
                </div>
                <div className="col-md-3  pr-0 pl-0">


                    <input type="text " className="input_styling " onChange={onTextChange} placeholder="Employee/CNIC "></input>
                </div>


                <div className="col-md-1 pr-0 pl-0">
                    {resultallemployees.loading ? <Loader type="ThreeDots"
                        color="green"
                        height={40}
                        width={40} /> : null}
                </div>
            </div>


            <div className="row ">
                <div className=" col-12  table_height pr-0 pl-0">
                    <div className="table-responsive mt-4">

                        <table className="table table-borderless table-hover m-0">
                            <thead>

                                <tr >

                                    <th>Sr</th>
                                    <th>Name</th>

                                    <th>Father Name</th>
                                    <th>CNIC</th>
                                    <th>Designation.</th>
                                    <th>City</th>



                                    <th className="text-center">
                                        <button
                                            className="save_btn"
                                            onClick={() => {
                                                props.showemployeetable();
                                            }}
                                        >
                                            New+
                                        </button>

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {resultallemployees.data.map((val, index) => (
                                    <tr key={index} onClick={() => { showmodal(val) }}>
                                        <td>{index + 1}</td>
                                        <td>{val.EmpName}</td>

                                        <td>{val.EmpFatherName}</td>
                                        <td>{val.EmpCnic}</td>


                                        <td>{val.EmpDesignation.DesignationName}</td>
                                        <td>{val.EmpCity}</td>
                                        <td className="text-center">
                                            <FiEdit2 size="1em" />
                                        </td>

                                    </tr>
                                ))}


                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-6  text-right pr-0 pl-0">
                    <button className="print_btn float-left" onClick={() => { printPaymentRec(); }}><TiPrinter size="1.3em" />Print</button>


                </div>
            </div>
            <div id="mymodal" class="modal">

                <div class="modal-content-cat">
                    <div className="row">
                        <div className="col-12  page_heading">
                            <h4 className="  text-center pt-2">Employee Details</h4>
                            <span
                                class="close pr-2  "
                                onClick={closemodal
                                }
                            >
                                &times;
                            </span>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 mt-2">

                            <ul>
                                <li>
                                    <b>Name:</b>{" "}
                                    {employeedata.EmpName}
                                </li>
                                <li>
                                    <b>Father Name:</b>{" "}
                                    {employeedata.EmpFatherName}
                                </li>
                                <li>
                                    <b>Email:</b>{" "}
                                    {employeedata.EmpEmail}
                                </li>
                                <li>
                                    <b>CNIC:</b>{" "}
                                    {employeedata.EmpCnic}
                                </li>
                                <li>
                                    <b>Designation:</b>{" "}
                                    {employeedata.EmpDesignation !== undefined ? employeedata.EmpDesignation.DesignationName : null}
                                </li>
                                <li>
                                    <b>DOB:</b>{" "}
                                    {employeedata.EmpDob !== null && employeedata.EmpDob !== undefined ? moment(employeedata.EmpDob.split("T")[0]).format("DD-MMM-YYYY") : null}
                                </li>
                                <li>
                                    <b>Joining Date :</b>{" "}
                                    {employeedata.EmpJoiningDate !== undefined && employeedata.EmpJoiningDate !== null ? moment(employeedata.EmpJoiningDate.split("T")[0]).format("DD-MMM-YYYY") : null}
                                </li>
                                <li>
                                    <b>Religion:</b>{" "}
                                    {employeedata.EmpReligon}
                                </li>
                                <li>
                                    <b>Gender:</b>{" "}
                                    {employeedata.EmpGender}
                                </li>


                            </ul>

                        </div>
                        <div className="col-6 mt-2">
                            <ul>

                                <li>
                                    <b>Status:</b>{" "}
                                    {employeedata.EmpMaritalStatus}
                                </li>
                                <li>
                                    <b>City:</b>{" "}
                                    {employeedata.EmpCity}
                                </li>
                                <li>
                                    <b>Country:</b>{" "}
                                    {employeedata.EmpCountry}
                                </li>
                                <li>
                                    <b>Contact No.1:</b>{" "}
                                    {employeedata.EmpCellNo1}

                                </li>
                                <li>
                                    <b>Contact No.2:</b>{" "}
                                    {employeedata.EmpCellNo2}

                                </li>
                                <li>
                                    <b>Phone No:</b>{" "}
                                    {employeedata.EmpPhoneNo}

                                </li>
                                <li>
                                    <b>Address:</b>{" "}
                                    {employeedata.EmpAddress}

                                </li>

                            </ul>
                        </div>
                    </div>

                    <div className="col-12 text-right">
                        <button
                            className=" save_btn   "
                            onClick={() => {
                                dispatch(GetEmployeesData(employeedata));
                                closemodal();
                                props.showemployeetable();


                            }}
                        >
                            <FiEdit2 size="0.8em" /> Edit
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
import axios from "axios";
import { toast } from "react-toastify";
export const GetAllNewPurchase = (data) => {

    return ({
        type: "get_allnewpurchase",
        payload: data,
        loader: false
    })
}
export function getallnewpurchase() {
    return (dispatch) => {
        axios({
            url: `${window.$url}UnitPurchase/GetAllFiles`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
        
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllNewPurchase(result.data.Data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}
export const GetAddfilePopulate = (data) => {

    return ({
        type: "get_addfilepopulate",
        payload: data,
        loader: false
    })
}
export function getaddfilepopulate() {
    return (dispatch) => {
        axios({
            url: `${window.$url}UnitPurchase/AddFile/Populate`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
    
                if (result.data.IsSuccess === true) {
                    dispatch(GetAddfilePopulate(result.data.Data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
          
                toast.info("Something went wrong!");

            }
        );
    }
}
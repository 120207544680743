import axios from "axios";
import moment from "moment";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { GetDealSoldData, getdealsoldpopulatetable } from "../../../actions/inventoryactions/dealsold";
import { DealSoldAllPrint } from "../DealsPrints/DealSoldAllPrint";
import DealSoldPrint from "../DealsPrints/DealSoldPrint";
import { CSVLink } from "react-csv";



export const DealSoldTable = (props) => {
    const resultalldealsold = useSelector((state) => state.getAllDealSoldRed);
    const [dealsolddata, setdealsolddata] = useState({});
    const [printIndvidualData, SetPrintIndvidualData] = useState();

    // print function
    const componentRef = useRef();

    const printIndividualdealsold = useReactToPrint({
        content: () => componentRef.current,
    });
    const AllcomponentRef = useRef();

    const AllprintPaymentRec = useReactToPrint({
        content: () => AllcomponentRef.current,
    });

    const dispatch = useDispatch();
    //Table Data fiter

    var suggestionlist = [];

    const onTextChange = (e) => {
        const value = e.target.value;
        if (value === "") {

            dispatch(getdealsoldpopulatetable());
        } else {
            let suggestions = [];
            if (value.length > 0) {
                const regex = new RegExp(`${value}`, "i");

                suggestions = resultalldealsold.data
                    .sort()
                    .filter((val) =>
                        (regex.test(val.DealName) || regex.test(val.AffiliateCnicNavigation !== undefined ? val.AffiliateCnicNavigation.AffiliateName : null)));

            }
            suggestionlist = suggestions;
            dispatch(GetDealSoldData(suggestionlist));
        }
    };
    useEffect(() => {

        dispatch(getdealsoldpopulatetable());
    }, [dispatch]);
    function showmodal(data) {
        document.getElementById("mymodal").style.display = "block";
        setdealsolddata(data);

    }
    function closemodal() {
        document.getElementById("mymodal").style.display = "none";

    }
    function showmodaledit(data) {
        document.getElementById("mymodaledit").style.display = "block";


    }
    function closemodaledit() {
        document.getElementById("mymodaledit").style.display = "none";

    }
    function addexetenddate(date) {
        if (document.getElementById("expirydate").value === "") {
            toast.info("Enter Expiry Date")
        }
        else {
            document.getElementById("datesave").disabled = true;
            axios({
                url: `${window.$url}Deals/Sold/Extend?DealId=${date}&ExtendedDate=${document.getElementById("expirydate").value}`,
                method: "put",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "content-type": "application/json",
                },

            }).then(
                (result) => {
                    document.getElementById("datesave").disabled = false;
                    if (result.data.IsSuccess === true) {
                        document.getElementById("expirydate").value = "";
                        toast.info(result.data.Message);
                        closemodaledit();
                        dispatch(getdealsoldpopulatetable());




                    } else {
                        toast.info(result.data.Message);
                    }
                },
                (error) => {
                    document.getElementById("datesave").disabled = false;
                    toast.info(
                        "Something went wrong!"
                    )
                }
            );
        }
    }
    const [CsvData, setCsvData] = useState([]);
    useEffect(() => {
      let arr = [];
      resultalldealsold.data?.forEach((val, index) => {
        arr.push({
          Sr: index + 1,
          "Deal Name": val.DealName,
          Affiliate:
            val.AffiliateCnicNavigation !== undefined
              ? val.AffiliateCnicNavigation.AffiliateName
              : null,
          "Entry Date": moment(val.EntryDate.split("T")[0]).format(
            "DD-MMM-YYYY"
          ),
          "Exp Date": moment(val.ExpDate.split("T")[0]).format("DD-MMM-YYYY"),
          Amount: parseFloat(val.Amount).toLocaleString(),
        });
      });
      setCsvData(arr);
    }, [resultalldealsold.data]);
    return (
        <>
            <div hidden>
                <DealSoldPrint
                    ref={componentRef}

                    printData={printIndvidualData}

                />
            </div>
            <div hidden>
                <DealSoldAllPrint
                    ref={AllcomponentRef}

                    printData={resultalldealsold}

                />
            </div>
            <div className="row pt-3 ">
                <div className="col-md-1 pr-0 pl-0">


                    <label className="input_label  m-0">Search:</label>
                </div>
                <div className="col-md-3  tpr-0 pl-0">


                    <input type="text " className="input_styling " onChange={onTextChange} placeholder=" Deal/Affiliate " ></input>
                </div>

                {/* <div className="col-md-3 pr-0 pl-0">
                    <label className="input_label  m-0">From:</label>
                    <input type="date" className="input_date ml-3" id="from date"></input>
                </div>

                <div className="col-md-3 pr-0 pl-0">
                    <label className="input_label   m-0">To:</label>
                    <input type="date" className="input_date ml-3" id="todate"></input>
                </div>
                <div className="col-md-1 pr-0 pl-0">
                    <button className="btn_Go">Go</button>
                </div> */}
                <div className="col-md-1 pr-0 pl-0">
                    {resultalldealsold.loading ? <Loader type="ThreeDots"
                        color="green"
                        height={40}
                        width={40} /> : null}
                </div>
            </div>


            <div className="row ">
                <div className=" col-12  table_height pr-0 pl-0">
                    <div className="table-responsive mt-4">

                        <table className="table table-borderless  table-hover m-0">
                            <thead>

                                <tr >

                                    <th>Sr</th>

                                    <th>Deal Name</th>

                                    <th>Affiliate</th>
                                    <th>Entry Date</th>
                                    <th>Exp Date</th>
                                    <th>Amount</th>



                                    <th className="text-center">
                                        Cancel

                                    </th>
                                    <th>  <button
                                        className="save_btn"
                                        onClick={() => {
                                            props.showloantable();
                                        }}
                                    >
                                        New+
                                    </button></th>
                                </tr>
                            </thead>
                            <tbody>

                                {resultalldealsold.data.map((val, index) => (
                                    <tr key={index}>
                                        <td className="cur_sor" onClick={() => { showmodal(val) }}>{index + 1}</td>
                                        {/* <td onClick={() => { showmodal(val) }}>{val.EntryDate.split("T")[0]}</td> */}
                                        <td className="cur_sor" onClick={() => { showmodal(val); SetPrintIndvidualData(val) }} >{val.DealName}</td>
                                        <td className="cur_sor" onClick={() => { showmodal(val); SetPrintIndvidualData(val) }}>{val.AffiliateCnicNavigation !== undefined ? val.AffiliateCnicNavigation.AffiliateName : null}</td>
                                        <td className="cur_sor" onClick={() => { showmodal(val); SetPrintIndvidualData(val) }}>{moment(val.EntryDate.split("T")[0]).format("DD-MMM-YYYY")}</td>
                                        <td className="cur_sor" onClick={() => { showmodal(val); SetPrintIndvidualData(val) }}>{moment(val.ExpDate.split("T")[0]).format("DD-MMM-YYYY")}</td>
                                        <td className="cur_sor" onClick={() => { showmodal(val); SetPrintIndvidualData(val) }}>{parseFloat(val.Amount).toLocaleString()}</td>
                                        <td className="cur_sor text-center"  > {val.Expired !== true ? <button
                                            onClick={() => { props.showdealcancelscreen(); props.setcancelList1(val) }}
                                            className="cancel_btn ml-2 "

                                        >

                                            Cancel
                                        </button> : null}
                                        </td>
                                        <td><FiEdit2 title="Edit" size="1.1em" className="cur_sor" onClick={() => { props.seteditfielddata(val); props.setdealunitid(val.DealSoldId); props.setedittablepopulate(val.DealSoldUnits); props.showloantable(); props.setedit("true"); }} /></td>



                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-6  text-right pr-0 pl-0">
                    <button className="print_btn float-left" onClick={() => { AllprintPaymentRec(); }}><TiPrinter size="1.3em" />Print</button>
                    <button
                    style={{ color: "black !important" }}
                    className="print_btn ml-2 float-left"
                    >
                    <CSVLink
                        style={{ color: "black" }}
                        filename="Deal_Sold_Csv"
                        data={CsvData}
                    >
                        Download CSV
                    </CSVLink>
                    </button>                       

                </div></div>
            <div id="mymodal" class="modal">

                <div class="modal-content-deal">
                    <div className="row">
                        <div className="col-12  page_heading">
                            <h4 className="  text-center pt-2">Deal Sold Detail</h4>
                            <span
                                class="close pr-2  "
                                onClick={closemodal
                                }
                            >
                                &times;
                            </span>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 mt-2">

                            <ul>
                                <li>
                                    <b>Project Name:</b>{" "}
                                    {dealsolddata.Project !== undefined ? dealsolddata.Project.ProjectName : null}
                                </li>
                                <li>
                                    <b>Affiliate:</b>{" "}
                                    {dealsolddata.AffiliateCnicNavigation !== undefined ? dealsolddata.AffiliateCnicNavigation.AffiliateName : null}
                                </li>
                                <li>
                                    <b> Entry Date:</b>{" "}
                                    {dealsolddata.EntryDate !== undefined ?moment(dealsolddata.EntryDate.split("T")[0]).format("DD-MMM-YYYY") : null}
                                </li>
                                <li>
                                    <b>Expiry Date:</b>{" "}
                                    {dealsolddata.ExpDate !== undefined ?moment(dealsolddata.ExpDate.split("T")[0]).format("DD-MMM-YYYY") : null}
                                </li>
                                <li>
                                    <b>Advance%:</b>{" "}
                                    {dealsolddata.AdvancePer !== undefined ? dealsolddata.AdvancePer : null}
                                </li>

                            </ul>

                        </div>
                        <div className="col-6 mt-2">
                            <ul>
                                <li>
                                    <b>Deal Name:</b>{" "}
                                    {dealsolddata.DealName !== undefined ? dealsolddata.DealName : null}
                                </li>

                                <li>
                                    <b>Amount:</b>{" "}
                                    {dealsolddata.Amount !== undefined ? dealsolddata.Amount.toLocaleString() : null}
                                </li>

                                <li>
                                    <b>Amount Received:</b>{" "}
                                    {dealsolddata.AmountReceived !== undefined ? dealsolddata.AmountReceived.toLocaleString() : null}
                                </li>


                                <li>
                                    <b>Commission%:</b>{" "}
                                    {dealsolddata.CommissionPer !== undefined ? dealsolddata.CommissionPer : null}
                                </li>


                                <li>
                                    <b>Profit Margin%:</b>{" "}
                                    {dealsolddata.ProfitMarginPer !== undefined ? dealsolddata.ProfitMarginPer : null}
                                </li>
                                <li>
                                    <b>Note:</b>{" "}
                                    {dealsolddata.Note !== undefined ? dealsolddata.Note : null}
                                </li>


                            </ul>
                        </div>
                    </div>
                    <table class="table ">
                        <thead class="purple whiteText">
                            <tr>

                                <th scope="col">Unit</th>
                                <th scope="col">Size</th>
                                <th scope="col">Sqft</th>

                                <th scope="col">OldSaleRate</th>
                                <th scope="col">NewSaleRate</th>

                                <th scope="col">TotalSaleValue</th>
                                <th scope="col">AdvanceAmount</th>



                            </tr>
                        </thead>
                        <tbody>
                            {dealsolddata === undefined || dealsolddata.DealSoldUnits === undefined

                                // contractinstallment.length == 0
                                ? null
                                : dealsolddata.DealSoldUnits.map((x, id) => (
                                    <tr key={id}>
                                        <td>{x.Unit.UnitName.toLocaleString()}</td>
                                        <td>{x.Unit.Marla + "M " + x.Unit.Sarsai + " S"}</td>
                                        <td>{x.Unit.NetSqft}</td>
                                        <td>{x.OldSaleRate.toLocaleString()}</td>

                                        <td>{x.NewSaleRate.toLocaleString()}</td>

                                        <td>{x.TotalSaleValue.toLocaleString()}</td>

                                        <td>{x.AdvanceAmount.toLocaleString()}</td>



                                    </tr>
                                ))}
                        </tbody>
                    </table>

                    <div className="col-12 text-right">
                        <button className="print_btn mr-2 " onClick={() => { printIndividualdealsold(); }}><TiPrinter size="1.3em" />Print</button>
                        <button
                            className=" save_btn   "
                            onClick={() => {
                                // dispatch(GetCreditorData(creditordata));
                                // closemodal();
                                // props.showcreditortable();
                                showmodaledit();


                            }}
                        >
                            <FiEdit2 size="0.8em" /> Edit
                        </button>
                    </div>
                </div>
            </div>
            {/* for extand date */}
            <div id="mymodaledit" class="modal">

                <div class="modal-content-cat">
                    <div className="row">
                        <div className="col-12  page_heading">
                            <h4 className="  text-center pt-2">Edit Expiry</h4>
                            <span
                                class="close pr-2  "
                                onClick={closemodaledit
                                }
                            >
                                &times;
                            </span>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 mt-2">
                            <input type="date" id="expirydate" className="input_styling"></input>
                        </div>

                    </div>

                    <div className="col-12 text-right">
                        <button
                            className=" save_btn   "
                            id="datesave"
                            onClick={() => {
                                addexetenddate(printIndvidualData.DealSoldId)


                            }}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>

        </>
    );
};
import axios from 'axios';
import { toast } from "react-toastify";
export const GetAllDealBoughtPay = (data) => {

    return ({
        type: "get_alldealboughtpay",
        payload: data,
        loader: false
    })
}
export function getallDealBoughtPay() {
    return (dispatch) => {
        let pgurl = `${window.$url}Vouchers/DealBought/Payment/Populate?ProjectId=${localStorage.getItem("ProjectId")}`;
        if (localStorage.getItem("ProjectId") === null) {
            pgurl = `${window.$url}Vouchers/DealBought/Payment/Populate`
        }
        axios({
            url: pgurl,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllDealBoughtPay(result.data.Data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}
export const GetAllDBPTableData = (data) => {

    return ({
        type: "get_allDealBPtabledata",
        payload: data,
        loader: false
    })
}
export function getallDBPpopulatetable() {
    return (dispatch) => {
        let pgurl = `${window.$url}Vouchers/RecoveryReceipt/Get?projectid=${localStorage.getItem("ProjectId")}`;
        if (localStorage.getItem("ProjectId") === null) {
            pgurl = `${window.$url}Vouchers/RecoveryReceipt/Get`
        }
        axios({
            url: pgurl,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllDBPTableData(result.data.Data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}

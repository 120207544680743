import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getallProjectCat } from "../../../actions/getprojectcat";

export const ProjectForm = (props) => {

    const resultsallprojectcat = useSelector((state) => state.getProjectCat);
  
    const dispatch = useDispatch();
    const [loader, setloader] = useState(false);
  
    const [state, setState] = useState({
      ProjectId: null,
      ProjectName: "",
      ProjectLocation: "",
      ProjectCity: "",
  
      // AdvancePayment: null,
      ProjectOwner: "",
      ProjectCategoryId: 0,
      // MarketingExpensePercentage: null,
      // Installments: ""
  
  
    });
    function addCategory() {
      document.getElementById("catsave").disabled = true;
      axios({
        url: `${window.$url}Projects/ProjectCategories/Add`,
        method: "post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
        data: JSON.stringify({
  
          ProjectCategoryId: 0,
          ProjectCategoryName: document.getElementById("catname").value
        }),
      }).then(
        (result) => {
          document.getElementById("catsave").disabled = false;
          if (result.data.IsSuccess === true) {
            document.getElementById("catname").value = "";
            toast.info(result.data.Message);
            modalclose();
            dispatch(getallProjectCat());
  
  
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          document.getElementById("catsave").disabled = false;
          toast.info(
            "Something went wrong!"
          )
        }
      );
    }
    function modalclose() {
      document.getElementById("catModal").style.display = "none";
    };
    const savedata = () => {
      if (document.getElementById("projectName").value === "" || document.getElementById("city").options[document.getElementById("city").selectedIndex].value === "Select" || document.getElementById("projectowner").selectedIndex === 0 || document.getElementById("city").options[document.getElementById("city").selectedIndex].value === "Select" || document.getElementById("projectcat").options[document.getElementById("projectcat").selectedIndex].value === "Select" || document.getElementById("location").value === "") {
        toast.info("All fields are required")
      }
      // else if (
      //   document.getElementById("AdvancePayment").value > 100 ||
      //   document.getElementById("AdvancePayment").value < 0) {
      //   toast.info("Advance Payment should be in between 0 to 100 ");
      // } 
      else {
        let ProjectOwner;
        if (document.getElementById("projectowner").options[document.getElementById("projectowner").selectedIndex].value === "Own Project") {
          ProjectOwner = "Personal"
        } else {
          ProjectOwner = "Other"
        }
        let data = state;
        data.ProjectId = 0;
        data.ProjectName = document.getElementById("projectName").value.trim();
        data.ProjectLocation = document.getElementById("location").value;
        data.ProjectOwner = ProjectOwner;
        data.ProjectCity = document.getElementById("city").options[document.getElementById("city").selectedIndex].value;
        data.ProjectCategoryId = parseInt(document.getElementById("projectcat").options[document.getElementById("projectcat").selectedIndex].dataset.id);
        // data.Installments = parseInt(document.getElementById("maxinstall").value);
        // data.MarketingExpensePercentage = parseInt(document.getElementById("MarketingExp").value);
  
        document.getElementById("projectsave").disabled = true;
        setloader(true);
        axios({
          method: "post",
          url: `${window.$url}Projects/Add`,
          data: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "content-type": "application/json",
          },
        }).then(
          (result) => {
            document.getElementById("projectsave").disabled = false;
            if (result.data.IsSuccess === true) {
              // document.getElementById("projectName").value = "";
              // document.getElementById("location").value = "";
              document.getElementById("projectowner").selectedIndex = 0;
              document.getElementById("city").selectedIndex = 0;
              document.getElementById("projectcat").selectedIndex = 0;
              // document.getElementById("maxinstall").value = "";
              // document.getElementById("AdvancePayment").value = "";
              setState({
                ProjectId: null,
                ProjectName: "",
                ProjectLocation: "",
                ProjectCity: "",
                // Installments: "",
                // AdvancePayment: null,
                ProjectOwner: "",
                ProjectCategoryId: 0
              })
              toast.info(result.data.Message);
              props.cancelprojecttable();
              setloader(false);
            } else {
              toast.info(result.data.Message);
              setloader(false);
  
            }
  
  
          },
          (error) => {
            document.getElementById("projectsave").disabled = false;
            setloader(false);
  
            console.log(error);
  
  
          }
        );
  
  
      }
    }
    function deleteprojectcat(id) {
  
  
  
      axios({
        url: `${window.$url}Projects/ProjectCategories/Del?ProjectCategoryId=${id}`,
        method: "delete",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result.data.IsSuccess === true) {
            dispatch(getallProjectCat());
            toast.info(result.data.Message);
          }
          else {
            toast.info(result.data.Message);
          }
  
        },
        (error) => {
          console.log(error);
          toast.info("Something went wrong!");
  
        }
      );
  
    }
    useEffect(() => {
  
      dispatch(getallProjectCat());
  
    }, [dispatch]
    )
    return (
      <>
        <div className="row ">
          <div className="col-md-12  text-left page_heading">
            <h4 className="m-0">Add Project</h4>
          </div>
        </div>
  
        <div className="row">
          <div className="col-md-2 mt-4 text-left p-0">
            <label className="input_label m-0 " > Name:</label>
          </div>
          <div className="col-md-3 mt-4  p-0">
            <input type="text" className="input_styling ml-md-3 " id="projectName" /><span className="estaric">*</span>
  
  
          </div>
          <div className="col-md-2 mt-4  p-0">
          </div>
          <div className="col-md-2 mt-4 text-left p-0">
            <label className="input_label m-0">Location:</label>
          </div>
          <div className="col-md-3 mt-4   p-0">
  
            <input type="text" className="input_styling ml-md-3 " id="location" /><span className="estaric">*</span>
  
          </div>
  
        </div>
        <div className="row">
          <div className="col-md-2 mt-4 text-left p-0">
            <label className="input_label m-0 " >Owner:</label>
          </div>
  
          <div className="col-md-3 mt-4   p-0">
            <select className="input_styling ml-md-3 " id="projectowner" >
              <option>
                Select
              </option>
              <option>
                Own Project
              </option>
              <option>
                Deal From Others
              </option>
            </select><span className="estaric">*</span>
  
          </div>
          <div className="col-md-2 mt-4  p-0">
          </div>
          <div className="col-md-2 mt-4 text-left p-0">
            <label className="input_label m-0">City:</label>
          </div>
          <div className="col-md-3 mt-4   p-0">
            <select className="input_styling ml-md-3" id="city" >
              <option >Select</option>
              <option value="Islamabad">Islamabad</option>
              <option value="" disabled>Punjab Cities</option>
              <option value="Ahmed Nager Chatha">Ahmed Nager Chatha</option>
              <option value="Ahmadpur East">Ahmadpur East</option>
              <option value="Ali Khan Abad">Ali Khan Abad</option>
              <option value="Alipur">Alipur</option>
              <option value="Arifwala">Arifwala</option>
              <option value="Attock">Attock</option>
              <option value="Bhera">Bhera</option>
              <option value="Bhalwal">Bhalwal</option>
              <option value="Bahawalnagar">Bahawalnagar</option>
              <option value="Bahawalpur">Bahawalpur</option>
              <option value="Bhakkar">Bhakkar</option>
              <option value="Burewala">Burewala</option>
              <option value="Chillianwala">Chillianwala</option>
              <option value="Chakwal">Chakwal</option>
              <option value="Chichawatni">Chichawatni</option>
              <option value="Chiniot">Chiniot</option>
              <option value="Chishtian">Chishtian</option>
              <option value="Daska">Daska</option>
              <option value="Darya Khan">Darya Khan</option>
              <option value="Dera Ghazi Khan">Dera Ghazi Khan</option>
              <option value="Dhaular">Dhaular</option>
              <option value="Dina">Dina</option>
              <option value="Dinga">Dinga</option>
              <option value="Dipalpur">Dipalpur</option>
              <option value="Faisalabad">Faisalabad</option>
              <option value="Ferozewala">Ferozewala</option>
              <option value="Fateh Jhang">Fateh Jang</option>
              <option value="Ghakhar Mandi">Ghakhar Mandi</option>
              <option value="Gojra">Gojra</option>
              <option value="Gujranwala">Gujranwala</option>
              <option value="Gujrat">Gujrat</option>
              <option value="Gujar Khan">Gujar Khan</option>
              <option value="Hafizabad">Hafizabad</option>
              <option value="Haroonabad">Haroonabad</option>
              <option value="Hasilpur">Hasilpur</option>
              <option value="Haveli Lakha">Haveli Lakha</option>
              <option value="Jatoi">Jatoi</option>
              <option value="Jalalpur">Jalalpur</option>
              <option value="Jattan">Jattan</option>
              <option value="Jampur">Jampur</option>
              <option value="Jaranwala">Jaranwala</option>
              <option value="Jhang">Jhang</option>
              <option value="Jhelum">Jhelum</option>
              <option value="Kalabagh">Kalabagh</option>
              <option value="Karor Lal Esan">Karor Lal Esan</option>
              <option value="Kasur">Kasur</option>
              <option value="Kamalia">Kamalia</option>
              <option value="Kamoke">Kamoke</option>
              <option value="Khanewal">Khanewal</option>
              <option value="Khanpur">Khanpur</option>
              <option value="Kharian">Kharian</option>
              <option value="Khushab">Khushab</option>
              <option value="Kot Addu">Kot Addu</option>
              <option value="Jauharabad">Jauharabad</option>
              <option value="Lahore">Lahore</option>
              <option value="Lalamusa">Lalamusa</option>
              <option value="Layyah">Layyah</option>
              <option value="Liaquat Pur">Liaquat Pur</option>
              <option value="Lodhran">Lodhran</option>
              <option value="Malakwal">Malakwal</option>
              <option value="Mamoori">Mamoori</option>
              <option value="Mailsi">Mailsi</option>
              <option value="Mandi Bahauddin">Mandi Bahauddin</option>
              <option value="Mian Channu">Mian Channu</option>
              <option value="Mianwali">Mianwali</option>
              <option value="Multan">Multan</option>
              <option value="Murree">Murree</option>
              <option value="Muridke">Muridke</option>
              <option value="Mianwali Bangla">Mianwali Bangla</option>
              <option value="Muzaffargarh">Muzaffargarh</option>
              <option value="Narowal">Narowal</option>
              <option value="Nankana Sahib">Nankana Sahib</option>
              <option value="Okara">Okara</option>
              <option value="Renala Khurd">Renala Khurd</option>
              <option value="Pakpattan">Pakpattan</option>
              <option value="Pattoki">Pattoki</option>
              <option value="Pir Mahal">Pir Mahal</option>
              <option value="Qaimpur">Qaimpur</option>
              <option value="Qila Didar Singh">Qila Didar Singh</option>
              <option value="Rabwah">Rabwah</option>
              <option value="Raiwind">Raiwind</option>
              <option value="Rajanpur">Rajanpur</option>
              <option value="Rahim Yar Khan">Rahim Yar Khan</option>
              <option value="Rawalpindi">Rawalpindi</option>
              <option value="Sadiqabad">Sadiqabad</option>
              <option value="Safdarabad">Safdarabad</option>
              <option value="Sahiwal">Sahiwal</option>
              <option value="Sangla Hill">Sangla Hill</option>
              <option value="Sarai Alamgir">Sarai Alamgir</option>
              <option value="Sargodha">Sargodha</option>
              <option value="Shakargarh">Shakargarh</option>
              <option value="Sheikhupura">Sheikhupura</option>
              <option value="Sialkot">Sialkot</option>
              <option value="Sohawa">Sohawa</option>
              <option value="Soianwala">Soianwala</option>
              <option value="Siranwali">Siranwali</option>
              <option value="Talagang">Talagang</option>
              <option value="Taxila">Taxila</option>
              <option value="Toba Tek Singh">Toba Tek Singh</option>
              <option value="Vehari">Vehari</option>
              <option value="Wah Cantonment">Wah Cantonment</option>
              <option value="Wazirabad">Wazirabad</option>
              <option value="" disabled>Sindh Cities</option>
              <option value="Badin">Badin</option>
              <option value="Bhirkan">Bhirkan</option>
              <option value="Rajo Khanani">Rajo Khanani</option>
              <option value="Chak">Chak</option>
              <option value="Dadu">Dadu</option>
              <option value="Digri">Digri</option>
              <option value="Diplo">Diplo</option>
              <option value="Dokri">Dokri</option>
              <option value="Ghotki">Ghotki</option>
              <option value="Haala">Haala</option>
              <option value="Hyderabad">Hyderabad</option>
              <option value="Islamkot">Islamkot</option>
              <option value="Jacobabad">Jacobabad</option>
              <option value="Jamshoro">Jamshoro</option>
              <option value="Jungshahi">Jungshahi</option>
              <option value="Kandhkot">Kandhkot</option>
              <option value="Kandiaro">Kandiaro</option>
              <option value="Karachi">Karachi</option>
              <option value="Kashmore">Kashmore</option>
              <option value="Keti Bandar">Keti Bandar</option>
              <option value="Khairpur">Khairpur</option>
              <option value="Kotri">Kotri</option>
              <option value="Larkana">Larkana</option>
              <option value="Matiari">Matiari</option>
              <option value="Mehar">Mehar</option>
              <option value="Mirpur Khas">Mirpur Khas</option>
              <option value="Mithani">Mithani</option>
              <option value="Mithi">Mithi</option>
              <option value="Mehrabpur">Mehrabpur</option>
              <option value="Moro">Moro</option>
              <option value="Nagarparkar">Nagarparkar</option>
              <option value="Naudero">Naudero</option>
              <option value="Naushahro Feroze">Naushahro Feroze</option>
              <option value="Naushara">Naushara</option>
              <option value="Nawabshah">Nawabshah</option>
              <option value="Nazimabad">Nazimabad</option>
              <option value="Qambar">Qambar</option>
              <option value="Qasimabad">Qasimabad</option>
              <option value="Ranipur">Ranipur</option>
              <option value="Ratodero">Ratodero</option>
              <option value="Rohri">Rohri</option>
              <option value="Sakrand">Sakrand</option>
              <option value="Sanghar">Sanghar</option>
              <option value="Shahbandar">Shahbandar</option>
              <option value="Shahdadkot">Shahdadkot</option>
              <option value="Shahdadpur">Shahdadpur</option>
              <option value="Shahpur Chakar">Shahpur Chakar</option>
              <option value="Shikarpaur">Shikarpaur</option>
              <option value="Sukkur">Sukkur</option>
              <option value="Tangwani">Tangwani</option>
              <option value="Tando Adam Khan">Tando Adam Khan</option>
              <option value="Tando Allahyar">Tando Allahyar</option>
              <option value="Tando Muhammad Khan">Tando Muhammad Khan</option>
              <option value="Thatta">Thatta</option>
              <option value="Umerkot">Umerkot</option>
              <option value="Warah">Warah</option>
              <option value="" disabled>Khyber Cities</option>
              <option value="Abbottabad">Abbottabad</option>
              <option value="Adezai">Adezai</option>
              <option value="Alpuri">Alpuri</option>
              <option value="Akora Khattak">Akora Khattak</option>
              <option value="Ayubia">Ayubia</option>
              <option value="Banda Daud Shah">Banda Daud Shah</option>
              <option value="Bannu">Bannu</option>
              <option value="Batkhela">Batkhela</option>
              <option value="Battagram">Battagram</option>
              <option value="Birote">Birote</option>
              <option value="Chakdara">Chakdara</option>
              <option value="Charsadda">Charsadda</option>
              <option value="Chitral">Chitral</option>
              <option value="Daggar">Daggar</option>
              <option value="Dargai">Dargai</option>
              <option value="Darya Khan">Darya Khan</option>
              <option value="Dera Ismail Khan">Dera Ismail Khan</option>
              <option value="Doaba">Doaba</option>
              <option value="Dir">Dir</option>
              <option value="Drosh">Drosh</option>
              <option value="Hangu">Hangu</option>
              <option value="Haripur">Haripur</option>
              <option value="Karak">Karak</option>
              <option value="Kohat">Kohat</option>
              <option value="Kulachi">Kulachi</option>
              <option value="Lakki Marwat">Lakki Marwat</option>
              <option value="Latamber">Latamber</option>
              <option value="Madyan">Madyan</option>
              <option value="Mansehra">Mansehra</option>
              <option value="Mardan">Mardan</option>
              <option value="Mastuj">Mastuj</option>
              <option value="Mingora">Mingora</option>
              <option value="Nowshera">Nowshera</option>
              <option value="Paharpur">Paharpur</option>
              <option value="Pabbi">Pabbi</option>
              <option value="Peshawar">Peshawar</option>
              <option value="Saidu Sharif">Saidu Sharif</option>
              <option value="Shorkot">Shorkot</option>
              <option value="Shewa Adda">Shewa Adda</option>
              <option value="Swabi">Swabi</option>
              <option value="Swat">Swat</option>
              <option value="Tangi">Tangi</option>
              <option value="Tank">Tank</option>
              <option value="Thall">Thall</option>
              <option value="Timergara">Timergara</option>
              <option value="Tordher">Tordher</option>
              <option value="" disabled>Balochistan Cities</option>
              <option value="Awaran">Awaran</option>
              <option value="Barkhan">Barkhan</option>
              <option value="Chagai">Chagai</option>
              <option value="Dera Bugti">Dera Bugti</option>
              <option value="Gwadar">Gwadar</option>
              <option value="Harnai">Harnai</option>
              <option value="Jafarabad">Jafarabad</option>
              <option value="Jhal Magsi">Jhal Magsi</option>
              <option value="Kacchi">Kacchi</option>
              <option value="Kalat">Kalat</option>
              <option value="Kech">Kech</option>
              <option value="Kharan">Kharan</option>
              <option value="Khuzdar">Khuzdar</option>
              <option value="Killa Abdullah">Killa Abdullah</option>
              <option value="Killa Saifullah">Killa Saifullah</option>
              <option value="Kohlu">Kohlu</option>
              <option value="Lasbela">Lasbela</option>
              <option value="Lehri">Lehri</option>
              <option value="Loralai">Loralai</option>
              <option value="Mastung">Mastung</option>
              <option value="Musakhel">Musakhel</option>
              <option value="Nasirabad">Nasirabad</option>
              <option value="Nushki">Nushki</option>
              <option value="Panjgur">Panjgur</option>
              <option value="Pishin Valley">Pishin Valley</option>
              <option value="Quetta">Quetta</option>
              <option value="Sherani">Sherani</option>
              <option value="Sibi">Sibi</option>
              <option value="Sohbatpur">Sohbatpur</option>
              <option value="Washuk">Washuk</option>
              <option value="Zhob">Zhob</option>
              <option value="Ziarat">Ziarat</option>
            </select><span className="estaric">*</span>
  
          </div>
  
        </div>
        <div className="row">
          <div className="col-md-2 mt-4 text-left p-0">
            <label className="input_label m-0 " > Category:</label>
          </div>
  
          <div className="col-md-3 mt-4   p-0">
            <select className="input_styling ml-md-3 " id="projectcat" onChange={(e) => { e.target.value === "Add New" ? document.getElementById("catModal").style.display = "block" : document.getElementById("catModal").style.display = "none"; }} >
              <option>Select</option>
              <option>Add New</option>
              {resultsallprojectcat.data.map((val, index) => (
                <option key={index} data-id={val.ProjectCategoryId}>{val.ProjectCategoryName}</option>
              ))}
            </select><span className="estaric">*</span>
  
          </div>
          <div className="col-md-2 mt-4  p-0">
          </div>
          {/* <div className="col-md-2 mt-4 text-left p-0">
            <label className="input_label m-0 " >Pay. Time:</label>
          </div>
          <div className="col-md-3 mt-4  p-0">
            <input type="number" className="input_styling ml-md-3 " id="maxinstall" placeholder="e.g 2 is equal to 2 months" /><span className="estaric">*</span>
  
  
          </div> */}
  
        </div>
  
        {/* <div className="row">
  
          <div className="col-md-2 mt-4 text-left p-0">
            <label className="input_label m-0">Marketing Exp%:</label>
          </div>
          <div className="col-md-3 mt-4   p-0">
  
            <input type="number" className="input_styling ml-md-3 " id="MarketingExp" /><span className="estaric">*</span>
  
          </div>
  
        </div> */}
  
        <div className="row pr-md-2 mt-md-5">
          <div className=" col-6 pr-0 pl-0  ">
  
          </div>
          <div className=" col-6 pr-0 pl-0  text-right ">
  
            <span>
              {loader ?
                <Loader
                  type="ThreeDots"
                  color="green"
                  height={40}
                  width={40}
                /> : null}
            </span>
            <span>
              <button
                className="cancel_btn ml-2  "
                onClick={props.cancelprojecttable}
              >
  
                Cancel
              </button>
            </span>
  
            <span>
              <button className="save_btn ml-2  " id="projectsave" onClick={savedata}>
                <BsCloudUpload size="1.3em" />
                Save
              </button>
            </span>
          </div>
        </div>
        {/* <!-- The Modal for cat --> */}
        <div id="catModal" class="modalscreen ">
          <div className="modal-content-screen1 pt-1">
            <div className=" page_heading">
              <span className="head_text pl-3">Add Project Category</span>
              <span className="close pr-3" onClick={modalclose}>&times;</span>
            </div>
  
            <div className="row pt-5 pl-3">
              <div className="col-md-2  text-left p-0">
                <label className="input_label m-0 ">Category Name:</label>
              </div>
  
              <div className="col-md-4  p-0">
  
                <input type="text" className="input_styling ml-md-3 " id="catname"></input><span className="estaric">*</span>
  
              </div>
              <div className=" col-2 pr-0 pl-0  text-right ">
  
                <span><button className="save_btn mr-md-3" id="catsave" onClick={addCategory}><BsCloudUpload size="1.3em" />Save</button></span>
              </div>
            </div>
  
  
            <div className="row  pt-md-4">
  
              <div className=" col-12 pr-0 pl-0 ">
                <div className="table-responsive mt-4">
                  <table className="table table-borderless m-0">
                    <thead>
                      <th>
                        Category Id
                      </th>
                      <th>
                        Category Name
                      </th>
                      <th>Del</th>
                    </thead>
                    <tbody>
                      {resultsallprojectcat.data.map((val, index) => (
                        <tr key={index}>
                          <td  >{val.ProjectCategoryId}</td>
  
                          <td >{val.ProjectCategoryName}</td>
                          <td style={{ cursor: "pointer" }} onClick={() => deleteprojectcat(val.ProjectCategoryId)}><MdDeleteForever size="1.6em" /></td>
  
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
  
            </div>
          </div>
  
        </div>
        {/* modal close */}
  
  
  
  
      </>
    )
  }
import moment from "moment";
import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
export class RecoveryReceiptReportPrint extends Component {
  render() {
    let i = 0;
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "80%" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Recovery Report</u>
            </h4>
            <h6>{this.props.month !== undefined ? this.props.month : null}</h6>

            <hr style={{ backgroundColor: "black" }} />
            <div className="mt-4">
              <div class=" col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Contract Date</th>
                      <th>Buyer</th>
                      <th>Contact No</th>
                      <th>Unit</th>
                      <th>Total Installments</th>
                      <th>Pending Installments</th>
                      <th>Total Price</th>
                      <th>Customer Receivable</th>
                      <th>Last Payment Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props?.recoveryfilter?.map((x, index) => {
                      let arr = [];
                      x?.ContractInstallments.map((item) => {
                        if (item?.InstallmentStatus === "PENDING") {
                          arr.push(item);
                        }
                      });
                      return (
                        x?.ContractInstallments?.length > 0 && (
                          <tr key={index} style={{ cursor: "pointer" }}>
                            <td>{++i}</td>
                            <td className="th2 tdcell">
                              {moment(x?.EntryDate?.split("T")[0]).format(
                                "DD-MMM-YYYY"
                              )}
                            </td>
                            <td className="th2 tdcell">
                              {x?.CustomerCnicNavigation?.CustomerName}
                            </td>
                            <td className="th2 tdcell">
                              {x?.CustomerCnicNavigation?.ContactNumber1}
                            </td>
                            <td>{x?.Unit?.UnitName}</td>
                            <td className="th2 tdcell">
                              {x?.NoOfInstallments}
                            </td>
                            <td>{arr?.length}</td>
                            <td className="th2 tdcell">
                              {x?.TotalPrice?.toLocaleString()}
                            </td>
                            <td className="th2 tdcell">
                              {x?.CustomerReceivable?.toLocaleString()}
                            </td>
                            <td className="th2 tdcell">
                              {moment(
                                x?.UnitVoucher[0]?.EntryDate?.split("T")[0]
                              ).format("DD-MMM-YYYY")}
                            </td>
                          </tr>
                        )
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className="col-12 text-left"
              style={{ display: this.props?.showTotal ? "flex" : "none" }}
            >
              <div className="row mt-1">
                <div className="col-md-4">
                  <label className="m-0">
                    <b>Total Due:</b>
                  </label>
                  <input
                    type="text"
                    id="credittotal"
                    value={parseFloat(this.props?.totalDue)?.toLocaleString()}
                    readOnly
                    className=" ml-1 input_styling"
                  />
                </div>
                <div className="col-md-4">
                  <label className="m-0">
                    <b>Total Received:</b>
                  </label>
                  <input
                    type="text"
                    id="credittotal"
                    value={parseFloat(
                      this.props?.totalReceived
                    )?.toLocaleString()}
                    readOnly
                    className=" ml-1 input_styling"
                  />
                </div>
                <div className="col-md-4">
                  <label className="m-0">
                    <b>Total Receivable:</b>
                  </label>
                  <input
                    type="text"
                    id="credittotal"
                    value={parseFloat(
                      this.props?.totalReceivable
                    )?.toLocaleString()}
                    readOnly
                    className=" ml-1 input_styling"
                  />
                </div>
              </div>
            </div>
            {/* {this.props.recoveryfilter !== undefined
              ? this.props.recoveryfilter.map((x, i) =>
                  x.ContractInstallments !== null &&
                  x.ContractInstallments.length > 0 ? (
                    <div>
                      <div className="row mt-4">
                        <div className="col-12 text-center">
                          <h4>{i + 1}</h4>{" "}
                        </div>
                        <div className="col-6 text-left">
                          <b>Contract Date:</b>{" "}
                          {moment(
                            x?.ContractInstallments?.DueDate?.split("T")[0]
                          ).format("DD-MMM-YYYY")}
                        </div>
                        <div className="col-6 text-right">
                          <b>Buyer: </b>
                          {x?.CustomerCnicNavigation?.CustomerName}
                        </div>

                        <div className="col-6 text-left">
                          <b>Unit: </b>
                          {x?.Unit?.UnitName}
                        </div>
                        <div className="col-6 text-right">
                          <b>Total Installments: </b>
                          {x?.NoOfInstallments}
                        </div>

                        <div className="col-6 text-left">
                          <b>Total Price: </b>
                          {x?.TotalPrice?.toLocaleString()}
                        </div>

                        <div className="col-6 text-right">
                          <b>Pending: </b>
                          {x?.CustomerReceivable?.toLocaleString()}
                        </div>
                      </div>

                      <div className="mt-4">
                        <div class=" col-12">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>Sr.</th>
                                <th scope="col">Due Date</th>
                                <th scope="col">Installment Amount</th>
                                <th scope="col">Received</th>
                                <th scope="col">Pending Amount</th>
                                <th scope="col">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {x.ContractInstallments.map((y, i) => (
                                <tr>
                                  <td scope="row">{++i}</td>
                                  <td>
                                    {moment(y?.DueDate?.split("T")[0]).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  </td>
                                  <td>{y?.DueAmount?.toLocaleString()}</td>
                                  <td>{y?.ReceivedAmount?.toLocaleString()}</td>
                                  <td>
                                    {(
                                      y?.DueAmount - y?.ReceivedAmount
                                    )?.toLocaleString()}
                                  </td>
                                  <td>{y?.InstallmentStatus}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <hr />
                    </div>
                  ) : null
                )
              : null} */}
          </div>
        </div>
      </div>
    );
  }
}

import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getAllContractTable } from "../../../actions/accountsactions/addcontract";
import Loader from "react-loader-spinner";
import UpdateNominee from "./UpdateNominee";
export default function UpdateContract({ setshowModal, updateData }) {
  const dispatch = useDispatch();
  console.log(updateData);
  const [amount, setamount] = useState("");
  const [commamount, setcommamount] = useState("");
  const [tab, settab] = useState("Advance");
  const [pecr, setpecr] = useState("");
  const [commpecr, setcommpecr] = useState("");
  const [loading2, setloading2] = useState(false);

  useEffect(() => {
    setamount(updateData?.AdvanceAmount);
    setcommamount(updateData?.ComissionAmount);
    setcommpecr(updateData?.ComissionPer);

    if (updateData.UpdatedAdvancePercent === null) {
      setpecr(updateData?.UpdatedAdvancePercent);
    } else {
      setpecr(updateData?.UpdatedAdvancePercent);
    }
  }, [updateData]);
  const handleAdd = () => {
    if (Number(amount) < updateData?.AdvanceReceived) {
      toast.info(
        `You can't add Advance less than ${updateData?.AdvanceReceived}`
      );
      return;
    }
    if (Number(amount) > updateData?.TotalPrice) {
      toast.info(
        `You can't add Advance greater  than ${updateData?.TotalPrice}`
      );
      return;
    }
    document.getElementById("instasatn").disabled = true;
    setloading2(true);
    axios({
      method: "post",
      url: `${window.$url}Contracts/UpdateAdvanceAmount`,
      data: {
        ContractId: updateData.ContractId,
        AdvanceAmount: Number(amount),
        UpdatedAdvancePercent: pecr,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    })
      .then((res) => {
        document.getElementById("instasatn").disabled = false;

        if (res.data.IsSuccess === true) {
          toast.info(res.data.Message);
          dispatch(getAllContractTable({ ProjectId: updateData?.ProjectId }));
          setshowModal(false);
        } else {
          toast.info(res.data.Message);
        }
        setloading2(false);
      })
      .catch((err) => {
        document.getElementById("instasatn").disabled = false;

        toast.error("Something went wrong");
        setloading2(false);
      });
  };
  const handleAddCom = () => {
    let projectId = null;
    // if (Number(commamount) < updateData?.ComissionAmount) {
    //   toast.info(
    //     `You can't change  Commission less than entered commission amount  (${updateData?.ComissionAmount})`
    //   );
    //   return;
    // }
    if (Number(commamount) > updateData?.TotalPrice) {
      toast.info(
        `You can't add Commission greater  than ${updateData?.TotalPrice}`
      );
      return;
    }
    document.getElementById("instasatn1").disabled = true;
    setloading2(true);
    axios({
      method: "post",
      url: `${window.$url}Contracts/UpdateCommission`,
      data: {
        ProjectId: updateData?.ProjectId,
        ContractId: updateData.ContractId,
        ComissionAmount: commamount,
        ComissionPer: commpecr,
        UnitId: updateData?.UnitId,
        EntryDate: updateData?.EntryDate,
        AffiliateCnic: updateData?.AffiliateCnic,
        ManagerId: updateData?.Manager?.ManagerId,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    })
      .then((res) => {
        document.getElementById("instasatn1").disabled = false;

        if (res.data.IsSuccess === true) {
          toast.info(res.data.Message);
          dispatch(getAllContractTable({ ProjectId: updateData?.ProjectId }));
          setshowModal(false);
        } else {
          toast.info(res.data.Message);
        }
        setloading2(false);
      })
      .catch((err) => {
        document.getElementById("instasatn").disabled = false;
        setloading2(false);

        toast.error("Something went wrong");
      });
  };
  return (
    <div style={{ display: "block" }} class="modalscreen ">
      <div class="modal-content-screen1 pt-1">
        <div className="row ">
          <div className="col-12  page_heading">
            <h4 className="  text-center "> Update </h4>
            <span
              class="close pr-2  "
              onClick={() => {
                setshowModal(false);
              }}
            >
              &times;
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center mt-2  p-0 d-flex">
            <button
              className={
                tab === "Advance" ? "adjust_btns_selected  " : "adjust_btns  "
              }
              onClick={() => {
                settab("Advance");
              }}
            >
              <span className="comp_btns_text">Advance</span>
            </button>

            <button
              className={
                tab === "Commission"
                  ? "adjust_btns_selected ml-1 "
                  : "adjust_btns ml-1 "
              }
              onClick={() => {
                settab("Commission");
              }}
            >
              <span className="comp_btns_text">Commission</span>
            </button>
            <button
              className={
                tab === "Nominee"
                  ? "adjust_btns_selected ml-1 "
                  : "adjust_btns ml-1 "
              }
              onClick={() => {
                settab("Nominee");
              }}
            >
              <span className="comp_btns_text">Nominee</span>
            </button>
          </div>
          {tab === "Advance" ? (
            <div className="row ">
              <div className="col-md-3 mt-4  text-left p-0">
                <label className="input_label m-0">Contract Adv.(%):</label>
              </div>
              <div className="col-md-3 mt-4 p-0">
                <input
                  disabled={
                    updateData?.InstallmentsCreated === false ? false : true
                  }
                  type="text"
                  value={pecr}
                  onChange={(e) => {
                    if (Number(e.target.value) <= 100) {
                      let am =
                        (Number(e.target.value) / 100) * updateData.TotalPrice;
                      setamount(Math.round(am));
                      setpecr(e.target.value);
                    } else {
                      setamount(updateData.AdvanceAmount);
                      setpecr(updateData?.UpdatedAdvancePercent);
                    }
                  }}
                  className="input_styling ml-md-2 "
                  id="lenghtinch"
                  placeholder="Enter %"
                />
              </div>
              <div className="col-md-3 mt-4  text-left p-0">
                <label className="input_label m-0">Amount:</label>
              </div>
              <div className="col-md-3 mt-4 p-0">
                <input
                  disabled={
                    updateData?.InstallmentsCreated === false ? false : true
                  }
                  value={amount?.toLocaleString()}
                  type="text"
                  onChange={(e) => {
                    let advance =
                      (parseFloat(e.target.value.replace(/,/g, "")) /
                        updateData?.TotalPrice) *
                      100;
                    setpecr(Math.round(advance).toFixed(2));
                    setamount(e.target.value);
                  }}
                  className="input_styling ml-md-2 "
                  id="lenghtinch"
                />
              </div>
            </div>
          ) : tab === "Nominee" ? (
            <UpdateNominee
              setshowModal={setshowModal}
              nomineeData={updateData?.Nominee}
              updateData={updateData}
            />
          ) : (
            <div className="row ">
              <div className="col-md-3 mt-4  text-left p-0">
                <label className="input_label m-0">Commission (%):</label>
              </div>
              <div className="col-md-3 mt-4 p-0">
                <input
                  type="text"
                  value={commpecr}
                  onChange={(e) => {
                    if (Number(e.target.value) <= 100) {
                      let am =
                        (Number(e.target.value) / 100) * updateData?.TotalPrice;
                      setcommamount(Math.round(am));
                      setcommpecr(e.target.value);
                    } else {
                      setcommamount(updateData.AdvanceAmount);
                      setcommpecr(updateData?.ComissionPer);
                    }
                  }}
                  className="input_styling ml-md-2 "
                  id="le"
                  placeholder="Enter %"
                />
              </div>
              <div className="col-md-3 mt-4  text-left p-0">
                <label className="input_label m-0">Commission Amount:</label>
              </div>
              <div className="col-md-3 mt-4 p-0">
                <input
                  value={commamount?.toLocaleString()}
                  type="text"
                  onChange={(e) => {
                    let advance =
                      (parseFloat(e.target.value?.replace(/,/g, "")) /
                        updateData?.TotalPrice) *
                      100;
                    setcommpecr(
                      isNaN(advance) ? "" : Math.round(advance).toFixed(2)
                    );
                    setcommamount(e.target.value);
                  }}
                  className="input_styling ml-md-2 "
                  id="l"
                />
              </div>
            </div>
          )}
          <div className="row m-0 "></div>
        </div>

        <div className="row pr-md-2 pt-md-4">
          <div className=" col-6 pr-0 pl-0  "></div>
          <div className=" col-6 pr-0 pl-0  text-right ">
            {loading2 && (
              <Loader type="ThreeDots" color="green" height={40} width={40} />
            )}

            {tab === "Advance" ? (
              <>
                <span>
                  <button
                    className="cancel_btn ml-2  "
                    onClick={() => setshowModal(false)}
                  >
                    Cancel
                  </button>
                </span>
                <span>
                  <button
                    className="save_btn ml-2  "
                    id="instasatn"
                    disabled={
                      loading2 ||
                      (updateData?.InstallmentsCreated === false ? false : true)
                    }
                    onClick={() => {
                      handleAdd();
                    }}
                  >
                    <BsCloudUpload size="1.3em" />
                    Save
                  </button>
                </span>
              </>
            ) : tab === "Commission" ? (
              <>
                <span>
                  <button
                    className="cancel_btn ml-2  "
                    onClick={() => setshowModal(false)}
                  >
                    Cancel
                  </button>
                </span>
                <span>
                  <button
                    className="save_btn ml-2  "
                    id="instasatn1"
                    onClick={() => {
                      handleAddCom();
                    }}
                  >
                    <BsCloudUpload size="1.3em" />
                    Save
                  </button>
                </span>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { BsCloudUpload, BsFillInfoSquareFill } from "react-icons/bs";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getallProject } from "../../../actions/getprojects";
import { getallrecoverybooking } from "../../../actions/vouchersactions/getrecoverybooking";
import ScreenInfoModal from "../../../ScreenInfoModal/ScreenInfoModal";

export const RecoveryBookingForm = (props) => {
  const unitReceiptData = useSelector(
    (state) => state.getRecoveryBookingPopulate
  );
  const resultsproject = useSelector((state) => state.getProject);
  let dispatch = useDispatch();
  let vouchernumber = `RB-${Math.floor(Date.now())}`;
  const [statemanagerlist, setmanagerlist] = useState({});
  const [stateunitlist, setunitlist] = useState();
  const [showDetailModal, setshowDetailModal] = useState(false);

  const savedata = () => {
    if (document.getElementById("Date").value === "") {
      alert("Date is Required");
    } else if (document.getElementById("project").selectedIndex === 0) {
      alert("Select Project");
    } else if (document.getElementById("manager").selectedIndex === 0) {
      alert("Select Manager");
    } else if (document.getElementById("unit").selectedIndex === 0) {
      alert("Select Unit");
    } else if (document.getElementById("Amount").value === "") {
      alert("Amount can't be empty ");
    } else if (document.getElementById("narration").value === "") {
      alert("Narration can't be empty ");
    } else if (
      parseFloat(document.getElementById("Amount").value.replace(/,/g, "")) >
      parseFloat(stateunitlist.AdvanceAmount) -
        parseFloat(stateunitlist.AdvanceReceived)
    ) {
      alert(
        `Amount should be less than ${
          stateunitlist !== undefined
            ? parseFloat(stateunitlist.AdvanceAmount) -
              parseFloat(stateunitlist.AdvanceReceived)
            : null
        }`
      );
    } else {
      let data = {
        VoucherNo: document.getElementById("Voucher").value,
        EntryDate: document.getElementById("Date").value,
        Narration: document.getElementById("narration").value,
        ContractId: stateunitlist.ContractId,
        ManagerId: parseInt(
          document.getElementById("manager").options[
            document.getElementById("manager").selectedIndex
          ].dataset.id
        ),
        UnitId: parseInt(
          document.getElementById("unit").options[
            document.getElementById("unit").selectedIndex
          ].dataset.id
        ),
        NetPaid: parseFloat(
          document.getElementById("Amount").value.replace(/,/g, "")
        ),
        ProjectId: parseInt(
          document.getElementById("project").options[
            document.getElementById("project").selectedIndex
          ].dataset.id
        ),
      };
      document.getElementById("recoverysave").disabled = true;
      axios({
        method: "post",
        url: `${window.$url}Vouchers/RecoveryBooking/Add`,
        data: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("recoverysave").disabled = false;
          if (result.data.IsSuccess === true) {
            document.getElementById("project").selectedIndex = 0;
            document.getElementById("unit").selectedIndex = 0;
            document.getElementById("manager").selectedIndex = 0;
            // document.getElementById("banks").selectedIndex = 0;
            // document.getElementById("chequeno").value = "";
            // document.getElementById("vouchertype").selectedIndex = 0;

            document.getElementById("Amount").value = "";
            document.getElementById("narration").value = "";
            setmanagerlist({});
            setunitlist();

            dispatch(getallrecoverybooking());

            alert(result.data.Message);
            props.cancelbookingtable();
          } else {
            alert(result.data.Message);
          }
        },
        (error) => {
          alert("Someting went wrong");
          document.getElementById("recoverysave").disabled = false;
        }
      );
    }
  };
  useEffect(() => {
    dispatch(getallrecoverybooking());
    dispatch(getallProject());
  }, [dispatch]);

  return (
    <>
      {unitReceiptData.loading ? (
        <Loader type="ThreeDots" color="green" height={40} width={40} />
      ) : null}

      <div className="row ">
        <div className="col-md-1 mt-4  p-0">
          <label className="input_label m-0 pl-md-4">Project:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <select className="input_styling ml-md-3 " id="project">
            <option>Select</option>
            {resultsproject.data.map((val, index) =>
              parseInt(`${localStorage.getItem("ProjectId")}`) ===
              parseInt(val.ProjectId) ? (
                <option key={index} data-id={val.ProjectId}>
                  {val.ProjectName}
                </option>
              ) : null
            )}
          </select>
        </div>
        <div className="col-md-1 mt-4  p-0">
          <label className="input_label m-0 pl-md-4">Manager:</label>
        </div>
        <div className="col-md-3 mt-4">
          <select
            className="input_styling ml-md-3 "
            id="manager"
            onChange={() => {
              setmanagerlist(
                unitReceiptData.data[
                  document.getElementById("manager").options[
                    document.getElementById("manager").selectedIndex
                  ].dataset.idx
                ]
              );
              document.getElementById("unit").selectedIndex = 0;
            }}
          >
            <option>Select</option>
            {unitReceiptData.data.map((val, index) => (
              <option data-idx={index} data-id={val.ManagerId}>
                {val.Employee !== undefined ? val.Employee.EmpName : null}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-1 mt-4 text-left p-0">
          <label className="input_label m-0 pl-md-4">Unit:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <select
            className="input_styling ml-md-3 "
            id="unit"
            onChange={() => {
              setunitlist(
                statemanagerlist.Contract[
                  document.getElementById("unit").options[
                    document.getElementById("unit").selectedIndex
                  ].dataset.idx
                ]
              );
            }}
          >
            <option>Select</option>
            {statemanagerlist !== undefined &&
            statemanagerlist.Contract !== undefined
              ? statemanagerlist.Contract.map((x, index) => (
                  <option key={index} data-idx={index} data-id={x.UnitId}>
                    {x.Unit.UnitName}
                  </option>
                ))
              : null}
          </select>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Date:</label>
        </div>
        <div className="col-md-3 mt-4  ">
          <input
            type="date"
            className="input_styling ml-md-3"
            defaultValue={moment().format("YYYY-MM-DD")}
            id="Date"
          ></input>
        </div>
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Voucher No:</label>
        </div>
        <div className="col-md-3 mt-4  ">
          <input
            type="text"
            disabled
            value={vouchernumber}
            className="input_styling ml-md-3"
            id="Voucher"
          ></input>
        </div>
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Amount:</label>
        </div>
        <div className="col-md-3 mt-4  ">
          <input
            type="text"
            className="input_styling ml-md-3 "
            defaultValue={
              stateunitlist !== undefined
                ? parseFloat(
                    stateunitlist.AdvanceAmount - stateunitlist.AdvanceReceived
                  ).toLocaleString()
                : null
            }
            id="Amount"
            onChange={(e) => {
              if (e.target.value.length > 0)
                document.getElementById("Amount").value = parseInt(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
            }}
          ></input>
        </div>
      </div>

      <div className="row ">
        <div className="col-md-1 mt-4  p-0">
          <label className="input_label m-0 pl-md-4">Narration:</label>
        </div>
        <div className="col-md-3 mt-4  ">
          <textarea className="input_address ml-md-3" id="narration"></textarea>
        </div>
      </div>

      <div className=" float-right mt-md-4 mr-md-3">
        <span>
          <button
            className="save_btn ml-2  "
            onClick={() => {
              setshowDetailModal(true);
            }}
          >
            <BsFillInfoSquareFill size="1em" className="mr-1" />
            Detail
          </button>
        </span>
        <span>
          <button
            className="cancel_btn ml-2  "
            onClick={props.cancelbookingtable}
          >
            Cancel
          </button>
        </span>

        <span>
          <button
            className="save_btn ml-2 "
            id="recoverysave"
            onClick={savedata}
          >
            <BsCloudUpload size="1.3em" />
            Save
          </button>
        </span>
      </div>

      {showDetailModal ? (
        <ScreenInfoModal
          setshowModal={setshowDetailModal}
          screenName="Recovery Booking"
        />
      ) : null}
    </>
  );
};

import axios from 'axios';
import { toast } from "react-toastify";
export const GetAllLenders = (data) => {

    return ({
        type: "get_alllenders",
        payload: data,
        loader: false
    })
}
export const GetLenderData = (data) => {

    return ({
        type: "get_lendersdata",
        payload: data,

    })
}
export function getalllenders() {
    return (dispatch) => {
        axios({
            // url: `${window.$url}Lenders/GetLenders`,
            url: `${window.$url}Buyers/GetAll`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllLenders(result.data.Buyers));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}
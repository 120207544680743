import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { toast } from "react-toastify";

export const DealSoldCancel = (props) => {
    const [valmarlas, setMarlas] = useState();
    // function RateCalculation(){
    //     let valM;
    //     if (val.RatePerMarlaOrsqft == "MARLA") {
    //         let marla = val.Marla.toString();
    //         let sarsai = val.Sarsai.toString();
    //         console.log(marla)
    //         let marlasarsai = marla.concat(".", sarsai, sarsai, sarsai, sarsai);
    //         valM = parseFloat(marlasarsai);
    //         setMarlas(parseFloat(marlasarsai));
    //         setmarlafieldchange("MARLA");
    //     }
    //     if (val.RatePerMarlaOrsqft == "SQFT") {
    //         valM = parseFloat(val.NetSqft);
    //         setMarlas(parseFloat(val.NetSqft));
    //         setmarlafieldchange("SQFT")
    //     }
    // }
    const savedata = () => {



        let DealSold = {
            DealSoldId: props.statecaneldataList1.DealSoldId
        };



        let UnitsDeatils = [];
        props.statecaneldataList1.DealSoldUnits.forEach((x, idx) => {
            if (document.getElementById(`dealsoldtablefield${idx}`) !== null) {
                UnitsDeatils.push({
                    UnitId: x.Unit.UnitId,
                    DealUnitId: x.DealUnitId,
                    NewSaleRate: document.getElementById(`dealsoldtablefield${idx}`).value,
                    TotalSaleValue: document.getElementById(`dealsoldtotalsalefield${idx}`).value,

                })
            }
        })



        document.getElementById("loansave").disabled = true;
        axios({
            method: "post",
            url: `${window.$url}Deals/Sold/Expired`,
            data: JSON.stringify({
                DealSold, UnitsDeatils
            }

            ),
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                document.getElementById("loansave").disabled = false;
                if (result.data.IsSuccess === true) {

                    toast.info(result.data.Message);
                    props.cancelloantable();

                }
                else {
                    toast.info(result.data.Message);
                }


            },
            (error) => {
                toast.info("Someting went wrong");
                document.getElementById("loansave").disabled = false;

            }
        );


    };

    return (
        <>

            <div className="row">
                <div className="col-12  page_heading">
                    <h4 className="  text-center pt-2">Cancel Deal</h4>


                </div>
            </div>
            <div className="row">
                <div className="col-6 mt-2">

                    <ul>
                        <li>
                            <b>Project Name:</b>{" "}
                            {props.statecaneldataList1 !== undefined ? props.statecaneldataList1.Project.ProjectName : null}
                        </li>
                        <li>
                            <b>Affiliate:</b>{" "}
                            {props.statecaneldataList1 !== undefined ? props.statecaneldataList1.AffiliateCnicNavigation.AffiliateName : null}
                        </li>
                        <li>
                            <b> Entry Date:</b>{" "}
                            {props.statecaneldataList1 !== undefined ?moment(props.statecaneldataList1.EntryDate.split("T")[0]).format("DD-MMM-YYYY") : null}
                        </li>
                        <li>
                            <b>Expiry Date:</b>{" "}
                            {props.statecaneldataList1 !== undefined ?moment(props.statecaneldataList1.ExpDate.split("T")[0]).format("DD-MMMM-YYYY") : null}
                        </li>
                        <li>
                            <b>Advance%:</b>{" "}
                            {props.statecaneldataList1 !== undefined ? props.statecaneldataList1.AdvancePer : null}
                        </li>

                    </ul>

                </div>
                <div className="col-6 mt-2">
                    <ul>

                        <li>
                            <b>Deal Name:</b>{" "}
                            {props.statecaneldataList1 !== undefined ? props.statecaneldataList1.DealName : null}
                        </li>
                        <li>
                            <b>Amount:</b>{" "}
                            {props.statecaneldataList1 !== undefined ? props.statecaneldataList1.Amount.toLocaleString() : null}
                        </li>

                        <li>
                            <b>Amount Received:</b>{" "}
                            {props.statecaneldataList1 !== undefined ? props.statecaneldataList1.AmountReceived.toLocaleString() : null}
                        </li>


                        <li>
                            <b>Commission%:</b>{" "}
                            {props.statecaneldataList1 !== undefined ? props.statecaneldataList1.CommissionPer : null}
                        </li>


                        <li>
                            <b>Profit Margin%:</b>{" "}
                            {props.statecaneldataList1 !== undefined ? props.statecaneldataList1.ProfitMarginPer : null}
                        </li>
                        <li>
                            <b>Note:</b>{" "}
                            {props.statecaneldataList1 !== undefined ? props.statecaneldataList1.Note : null}
                        </li>


                    </ul>
                </div>
            </div>
            <table class="table ">
                <thead class="purple whiteText">
                    <tr>

                        <th scope="col">Unit</th>
                        <th scope="col">Size</th>
                        <th scope="col">Sqft</th>

                        <th scope="col">OldSaleRate</th>
                        <th scope="col">NewSaleRate</th>

                        <th scope="col">TotalSaleValue</th>
                        {/* <th scope="col">AdvanceAmount</th> */}



                    </tr>
                </thead>
                <tbody>
                    {props.statecaneldataList1 === undefined || props.statecaneldataList1.DealSoldUnits === undefined

                        // contractinstallment.length == 0
                        ? null
                        : props.statecaneldataList1.DealSoldUnits.map((x, idx) => x.Unit.Status !== "SOLD" ? (
                            <tr key={idx}>
                                <td>{x.Unit.UnitName.toLocaleString()}</td>
                                <td>{x.Unit.Marla + "M " + x.Unit.Sarsai + " S"}</td>
                                <td>{x.Unit.NetSqft}</td>



                                <td>{x.OldSaleRate.toLocaleString()}</td>

                                <td>
                                    <input
                                        type="number"
                                        id={`dealsoldtablefield${idx}`}
                                        className="input_styling"
                                        // defaultValue="0"
                                        // placeholder={x.Ratemarla}
                                        defaultValue={x.NewSaleRate}
                                        onChange={
                                            (e) => {
                                                // settotalsaleamount(parseFloat(e.target.value))
                                                // document.getElementById(`dealsoldtotalsalefield${idx}`).value = Math.round((parseFloat(x.marlas) * parseFloat(e.target.value)) * 10) / 10
                                                let currentval = e.target.value;


                                                if (x.Unit.RatePerMarlaOrsqft == "MARLA") {
                                                    let marla = x.Unit.Marla.toString();
                                                    let sarsai = x.Unit.Sarsai.toString();
                                                    let marlasarsai = marla.concat(".", sarsai, sarsai, sarsai, sarsai);

                                                    let total =
                                                        parseFloat(marlasarsai) *
                                                        parseFloat(currentval);
                                                    document.getElementById(`dealsoldtotalsalefield${idx}`).value = Math.ceil(total);

                                                }

                                                else if (x.Unit.RatePerMarlaOrsqft == "SQFT") {
                                                    let total =
                                                        parseFloat(x.Unit.NetSqft) * parseFloat(currentval);

                                                    document.getElementById(`dealsoldtotalsalefield${idx}`).value = Math.ceil(total);

                                                }

                                            }
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        id={`dealsoldtotalsalefield${idx}`}
                                        className="input_styling"
                                        disabled
                                        defaultValue={x.TotalSaleValue}
                                    />
                                </td>

                                {/* <td>{ }</td>

                                <td>{x.NewSaleRate.toLocaleString()}</td>

                                <td>{x.TotalSaleValue.toLocaleString()}</td>

                                <td>{x.AdvanceAmount.toLocaleString()}</td> */}



                            </tr>
                        ) : null)}
                </tbody>
            </table>

            <div className="row">
                <div className="col-md-7 mt-4 text-left p-0">

                </div>




            </div>
            <div className=" float-right mt-md-4 ">
                <span>
                    <button
                        className="cancel_btn ml-2  "
                        onClick={props.cancelloantable}
                    >

                        Cancel
                    </button>
                </span>

                <span>
                    <button className="save_btn ml-2  " onClick={savedata}  >
                        <BsCloudUpload size="1.3em" id="loansave" />
                        Save
                    </button>
                </span>
            </div>
        </>
    );
};
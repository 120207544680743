import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactDOM from "react-dom";
import { BsCloudUpload } from "react-icons/bs";
import { Country, State, City } from "country-state-city";
import { getallcustomers } from "../../../actions/getclients";
import { GetAllCustomersRed } from "../../../reducers/getclients";
import Loader from "react-loader-spinner";
// import { BsCloudUpload } from "react-icons/bs";

function AddBuyerModal({setshowBuyerModal}) {
    const clientdatastate = useSelector((state) => state.getCustomersdata);
    const customerPopulate = useSelector((state) => state.getAllClients);
    const [statemanagers, setmanagersdata] = useState();
    const [allCountries, setallCountries] = useState([]);
    const [allStates, setallStates] = useState([]);
    const [allCity, setallCity] = useState([]);
    const [selectedCountries, setselectedCountries] = useState(null);
    const [selectedStates, setselectedStates] = useState(null);
    const [selectedCity, setselectedCity] = useState(null);
    const [loader1, setloader1] = useState(false);

    useEffect(() => {
      const data = Country?.getAllCountries()?.map((country) => ({
        value: country.name,
        displayValue: `${country.name}-${country.isoCode}`,
        isoCode: country.isoCode,
      }));
      setallCountries(data);
    }, [Country]);
    useEffect(() => {
      const data = State.getStatesOfCountry(
        selectedCountries?.split("-")[1]
      )?.map((state) => ({
        value: state.name,
        displayValue: `${state.name}-${state.isoCode}`,
      }));
      setallStates(data);
    }, [selectedCountries]);
    console.log(City.getCitiesOfState(
      selectedCountries?.split("-")[1],
      selectedStates?.split("-")[1]
    ).map((city) => ({
      value: city.name,
      displayValue: city.name,
    })))
    useEffect(() => {
      const data = City.getCitiesOfState(
        selectedCountries?.split("-")[1],
        selectedStates?.split("-")[1]
      ).map((city) => ({
        value: city.name,
        displayValue: city.name,
      }));
      console.log(data);
      setallCity(data);
    }, [selectedCountries, selectedStates]);
    
    function numberWithCommas(x) {
      let num = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    let dispatch = useDispatch();
    let populatedata = {};
    if (
      clientdatastate.customerdata !== undefined ||
      clientdatastate.customerdata !== null
    ) {
      populatedata = clientdatastate.customerdata;
    } else {
      populatedata = clientdatastate;
    }
    console.log(populatedata.Occupation);
      function emptyfields() {
        setselectedCountries("")
        document.getElementById("modalbuyerName").value = "";
        document.getElementById("modalbuyerGuardian").value = "";
        document.getElementById("modalbuyerCNIC").value = "";
        document.getElementById("modalbuyerEmail").value = "";
        document.getElementById("modalbuyerCountry").selectedIndex = 0;
        document.getElementById("modalbuyerState").selectedIndex = 0;
        document.getElementById("modalbuyerCity").selectedIndex = 0;
        document.getElementById("modalbuyerOccupation").value = "";
        document.getElementById("modalbuyerCell1").value = "";
        document.getElementById("modalbuyerCell2").value = "";
        document.getElementById("modalbuyerAddress").value = "";
        document.getElementById("modalbuyerPhone").value = "";
        document.getElementById("modalbuyerRating").value = "";
      }
      
      
  
    function addCustomer() {
      let regexp = new RegExp("^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$");
      let regexphone = new RegExp("^[0-9+]{4}-[0-9+]{7}$");
      let regexptcl = new RegExp("^[0-9+]{3}-[0-9+]{7}$");
      let check = document.getElementById("modalbuyerCNIC").value;
      let checkcell1 = document.getElementById("modalbuyerCell1").value;
      let checkcell2 = document.getElementById("modalbuyerCell2").value;
      let checkptcl = document.getElementById("modalbuyerPhone").value;
      if (document.getElementById("modalbuyerName").value === "") {
        toast.info("Name is required");
      } else if (document.getElementById("modalbuyerCNIC").value === "") {
        toast.info("CNIC is required");
      } else if (!regexp.test(check)) {
        toast.info("Invalid CNIC. Format should be like xxxxx-xxxxxxx-x");
      } else if (document.getElementById("modalbuyerAddress").value === "") {
        toast.info("Address is required");
      } else if (document.getElementById("modalbuyerGuardian").value === "") {
        toast.info("Guardian is required");
      }
      else if (
        !regexptcl.test(checkptcl) &&
        document.getElementById("modalbuyerPhone").value !== ""
      ) {
        toast.info("Invalid Phone. Format should be like xxx-xxxxxxx");
      } else if (document.getElementById("modalbuyerCell1").value === "") {
        toast.info("cell no.1  is required");
      } else if (!regexphone.test(checkcell1)) {
        toast.info("Invalid Cell No. 1 Format should be like xxxx-xxxxxxx");
      } else if (
        !regexphone.test(checkcell2) &&
        document.getElementById("modalbuyerCell2").value !== ""
      ) {
        toast.info("Invalid Cell No. 2 Format should be like xxxx-xxxxxxx");
      } else if (
        document.getElementById("modalbuyerCity").options[
          document.getElementById("modalbuyerCity").selectedIndex
        ].value === "Select"
      ) {
        toast.info("Select any city");
      } else if (
        document.getElementById("modalbuyerState").options[
          document.getElementById("modalbuyerState").selectedIndex
        ].value === "Select"
      ) {
        toast.info("Select any city");
      } else if (
        document.getElementById("modalbuyerCountry").options[
          document.getElementById("modalbuyerCountry").selectedIndex
        ].value === "Select"
      ) {
        toast.info("Select  country");
      } else {
        setloader1(true)


        var ClientDetails = clientdatastate;
  
        ClientDetails.CustomerName = document.getElementById("modalbuyerName").value;
        ClientDetails.CustomerAddress = document.getElementById("modalbuyerAddress").value;
        ClientDetails.CustomerCnic = document.getElementById("modalbuyerCNIC").value;
        ClientDetails.ContactNumber1 = document.getElementById("modalbuyerCell1").value;
        ClientDetails.ContactNumber2 = document.getElementById("modalbuyerCell2").value;
        ClientDetails.PhoneNumber = document.getElementById("modalbuyerPhone").value;
        ClientDetails.Email = document.getElementById("modalbuyerEmail").value;
        ClientDetails.Occupation = document.getElementById("modalbuyerOccupation").value;
        ClientDetails.ClientRating = document.getElementById("modalbuyerRating").value;
        ClientDetails.GuardianName = document.getElementById("modalbuyerGuardian").value;
  
        ClientDetails.City =
          document.getElementById("modalbuyerCity").options[
            document.getElementById("modalbuyerCity").selectedIndex
          ].value;
        ClientDetails.Country =
          document.getElementById("modalbuyerCountry").options[
            document.getElementById("modalbuyerCountry").selectedIndex
          ].value;
        ClientDetails.State =
          document.getElementById("modalbuyerState").options[
            document.getElementById("modalbuyerState").selectedIndex
          ].value;
  
        document.getElementById("clientsave").disabled = true;
        let pgurl = `${window.$url}Customers/Add?ProjectId=${localStorage.getItem(
          "ProjectId"
        )}`;
        // if (props.stateedit === "yes") {
        //   pgurl = `${window.$url}Customers/Edit?ProjectId=${localStorage.getItem(
        //     "ProjectId"
        //   )}`;
        // }
        axios({
          url: pgurl,
          method: "Post",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "content-type": "application/json",
          },
          data: JSON.stringify(ClientDetails),
        }).then(
          (result) => {
            document.getElementById("clientsave").disabled = false;
    
            if (result.data.IsSuccess === true) {
                dispatch(getallcustomers());
                toast.info(result.data.Message);
                setloader1(false) 
                emptyfields();
                setshowBuyerModal(false)
            } else {
                toast.info(result.data.Message);
                setloader1(false) 
            }
        },
        (error) => {
            setloader1(false) 
            toast.info("Something went wrong!");
            document.getElementById("clientsave").disabled = false;
        }
        );
      }
      // emptyfields()
    }
    //    auto suggestion
    var Clientsuggestionlist = [];
    const bclientonTextChange = (e) => {
      const value = e.target.value;
      if (value === "") {
        ReactDOM.render(null, document.getElementById("bclientdemo"));
      } else {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = customerPopulate.data
            .sort()
            .filter((v) => regex.test(v.ManagerClientCnic));
        }
  
        Clientsuggestionlist = suggestions;
        // suggestionlist.push("Add New");
        if (Clientsuggestionlist.length === 0) {
          ReactDOM.render(null, document.getElementById("bclientdemo"));
        } else {
          ReactDOM.render(
            <ul className="suggestbox">
              {Clientsuggestionlist.map((item) => (
                <li
                  className="listcss"
                  onClick={() => bClientsuggestionselected(item)}
                >
                  {item === undefined ? item : item.ManagerClientCnic}
                </li>
              ))}
            </ul>,
            document.getElementById("bclientdemo")
          );
        }
      }
     
    };
    const bClientsuggestionselected = (val) => {
      document.getElementById("jBuyer").value = val.ManagerClientCnic;
      document.getElementById("modalbuyerName").value = val.ManagerClientName;
      document.getElementById("modalbuyerEmail").value = val.Email;
      document.getElementById("modalbuyerOccupation").value = val.Occupation;
      document.getElementById("modalbuyerCNIC").value = val.ManagerClientCnic;
      document.getElementById("modalbuyerCity").value = val.City;
      document.getElementById("modalbuyerCountry").value = val.Country;
      document.getElementById("modalbuyerRating").value = val.ManagerClientRating;
      document.getElementById("modalbuyerPhone").value = val.PhoneNumber;
      document.getElementById("modalbuyerCell1").value = val.ContactNumber1;
      document.getElementById("modalbuyerCell2").value = val.ContactNumber2;
      document.getElementById("modalbuyerAddress").value = val.Address;
  
      // setmanagersdata(val.CustomerCnic);
      Clientsuggestionlist = [];
      ReactDOM.render(null, document.getElementById("bclientdemo"));
    };
  
    const onTextChangeJBuyer = () => {
      var inputValue = document.getElementById("jBuyer").value;
      var options = document.getElementById("jbuyerlist").childNodes;
  
      let val;
      for (var i = 0; i < options.length; i++) {
        if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
          customerPopulate.data.forEach((x, idx) => {
            if (idx == options[i].attributes.index.value) {
              val = x;
            }
          });
          document.getElementById("jBuyer").value = val.ManagerClientCnic;
          document.getElementById("modalbuyerName").value = val.ManagerClientName;
          document.getElementById("modalbuyerEmail").value = val.Email;
          document.getElementById("modalbuyerOccupation").value = val.Occupation;
          document.getElementById("modalbuyerCNIC").value = val.ManagerClientCnic;
          document.getElementById("modalbuyerCity").value = val.City;
          document.getElementById("modalbuyerCountry").value = val.Country;
          document.getElementById("modalbuyerRating").value = val.ManagerClientRating;
          document.getElementById("modalbuyerPhone").value = val.PhoneNumber;
          document.getElementById("modalbuyerCell1").value = val.ContactNumber1;
          document.getElementById("modalbuyerCell2").value = val.ContactNumber2;
          document.getElementById("modalbuyerAddress").value = val.Address;
          break;
        }
      }
    };
    useEffect(() => {
      if (populatedata.Country !== null && populatedata.Country !== undefined) {
        setselectedCountries(populatedata.Country);
      }
      if (populatedata.State !== null && populatedata.State !== undefined) {
        setselectedStates(populatedata.State);
      }
      if (populatedata.City !== null && populatedata.City !== undefined) {
        setselectedCity(populatedata.City);
      }
    }, [populatedata]);
  return (
    <div style={{ display: "block" }} class="modalscreen ">
    <div class="modal-content-dashboard pt-1">
      <div className="row ">
        <div className="col-12  page_heading">
          <h4 className="  text-center ">Add Buyer</h4>
          <span
            class="close pr-2  "
            onClick={() => {
                setshowBuyerModal(false)
            }}
          >
            &times;
          </span>
        </div>
      </div>
      <div className="row ">
        <div className="col-12  mt-3">
        <>

      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Name :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3"
            id="modalbuyerName"
            defaultValue={populatedata.CustomerName}
          />
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Guardian :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3"
            id="modalbuyerGuardian"
            defaultValue={populatedata.GuardianName}
          />
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">CNIC</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="modalbuyerCNIC"
            placeholder="xxxxx-xxxxxxx-x"
            defaultValue={populatedata.CustomerCnic}
          />
          <span className="estaric">*</span>
        </div>

        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Email</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="email"
            className="input_styling ml-md-3 "
            id="modalbuyerEmail"
            defaultValue={populatedata.Email}
          />
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Country</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <select
            id="modalbuyerCountry"
            onChange={(e) => {
              setselectedCountries(e.target.value);
            }}
            className="input_styling ml-md-3"
            name="country"
            value={selectedCountries}
          >
            <option selected>Select</option>
            {allCountries?.map((item, i) => {
              return (
                <>
                  <option key={i} value={item?.displayValue?.toUpperCase()}>
                    {item?.displayValue?.toUpperCase()}
                  </option>
                </>
              );
            })}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>

        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">States</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <select
            className="input_styling ml-md-3"
            id="modalbuyerState"
            onChange={(e) => {
              setselectedStates(e.target.value);
            }}
            value={selectedStates}
          >
            <option selected>Select</option>
            {allStates?.map((item, i) => {
              return (
                <>
                  <option key={i} value={item?.displayValue?.toUpperCase()}>
                    {item?.displayValue?.toUpperCase()}
                  </option>
                </>
              );
            })}
          </select>
          <span className="estaric">*</span>
        </div>

      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">City</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <select
            className="input_styling ml-md-3"
            id="modalbuyerCity"
            onChange={(e) => {
              setselectedCity(e.target.value);
            }}
            value={selectedCity}
          >
            <option selected>Select</option>
            {allCity?.map((item, i) => {
              return (
                <>
                  <option key={i} value={item?.displayValue?.toUpperCase()}>
                    {item?.displayValue?.toUpperCase()}
                  </option>
                </>
              );
            })}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Occupation:</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3"
            id="modalbuyerOccupation"
            defaultValue={populatedata.Occupation}
          />
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Cell No 1:</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3"
            id="modalbuyerCell1"
            placeholder="xxxx-xxxxxxx"
            defaultValue={populatedata.ContactNumber1}
          />
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Cell No 2: </label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="modalbuyerCell2"
            placeholder="xxxx-xxxxxxx"
            defaultValue={populatedata.ContactNumber2}
          />
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Address:</label>
        </div>
        <div className="col-md-3 mt-4   p-0">
          <textarea
            className="input_address ml-md-3 "
            id="modalbuyerAddress"
            defaultValue={populatedata.CustomerAddress}
          ></textarea>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Phone No </label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="modalbuyerPhone"
            placeholder="xxx-xxxxxxx"
            defaultValue={populatedata.PhoneNumber}
          />
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Customer Rating:</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3"
            id="modalbuyerRating"
            defaultValue={populatedata.ClientRating}
            onChange={(e) => {
              if (e.target.value.length > 0)
                document.getElementById("modalbuyerRating").value = parseInt(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
            }}
          />
        </div>
      </div>

      <div className="row pr-md-2 pt-md-4">
        <div style={{display:"flex",justifyContent:"end",alignItems:"center"}} className=" col-12 pr-0 pl-0  text-right ">
          <span>
            <button
              className="cancel_btn ml-2  "
              onClick={() => {
                // props.cancelclienttable();
                emptyfields();
                setshowBuyerModal(false)
              }}
            >
              Cancel
            </button>
          </span>
          <span className="ml-2">
                    {loader1 && (
                        <>
                <Loader
                  type="Oval"
                  color="black"
                  height={30}
                  width={30}
                  radius={12}
                />
                </>
              )}
                    </span>
          <span>
            <button
              className="save_btn  ml-2"
              id="clientsave"
              onClick={addCustomer}
              disabled={loader1}
            >
              <BsCloudUpload size="1.3em" />
              Save
            </button>
          </span>
        </div>
      </div>
    </>
        </div>
      </div>
    </div>
  </div>
  )
}

export default AddBuyerModal
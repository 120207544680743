import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getallunits } from "../../../actions/getunit";

function UpdateUnitModal({
  creditordata,
  setshowUpdateModal,
  setcreditordata,
  editunitid,
}) {
  const [marlaCal, setMarlaCal] = useState(false);
  const dispatch = useDispatch();
  function radioonchange(e) {
    let currentval = parseInt(
      document.getElementById("Saleedit").value.replace(/,/g, "")
    ).toLocaleString();
    if (e.target.value === "MARLA") {
      let marla =
        document.getElementById("marlas") !== null
          ? document.getElementById("marlas").value
          : 0;
      let sarsai =
        document.getElementById("sarsai") !== null
          ? document.getElementById("sarsai").value
          : 0;
      let marlasarsai = marla.concat(".", sarsai, sarsai, sarsai, sarsai);
      document.getElementById("netmarlas").value = marlasarsai;
      let total =
        parseFloat(marlasarsai) * parseFloat(currentval.replace(/,/g, ""));
      let totalfinal = Math.ceil(total);
      document.getElementById("totalSaleedit").value =
        totalfinal.toLocaleString();
    } else if (e.target.value === "SQFT") {
      let dimenstionwidth = document.getElementById("dimentionwidth").value
        ? document.getElementById("dimentionwidth").value
        : "0";
      let dimenstionlength = document.getElementById("dimensionlenght").value
        ? document.getElementById("dimensionlenght").value
        : "0";

      let totalmarla =
        (Number(dimenstionwidth) * Number(dimenstionlength)) / 270;
      let netsqft = parseFloat(
        document.getElementById("netsqft").value.replace(/,/g, "")
      );
      document.getElementById("totalSaleedit").value = (
        parseFloat(netsqft) * parseFloat(currentval.replace(/,/g, ""))
      ).toLocaleString();
      console.log(totalmarla);
      document.getElementById("netmarlas").value = totalmarla.toFixed(4);
    }
  }
  const savedata = () => {
    document.getElementById("unitsave").disabled = true;

    axios({
      method: "post",
      url: `${window.$url}Projects/Units/Add`,
      data: JSON.stringify({
        UnitId: creditordata.UnitId,
        RatePerMarla: document.getElementById("Saleedit").value,
        Price: document.getElementById("totalSaleedit").value,
        RatePerMarlaOrsqft: marlaCal ? "MARLA" : "SQFT",
        DimensionLength: document.getElementById("dimensionlenght").value,
        DimensionWidth: document.getElementById("dimentionwidth").value,
        Marla: document.getElementById("marlas").value,
        Sarsai: document.getElementById("sarsai").value,
        NetSqft: document.getElementById("netsqft").value,
        NetMarla: document.getElementById("netmarlas").value,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        document.getElementById("unitsave").disabled = false;
        if (result.data.IsSuccess === true) {
          toast.info(result.data.Message);

          dispatch(getallunits());
          setshowUpdateModal(false);
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        document.getElementById("unitsave").disabled = false;
        console.log(error.response.title);
        toast.info("Something went wrong");
        console.log(error);
      }
    );
  };
  const calculaterate = () => {
    let salerate = document.getElementById("Saleedit").value;
    if (marlaCal === true) {
      let marla = document.getElementById("marlas").value?.toString();
      let sarsai = document.getElementById("sarsai").value?.toString();
      let marlasarsai = marla.concat(".", sarsai, sarsai, sarsai, sarsai);
      let total =
        parseFloat(marlasarsai) * parseFloat(salerate?.replace(/,/g, ""));
      let totalfinal = Math.ceil(total);
      document.getElementById("netmarlas").value = marlasarsai;
      document.getElementById("totalSaleedit").value =
        totalfinal.toLocaleString();
    } else {
      let total =
        parseFloat(
          document.getElementById("netsqft").value?.replace(/,/g, "")
        ) * parseFloat(salerate?.replace(/,/g, ""));
      document.getElementById("totalSaleedit").value = total?.toLocaleString();
    }
  };
  function LenghtCalculation() {
    if (document.getElementById("lenghtfeet").value !== null) {
      let lenghtfeet = document.getElementById("lenghtfeet").value
        ? document.getElementById("lenghtfeet").value
        : "0";
      let lengthinches = document.getElementById("lenghtinch").value
        ? document.getElementById("lenghtinch").value
        : "0";
      let widthfeet = document.getElementById("widthfeet").value
        ? document.getElementById("widthfeet").value
        : "0";
      let widthinch = document.getElementById("widthinch").value
        ? document.getElementById("widthinch").value
        : "0";
      // let totalmarla =
      //     parseFloat(lenghtfeet) * parseFloat(lengthinches) / 12;
      let totallenght = parseFloat(lenghtfeet) + parseFloat(lengthinches) / 12;
      let totalwidth = parseFloat(widthfeet) + parseFloat(widthinch) / 12;
      // console.log(totalmarla);
      // document.getElementById("netmarlas").value = totalmarla.toFixed(4);
      // document.getElementById("netsqft").value = parseFloat(totalsqft).toLocaleString();
      document.getElementById("dimensionlenght").value =
        parseFloat(totallenght).toFixed(4);
      document.getElementById("dimentionwidth").value =
        parseFloat(totalwidth).toFixed(4);
      document.getElementById("netsqft").value = parseFloat(
        parseFloat(totallenght) * parseFloat(totalwidth)
      ).toLocaleString();
      let totalmarla = (parseFloat(totallenght) * parseFloat(totalwidth)) / 270;
      document.getElementById("netmarlas").value = totalmarla.toFixed(4);
      calculaterate()
    }
  }
  useEffect(() => {
    if (creditordata) {
      setMarlaCal(creditordata?.RatePerMarlaOrsqft === "MARLA" ? true : false);
    }
  }, [creditordata]);
  return (
    <div
      style={{ display: "block", maxWidth: "fit-content !important" }}
      class="modal"
    >
      <div class="modal-content-deal">
        <div className="row">
          <div className="col-12  page_heading">
            <h4 className="  text-center pt-2">Update Unit</h4>
            <span
              class="close pr-2  "
              onClick={() => setshowUpdateModal(false)}
            >
              &times;
            </span>
          </div>
        </div>

        <div className="row">
          <div className="col-md-2 mt-4 text-left pl-2">
            <label className="input_label m-0">Lenght(ft):</label>
          </div>

          <div className="col-md-1 mt-4  p-0">
            <input
              type="number"
              className="input_styling ml-md-3 "
              id="lenghtfeet"
              onChange={LenghtCalculation}
              placeholder="feet"
              defaultValue={
                creditordata.DimensionLength !== undefined &&
                creditordata.DimensionLength !== null
                  ? creditordata?.DimensionLength
                  : null
              }
            />
          </div>
          <div className="col-md-1 mt-4 p-0" style={{ marginLeft: "7px" }}>
            <input
              type="number"
              onChange={LenghtCalculation}
              className="input_styling ml-md-2 "
              id="lenghtinch"
              defaultValue={"0"}
              placeholder="inch"
            />
          </div>
          <div className="col-md-1 mt-4 p-0" style={{ marginLeft: "7px" }}>
            <input
              disabled
              type="number"
              className="input_styling "
              id="dimensionlenght"
              placeholder="Total"
              defaultValue={
                creditordata.DimensionLength !== undefined &&
                creditordata.DimensionLength !== null
                  ? creditordata?.DimensionLength
                  : null
              }
            />
          </div>
          <div className="col-md-1 mt-4 text-left pl-2"></div>
          <div className="col-md-2 mt-4  text-left p-0">
            <label className="input_label m-0">Width(ft):</label>
          </div>
          <div className="col-md-1 mt-4  p-0">
            <input
              type="number"
              onChange={LenghtCalculation}
              className="input_styling  "
              id="widthfeet"
              placeholder="feet"
              defaultValue={
                creditordata.DimensionWidth !== undefined &&
                creditordata.DimensionWidth !== null
                  ? creditordata?.DimensionWidth
                  : null
              }
            />
          </div>
          <div className="col-md-1 mt-4 p-0" style={{ marginLeft: "7px" }}>
            <input
              onChange={LenghtCalculation}
              type="number"
              className="input_styling"
              id="widthinch"
              defaultValue={"0"}
              placeholder="inch"
            />
          </div>
          <div className="col-md-1 mt-4 p-0" style={{ marginLeft: "7px" }}>
            <input
              type="number"
              className="input_styling "
              id="dimentionwidth"
              placeholder="Total"
              disabled
              defaultValue={
                creditordata.DimensionWidth !== undefined &&
                creditordata.DimensionWidth !== null
                  ? Number(creditordata?.DimensionWidth)
                  : null
              }
            />
          </div>
          <div className="col-md-2 mt-4 text-left pl-2">
            <label className="input_label m-0">Area Value:</label>
          </div>

          <div className="col-md-1 mt-4  p-0">
            <input
              type="number"
              className="input_styling ml-md-3 "
              defaultValue={
                creditordata.Marla !== undefined && creditordata.Marla !== null
                  ? creditordata.Marla
                  : null
              }
              onChange={calculaterate}
              id="marlas"
              placeholder="marla"
            />
          </div>
          <div className="col-md-1 mt-4  p-0" style={{ marginLeft: "7px" }}>
            <input
              type="number"
              defaultValue={
                creditordata.Sarsai !== undefined &&
                creditordata.Sarsai !== null
                  ? creditordata.Sarsai
                  : null
              }
              style={{ width: "100% important" }}
              className="input_styling ml-md-2 "
              placeholder="sarsai"
              id="sarsai"
              onChange={calculaterate}
            />
          </div>
          <div className="col-md-1 mt-4 p-0"></div>

          <div className="col-md-1 mt-4 text-left pl-2"></div>

          <div className="col-md-2 mt-4  text-left p-0">
            <label className="input_label m-0 ">Net Sq/ft:</label>
          </div>
          <div className="col-md-3 mt-4   p-0 ">
            <input
              type="text"
              disabled
              className="input_styling ml-md-2"
              id="netsqft"
              defaultValue={
                creditordata.NetSqft !== undefined &&
                creditordata.NetSqft !== null
                  ? creditordata.NetSqft
                  : null
              }

              //    onChange={(e) => { if (e.target.value.length > 0) document.getElementById("netsqft").value = parseFloat(e.target.value.replace(/,/g, '')).toLocaleString() }}
            />
          </div>
          <div className="col-md-2 mt-4 text-left pl-2">
            <label className="input_label m-0 ">Rate Per:</label>
          </div>
          <div className="col-md-2 mt-4 text-left  pl-2">
            <span>Marla</span>
            <input
              type="radio"
              value="MARLA"
              checked={marlaCal}
              name="radiomarla"
              className="ml-2"
              id="radiomarlas"
              onClick={(e) => {
                radioonchange(e);
                setMarlaCal(true);
              }}
            />
          </div>
          <div className="col-md-2 text-left  mt-4  p-0">
            <span>Sq.Ft</span>
            <input
              type="radio"
              value="SQFT"
              name="radiomarla"
              className="ml-2"
              id="radiosqft"
              checked={!marlaCal}
              onClick={(e) => {
                radioonchange(e);
                setMarlaCal(false);
              }}
            />
          </div>

          <div className="col-md-2 mt-4 text-left pl-2">
            <label className="input_label m-0 ">Net Marla:</label>
          </div>
          <div className="col-md-4 mt-4   p-0 ">
            <input
              type="number"
              className="input_styling ml-md-3"
              id="netmarlas"
              disabled
              defaultValue={
                creditordata?.NetMarla ? creditordata?.NetMarla : null
              }
            />
          </div>

          <div className="col-md-2 mt-4 text-left pl-2">
            <label className="input_label m-0">Sale Rate:</label>
          </div>
          <div className="col-md-4 mt-4   p-0">
            <input
              type="text"
              className="input_styling ml-md-3 "
              defaultValue={
                creditordata.RatePerMarla !== undefined &&
                creditordata.RatePerMarla !== null
                  ? creditordata.RatePerMarla.toLocaleString()
                  : null
              }
              key={editunitid !== undefined ? editunitid : null}
              id="Saleedit"
              onChange={(e) => {
                if (e.target.value !== "" && e.target.value?.length > 0) {
                  document.getElementById("Saleedit").value = parseInt(
                    e.target.value.replace(/,/g, "")
                  ).toLocaleString();
                  if (marlaCal == true) {
                    let marla = document
                      .getElementById("marlas")
                      .value?.toString();
                    let sarsai = document
                      .getElementById("sarsai")
                      .value?.toString();
                    let marlasarsai = marla.concat(
                      ".",
                      sarsai,
                      sarsai,
                      sarsai,
                      sarsai
                    );
                    let total =
                      parseFloat(marlasarsai) *
                      parseFloat(
                        document
                          .getElementById("Saleedit")
                          .value?.replace(/,/g, "")
                      );
                    let totalfinal = Math.ceil(total);
                    document.getElementById("totalSaleedit").value =
                      totalfinal.toLocaleString();
                  } else {
                    let netsqft = document
                      .getElementById("netsqft")
                      ?.value?.replace(/,/g, "");
                    document.getElementById("totalSaleedit").value = (
                      parseFloat(netsqft) *
                      parseFloat(e.target.value.replace(/,/g, ""))
                    ).toLocaleString();
                  }
                } else {
                  document.getElementById("Saleedit").value = "";
                  document.getElementById("totalSaleedit").value = "";
                }
              }}
            />
          </div>
          {/* <div className="col-md-2 mt-4 text-left pl-2">
                        </div> */}
          <div className="col-md-2 mt-4 text-left pl-2">
            <label className="input_label m-0">Total Sale:</label>
          </div>
          <div className="col-md-4 mt-4   p-0">
            <input
              type="text"
              className="input_styling ml-md-3 "
              disabled
              defaultValue={
                creditordata.Price !== undefined && creditordata.Price !== null
                  ? creditordata.Price.toLocaleString()
                  : null
              }
              key={editunitid !== undefined ? editunitid : null}
              id="totalSaleedit"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-4 text-right pl-2">
            <button className="save_btn  ml-2" id="unitsave" onClick={savedata}>
              <BsCloudUpload size="1.3em" />
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateUnitModal;

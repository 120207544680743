import axios from 'axios';
import { toast } from "react-toastify";
export const GetAllLand = (data) => {

    return ({
        type: "get_alllands",
        payload: data,
        loader: false
    })
}
export function getallland() {
    return (dispatch) => {
        axios({
            url: `${window.$url}Land/GetLand`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
            if (result.data.IsSuccess === true) {
                    dispatch(GetAllLand(result.data.Data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}

import React, { useState } from 'react'
import ChangeInReqForm from './ChangeInReqForm'
import ChangeInReqTable from './ChangeInReqTable'

const ChangeInReqMain=()=> {
    const [ShowForm,setShowForm]=useState(false)

    return (
      <div>
  
  {
      ShowForm?(
  <ChangeInReqForm setShowForm={setShowForm}/>
      ):(
  <ChangeInReqTable setShowForm={setShowForm}/>
      )
  }
  
      </div>
    )
}

export default ChangeInReqMain

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BsCloudUpload } from 'react-icons/bs';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getallNotifications } from '../../../actions/vouchersactions/GetAllNottifications';
import { CSVLink } from "react-csv";

function AllNotification() {
    const [apiLoading, setapiloading] = useState(false);
    const [projectFilter, setProjectFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const dispatch = useDispatch();
    const [allnotificationData, setallnotificationData] = useState([]);
    const Notification = useSelector((state) => state.getallsNotification);
    useEffect(() => {
        dispatch(getallNotifications());
    }, [dispatch]);
    useEffect(() => {
        if (Notification?.data?.Data) {
            setallnotificationData(JSON.parse(JSON.stringify(Notification?.data?.Data)));
        }

    }, [Notification?.data?.Data]);
    console.log(Notification?.data );
    const onTextChange = (e) => {

        if (document.getElementById("projectNotifilter").value === "" &&
            document.getElementById("statusNotifilter").value === ""
        ) {
            setallnotificationData(Notification?.data?.Data);

        } else {
            let suggestions = [];
            {
                const regex = new RegExp(document.getElementById("projectNotifilter").value, "i");
                const regex1 = new RegExp(document.getElementById("statusNotifilter").value, "i");





                suggestions = Notification?.data?.Data
                    .sort()
                    .filter((val) =>
                        (document.getElementById("projectNotifilter").value === "" || regex.test(val.Project?.ProjectName)) &&
                        (document.getElementById("statusNotifilter").value === "" || regex1.test(val.Status)) 





                    );

            }


            setallnotificationData(suggestions);
        }
    };
  console.log(allnotificationData);

  const [CsvData, setCsvData] = useState([]);
  useEffect(() => {
    let arr = [];

    allnotificationData?.forEach((itm, idx) => {
      arr.push({
        Sr: idx + 1,
        Date: moment(itm?.Date).format("DD-MMM-YYYY"),
        "Voucher No": itm?.GeneralVoucherId,
        Project: itm?.Project?.ProjectName,
        Amount: itm?.Amount?.toLocaleString(),
        Narration: itm?.Narration,
        Status: itm?.Status,
        "Marked By": itm?.MarkedByNavigation?.UserName,
        "Marked Date": itm?.MarkedOnDate
          ? moment(itm?.MarkedOnDate).format("DD-MM-YYYY")
          : null,
      });
    });
    setCsvData(arr);
  }, [allnotificationData]);

    return (
      <div className="row">
        <div className="dash_background col-12 p-0">
          <div className="row ">
            <div className="col-md-12">
              <>
                <div className="row pt-3">
                  <div className="col-md-1 pr-0 pl-0">
                    <label className="input_label m-0">Search:</label>
                  </div>
                  {/* <div className="col-md-3 pr-0 pl-0">

                            <input type="text " className="input_styling " id="datefilter" onChange={onTextChange} placeholder="User"></input>

                        </div> */}
                  <div className="col-md-3 pr-0 pl-0">
                    <input
                      type="text "
                      className="input_styling "
                      id="projectNotifilter"
                      onChange={onTextChange}
                      placeholder="Project"
                    ></input>
                  </div>
                  <div className="col-md-3 pr-0 pl-0">
                    <input
                      type="text "
                      className="input_styling "
                      id="statusNotifilter"
                      onChange={onTextChange}
                      placeholder="Status"
                    ></input>
                  </div>

                  <div className="col-md-1 pr-0 pl-0">
                    {Notification?.data?.IsSuccess ? null : (
                      <Loader
                        type="ThreeDots"
                        color="green"
                        height={40}
                        width={40}
                      />
                    )}
                  </div>
                </div>

                <div className="table-responsive mt-2">
                  <table className="table table-borderless m-0">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th>Date</th>
                        <th>Voucher No</th>
                        <th>Project</th>
                        <th>Amount</th>
                        <th>Narration</th>
                        <th>Status</th>
                        <th>Marked By</th>
                        <th>Marked Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allnotificationData
                        ?.filter(
                          (itm) =>
                            itm.Project?.ProjectName.toLowerCase().includes(
                              projectFilter.toLowerCase()
                            ) &&
                            itm.Status.toLowerCase().includes(
                              statusFilter.toLowerCase()
                            )
                        )
                        .map((itm, idx) => {
                          return (
                            <tr key={idx}>
                              <td className="table_data">{idx + 1}</td>
                              <td className="table_data">
                                {moment(itm?.Date).format("DD-MMM-YYYY")}
                              </td>
                              <td className="table_data">
                                {itm?.GeneralVoucherId}
                              </td>
                              <td className="table_data">
                                {itm?.Project?.ProjectName}
                              </td>

                              <td className="table_data">
                                {itm?.Amount?.toLocaleString()}
                              </td>
                              <td className="table_data">{itm?.Narration}</td>

                              <td className="table_data">{itm?.Status}</td>
                              <td className="table_data">
                                {itm?.MarkedByNavigation?.UserName}
                              </td>
                              <td className="table_data">
                                {itm?.MarkedOnDate
                                  ? moment(itm?.MarkedOnDate).format(
                                      "DD-MM-YYYY"
                                    )
                                  : null}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <button
                  style={{ color: "black !important" }}
                  className="print_btn ml-2 float-left"
                >
                  <CSVLink
                    style={{ color: "black" }}
                    filename="All_Notification_Csv"
                    data={CsvData}
                  >
                    Download CSV
                  </CSVLink>
                </button>
              </>
            </div>
            <div className="col-md-12"></div>
          </div>
        </div>
      </div>
    );
}

export default AllNotification
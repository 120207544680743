import axios from 'axios';
import { toast } from "react-toastify";
export const GetAllLoanTableData = (data) => {

    return ({
        type: "get_allloantabledata",
        payload: data,
        loader: false
    })
}
export function getloanpopulatetable() {
    return (dispatch) => {
        axios({
            // url: `${window.$url}Lenders/GetLoans`, 
            url: `${window.$url}Investment/Get?projectId=${localStorage.getItem("ProjectId")}`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllLoanTableData(result.data.Data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}

import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import noImage from "../../../../assets/noImage.png";
import {
  getallclients,
  getallcustomers,
  GetCustomerData,
} from "../../../actions/getclients";
import { CustomerPrint } from "../ContractPrint/CustomerPrint";
import { CSVLink } from "react-csv";
import CustomerModal from "./CustomerModal";

export const CustomerTable = (props) => {
  const [clientdata, setclientdata] = useState({});
  const resultallcustomers = useSelector((state) => state.getAllCustomersRed);
  const [filteredCustomers, setfilteredCustomers] = useState([]);

  let dispatch = useDispatch();
  // print function
  const componentRef = useRef();

  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  //Table Data fiter

  const onTextChange = () => {
    if (
      document.getElementById("filterCustomerByname").value === "" &&
      document.getElementById("filterCustomerBycnic").value === ""
    ) {
      setfilteredCustomers(resultallcustomers.data);
    } else {
      let suggestions = [];

      {
        const regex = new RegExp(
          document.getElementById("filterCustomerByname").value,
          "i"
        );
        const regex1 = new RegExp(
          document.getElementById("filterCustomerBycnic").value,
          "i"
        );

        suggestions = resultallcustomers.data
          .sort()
          .filter(
            (val) =>
              (document.getElementById("filterCustomerByname").value === "" ||
                regex.test(val.CustomerName)) &&
              (document.getElementById("filterCustomerBycnic").value === "" ||
                regex1.test(val.CustomerCnic))
          );
      }
      setfilteredCustomers(suggestions);
    }
  };

  useEffect(() => {
    dispatch(getallcustomers());
    dispatch(getallclients());
  }, [dispatch]);

  useEffect(() => {
    if (resultallcustomers.data) {
      setfilteredCustomers(resultallcustomers.data);
    }
  }, [resultallcustomers.data]);

  function showmodal(data) {
    document.getElementById("mymodal").style.display = "block";
    setclientdata(data);
  }
  function closemodal() {
    document.getElementById("mymodal").style.display = "none";
  }

  const [CsvData, setCsvData] = useState([]);
  useEffect(() => {
    let arr = [];

    filteredCustomers?.forEach((val, index) => {
      arr.push({
        Sr: index + 1,
        Name: val.CustomerName,
        CNIC: val.CustomerCnic2,
        "Guardian Name": val.GuardianName,
        "Contact No.": val.ContactNumber1,
        City: val.City,
      });
    });
    setCsvData(arr);
  }, [filteredCustomers]);
  return (
    <>
      <div hidden>
        <CustomerPrint ref={componentRef} printData={resultallcustomers} />
      </div>
      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-3 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            onChange={onTextChange}
            placeholder="Customer Name "
            id="filterCustomerByname"
          />
        </div>

        <div className="col-md-3 pr-0 pl-0">
          <input
            type="number"
            className="input_styling "
            onChange={onTextChange}
            placeholder="Customer CNIC "
            id="filterCustomerBycnic"
          />
        </div>

        {/* <div className="col-md-3 text-center  pr-0 pl-0">
                      <label className="input_label m-0">From:</label>
                      <input type="date" className="input_date ml-3" id="from date"></input>
                  </div>
                  <div className="col-md-3 text-center pr-0 pl-0">
                      <label className="input_label m-0">To:</label>
                      <input type="date" className="input_date ml-3" id="todate" defaultValue={moment().format("YYYY-MM-DD")}></input>
                  </div> */}
        {/* <div className="col-md-1 pr-0 pl-0">
                      <button className="btn_Go">Go</button>
                  </div> */}
        <div className="col-md-1 pr-0 pl-0">
          <CustomerModal />
        </div>
        <div className="col-md-1 pr-0 pl-0">
          {resultallcustomers.loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </div>
      </div>
      <div className="row ">
        <div className=" col-12  table_height pr-0 pl-0">
          <div className="table-responsive mt-4">
            <table className="table table-borderless table-hover m-0">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Name</th>

                  <th>CNIC</th>
                  <th>Guardian Name</th>
                  <th>Contact No.</th>
                  <th>City</th>

                  <th className="text-center">
                    <button
                      className="save_btn"
                      onClick={() => {
                        props.showclienttable();
                        props.setedit("No");
                      }}
                    >
                      New+
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredCustomers?.map((val, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      showmodal(val);
                    }}
                  >
                    <td className="table_data">{index + 1}</td>
                    <td className="table_data">{val.CustomerName}</td>

                    <td className="table_data">{val.CustomerCnic2}</td>
                    <td className="table_data">{val.GuardianName}</td>
                    <td className="table_data">{val.ContactNumber1}</td>
                    <td className="table_data">{val.City}</td>
                    <td className="text-center">
                      <FiEdit2 size="1em" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-6  text-right pr-0 pl-0">
          <button
            className="print_btn float-left"
            onClick={() => {
              printPaymentRec();
            }}
          >
            <TiPrinter size="1.3em" />
            Print
          </button>
          <button
            style={{ color: "black !important" }}
            className="print_btn ml-2 float-left"
          >
            <CSVLink
              style={{ color: "black" }}
              filename="Customer_Csv"
              data={CsvData}
            >
              Download CSV
            </CSVLink>
          </button>
        </div>
      </div>

      <div id="mymodal" class="modal">
        <div class="modal-content-cat">
          <div className="row">
            <div className="col-12  page_heading">
              <h4 className="  text-center pt-2">Customer Details</h4>
              <span class="close pr-2  " onClick={closemodal}>
                &times;
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-2 text-center">
              {!clientdata?.Image ? (
                <img
                  src={noImage}
                  classNameF="rounded-circle"
                  style={{ width: "150px" }}
                  alt="Avatar"
                />
              ) : (
                <img
                  src={`${clientdata?.Image}`}
                  alt={"profile"}
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%",
                  }}
                />
              )}
            </div>
            <div className="col-6 mt-2">
              <ul>
                <li>
                  <b>Name:</b> {clientdata.CustomerName}
                </li>
                <li>
                  <b>Email:</b> {clientdata.Email}
                </li>
                <li>
                  <b>CNIC:</b> {clientdata.CustomerCnic2}
                </li>
                <li>
                  <b>Occupation:</b> {clientdata.Occupation}
                </li>
                <li>
                  <b>Contact No.1:</b> {clientdata.ContactNumber1}
                </li>
                <li>
                  <b>Contact No.2:</b> {clientdata.ContactNumber2}
                </li>
              </ul>
            </div>
            <div className="col-6 mt-2">
              <ul>
                <li>
                  <b>GuardianName:</b> {clientdata.GuardianName}
                </li>
                <li>
                  <b>Client Rating:</b> {clientdata.ClientRating}
                </li>
                <li>
                  <b>Country:</b> {clientdata.Country}
                </li>
                <li>
                  <b>State:</b> {clientdata.State}
                </li>
                <li>
                  <b>City:</b> {clientdata.City}
                </li>
                <li>
                  <b>Phone No:</b> {clientdata.PhoneNumber}
                </li>
                <li>
                  <b>Address:</b> {clientdata.CustomerAddress}
                </li>
              </ul>
            </div>
          </div>

          <div className="col-12 text-right">
            <button
              className=" save_btn   "
              onClick={() => {
                dispatch(GetCustomerData(clientdata));
                closemodal();
                props.setedit("yes");
                props.showclienttable();
              }}
            >
              <FiEdit2 size="0.8em" /> Edit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

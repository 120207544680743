import axios from "axios";
import { toast } from "react-toastify";
export const GetAllUnitReceipt = (data) => {
  return {
    type: "get_allunitreceipt",
    payload: data,
    loader: false,
  };
};
export function getallunitreceipt() {
  let pgurl = `${
    window.$url
  }Vouchers/Unit/Populate?ProjectId=${localStorage.getItem("ProjectId")}`;
  if (localStorage.getItem("ProjectId") === null) {
    pgurl = `${window.$url}Vouchers/Unit/Populate`;
  }
  return (dispatch) => {
    axios({
      url: pgurl,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllUnitReceipt(result.data.Data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}

export const GetAllUnitTableData = (data) => {
  return {
    type: "get_allunittabledata",
    payload: data,
    loader: false,
  };
};
export function getallunitpopulatetable(data, link) {
  return (dispatch) => {
    let pgurl = `${
      link ? link + "&" : window.$url + "Vouchers/UnitReceipt/GetAll?"
    }ProjectId=${localStorage.getItem("ProjectId")}&From=${
      data.From ? data.From : ""
    }&To=${data.To ? data.To : ""}&ContractId=${
      data.ContractId ? data.ContractId : ""
    }&CustomerCnic=${data.CustomerCnic ? data.CustomerCnic : ""}&Status=${
      data.Status ? data.Status : ""
    }&VoucherId=${data.VoucherId ? data.VoucherId : ""}
        `;
    if (localStorage.getItem("ProjectId") === null) {
      pgurl = `${window.$url}Vouchers/Unit/GetAll`;
    }
    // let ProjectId = `${localStorage.getItem("ProjectId")}`
    axios({
      url: pgurl,
      method: "Post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        if (result.data.Succeeded === true) {
          dispatch(GetAllUnitTableData(result.data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}

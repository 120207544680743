import axios from 'axios';
import { toast } from "react-toastify";
export const GetAllSell = (data) => {

    return ({
        type: "get_allSell",
        payload: data,
        loader: false
    })
}
export function getallSell() {
    return (dispatch) => {
        axios({
            url: `${window.$url}Assets/Sold/GetAll`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllSell(result.data.DAssetSales));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { GrPrint } from "react-icons/gr";
import { RiFileEditLine } from "react-icons/ri";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { getalljournalenrty } from "../../../actions/getjournalentry";
import JournalVoucherPrint, {
  JournalGeneralModal,
} from "../../accpayablepages/vouchersprints/jouralentryprint";
import { MdDeleteForever } from "react-icons/md";
import axios from "axios";
import { CSVLink } from "react-csv";
import JournalEntryModal from "./JournalEntryModal";


export const JournalEntryTable = (props) => {
  const resultsgetJE = useSelector((state) => state.getAllJournalEntry);
  const dispatch = useDispatch();
  const [TransactionData, setTransactionData] = useState();
  const [allData, setallData] = useState([]);

  const [printData, SetPrintData] = useState();
  const [statetoggle, settoggle] = useState(true);
  const rolesdata = useSelector((state) => state.GetAllRole);

  const [debitState, setdebitState] = useState(0);
  const [creditState, setcreditState] = useState(0);
  const [journalEntryRowdata, setjournalEntryRowdata] = useState(null);
  const [loading, setloading] = useState(false);
  const [disableBtn, setdisableBtn] = useState(true);
  const [selectedVouchers, setSelectedVouchers] = useState([]);
  const [rendarComp, setrendarComp] = useState(false);

  console.log(journalEntryRowdata);
  // print function
  const componentRef = useRef();

  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    if (printData !== undefined) {
      printPaymentRec();
    }
  }, [printData, statetoggle]);
  const [printDataModal, SetPrintDataModal] = useState();
  const componentRefModal = useRef();

  const printGenrealRecModal = useReactToPrint({
    content: () => componentRefModal.current,
  });

  //Table Data fiter

  var suggestionlist = [];

  const onTextChange = (e) => {
    if (
      document.getElementById("vochId")?.value === "" &&
      document.getElementById("submittedId")?.value === "all"
    ) {
      setallData(resultsgetJE?.data);
    } else {
      let suggestions = [];

      const regex = new RegExp(document.getElementById("vochId")?.value, "i");

      suggestions = resultsgetJE?.data
        .sort()
        .filter(
          (val) =>
            (document.getElementById("vochId").value === "" ||
              regex.test(val.GeneralVoucherId)) &&
            (document.getElementById("submittedId").value === "all"
              ? val.Submitted === false || val.Submitted === true
              : document.getElementById("submittedId").value === "false"
              ? val.Submitted === false
              : val.Submitted === true)
        );

      suggestionlist = suggestions;
      setallData(suggestionlist);
    }
  };
  useEffect(() => {
    let data = {};
    dispatch(getalljournalenrty(data));
  }, [dispatch]);
  useEffect(() => {
    if (resultsgetJE?.data) {
      setallData(resultsgetJE?.data);
    }
  }, [resultsgetJE]);
  function ongo() {
    let data = {
      ProjectId: `${localStorage.getItem("ProjectId")}`,
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
    };

    dispatch(getalljournalenrty(data));
  }
  function showmodal(data) {
    document.getElementById("mymodal").style.display = "block";
    setTransactionData(data);
    var credit = 0;
    var debit = 0;
    data?.map((val) =>
      val.TransactionType === "CREDIT"
        ? (credit += parseFloat(val.Amount))
        : val.TransactionType === "DEBIT"
        ? (debit += parseFloat(val.Amount))
        : null
    );
    setdebitState(debit);
    setcreditState(credit);
  }
  function closemodal() {
    document.getElementById("mymodal").style.display = "none";
  }
  function showmodal2(data) {
    setjournalEntryRowdata(data);
    document.getElementById("mymodal2").style.display = "block";
  }
  function closemodal2() {
    document.getElementById("mymodal2").style.display = "none";
    document.getElementById("journalEntryVoucherEmail").value = "";
    document.getElementById("journalEntryVoucherPassword").value = "";
    document.getElementById("journalEntryVoucherReason").value = "";
  }

  function deletejournalEntryVoucher() {
    if (document.getElementById("journalEntryVoucherEmail").value === "") {
      return toast.info("Email is Required");
    } else if (
      document.getElementById("journalEntryVoucherPassword").value === ""
    ) {
      return toast.info("Password is Required");
    } else if (
      document.getElementById("journalEntryVoucherReason").value === ""
    ) {
      return toast.info("Reason is Required");
    }
    setloading(true);
    setdisableBtn(false);
    const Email = document.getElementById("journalEntryVoucherEmail").value;
    const Password = document.getElementById(
      "journalEntryVoucherPassword"
    ).value;
    const Reason = document.getElementById("journalEntryVoucherReason").value;
    // const data={
    //     Email:Email,
    //   Password:Password,

    // }
    axios({
      method: "post",
      url: `${window.$url}Scripts/DeleteAllUnsubmittedVouchers`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
      data: {
        Password:Password,
        Email: Email,
        Reason: Reason,
        voucherId: selectedVouchers,
      },
    }).then(
      (result) => {
        console.log(result);
        // document.getElementById("headingsave").disabled = false;
        if (result.data.IsSuccess === true) {
          document.getElementById("journalEntryVoucherEmail").value = "";
          document.getElementById("journalEntryVoucherPassword").value = "";
          document.getElementById("journalEntryVoucherReason").value = "";
          let data = {};
          dispatch(getalljournalenrty(data));
          toast.info(result.data.Message);
          setdisableBtn(true);
          setloading(false);
          setjournalEntryRowdata(null);
          closemodal2();
        } else {
          toast.info(result.data.Message);
          document.getElementById("journalEntryVoucherEmail").value = "";
          document.getElementById("journalEntryVoucherPassword").value = "";
          document.getElementById("journalEntryVoucherReason").value = "";
          setdisableBtn(true);
          setloading(false);
          // setgeneralPaymentRowdata(null)
        }
      },
      (error) => {
        toast.info("Someting went wrong");
        // document.getElementById("headingsave").disabled = false;
      }
    );
  }

  function handleCheckboxChange(i) {
    console.log(allData)
    let arr = allData?.filter((itm) => itm.checked).map((itm) => itm.GeneralVoucherId);
    setSelectedVouchers(arr);
    // filterdata?.forEach((itm) => {
    //   if (itm.checked) {
    //     arr.push(itm.GeneralVoucherId
    //     )
    //   }
    // })
    // setSelectedVouchers(arr)
  }
  console.log(resultsgetJE?.data);
  const [CsvData, setCsvData] = useState([]);
  useEffect(() => {
    let arr = [];
    var i = 1;

    allData?.forEach((val) => {
      let year = val.EntryDate.slice(0, 4);
      let day = val.EntryDate.slice(8, 10);
      let month = val.EntryDate.slice(5, 7);
      arr.push({
        Sr: i++,
        Date: day + "-" + month + "-" + year,
        "Voucher No": val.GeneralVoucherId,
        Narration: val.Narration,
        Amount: val.Amount.toLocaleString(),
        User: val?.AddedBy?.UserName,
        Submitted: val.Submitted ? "True" : "False",
      });
    });
    setCsvData(arr);
  }, [allData]);
  return (
    <>
      <div hidden>
        <JournalVoucherPrint ref={componentRef} printData={printData} />
      </div>
      <div hidden>
        <JournalGeneralModal
          ref={componentRefModal}
          printData={printDataModal}
          Data={printData}
          total={debitState + creditState}
          debitTotal={debitState}
          creditTotal={creditState}
        />
      </div>
      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-3 pr-0 pl-0">
          <input
            type="text "
            id="vochId"
            className="input_styling "
            onChange={onTextChange}
            placeholder="Voucher"
          ></input>
        </div>
        <div className="col-md-3 pr-0 pl-0 ">
          <select
            className="input_styling "
            id="submittedId"
            onChange={onTextChange}
          >
            <option value={"all"}>All</option>
            <option value={false}>Pending</option>
            <option value={true}>Submitted</option>
          </select>
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <JournalEntryModal />
        </div>
      </div>
      <div className="row pt-3 ">
        <div className="col-md-3 text-center  pr-0 pl-0">
          <label className="input_label m-0">From:</label>
          <input type="date" className="input_date ml-3" id="fromdate"></input>
        </div>
        <div className="col-md-3 text-center pr-0 pl-0">
          <label className="input_label m-0">To:</label>
          <input type="date" className="input_date ml-3" id="todate"></input>
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <button className="btn_Go" onClick={ongo}>
            Go
          </button>
        </div>

        <div className="col-md-12 pr-0 pl-0">
          {selectedVouchers.length > 0 && (
            <div className="col-md-12 mt-2">
              <div className="float-right">
                {loading && (
                  <Loader
                    type="ThreeDots"
                    color="green"
                    height={40}
                    width={40}
                  />
                )}
                <span>
                  <button
                    onClick={() => {
                      showmodal2(selectedVouchers);
                    }}
                    className="cancel_btn ml-2"
                  >
                    <MdDeleteForever />
                    Delete All
                  </button>
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="col-md-1 pr-0 pl-0">
          {resultsgetJE.loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </div>
      </div>

      <div className="table-responsive mt-2">
        <table className="table table-borderless m-0">
          <thead>
            <tr>
              <th>Sr</th>
              <th>{""}</th>
              <th>Date</th>
              <th>Voucher No.</th>
              <th>Narration</th>
              <th>Amount</th>
              <th>User</th>
              <th>Submitted</th>
              <th className="text-center">
                {rolesdata?.data?.Access?.includes("C") ? (
                  <button
                    className="save_btn"
                    onClick={() => {
                      props.showjournaltable();
                    }}
                  >
                    New+
                  </button>
                ) : null}
              </th>
            </tr>
          </thead>
          <tbody>
            {allData?.map((val, index) => {
              let year = val.EntryDate.slice(0, 4);
              let day = val.EntryDate.slice(8, 10);
              let month = val.EntryDate.slice(5, 7);
              switch (month) {
                case "01":
                  month = "Jan";

                  break;
                case "02":
                  month = "Feb";

                  break;
                case "03":
                  month = "Mar";

                  break;
                case "04":
                  month = "Apr";

                  break;
                case "05":
                  month = "May";

                  break;
                case "06":
                  month = "Jun";

                  break;
                case "07":
                  month = "Jul";

                  break;
                case "08":
                  month = "Aug";

                  break;
                case "09":
                  month = "Sep";

                  break;
                case "10":
                  month = "Oct";
                  break;
                case "11":
                  month = "Nov";
                  break;
                case "12":
                  month = "Dec";
                  break;
                default:
                  break;
              }
              return (
                <tr
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    showmodal(val.Transaction);
                    SetPrintDataModal(val.Transaction);
                  }}
                >
                  <td
                    className="table_data"
                    // onClick={() => {
                    //   showmodal(val.Transaction);
                    //   SetPrintDataModal(val.Transaction);
                    // }}
                  >
                    {index + 1}
                  </td>
                  <td>
                    {localStorage.getItem("UserName") === "Admin" ? (
                      <input
                        checked={val.checked ? val.checked : false}
                        key={index}
                        onClick={(e)=>{
                    e.stopPropagation();

                        }}
                        onChange={(e) => {
                          e.stopPropagation();
                          allData[index].checked = e.target.checked;
                          setrendarComp(!rendarComp);
                          handleCheckboxChange(index);
                        }}
                        type="checkbox"
                        id={`checkedVoucher${index}`}
                      />
                    ) : null}
                  </td>
                  <td
                    className="table_data"
                    // onClick={() => {
                    //   showmodal(val.Transaction);
                    //   SetPrintDataModal(val.Transaction);
                    // }}
                  >
                    {day + "-" + month + "-" + year}
                  </td>

                  <td
                    className="table_data"
                    onClick={() => {
                      // showmodal(val.Transaction);
                      // SetPrintDataModal(val.Transaction);
                    }}
                  >
                    {val.GeneralVoucherId}
                  </td>
                  <td
                    className="table_data"
                    onClick={() => {
                      // showmodal(val.Transaction);
                      // SetPrintDataModal(val.Transaction);
                    }}
                  >
                    {val.Narration}
                  </td>
                  <td
                    className="table_data"
                    // onClick={() => {
                    //   showmodal(val.Transaction);
                    //   SetPrintDataModal(val.Transaction);
                    // }}
                  >
                    {val.Amount.toLocaleString()}
                  </td>
                  <td>{val?.AddedBy?.UserName}</td>
                  <td className="table_data">
                    {val.Submitted ? "True" : "False"}
                  </td>
                  <td className="text-center ">
                    {rolesdata?.data?.Access?.includes("U") ? (
                      <RiFileEditLine
                        title="Edit"
                        size="1em"
                        color={val.Submitted === false ? "black" : "#707070"}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (val.Submitted === false) {
                            props.setvoucherData(val.JournalVoucherSaved);
                          } else {
                            toast.info("Can't edit voucher");
                          }
                          // props.showgeneraltable();
                        }}
                        className="cur_sor"
                      />
                    ) : null}
                    &nbsp;
                    <GrPrint
                      onClick={(e) => {
                        e.stopPropagation();
                        SetPrintData(val);
                        settoggle(!statetoggle);
                      }}
                      size="1em"
                    />
                    &nbsp;
                    {localStorage.getItem("UserName") === "Admin" ? (
                      <MdDeleteForever
                        onClick={(e) => {
                          e.stopPropagation();
                          showmodal2(val);
                        }}
                        title="Delete"
                        className="cur_sor"
                        size="1em"
                      />
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <button
        style={{ color: "black !important" }}
        className="print_btn ml-2 float-left"
      >
        <CSVLink
          style={{ color: "black" }}
          filename="Journal_Entry_Csv"
          data={CsvData}
        >
          Download CSV
        </CSVLink>
      </button>

      <div id="mymodal2" class="modal">
        <div class="modal-content-cat_voucher2">
          <div className="row">
            <div className="col-12  page_heading">
              <h4 className="  text-center pt-2">Delete Voucher</h4>
              <span class="close pr-2  " onClick={closemodal2}>
                &times;
              </span>
            </div>
          </div>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-12 " style={{ paddingTop: "10px" }}>
              <label className="input_label m-0">Email:</label>
            </div>
            <div className="col-12 ">
              <input
                style={{ width: "100%" }}
                className="input_total_styling "
                placeholder="Email"
                id="journalEntryVoucherEmail"
                type="email"
              ></input>
            </div>
            <div className="col-12 " style={{ paddingTop: "10px" }}>
              <label className="input_label m-0">Password:</label>
            </div>
            <div className="col-12 ">
              <input
                style={{ width: "100%" }}
                className="input_total_styling "
                placeholder="Password"
                id="journalEntryVoucherPassword"
              ></input>
            </div>
            <div className="col-12  " style={{ paddingTop: "10px" }}>
              <label className="input_label m-0">Reason:</label>
            </div>
            <div className="col-12  " style={{ width: "100%" }}>
              <textarea
                style={{ width: "100%", height: "100px" }}
                className="input_total_styling "
                placeholder="Reason"
                id="journalEntryVoucherReason"
                rows="4"
              ></textarea>
            </div>
            <div
              style={{
                display: "flex",
                float: "right",
                width: "100%",
                justifyContent: "end",
                marginRight: "3%",
                marginTop: "10px",
                alignItems: "center",
              }}
            >
              {loading && (
                <Loader type="ThreeDots" color="green" height={40} width={40} />
              )}
              <div>
                <span>
                  <button
                    className="cancel_btn ml-2  "
                    onClick={() => {
                      closemodal2();
                      setjournalEntryRowdata(null);
                    }}
                  >
                    Cancel
                  </button>
                </span>
              </div>
              <div>
                <span>
                  <button
                    className="save_btn ml-2  "
                    disabled={!disableBtn}
                    onClick={deletejournalEntryVoucher}
                  >
                    Save
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="mymodal" class="modal">
        <div class="modal-content-cat_voucher">
          <div className="row">
            <div className="col-12  page_heading">
              <h4 className="  text-center pt-2">Transaction Detail</h4>
              <span class="close pr-2  " onClick={closemodal}>
                &times;
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-responsive_modal mt-4 table_height-voucher">
                <table className="table table-borderless m-0 ">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Account</th>
                      <th>Narration</th>
                      <th>Debit</th>

                      <th>Credit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {TransactionData !== undefined
                      ? TransactionData.map((x, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{x.Account.AccountTitle}</td>
                            <td>{x.Narration}</td>
                            <td>
                              {x.TransactionType === "DEBIT"
                                ? x.Amount.toLocaleString()
                                : null}
                            </td>

                            <td>
                              {x.TransactionType === "CREDIT"
                                ? x.Amount.toLocaleString()
                                : null}
                            </td>
                          </tr>
                        ))
                      : null}
                    <tr>
                      <td></td>
                      <td></td>

                      <td>
                        {" "}
                        <label className="input_label m-0">Total:</label>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="input_total_styling "
                          placeholder="Debit"
                          disabled
                          defaultValue={parseFloat(debitState).toLocaleString()}
                          key={debitState}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="input_total_styling "
                          placeholder="Credit"
                          disabled
                          defaultValue={parseFloat(
                            creditState
                          ).toLocaleString()}
                          key={creditState}
                        ></input>
                      </td>
                      {/* <td><input type="text" className="input_total_styling " placeholder="Balance" disabled value={stateledgerdata[stateledgerdata.length - 1]?.AccountBalance?.toLocaleString()}></input></td> */}
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 mt-2">
              <button
                className="print_btn float-left"
                onClick={() => {
                  printGenrealRecModal();
                }}
              >
                <TiPrinter size="1.3em" />
                Print
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

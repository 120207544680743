import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { GrPrint } from "react-icons/gr";
import { MdDeleteForever } from "react-icons/md";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { getallCRpopulatetable } from "../../../actions/vouchersactions/getcomreceipt";
import {
  CommissionReceiptPrint,
  CommissionRecModal,
} from "../../accpayablepages/vouchersprints/receiptprints";
import CommossionReceiptDeleteVoucherModal from "./CommissionReceiptDeleteVoucherModal";
import { CSVLink } from "react-csv";
import CommissionReceiptModal from "./CommissionReceiptModal";


export const CommissionReceiptTable = (props) => {
  const resultsrrtable = useSelector((state) => state.getAllComReceiptTable);
  const [TransactionData, setTransactionData] = useState();
  const [filterdata, setfilterdata] = useState();
  const [statetoggle, settoggle] = useState(true);
  const [printData, SetPrintData] = useState();
  const [debitState, setdebitState] = useState(0);
  const [creditState, setcreditState] = useState(0);
  const [SelecetdVoucher, setSelecetdVoucher] = useState(null);
  const rolesdata = useSelector((state) => state.GetAllRole);

  const [filterObject, setfilterObject] = useState({
    date: "",
    voucherNumber: "",
    seller: "",
    unit: "",
  });

  /////////// Delete Voucher Data and functions Start ///////////////
  const [DeleteVoucherData, setDeleteVoucherData] = useState({
    Email: "",
    Password: "",
    Reason: "",
  });
  const [showPassword, setshowPassword] = useState(false);
  const [loading, setloading] = useState(false);

  const closeDeleteVoucherModal = () => {
    document.getElementById("DeleteVoucherModal").style.display = "none";
    setSelecetdVoucher(null);
    setDeleteVoucherData({
      Email: "",
      Password: "",
      Reason: "",
    });
  };
  const handelDeleteVoucherdataChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setDeleteVoucherData({ ...DeleteVoucherData, [name]: value });
  };

  function deleteVoucher() {
    if (DeleteVoucherData?.Email === "") {
      return toast.info("Email is Required");
    } else if (DeleteVoucherData?.Password === "") {
      return toast.info("Password is Required");
    } else if (DeleteVoucherData?.Reason === "") {
      return toast.info("Reason is Required");
    }
    setloading(true);
    axios({
      method: "delete",
      url: `${window.$url}Scripts/DeleteCR?Email=${DeleteVoucherData?.Email}&Password=${DeleteVoucherData?.Password}&Reason=${DeleteVoucherData?.Reason}&voucherId=${SelecetdVoucher?.CommissionReceiptVoucherId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        setloading(false);
        if (result.data.IsSuccess === true) {
          setDeleteVoucherData({
            Email: "",
            Password: "",
            Reason: "",
          });
          toast.info(result.data.Message);
          dispatch(getallCRpopulatetable({}));
          closeDeleteVoucherModal();
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Someting went wrong");
      }
    );
  }

  /////////// Delete Voucher Data and functions End ///////////////

  // print function
  const componentRef = useRef();

  const printRecoveryRec = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    if (printData !== undefined) {
      printRecoveryRec();
    }
  }, [printData, statetoggle]);
  const [printDataModal, SetPrintDataModal] = useState();
  // print function
  const componentRefModal = useRef();

  const printGenrealRecModal = useReactToPrint({
    content: () => componentRefModal.current,
  });

  const onTextChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setfilterObject({ ...filterObject, [name]: value });
    if (
      document.getElementById("sellerfilter").value === "" &&
      document.getElementById("datefilter").value === "" &&
      document.getElementById("voucherfilter").value === "" &&
      document.getElementById("unitfilter").value === ""
    ) {
      setfilterdata(resultsrrtable.data);
    } else {
      let suggestions = [];
      {
        const regex = new RegExp(
          document.getElementById("sellerfilter").value,
          "i"
        );
        const regex1 = new RegExp(
          document.getElementById("voucherfilter").value,
          "i"
        );
        const regex2 = new RegExp(
          document.getElementById("datefilter").value,
          "i"
        );
        const regex3 = new RegExp(
          document.getElementById("unitfilter").value,
          "i"
        );

        suggestions = resultsrrtable.data
          .sort()
          .filter(
            (val) =>
              (document.getElementById("sellerfilter").value === "" ||
                regex.test(val.Seller.SellerName)) &&
              (document.getElementById("voucherfilter").value === "" ||
                regex1.test(val.CommissionReceiptVoucherId)) &&
              (document.getElementById("datefilter").value === "" ||
                regex2.test(val.EntryDate)) &&
              (document.getElementById("unitfilter").value === "" ||
                regex3.test(val.Unit.UnitName))
          );
      }
      setfilterdata(suggestions);
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    let data = {};
    dispatch(getallCRpopulatetable(data));
  }, [dispatch]);
  function showmodal(data) {
    document.getElementById("mymodal").style.display = "block";
    setTransactionData(data);
    var credit = 0;
    var debit = 0;
    data?.map((val) =>
      val.TransactionType === "CREDIT"
        ? (credit += parseFloat(val.Amount))
        : val.TransactionType === "DEBIT"
        ? (debit += parseFloat(val.Amount))
        : null
    );
    setdebitState(debit);
    setcreditState(credit);
  }
  function closemodal() {
    document.getElementById("mymodal").style.display = "none";
  }
  function ongo() {
    let data = {
      ProjectId: `${localStorage.getItem("ProjectId")}`,
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
    };

    dispatch(getallCRpopulatetable(data));
  }
  useEffect(() => {
    console.log(resultsrrtable);
    if (resultsrrtable.data) {
      setfilterdata(resultsrrtable.data);
    }
  }, [resultsrrtable]);

  const [CsvData, setCsvData] = useState([]);
  useEffect(() => {
    let arr = [];
    var i = 1;

    filterdata?.forEach((val) => {
      let year = val.EntryDate.slice(0, 4);
      let day = val.EntryDate.slice(8, 10);
      let month = val.EntryDate.slice(5, 7);
      arr.push({
        Sr: i++,
        Date: day + "-" + month + "-" + year,
        "Voucher No": val.CommissionReceiptVoucherId,
        Seller: val.Seller.SellerName,
        Unit: val.Unit !== undefined ? (val.Unit.UnitName) : (''),
        Amount: parseFloat(val.Amount).toLocaleString(),
      });
    });
    setCsvData(arr);
  }, [filterdata]);

  return (
    <>
      <div hidden>
        <CommissionReceiptPrint ref={componentRef} printData={printData} />
      </div>
      <div hidden>
        <CommissionRecModal
          ref={componentRefModal}
          total={debitState + creditState}
          debitTotal={debitState}
          creditTotal={creditState}
          printData={printDataModal}
        />
      </div>
      <div className="row pt-3">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            autoComplete="off"
            name="date"
            value={filterObject?.date}
            type="date"
            className="input_styling "
            id="datefilter"
            onChange={onTextChange}
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
          />
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            autoComplete="off"
            name="voucherNumber"
            value={filterObject?.voucherNumber}
            type="text"
            className="input_styling "
            id="voucherfilter"
            onChange={onTextChange}
            placeholder="Voucher"
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
          />
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            autoComplete="off"
            name="seller"
            value={filterObject?.seller}
            type="text"
            className="input_styling "
            id="sellerfilter"
            onChange={onTextChange}
            placeholder="Seller"
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
          />
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            name="unit"
            value={filterObject?.unit}
            type="text"
            autoComplete={"false"}
            autoCorrect="off"
            className="input_styling "
            id="unitfilter"
            onChange={onTextChange}
            placeholder="Unit"
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
          />
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <CommissionReceiptModal />
        </div>
        <div className="col-md-1 pr-0 pl-0">
          {resultsrrtable.loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </div>
      </div>

      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0"></div>
        <div className="col-md-4   pr-0 pl-0">
          <label className="input_label m-0">From:</label>
          <input type="date" className="input_date ml-3" id="fromdate"></input>
        </div>
        <div className="col-md-4  pr-0 pl-0">
          <label className="input_label m-0">To:</label>
          <input type="date" className="input_date ml-3" id="todate"></input>
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <button className="btn_Go" onClick={ongo}>
            Go
          </button>
        </div>
      </div>

      <div className="table-responsive vouchers_table_height mt-4">
        <table className="table table-borderless m-0">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Date</th>
              <th>Voucher No.</th>
              <th>Seller</th>
              <th>Unit</th>
              <th>Amount</th>
              <th className="text-center">
                {rolesdata?.data?.Access?.includes("C") ? (
                  <button
                    className="save_btn"
                    onClick={() => {
                      props.showcommissiontable();
                    }}
                  >
                    New+
                  </button>
                ) : null}
              </th>
            </tr>
          </thead>
          <tbody>
            {filterdata !== undefined
              ? filterdata.map((val, index) => {
                  let year = val.EntryDate.slice(0, 4);
                  let day = val.EntryDate.slice(8, 10);
                  let month = val.EntryDate.slice(5, 7);
                  switch (month) {
                    case "01":
                      month = "Jan";

                      break;
                    case "02":
                      month = "Feb";

                      break;
                    case "03":
                      month = "Mar";

                      break;
                    case "04":
                      month = "Apr";

                      break;
                    case "05":
                      month = "May";

                      break;
                    case "06":
                      month = "Jun";

                      break;
                    case "07":
                      month = "Jul";

                      break;
                    case "08":
                      month = "Aug";

                      break;
                    case "09":
                      month = "Sep";

                      break;
                    case "10":
                      month = "Oct";
                      break;
                    case "11":
                      month = "Nov";
                      break;
                    case "12":
                      month = "Dec";
                      break;
                    default:
                      break;
                  }
                  return (
                    <tr key={index}>
                      <td
                        className="table_data"
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="table_data"
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {day + "-" + month + "-" + year}
                      </td>

                      <td
                        className="table_data"
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {val.CommissionReceiptVoucherId}
                      </td>
                      {val.Seller !== undefined ? (
                        <td
                          className="table_data"
                          onClick={() => {
                            showmodal(val.Transaction);
                            SetPrintDataModal(val.Transaction);
                          }}
                        >
                          {val.Seller.SellerName}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      {/* {val.Project !== undefined && val.Project !== null ?
                                      <td>{val.Project.ProjectName}</td> : <td></td>} */}
                      {val.Unit !== undefined ? (
                        <td
                          className="table_data"
                          onClick={() => {
                            showmodal(val.Transaction);
                            SetPrintDataModal(val.Transaction);
                          }}
                        >
                          {val.Unit.UnitName}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td
                        className="table_data"
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {parseFloat(val.Amount).toLocaleString()}
                      </td>

                      <td className="text-center cur_sor">
                        <GrPrint
                          size="1em"
                          onClick={() => {
                            SetPrintData(val);
                            settoggle(!statetoggle);
                          }}
                        />
                        &nbsp;
                        {localStorage.getItem("UserName") === "Admin" ? (
                          <MdDeleteForever
                            onClick={() => {
                              setSelecetdVoucher(val);
                              document.getElementById(
                                "DeleteVoucherModal"
                              ).style.display = "block";
                            }}
                            title="Delete"
                            className="cur_sor"
                            size="1em"
                          />
                        ) : null}
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
      <button
        style={{ color: "black !important" }}
        className="print_btn ml-2 float-left"
      >
        <CSVLink
          style={{ color: "black" }}
          filename="Commission_Receipt_Csv"
          data={CsvData}
        >
          Download CSV
        </CSVLink>
      </button>
      <div id="mymodal" class="modal">
        <div class="modal-content-cat_voucher">
          <div className="row">
            <div className="col-12  page_heading">
              <h4 className="  text-center pt-2">Transaction Detail</h4>
              <span class="close pr-2  " onClick={closemodal}>
                &times;
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-2">
              <div className="table-responsive_modal mt-4 table_height-voucher">
                <table className="table table-borderless m-0 ">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Account</th>
                      <th>Narration</th>
                      <th>Debit</th>

                      <th>Credit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {TransactionData !== undefined
                      ? TransactionData.map((x, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>

                            <td>{x.Account.AccountTitle}</td>
                            <td>{x.Narration}</td>
                            <td>
                              {x.TransactionType === "DEBIT"
                                ? x.Amount.toLocaleString()
                                : null}
                            </td>

                            <td>
                              {x.TransactionType === "CREDIT"
                                ? x.Amount.toLocaleString()
                                : null}
                            </td>
                          </tr>
                        ))
                      : null}
                    <tr>
                      <td></td>
                      <td></td>

                      <td>
                        {" "}
                        <label className="input_label m-0">Total:</label>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="input_total_styling "
                          placeholder="Debit"
                          disabled
                          defaultValue={parseFloat(debitState).toLocaleString()}
                          key={debitState}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="input_total_styling "
                          placeholder="Credit"
                          disabled
                          defaultValue={parseFloat(
                            creditState
                          ).toLocaleString()}
                          key={creditState}
                        ></input>
                      </td>
                      {/* <td><input type="text" className="input_total_styling " placeholder="Balance" disabled value={stateledgerdata[stateledgerdata.length - 1]?.AccountBalance?.toLocaleString()}></input></td> */}
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 mt-2">
              <button
                className="print_btn float-left"
                onClick={() => {
                  printGenrealRecModal();
                }}
              >
                <TiPrinter size="1.3em" />
                Print
              </button>
            </div>
          </div>
        </div>
      </div>

      <CommossionReceiptDeleteVoucherModal
        setSelecetdVoucher={setSelecetdVoucher}
        DeleteVoucherData={DeleteVoucherData}
        handelDeleteVoucherdataChange={handelDeleteVoucherdataChange}
        closeDeleteVoucherModal={closeDeleteVoucherModal}
        showPassword={showPassword}
        setshowPassword={setshowPassword}
        loading={loading}
        deleteVoucher={deleteVoucher}
      />
    </>
  );
};

import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import { BsCloudUpload } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { getallLoanPopulate, getallLoanTable } from "../../actions/HRactions/advanceloanaction";

import axios from "axios";
import { MdDeleteForever } from "react-icons/md";
import { toast } from "react-toastify"

import { FiEdit2 } from "react-icons/fi";






import Loader from "react-loader-spinner";
import moment from "moment";
export const PayLoan = (props) => {
    const loanpopulate = useSelector(state => state.GetAllLoanPopulate);
    const [stateempid, setempid] = useState()
    let dispatch = useDispatch();
    var suggestionlist1 = [];
    const [state, setstate] = useState({

        EmployeeLoanId: 0,// 0 for add other for edit
        LoanAmount: null,
        ReturnFromDate: null,
        EmployeeId: null,
        RepayAmount: null

    })
    const designationSelecttion = (e) => {
        const value = e.target.value;
        if (value == "") {
            ReactDOM.render(null, document.getElementById("unitdemo"));
        } else {
            let suggestions = [];
            if (value.length > 0) {
                const regex = new RegExp(`${value}`, "i");
                suggestions = loanpopulate.data.Employees
                    .sort()
                    .filter((v) =>
                        regex.test(v.EmpName !== null ? v.EmpName : null)
                    );
            }
            suggestionlist1 = suggestions;

            if (suggestionlist1.length === 0) {
                ReactDOM.render(null, document.getElementById("unitdemo"));
            } else {
                ReactDOM.render(
                    <ul className="suggestbox">
                        {suggestionlist1.map((item) => (
                            <li className="listcss" onClick={() => suggestionselected1(item)}>
                                {item === undefined
                                    ? item
                                    : item.EmpName
                                }
                            </li>
                        ))}
                    </ul>,
                    document.getElementById("unitdemo")
                );
            }
        }
    };
    const onTextChangeEmployeeName = () => {
        var inputValue = document.getElementById("Employeename").value;
        var options = document.getElementById("employeenamelist").childNodes;
    
        let val;
        for (var i = 0; i < options.length; i++) {
          if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
            loanpopulate.data.Employees.forEach((x, idx) => {
              if (idx == options[i].attributes.index.value) {
                val = x;
              }
            });
            document.getElementById("Employeename").value = val.EmpName;

        setempid(val.EmployeeId)
    
            break;
          }
        }
      };
    const suggestionselected1 = (val) => {

        document.getElementById("Employeename").value = val.EmpName;

        setempid(val.EmployeeId)





        suggestionlist1 = [];
        ReactDOM.render(null, document.getElementById("unitdemo"));
    };
    const savedata = () => {
        if (document.getElementById("Employeename").value === "") {
            toast.info("Enter Employee Name");
        }

        else if (document.getElementById("loanamount").value === "") {
            toast.info("Enter Loan Amount");

        }

        else if (document.getElementById("date").value === "") {
            toast.info("Enter Start Date");

        }

        else {
            let Loan = state;
            // let LoanAllowances = props.allallowances;

            Loan.EmployeeLoanId = 0;
            Loan.EmployeeId = stateempid;
            Loan.ReturnFromDate = document.getElementById("date").value;
            Loan.LoanAmount = parseInt(document.getElementById("loanamount").value.replace(/,/g, ''));
            Loan.RepayAmount = parseInt(document.getElementById("RePayamount").value.replace(/,/g, ''));

            document.getElementById("loansave").disabled = true;
            axios({
                method: "post",
                url: `${window.$url}Loans/Add`,
                data: JSON.stringify(
                    Loan

                ),
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "content-type": "application/json",
                },
            }).then(
                (result) => {
                    document.getElementById("loansave").disabled = false;
                    if (result.data.IsSuccess === true) {



                        toast.info(result.data.Message);
                        props.cancelpayloantable();
                    }
                    else {
                        toast.info(result.data.Message);
                    }


                },
                (error) => {
                    toast.info("Someting went wrong");
                    document.getElementById("loansave").disabled = false;

                }
            );

        }
    };
    useEffect(() => {
        dispatch(getallLoanPopulate());

    }, [])
    return (

        <>

            <div className="row ">


                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">Employee Name</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">
                    <input type="text" className="input_styling   ml-md-3" list="employeenamelist" id="Employeename" onChange={onTextChangeEmployeeName} ></input><span className="estaric">*</span>
                    <datalist id="employeenamelist">
            {loanpopulate?.data?.Employees?.map((x, idx) => (
              <option index={idx} value={x.EmpName}>
                {x.EmployeeId}
              </option>
            ))}
          </datalist>
                    <span id="unitdemo"></span>

                </div>
                <div className="col-md-2 mt-md-4   p-0">
                </div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">Start Date</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">
                    <input type="date" className="input_styling  ml-md-3" id="date" ></input><span className="estaric">*</span>


                </div>
            </div>
            <div className="row ">


                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">Amount</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">
                    <input type="text" className="input_styling  ml-md-3" id="loanamount"
                        onChange={(e) => { if (e.target.value.length > 0) document.getElementById("loanamount").value = parseInt(e.target.value.replace(/,/g, '')).toLocaleString() }}
                    ></input><span className="estaric">*</span>

                </div>
                <div className="col-md-2 mt-md-4   p-0">
                </div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">RePay Amount</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">
                    <input type="text" className="input_styling  ml-md-3" id="RePayamount"
                        onChange={(e) => { if (e.target.value.length > 0) document.getElementById("RePayamount").value = parseInt(e.target.value.replace(/,/g, '')).toLocaleString() }}
                    ></input><span className="estaric">*</span>

                </div>
            </div>
            <div className=" float-right mt-md-4 ">
                <span>
                    <button
                        className="cancel_btn ml-2  "
                        onClick={() => { props.cancelpayloantable(); dispatch(getallLoanTable()); }}

                    >

                        Cancel
                    </button>
                </span>

                <span>
                    <button className="save_btn ml-2  " id="loansave" onClick={savedata}>
                        <BsCloudUpload size="1.3em" />
                        Save
                    </button>
                </span>
            </div>
        </>

    )
}
export const PayLoanTable = (props) => {


    const loandatastate = useSelector(state => state.GetAllLoanTable);

    const [allLoanData, setallLoanData] = useState([]);
    const [filterloans, setfilterloans] = useState();
    const [toggle, settoggle] = useState(false);


    let dispatch = useDispatch();

    var suggestionlist = [];

    const onTextChange = (e) => {
        const value = e.target.value;
        if (value === "") {

            setfilterloans(loandatastate.data.Loans);
        } else {
            let suggestions = [];
            if (value.length > 0) {
                const regex = new RegExp(`${value}`, "i");

                suggestions = filterloans
                    .sort()
                    .filter((val) =>
                        (regex.test(val.Employee !== null ? val.Employee.EmpName : null)));

            }
            suggestionlist = suggestions;
            setfilterloans(suggestionlist);
        }
    };

    const openmodalallowancetable = (x) => {

        setallLoanData(x);

        document.getElementById("modalofleadtable").style.display = "block";

    };
    const savedata = (repayamount, loanid) => {
        if (window.confirm("Do you really want to Edit?")) {
            axios({
                method: "post",
                url: `${window.$url}Loans/Add`,
                data: JSON.stringify({
                    EmployeeLoanId: loanid,
                    EmployeeId: null,
                    ReturnFromDate: null,
                    LoanAmount: null,
                    RepayAmount: repayamount,
                }


                ),
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "content-type": "application/json",
                },
            }).then(
                (result) => {

                    if (result.data.IsSuccess === true) {


                        props.cancelpayloantable();

                        dispatch(getallLoanTable());
                        toast.info(result.data.Message);



                    }
                    else {
                        toast.info(result.data.Message);
                    }


                },
                (error) => {
                    toast.info("Someting went wrong");

                }
            );
        }

    };
    useEffect(() => {

        dispatch(getallLoanTable());
    }, [dispatch]);
    useEffect(() => {
        setfilterloans(loandatastate.data.Loans);

    }, [loandatastate]
    )
    return (

        <>



            <div className="row pt-3 ">
                <div className="col-md-1 pr-0 pl-0">


                    <label className="input_label  m-0">Search:</label>
                </div>
                <div className="col-md-3   pr-0 pl-0">


                    <input type="text " className="input_styling " placeholder="Employee" onChange={onTextChange} ></input>
                </div>

                <div className="col-md-1 pr-0 pl-0">
                    {loandatastate.loading ? <Loader type="ThreeDots"
                        color="green"
                        height={40}
                        width={40} /> : null}
                </div>
            </div>




            <div className="table-responsive mt-4">

                <table className="table table-borderless  table-hover m-0">
                    <thead>

                        <tr >

                            <th>Sr</th>

                            <th>Employee</th>

                            <th>Start Date</th>
                            <th>Loan Amount</th>
                            <th>Repay Amount</th>





                            <th className="text-center">
                                <button
                                    className="save_btn"
                                    onClick={() => {
                                        props.showpayloantable();
                                    }}
                                >
                                    New+
                                </button>

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filterloans !== undefined ? filterloans.map((val, index) => (
                            <tr key={index + 1}>
                                <td className="cur_sor" >{index + 1}</td>

                                <td className="cur_sor" >{val.Employee !== undefined ? val.Employee.EmpName : null}</td>
                                <td className="cur_sor" >{val.ReturnFromDate !== undefined ? moment(val.ReturnFromDate.split("T")[0]).format("DD-MMM-YYYY") : null}</td>

                                <td className="cur_sor" >{val.LoanAmount !== undefined ? val.LoanAmount.toLocaleString() : null}</td>
                                {/* <td className="cur_sor" >{val.RepayAmount !== undefined ? val.RepayAmount : null}</td> */}
                                <td className="cur_sor" ><input type="text" className="input_styling " id={`repayamount${index}`} defaultValue={val.RepayAmount !== undefined && val.RepayAmount !== null ? val.RepayAmount.toLocaleString() : null}
                                    onChange={(e) => { if (e.target.value.length > 0) document.getElementById(`repayamount${index}`).value = parseInt(e.target.value.replace(/,/g, '')).toLocaleString() }}
                                ></input></td>


                                <td className="text-center cur_sor"><button type="button" className="save_btn" onClick={() => { savedata(document.getElementById(`repayamount${index}`) !== null ? parseInt(document.getElementById(`repayamount${index}`).value.replace(/,/g, '')) : null, val.EmployeeLoanId); }}>Edit  </button></td>



                            </tr>
                        )) : null}


                    </tbody>
                </table>
            </div>


            {/* model for show information*/}
            <div id="modalofleadtable" class="modalscreen ">
                <div class="modal-content-screen1 pt-1">
                    <div className="row ">
                        <div className="col-12  page_heading">
                            <h4 className="  text-center ">Allowance Detail</h4>
                            <span
                                class="close pr-2  "
                                onClick={() => {
                                    document.getElementById("modalofleadtable").style.display =
                                        "none";
                                }}
                            >
                                &times;
                            </span>

                        </div>
                    </div>
                    <div className="container modal_container">



                        <div className="table-responsive mt-4">
                            <table class="table mt-2">
                                <thead class="purple whiteText">
                                    <tr>
                                        <th scope="col">Allowance Name</th>
                                        <th scope="col">Allowance Amount</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {allLoanData !== undefined && allLoanData.DesignationAllowances !== undefined

                                        // allLoanData.length == 0
                                        ?
                                        allLoanData.DesignationAllowances.map((x, id) => (
                                            <tr key={id}>
                                                <td>{x.Allowance.AllowanceName}</td>
                                                <td>{x.AllowanceAmount}</td>


                                            </tr>
                                        )) : null}
                                </tbody>
                            </table>
                        </div>







                        {/* <div className="row">
                            <div className=" col-12 text-right pr-0 pl-0">
                                <button className="print_btn float-right" ><TiPrinter size="1.3em" />Print</button>


                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

        </>

    )
}

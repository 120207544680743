import axios from "axios";
import moment from "moment";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { GrPrint } from "react-icons/gr";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { getaddfilepopulate, getallnewpurchase } from "../../../actions/inventoryactions/newpurchase";
import { NewPurchasePrint } from "../ProjectsPrint/NewPurchasePrint";
import { CSVLink } from "react-csv";




export const NewPurchaseTable = (props) => {
    const resultsallunits = useSelector((state) => state.getAllNewPurchase);
    const resultsaddfilepopulate = useSelector((state) => state.getAddfilePopulate);
    const [stateSectionList, setSectionList] = useState([]);
    const [stateprojectid, setprojectid] = useState(0);

    const [filerunits, setfilterunits] = useState()
    const dispatch = useDispatch();
    const [purchasemodaldata, setpurchasedata] = useState({});
    const [statetoggle, settoggle] = useState(true);
    const [state, setstate] = useState(
        {
            UnitFileId: 0,

            SoldValue: null,
            Sold: null,

            ProfitValue: null,
            TransferFee: null,
            ProjectId: 0,
            CustomerCnic: null,
            SellerId: null
        }
    );

    const [printData, SetPrintData] = useState();
    // print function
    const componentRef = useRef();

    const printRecoveryBooking = useReactToPrint({
        content: () => componentRef.current,
    });
    useEffect(() => {
        if (printData !== undefined) {
            printRecoveryBooking();
        }
    }, [printData, statetoggle]);
    // all projects print

    const AllcomponentRef = useRef();

    const AllprintPaymentRec = useReactToPrint({
        content: () => AllcomponentRef.current,
    });
    //Table Data fiter


    const onTextChange = (e) => {

        if (document.getElementById("projectfilter").value === "") {
            setfilterunits(resultsallunits.data);

        } else {
            let suggestions = [];
            {
                const regex = new RegExp(document.getElementById("projectfilter").value, "i");
                // const regex1 = new RegExp(document.getElementById("sectionfilter").value, "i");
                // const regex2 = new RegExp(document.getElementById("unitfilter").value, "i");
                // const regex3 = new RegExp(document.getElementById("areafilter").value, "i");




                suggestions = resultsallunits.data
                    .sort()
                    .filter((val) =>
                        (document.getElementById("projectfilter").value === "" || regex.test(val.UnitName))
                        // (document.getElementById("sectionfilter").value === "" || regex1.test(val.Unit.Section)) &&
                        // (document.getElementById("unitfilter").value === "" || regex2.test(val.Unit.UnitName)) &&
                        // (document.getElementById("areafilter").value === "" || regex3.test(val.Unit.Marla + "M" + val.Unit.Sarsai + "S"))


                    );

            }
            setfilterunits(suggestions);
        }
    };

    useEffect(() => {


        dispatch(getallnewpurchase());
        dispatch(getaddfilepopulate());


    }, [dispatch]
    )
    useEffect(() => {
        setfilterunits(resultsallunits.data);
 
    }, [resultsallunits]
    )
    function showmodal(data) {
        document.getElementById("mymodal").style.display = "block";
        setpurchasedata(data);
    }
    function closemodal() {
        document.getElementById("mymodal").style.display = "none";

    }

    const [solddata, setsolddata] = useState(null);

    useEffect(() => {
        if (solddata !== null) {
            document.getElementById("subcatModal").style.display = "block"
        } else {
            document.getElementById("subcatModal").style.display = "none"
        }
    }, [solddata])

    const savedata = () => {
        if (
            document.getElementById("soldvaluemodal").value === "" || document.getElementById("transferfeemodal").value === "" || document.getElementById("profitpercentagemodal").value === "" ||
            document.getElementById("soldvaluemodal").value === "0" || document.getElementById("transferfeemodal").value === "0" || document.getElementById("profitpercentagemodal").value === "0" || document.getElementById("projectname").selectedIndex == 0
        ) {
            toast.info("Please enter all the fields")
        }

        else {
            let Unit = state

            Unit.UnitFileId = solddata.UnitFileId;


            Unit.SoldValue = parseFloat(document.getElementById("soldvaluemodal").value.replace(/,/g, ''));

            Unit.Sold = true;

            Unit.ProfitValue = parseFloat(document.getElementById("profitpercentagemodal").value.replace(/,/g, ''));
            Unit.TransferFee = parseFloat(document.getElementById("transferfeemodal").value.replace(/,/g, ''));
            Unit.ProjectId = parseInt(stateprojectid);
            Unit.SellerId = document.getElementById("sellername").options[document.getElementById("sellername").selectedIndex].dataset.id;
            Unit.CustomerCnic = document.getElementById("customername").options[document.getElementById("customername").selectedIndex].dataset.id;

            document.getElementById("catsave").disabled = true;

            axios({
                method: "post",
                url: `${window.$url}UnitPurchase/AddFile`,
                data: JSON.stringify(Unit),
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "content-type": "application/json",
                },
            }).then(
                (result) => {
                    document.getElementById("catsave").disabled = false;
                    if (result.data.IsSuccess === true) {
                        toast.info(result.data.Message);

                        dispatch(getallnewpurchase());
                        document.getElementById("soldvaluemodal").value = ""
                        document.getElementById("transferfeemodal").value = ""
                        document.getElementById("profitpercentagemodal").value = ""
                        document.getElementById("projectname").selectedIndex = 0;
                        document.getElementById("sellername").selectedIndex = 0;
                        document.getElementById("customername").selectedIndex = 0



                    } else {
                        toast.info(result.data.Message);
                    }


                },
                (error) => {
                    document.getElementById("catsave").disabled = false;



                }
            );


        }
    }

    const [CsvData, setCsvData] = useState([]);
    useEffect(() => {
      let arr = [];
      filerunits?.forEach((val, index) => {
        arr.push({
          Sr: index + 1,
          Date:
            val.EntryDate !== null
              ? moment(val.EntryDate.split("T")[0]).format("DD-MMM-YYYY")
              : null,
          UnitName: val.UnitName !== null && val.UnitName !== undefined ? (val.UnitName) : (''),
          "Purchase Amt": val.PurchaseValue.toLocaleString(),
          "Sale Amt":
            val.SaleValue !== null && val.SaleValue !== undefined
              ? val.SaleValue.toLocaleString()
              : "",
          "Remaining Amt":
            val.RemainingAmount !== null && val.RemainingAmount !== undefined
              ? val.RemainingAmount.toLocaleString()
              : "",
          Description:
            val.UnitDescription !== null && val.UnitDescription !== undefined
              ? val.UnitDescription
              : null,
        });
      });
      setCsvData(arr);
    }, [filerunits]);
    return (
      <>
        <div hidden>
          <NewPurchasePrint ref={componentRef} printData={printData} />
        </div>

        <div id="subcatModal" class="modalscreen ">
          <div className="modal-content-screen1 pt-1">
            <div className=" page_heading">
              <span className="head_text pl-3">File Sold</span>
              <span
                className="close pr-3"
                onClick={() => {
                  setsolddata(null);
                  document.getElementById("soldvaluemodal").value = "";
                  document.getElementById("transferfeemodal").value = "";
                  document.getElementById("profitpercentagemodal").value = "";
                  document.getElementById("projectname").selectedIndex = 0;
                  document.getElementById("sellername").selectedIndex = 0;
                  document.getElementById("customername").selectedIndex = 0;
                }}
              >
                &times;
              </span>
            </div>

            <div className="row pt-5 pl-3">
              <div className="col-md-2  text-left p-0">
                <label className="input_label m-0 ">Sold Value:</label>
              </div>

              <div className="col-md-4  p-0">
                <input
                  className="input_styling ml-md-3"
                  id="soldvaluemodal"
                  defaultValue={
                    solddata !== null
                      ? solddata.SaleValue.toLocaleString()
                      : null
                  }
                  key={solddata !== null ? solddata.SaleValue : null}
                  onChange={(e) => {
                    if (e.target.value.length > 0)
                      document.getElementById("soldvaluemodal").value =
                        parseInt(
                          e.target.value.replace(/,/g, "")
                        ).toLocaleString();
                  }}
                ></input>
                <span className="estaric">*</span>
              </div>
              <div className="col-md-2  text-left p-0">
                <label className="input_label m-0 ">Transfer Fee:</label>
              </div>

              <div className="col-md-4  p-0">
                <input
                  className="input_styling ml-md-3"
                  id="transferfeemodal"
                  defaultValue={
                    solddata !== null
                      ? solddata.TransferFee.toLocaleString()
                      : null
                  }
                  key={solddata !== null ? solddata.TransferFee : null}
                  onChange={(e) => {
                    if (e.target.value.length > 0)
                      document.getElementById("transferfeemodal").value =
                        parseInt(
                          e.target.value.replace(/,/g, "")
                        ).toLocaleString();
                  }}
                ></input>
                <span className="estaric">*</span>
              </div>
            </div>

            <div className="row pt-5 pl-3">
              <div className="col-md-2  text-left p-0">
                <label className="input_label m-0 ">Profit Amount:</label>
              </div>

              <div className="col-md-4  p-0">
                <input
                  className="input_styling ml-md-3"
                  type="text"
                  id="profitpercentagemodal"
                  defaultValue={
                    solddata !== null && solddata.ProfitValue !== null
                      ? solddata.ProfitValue.toLocaleString()
                      : null
                  }
                  key={solddata !== null ? solddata.ProfitValue : null}
                  onChange={(e) => {
                    if (e.target.value.length > 0)
                      document.getElementById("profitpercentagemodal").value =
                        parseFloat(
                          e.target.value.replace(/,/g, "")
                        ).toLocaleString();
                  }}
                ></input>
                <span className="estaric">*</span>
              </div>

              <div className="col-md-2  text-left p-0">
                <label className="input_label m-0 ">Project:</label>
              </div>

              <div className="col-md-4  p-0">
                <select
                  id="projectname"
                  className="input_styling  ml-md-3"
                  onChange={(e) => {
                    if (e.target.value !== "Select") {
                      setSectionList(
                        resultsaddfilepopulate.data[
                          document.getElementById("projectname").options[
                            document.getElementById("projectname").selectedIndex
                          ].dataset.idx
                        ]
                      );
                      setprojectid(
                        document.getElementById("projectname").options[
                          document.getElementById("projectname").selectedIndex
                        ].dataset.id
                      );
                    }
                  }}
                >
                  <option>Select</option>
                  {resultsaddfilepopulate.data.map((x, index) => (
                    <option data-id={x.ProjectId} data-idx={index} key={index}>
                      {x.ProjectName}
                    </option>
                  ))}
                </select>
                <span className="estaric">*</span>
              </div>
            </div>
            <div className="row pt-5 pl-3">
              <div className="col-md-2  text-left p-0">
                <label className="input_label m-0 ">Customer:</label>
              </div>

              <div className="col-md-4  p-0">
                <select id="customername" className="input_styling  ml-md-3">
                  <option>Select</option>
                  {stateSectionList !== undefined &&
                  stateSectionList.Customers !== undefined
                    ? stateSectionList.Customers.map((x, index) => (
                        <option
                          data-id={x.CustomerCnic}
                          data-idx={index}
                          key={index}
                        >
                          {x.CustomerName}
                        </option>
                      ))
                    : null}
                </select>
                <span className="estaric">*</span>
              </div>

              <div className="col-md-2  text-left p-0">
                <label className="input_label m-0 ">Seller:</label>
              </div>

              <div className="col-md-4  p-0">
                <select id="sellername" className="input_styling  ml-md-3">
                  <option>Select</option>
                  {stateSectionList !== undefined &&
                  stateSectionList.Sellers !== undefined
                    ? stateSectionList.Sellers.map((x, index) => (
                        <option
                          data-id={x.SellerId}
                          data-idx={index}
                          key={index}
                        >
                          {x.SellerName}
                        </option>
                      ))
                    : null}
                </select>
                <span className="estaric">*</span>
              </div>
            </div>
            <div className="row">
              <div className="  col-12 pr-3 pl-0 mt-3 text-right ">
                <span>
                  <button
                    className="save_btn mr-md-3"
                    id="catsave"
                    onClick={savedata}
                  >
                    <BsCloudUpload size="1.3em" />
                    Save
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* <div hidden>
                <AllNewPurchasePrint
                    ref={AllcomponentRef}

                    printData={resultsallunits}

                />
            </div> */}

        <div className="row pt-3 ">
          <div className="col-md-1 pr-0 pl-0">
            <label className="input_label m-0">Search:</label>
          </div>
          <div className="col-md-3 pr-0 pl-0">
            <input
              type="text "
              className="input_styling "
              id="projectfilter"
              onChange={onTextChange}
              placeholder="Unit "
            ></input>
          </div>

          <div className="col-md-1 pr-0 pl-0">
            {resultsallunits.loading ? (
              <Loader type="ThreeDots" color="green" height={40} width={40} />
            ) : null}
          </div>
        </div>

        <div className="row ">
          <div className=" col-12  table_height pr-0 pl-0">
            <div className="table-responsive mt-4">
              <table className="table table-borderless m-0">
                <thead>
                  <tr>
                    <th>Sr</th>
                    <th>Date</th>

                    <th>UnitName</th>
                    <th>Purchase Amt</th>
                    <th>Sale Amt</th>
                    <th>Remaining Amt</th>

                    <th>Description</th>

                    <th></th>
                    <th className="text-center">
                      <button
                        className="save_btn"
                        onClick={() => {
                          props.showunittable();
                        }}
                      >
                        New+
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filerunits !== undefined
                    ? filerunits.map((val, index) => (
                        <tr key={index}>
                          <td
                            className=" cur_sor "
                            onClick={() => {
                              showmodal(val);
                            }}
                          >
                            {index + 1}
                          </td>
                          <td
                            className=" cur_sor "
                            onClick={() => {
                              showmodal(val);
                            }}
                          >
                            {val.EntryDate !== null
                              ? moment(val.EntryDate.split("T")[0]).format(
                                  "DD-MMM-YYYY"
                                )
                              : null}
                          </td>

                          {val.UnitName !== null &&
                          val.UnitName !== undefined ? (
                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val.UnitName}
                            </td>
                          ) : (
                            <td></td>
                          )}
                          <td
                            className=" cur_sor "
                            onClick={() => {
                              showmodal(val);
                            }}
                          >
                            {val.PurchaseValue.toLocaleString()}
                          </td>
                          {val.SaleValue !== null &&
                          val.SaleValue !== undefined ? (
                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val.SaleValue.toLocaleString()}
                            </td>
                          ) : (
                            <td></td>
                          )}
                          {val.RemainingAmount !== null &&
                          val.RemainingAmount !== undefined ? (
                            <td
                              className=" cur_sor "
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val.RemainingAmount.toLocaleString()}
                            </td>
                          ) : (
                            <td></td>
                          )}

                          <td
                            className=" cur_sor "
                            onClick={() => {
                              showmodal(val);
                            }}
                          >
                            {val.UnitDescription !== null &&
                            val.UnitDescription !== undefined
                              ? val.UnitDescription
                              : null}
                          </td>
                          <td className=" cur_sor ">
                            {val.Sold ? (
                              <button className="cancel_btn" disabled>
                                Sell
                              </button>
                            ) : (
                              <button
                                className="cancel_btn"
                                onClick={() => {
                                  setsolddata(val);
                                }}
                                id={`soldbutton${index}`}
                              >
                                Sell
                              </button>
                            )}
                          </td>
                          <td
                            className="text-center cur_sor "
                            onClick={() => {
                              SetPrintData(val);
                              settoggle(!statetoggle);
                            }}
                          >
                            <GrPrint size="1.3em" />
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
            <button
              style={{ color: "black !important" }}
              className="print_btn ml-2 float-left"
            >
              <CSVLink
                style={{ color: "black" }}
                filename="New_Purchase_Csv"
                data={CsvData}
              >
                Download CSV
              </CSVLink>
            </button>
          </div>
          {/* <div className="col-6  text-right pr-0 pl-0">
                    <button className="print_btn float-left" onClick={() => { AllprintPaymentRec(); }}><TiPrinter size="1.3em" />Print</button>


                </div> */}
        </div>
        <div id="mymodal" class="modal">
          <div class="modal-content-cat">
            <div className="row">
              <div className="col-12  page_heading">
                <h4 className="  text-center pt-2">Sold Detail</h4>
                <span class="close pr-2  " onClick={closemodal}>
                  &times;
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-6 mt-2">
                <ul>
                  <li>
                    <b>Project:</b>{" "}
                    {purchasemodaldata.Project !== undefined
                      ? purchasemodaldata.Project.ProjectName
                      : null}
                  </li>
                  <li>
                    <b>Seller:</b>{" "}
                    {purchasemodaldata.Seller !== undefined
                      ? purchasemodaldata.Seller.SellerName
                      : null}
                  </li>
                  <li>
                    <b>Purchase Value:</b>{" "}
                    {purchasemodaldata.PurchaseValue !== undefined
                      ? purchasemodaldata.PurchaseValue.toLocaleString()
                      : null}
                  </li>
                  <li>
                    <b>Transfer Fee:</b>{" "}
                    {purchasemodaldata.TransferFee !== undefined
                      ? purchasemodaldata.TransferFee.toLocaleString()
                      : null}
                  </li>
                </ul>
              </div>
              <div className="col-6 mt-2">
                <ul>
                  <li>
                    <b>Customer:</b>{" "}
                    {purchasemodaldata.CustomerCnicNavigation !== undefined
                      ? purchasemodaldata.CustomerCnicNavigation.CustomerName
                      : null}
                  </li>
                  <li>
                    <b>Sold Value:</b>{" "}
                    {purchasemodaldata.SoldValue !== undefined &&
                    purchasemodaldata.SoldValue !== null
                      ? purchasemodaldata.SoldValue.toLocaleString()
                      : null}
                  </li>
                  <li>
                    <b>Profit Value:</b>{" "}
                    {purchasemodaldata.ProfitValue !== undefined &&
                    purchasemodaldata.ProfitValue !== null
                      ? purchasemodaldata.ProfitValue.toLocaleString()
                      : null}
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-12 text-right"></div>
          </div>
        </div>
      </>
    );
};

import axios from 'axios';
import { toast } from "react-toastify";
export const GetAllLoanReceipt = (data) => {

    return ({
        type: "get_allgetloanreceipt",
        payload: data,
        loader: false
    })
}
export function getallLoanReceipt() {
    return (dispatch) => {
        let pgurl = `${window.$url}Vouchers/LoanReceipt/Populate?ProjectId=${localStorage.getItem("ProjectId")}`;
        if (localStorage.getItem("ProjectId") === null) {
            pgurl = `${window.$url}Vouchers/LoanReceipt/Populate`
        }
        axios({
            url: pgurl,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllLoanReceipt(result.data.Data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}
export const GetAllLoanRecTableData = (data) => {

    return ({
        type: "get_allloanrectabledata",
        payload: data,
        loader: false
    })
}
export function getallrecoverypopulatetable(data) {
    return (dispatch) => {
        let pgurl = `${window.$url}Vouchers/LoanReceipt/Get?projectid=${localStorage.getItem("ProjectId")}`;
        if (localStorage.getItem("ProjectId") === null) {
            pgurl = `${window.$url}Vouchers/LoanReceipt/Get`
        }
        axios({
            url: pgurl,
            method: "Post",
            data: JSON.stringify(
                data
            ),
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllLoanRecTableData(result.data.Data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}

import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getallProject } from "../../../actions/getprojects";
import {
  getallSectionCat,
  getallSectionSubCat,
  getallSectionType,
} from "../../../actions/getsectioncat";
import { getallSection } from "../../../actions/getsections";
import FormHeaderWithInfo from "../../../FormHeaderWithInfo/FormHeaderWithInfo";

export const SectionForm = (props) => {
  const resultsallsections = useSelector((state) => state.getAllSections);
  const resultcat = useSelector((state) => state.getsectioncat);
  const resulttype = useSelector((state) => state.getsectiontype);

  const resultsubcat = useSelector((state) => state.getsectionsubcat);
  const resultsproject = useSelector((state) => state.getProject);
  const dispatch = useDispatch();
  const [loader, setloader] = useState(false);

  const [subcat, setsubcat] = useState({
    CategoryId: 0,
    SubcategoryId: 0,
    SubcategoryName: "",
  });
  const [state, setstate] = useState({
    SectionId: 0,
    SectionName: null,
    SectionTypeId: 0,
    CategoryId: 0,
    SubCategoryId: 0,
    ProjectId: 0,
    AdvanceAmountPer: null,
    MarketingExpensePercentage: null,
    Installments: "",
  });
  const [projectnameid, setprojectnameid] = useState();
  function addSectionType() {
    document.getElementById("catsave").disabled = true;
    axios({
      url: `${window.$url}Projects/SectionType/Add`,
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
      data: JSON.stringify({
        SectionTypeId: 0,
        Type: document.getElementById("catname").value,
        ProjectId:
          document.getElementById("projectname").options[
            document.getElementById("projectname").selectedIndex
          ].dataset.id,
      }),
    }).then(
      (result) => {
        document.getElementById("catsave").disabled = false;
        if (result.data.IsSuccess === true) {
          document.getElementById("catname").value = "";
          toast.info(result.data.Message);
          modalclose();

          dispatch(getallSectionType());
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        document.getElementById("catsave").disabled = false;
        toast.info("Something went wrong!");
      }
    );
  }
  function deleteSectionType(id) {
    axios({
      url: `${window.$url}Projects/SectionType/Del?SectionTypeId=${id}`,
      method: "Delete",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(getallSectionType());

          toast.info(result.data.Message);
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        console.log(error);
        toast.info("Something went wrong!");
      }
    );
  }
  function modalclose() {
    document.getElementById("catModal").style.display = "none";
  }
  function addSubCategory() {
    let subcatdata = subcat;
    subcatdata.SubcategoryId = 0;
    subcatdata.CategoryId =
      document.getElementById("catselectmodal").options[
        document.getElementById("catselectmodal").selectedIndex
      ].dataset.id;
    subcatdata.SubcategoryName =
      document.getElementById("subcatnamemodal").value;
    axios({
      url: `${window.$url}Projects/Subcategory/Add`,
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
      data: JSON.stringify(subcatdata),
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          document.getElementById("subcatnamemodal").value = "";
          document.getElementById("catselectmodal").selectedIndex = 0;
          toast.info(result.data.Message);
          dispatch(getallSectionSubCat());
          setsubcat({
            CategoryId: 0,
            SubcategoryId: 0,
            SubcategoryName: "",
          });
          modalclosesubcat();
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
        console.log(error);
      }
    );
  }
  function deleteSectionSubCat(id) {
    axios({
      url: `${window.$url}Projects/Subcategory/Del?SubcategoryId=${id}`,
      method: "Delete",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(getallSectionSubCat());

          toast.info(result.data.Message);
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        console.log(error);
        toast.info("Something went wrong!");
      }
    );
  }
  function modalclosesubcat() {
    document.getElementById("subcatModal").style.display = "none";
  }
  //
  const savedata = () => {
    if(document.getElementById("sectionname").value === "" ){
      toast.info("Please Add Section Name");
    }
    else if(    document.getElementById("sectiontype").options[
      document.getElementById("sectiontype").selectedIndex
    ].value === "Select" ){
      toast.info("Please Add Section Type");
    }
    else if(       document.getElementById("cat").options[
      document.getElementById("cat").selectedIndex
    ].value === "Select"  ){
      toast.info("Please Add Category");
    }
    else if(            document.getElementById("subcat").options[
      document.getElementById("subcat").selectedIndex
    ].value === "Select" ){
      toast.info("Please Add Sub Category");
    }
    else if(            document.getElementById("subcat").options[
      document.getElementById("subcat").selectedIndex
    ].value === "Select" ){
      toast.info("Please Add Sub Category");
    }
    // if(document.getElementById("sectionname").value === "" ){
    //   toast.info("Please Add Section Name");
    // }
   else if (
      // document.getElementById("sectionname").value === "" ||
  
  

      // document.getElementById("sectiontype").options[
      //   document.getElementById("sectiontype").selectedIndex
      // ].value === "Select" ||
      // document.getElementById("cat").options[
      //   document.getElementById("cat").selectedIndex
      // ].value === "Select" ||
      document.getElementById("projectname").options[
        document.getElementById("projectname").selectedIndex
      ].value === "Select"
    ) {
      toast.info("Please Add Project Name");
    } else if (
      document.getElementById("AdvancePayment").value > 100 ||
      document.getElementById("AdvancePayment").value < 0
    ) {
      toast.info("Advance Payment should be in between 0 to 100 ");
    } else {
      let data = state;
      data.SectionId = 0;
      data.SectionName = document.getElementById("sectionname").value;
      data.SectionTypeId =
        document.getElementById("sectiontype").options[
          document.getElementById("sectiontype").selectedIndex
        ].dataset.id;
      data.CategoryId =
        document.getElementById("cat").options[
          document.getElementById("cat").selectedIndex
        ].dataset.id;
      data.SubCategoryId =
        document.getElementById("subcat").options[
          document.getElementById("subcat").selectedIndex
        ].dataset.id;
      data.ProjectId =
        document.getElementById("projectname").options[
          document.getElementById("projectname").selectedIndex
        ].dataset.id;
      data.AdvanceAmountPer = document.getElementById("AdvancePayment").value;
      data.Installments = parseInt(document.getElementById("maxinstall").value);
      data.MarketingExpensePercentage = parseInt(
        document.getElementById("MarketingExp").value
      )?parseInt(
        document.getElementById("MarketingExp").value
      ):0;

      document.getElementById("sectionsave").disabled = true;
      setloader(true);

      axios({
        method: "post",
        url: `${window.$url}Projects/Sections/Add`,
        data: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result.data.IsSuccess === true) {
            document.getElementById("sectionname").value = "";
            document.getElementById("sectiontype").selectedIndex = 0;
            document.getElementById("cat").selectedIndex = 0;
            document.getElementById("subcat").selectedIndex = 0;
            document.getElementById("projectname").selectedIndex = 0;
            setstate({
              SectionId: 0,
              SectionName: null,
              SectionTypeId: null,
              CategoryId: 0,
              SubCategoryId: 0,
              ProjectId: 0,
            });

            dispatch(getallSection());

            toast.info(result.data.Message);
            props.cancelsectiontable();
            setloader(false);
          } else {
            toast.info(result.data.Message);
            document.getElementById("sectionsave").disabled = false;
            setloader(false);
          }
        },
        (error) => {
          document.getElementById("sectionsave").disabled = false;

          console.log(error);
          setloader(false);
        }
      );
    }
  };

  useEffect(() => {
    dispatch(getallSectionCat());
    dispatch(getallSectionSubCat());
    dispatch(getallSectionType());

    dispatch(getallProject());
    dispatch(getallSection());
  }, [dispatch]);
  return (
    <>
      <FormHeaderWithInfo HeaderTitle={"Add Section"} Info={""} />
      <div className="row ">
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0 ">Project Name:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <select
            className="input_styling ml-md-3"
            id="projectname"
            onChange={() =>
              setprojectnameid(
                document.getElementById("projectname").options[
                  document.getElementById("projectname").selectedIndex
                ].dataset.id
              )
            }
          >
            <option>Select</option>
            {resultsproject.data.map((val, index) => (
              <option key={index} data-id={val.ProjectId}>
                {val.ProjectName}
              </option>
            ))}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-4   p-0"></div>
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0 ">Section Name:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="sectionname"
          />
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0 ">Section Type:</label>
        </div>
        <div className="col-md-3 pt-4  p-0">
          <select
            className="input_styling ml-md-3 "
            id="sectiontype"
            onChange={(e) => {
              e.target.value === "Add New"
                ? (document.getElementById("catModal").style.display = "block")
                : (document.getElementById("catModal").style.display = "none");
            }}
          >
            <option>Select</option>
            <option>Add New</option>
            {resulttype.data.map((val, index) =>
              parseInt(projectnameid) === parseInt(val.ProjectId) ? (
                <option key={index} data-id={val.SectionTypeId}>
                  {val.Type}
                </option>
              ) : null
            )}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-4   p-0"></div>
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0 ">Category:</label>
        </div>
        <div className="col-md-3 pt-4  p-0">
          <select className="input_styling ml-md-3" id="cat">
            <option>Select</option>

            {resultcat.data.map((val, index) => (
              <option key={index} data-id={val.CategoryId}>
                {val.CategoryName}
              </option>
            ))}
          </select>
          <span className="estaric">*</span>
        </div>
      </div>

      <div className="row ">
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0 ">Sub Category:</label>
        </div>
        <div className="col-md-3 pt-4  p-0">
          <select
            className="input_styling ml-md-3"
            id="subcat"
            onChange={(e) => {
              e.target.value === "Add New"
                ? (document.getElementById("subcatModal").style.display =
                    "block")
                : (document.getElementById("subcatModal").style.display =
                    "none");
            }}
          >
            <option>Select</option>
            <option>Add New</option>
            {resultsubcat.data.map((val, index) => (
              <option key={index} data-id={val.SubcategoryId}>
                {val.SubcategoryName}
              </option>
            ))}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-4   p-0"></div>
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Advance%:</label>
        </div>
        <div className="col-md-3 mt-4   p-0">
          <input
            type="number"
            className="input_styling ml-md-3 "
            id="AdvancePayment"
          />
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0 ">Pay. Time:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="number"
            className="input_styling ml-md-3 "
            id="maxinstall"
            placeholder="e.g 2 is equal to 2 months"
          />
        </div>
        <div className="col-md-2 mt-4   p-0"></div>
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0 ">Marketing Exp%:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="number"
            className="input_styling ml-md-3 "
            id="MarketingExp"
          />

        </div>
      </div>

      <div className="row pt-md-4">
        <div className=" col-6 pr-0 pl-0  "></div>
        <div className=" col-6 pr-0 pl-0  text-right ">
          <span>
            {loader ? (
              <Loader type="ThreeDots" color="green" height={40} width={40} />
            ) : null}
          </span>
          <span>
            <button
              className="cancel_btn ml-2 mr-md-2 "
              onClick={props.cancelsectiontable}
            >
              Cancel
            </button>
          </span>
          <span>
            <button
              className="save_btn mr-md-2"
              id="sectionsave"
              onClick={savedata}
            >
              <BsCloudUpload size="1.3em" />
              Save
            </button>
          </span>
        </div>
      </div>
      {resultsallsections.loading ? (
        <Loader type="ThreeDots" color="green" height={40} width={40} />
      ) : null}
      <div className="table-responsive mt-4">
        <table className="table table-borderless m-0">
          <thead>
            <tr>
              <th>Project Name</th>
              <th>Section Name</th>
              <th>Section Type</th>
              <th>Category</th>
              <th>Sub Category</th>
              <th>Adv%</th>
              <th>Pay Time</th>
              <th>Marketing Exp%</th>
            </tr>
          </thead>
          <tbody>
            {resultsallsections.data.map((val, index) => (
              <tr key={index}>
                {val.Project !== null && val.Project !== undefined ? (
                  <td>{val.Project.ProjectName}</td>
                ) : (
                  <td></td>
                )}
                <td>{val.SectionName}</td>
                <td>{val.SectionType.Type}</td>
                {val.Category !== null && val.Category !== undefined ? (
                  <td>{val.Category.CategoryName}</td>
                ) : (
                  <td></td>
                )}
                {val.SubCategory !== null && val.SubCategory !== undefined ? (
                  <td>{val.SubCategory.SubcategoryName}</td>
                ) : (
                  <td></td>
                )}

                {val.AdvanceAmountPer !== null &&
                val.AdvanceAmountPer !== undefined ? (
                  <td>{val.AdvanceAmountPer}</td>
                ) : (
                  <td></td>
                )}
                <td>{val.Installments + " Months"}</td>
                <td>{val.MarketingExpensePercentage}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <!-- The Modal for cat --> */}
      {/* <div id="catModal" className="modal">
                <div className="modal-content-cat">
                    <div className="modal_heading">
                        <span className="head_text pl-3">Add Section Type </span>
                        <span className="close pr-3" onClick={modalclose}>&times;</span>
                    </div>
                    <div className="row pt-5 pl-3">
                        <div className="col-md-4  text-left p-0">
                            <label className="input_label m-0 ">Type Name:</label>
                        </div>

                        <div className="col-md-8  p-0">

                            <input type="text" className="input_styling ml-md-3 " id="catname"></input>

                        </div>

                    </div>

                    <div className="row pr-md-2 pt-md-4">

                        <div className=" col-12 pr-0 pl-0  text-right ">

                            <span><button className="save_btn mr-md-3" id="catsave" onClick={addSectionType}><BsCloudUpload size="1.3em" />Save</button></span>
                        </div>
                    </div>
                </div>

            </div> */}
      <div id="catModal" class="modalscreen ">
        <div className="modal-content-screen1 pt-1">
          <div className=" page_heading">
            <span className="head_text pl-3">Add Section Type </span>
            <span className="close pr-3" onClick={modalclose}>
              &times;
            </span>
          </div>

          <div className="row pt-5 pl-3">
            <div className="col-md-2  text-left p-0">
              <label className="input_label m-0 ">Type Name:</label>
            </div>

            <div className="col-md-4  p-0">
              <input
                type="text"
                className="input_styling ml-md-3 "
                id="catname"
              ></input>
              <span className="estaric">*</span>
            </div>
            <div className=" col-2 pr-0 pl-0  text-right ">
              <span>
                <button
                  className="save_btn mr-md-3"
                  id="catsave"
                  onClick={addSectionType}
                >
                  <BsCloudUpload size="1.3em" />
                  Save
                </button>
              </span>
            </div>
          </div>

          <div className="row  pt-md-4">
            <div className=" col-12 pr-0 pl-0 ">
              <div className="table-responsive mt-4">
                <table className="table table-borderless m-0">
                  <thead>
                    <th>Section Type Id</th>
                    <th>Section Type</th>
                    <th>Del</th>
                  </thead>
                  <tbody>
                    {resulttype.data.map((val, index) =>
                      parseInt(projectnameid) === parseInt(val.ProjectId) ? (
                        <tr key={index}>
                          <td>{val.SectionTypeId}</td>

                          <td>{val.Type}</td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteSectionType(val.SectionTypeId)}
                          >
                            <MdDeleteForever size="1.6em" />
                          </td>
                        </tr>
                      ) : null
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal close */}
      {/* <!-- The Modal of sub cat --> */}
      {/* <div id="subcatModal" className="modal">
                <div className="modal-content-cat">
                    <div className="modal_heading">
                        <span className="head_text pl-3">Add SubCategory</span>
                        <span className="close pr-3" onClick={modalclosesubcat}>&times;</span>
                    </div>
                    <div className="row pt-5 pl-3">
                        <div className="col-md-4  text-left p-0">
                            <label className="input_label m-0 ">Select Category:</label>
                        </div>

                        <div className="col-md-8  p-0">

                            <select className="input_styling ml-md-3" id="catselectmodal" >
                                <option>Select</option>
                                {resultcat.data.map((val, index) => (
                                    <option key={index} data-id={val.CategoryId}>{val.CategoryName}</option>
                                ))}

                            </select>

                        </div>

                    </div>
                    <div className="row pt-5 pl-3">
                        <div className="col-md-4  text-left p-0">
                        </div>

                        <div className="col-md-8  p-0">

                            <input type="text" className="input_styling ml-md-3 " id="subcatnamemodal"></input>

                        </div>

                    </div>

                    <div className="row pr-md-2 pt-md-4">

                        <div className=" col-12 pr-0 pl-0  text-right ">


                            <span><button className="save_btn mr-md-3" id="catsave" onClick={addSubCategory}><BsCloudUpload size="1.3em" />Save</button></span>
                        </div>
                    </div>
                </div>

            </div> */}

      <div id="subcatModal" class="modalscreen ">
        <div className="modal-content-screen1 pt-1">
          <div className=" page_heading">
            <span className="head_text pl-3">Add SubCategory</span>
            <span className="close pr-3" onClick={modalclosesubcat}>
              &times;
            </span>
          </div>

          <div className="row pt-5 pl-3">
            <div className="col-md-2  text-left p-0">
              <label className="input_label m-0 ">Select Category:</label>
            </div>

            <div className="col-md-4  p-0">
              <select className="input_styling ml-md-3" id="catselectmodal">
                <option>Select</option>
                {resultcat.data.map((val, index) => (
                  <option key={index} data-id={val.CategoryId}>
                    {val.CategoryName}
                  </option>
                ))}
              </select>
              <span className="estaric">*</span>
            </div>
            <div className="col-md-2  text-left p-0">
              <label className="input_label m-0 ">SubCategory Name:</label>
            </div>

            <div className="col-md-4  p-0">
              <input
                type="text"
                className="input_styling ml-md-3 "
                id="subcatnamemodal"
              ></input>
              <span className="estaric">*</span>
            </div>
          </div>
          <div className="row">
            <div className="  col-12 pr-3 pl-0 mt-3 text-right ">
              <span>
                <button
                  className="save_btn mr-md-3"
                  id="catsave"
                  onClick={addSubCategory}
                >
                  <BsCloudUpload size="1.3em" />
                  Save
                </button>
              </span>
            </div>
          </div>

          <div className="row  pt-md-4">
            <div className=" col-12 pr-0 pl-0 ">
              <div className="table-responsive mt-4">
                <table className="table table-borderless m-0">
                  <thead>
                    <th>Sub Category</th>
                    <th>Sub Category Name</th>
                    <th>Del</th>
                  </thead>
                  <tbody>
                    {resultsubcat.data.map((val, index) => (
                      <tr key={index}>
                        <td>{val.SubcategoryId}</td>

                        <td>{val.SubcategoryName}</td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => deleteSectionSubCat(val.SubcategoryId)}
                        >
                          <MdDeleteForever size="1.6em" />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal close */}
    </>
  );
};

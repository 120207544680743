import axios from 'axios';
import { toast } from "react-toastify";
export const GetAllAssetsCat = (data) => {

    return ({
        type: "get_allassetscat",
        payload: data,
        loader: false
    })
}
export function getallAssetsCat() {
    return (dispatch) => {
        axios({
            url: `${window.$url}Assets/Category/GetAll`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllAssetsCat(result.data.Data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}
export const GetAllAssetsSubCat = (data) => {

    return ({
        type: "get_allassetssubcat",
        payload: data,
        loader: false
    })
}
export function getallAssetsSubCat() {
    return (dispatch) => {
        axios({
            url: `${window.$url}Assets/Subcategory/GetAll`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllAssetsSubCat(result.data.Data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}
export const GetAllDepricationMethod = (data) => {

    return ({
        type: "get_alldepricationmethod",
        payload: data,
        loader: false
    })
}
export function getallDepricationMethod() {
    return (dispatch) => {
        axios({
            url: `${window.$url}Assets/DepreciationMethod/GetAll`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllDepricationMethod(result.data.Data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}
export const GetAllTableAssets = (data) => {

    return ({
        type: "get_alltableassets",
        payload: data,
        loader: false
    })
}
export function getallTableAssets() {
    return (dispatch) => {
        axios({
            url: `${window.$url}Assets/GetAll`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllTableAssets(result.data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}
import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
class ProfitLossPrint extends Component {
    render() {

        return (
          <div style={{ backgroundColor: "white", height: "100%" }}>
            <div className="row m-0 p-0">
              <PrintHeader />
            </div>
            <div
              className="row m-3 p-0"
              // style={{ border: "5px solid darkgrey", height: "auto" }}
            >
              <div className="col-12 text-center mt-2">
                <h4 style={{ color: "red" }}>
                  <u>Balance Sheet </u>
                </h4>
              </div>
            </div>
            {/* assets */}
            <div className="row ">
              <div className="col-6 pr-0 pl-3 pt-3"></div>
              <div className="col-3 pr-0 pl-3">
                <div>
                  <b>
                    {this.props.statealldata.data !== undefined
                      ? this.props.statealldata.data.PastYear
                      : null}
                  </b>
                </div>
                <div>
                  <b>Rupees</b>
                </div>
              </div>
              <div className="col-3 pr-0 pl-3">
                <div>
                  <b>
                    {this.props.statealldata.data !== undefined
                      ? this.props.statealldata.data.CurrentYear
                      : null}
                  </b>
                </div>
                <div>
                  <b>Rupees</b>
                </div>
              </div>
            </div>
            {/*Revenue */}
            <div className="row ">
              <div className="col-6 pr-0 pl-3">
                <label className="input_label m-0">
                  <b>Revenue</b>
                </label>
              </div>
              <div className="col-3 pr-0 pl-3"></div>
              <div className="col-3 pr-0 pl-3"></div>
            </div>
            <div className="row pt-3">
              <div className="col-6 pr-0 pl-3">
                {this.props.statenewdata.REVEUE !== undefined
                  ? this.props.statenewdata.REVEUE.map((val) => (
                      <div>{val.Title}</div>
                    ))
                  : null}
              </div>
              <div className="col-3 pr-0 pl-3">
                {this.props.stateolddata.REVEUE !== undefined
                  ? this.props.stateolddata.REVEUE.map((val) => (
                      <div>{val.Balance.toLocaleString()}</div>
                    ))
                  : null}
              </div>
              <div className="col-3 pr-0 pl-3">
                {this.props.statenewdata.REVEUE !== undefined
                  ? this.props.statenewdata.REVEUE.map((val) => (
                      <div>{val.Balance.toLocaleString()}</div>
                    ))
                  : null}
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-6 pr-0 pl-3">
                <label className="input_label m-0">
                  <b>Cost of Revenue</b>
                </label>
              </div>
              <div className="col-3 pr-0 pl-3"></div>
              <div className="col-3 pr-0 pl-3"></div>
            </div>
            <div className="row pt-3">
              <div className="col-6 pr-0 pl-3">
                {this.props.statenewdata.CostofRevenue !== undefined
                  ? this.props.statenewdata.CostofRevenue.map((val) => (
                      <div>{val.Title}</div>
                    ))
                  : null}
              </div>
              <div className="col-3 pr-0 pl-3">
                {this.props.stateolddata.CostofRevenue !== undefined
                  ? this.props.stateolddata.CostofRevenue.map((val) => (
                      <div>{val.Balance.toLocaleString()}</div>
                    ))
                  : null}
              </div>
              <div className="col-3 pr-0 pl-3">
                {this.props.statenewdata.CostofRevenue !== undefined
                  ? this.props.statenewdata.CostofRevenue.map((val) => (
                      <div>{val.Balance.toLocaleString()}</div>
                    ))
                  : null}
              </div>
            </div>
            {/* Gross Profit */}
            <div className="row pt-3">
              <div className="col-6 pr-0 pl-3 text-center">
                <label className="input_label m-0">
                  <b>Gross Profit</b>
                </label>
              </div>
              <div className="col-3 pr-0 pl-3">
                {this.props.grossprofitold.toLocaleString()}
              </div>
              <div className="col-3 pr-0 pl-3">
                {this.props.grossprofitnew.toLocaleString()}
              </div>
            </div>
            {/* Operating Expense */}
            <div className="row pt-3">
              <div className="col-12 pr-0 pl-3 text center">
                <h4 className="text-center"> Operating Expense</h4>
              </div>
              {/* <div className="col-3 pr-0 pl-3">

                  
                           
                        </div>
                        <div className="col-3 pr-0 pl-3">

       
                        </div> */}
            </div>
            {/* Administrative  Expense */}
            <div className="row pt-3">
              <div className="col-6 pr-0 pl-3">
                <label className="input_label m-0">
                  <b>Administrative Expense</b>
                </label>
              </div>
              <div className="col-3 pr-0 pl-3"></div>
              <div className="col-3 pr-0 pl-3"></div>
            </div>
            <div className="row pt-3">
              <div className="col-6 pr-0 pl-3">
                {this.props.statenewdata.AdminExpense !== undefined
                  ? this.props.statenewdata.AdminExpense.map((val) => (
                      <div>{val.Title}</div>
                    ))
                  : null}
              </div>
              <div className="col-3 pr-0 pl-3">
                {this.props.stateolddata.AdminExpense !== undefined
                  ? this.props.stateolddata.AdminExpense.map((val) => (
                      <div>{val.Balance.toLocaleString()}</div>
                    ))
                  : null}
              </div>
              <div className="col-3 pr-0 pl-3">
                {this.props.statenewdata.AdminExpense !== undefined
                  ? this.props.statenewdata.AdminExpense.map((val) => (
                      <div>{val.Balance.toLocaleString()}</div>
                    ))
                  : null}
              </div>
            </div>
            {/* Other Expense */}
            <div className="row pt-3">
              <div className="col-6 pr-0 pl-3 ">
                <label className="input_label m-0">
                  <b>Other Operating Expense</b>
                </label>
              </div>
              <div className="col-3 pr-0 pl-3"></div>
              <div className="col-3 pr-0 pl-3"></div>
            </div>
            <div className="row pt-3">
              <div className="col-6 pr-0 pl-3">
                {this.props.statenewdata.OtherExpense !== undefined
                  ? this.props.statenewdata.OtherExpense.map((val) => (
                      <div>{val.Title}</div>
                    ))
                  : null}
              </div>
              <div className="col-3 pr-0 pl-3">
                {this.props.stateolddata.OtherExpense !== undefined
                  ? this.props.stateolddata.OtherExpense.map((val) => (
                      <div>{val.Balance.toLocaleString()}</div>
                    ))
                  : null}
              </div>
              <div className="col-3 pr-0 pl-3">
                {this.props.statenewdata.OtherExpense !== undefined
                  ? this.props.statenewdata.OtherExpense.map((val) => (
                      <div>{val.Balance.toLocaleString()}</div>
                    ))
                  : null}
              </div>
            </div>
            {/* Other Inceme */}
            <div className="row pt-3">
              <div className="col-6 pr-0 pl-3 ">
                <label className="input_label m-0">
                  <b>Other Operating Income</b>
                </label>
              </div>
              <div className="col-3 pr-0 pl-3"></div>
              <div className="col-3 pr-0 pl-3"></div>
            </div>
            <div className="row pt-3">
              <div className="col-6 pr-0 pl-3">
                {this.props.statenewdata.OtherIncome !== undefined
                  ? this.props.statenewdata.OtherIncome.map((val) => (
                      <div>{val.Title}</div>
                    ))
                  : null}
              </div>
              <div className="col-3 pr-0 pl-3">
                {this.props.stateolddata.OtherIncome !== undefined
                  ? this.props.stateolddata.OtherIncome.map((val) => (
                      <div>{val.Balance.toLocaleString()}</div>
                    ))
                  : null}
              </div>
              <div className="col-3 pr-0 pl-3">
                {this.props.statenewdata.OtherIncome !== undefined
                  ? this.props.statenewdata.OtherIncome.map((val) => (
                      <div>{val.Balance.toLocaleString()}</div>
                    ))
                  : null}
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-6 pr-0 pl-3 text-center">
                <label className="input_label m-0 ">
                  <b>Operating Profit</b>
                </label>
              </div>
              <div className="col-3 pr-0 pl-3">
                {this.props.operatingprofitold.toLocaleString()}
              </div>
              <div className="col-3 pr-0 pl-3">
                {this.props.operatingprofitnew.toLocaleString()}
              </div>
            </div>
            {/*Finance Cost*/}
            <div className="row pt-3">
              <div className="col-6 pr-0 pl-3 ">
                <label className="input_label m-0">
                  <b>Finance Cost</b>
                </label>
              </div>
              <div className="col-3 pr-0 pl-3"></div>
              <div className="col-3 pr-0 pl-3"></div>
            </div>
            <div className="row pt-3">
              <div className="col-6 pr-0 pl-3">
                {this.props.statenewdata.FinanceCost !== undefined
                  ? this.props.statenewdata.FinanceCost.map((val) => (
                      <div>{val.Title}</div>
                    ))
                  : null}
              </div>
              <div className="col-3 pr-0 pl-3">
                {this.props.stateolddata.FinanceCost !== undefined
                  ? this.props.stateolddata.FinanceCost.map((val) => (
                      <div>{val.Balance.toLocaleString()}</div>
                    ))
                  : null}
              </div>
              <div className="col-3 pr-0 pl-3">
                {this.props.statenewdata.FinanceCost !== undefined
                  ? this.props.statenewdata.FinanceCost.map((val) => (
                      <div>{val.Balance.toLocaleString()}</div>
                    ))
                  : null}
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-6 pr-0 pl-3 text-center ">
                <label className="input_label m-0">
                  <b>Profit Before Taxation</b>
                </label>
              </div>
              <div className="col-3 pr-0 pl-3">
                {this.props.profitbeforetaxold.toLocaleString()}
              </div>
              <div className="col-3 pr-0 pl-3">
                {this.props.profitbeforetaxnew.toLocaleString()}
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-6 pr-0 pl-3 ">
                <label className="input_label m-0">
                  <b>Taxation</b>
                </label>
              </div>
              <div className="col-3 pr-0 pl-3"></div>
              <div className="col-3 pr-0 pl-3"></div>
            </div>
            <div className="row pt-3">
              <div className="col-6 pr-0 pl-3 text-center ">
                <label className="input_label m-0 ">
                  <b>Profit For The Year</b>
                </label>
              </div>
              <div className="col-3 pr-0 pl-3">
                {this.props.profitbeforetaxold.toLocaleString()}
              </div>
              <div className="col-3 pr-0 pl-3">
                {this.props.profitbeforetaxnew.toLocaleString()}
              </div>
            </div>
          </div>
        );
    }
}

export default ProfitLossPrint;
import { useEffect } from "react";
import { useRef } from "react";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getbalancesheet } from "../../../actions/AccountReports/balancesheet";
import BalanceSheetPrint from "../ReportPrints/BalanceSheetPrint";
import moment from "moment/moment";

const BalanceSheet = () => {
  const statealldata = useSelector((state) => state.getBalanceSheet);
  let stateolddata =
    statealldata.data.OldBalanceSheet !== undefined
      ? statealldata.data.OldBalanceSheet
      : "";

  let statenewdata =
    statealldata.data.NewBalanceSheet !== undefined
      ? statealldata.data.NewBalanceSheet
      : "";

  let dispatch = useDispatch();
  // print function
  const componentRef = useRef();

  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  // const [stateolddata, setolddata] = useState([]);
  // const [statenewdata, setnewdata] = useState([]);

  // const savedata = () => {

  //         setloader(true);

  //         axios({
  //             method: "post",
  //             url: `${window.$url}Reports/BalanceSheet?ProjectId=${localStorage.getItem("ProjectId")}`,

  //             headers: {
  //                 Authorization: `Bearer ${localStorage.getItem("token")}`,
  //                 "content-type": "application/json",
  //             },
  //         }).then(
  //             (result) => {
  //                 console.log("OldBalanceSheet", result.data.OldBalanceSheet);
  //                 console.log("NewBalanceSheet",result.data.NewBalanceSheet);

  //                 if (result.data.IsSuccess === true) {
  //                     setolddata(result.data.OldBalanceSheet);
  //                     setnewdata(result.data.NewBalanceSheet);
  //                                          setloader(false);
  //                 } else {

  //                     setloader(false);

  //                 }
  //             },
  //             (error) => {
  //                 console.log(error);
  //                 setloader(false);

  //             }
  //         );

  // };
  // useEffect(()=>{savedata();},[])
  useEffect(() => {
    dispatch(getbalancesheet());
  }, []);

  return (
    <>
      <div hidden>
        <BalanceSheetPrint
          ref={componentRef}
          stateolddata={stateolddata}
          statenewdata={statenewdata}
          statealldata={statealldata}
        />
      </div>
      <div className="row   app_font ">
        <div className="dash_background col-12 pt-2 ">
          <div className="row ">
            <div className="col-md-2 text-center  pr-0 pl-0">
              <label className="input_label m-0">From:</label>
              <input
                type="date"
                className="input_date ml-3"
                id="fromdate"
                defaultValue={localStorage.getItem("OpeningYear")}
              ></input>
            </div>
            <div className="col-md-2 text-center pr-0 pl-0">
              <label className="input_label m-0">To:</label>
              <input
                type="date"
                className="input_date ml-3"
                id="todate"
                defaultValue={moment().format("YYYY-MM-DD")}
              ></input>
            </div>
            <div className="col-md-1 pr-0 pl-0">
              <button
                className="btn_Go"
                //   onClick={savedata}
                id="go"
              >
                Go
              </button>
            </div>
            <div className="col-md-1 pr-0 pl-0">
              {/* {dateLoading && (
                    <Loader
                      type="ThreeDots"
                      color="green"
                      height={40}
                      width={40}
                    />
                  )} */}
            </div>
          </div>
          <div
            style={{ backgroundColor: "#CAF5F5", height: "1px" }}
            className="col-12 m-2"
          ></div>
          <div className="row ">
            <div className="col-md-6 pr-0 pl-0">
              <label className=" m-0">
                <b>
                  GRAVITY ASSOCIATES (PRIVATE) LIMITED STATEMENT OF FINANCIAL
                  POSITION:
                </b>
              </label>
            </div>
            <div className="col-md-6 pr-0 pl-0">
              {statealldata.loading ? (
                <Loader
                  type="ThreeDots"
                  color="green"
                  height={40}
                  width={40}
                />
              ) : null}
            </div>
          </div>
          {/* assets */}
          <div className="row ">
            <div className="col-md-6 pr-0 pl-0 pt-3 ">
              <h5>
                <b>Assets</b>
              </h5>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              <div>
                <b>
                  {statealldata.data !== undefined
                    ? statealldata.data.PastYear
                    : null}
                </b>
              </div>
              <div>
                <b>Rupees</b>
              </div>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              <div>
                <b>
                  {statealldata.data !== undefined
                    ? statealldata.data.CurrentYear
                    : null}
                </b>
              </div>
              <div>
                <b>Rupees</b>
              </div>
            </div>
          </div>
          {/* non current assets */}
          <div className="row ">
            <div className="col-md-6 pr-0 pl-0">
              <label className="input_label m-0">
                <b>Non Current Assets</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0"></div>
            <div className="col-md-3 pr-0 pl-0"></div>
          </div>
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              {statenewdata.FixedAsset !== undefined
                ? statenewdata.FixedAsset.map((val) => <div>{val.Title}</div>)
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata.FixedAsset !== undefined
                ? stateolddata.FixedAsset.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statenewdata.FixedAsset !== undefined
                ? statenewdata.FixedAsset.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
          </div>
          {/* Current Assets */}
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              <label className="input_label m-0">
                <b>Current Assets</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0"></div>
            <div className="col-md-3 pr-0 pl-0"></div>
          </div>
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              {statenewdata.CurrentAsset !== undefined
                ? statenewdata.CurrentAsset.map((val) => <div>{val.Title}</div>)
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata.CurrentAsset !== undefined
                ? stateolddata.CurrentAsset.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statenewdata.CurrentAsset !== undefined
                ? statenewdata.CurrentAsset.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
          </div>

          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0 text-center">
              <label className="input_label m-0">
                <b>Total Assets</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata
                ? parseFloat(
                    stateolddata.FixedAssetSum + stateolddata.CurrentAssetSum
                  ).toLocaleString()
                : 0}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {parseFloat(
                statenewdata.FixedAssetSum + statenewdata.CurrentAssetSum
              ).toLocaleString()}
            </div>
          </div>
          {/* Equity and liability */}
          <div className="row pt-3">
            <div className="col-md-12 pr-0 pl-0 text-center">
              <h5>
                <b>Equity and Liability </b>
              </h5>
            </div>
            {/* <div className="col-md-3 pr-0 pl-0">

                  
                           
                        </div>
                        <div className="col-md-3 pr-0 pl-0">

       
                        </div> */}
          </div>
          {/* SHARE CAPITAL AND RESERVES */}
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0 ">
              <label className="input_label m-0">
                <b>Share Capital and Reserves</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0"></div>
            <div className="col-md-3 pr-0 pl-0"></div>
          </div>
          {/* Authorized share capital (No Financial Effect) */}
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0 ">
              <label className="input_label m-0">
                <b>Authorized share capital (No Financial Effect)</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0"></div>
            <div className="col-md-3 pr-0 pl-0"></div>
          </div>

          {/* share capital */}
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              <label className="input_label m-0">
                <b>Share Capital</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0"></div>
            <div className="col-md-3 pr-0 pl-0"></div>
          </div>
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              {statenewdata.ShareCapital !== undefined
                ? statenewdata.ShareCapital.map((val) => <div>{val.Title}</div>)
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata.ShareCapital !== undefined
                ? stateolddata.ShareCapital.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statenewdata.ShareCapital !== undefined
                ? statenewdata.ShareCapital.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              <label className="input_label m-0">
                <b>Retained Earning</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statealldata?.data?.OldProfit
                ? parseFloat(statealldata.data.OldProfit).toLocaleString()
                : 0}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statealldata?.data?.NewProfit
                ? parseFloat(statealldata.data.NewProfit).toLocaleString()
                : 0}
            </div>
          </div>
          {/* non current laibilites */}
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              <label className="input_label m-0">
                <b>Non Current Laibilites</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0"></div>
            <div className="col-md-3 pr-0 pl-0"></div>
          </div>
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              {statenewdata.FixedLiabilities !== undefined
                ? statenewdata.FixedLiabilities.map((val) => (
                    <div>{val.Title}</div>
                  ))
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata.FixedLiabilities !== undefined
                ? stateolddata.FixedLiabilities.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statenewdata.FixedLiabilities !== undefined
                ? statenewdata.FixedLiabilities.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
          </div>
          {/*  current laibilites */}
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              <label className="input_label m-0">
                <b>Current Laibilites</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0"></div>
            <div className="col-md-3 pr-0 pl-0"></div>
          </div>
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              {statenewdata.CurrentLiabilities !== undefined
                ? statenewdata.CurrentLiabilities.map((val) => (
                    <div>{val.Title}</div>
                  ))
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata.CurrentLiabilities !== undefined
                ? stateolddata.CurrentLiabilities.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statenewdata.CurrentLiabilities !== undefined
                ? statenewdata.CurrentLiabilities.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
          </div>
          {/* CONTINGENCIES AND COMMITMENTS */}
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0 ">
              <label className="input_label m-0">
                <b>Contingencies And Commitments</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata
                ? parseFloat(
                    stateolddata.FixedLiabilitiesSum +
                      stateolddata.CurrentLiabilitiesSum
                  ).toLocaleString()
                : 0}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {parseFloat(
                statenewdata.FixedLiabilitiesSum +
                  statenewdata.CurrentLiabilitiesSum
              ).toLocaleString()}
            </div>
          </div>
          {/* CONTINGENCIES AND COMMITMENTS */}
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0 ">
              <label className="input_label m-0">
                <b>Total Equities And Liabilities</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata
                ? parseFloat(
                    stateolddata.FixedLiabilitiesSum +
                      stateolddata.CurrentLiabilitiesSum +
                      stateolddata.ShareCapitalSum +
                      statealldata.data.OldProfit
                  ).toLocaleString()
                : 0}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {parseFloat(
                statenewdata.FixedLiabilitiesSum +
                  statenewdata.CurrentLiabilitiesSum +
                  statenewdata.ShareCapitalSum +
                  statealldata.data.NewProfit
              ).toLocaleString()}
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-12  text-right pr-0 pl-0">
              <button
                className="print_btn float-left"
                onClick={() => {
                  printPaymentRec();
                }}
              >
                <TiPrinter size="1.3em" />
                Print
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BalanceSheet;

import moment from "moment";
import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png"
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
export class LandIndvidualPrint extends Component {
    render() {

        return (
          <div style={{ backgroundColor: "white", height: "100%" }}>
            <div className="row m-0 p-0">
              <PrintHeader />
            </div>
            <div
              className="row m-3 p-0"
              // style={{ border: "5px solid darkgrey", height: "auto" }}
            >
              <div className="col-12 text-center mt-2">
                <h4 style={{ color: "red" }}>
                  <u>Land Detail</u>
                </h4>
                <div className="row pt-4">
                  <div className="col-6 mt-2">
                    <ul>
                      <li>
                        <b>Entry Date:</b>{" "}
                        {this.props.printData !== undefined &&
                        this.props.printData.EntryDate !== undefined
                          ? moment(
                              this.props.printData.EntryDate.split("T")[0]
                            ).format("DD-MMM-YYYY")
                          : null}
                      </li>
                      <li>
                        <b> Creditor:</b>{" "}
                        {this.props.printData !== undefined &&
                        this.props.printData.LandCreditor !== undefined
                          ? this.props.printData.LandCreditor.LandCreditorName
                          : null}
                      </li>
                      <li>
                        <b> Payment Plan:</b>{" "}
                        {this.props.printData !== undefined &&
                        this.props.printData.PaymentPlan !== undefined
                          ? this.props.printData.PaymentPlan
                          : null}
                      </li>

                      <li>
                        <b> Sq No:</b>{" "}
                        {this.props.printData !== undefined &&
                        this.props.printData.SqaNumber !== null
                          ? this.props.printData.SqaNumber
                          : null}
                      </li>
                    </ul>
                  </div>
                  <div className="col-6 mt-2">
                    <ul>
                      <li>
                        <b>Land Name:</b>{" "}
                        {this.props.printData !== undefined &&
                        this.props.printData.LandName !== undefined
                          ? this.props.printData.LandName
                          : null}
                      </li>
                      <li>
                        <b> Land Value:</b>{" "}
                        {this.props.printData !== undefined &&
                        this.props.printData.LandValue !== null
                          ? this.props.printData.LandValue.toLocaleString()
                          : null}
                      </li>
                      <li>
                        <b>Size:</b>{" "}
                        {this.props.printData !== undefined &&
                        this.props.printData.MeasureValue !== null &&
                        this.props.printData.MeasureUnit !== null
                          ? this.props.printData.MeasureValue +
                            "" +
                            this.props.printData.MeasureUnit.Unit
                          : null}
                      </li>
                      <li>
                        <b> Kh No:</b>{" "}
                        {this.props.printData !== undefined &&
                        this.props.printData.KhewatNumber !== null
                          ? this.props.printData.KhewatNumber
                          : null}
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="mt-3">
                  <div className="text-center">
                    <b>Installments</b>
                  </div>
                  <table className="table ">
                    <thead className="purple whiteText">
                      <tr>
                        <th scope="col">Sr</th>
                        <th scope="col">Due Date</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.printData === undefined ||
                      this.props.printData.LandPurchasedInstallment ===
                        undefined
                        ? // contractinstallment.length == 0
                          null
                        : this.props.printData.LandPurchasedInstallment.map(
                            (x, id) => (
                              <tr key={id}>
                                <td>{id + 1}</td>

                                <td>
                                  {moment(x.DueDate.split("T")[0]).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </td>
                                <td>{x.InstallmentAmount.toLocaleString()}</td>
                              </tr>
                            )
                          )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        );
    }
}
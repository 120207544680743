import React, { useState, useRef, useEffect } from "react";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import LandPaymentsReportPrint from "./LandPaymentReportPrint";
import { useReactToPrint } from "react-to-print";
const LandPaymentReport = () => {
  const [statecashbookdata, setcashbookdata] = useState([]);
  const [statedebit, setdebit] = useState(0);
  const [statecredit, setcredit] = useState(0);
  const [loader, setloader] = useState(false);
  const [statecashinhand, setcashinhand] = useState(0);
  const [stateopeningbal, setopeningbal] = useState(0);
  const [csvData, setcsvData] = useState([]);
  // print function
  const componentRef = useRef();

  const savedata = () => {
    document.getElementById("go").disabled = true;
    console.log(document.getElementById("todate").value);
    if (
      document.getElementById("fromdateLand").value === "" ||
      document.getElementById("todateLand").value === ""
    ) {
      toast.info("Select FromDate To ToDate");
      document.getElementById("go").disabled = false;
    } else {
      setloader(true);

      axios({
        method: "post",
        url: `${window.$url}Reports/DashBoard/LandPayment`,
        data: JSON.stringify({
          From: document.getElementById("fromdateLand").value,
          To: document.getElementById("todateLand").value,
          ProjectId: localStorage.getItem("ProjectId")
            ? localStorage.getItem("ProjectId")
            : null,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result.data.IsSuccess === true) {
            setcashinhand(result.data.CashInHand);
            setopeningbal(result.data.OpeningBalance);
            var credit = 0;

            result.data.Data.map((val) =>
              val.TransactionType === "CREDIT"
                ? (credit += parseFloat(val.Amount))
                : 0
            );
            setcredit(credit);
            var debit = 0;

            result.data.Data.map((val) =>
              val.TransactionType === "DEBIT"
                ? (debit += parseFloat(val.Amount))
                : 0
            );
            setdebit(debit);
            setcashbookdata(result.data.Data);

            document.getElementById("go").disabled = false;
            setloader(false);
          } else {
            setloader(false);
            document.getElementById("go").disabled = false;
          }
        },
        (error) => {
          setloader(false);
          document.getElementById("go").disabled = false;
        }
      );
    }
  };
  useEffect(() => {
    let arr = [];
    statecashbookdata?.forEach((itm) => {
      arr.push({
        Date: moment(itm.EntryDate).format("YYYY-MM-DD"),
        Narration: itm.Narration,
        Debit: itm.TransactionType === "DEBIT" ? parseFloat(itm.Amount) : 0,
        Debit: itm.TransactionType === "CREDIT" ? parseFloat(itm.Amount) : 0,
        Balance: itm.AccountBalance,
        Type: itm.AccountBalance < 0 ? "CR" : "DR",
      });
    });
    arr.push(
      {
        Date: "",
        Narration: "Total",
        Debit: statedebit,
        Debit: statecredit,
        Balance: statedebit - statecredit,
        Type: "",
      },
      {
        Date: "Opening Balance",
        Narration: stateopeningbal,
        Debit: "",
        Debit: "Cash In Hand",
        Balance: statecashinhand,
        Type: "",
      }
    );
    setcsvData(arr);
  }, [statecashbookdata, stateopeningbal]);

  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>

      <div className="row app_font  m-0 p-0">
        <div className="dash_background col-12 pt-2 ">
          <div className="row ">
            <div className="col-md-5 text-center  pr-0 pl-0">
              <label className="input_label m-0">From:</label>
              <input
                type="date"
                className="input_date ml-3"
                id="fromdateLand"
                defaultValue={localStorage.getItem("OpeningYear")}
              ></input>
            </div>
            <div className="col-md-5 text-center pr-0 pl-0">
              <label className="input_label m-0">To:</label>
              <input
                type="date"
                className="input_date ml-3"
                id="todateLand"
                defaultValue={moment().format("YYYY-MM-DD")}
              ></input>
            </div>
            <div className="col-md-1 pr-0 pl-0">
              <button className="btn_Go" onClick={savedata} id="go">
                Go
              </button>
            </div>
            <div className="col-md-1 pr-0 pl-0">
              {loader && (
                <Loader type="ThreeDots" color="green" height={40} width={40} />
              )}
            </div>
          </div>

          <div className=" col-12  table_height pr-0 pl-0">
            <div className="table-responsive mt-2">
              <table className="table table-borderless m-0">
                <thead>
                  <tr>
                    <th>Sr</th>

                    <th>Date</th>
                    <th>Account</th>
                    <th>Narration</th>
                    <th>Payment</th>
                    <th>Receipt</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {statecashbookdata?.map((val, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {moment(val.EntryDate.split("T")[0]).format(
                          "DD-MMM-YYYY"
                        )}
                      </td>
                      <td>{val.Account}</td>
                      <td>{val.Narration}</td>

                      {val.TransactionType === "DEBIT" ? (
                        <td>{parseFloat(val.Amount).toLocaleString()}</td>
                      ) : (
                        <td></td>
                      )}
                      {val.TransactionType === "CREDIT" ? (
                        <td>{parseFloat(val.Amount).toLocaleString()}</td>
                      ) : (
                        <td></td>
                      )}
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <button
                  disabled={statecashbookdata.length > 0 ? false : true}
                  className="print_btn float-left"
                  onClick={() => {
                    printPaymentRec();
                  }}
                >
                  <TiPrinter size="1.3em" />
                  Print
                </button>
              </div>
              <div>
                Payment Sum: <b>{parseFloat(statedebit).toLocaleString()}</b>{" "}
              </div>

              <div>
                Receipt Sum: <b>{parseFloat(statecredit).toLocaleString()}</b>{" "}
              </div>
              <div>
                Balance:
                <b>
                  {parseFloat(statedebit - statecredit).toLocaleString()}{" "}
                </b>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div hidden>
        <LandPaymentsReportPrint
          ref={componentRef}
          printData={statecashbookdata}
          statecashinhand={statedebit}
          stateopeningbal={statecredit}
        />
      </div>
    </>
  );
};

export default LandPaymentReport;

import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import moment from "moment";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
import IndividualPrintHeader from "../../../Prints/PrintHeader/IndividualPrintHeader";
var converter = require("number-to-words");
class GeneralRecPrint extends Component {
  render() {
    return (
      <div className="row m-0 p-0">
        <IndividualPrintHeader />
        {/* <div className="col-4 text-center">
          <img
            src={gravity}
            style={{ height: "175px", width: "175px", borderRadius: "100px" }}
            className="pt-2"
            alt="Company logo"
          />
        </div>
        <div className="col-3"></div>
        <div className="col-5 text-center mt-4">
          <h2 style={{ color: "#1b7a7f" }}>Gravity Associates Pvt Limited</h2>
          <hr />
          <h6 style={{ color: "grey", marginTop: "5px" }}>
            <i>204 R/B Road, East Canal Road, Faisalabad.</i>
          </h6>
        </div> */}

        <h4 className="col-12 text-center">
          <u>General Receipt</u>
        </h4>
        <h4 className="col-12 text-center">
          {localStorage.getItem("ProjectName") === "THE GRAND CITY"
            ? "THE GRAND ICONIC CITY"
            : localStorage.getItem("ProjectName")}
        </h4>
        <div className="col-6 mt-2">
          Voucher No:{" "}
          {this.props?.printData !== undefined
            ? this.props?.printData.GeneralVoucherId
            : null}
        </div>
        <div className="col-6 mt-2 text-right">
          Voucher Date:{" "}
          {this.props?.printData !== undefined
            ? moment(this.props?.printData.EntryDate.split("T")[0]).format(
                "DD-MMM-YYYY"
              )
            : null}
        </div>

        <div className="col-6 mt-2">
          Payment Type:{" "}
          {this.props?.printData !== undefined
            ? this.props?.printData.PaymentType
            : null}
        </div>
        {/* <div className="col-6 mt-2 text-right">
                    Print Date:{" "}
                    {moment().format("DD-MMM-YYYY")}
                </div> */}

        {/* <div className="col-12 mt-2">
                    Received with thanks from:
                </div>
            
                <div className="col-6 mt-2">
                    Name:
                </div>
                <div className="col-6 mt-2 text-right">
                    CNIC:
                </div>
                
                <div className="col-6 mt-2">
                    S,D,W/O:
                </div>
                <div className="col-6 mt-2 text-right">
                    Cell:
                </div>
            */}
        <div className="col-12 mt-3">
          <table className="table">
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Name
                </th>

                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Narration
                </th>
                {this.props?.printData?.ChequeNumber && (
                  <th
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    Cheque Number
                  </th>
                )}

                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Amount
                </th>
              </tr>
            </thead>

            <tbody>
              {this.props?.printData !== undefined ? (
                <tr>
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    1
                  </td>
                  {this.props?.printData?.Transaction[
                    this.props?.printData?.Transaction.length - 1
                  ] !== undefined ? (
                    this.props?.printData?.Transaction[
                      this.props?.printData?.Transaction.length - 1
                    ].Account !== null &&
                    this.props?.printData?.Transaction[
                      this.props?.printData?.Transaction.length - 1
                    ].Account !== undefined ? (
                      <td
                        style={{
                          borderLeft: "1px solid grey",
                          borderRight: "1px solid grey",
                        }}
                      >
                        {
                          this.props?.printData?.Transaction[
                            this.props?.printData?.Transaction.length - 1
                          ].Account.AccountTitle
                        }
                      </td>
                    ) : (
                      <td></td>
                    )
                  ) : (
                    <td>
                      {
                        this.props?.printData?.GeneralVoucherSaved
                          ?.CreditAccount?.AccountTitle
                      }
                    </td>
                  )}

                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    {this.props?.printData.Narration}
                  </td>
                  {this.props?.printData?.ChequeNumber && (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      {this.props?.printData.ChequeNumber}
                    </td>
                  )}
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    {parseFloat(this.props?.printData.Amount)?.toLocaleString()}
                  </td>
                </tr>
              ) : null}
            </tbody>
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th style={{ borderLeft: "1px solid grey" }}>Total</th>
                <th></th>

                {this.props?.printData?.ChequeNumber && <th></th>}

                <th></th>

                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  {this.props?.printData !== undefined
                    ? parseFloat(this.props?.printData.Amount)?.toLocaleString()
                    : null}
                </th>
              </tr>
            </thead>
          </table>
        </div>

        <div className="col-12 mt-2">
          Total Amount:{" "}
          {this.props?.printData !== undefined
            ? parseFloat(this.props?.printData.Amount)?.toLocaleString()
            : null}
        </div>

        <div className="col-12 mt-2">
          Amount in words:{" "}
          {this.props?.printData !== undefined
            ? converter.toWords(parseFloat(this.props?.printData.Amount))
            : null}
        </div>

        {/* <div className="col-12 mt-2">
                    On account payment of shop:
                </div>                          
             */}
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Received By:</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Authorized By:</b>
        </div>

        <div className="col-12 mt-4">
          <hr />
        </div>
      </div>
    );
  }
}

export default GeneralRecPrint;
export class GeneralRecModal extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div
          className="row m-3 p-0"
          style={{ border: "5px solid darkgrey", height: "85%" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>General Receipt Transactions </u>
            </h4>
            <h5 className="text-right pt-3 ">
              <div className="row">
                <div className="col-2 text-right">
                  <b>Voucher Date: </b>
                </div>
                <div className="col-2 text-center">
                  {this.props?.printData?.length > 0 &&
                    moment(
                      this.props?.printData[0]?.EntryDate?.slice(0, 10)
                    ).format("DD-MMM-YYYY")}
                </div>
                <div className="col-4"></div>
                <div className="col-2 text-right">
                  <b>Print Date: </b>
                </div>

                <div className="col-2 text-center">
                  {moment().format("DD-MMM-YYYY")}
                </div>
              </div>
            </h5>
            {/* <h5 className="d-flex justify-content-between" >
                            <div className="text-left">

                                <b>Voucher No:{" "}</b>
                                {this.props?.Data !== undefined ?

                                    this.props?.Data.GeneralVoucherId

                                    : null}</div>
                            <div className="text-right">
                                <b>Payment Type:{" "}</b>
                                {this.props?.Data !== undefined ?

                                    this.props?.Data.VoucherType

                                    : null}
                            </div>

                        </h5> */}

            <div className="mt-3">
              <div class="table-responsive-print col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Account</th>
                      <th>Narration</th>
                      <th>Debit</th>

                      <th>Credit</th>
                    </tr>
                  </thead>
                  {this.props?.printData !== undefined
                    ? this.props?.printData.map((x, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{x.Account.AccountTitle}</td>
                          <td>{x.Narration}</td>
                          <td>
                            {x.TransactionType === "DEBIT"
                              ? x.Amount?.toLocaleString()
                              : null}
                          </td>

                          <td>
                            {x.TransactionType === "CREDIT"
                              ? x.Amount?.toLocaleString()
                              : null}
                          </td>
                        </tr>
                      ))
                    : null}
                  <tr>
                    <td></td>
                    <td></td>

                    <td>
                      {" "}
                      <label className="input_label m-0">Total:</label>
                    </td>
                    <td>{this.props?.credittotal?.toLocaleString()}</td>
                    <td>{this.props?.debittotal?.toLocaleString()}</td>
                    {/* <td><input type="text" className="input_total_styling " placeholder="Balance" disabled this.props?.printData?ue={stateledgerdata[stateledgerdata.length - 1]?.AccountBalance??.toLocaleString()}></input></td> */}
                    <td></td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="col-12 text-left mt-2">
              Total Amount:{" "}
              {this.props?.total !== undefined
                ? parseFloat(this.props?.total)?.toLocaleString()
                : null}
            </div>

            <div className="col-12 text-left mt-2">
              Amount in words:{" "}
              {this.props?.total !== undefined
                ? converter.toWords(parseFloat(this.props?.total))
                : null}
            </div>
            <div className="signature">
              <h5 className=" pt-3 ">
                <div className="row">
                  <div className="col-2">
                    <b>Prepared By: </b>
                  </div>
                  <div className="col-2">
                    <>----------------</>
                  </div>
                  <div className="col-2">
                    <b>Checked By: </b>
                  </div>
                  <div className="col-2">
                    <>----------------</>
                  </div>
                  <div className="col-2">
                    <b>Approved By: </b>
                  </div>

                  <div className="col-2">
                    <>----------------</>
                  </div>
                </div>
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class RecoveryBookingPrint extends Component {
  render() {
    return (
      <div className="row m-0 p-0">
        <IndividualPrintHeader />

        <h4 className="col-12 text-center">
          <u>Recovery Booking</u>
        </h4>
        <h4 className="col-12 text-center">
          {localStorage.getItem("ProjectName") === "THE GRAND CITY"
            ? "THE GRAND ICONIC CITY"
            : localStorage.getItem("ProjectName")}
        </h4>
        <div className="col-6 mt-2">
          Voucher No:{" "}
          {this.props?.printData !== undefined
            ? this.props?.printData.RecoveryBookingVoucherId
            : null}
        </div>
        <div className="col-6 mt-2 text-right">
          Voucher Date:{" "}
          {this.props?.printData !== undefined &&
          this.props?.printData.EntryDate !== undefined
            ? moment(this.props?.printData.EntryDate.split("T")[0]).format(
                "DD-MMM-YYYY"
              )
            : null}
        </div>

        <div className="col-6 mt-2">
          Payment Type:{" "}
          {this.props?.printData !== undefined
            ? this.props?.printData.VoucherType
            : null}
        </div>
        {/* <div className="col-6 mt-2 text-right">
                    Print Date:{" "}
                    {moment().format("DD-MMM-YYYY")}
                </div> */}

        {/* <div className="col-12 mt-2">
                    Received with thanks from:
                </div>
            
                <div className="col-6 mt-2">
                    Name: {this.props?.printData !== undefined ?

this.props?.printData.AffiliateCnicNavigation.AffiliateName

                                : null}
                </div>
                <div className="col-6 mt-2 text-right">
                    CNIC:
                    {this.props?.printData !== undefined ?

                        this.props?.printData.Contract.AffiliateCnic

                                : null}
                </div>
                
                <div className="col-6 mt-2">
                    Cell: {this.props?.printData !== undefined ?

                        this.props?.printData.Contract.AffiliateCnicNavigation.PhoneNumber

                                : null}
                </div>
                 */}
        <div className="col-6 mt-2 text-right"></div>

        <div className="col-12 mt-3">
          <table className="table">
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Manager
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Project{" "}
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Project Owner
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Narration
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Amount
                </th>
              </tr>
            </thead>

            {this.props?.printData !== undefined ? (
              <tbody>
                <tr>
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    1
                  </td>
                  {this.props?.printData.Manager !== undefined &&
                  this.props?.printData.Manager.Employee !== undefined ? (
                    <td>{this.props?.printData.Manager.Employee.EmpName}</td>
                  ) : (
                    <td></td>
                  )}
                  {this.props?.printData.Project !== undefined &&
                  this.props?.printData.Project !== null ? (
                    <td>
                      {this.props?.printData.Project.ProjectName ===
                      "THE GRAND CITY"
                        ? "THE GRAND ICONIC CITY"
                        : this.props?.printData.Project.ProjectName}
                    </td>
                  ) : (
                    <td></td>
                  )}
                  <td>{this.props?.printData.Project.ProjectOwner}</td>
                  <td>{this.props?.printData.Narration}</td>

                  <td>
                    {parseFloat(this.props?.printData.Amount)?.toLocaleString()}
                  </td>
                </tr>
              </tbody>
            ) : null}
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th style={{ borderLeft: "1px solid grey" }}>Total</th>
                <th></th>

                <th></th>
                <th></th>
                <th></th>

                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  {this.props?.printData !== undefined
                    ? parseFloat(this.props?.printData.Amount)?.toLocaleString()
                    : null}
                </th>
              </tr>
            </thead>
          </table>
        </div>

        <div className="col-12 mt-2">
          Total Amount:{" "}
          {this.props?.printData !== undefined
            ? parseFloat(this.props?.printData.Amount)?.toLocaleString()
            : null}
        </div>

        <div className="col-12 mt-2">
          ReceivedAmount in words:{" "}
          {this.props?.printData !== undefined
            ? converter.toWords(parseFloat(this.props?.printData.Amount))
            : null}
        </div>

        {/* <div className="col-12 mt-2">
                    On account payment of shop:
                </div>                          
             */}
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Received By:</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Authorized By:</b>
        </div>

        <div className="col-12 mt-4">
          <hr />
        </div>
      </div>
    );
  }
}

export class RecoveryRecPrint extends Component {
  render() {
    return (
      <div className="row m-0 p-0">
        <IndividualPrintHeader />

        <h4 className="col-12 text-center">
          <u>Recovery Receipt</u>
        </h4>
        <h4 className="col-12 text-center">
          {localStorage.getItem("ProjectName") === "THE GRAND CITY"
            ? "THE GRAND ICONIC CITY"
            : localStorage.getItem("ProjectName")}
        </h4>
        <div className="col-6 mt-2">
          Voucher No:{" "}
          {this.props?.printData !== undefined
            ? this.props?.printData.RecoveryReceiptVoucherId
            : null}
        </div>
        {/* <div className="col-6 mt-2 text-right">
                    Print Date:{" "}
                    {moment().format("DD-MMM-YYYY")}
                </div> */}

        {/* 
                <div className="col-6 mt-2">
                       Payment Type:{" "}
                       {this.props?.printData !== undefined ?

                            this.props?.printData.PaymentType

                            : null}
                </div>
                <div className="col-6 mt-2 text-right">
                       
                </div>
            
                <div className="col-12 mt-2">
                    Received with thanks from:
                </div>
            
                <div className="col-6 mt-2">
                    Name: {this.props?.printData !== undefined ?

this.props?.printData.AffiliateCnicNavigation.AffiliateName

                                : null}
                </div>
                <div className="col-6 mt-2 text-right">
                    CNIC:
                    {this.props?.printData !== undefined ?

                        this.props?.printData.Contract.AffiliateCnic

                                : null}
                </div>
                
                <div className="col-6 mt-2">
                    Cell: {this.props?.printData !== undefined ?

                        this.props?.printData.Contract.AffiliateCnicNavigation.PhoneNumber

                                : null}
                </div>
                 */}
        <div className="col-6 mt-2 text-right"></div>

        <div className="col-12 mt-3">
          <table className="table">
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Manager
                </th>

                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Project Title
                </th>

                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Project Owner
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Unit
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Narration
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Cheque Number
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Amount
                </th>
              </tr>
            </thead>

            {this.props?.printData !== undefined ? (
              <tbody>
                <tr>
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    1
                  </td>
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  ></td>
                  {/* {this.props?.printData.Manager !== undefined && this.props?.printData.Manager.Employee !== undefined ?
                                                    <td style={{borderLeft:"1px solid grey", borderRight:"1px solid grey"}}>{this.props?.printData.Manager.Employee.EmpName}</td> : <td style={{borderLeft:"1px solid grey", borderRight:"1px solid grey"}}></td>} */}
                  {this.props?.printData.Project !== undefined &&
                  this.props?.printData.Project !== null ? (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      {this.props?.printData.Project.ProjectName ===
                      "THE GRAND CITY"
                        ? "THE GRAND ICONIC CITY"
                        : this.props?.printData.Project.ProjectName}
                    </td>
                  ) : (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    ></td>
                  )}
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    {this.props?.printData.Project.ProjectOwner}
                  </td>
                  {this.props?.printData.Unit !== undefined ? (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      {this.props?.printData.Unit.UnitName}
                    </td>
                  ) : (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    ></td>
                  )}
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    {this.props?.printData.Narration}
                  </td>
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    {this.props?.printData.ChequeNumber}
                  </td>
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    {parseFloat(
                      this.props?.printData.NetAmount
                    )?.toLocaleString()}
                  </td>
                </tr>
              </tbody>
            ) : null}
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th style={{ borderLeft: "1px solid grey" }}>Total</th>
                <th></th>

                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>

                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  {this.props?.printData !== undefined
                    ? parseFloat(
                        this.props?.printData.NetAmount
                      )?.toLocaleString()
                    : null}
                </th>
              </tr>
            </thead>
          </table>
        </div>

        <div className="col-12 mt-2">
          Total Amount:{" "}
          {this.props?.printData !== undefined
            ? parseFloat(this.props?.printData.NetAmount)?.toLocaleString()
            : null}
        </div>

        <div className="col-12 mt-2">
          Amount in words:{" "}
          {this.props?.printData !== undefined
            ? converter.toWords(parseFloat(this.props?.printData.NetAmount))
            : null}
        </div>

        {/* <div className="col-12 mt-2">
                    On account payment of shop:
                </div>                          
             */}
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Received By:</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Authorized By:</b>
        </div>

        <div className="col-12 mt-4">
          <hr />
        </div>
      </div>
    );
  }
}
export class RecoveryRecModal extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div
          className="row m-3 p-0"
          style={{ border: "5px solid darkgrey", height: "85%" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Recovery Receipt Transactions </u>
            </h4>
            <h5 className="text-right pt-3 ">
              <div className="row">
                <div className="col-8"></div>
                <div className="col-2 text-right">
                  <b>Print Date: </b>
                </div>

                <div className="col-2 text-center">
                  {moment().format("DD-MMM-YYYY")}
                </div>
              </div>
            </h5>
            {/* <h5 className="d-flex justify-content-between" >
                            <div className="text-left">

                                <b>Voucher No:{" "}</b>
                                {this.props?.Data !== undefined ?

                                    this.props?.Data.RecoveryReceiptVoucherId

                                    : null}</div>
                            <div className="text-right">
                                <b>Payment Type:{" "}</b>
                                {this.props?.Data !== undefined ?

                                    this.props?.Data.VoucherType

                                    : null}
                            </div>

                        </h5> */}

            <div className="mt-3">
              <div class="table-responsive-print col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Account</th>

                      <th>Debit</th>

                      <th>Credit</th>
                    </tr>
                  </thead>
                  {this.props?.printData !== undefined
                    ? this.props?.printData.map((x, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{x.Account.AccountTitle}</td>
                          <td>
                            {x.TransactionType === "DEBIT"
                              ? x.Amount?.toLocaleString()
                              : null}
                          </td>

                          <td>
                            {x.TransactionType === "CREDIT"
                              ? x.Amount?.toLocaleString()
                              : null}
                          </td>
                        </tr>
                      ))
                    : null}
                </table>
              </div>
            </div>
            <div className="signature">
              <h5 className=" pt-3 ">
                <div className="row">
                  <div className="col-2">
                    <b>Prepared By: </b>
                  </div>
                  <div className="col-2">
                    <>----------------</>
                  </div>
                  <div className="col-2">
                    <b>Checked By: </b>
                  </div>
                  <div className="col-2">
                    <>----------------</>
                  </div>
                  <div className="col-2">
                    <b>Approved By: </b>
                  </div>

                  <div className="col-2">
                    <>----------------</>
                  </div>
                </div>
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export class LoanRecPrint extends Component {
  render() {
    return (
      <div className="row m-0 p-0">
        <IndividualPrintHeader />

        <h4 className="col-12 text-center">
          <u>Investment Receipt</u>
        </h4>
        <h4 className="col-12 text-center">
          {localStorage.getItem("ProjectName") === "THE GRAND CITY"
            ? "THE GRAND ICONIC CITY"
            : localStorage.getItem("ProjectName")}
        </h4>
        <div className="col-6 mt-2">
          Voucher No:{" "}
          {this.props?.printData !== undefined
            ? this.props?.printData.RecoveryReceiptVoucherId
            : null}
        </div>
        <div className="col-6 mt-2 text-right">
          Voucher Date:{" "}
          {this.props?.printData !== undefined
            ? moment(this.props?.printData.EntryDate.split("T")[0]).format(
                "DD-MMM-YYYY"
              )
            : null}
        </div>

        <div className="col-6 mt-2">
          Payment Type:{" "}
          {this.props?.printData !== undefined
            ? this.props?.printData.PaymentType
            : null}
        </div>
        {/* <div className="col-6 mt-2 text-right">
                    Print Date:{" "}
                    {moment().format("DD-MMM-YYYY")}
                </div> */}

        {/* <div className="col-12 mt-2">
                    Received with thanks from:
                </div>
            
                <div className="col-6 mt-2">
                    Name: {this.props?.printData !== undefined ?

this.props?.printData.AffiliateCnicNavigation.AffiliateName

                                : null}
                </div>
                <div className="col-6 mt-2 text-right">
                    CNIC:
                    {this.props?.printData !== undefined ?

                        this.props?.printData.Contract.AffiliateCnic

                                : null}
                </div>
                
                <div className="col-6 mt-2">
                    Cell: {this.props?.printData !== undefined ?

                        this.props?.printData.Contract.AffiliateCnicNavigation.PhoneNumber

                                : null}
                </div>
                 */}
        <div className="col-6 mt-2 text-right"></div>

        <div className="col-12 mt-3">
          <table className="table">
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Loan Name
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Lender Name
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Narration
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Cheque Number
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Amount
                </th>
              </tr>
            </thead>

            {this.props?.printData !== undefined ? (
              <tbody>
                <tr>
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    1
                  </td>
                  {this.props?.printData.Loan !== undefined &&
                  this.props?.printData.Loan !== null ? (
                    <td>{this.props?.printData.Loan.LoanName}</td>
                  ) : (
                    <td></td>
                  )}
                  {this.props?.printData.Lender !== undefined &&
                  this.props?.printData.Lender !== null ? (
                    <td>{this.props?.printData.Lender.LenderName}</td>
                  ) : (
                    <td></td>
                  )}
                  <td>{this.props?.printData.Narration}</td>
                  <td>{this.props?.printData.ChequeNumber}</td>
                  <td>
                    {parseFloat(
                      this.props?.printData.ReceivedAmount
                    )?.toLocaleString()}
                  </td>
                </tr>
              </tbody>
            ) : null}
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th style={{ borderLeft: "1px solid grey" }}>Total</th>
                <th></th>

                <th></th>
                <th></th>
                <th></th>

                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  {this.props?.printData !== undefined
                    ? parseFloat(
                        this.props?.printData.NetAmount
                      )?.toLocaleString()
                    : null}
                </th>
              </tr>
            </thead>
          </table>
        </div>

        <div className="col-12 mt-2">
          Total Amount:{" "}
          {this.props?.printData !== undefined
            ? parseFloat(this.props?.printData.NetAmount)?.toLocaleString()
            : null}
        </div>
        {/* 
                <div className="col-12 mt-2">
                    Amount in words: {this.props?.printData !== undefined ? converter.toWords(parseFloat(this.props?.printData.NetAmount)) : null}
                </div> */}

        {/* <div className="col-12 mt-2">
                    On account payment of shop:
                </div>                          
             */}
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Received By:</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Authorized By:</b>
        </div>

        <div className="col-12 mt-4">
          <hr />
        </div>
      </div>
    );
  }
}
export class LoanRecModal extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div
          className="row m-3 p-0"
          style={{ border: "5px solid darkgrey", height: "85%" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Investment Receipt Transactions </u>
            </h4>
            <h5 className="text-right pt-3 ">
              <div className="row">
                <div className="col-8"></div>
                <div className="col-2 text-right">
                  <b>Print Date: </b>
                </div>

                <div className="col-2 text-center">
                  {moment().format("DD-MMM-YYYY")}
                </div>
              </div>
            </h5>
            {/* <h5 className="d-flex justify-content-between" >
                            <div className="text-left">

                                <b>Voucher No:{" "}</b>
                                {this.props?.Data !== undefined ?

                                    this.props?.Data.LoanReceiptVoucherId

                                    : null}</div>
                            <div className="text-right">
                                <b>Payment Type:{" "}</b>
                                {this.props?.Data !== undefined ?

                                    this.props?.Data.VoucherType

                                    : null}
                            </div>

                        </h5> */}

            <div className="mt-3">
              <div class="table-responsive-print col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Account</th>
                      <th>Narration</th>
                      <th>Debit</th>

                      <th>Credit</th>
                    </tr>
                  </thead>
                  {this.props?.printData !== undefined
                    ? this.props?.printData.map((x, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{x.Account.AccountTitle}</td>
                          <td>{x.Narration}</td>
                          <td>
                            {x.TransactionType === "DEBIT"
                              ? x.Amount?.toLocaleString()
                              : null}
                          </td>

                          <td>
                            {x.TransactionType === "CREDIT"
                              ? x.Amount?.toLocaleString()
                              : null}
                          </td>
                        </tr>
                      ))
                    : null}
                  <tr>
                    <td></td>
                    <td></td>

                    <td>
                      {" "}
                      <label className="input_label m-0">Total:</label>
                    </td>
                    <td>{this.props?.creditTotal?.toLocaleString()}</td>
                    <td>{this.props?.debitTotal?.toLocaleString()}</td>
                    <td></td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="col-12 text-left mt-2">
              Total Amount:{" "}
              {this.props?.total !== undefined
                ? parseFloat(this.props?.total)?.toLocaleString()
                : null}
            </div>

            <div className="col-12 text-left mt-2">
              Amount in words:{" "}
              {this.props?.total !== undefined
                ? converter.toWords(parseFloat(this.props?.total))
                : null}
            </div>
            <div className="signature">
              <h5 className=" pt-3 ">
                <div className="row">
                  <div className="col-2">
                    <b>Prepared By: </b>
                  </div>
                  <div className="col-2">
                    <>----------------</>
                  </div>
                  <div className="col-2">
                    <b>Checked By: </b>
                  </div>
                  <div className="col-2">
                    <>----------------</>
                  </div>
                  <div className="col-2">
                    <b>Approved By: </b>
                  </div>

                  <div className="col-2">
                    <>----------------</>
                  </div>
                </div>
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export class UnitReceiptPrint extends Component {
  render() {
    return (
      <div className="row m-0 p-0">
        <IndividualPrintHeader />
        <h4 className="col-12 text-center">
          <u>Unit Receipt</u>
        </h4>
        <h4 className="col-12 text-center">
          {localStorage.getItem("ProjectName") === "THE GRAND CITY"
            ? "THE GRAND ICONIC CITY"
            : localStorage.getItem("ProjectName")}
        </h4>
        <div className="col-6 mt-2">
          <b> Voucher Date: </b>
          {this.props?.printData !== undefined
            ? moment(this.props?.printData?.EntryDate?.split("T")[0]).format(
                "DD-MMM-YYYY"
              )
            : null}
        </div>
        <div className="col-6 mt-2 text-right">
          <b> Total Price:</b>
          {this.props?.printData !== undefined && this.props?.printData !== null
            ? this.props?.printData?.Contract?.TotalPrice?.toLocaleString()
            : null}
        </div>
        <div className="col-6 mt-2">
          <b> Name: </b>
          {this.props?.printData !== undefined && this.props?.printData !== null
            ? this.props?.printData?.Contract?.CustomerCnicNavigation
                ?.CustomerName
            : null}
        </div>
        <div className="col-6 mt-2 text-right">
          <b> Received:</b>
          {this.props?.printData !== undefined &&
          this.props?.printData !== null &&
          this.props?.printData?.Contract?.CustomerReceivable &&
          this.props?.printData?.Contract?.TotalPrice
            ? Number(
                Number(this.props?.printData?.Contract?.TotalPrice) -
                  Number(this.props?.printData?.Contract?.CustomerReceivable)
              )?.toLocaleString()
            : null}
        </div>{" "}
        {/* <div className="col-6 mt-2 text-right"> */}
        {/* <b> CNIC:</b>
          {this.props?.printData !== undefined &&
                        this.props?.printData !== null
                          ? this.props?.printData?.Contract?.CustomerCnicNavigation?.CustomerCnic
                          : null} */}
        {/* </div> */}
        <div className="col-6 mt-2  ">
          <b> CNIC:</b>
          {this.props?.printData !== undefined && this.props?.printData !== null
            ? this.props?.printData?.Contract?.CustomerCnicNavigation
                ?.CustomerCnic2
            : null}
        </div>
        <div className="col-6 mt-2 text-right ">
          <b> Receivable:</b>
          {this.props?.printData !== undefined && this.props?.printData !== null
            ? this.props?.printData?.Contract?.CustomerReceivable?.toLocaleString()
            : null}
        </div>
        <div className="col-6 mt-2  ">
          <b> Unit Name:</b>
          {this.props?.printData !== undefined && this.props?.printData !== null
            ? this.props?.printData?.Contract?.Unit?.UnitName
            : null}
        </div>{" "}
        <div className="col-12 mt-3">
          <table className="table">
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                    fontWeight: "bold",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                    fontWeight: "bold",
                  }}
                >
                  Date
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                    fontWeight: "bold",
                  }}
                >
                  Voucher No
                </th>

                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                    fontWeight: "bold",
                  }}
                >
                  Customer
                </th>
                {/* <th style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey" }}>Due Amount</th> */}
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                    fontWeight: "bold",
                  }}
                >
                  Unit
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                    fontWeight: "bold",
                  }}
                >
                  Narration
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                    fontWeight: "bold",
                  }}
                >
                  Amount
                </th>
              </tr>
            </thead>

            <tbody>
              {/* {this.props?.printData !== undefined &&
              this.props?.printData?.UnitReceiptDetail !== undefined */}
              {/* // ? this.props?.printData?.UnitReceiptDetail?.map((val, index) => ( */}
              <tr>
                <td>1</td>
                <td>
                  {this.props?.printData !== undefined &&
                  this.props?.printData !== null
                    ? moment(
                        this.props?.printData?.EntryDate.split("T")[0]
                      ).format("DD-MMM-YYYY")
                    : null}
                </td>
                <td>
                  {this.props?.printData !== undefined &&
                  this.props?.printData !== null
                    ? this.props?.printData?.UnitVoucherId
                    : null}
                </td>
                <td>
                  {this.props?.printData?.Contract !== undefined &&
                  this.props?.printData?.Contract !== null
                    ? this.props?.printData?.Contract?.CustomerCnicNavigation
                        ?.CustomerName
                    : null}
                </td>
                <td>
                  {this.props?.printData?.Contract?.Unit !== undefined &&
                  this.props?.printData?.Contract?.Unit !== null
                    ? this.props?.printData?.Contract?.Unit?.UnitName
                    : null}
                </td>
                <td>
                  {this.props?.printData?.Narration !== undefined &&
                  this.props?.printData?.Narration !== null
                    ? this.props?.printData?.Narration
                    : null}
                </td>
                <td>
                  {this.props?.printData !== undefined &&
                  this.props?.printData !== null
                    ? this.props?.printData?.Amount?.toLocaleString()
                    : null}
                </td>
                {/* <td>{this.props?.printData?.Narration}</td>

                      <td>
                        {val.ContractInstallment !== undefined &&
                        val.ContractInstallment !== null &&
                        val.ContractInstallment.DueDate !== null
                          ? moment(
                              val.ContractInstallment.DueDate.split("T")[0]
                            ).format("DD-MMM-YYYY")
                          : null}
                      </td>
                      <td>{val.ReceivedAmount?.toLocaleString()}</td> */}
              </tr>
              {/* // )) */}
              {/* : null} */}
              {/* {this.props?.printData !== undefined ?
                                <tr>
                                    <td style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey" }}>1</td>
                                    {this.props?.printData.Project !== undefined && this.props?.printData.Project !== null ?
                                        <td style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey" }}>{ this.props?.printData.Project.ProjectName === "THE GRAND CITY" ? "THE GRAND ICONIC CITY" : this.props?.printData.Project.ProjectName}</td> : <td style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey" }}></td>}


                                    <td style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey" }}>{this.props?.printData.Contract !== undefined ? this.props?.printData.Contract.Unit.UnitName : <td style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey" }}></td>}</td>

                                    <td style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey" }}>{this.props?.printData.Narration}</td>
                                    <td style={{ borderLeft: "1px solid grey", borderRight: "1px solid grey" }}>{parseFloat(this.props?.printData.Amount)?.toLocaleString()}</td>


                                </tr>
                                : null} */}
            </tbody>
          </table>
        </div>
        <div className="col-12 mt-2">
          <b>Total Amount: </b>
          {this.props?.printData !== undefined
            ? parseFloat(this.props?.printData?.Amount)?.toLocaleString()
            : null}
        </div>
        <div className="col-12 mt-2">
          <b>Amount in words: </b>
          {this.props?.printData !== undefined
            ? converter
                .toWords(parseFloat(this.props?.printData?.Amount))
                ?.toUpperCase()
            : null}
        </div>
        <div className="col-12 mt-2">
          <b> Received with thanks from: </b>
          {this.props?.printData !== undefined
            ? this.props?.printData?.Contract?.CustomerCnicNavigation
                ?.CustomerName
            : null}
        </div>
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Received By:</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Authorized By:</b>
        </div>
        <div className="col-12 mt-4">
          <hr />
        </div>
      </div>
    );
  }
}
export class UnitRecModal extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div
          className="row m-0 p-0"
          style={{ border: "5px solid darkgrey", height: "100%" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Unit Receipt Transactions </u>
            </h4>
            <h5 className="text-right pt-3 ">
              <div className="row">
                <div className="col-8"></div>
                <div className="col-2 text-right">
                  <b>Print Date: </b>
                </div>

                <div className="col-2 text-center">
                  {moment().format("DD-MMM-YYYY")}
                </div>
              </div>
            </h5>

            {/* <h5 className="d-flex justify-content-between" >
                            <div className="text-left">

                                <b>Voucher No:{" "}</b>
                                {this.props?.Data !== undefined ?

                                    this.props?.Data.UnitVoucherId

                                    : null}</div>
                            <div className="text-right">
                                <b>Payment Type:{" "}</b>
                                {this.props?.Data !== undefined ?

                                    this.props?.Data.VoucherType

                                    : null}
                            </div>

                        </h5> */}

            <div className="mt-3">
              <div class="table-responsive-print col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Account</th>
                      <th>Narration</th>
                      <th>Debit</th>

                      <th>Credit</th>
                    </tr>
                  </thead>
                  {this.props?.printData !== undefined
                    ? this.props?.printData.map((x, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{x.Account.AccountTitle}</td>
                          <td>{x.Narration}</td>
                          <td>
                            {x.TransactionType === "DEBIT"
                              ? x.Amount?.toLocaleString()
                              : null}
                          </td>

                          <td>
                            {x.TransactionType === "CREDIT"
                              ? x.Amount?.toLocaleString()
                              : null}
                          </td>
                        </tr>
                      ))
                    : null}
                  <tr>
                    <td></td>
                    <td></td>

                    <td>
                      {" "}
                      <label className="input_label m-0">Total:</label>
                    </td>
                    <td>{this.props?.creditTotal?.toLocaleString()}</td>
                    <td>{this.props?.debitTotal?.toLocaleString()}</td>
                    <td></td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="col-12 text-left mt-2">
              Total Amount:{" "}
              {this.props?.total !== undefined
                ? parseFloat(this.props?.total)?.toLocaleString()
                : null}
            </div>

            <div className="col-12 text-left mt-2">
              Amount in words:{" "}
              {this.props?.total !== undefined
                ? converter.toWords(parseFloat(this.props?.total))
                : null}
            </div>
            <div className="signature">
              <h5 className=" pt-3 ">
                <div className="row">
                  <div className="col-2">
                    <b>Prepared By: </b>
                  </div>
                  <div className="col-2">
                    <>----------------</>
                  </div>
                  <div className="col-2">
                    <b>Checked By: </b>
                  </div>
                  <div className="col-2">
                    <>----------------</>
                  </div>
                  <div className="col-2">
                    <b>Approved By: </b>
                  </div>

                  <div className="col-2">
                    <>----------------</>
                  </div>
                </div>
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export class DealSoldRecPrint extends Component {
  render() {
    return (
      <div className="row m-0 p-0">
        <IndividualPrintHeader />

        <h4 className="col-12 text-center">
          <u>Deal Sold Receipt</u>
        </h4>
        <h4 className="col-12 text-center">
          {localStorage.getItem("ProjectName") === "THE GRAND CITY"
            ? "THE GRAND ICONIC CITY"
            : localStorage.getItem("ProjectName")}
        </h4>
        <div className="col-6 mt-2">
          Voucher No:{" "}
          {this.props?.printData !== undefined
            ? this.props?.printData.DealSoldReceiptVoucherId
            : null}
        </div>
        <div className="col-6 mt-2 text-right">
          Voucher Date:{" "}
          {this.props?.printData !== undefined
            ? moment(this.props?.printData.EntryDate.split("T")[0]).format(
                "DD-MMM-YYYY"
              )
            : null}
        </div>

        <div className="col-6 mt-2">
          Payment Type:{" "}
          {this.props?.printData !== undefined
            ? this.props?.printData.PaymentType
            : null}
        </div>
        {/* <div className="col-6 mt-2 text-right">
                    Print Date:{" "}
                    {moment().format("DD-MMM-YYYY")}
                </div> */}

        <div className="col-12 mt-2">Received with thanks from:</div>

        <div className="col-6 mt-2">
          Name:{" "}
          {this.props?.printData !== undefined
            ? this.props?.printData.AffiliateCnicNavigation.AffiliateName
            : null}
        </div>
        <div className="col-6 mt-2 text-right">
          CNIC:
          {this.props?.printData !== undefined
            ? this.props?.printData.AffiliateCnicNavigation.AffiliateCnic
            : null}
        </div>

        <div className="col-6 mt-2">
          Cell:{" "}
          {this.props?.printData !== undefined
            ? this.props?.printData.AffiliateCnicNavigation.AffiliatePhoneNumber
            : null}
        </div>
        <div className="col-6 mt-2 text-right"></div>

        <div className="col-12 mt-3">
          <table className="table">
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Deal
                </th>

                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Narration
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Amount
                </th>
              </tr>
            </thead>

            <tbody>
              {this.props?.printData !== undefined ? (
                <tr>
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    1
                  </td>
                  {this.props?.printData.DealSold !== undefined ? (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      {this.props?.printData.DealSold.DealName}
                    </td>
                  ) : (
                    <td></td>
                  )}

                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    {this.props?.printData.Narration !== null
                      ? this.props?.printData.Narration
                      : null}
                  </td>
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    {parseFloat(this.props?.printData.Amount)?.toLocaleString()}
                  </td>
                </tr>
              ) : null}
            </tbody>
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th style={{ borderLeft: "1px solid grey" }}>Total</th>
                <th></th>

                <th></th>

                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  {this.props?.printData !== undefined
                    ? parseFloat(this.props?.printData.Amount)?.toLocaleString()
                    : null}
                </th>
              </tr>
            </thead>
          </table>
        </div>

        <div className="col-12 mt-2">
          Total Amount:{" "}
          {this.props?.printData !== undefined
            ? parseFloat(this.props?.printData.Amount)?.toLocaleString()
            : null}
        </div>

        <div className="col-12 mt-2">
          Amount in words:{" "}
          {this.props?.printData !== undefined
            ? converter.toWords(parseFloat(this.props?.printData.Amount))
            : null}
        </div>

        {/* <div className="col-12 mt-2">
                    On account payment of shop:
                </div>                          
             */}
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Received By:</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Authorized By:</b>
        </div>

        <div className="col-12 mt-4">
          <hr />
        </div>
      </div>
    );
  }
}
export class DealSoldRecModal extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div
          className="row m-3 p-0"
          style={{ border: "5px solid darkgrey", height: "85%" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Deal Sold Receipt Transactions </u>
            </h4>
            <h5 className="text-right pt-3 ">
              <div className="row">
                <div className="col-8"></div>
                <div className="col-2 text-right">
                  <b>Print Date: </b>
                </div>

                <div className="col-2 text-center">
                  {moment().format("DD-MMM-YYYY")}
                </div>
              </div>
            </h5>
            {/* <h5 className="d-flex justify-content-between" >
                            <div className="text-left">

                                <b>Voucher No:{" "}</b>
                                {this.props?.Data !== undefined ?

                                    this.props?.Data.DealSoldReceiptVoucherId

                                    : null}</div>
                            <div className="text-right">
                                <b>Payment Type:{" "}</b>
                                {this.props?.Data !== undefined ?

                                    this.props?.Data.VoucherType

                                    : null}
                            </div>

                        </h5> */}

            <div className="mt-3">
              <div class="table-responsive-print col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Account</th>
                      <th>Narration</th>
                      <th>Debit</th>

                      <th>Credit</th>
                    </tr>
                  </thead>
                  {this.props?.printData !== undefined
                    ? this.props?.printData.map((x, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{x.Account.AccountTitle}</td>
                          <td>{x.Narration}</td>
                          <td>
                            {x.TransactionType === "DEBIT"
                              ? x.Amount?.toLocaleString()
                              : null}
                          </td>

                          <td>
                            {x.TransactionType === "CREDIT"
                              ? x.Amount?.toLocaleString()
                              : null}
                          </td>
                        </tr>
                      ))
                    : null}
                  <tr>
                    <td></td>
                    <td></td>

                    <td>
                      {" "}
                      <label className="input_label m-0">Total:</label>
                    </td>
                    <td>{this.props?.creditTotal?.toLocaleString()}</td>
                    <td>{this.props?.debitTotal?.toLocaleString()}</td>
                    <td></td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="col-12 text-left mt-2">
              Total Amount:{" "}
              {this.props?.total !== undefined
                ? parseFloat(this.props?.total)?.toLocaleString()
                : null}
            </div>

            <div className="col-12 text-left mt-2">
              Amount in words:{" "}
              {this.props?.total !== undefined
                ? converter.toWords(parseFloat(this.props?.total))
                : null}
            </div>
            <div className="signature">
              <h5 className=" pt-3 ">
                <div className="row">
                  <div className="col-2">
                    <b>Prepared By: </b>
                  </div>
                  <div className="col-2">
                    <>----------------</>
                  </div>
                  <div className="col-2">
                    <b>Checked By: </b>
                  </div>
                  <div className="col-2">
                    <>----------------</>
                  </div>
                  <div className="col-2">
                    <b>Approved By: </b>
                  </div>

                  <div className="col-2">
                    <>----------------</>
                  </div>
                </div>
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export class CommissionReceiptPrint extends Component {
  render() {
    return (
      <div className="row m-0 p-0">
       <IndividualPrintHeader/>
        <h4 className="col-12 text-center">
          <u>Commission Receipt</u>
        </h4>
        <h4 className="col-12 text-center">
          {localStorage.getItem("ProjectName") === "THE GRAND CITY"
            ? "THE GRAND ICONIC CITY"
            : localStorage.getItem("ProjectName")}
        </h4>
        <div className="col-6 mt-2">
          Voucher No:{" "}
          {this.props?.printData !== undefined
            ? this.props?.printData.CommissionReceiptVoucherId
            : null}
        </div>
        <div className="col-6 mt-2 text-right">
          Voucher Date:{" "}
          {this.props?.printData !== undefined
            ? moment(this.props?.printData.EntryDate.split("T")[0]).format(
                "DD-MMM-YYYY"
              )
            : null}
        </div>

        <div className="col-6 mt-2">
          Payment Type:{" "}
          {this.props?.printData !== undefined
            ? this.props?.printData.PaymentType
            : null}
        </div>
        {/* <div className="col-6 mt-2 text-right">
                    Print Date:{" "}
                    {moment().format("DD-MMM-YYYY")}
                </div> */}

        {/* <div className="col-12 mt-2">
                    Received with thanks from:
                </div>
            
                <div className="col-6 mt-2">
                    Name: {this.props?.printData !== undefined ?

this.props?.printData.AffiliateCnicNavigation.AffiliateName

                                : null}
                </div>
                <div className="col-6 mt-2 text-right">
                    CNIC:
                    {this.props?.printData !== undefined ?

                        this.props?.printData.Contract.AffiliateCnic

                                : null}
                </div>
                
                <div className="col-6 mt-2">
                    Cell: {this.props?.printData !== undefined ?

                        this.props?.printData.Contract.AffiliateCnicNavigation.PhoneNumber

                                : null}
                </div>
                 */}
        <div className="col-6 mt-2 text-right"></div>

        <div className="col-12 mt-3">
          <table className="table">
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Affiliate
                </th>
                {/* <th style={{borderLeft:"1px solid grey", borderRight:"1px solid grey"}}>Project</th> */}
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Unit
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Amount
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Narration
                </th>
              </tr>
            </thead>

            {this.props?.printData !== undefined ? (
              <tbody>
                <tr>
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    1
                  </td>
                  {this.props?.printData.Seller !== undefined ? (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      {this.props?.printData.Seller.SellerName}
                    </td>
                  ) : (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    ></td>
                  )}
                  {/* {this.props?.printData.Project !== undefined && this.props?.printData.Project !== null ?
                                    <td style={{borderLeft:"1px solid grey", borderRight:"1px solid grey"}}>{ this.props?.printData.Project.ProjectName === "THE GRAND CITY" ? "THE GRAND ICONIC CITY" : this.props?.printData.Project.ProjectName}</td> : <td style={{borderLeft:"1px solid grey", borderRight:"1px solid grey"}}></td>} */}
                  {this.props?.printData.Unit !== undefined ? (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      {this.props?.printData.Unit.UnitName}
                    </td>
                  ) : (
                    <td
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    ></td>
                  )}
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    {parseFloat(this.props?.printData.Amount)?.toLocaleString()}
                  </td>
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    {this.props?.printData.Narration !== null
                      ? this.props?.printData.Narration
                      : null}
                  </td>
                </tr>
              </tbody>
            ) : null}
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th style={{ borderLeft: "1px solid grey" }}>Total</th>
                <th></th>

                <th></th>

                <th></th>

                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  {this.props?.printData !== undefined
                    ? parseFloat(this.props?.printData.Amount)?.toLocaleString()
                    : null}
                </th>
              </tr>
            </thead>
          </table>
        </div>

        <div className="col-12 mt-2">
          Total Amount:{" "}
          {this.props?.printData !== undefined
            ? parseFloat(this.props?.printData.Amount)?.toLocaleString()
            : null}
        </div>

        <div className="col-12 mt-2">
          Amount in words:{" "}
          {this.props?.printData !== undefined
            ? converter.toWords(parseFloat(this.props?.printData.Amount))
            : null}
        </div>

        {/* <div className="col-12 mt-2">
                    On account payment of shop:
                </div>                          
             */}
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Received By:</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Authorized By:</b>
        </div>

        <div className="col-12 mt-4">
          <hr />
        </div>
      </div>
    );
  }
}
export class CommissionRecModal extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div
          className="row m-0 p-0"
          style={{ border: "5px solid darkgrey", height: "85%" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Deal Sold Receipt Transactions </u>
            </h4>
            <h5 className="text-right pt-3 ">
              <div className="row">
                <div className="col-8"></div>
                <div className="col-2 text-right">
                  <b>Print Date: </b>
                </div>

                <div className="col-2 text-center">
                  {moment().format("DD-MMM-YYYY")}
                </div>
              </div>
            </h5>
            {/* 
                        <h5 className="d-flex justify-content-between" >
                            <div className="text-left">

                                <b>Voucher No:{" "}</b>
                                {this.props?.Data !== undefined ?

                                    this.props?.Data.CommissionReceiptVoucherId

                                    : null}</div>
                            <div className="text-right">
                                <b>Payment Type:{" "}</b>
                                {this.props?.Data !== undefined ?

                                    this.props?.Data.VoucherType

                                    : null}
                            </div>

                        </h5> */}

            <div className="mt-3">
              <div class="table-responsive-print col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Account</th>
                      <th>Narration</th>
                      <th>Debit</th>

                      <th>Credit</th>
                    </tr>
                  </thead>
                  {this.props?.printData !== undefined
                    ? this.props?.printData.map((x, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{x.Account.AccountTitle}</td>
                          <td>{x.Narration}</td>
                          <td>
                            {x.TransactionType === "DEBIT"
                              ? x.Amount?.toLocaleString()
                              : null}
                          </td>

                          <td>
                            {x.TransactionType === "CREDIT"
                              ? x.Amount?.toLocaleString()
                              : null}
                          </td>
                        </tr>
                      ))
                    : null}
                  <tr>
                    <td></td>
                    <td></td>

                    <td>
                      {" "}
                      <label className="input_label m-0">Total:</label>
                    </td>
                    <td>{this.props?.creditTotal?.toLocaleString()}</td>
                    <td>{this.props?.debitTotal?.toLocaleString()}</td>
                    <td></td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="col-12 text-left mt-2">
              Total Amount:{" "}
              {this.props?.total !== undefined
                ? parseFloat(this.props?.total)?.toLocaleString()
                : null}
            </div>

            <div className="col-12 text-left mt-2">
              Amount in words:{" "}
              {this.props?.total !== undefined
                ? converter.toWords(parseFloat(this.props?.total))
                : null}
            </div>
            <div className="signature">
              <h5 className=" pt-3 ">
                <div className="row">
                  <div className="col-2">
                    <b>Prepared By: </b>
                  </div>
                  <div className="col-2">
                    <>----------------</>
                  </div>
                  <div className="col-2">
                    <b>Checked By: </b>
                  </div>
                  <div className="col-2">
                    <>----------------</>
                  </div>
                  <div className="col-2">
                    <b>Approved By: </b>
                  </div>

                  <div className="col-2">
                    <>----------------</>
                  </div>
                </div>
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export class Demoprint extends Component {
  render() {
    return (
      <div className="row m-0 p-0">
        <div className="watermarked">
          <img
            src={gravity}
            style={{ height: "600px", width: "600px" }}
            alt="Company logo"
          />
        </div>

        <div className="col-4 text-center">
          <img
            src={gravity}
            style={{ height: "175px", width: "175px", borderRadius: "100px" }}
            className="pt-2"
            alt="Company logo"
          />
        </div>
        <div className="col-3"></div>
        <div className="col-5 text-center mt-4">
          <h2 style={{ color: "#1b7a7f" }}>Gravity Associates Pvt Limited</h2>
          <hr />
          <h6 style={{ color: "grey", marginTop: "5px" }}>
            <i>204 R/B Road, East Canal Road, Faisalabad.</i>
          </h6>
        </div>

        <h4 className="col-12 text-center">
          <u>Recovery Booking</u>
        </h4>
        <h4 className="col-12 text-center">
          {localStorage.getItem("ProjectName") === "THE GRAND CITY"
            ? "THE GRAND ICONIC CITY"
            : localStorage.getItem("ProjectName")}
        </h4>
        <div className="col-6 mt-2">
          Voucher No:{" "}
          {this.props?.printData !== undefined
            ? this.props?.printData.RecoveryBookingVoucherId
            : null}
        </div>
        <div className="col-6 mt-2 text-right">
          Voucher Date:{" "}
          {this.props?.printData !== undefined
            ? moment(this.props?.printData.EntryDate.split("T")[0]).format(
                "DD-MMM-YYYY"
              )
            : null}
        </div>

        <div className="col-6 mt-2">
          Payment Type:{" "}
          {this.props?.printData !== undefined
            ? this.props?.printData.VoucherType
            : null}
        </div>
        <div className="col-6 mt-2 text-right">
          Print Date: {moment().format("DD-MMM-YYYY")}
        </div>

        {/* <div className="col-12 mt-2">
                    Received with thanks from:
                </div>
            
                <div className="col-6 mt-2">
                    Name: {this.props?.printData !== undefined ?

this.props?.printData.AffiliateCnicNavigation.AffiliateName

                                : null}
                </div>
                <div className="col-6 mt-2 text-right">
                    CNIC:
                    {this.props?.printData !== undefined ?

                        this.props?.printData.Contract.AffiliateCnic

                                : null}
                </div>
                
                <div className="col-6 mt-2">
                    Cell: {this.props?.printData !== undefined ?

                        this.props?.printData.Contract.AffiliateCnicNavigation.PhoneNumber

                                : null}
                </div>
                 */}
        <div className="col-6 mt-2 text-right"></div>

        <div className="col-12 mt-3">
          <table className="table">
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Manager
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Project{" "}
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Project Owner
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Narration
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Amount
                </th>
              </tr>
            </thead>

            {this.props?.printData !== undefined ? (
              <tbody>
                <tr>
                  <td
                    style={{
                      borderLeft: "1px solid grey",
                      borderRight: "1px solid grey",
                    }}
                  >
                    1
                  </td>
                  {this.props?.printData.Manager !== undefined &&
                  this.props?.printData.Manager.Employee !== undefined ? (
                    <td>{this.props?.printData.Manager.Employee.EmpName}</td>
                  ) : (
                    <td></td>
                  )}
                  {this.props?.printData.Project !== undefined &&
                  this.props?.printData.Project !== null ? (
                    <td>
                      {this.props?.printData.Project.ProjectName ===
                      "THE GRAND CITY"
                        ? "THE GRAND ICONIC CITY"
                        : this.props?.printData.Project.ProjectName}
                    </td>
                  ) : (
                    <td></td>
                  )}
                  <td>{this.props?.printData.Project.ProjectOwner}</td>
                  <td>{this.props?.printData.Narration}</td>

                  <td>
                    {parseFloat(this.props?.printData.Amount)?.toLocaleString()}
                  </td>
                </tr>
              </tbody>
            ) : null}
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th style={{ borderLeft: "1px solid grey" }}>Total</th>
                <th></th>

                <th></th>
                <th></th>
                <th></th>

                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  {this.props?.printData !== undefined
                    ? parseFloat(this.props?.printData.Amount)?.toLocaleString()
                    : null}
                </th>
              </tr>
            </thead>
          </table>
        </div>

        <div className="col-12 mt-2">
          Total Amount:{" "}
          {this.props?.printData !== undefined
            ? parseFloat(this.props?.printData.Amount)?.toLocaleString()
            : null}
        </div>

        <div className="col-12 mt-2">
          ReceivedAmount in words:{" "}
          {this.props?.printData !== undefined
            ? converter.toWords(parseFloat(this.props?.printData.Amount))
            : null}
        </div>

        {/* <div className="col-12 mt-2">
                    On account payment of shop:
                </div>                          
             */}
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Received By:</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Authorized By:</b>
        </div>

        <div className="col-12 mt-4">
          <hr />
        </div>
      </div>
    );
  }
}

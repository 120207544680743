import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getallAvailable,
  getallLeads,
} from "../../actions/Crmactions/availabiltyaction";
import { getallclients } from "../../actions/getclients";
import moment from "moment";
import { getallEmployees } from "../../actions/HRactions/getemployees";
import { getaffiliatepopulatetable } from "../../actions/inventoryactions/getaffiliates";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { BsCloudUpload } from "react-icons/bs";
import { TiPrinter } from "react-icons/ti";
import { useReactToPrint } from "react-to-print";
import AvailabilityPrint from "./Availabiltyprint";
import { icons } from "react-icons/lib";
import axios from "axios";
const AvailabilityComp = (props) => {
  const resultsallunits = useSelector((state) => state.getCRMAvailability);
  const resultsallleads = useSelector((state) => state.getallLeadsred);
  const resultallclients = useSelector((state) => state.getAllClients);
  const resultallemployees = useSelector((state) => state.getAllEmployees);
  const [StateSellerId, SetSellerId] = useState(0);
  const [StateAffiliateCnic, SetAffiliateCnic] = useState();

  const [availabilitymodaldata, setavailabilitymodaldata] = useState({});
  const resultallaffiliates = useSelector((state) => state.getAffiliatesRed);

  const [filerunits, setfilterunits] = useState();
  const [showhide, setshowhide] = useState(true);



  const savereserved = () => {
    let regexp = new RegExp("^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$");
    let check = document.getElementById("CNIC").value;
    // if (document.getElementById("inittiate").value === "") {
    //     toast.info("Select Lead Identifier");

    // }
    if (
      document.getElementById("CNIC").value === "" &&
      document.getElementById("check").checked === true
    ) {
      toast.info("CNIC is Required");
    } else if (
      parseFloat(
        document.getElementById("tokenamount").value.replace(/,/g, "")
      ) === "" &&
      document.getElementById("check").checked === true
    ) {
      toast.info("Token Amount is Required");
    } else if (
      document.getElementById("inittiate").value === "" &&
      document.getElementById("check").checked === true
    ) {
      toast.info("Client Name is Required");
    } else if (
      document.getElementById("reservedby").value === "" &&
      document.getElementById("check").checked !== true
    ) {
      toast.info("Reserved By is Required");
    } else if (
      !regexp.test(check) &&
      document.getElementById("CNIC").value !== ""
    ) {
      toast.info("Invalid CNIC. Format should be like xxxxx-xxxxxxx-x");
    } else if (document.getElementById("uptodate").value === "") {
      toast.info("Date is Required");
    } else {
      let Lead = {
        UnitId: parseInt(
          stateunitdetail !== undefined ? stateunitdetail.UnitId : null
        ),
        // LeadId: parseInt(statemanagerid),
        UpToDate: document.getElementById("uptodate").value,
        TokenReceived:
          document.getElementById("check").checked === true ? true : false,
        TokenAmount: document.getElementById("tokenamount").value,
        ManagerClientId: parseInt(statemanagerid),
        ClientCnic: document.getElementById("CNIC").value,
        ReservedByEmployeeId:
          individualcheck !== true ? parseInt(StateSellerId) : null,
        ReservedByAffiliateId:
          individualcheck === true ? StateAffiliateCnic : null,
      };

      document.getElementById("leadsave").disabled = true;
      axios({
        method: "post",
        url: `${window.$url}Leads/ReserveUnit`,
        data: JSON.stringify(Lead),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("leadsave").disabled = false;
          if (result.data.IsSuccess === true) {
            document.getElementById("uptodate").value = "";
            document.getElementById("inittiate").value = "";
            document.getElementById("tokenamount").value = "";
            document.getElementById("CNIC").value = "";
            document.getElementById("check").checked = false;
            document.getElementById("reservedby").value = "";

            SetSellerId(0);
            setshowhide(true);

            // setunitdetail();
            // setmanagerid();
            document.getElementById("modalofleadedit").style.display = "none";

            setunitdetail();
            setmanagerid();
            SetAffiliateCnic();
            dispatch(getallAvailable());

            toast.info(result.data.Message);
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Someting went wrong");
          document.getElementById("leadsave").disabled = false;
        }
      );
    }
  };
  const [statemanagerid, setmanagerid] = useState();
  const [stateunitdetail, setunitdetail] = useState();
  const [individualcheck, setindividualcheck] = useState(true);

  const saveUnreserved = (unitid) => {
    document.getElementById("leadunreservedsave").disabled = true;
    axios({
      method: "post",
      url: `${window.$url}Leads/UnreserveUnit?UnitId=${unitid}`,

      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        document.getElementById("leadunreservedsave").disabled = false;
        if (result.data.IsSuccess === true) {
          toast.info(result.data.Message);
          dispatch(getallAvailable());
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Someting went wrong");
        document.getElementById("leadunreservedsave").disabled = false;
      }
    );
  };
  const dispatch = useDispatch();
  // print function
  const componentRef = useRef();

  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  //Table Data fiter
  var suggestionlist1 = [];

  const ManagerSelecttion = (e) => {
    const value = e.target.value;
    if (value == "") {
      ReactDOM.render(null, document.getElementById("unitdemo"));
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = resultallclients.data.filter((v) =>
          regex.test(v.ManagerClientName !== null ? v.ManagerClientName : null)
        );
      }
      suggestionlist1 = suggestions;

      if (suggestionlist1.length === 0) {
        ReactDOM.render(null, document.getElementById("unitdemo"));
      } else {
        ReactDOM.render(
          <ul className="suggestbox">
            {suggestionlist1.map((item) => (
              <li className="listcss" onClick={() => suggestionselected1(item)}>
                {item === undefined ? item : item.ManagerClientName}
              </li>
            ))}
          </ul>,
          document.getElementById("unitdemo")
        );
      }
    }
  };
  const onTextChangeClient = () => {
    var inputValue = document.getElementById("inittiate").value;
        var options = document.getElementById("clientlist").childNodes;
    
        let val;
        for (var i = 0; i < options.length; i++) {
          if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
            resultallclients.data.forEach((x, idx) => {
              if (idx == options[i].attributes.index.value) {
                val = x;
              }
            });
            
            document.getElementById("CNIC").value = val.ManagerClientCnic;
    setmanagerid(val.ManagerClientId);
    
            break;
          }
        }
  }
  const suggestionselected1 = (val) => {

    document.getElementById("inittiate").value = val.ManagerClientName;
    document.getElementById("CNIC").value = val.ManagerClientCnic;
    setmanagerid(val.ManagerClientId);

    // setUnitId(val.UnitId);

    suggestionlist1 = [];
    ReactDOM.render(null, document.getElementById("unitdemo"));
  };

  const LeadSelecttion = (e) => {
    const value = e.target.value;
    if (value == "") {
      ReactDOM.render(null, document.getElementById("unitdemo"));
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = resultsallleads.data.Leads.filter((v) =>
          regex.test(v.LeadIdentifier !== null ? v.LeadIdentifier : null)
        );
      }
      suggestionlist1 = suggestions;

      if (suggestionlist1.length === 0) {
        ReactDOM.render(null, document.getElementById("unitdemo"));
      } else {
        ReactDOM.render(
          <ul className="suggestbox">
            {suggestionlist1.map((item) => (
              <li className="listcss" onClick={() => suggestionselected1(item)}>
                {item === undefined ? item : item.LeadIdentifier}
              </li>
            ))}
          </ul>,
          document.getElementById("unitdemo")
        );
      }
    }
  };

  const onTextChange = (e) => {
    if (
      document.getElementById("projectfilter").value === "" &&
      document.getElementById("unitfilter").value === "" &&
      document.getElementById("sectionfilter").value === "" &&
      document.getElementById("statusfilter").value === "" &&
      document.getElementById("reservedfilter").value === ""
    ) {
      setfilterunits(resultsallunits.data.Units);
    } else {
      let suggestions = [];
      {
        const regex = new RegExp(
          document.getElementById("projectfilter").value,
          "i"
        );
        const regex1 = new RegExp(
          document.getElementById("sectionfilter").value,
          "i"
        );
        const regex2 = new RegExp(
          document.getElementById("unitfilter").value,
          "i"
        );
        const regex3 = new RegExp(
          document.getElementById("statusfilter").value,
          "i"
        );
        const regex4 = new RegExp(
          document.getElementById("reservedfilter").value,
          "i"
        );

        suggestions = resultsallunits.data.Units.sort().filter(
          (val) =>
            (document.getElementById("projectfilter").value == "" ||
              regex.test(val.Project.ProjectName)) &&
            (document.getElementById("sectionfilter").value == "" ||
              regex1.test(val.Section.SectionName)) &&
            (document.getElementById("unitfilter").value == "" ||
              regex2.test(val.UnitName)) &&
            (document.getElementById("statusfilter").value == "" ||
              regex3.test(val.Status)) &&
            (document.getElementById("reservedfilter").value == "" ||
              regex4.test(
                val.ReservedUnits[0] !== undefined
                  ? val.ReservedUnits[0].ReservedByName
                  : null
              ))
        );
      }
      setfilterunits(suggestions);
    }
  };

  useEffect(() => {
    dispatch(getallAvailable());
    dispatch(getallLeads());
    dispatch(getallclients());
    dispatch(getallEmployees());
    dispatch(getaffiliatepopulatetable());
  }, [dispatch]);

  useEffect(() => {
    setfilterunits(resultsallunits.data.Units);
  }, [resultsallunits]);
  const openmodalleadsedit = (x) => {
    setunitdetail(x);

    document.getElementById("modalofleadedit").style.display = "block";
  };
  function showmodal(data) {
    document.getElementById("mymodal").style.display = "block";
    setavailabilitymodaldata(data);
  }
  function closemodal() {
    document.getElementById("mymodal").style.display = "none";
  }

  var assetsuggestionlist = [];

  const SellerSelecttion = (e) => {
    const value = e.target.value;
    if (value == "") {
      ReactDOM.render(null, document.getElementById("teamdemo"));
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = resultallemployees.data
          .sort()
          .filter((v) => regex.test(v.EmpName !== null ? v.EmpName : null));
      }
      assetsuggestionlist = suggestions;

      if (assetsuggestionlist.length === 0) {
        ReactDOM.render(null, document.getElementById("teamdemo"));
      } else {
        ReactDOM.render(
          <ul className="suggestbox">
            {assetsuggestionlist.map((item) => (
              <li
                className="listcss"
                onClick={() => sellersuggestionselected(item)}
              >
                {item !== undefined &&
                item.EmpDesignation.SalesTeamHierarchyDesignation.length > 0
                  ? item.EmpName
                  : item}
              </li>
            ))}
          </ul>,
          document.getElementById("teamdemo")
        );
      }
    }
  };
  const onTextChangeSeller = () => {
    var inputValue = document.getElementById("reservedby").value;
        var options = document.getElementById("sellerlist").childNodes;
    
        let val;
        for (var i = 0; i < options.length; i++) {
          if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
            resultallemployees.data.forEach((x, idx) => {
              if (idx == options[i].attributes.index.value) {
                val = x;
              }
            });
            
            SetSellerId(val.EmployeeId);
    
            break;
          }
        }
  }
  const sellersuggestionselected = (val) => {

    document.getElementById("reservedby").value = val.EmpName;

    SetSellerId(val.EmployeeId);

    assetsuggestionlist = [];
    ReactDOM.render(null, document.getElementById("teamdemo"));
  };
  var affiliatesuggestionlist = [];

  const AffiliateSelecttion = (e) => {
    const value = e.target.value;
    if (value == "") {
      ReactDOM.render(null, document.getElementById("affdemo"));
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = resultallaffiliates.data
          .sort()
          .filter((v) =>
            regex.test(v.AffiliateName !== null ? v.AffiliateName : null)
          );
      }
      affiliatesuggestionlist = suggestions;

      if (affiliatesuggestionlist.length === 0) {
        ReactDOM.render(null, document.getElementById("affdemo"));
      } else {
        ReactDOM.render(
          <ul className="suggestbox">
            {affiliatesuggestionlist.map((item) => (
              <li
                className="listcss"
                onClick={() => affiliatesuggestionselected(item)}
              >
                {item !== undefined ? item.AffiliateName : item}
              </li>
            ))}
          </ul>,
          document.getElementById("affdemo")
        );
      }
    }
  };
  const onTextChangeAffiliate = () => {
    var inputValue = document.getElementById("reservedby").value;
        var options = document.getElementById("affiliatelist").childNodes;
    
        let val;
        for (var i = 0; i < options.length; i++) {
          if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
            resultallaffiliates.data.forEach((x, idx) => {
              if (idx == options[i].attributes.index.value) {
                val = x;
              }
            });
            
    SetAffiliateCnic(val.AffiliateCnic);
    
            break;
          }
        }
  }
  const affiliatesuggestionselected = (val) => {

    document.getElementById("reservedby").value = val.AffiliateName;

    SetAffiliateCnic(val.AffiliateCnic);

    affiliatesuggestionlist = [];
    ReactDOM.render(null, document.getElementById("affdemo"));
  };
  return (
    <>
      <div hidden>
        <AvailabilityPrint ref={componentRef} printData={resultsallunits} />
      </div>
      <div className="row  app_font">
        <div className="dash_background col-12 pt-2">
          <div className="row pt-3 ">
            <div className="col-md-1 pr-0 pl-0">
              <label className="input_label m-0">Search:</label>
            </div>
            <div className="col-md-2 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="projectfilter"
                onChange={onTextChange}
                placeholder="Project "
              ></input>
            </div>
            <div className="col-md-2 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="sectionfilter"
                onChange={onTextChange}
                placeholder="Section"
              ></input>
            </div>
            <div className="col-md-2 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="unitfilter"
                onChange={onTextChange}
                placeholder="Unit "
              ></input>
            </div>
            <div className="col-md-2 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="statusfilter"
                onChange={onTextChange}
                placeholder="Status "
              ></input>
            </div>
            <div className="col-md-2 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="reservedfilter"
                onChange={onTextChange}
                placeholder="Reserved By "
              ></input>
            </div>
            <div className="col-md-1 pr-0 pl-0">
              {resultsallunits.loading ? (
                <Loader type="ThreeDots" color="green" height={40} width={40} />
              ) : null}
            </div>
          </div>

          <div className="row ">
            <div className=" col-12  table_height pr-0 pl-0">
              <div className="table-responsive mt-4">
                <table className="table table-borderless m-0">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Project</th>
                      <th>Section </th>
                      <th>Unit</th>
                      <th>Area</th>
                      <th>Dimension</th>
                      <th>Sqft</th>
                      <th>S.Rate</th>

                      <th>Total Value</th>
                      <th>Down Payment</th>
                      <th>Install</th>
                      <th>Reserved By</th>
                      <th>Status</th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filerunits !== undefined
                      ? filerunits.map((val, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            {val.Project !== null &&
                            val.Project !== undefined ? (
                              <td
                                onClick={() => {
                                  showmodal(val);
                                }}
                              >
                                {val.Project.ProjectName}
                              </td>
                            ) : (
                              <td></td>
                            )}
                            {val.Section !== null &&
                            val.Section !== undefined ? (
                              <td
                                onClick={() => {
                                  showmodal(val);
                                }}
                              >
                                {val.Section.SectionName}
                              </td>
                            ) : (
                              <td></td>
                            )}
                            <td
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val.UnitName}
                            </td>

                            {/* <td>{val.NetMarla}</td> */}
                            <td
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val.Marla + "M" + val.Sarsai + "S"}
                            </td>
                            <td
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val.DimensionLength + "x" + val.DimensionWidth}
                            </td>
                            <td
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val.NetSqft}
                            </td>

                            <td
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {parseFloat(val.RatePerMarla).toLocaleString()}
                            </td>

                            <td
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {parseFloat(val.Price).toLocaleString()}
                            </td>

                            <td
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {parseFloat(val.AdvanceAmount).toLocaleString()}
                            </td>

                            <td
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {" "}
                              {parseFloat(
                                Math.ceil(val.ExpectedInstallment)
                              ).toLocaleString() +
                                " in " +
                                val.Months +
                                " M"}
                            </td>
                            <td
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              {val.ReservedUnits.length > 0
                                ? val.ReservedUnits[0].ReservedByName
                                : null}
                            </td>

                            <td>{val.Status}</td>
                            <td>
                              {val.Status === "AVAILABLE" ? (
                                <button
                                  className="print_btn"
                                  onClick={() => {
                                    openmodalleadsedit(val);
                                  }}
                                >
                                  Reserve
                                </button>
                              ) : (
                                <button
                                  className="print_btn"
                                  id="leadunreservedsave"
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Are you sure you wish to Unreserve this Unit?"
                                      )
                                    )
                                      saveUnreserved(val.UnitId);
                                  }}
                                >
                                  UnReserve
                                </button>
                              )}
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-6  text-right pr-0 pl-0">
              <button
                className="print_btn float-left"
                onClick={() => {
                  printPaymentRec();
                }}
              >
                <TiPrinter size="1.3em" />
                Print
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="modalofleadedit" class="modalscreen ">
        <div class="modal-content-screen1 pt-1">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center ">Reserve</h4>
              <span
                class="close pr-2  "
                onClick={() => {
                  document.getElementById("modalofleadedit").style.display =
                    "none";

                  document.getElementById("uptodate").value = "";
                  document.getElementById("inittiate").value = "";
                  // document.getElementById("client").value = "";
                  setunitdetail();
                  setmanagerid();
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="container">
            <div className="row ">
              <div className="col-md-4 mt-2 text-right">
                <span className="txt input_label"> Affliate</span>
              </div>
              <div className="col-md-1 mt-2 text-left">
                <span>
                  {" "}
                  <input
                    type="radio"
                    id="affiliatetype"
                    name="affiliateType"
                    checked={individualcheck}
                    onClick={() => {
                      // document.getElementById("showhide").style.display = "none";
                      // document.getElementById("showhide2").style.display = "block";
                      // document.getElementById("cityshowhide").style.display = "block";
                      setindividualcheck(true);
                    }}
                  ></input>
                </span>
              </div>
              <div className="col-md-4 mt-2 text-right">
                <span className="txt input_label"> Employee</span>
              </div>
              <div className="col-md-1 mt-2 text-left">
                <span>
                  {" "}
                  <input
                    type="radio"
                    name="employeeType"
                    id="employeeproject"
                    checked={!individualcheck}
                    onClick={() => {
                      setindividualcheck(false);
                    }}
                  ></input>
                </span>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0 ">Unit Name:</label>
              </div>
              <div className="col-md-3 mt-md-4  p-0">
                <input
                  type="text"
                  className="input_styling  ml-md-3"
                  defaultValue={
                    stateunitdetail !== undefined
                      ? stateunitdetail.UnitName
                      : null
                  }
                ></input>
              </div>
              <div className="col-md-2 mt-md-4   p-0"></div>
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0 ">Up To Date:</label>
              </div>
              <div className="col-md-3 mt-md-4  p-0">
                <input
                  type="date"
                  className="input_date_styling  ml-md-3"
                  id="uptodate"
                  defaultValue={moment(new Date() + 7).format("YYYY-MM-DD")}
                ></input>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0 ">Reserved By:</label>
              </div>
              <div className="col-md-3 mt-md-4  p-0">
                {individualcheck === true ? (
                  <>
                    <input
                      type="text"
                      className="input_styling  ml-md-3"
                      id="reservedby"
                      onChange={onTextChangeAffiliate}
                      placeholder="Suggestion aff List"
                      list="affiliatelist"
                    ></input>
<datalist id="affiliatelist">
            {resultallaffiliates?.data?.map((x, idx) => (
              <option index={idx} value={x.AffiliateName}>
                {x.AffiliateCnic}
              </option>
            ))}
          </datalist>
                    <span id="affdemo"></span>
                  </>
                ) : (
                  <>
                    {" "}
                    <input
                      type="text"
                      className="input_styling  ml-md-3"
                      id="reservedby"
                      onChange={onTextChangeSeller}
                      placeholder="Suggestion emp List"
                      list="sellerlist"
                    ></input>
                    <datalist id="sellerlist">
            {resultallemployees?.data?.map((x, idx) => (
              <option index={idx} value={x.EmpName}>
                {x.EmployeeId}
              </option>
            ))}
          </datalist>
                    <span id="teamdemo"></span>
                  </>
                )}
              </div>
            </div>
            <div className="row ">
              <div className="col-md-2 mt-md-4  text-left p-0">
                <span>
                  <input
                    type="checkbox"
                    id="check"
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setshowhide(false);
                      } else {
                        setshowhide(true);
                      }
                    }}
                  ></input>
                  <label className="input_label m-0 "> Token Received:</label>
                </span>
              </div>
              <div className="col-md-3 mt-md-4  p-0"></div>
            </div>
            <div className="row " hidden={showhide}>
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0 ">Token Amount:</label>
              </div>
              <div className="col-md-3 mt-md-4  p-0">
                <input
                  type="text"
                  className="input_styling  ml-md-3"
                  id="tokenamount"
                  onChange={(e) => {
                    if (e.target.value.length > 0)
                      document.getElementById("tokenamount").value = parseInt(
                        e.target.value.replace(/,/g, "")
                      ).toLocaleString();
                  }}
                ></input>
                {/* <span id="unitdemo"></span> */}
              </div>
              <div className="col-md-2 mt-md-4   p-0"></div>
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0 ">Client Name:</label>
              </div>
              <div className="col-md-3 mt-md-4  p-0">
                <input
                  type="text"
                  className="input_styling  ml-md-3"
                  id="inittiate"
                  onChange={onTextChangeClient}
                  list="clientlist"
                ></input>
                <datalist id="clientlist">
            {resultallclients?.data?.map((x, idx) => (
              <option index={idx} value={x.ManagerClientName}>
                {x.ManagerClientId}
              </option>
            ))}
          </datalist>
                <span id="unitdemo"></span>
              </div>

              {/* 
                            <div className="col-md-2 mt-md-4  text-left p-0">
                                <label className="input_label m-0 " >Lead:</label>
                            </div>
                            <div className="col-md-3 mt-md-4  p-0">
                                <input type="text" className="input_styling  ml-md-3" id="inittiate" onChange={LeadSelecttion} ></input>
                                <span id="unitdemo"></span>



                            </div> */}

              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0 ">Client CNIC:</label>
              </div>
              <div className="col-md-3 mt-md-4  p-0">
                <input
                  type="text"
                  className="input_styling  ml-md-3"
                  placeholder="xxxxx-xxxxxxx-x"
                  id="CNIC"
                ></input>
              </div>
              <div className="col-md-2 mt-md-4   p-0"></div>
            </div>

            <div className="row ">
              <div className=" col-12 text-right mt-md-4 ">
                <button
                  className="save_btn ml-2  "
                  id="leadsave"
                  onClick={savereserved}
                >
                  <BsCloudUpload size="1.3em" />
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="mymodal" class="modal">
        <div class="modal-content-cat">
          <div className="row">
            <div className="col-12  page_heading">
              <h4 className="  text-center pt-2">Units Detail</h4>
              <span class="close pr-2  " onClick={closemodal}>
                &times;
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-6 mt-2">
              <ul>
                <li>
                  <b>Project:</b>{" "}
                  {availabilitymodaldata.Project !== undefined
                    ? availabilitymodaldata.Project.ProjectName
                    : null}
                </li>
                <li>
                  <b>Section:</b>{" "}
                  {availabilitymodaldata.Section !== undefined
                    ? availabilitymodaldata.Section.SectionName
                    : null}
                </li>
                <li>
                  <b>Unit:</b> {availabilitymodaldata.UnitName}
                </li>

                <li>
                  <b>Marla:</b> {availabilitymodaldata.Marla}
                </li>
                <li>
                  <b>Sarsai:</b> {availabilitymodaldata.Sarsai}
                </li>
                <li>
                  <b>LxW:</b>{" "}
                  {availabilitymodaldata.DimensionLength +
                    "x" +
                    availabilitymodaldata.DimensionWidth}
                </li>
                <li>
                  <b>Measurment Unit:</b>{" "}
                  {availabilitymodaldata.RatePerMarlaOrSqft}
                </li>
                <li>
                  <b>Client:</b>{" "}
                  {availabilitymodaldata.ReservedUnits !== undefined &&
                  availabilitymodaldata.ReservedUnits.length > 0
                    ? availabilitymodaldata.ReservedUnits[0].ManagerClient
                        .ManagerClientName
                    : null}
                </li>
                <li>
                  <b>Client Cnic/Id:</b>{" "}
                  {availabilitymodaldata.ReservedUnits !== undefined &&
                  availabilitymodaldata.ReservedUnits.length > 0
                    ? availabilitymodaldata.ReservedUnits[0].ManagerClient
                        .ManagerClientCnic
                    : null}
                </li>

                <li>
                  <b>Entry Date</b>{" "}
                  {availabilitymodaldata.ReservedUnits !== undefined &&
                  availabilitymodaldata.ReservedUnits.length > 0 &&
                  availabilitymodaldata.ReservedUnits[0].EntryDate
                    ?moment(availabilitymodaldata.ReservedUnits[0].EntryDate.split(
                        "T"
                      )[0]).format("DD-MMM-YYYY")
                    : null}
                </li>
              </ul>
            </div>
            <div className="col-6 mt-2">
              <ul>
                <li>
                  <b>Net Marla:</b>{" "}
                  {parseFloat(availabilitymodaldata.NetMarla).toLocaleString()}
                </li>
                <li>
                  <b>Net Sqft:</b>{" "}
                  {parseFloat(availabilitymodaldata.NetSqft).toLocaleString()}
                </li>
                <li>
                  <b>Sale Rate:</b>{" "}
                  {parseFloat(
                    availabilitymodaldata.RatePerMarla
                  ).toLocaleString()}
                </li>
                <li>
                  <b>Total Value:</b>{" "}
                  {parseFloat(availabilitymodaldata.Price).toLocaleString()}
                </li>
                <li>
                  <b>Cost Rate:</b>{" "}
                  {parseFloat(
                    availabilitymodaldata.CostPerMarla
                  ).toLocaleString()}
                </li>
                <li>
                  <b>Total Cost:</b>{" "}
                  {parseFloat(availabilitymodaldata.TotalCost).toLocaleString()}
                </li>
                <li>
                  <b>Token Amount:</b>{" "}
                  {availabilitymodaldata.ReservedUnits !== undefined &&
                  availabilitymodaldata.ReservedUnits.length > 0
                    ? availabilitymodaldata.ReservedUnits[0].TokenAmount !==
                      null
                      ? parseFloat(
                          availabilitymodaldata.ReservedUnits[0].TokenAmount
                        ).toLocaleString()
                      : null
                    : null}
                </li>
                <li>
                  <b>Reserved By:</b>{" "}
                  {availabilitymodaldata.ReservedUnits !== undefined &&
                  availabilitymodaldata.ReservedUnits.length > 0
                    ? availabilitymodaldata.ReservedUnits[0].ReservedByName
                    : null}
                </li>
                <li>
                  <b>Reserved By Cnic/Id:</b>{" "}
                  {availabilitymodaldata.ReservedUnits !== undefined &&
                  availabilitymodaldata.ReservedUnits.length > 0
                    ? availabilitymodaldata.ReservedUnits[0].ReservedByCNIC
                    : null}
                </li>
                <li>
                  <b>Up To Date</b>{" "}
                  {availabilitymodaldata.ReservedUnits !== undefined &&
                  availabilitymodaldata.ReservedUnits.length > 0 &&
                  availabilitymodaldata.ReservedUnits[0].UpToDate !== null
                    ?moment(availabilitymodaldata.ReservedUnits[0].UpToDate.split(
                        "T"
                      )[0]).format("DD-MMM-YYYY")
                    : null}
                </li>
              </ul>
            </div>
          </div>

          <div className="col-12 text-right"></div>
        </div>
      </div>
    </>
  );
};
export default AvailabilityComp;

import axios from 'axios';
import { toast } from "react-toastify";
export const GetAffiliatesTable = (data) => {

    return ({
        type: "get_affiliatesdata",
        payload: data,
        loader: false
    })
}
export const GetAffiliateData = (data) => {

    return ({
        type: "get_Affdata",
        payload: data,

    })
}
export function getaffiliatepopulatetable() {
    return (dispatch) => {
        axios({
            url: `${window.$url}Affiliates/GetAll`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAffiliatesTable(result.data.Data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}


import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getallunits } from "../../actions/getunit";
import AvailabilityPrint from "./AvailabilityPrint/AvailabilityPrint";
import { CSVLink } from "react-csv";



const MainAvailability = (props) => {
    const resultsallunits = useSelector((state) => state.getAllUnits);
    const [filerunits, setfilterunits] = useState()
    const [availabilitymodaldata, setavailabilitymodaldata] = useState({});

    const dispatch = useDispatch();
    // print function
    const componentRef = useRef();

    const printPaymentRec = useReactToPrint({
        content: () => componentRef.current,
    });
    //Table Data fiter




    const onTextChange = (e) => {

        if (document.getElementById("projectfilter").value === "" && document.getElementById("unitfilter").value === "" && document.getElementById("sectionfilter").value === "" && document.getElementById("statusfilter").value === "") {
            setfilterunits(resultsallunits.data);

        } else {
            let suggestions = [];
            {
                const regex = new RegExp(document.getElementById("projectfilter").value, "i");
                const regex1 = new RegExp(document.getElementById("sectionfilter").value, "i");
                const regex2 = new RegExp(document.getElementById("unitfilter").value, "i");
                const regex3 = new RegExp(document.getElementById("statusfilter").value, "i");


                suggestions = resultsallunits.data
                    .sort()
                    .filter((val) =>
                        (document.getElementById("projectfilter").value == "" || regex.test(val.Project.ProjectName)) &&
                        (document.getElementById("sectionfilter").value == "" || regex1.test(val.Section.SectionName)) &&
                        (document.getElementById("unitfilter").value == "" || regex2.test(val.UnitName)) &&
                        (document.getElementById("statusfilter").value == "" || regex3.test(val.Status))
                    );

            }
            setfilterunits(suggestions);
        }
    };

    useEffect(() => {


        dispatch(getallunits());
    }, [dispatch]
    )
    useEffect(() => {
        setfilterunits(resultsallunits.data);
    }, [resultsallunits]
    )
    function showmodal(data) {
        document.getElementById("mymodal").style.display = "block";
        setavailabilitymodaldata(data);
    }
    function closemodal() {
        document.getElementById("mymodal").style.display = "none";

    }

    const [CsvData, setCsvData] = useState([]);
    useEffect(() => {
      let arr = [];
      filerunits?.forEach((val, index) => {
        arr.push({
          Sr: index + 1,
          Project:
            val.Project !== null && val.Project !== undefined ? (val.Project.ProjectName) : (''),
          Section:
            val.Section !== null && val.Section !== undefined ? (val.Section.SectionName) : (''),
          Unit: val.UnitName,
          Area: val.Marla + "M" + val.Sarsai + "S",
          Dimension: val.DimensionLength + "x" + val.DimensionWidth,
          Sqft: val.NetSqft,
          "S.Rate": parseFloat(val.RatePerMarla).toLocaleString(),
          "Total Value": parseFloat(val.Price).toLocaleString(),
          "Down Payment": parseFloat(val.AdvanceAmount).toLocaleString(),
          Installment:
            parseFloat(Math.ceil(val.ExpectedInstallment)).toLocaleString() +
            " in " +
            val.Months +
            " M",
          Status: val.Status,
          "Reserved By":
            val.ReservedUnits.length > 0
              ? val.ReservedUnits[0].ReservedByName
              : null,
        });
      });
      setCsvData(arr);
    }, [filerunits]);
    return (
      <>
        <div hidden>
          <AvailabilityPrint ref={componentRef} printData={resultsallunits} />
        </div>
        <div className="row  app_font">
          <div className="dash_background col-12 pt-2">
            <div className="row pt-3 ">
              <div className="col-md-1 pr-0 pl-0">
                <label className="input_label m-0">Search:</label>
              </div>
              <div className="col-md-2 pr-0 pl-0">
                <input
                  type="text "
                  className="input_styling "
                  id="projectfilter"
                  onChange={onTextChange}
                  placeholder="Project "
                ></input>
              </div>
              <div className="col-md-2 pr-0 pl-0">
                <input
                  type="text "
                  className="input_styling "
                  id="sectionfilter"
                  onChange={onTextChange}
                  placeholder="Section"
                ></input>
              </div>
              <div className="col-md-2 pr-0 pl-0">
                <input
                  type="text "
                  className="input_styling "
                  id="unitfilter"
                  onChange={onTextChange}
                  placeholder="Unit "
                ></input>
              </div>
              <div className="col-md-2 pr-0 pl-0">
                <input
                  type="text "
                  className="input_styling "
                  id="statusfilter"
                  onChange={onTextChange}
                  placeholder="Status "
                ></input>
              </div>

              <div className="col-md-1 pr-0 pl-0">
                {filerunits !== undefined ? (
                  filerunits.loading ? (
                    <Loader
                      type="ThreeDots"
                      color="green"
                      height={40}
                      width={40}
                    />
                  ) : null
                ) : null}
              </div>
            </div>

            <div className="row ">
              <div className=" col-12  table_height pr-0 pl-0">
                <div className="table-responsive mt-4">
                  <table className="table table-borderless m-0">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th>Project</th>
                        <th>Section </th>
                        <th>Unit</th>
                        <th>Area</th>
                        <th>Dimension</th>
                        <th>Sqft</th>
                        <th>S.Rate</th>

                        <th>Total Value</th>
                        <th>Down Payment</th>
                        <th>Installment</th>
                        <th>Status</th>
                        <th>Reserved By</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filerunits !== undefined
                        ? filerunits.map((val, index) => (
                            <tr
                              key={index}
                              className="cur_sor"
                              onClick={() => {
                                showmodal(val);
                              }}
                            >
                              <td>{index + 1}</td>
                              {val.Project !== null &&
                              val.Project !== undefined ? (
                                <td>{val.Project.ProjectName}</td>
                              ) : (
                                <td></td>
                              )}
                              {val.Section !== null &&
                              val.Section !== undefined ? (
                                <td>{val.Section.SectionName}</td>
                              ) : (
                                <td></td>
                              )}
                              <td>{val.UnitName}</td>

                              {/* <td>{val.NetMarla}</td> */}
                              <td>{val.Marla + "M" + val.Sarsai + "S"}</td>
                              <td>
                                {val.DimensionLength + "x" + val.DimensionWidth}
                              </td>
                              <td>{val.NetSqft}</td>

                              <td>
                                {parseFloat(val.RatePerMarla).toLocaleString()}
                              </td>

                              <td>{parseFloat(val.Price).toLocaleString()}</td>
                              <td>
                                {parseFloat(val.AdvanceAmount).toLocaleString()}
                              </td>

                              <td>
                                {parseFloat(
                                  Math.ceil(val.ExpectedInstallment)
                                ).toLocaleString() +
                                  " in " +
                                  val.Months +
                                  " M"}
                              </td>

                              <td>{val.Status}</td>
                              <td
                                onClick={() => {
                                  showmodal(val);
                                }}
                              >
                                {val.ReservedUnits.length > 0
                                  ? val.ReservedUnits[0].ReservedByName
                                  : null}
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-6  text-right pr-0 pl-0">
                <button
                  className="print_btn float-left"
                  onClick={() => {
                    printPaymentRec();
                  }}
                >
                  <TiPrinter size="1.3em" />
                  Print
                </button>
                <button
                  style={{ color: "black !important" }}
                  className="print_btn ml-2 float-left"
                >
                  <CSVLink
                    style={{ color: "black" }}
                    filename="Availability_Csv"
                    data={CsvData}
                  >
                    Download CSV
                  </CSVLink>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="mymodal" class="modal">
          <div class="modal-content-cat">
            <div className="row">
              <div className="col-12  page_heading">
                <h4 className="  text-center pt-2">Token Detail</h4>
                <span class="close pr-2  " onClick={closemodal}>
                  &times;
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-6 mt-2">
                <ul>
                  <li>
                    <b>Reserved By:</b>{" "}
                    {availabilitymodaldata.ReservedUnits !== undefined &&
                    availabilitymodaldata.ReservedUnits.length > 0
                      ? availabilitymodaldata.ReservedUnits[0].ReservedByName
                      : null}
                  </li>
                  <li>
                    <b>Reserved By Cnic/Id:</b>{" "}
                    {availabilitymodaldata.ReservedUnits !== undefined &&
                    availabilitymodaldata.ReservedUnits.length > 0
                      ? availabilitymodaldata.ReservedUnits[0].ReservedByCNIC
                      : null}
                  </li>
                </ul>
              </div>
              <div className="col-6 mt-2">
                <ul>
                  <li>
                    <b>Token Amount:</b>{" "}
                    {availabilitymodaldata.ReservedUnits !== undefined &&
                    availabilitymodaldata.ReservedUnits.length > 0
                      ? availabilitymodaldata.ReservedUnits[0].TokenAmount !==
                        null
                        ? parseFloat(
                            availabilitymodaldata.ReservedUnits[0].TokenAmount
                          ).toLocaleString()
                        : null
                      : null}
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-12 text-right"></div>
          </div>
        </div>
      </>
    );
};
export default MainAvailability;
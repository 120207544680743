import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { MdDeleteForever } from "react-icons/md";
import { BsCloudUpload } from "react-icons/bs";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getAllContractTable } from "../../../actions/accountsactions/addcontract";
import AddNomineeModal from "./AddNomineeModal";
import Loader from "react-loader-spinner";

export default function ContractInstallmentModal({ setshowModal, modalData }) {
  const [installmentdifference, setinstallmentdifference] = useState(0);
  const [totalAmount, settotalAmount] = useState(0);
  const [advanceAmount, setadvanceAmount] = useState(0);
  const [InstallmentDurationList, setInstallmentDurationList] = useState([]);
  const [installmenttotal, setinstallmenttotal] = useState(0);
  const [dealboughtcommissionamt, setdealboughtcommissionamt] = useState(0);
  const [reservedComm, setreservedComm] = useState(true);
  const [commissionAmt, setcommissionAmt] = useState(0);
  const [comissonExPer, setcomissonExPer] = useState(0);
  const [adminSharePer, setadminSharePer] = useState(0);
  const [comissionIncPer, setcomissionIncPer] = useState(0);
  const [commissionReservePer, setcommissionReservePer] = useState(0);
  const [showNomineeModal, setshowNomineeModal] = useState(false);
  const dispatch = useDispatch();
  const [allNominees, setallNominees] = useState([]);
  const [idval, setidval] = useState(0);
  const [nomnieeid, setnomnieeid] = useState("");
  const [loader1, setloader1] = useState(false);

  console.log(modalData);
  useEffect(() => {
    setinstallmentdifference(modalData.TotalPrice - modalData.AdvanceAmount);
    setadvanceAmount(modalData.AdvanceAmount);
    settotalAmount(modalData.TotalPrice);
  }, [modalData]);
  useEffect(() => {
    let total = 0;
    if (InstallmentDurationList.length !== 0) {
      InstallmentDurationList.forEach((x) => {
        total += parseFloat(x.DueAmount);
      });
    }

    // let inst = installmentdifference;
    // inst = inst - total;
    // setinstallmentdifference(inst)

    setinstallmenttotal(total);
  }, [InstallmentDurationList]);
  const custominstallments = () => {
    if (parseInt(installmentdifference) === 0) {
      toast.info("Remaining Amount is 0 , you can't create installments");
    } else if (document.getElementById("customDate").value === "") {
      toast.info("Select Date ");
    } else if (document.getElementById("customAmount").value === "") {
      toast.info("Enter custome installment amount");
    } else {
      if (parseFloat(document.getElementById("customAmount").value) > "0") {
        let sum = 0;
        InstallmentDurationList.map((x) => {
          if (
            x.DueAmount != "" ||
            x.DueAmount != null ||
            x.DueAmount != undefined
          )
            sum = sum + parseFloat(x.DueAmount);
        });

        let idv = idval + 1;
        let amount = parseFloat(
          document.getElementById("customAmount").value.replace(/,/g, "")
        );
        let installmentdate = document.getElementById("customDate").value;
        if (amount === "") {
          amount = "0";
        }
        sum = sum + parseFloat(amount);
        if (sum > installmentdifference) {
          toast.info(
            "Installments value is greater than total receivable amount"
          );
        } else if (sum === 0) {
          toast.info("Please enter value greater than 0!");
        } else {
          // document.getElementById("advanceval").disabled = true;
          let item = {
            InstallmentNo: idv,
            DueAmount: amount,

            DueDate: installmentdate,
          };
          setidval(idv + 1);
          setInstallmentDurationList([...InstallmentDurationList, item]);
          // document.getElementById("NoOfInstallements").value = (
          //     InstallmentDurationList.length + 1
          // ).toString();
          document.getElementById("customAmount").value = "";
        }
      } else {
        toast.info("Please enter value greater than 0!");
      }
    }
  };
  const deleteinstallment = (idx) => {
    const rows = [...InstallmentDurationList];
    rows.splice(idx, 1);

    setInstallmentDurationList(rows);
  };
  // custom installments end
  // auto installments
  function monthDiff(dateFrom, dateTo) {
    return (
      1 +
      dateTo.getMonth() -
      dateFrom.getMonth() +
      12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    );
  }
  const autoinstallments = () => {
    if (parseInt(installmentdifference) === 0) {
      toast.info("Remaining Amount is 0 , you can't create installments");
    } else if (document.getElementById("noofinstall").value === "") {
      toast.info("Add no of installment");
    } else if (document.getElementById("startDate").value === "") {
      toast.info("Enter starting date ");
    } else if (document.getElementById("Enddate").value === "") {
      toast.info("Enter end date ");
    } else {
      let startmonth = document.getElementById("startDate").value;
      let endmonth = document.getElementById("Enddate").value;
      let noofinstall = parseInt(document.getElementById("noofinstall").value);
      let paydate = document.getElementById("paydate").value;

      if (startmonth !== "" && endmonth !== "" && noofinstall !== "") {
        let totalmonths = monthDiff(new Date(startmonth), new Date(endmonth));

        let totalintallmentsmonths = totalmonths / noofinstall;

        let singleintallvalue = Math.round(installmentdifference / noofinstall);

        let tabledata = [];
        let dt = new Date(
          parseInt(startmonth.split("-")[0]),
          parseInt(startmonth.split("-")[1]) - 1,
          paydate
        );

        let tIV = 0;
        let i = 1;
        for (; i <= noofinstall - 1; i++) {
          if (Number(singleintallvalue.toString().slice(-2)) >= 50) {
            let amt = 100 - Number(singleintallvalue.toString().slice(-2));
            singleintallvalue = singleintallvalue + amt;
          } else if (Number(singleintallvalue.toString().slice(-2)) < 50) {
            singleintallvalue =
              singleintallvalue -
              Number(singleintallvalue.toString().slice(-2));
          }
          tIV += singleintallvalue;
          let item = {
            InstallmentNo: i,
            DueAmount: parseFloat(singleintallvalue),
            DueDate: moment(dt).format("MM/DD/YYYY"),
          };
          dt.setMonth(dt.getMonth() + parseInt(totalintallmentsmonths));
          setidval(i);
          tabledata.push(item);
          // setidval(idv + 1);
        }
        let item = {
          InstallmentNo: i,
          DueAmount: installmentdifference - tIV,
          DueDate: moment(dt).format("MM/DD/YYYY"),
        };

        tabledata.push(item);

        setInstallmentDurationList(tabledata);
        // setInstallmentDurationList([...InstallmentDurationList, item]);
      } else {
        toast.info("select start, end month and number of installments");
      }
    }
  };
  const saveInstallments = () => {
    if (
      localStorage.getItem("ProjectOwner") === "OTHER" &&
      document.getElementById("DealCommPer").value === ""
    ) {
      toast.warning("Please Enter Commission Income");
      return;
    }
    // if (localStorage.getItem("ProjectOwner") === "PERSONAL" && comissonExPer + adminSharePer + commissionReservePer !== modalData.Unit?.Section?.MarketingExpensePercentage) {
    //     toast.warning(`Sum of Commisson Expense %,Commission Reserved % and Admin Share % should be equal to ${modalData.Unit?.Section?.MarketingExpensePercentage}`)
    //     return

    // }
    if (
      localStorage.getItem("ProjectOwner") === "PERSONAL" &&
      installmenttotal != installmentdifference
    ) {
      toast.warning("Installment amount should be equal to Remaining Amount");
      return;
    }
    if (nomnieeid === "") {
      toast.warning("Please Select Nominee");
      return;
    }
    setloader1(true);

    document.getElementById("instasavebtn").disabled = true;
    axios({
      method: "post",
      url: `${window.$url}Contracts/AddInstallments`,
      data: {
        Contract: {
          ContractId: modalData.ContractId,
          ProjectId: modalData.ProjectId,
          CompanyCommissionPer: comissionIncPer,
          CompanyCommissionAmount: parseFloat(
            document.getElementById("DealCommAmt").value?.replace(/,/g, "")
          ),
          AdminShareAmount: parseFloat(
            document.getElementById("contractAdminAmt").value?.replace(/,/g, "")
          ),
          AdminSharePer: adminSharePer,
          ReservedCommissionAmount: parseFloat(
            document
              .getElementById("contractReservedCommAmt")
              .value?.replace(/,/g, "")
          ),
          ReservedCommissionPer: parseFloat(
            document
              .getElementById("contractReservedComPer")
              .value?.replace(/,/g, "")
          ),
          ComissionPer: comissonExPer,
          ComissionAmount: parseFloat(
            document
              .getElementById("contractcomamount")
              .value?.replace(/,/g, "")
          ),
          NomineeId: nomnieeid,
        },
        Installments: InstallmentDurationList,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    })
      .then((res) => {
        document.getElementById("instasavebtn").disabled = false;

        if (res.data.IsSuccess === true) {
          setloader1(false);
          toast.info(res.data.Message);

          dispatch(
            getAllContractTable({
              From: "",
              To: "",
              ProjectId: localStorage.getItem("ProjectId"),
            })
          );
          setshowModal(false);
        } else {
          setloader1(false);

          toast.info(res.data.Message);
        }
      })
      .catch((err) => {
        setloader1(false);
        document.getElementById("instasavebtn").disabled = false;

        toast.info("Something went wrong");
      });
    // setloader1(false)
  };
  const onTextChangeNomineeCnic = () => {
    var inputValue = document.getElementById("jBuyernominee").value;
    var options = document.getElementById("buyernominee").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        allNominees.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });

        setnomnieeid(val.NomineeId);
        break;
      }
      setnomnieeid("");
    }
  };
  const getAllNominee = () => {
    axios({
      url: `${window.$url}Nominees/GetAll`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          setallNominees(result.data.Nominees);
          // emptyfields();
          // setnomnieeid(result.data.NomineeId);

          toast.info(result.data.Message);
        } else {
          toast.info(result.data.Message);
          // setnomnieeid(result.data.NomineeId);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
  useEffect(() => {
    getAllNominee();
  }, [modalData]);
  return (
    <div
      style={{ display: "block" }}
      class="modalscreen "
    >
      <div class="modal-content-screen1 pt-1">
        <div className="row ">
          <div className="col-12  page_heading">
            <h4 className="  text-center ">
              Add{" "}
              {localStorage.getItem("ProjectOwner") === "OTHER"
                ? "Commisions & Transactions"
                : "Installments & Transactions"}
            </h4>
            <span
              class="close pr-2  "
              onClick={() => {
                setshowModal(false);
              }}
            >
              &times;
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 mt-3 text-left p-0">
            <label className="input_label m-0 ">Total Amount:</label>
          </div>
          <div className="col-md-3 mt-3  p-0">
            <input
              type="text"
              value={totalAmount?.toLocaleString()}
              placeholder="%"
              disabled={true}
              className="input_styling ml-md-3 "
            />
          </div>
          <div className="col-md-2 mt-3  p-0"></div>
          <div className="col-md-2 mt-3 text-left p-0">
            <label className="input_label m-0 ">Advance Amount:</label>
          </div>
          <div className="col-md-3 mt-3  p-0">
            <input
              type="text"
              value={advanceAmount?.toLocaleString()}
              placeholder="%"
              disabled={true}
              className="input_styling ml-md-3 "
            />
          </div>
        </div>
        <div
          hidden={
            localStorage.getItem("ProjectOwner") === "PERSONAL" ? true : false
          }
          className="row"
        >
          <div className="col-md-2 mt-3 text-left p-0">
            <label className="input_label m-0 ">Commission Inc%:</label>
          </div>
          <div className="col-md-3 mt-3  p-0">
            <input
              type="text"
              id="DealCommPer"
              placeholder="%"
              disabled={false}
              className="input_styling ml-md-3 "
              onChange={(e) => {
                if (Number(e.target.value) <= 100) {
                  setcomissionIncPer(Number(e.target.value));
                  document.getElementById("DealCommAmt").value = parseFloat(
                    Math.round(
                      (parseFloat(e.target.value.replace(/,/g, "")) / 100) *
                        totalAmount
                    )
                  ).toLocaleString();
                } else {
                  setcomissionIncPer(0);

                  e.target.value = 0;
                  document.getElementById("DealCommAmt").value = "";
                }
                if (e.target.value === "") {
                  document.getElementById("DealCommAmt").value = 0;
                }
              }}
            />
          </div>
          <div className="col-md-2 mt-3  p-0"></div>
          <div className="col-md-2 mt-3 text-left p-0">
            <label className="input_label m-0">Commission Inc:</label>
          </div>
          <div className="col-md-3 mt-3  p-0">
            <input
              type="text"
              placeholder="amount"
              id="DealCommAmt"
              disabled={true}
              className="input_styling ml-md-3"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 mt-3 text-left p-0">
            <label className="input_label m-0 ">Comission Exp% :</label>
          </div>
          <div className="col-md-3 mt-3  p-0">
            <input
              type="text"
              id="contractcomisson"
              placeholder="%"
              disabled={false}
              className="input_styling ml-md-3 "
              onChange={(e) => {
                if (localStorage.getItem("ProjectOwner") === "PERSONAL") {
                  // if ((Number(e.target.value) + adminSharePer + commissionReservePer <= modalData.Unit?.Section?.MarketingExpensePercentage)) {
                  setcomissonExPer(Number(e.target.value));
                  document.getElementById("contractcomamount").value =
                    parseFloat(
                      Math.round(
                        (parseFloat(e.target.value.replace(/,/g, "")) / 100) *
                          totalAmount
                      )
                    ).toLocaleString();
                  if (
                    parseFloat(
                      Math.round(
                        (parseFloat(e.target.value.replace(/,/g, "")) / 100) *
                          totalAmount
                      )
                    ) > 0
                  ) {
                    setreservedComm(false);
                  } else {
                    setreservedComm(true);
                  }
                  setcommissionAmt(
                    parseFloat(
                      Math.round(
                        (parseFloat(e.target.value.replace(/,/g, "")) / 100) *
                          totalAmount
                      )
                    )
                  );
                  // }
                  //  else {
                  //     toast.warning(`Sum of Commisson Expense %,Commission Reserved % and Admin Share % should be equal to ${modalData.Unit?.Section?.MarketingExpensePercentage}`)

                  //     setcomissonExPer(0)
                  //     document.getElementById("contractcomamount").value = "";
                  //     e.target.value = 0;
                  //     setreservedComm(true)
                  //     setcommissionAmt(0)
                  // }
                } else {
                  if (
                    Number(e.target.value) +
                      adminSharePer +
                      commissionReservePer <=
                    comissionIncPer
                  ) {
                    setcomissonExPer(Number(e.target.value));
                    document.getElementById("contractcomamount").value =
                      parseFloat(
                        Math.round(
                          (parseFloat(e.target.value.replace(/,/g, "")) / 100) *
                            totalAmount
                        )
                      ).toLocaleString();
                    if (
                      parseFloat(
                        Math.round(
                          (parseFloat(e.target.value.replace(/,/g, "")) / 100) *
                            totalAmount
                        )
                      ) > 0
                    ) {
                      setreservedComm(false);
                    } else {
                      setreservedComm(true);
                    }
                    setcommissionAmt(
                      parseFloat(
                        Math.round(
                          (parseFloat(e.target.value.replace(/,/g, "")) / 100) *
                            totalAmount
                        )
                      )
                    );
                  }
                  //  else {
                  //     setcomissonExPer(0)
                  //     toast.warning("Sum of Admin Share %, Commission Expense % and Reserved Commission % Should be less than Commisson Income Percentage")

                  //     document.getElementById("contractcomamount").value = "";
                  //     e.target.value = 0;
                  //     setreservedComm(true)
                  //     setcommissionAmt(0)
                  // }
                }
                if (e.target.value === "") {
                  document.getElementById("contractcomamount").value = 0;
                }
              }}
            />
          </div>
          <div className="col-md-2 mt-3  p-0"></div>
          <div className="col-md-2 mt-3 text-left p-0">
            <label className="input_label m-0">Commission Exp:</label>
          </div>
          <div className="col-md-3 mt-3   p-0">
            <input
              placeholder="amount"
              type="text"
              id="contractcomamount"
              disabled={true}
              className="input_styling ml-md-3"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 mt-3 text-left p-0">
            <label className="input_label m-0 ">Reserved Comm. %:</label>
          </div>
          <div className="col-md-3 mt-3  p-0">
            <input
              type="text"
              id="contractReservedComPer"
              placeholder="%"
              disabled={false}
              className="input_styling ml-md-3 "
              // onChange={(e) => {
              //     if ((Number(e.target.value) <= 100)) {

              //         document.getElementById("contractReservedCommAmt").value =
              //             parseFloat(
              //                 Math.round(
              //                     (parseFloat(e.target.value.replace(/,/g, "")) / 100) * installmentdifference)
              //             ).toLocaleString();
              //     } else {

              //         document.getElementById("contractReservedCommAmt").value = "";
              //         e.target.value = 0
              //     }

              // }}
              onChange={(e) => {
                if (localStorage.getItem("ProjectOwner") === "PERSONAL") {
                  // if ((Number(e.target.value) + adminSharePer + comissonExPer <= modalData.Unit?.Section?.MarketingExpensePercentage)) {
                  setcommissionReservePer(Number(e.target.value));
                  document.getElementById("contractReservedCommAmt").value =
                    parseFloat(
                      Math.round(
                        (parseFloat(e.target.value.replace(/,/g, "")) / 100) *
                          totalAmount
                      )
                    ).toLocaleString();

                  // }
                  // else {
                  //     toast.warning(`Sum of Commisson Expense %,Commission Reserved % and Admin Share % should be equal to ${modalData.Unit?.Section?.MarketingExpensePercentage}`)
                  //     document.getElementById("contractReservedCommAmt").value = "";
                  //     e.target.value = 0;
                  //     setcommissionReservePer(0)
                  // }
                } else {
                  if (
                    Number(e.target.value) + adminSharePer + comissonExPer <=
                    comissionIncPer
                  ) {
                    setcommissionReservePer(Number(e.target.value));
                    document.getElementById("contractReservedCommAmt").value =
                      parseFloat(
                        Math.round(
                          (parseFloat(e.target.value.replace(/,/g, "")) / 100) *
                            totalAmount
                        )
                      ).toLocaleString();
                  }
                  //  else {
                  //     toast.warning("Sum of Admin Share %, Commission Expense % and Reserved Commission % Should be less than Commisson Income Percentage")

                  //     document.getElementById("contractReservedCommAmt").value = "";
                  //     e.target.value = 0;
                  //     setcommissionReservePer(0)
                  // }
                }
                if (e.target.value === "") {
                  document.getElementById("contractReservedCommAmt").value = 0;
                }
              }}
            />
          </div>
          <div className="col-md-2 mt-3  p-0"></div>
          <div className="col-md-2 mt-3 text-left p-0">
            <label className="input_label m-0">Reserved Comm. Amt.:</label>
          </div>
          <div className="col-md-3 mt-3   p-0">
            <input
              placeholder="amount"
              type="text"
              id="contractReservedCommAmt"
              disabled={true}
              className="input_styling ml-md-3"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 mt-3 text-left p-0">
            <label className="input_label m-0 ">Admin Share % :</label>
          </div>
          <div className="col-md-3 mt-3  p-0">
            <input
              type="text"
              id="contractAdminShare"
              placeholder="%"
              disabled={false}
              className="input_styling ml-md-3 "
              onChange={(e) => {
                if (localStorage.getItem("ProjectOwner") === "PERSONAL") {
                  // if (Number(e.target.value) + comissonExPer + commissionReservePer <= modalData.Unit?.Section?.MarketingExpensePercentage) {
                  setadminSharePer(Number(e.target.value));
                  document.getElementById("contractAdminAmt").value =
                    parseFloat(
                      Math.round(
                        (parseFloat(e.target.value.replace(/,/g, "")) / 100) *
                          totalAmount
                      )
                    ).toLocaleString();

                  // }
                  //  else {
                  //     toast.warning(`Sum of Commisson Expense %,Commission Reserved % and Admin Share % should be equal to ${modalData.Unit?.Section?.MarketingExpensePercentage}`)

                  //     setadminSharePer(Number(e.target.value))
                  //     document.getElementById("contractAdminAmt").value = "";
                  //     e.target.value = 0
                  // }
                } else {
                  if (
                    Number(e.target.value) +
                      comissonExPer +
                      commissionReservePer <=
                    comissionIncPer
                  ) {
                    setadminSharePer(Number(e.target.value));
                    document.getElementById("contractAdminAmt").value =
                      parseFloat(
                        Math.round(
                          (parseFloat(e.target.value.replace(/,/g, "")) / 100) *
                            totalAmount
                        )
                      ).toLocaleString();
                  }
                  // else {
                  //     toast.warning("Sum of Admin Share %, Commission Expense % and Reserved Commission % Should be less than Commisson Income Percentage")
                  //     setadminSharePer(Number(e.target.value))
                  //     document.getElementById("contractAdminAmt").value = "";
                  //     e.target.value = 0
                  // }
                }
                if (e.target.value === "") {
                  document.getElementById("contractAdminAmt").value = 0;
                }
              }}
            />
          </div>
          <div className="col-md-2 mt-3  p-0"></div>
          <div className="col-md-2 mt-3 text-left p-0">
            <label className="input_label m-0">Admin Share Amt.:</label>
          </div>
          <div className="col-md-3 mt-3   p-0">
            <input
              placeholder="amount"
              type="text"
              id="contractAdminAmt"
              disabled={true}
              className="input_styling ml-md-3"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-2 mt-md-4 p-0">
            <label className="input_label m-0">Nominee CNIC :</label>
          </div>
          <div className="col-md-3 mt-md-4  p-0">
            <input
              type="text"
              autoComplete="off"
              className="input_styling ml-md-3"
              id="jBuyernominee"
              placeholder="Search By CNIC "
              onChange={onTextChangeNomineeCnic}
              list="buyernominee"
            />
            <datalist id="buyernominee">
              {allNominees?.map((x, idx) => (
                <option
                  index={idx}
                  value={x.NomineeCnic}
                >
                  {x.NomineeName}
                </option>
              ))}
            </datalist>
            <span id="bclientdemonominee"></span>
          </div>

          <div className="col-md-2 mt-4   p-0">
            <button
              className="save_btn ml-2  "
              onClick={() => {
                setshowNomineeModal(true);
              }}
            >
              Add
            </button>
          </div>
        </div>
        {localStorage.getItem("ProjectOwner") === "OTHER" ? null : (
          <div className="row">
            <div className="col-md-6 mt-3 p-0">
              <div className="row ">
                <div className="col-md-12   ">
                  <label className="input_label m-0 ">
                    <b>Custom Installments:</b>
                  </label>
                </div>
                <div className="col-md-5 mt-3  p-0">
                  <input
                    type="text"
                    className="input_styling "
                    id="customAmount"
                    placeholder="amount"
                    onChange={(e) => {
                      if (e.target.value.length > 0)
                        document.getElementById("customAmount").value =
                          parseFloat(
                            e.target.value.replace(/,/g, "")
                          ).toLocaleString();
                    }}
                  />
                </div>
                <div className="col-md-5 mt-3  p-0">
                  <input
                    type="date"
                    className="input_date_styling "
                    id="customDate"
                  />
                </div>

                <div className="col-md-5 mt-3 text-center p-0">
                  <label className="input_label m-0 ">Remaining Amt:</label>
                </div>
                <div className="col-md-5 mt-3  p-0">
                  <input
                    type="text"
                    className="input_styling"
                    id="remainingamt"
                    value={(
                      installmentdifference - Number(installmenttotal)
                    )?.toLocaleString()}
                  />
                </div>
                <div
                  className="col-md-10 mt-3 text-right
                        pr-4"
                >
                  <button
                    className="save_btn "
                    onClick={() => {
                      custominstallments();
                    }}
                    id="custominstallbutton"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-3  p-0">
              <div className="row ">
                <div className="col-md-12   ">
                  <label className="input_label m-0 ">
                    <b>Auto Installments:</b>
                  </label>
                </div>
                <div className="col-md-2 mt-3  p-0">
                  <label className="input_label m-0 ">Start:</label>
                </div>
                <div className="col-md-4 mt-3  p-0">
                  <input
                    type="month"
                    className="input_date_styling "
                    id="startDate"
                  />
                </div>
                <div className="col-md-2 mt-3  p-0">
                  <label className="input_label m-0 ">End:</label>
                </div>
                <div className="col-md-4 mt-3  p-0">
                  <input
                    type="month"
                    className="input_date_styling "
                    id="Enddate"
                  />
                </div>
                <div className="col-md-2 mt-3  p-0">
                  <label className="input_label m-0 ">Pay. Date:</label>
                </div>
                <div className="col-md-2 mt-3  p-0">
                  <select
                    className="input_styling "
                    id="paydate"
                  >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
                    <option>21</option>
                    <option>22</option>
                    <option>23</option>
                    <option>24</option>
                    <option>25</option>
                    <option>26</option>
                    <option>27</option>
                    <option>28</option>
                  </select>
                </div>
                <div className="col-md-4 mt-3  p-0">
                  <label className="input_label m-0 ">
                    No. of Installments:
                  </label>
                </div>
                <div className="col-md-4 mt-3 p-0">
                  <input
                    type="number"
                    className="input_styling "
                    id="noofinstall"
                    onChange={(e) => {
                      let totalmonths = monthDiff(
                        new Date(document.getElementById("startDate").value),
                        new Date(document.getElementById("Enddate").value)
                      );
                      if (Number(e.target.value) > totalmonths) {
                        e.target.value = totalmonths;
                        toast.warning(
                          "No of installments should be less than or equal to month difference"
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-md-12 mt-3 text-right ">
                  <button
                    className="save_btn ml-2 "
                    onClick={() => {
                      autoinstallments();
                    }}
                    id="autoinstallbutton"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div
                className="  mt-3 overflow-auto"
                style={{ height: "145px" }}
              >
                <table class="table tableCash table-hover ">
                  <thead class="purple whiteText">
                    <tr>
                      <th scope="col">Installment No</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Date</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {InstallmentDurationList.map((x, idx) => (
                      <tr>
                        <th scope="row">{x.InstallmentNo}</th>
                        <td>{x.DueAmount.toLocaleString()}</td>
                        <td>{moment(x.DueDate).format("DD-MMM-YYYY")}</td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => deleteinstallment(idx)}
                        >
                          <MdDeleteForever size="1.6em" />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mt-1">
              <div className="col-6 text-center">
                <b>Total:</b> {parseFloat(installmenttotal).toLocaleString()}
              </div>
            </div>
          </div>
        )}
        <div className="row pr-md-2 pt-md-4">
          <div className=" col-6 pr-0 pl-0  "></div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
            className=" col-6 pr-0 pl-0  text-right "
          >
            <span>
              <button
                className="cancel_btn ml-2  "
                onClick={() => setshowModal(false)}
              >
                Cancel
              </button>
            </span>
            <span className="ml-2">
              {loader1 && (
                <>
                  <Loader
                    type="Oval"
                    color="black"
                    height={30}
                    width={30}
                    radius={12}
                  />
                </>
              )}
            </span>
            <span>
              <button
                className="save_btn ml-2  "
                id="instasavebtn"
                onClick={() => {
                  saveInstallments();
                }}
              >
                <BsCloudUpload size="1.3em" />
                Save
              </button>
            </span>
          </div>
        </div>
      </div>
      {showNomineeModal && (
        <AddNomineeModal
          getAllNominee={getAllNominee}
          setshowModal={setshowNomineeModal}
        />
      )}
    </div>
  );
}

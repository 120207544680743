import React, { useEffect, useRef, useState } from "react";
import "../../App.css";
// import profilePic from "../../assets/profilelogo.jpg";
import { useHistory } from "react-router-dom";

import bellPic from "../../assets/bell.svg";

import dotpic from "../../assets/Group1.svg";
import dashboardPic from "../../assets/dashboard.svg";
import ledgerPic from "../../assets/ledger.svg";

import chartPic from "../../assets/chart.svg";
import taxPic from "../../assets/tax.svg";
import loanPic from "../../assets/loan.svg";
import reportPic from "../../assets/report.svg";
import payablePic from "../../assets/payable.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { RiArrowDropDownLine } from "react-icons/ri";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MdVisibility } from "react-icons/md";
import { Link } from "react-router-dom";

// import all pages of account payabale component
import AccDashboard from "./DashboardPage/accdashboard";
// import MainJournalEntryComp from "./JournalEntry/journalentry";
import ReceiptEntry from "./ReceiptEntry/receiptentry";

import MainGeneralAdjustment from "./accpayablepages/generaladjustment";
// import all pages of ledger component

// import AdjustmentJournal from "./ledgerpages/adjustmentjournal";
// import BrokerLedger from "./ledgerpages/brokerledger";
// import CustomerLedger from "./ledgerpages/customerledger";
// import EmployeeLedger from "./ledgerpages/employeeledger";
// import SupplierLedger from "./ledgerpages/supplierledger";

// import all pages ofchart of account

// import all pagesof tax
import TaxPayable from "./taxpages/taxpayable";

import { RecoveryReceiptReport } from "./Reports/RecoveryReceipt/RecoveryReceipt";

// loan import

import MainAddRoleCom from "./roles/roles";
import { getallRoles } from "../actions/getroles";
import { useSelector, useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Changeform from "./changeform/changeform";

import { getAllRevertUserVouchers } from "../actions/vouchersactions/GetRevertBackUserVouchers";
import moment from "moment";
import axios from "axios";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { monthlyAlert } from "../ContextApi/MonthlyAlert";
import AltertModal from "../AlertModal/AltertModal";
import { useContext } from "react";
import PaymentEntry from "./PaymentEntry/paymententry";
import RecoveryBookingMain from "./Vouchers/RecoveryBooking/RecoveryBookingMain";
import InstallmentMain from "./Vouchers/Installments/MainInstallment";
import MainContract from "./Contracts/Contract/MainContract";
import MainCustomer from "./Contracts/Customer/MainCustomer";
import InstallmentResceduleMain from "./Contracts/InstallmentRescedule/MainInstallRescedule";
import MainTransferContract from "./Contracts/TransferContract/TransferContractMain";
import ChartofAccount from "./ChartofAccount/ChartofAcc/ChartofAccount";
import Account from "./ChartofAccount/AddAccount/AddAccount";

import MainInvestment from "./Investments/Investment/MainInvestment";
import MainAddInvestor from "./Investments/Investor/MainAddInvestor";
import TrailBalance from "./Reports/TrailBalance/TrailBalance";
import GeneralJournalReport from "./Reports/GeneralJournal/GeneralJournal";
import BalanceSheet from "./Reports/BalanceSheet/BalanceSheet";
import IncomeStatement from "./Reports/IncomeStatement/IncomeStatement";
import GeneralLedger from "./Reports/GeneralLedger/GeneralLedger";
import CashBook from "./Reports/CashBook/CashBook";
import ManagerRecovery from "./Reports/ManagerRecovery/ManagerRecovery";
import JournalEntryMain from "./Vouchers/JournalEntry/JournalEntryMain";
import GeneralAdjustmentMain from "./Vouchers/GeneralAdjustment/GeneralAdjustmentMain";
import InstallmentReceipt from "./Reports/InstallReceipt/InstallReceipt";
import CommissionReceipt from "./Reports/CommissionReceipt/CommissionReceipt";
import AccountPayable from "./Reports/AccountPayable/AccountPayable";
import AccountReceivable from "./Reports/AccountReceviable/AccountReceivable";
import InstallmentPayment from "./Reports/InstallPayment/InstallPayment";
import CommissionPayment from "./Reports/CommissionPayment/CommissionPayment";
import ProjectReport from "./Reports/ProjectReport/ProjectReport";
import CommPaymentSold from "./Reports/CommissionPaySold/CommissionPaySold";
import MainTaxDetail from "./Tax/Tax/MainTaxDetail";
import MainTaxPayable from "./Tax/TaxPayable/MainTaxPayable";
import { TaxReceivable } from "./Tax/TaxReceivable/TaxReceivableForm";
import { TaxReceivableTable } from "./Tax/TaxReceivable/TaxReceivableTable";
import TaxReceivableMain from "./Tax/TaxReceivable/TaxReceivableMain";
import TaxPaid from "./Tax/TaxPaid/TaxPaid";
import TaxReceived from "./Tax/TaxReceived/TaxReceived";
import RevertBackVouchers from "./Vouchers/RevertBackVouchers/RevertBackVouchers";
import SubmittedVouchers from "./Vouchers/SubmittedVouchers/SubmittedVouchers";
import UnSubmittedVouchers from "./Vouchers/UnSumbittedVouhcers/UnSubmittedVouchers";
import AdminSharePayableReport from "./Reports/AdminSharePayable/AdminSharePayableReport";
import ReserveCommissionPayableReport from "./Reports/ReserveCommissionPayable/ReserveCommissionPayableReport";
import ExternalCommission from "./Vouchers/ExternalCommission/ExternalCommission";
import AllNotification from "./Vouchers/AllNotification/AllNotification";
import Loader from "react-loader-spinner";
import { getCompanyInfo } from "../actions/GetCompanyInfo";

function SideButton(props) {
  return (
    <>
      <button
        onClick={() => {
          props.onClick();
        }}
        className={
          props.active
            ? "grey side_button_hover row  sideText sideButton  mt-4"
            : "row side_button_hover sideText sideButton  mt-4"
        }
      >
        {props.icon}
        <p className=" ml-3 sideText green_text toggleButton">{props.text}</p>
      </button>
    </>
  );
}
function SideSubButton(props) {
  return (
    <>
      <button
        onClick={() => {
          props.onClick();
        }}
        className={
          props.active ? "sub_button_style_underline " : "sub_button_style "
        }
      >
        <span className="sideText">{props.text}</span>
        <span>
          {props.btnComponent !== undefined ? props.btnComponent : null}
        </span>
      </button>
      <br></br>
    </>
  );
}
const AccountMainPage = () => {
  // toggle state start
  let history = useHistory();
  const companyInfo = useSelector((state) => state.getCompany);
  const rolesdata = useSelector((state) => state.GetAllRole);
  console.log(rolesdata);
  let dispatch = useDispatch();
  const [reload, setreload] = useState(true);
  const [toggle, setToggle] = useState(true);
  const [allData, setallData] = useState([]);
  const VouchersData = useSelector(
    (state) => state.getAllRevertBackUserVouchers
  );
  const [connection, setConnection] = useState(null);
  const [chat, setChat] = useState([]);
  const { changeState } = useContext(monthlyAlert);
  const latestChat = useRef(null);
  const [allNotification, setallNotification] = useState([]);

  latestChat.current = chat;
  const [showAlert, setshowAlert] = useState(false);
  const [showNotificationModal, setshowNotificationModal] = useState(false);
  const [notificationRow, setnotificationRow] = useState(false);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    if (sessionStorage.getItem("isPaid") === "false") {
      changeState(true);
      setshowAlert(true);
    } else {
      changeState(false);
      setshowAlert(false);
    }
  }, []);
  console.log(allNotification);
  // useEffect(() => {

  //   axios
  //     .get("https://sheetdb.io/api/v1/dskt971mukoor")
  //     .then((res) => {
  //       if (
  //         res?.data?.length > 0 &&
  //         res.data[0]?.IsPaid === "FALSE" &&
  //         Number(moment().format("DD")) > Number(res.data[0]?.Date)
  //       ) {
  //         sessionStorage.setItem("isPaid", false);
  //         changeState(true);
  //         setshowAlert(true);
  //       } else {
  //         sessionStorage.setItem("isPaid", true);
  //         changeState(false);
  //         setshowAlert(false);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  const getAllNotification = () => {
    axios({
      url: `${window.$url}Vouchers/PendingNotifications`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    })
      .then((res) => {
        if (res.data.IsSuccess === true) {
          console.log(res.data);
          setallNotification(res.data.Data);
          toast.info(res.data.message);
        } else {
          toast.info(res.data.message);
        }
      })
      .catch((err) => {
        toast.info("Something went wrong");
      });
  };
  useEffect(() => {
    getAllNotification();
    const delay = 30 * 60 * 1000;
    const interval = setInterval(getAllNotification, delay);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(
        `https://gravitytestapi.azurewebsites.net/RevertBackNotification?Id=${localStorage.getItem(
          "LoginId"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "content-type": "application/json",
          },
        }
      )
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }, []);

  // useEffect(() => {
  //   if (connection) {
  //     connection
  //       .start()
  //       .then((result) => {
  //         console.log("Connected!");

  //         // connection.on('RevertBackNotification', message => {
  //         //   const updatedChat = [...latestChat.current];
  //         //   updatedChat.push(message);

  //         //   setChat(updatedChat);
  //         // });
  //       })
  //       .catch((e) => console.log("Connection failed: ", e));
  //   }
  // }, [connection]);
  const sendMessage = async (user, message) => {
    const chatMessage = {
      user: user,
      message: message,
    };

    if (connection.connectionStarted) {
      try {
        await connection.send("SendMessage", chatMessage);
      } catch (e) {
        console.log(e);
      }
    } else {
      alert("No connection to server yet.");
    }
  };
  // useEffect(() => {
  //   dispatch(getAllRevertUserVouchers(localStorage.getItem("LoginId")));
  // }, [dispatch]);

  useEffect(() => {
    if (VouchersData?.data) {
      setallData(VouchersData?.data);
    }
  }, [VouchersData]);

  useEffect(() => {
    dispatch(getCompanyInfo());
  }, []);
  const toggleClose = () => {
    let list = document.getElementsByClassName("toggleButton");
    if (toggle === true) {
      setToggle(false);
      for (var i = 0; i < list.length; i++) {
        list[i].style.display = "none";
      }
      document.getElementById("tog").removeAttribute("style");
    } else {
      setToggle(true);
      for (let i = 0; i < list.length; i++) {
        list[i].style.display = "block";
      }
      document.getElementById("tog").setAttribute("style", "width: 15em;");
    }
  };

  // toggle state end
  const Dashboard = [
    {
      component: <AccDashboard />,
      rolename: "Dashboard",
    },
  ];
  // account payable sub buttons

  const Voucher =
    `${localStorage.getItem("ProjectName")}` !== "HEAD OFFICE"
      ? [
          {
            text: "Receipt Entry",
            component: <ReceiptEntry></ReceiptEntry>,
            rolename: "Receipt Entry",
          },
          {
            text: "Payment Entry",
            component: <PaymentEntry></PaymentEntry>,
            rolename: "Payment Entry",
          },
          // {
          //     text: "Adjustment Entry",
          //     component: <AdjustmentEntry></AdjustmentEntry>,

          // },
          {
            text: "Recovery Booking",
            component: <RecoveryBookingMain />,
            rolename: "Recovery Booking",
          },
          {
            text: "Journal Entry",
            component: <JournalEntryMain></JournalEntryMain>,
            rolename: "Journal Entry",
          },
          {
            text: "General Adjustment",
            component: <GeneralAdjustmentMain></GeneralAdjustmentMain>,
            rolename: "General Adjustment",
          },
          {
            text: "Installments",
            component: <InstallmentMain></InstallmentMain>,
            rolename: "Installments",
          },
          {
            text: "External Commission",
            component: <ExternalCommission />,
            rolename: "External Commission",
          },
          {
            text: "UnSubmitted Vouchers",
            component: <UnSubmittedVouchers />,
            rolename: "UnSubmitted Vouchers",
          },
          // {
          //   text: "Submitted Vouchers",
          //   component: <SubmittedVouchers />,
          //   rolename: "Submitted Vouchers",
          // },
          {
            text: "Notifications",
            component: <AllNotification />,
            rolename: "Notifications",
          },
        ]
      : [
          {
            text: "Receipt Entry",
            component: <ReceiptEntry></ReceiptEntry>,
            rolename: "Receipt Entry",
          },
          {
            text: "Payment Entry",
            component: <PaymentEntry></PaymentEntry>,
            rolename: "Payment Entry",
          },
          // {
          //     text: "Adjustment Entry",
          //     component: <AdjustmentEntry></AdjustmentEntry>,

          // },

          {
            text: "Journal Entry",
            component: <JournalEntryMain></JournalEntryMain>,
            rolename: "Journal Entry",
          },

          {
            text: "Installments",
            component: <InstallmentMain></InstallmentMain>,
            rolename: "Installments",
          },
          {
            text: "UnSubmitted Vouchers",
            component: <UnSubmittedVouchers />,
            rolename: "UnSubmitted Vouchers",
          },
          // {
          //   text: "Submitted Vouchers",
          //   component: <SubmittedVouchers />,
          //   rolename: "Submitted Vouchers",
          // },
          {
            text: "Notifications",
            component: <AllNotification />,
            rolename: "Notifications",
          },
        ];

  // const Ledger = [
  //     // {
  //     //     text: "General Ledger",
  //     //     component: <GeneralLedgder></GeneralLedgder>,

  //     // },

  //     {
  //         text: "Customer Ledger",
  //         component: <CustomerLedger></CustomerLedger>,

  //     },
  //     {
  //         text: "Supplier Ledger",
  //         component: <SupplierLedger></SupplierLedger>,

  //     },
  //     {
  //         text: "Employee Ledger",
  //         component: <EmployeeLedger></EmployeeLedger>,

  //     },
  //     {
  //         text: "Broker's Ledger",
  //         component: <BrokerLedger></BrokerLedger>,

  //     },
  //     {
  //         text: "Adjustment Ledger",
  //         component: <AdjustmentJournal></AdjustmentJournal>,

  //     },

  // ];
  // contract

  const Contracts =
    `${localStorage.getItem("ProjectName")}` !== "HEAD OFFICE"
      ? `${localStorage.getItem("ProjectOwner")}` != "OTHER"
        ? [
            {
              text: "Contract",
              component: <MainContract></MainContract>,
              rolename: "Add Contract",
            },

            {
              text: "Install Reschedule",
              component: <InstallmentResceduleMain></InstallmentResceduleMain>,
              rolename: "Install Reschedule",
            },
            {
              text: "Customer",
              component: <MainCustomer></MainCustomer>,
              rolename: "Customer",
            },
            {
              text: "Transfer Contract",
              component: <MainTransferContract></MainTransferContract>,
              rolename: "Transfer Contract",
            },
          ]
        : [
            {
              text: "Contract",
              component: <MainContract></MainContract>,
            },

            {
              text: "Customer",
              component: <MainCustomer></MainCustomer>,
            },
            {
              text: "Transfer Contract",
              component: <MainTransferContract></MainTransferContract>,
            },
          ]
      : null;
  // chart of acccount subbutton
  const Chart = [
    {
      text: "Chart of Acc.",
      component: <ChartofAccount></ChartofAccount>,
      rolename: "Add Chart of Acc",
    },

    {
      text: "Account",
      component: <Account></Account>,
      rolename: "Add Account",
    },
    // {
    //     text: "Account Payable",
    //     component: <AccountPayable></AccountPayable>,

    // },
    // {
    //     text: "Account Receivable",
    //     component: <AccountReceivable></AccountReceivable>,

    // },
    // {
    //     text: "Fiscal Year",
    //     component: <FiscalYear></FiscalYear>,

    // },
  ];
  // Tax subbutton

  const Tax = [
    {
      text: "Tax",
      component: <MainTaxDetail></MainTaxDetail>,
      rolename: "Add Tax",
    },
    {
      text: "Tax Payable",
      component: <MainTaxPayable></MainTaxPayable>,
      rolename: "Tax Payable",
    },
    `${localStorage.getItem("ProjectName")}` === "HEAD OFFICE"
      ? {
          text: "Tax Paid",
          component: <TaxPaid></TaxPaid>,
          rolename: "Tax Paid",
        }
      : null,
    // `${localStorage.getItem("COA")}` !== "yes" ? null : {
    //     text: "Tax Paid",
    //     component: <TaxPaid></TaxPaid>,

    // },
    {
      text: "Tax Receivable",
      component: <TaxReceivableMain></TaxReceivableMain>,
      rolename: "Tax Receivable",
    },
    `${localStorage.getItem("ProjectName")}` === "HEAD OFFICE"
      ? {
          text: "Tax Received",
          component: <TaxReceived></TaxReceived>,
          rolename: "Tax Received",
        }
      : null,

    // `${localStorage.getItem("COA")}` !== "yes" ? null : {
    //     text: "Tax Received",
    //     component: <TaxReceived></TaxReceived>,

    // },
  ];
  // reports subbutton
  const Reports = [
    {
      text: "Trail Balance",
      component: <TrailBalance></TrailBalance>,
      // btnComponent: <div className="sub_btn_number">60000  |  90000</div>
      rolename: "Trail Balance",
    },
    {
      text: "General Journal",
      component: <GeneralJournalReport></GeneralJournalReport>,
      rolename: "General Journal",
    },
    {
      text: "General Ledger",
      component: <GeneralLedger></GeneralLedger>,
      rolename: "General Ledger",
    },
    {
      text: "Balance Sheet",
      component: <BalanceSheet></BalanceSheet>,
      // btnComponent: <div className="sub_btn_number">60000  |  90000</div>
      rolename: "Balance Sheet",
    },

    {
      text: "Income Statement",
      component: <IncomeStatement></IncomeStatement>,
      // btnComponent: <div className="sub_btn_number">60000 </div>
      rolename: "Income Statement",
    },
    {
      text: "Cash Book",
      component: <CashBook></CashBook>,
      rolename: "Cash Book",
    },
    // {
    //     text: "Retained Earning",
    //     component: <RetainedEarning></RetainedEarning>,
    //     // btnComponent: <div className="sub_btn_number">90000</div>

    // },
    {
      text: "Manager Recovery",
      component: <ManagerRecovery></ManagerRecovery>,
      rolename: "Manager Recovery",
    },
    {
      text: "Recovery Receipt",
      component: <RecoveryReceiptReport></RecoveryReceiptReport>,
      rolename: "Recovery Receipt",
    },
    {
      text: "Account Payable",
      component: <AccountPayable></AccountPayable>,
      rolename: "Account Payable",
    },
    {
      text: "Account Receivable",
      component: <AccountReceivable></AccountReceivable>,
      rolename: "Account Receivable",
    },
    {
      text: "Install Receipt",
      component: <InstallmentReceipt></InstallmentReceipt>,
      rolename: "Install Receipt",
    },
    {
      text: "Install Payment",
      component: <InstallmentPayment></InstallmentPayment>,
      rolename: "Install Payment",
    },

    {
      text: "Commission Payment",
      component: <CommissionPayment />,
      rolename: "Commission Pay Bought",
    },
    {
      text: "Commission Receipt",
      component: <CommissionReceipt />,
      rolename: "Commission Rec Bought",
    },
    {
      text: "Admin Share Payable",
      component: <AdminSharePayableReport />,
      rolename: "Admin Share Payable",
    },
    {
      text: "Reserved Commission Payable",
      component: <ReserveCommissionPayableReport />,
      rolename: "Reserved Commission Payable",
    },
    {
      text: "Project Report",
      component: <ProjectReport></ProjectReport>,
      rolename: "Projects Report",
    },
  ];

  const Loan =
    `${localStorage.getItem("ProjectName")}` !== "HEAD OFFICE"
      ? [
          {
            text: "Investor",
            component: <MainAddInvestor></MainAddInvestor>,
            rolename: "Investor",
          },
          {
            text: "Investment",
            component: <MainInvestment></MainInvestment>,
            rolename: "Add Investment",
          },
        ]
      : null;

  // buttons state start
  const [statesubbutton, setsubbutton] = useState("");

  const [chosen1, setChosen1] = useState("Dashboard");
  const [viewcomponent, setview] = useState(<AccDashboard />);
  // subbutton
  const [subchosen2, setsubchosen2] = useState({
    text: "Dashboard",
    icon: (
      <img
        src={dashboardPic}
        alt="Dashboard"
        className="ml-3 Icondashboard"
      />
    ),
    rolename: "Dashboard",
    subbtn: Dashboard,
  });
  const [Menu] = useState([
    {
      text: "Dashboard",
      icon: (
        <img
          src={dashboardPic}
          alt="Dashboard"
          className="ml-3 Icondashboard"
        />
      ),
      rolename: "Dashboard",
      subbtn: Dashboard,
    },
    {
      text: "Vouchers",
      icon: (
        <img
          src={payablePic}
          alt="Vouchers"
          className="ml-3 Iconpayable"
        />
      ),
      rolename: "Voucher",
      subbtn: Voucher,
    },

    {
      text: "Contract",
      icon: (
        <img
          src={ledgerPic}
          alt="Ledger"
          className="ml-3 Iconpayable"
        />
      ),
      rolename: "Contract",
      subbtn: Contracts,
    },
    {
      text: "Chart of Acc.",
      icon: (
        <img
          src={chartPic}
          alt="Chart"
          className="ml-3 Iconpayable"
        />
      ),
      rolename: "Chart of Account",
      subbtn: Chart,
    },
    {
      text: "Tax",
      icon: (
        <img
          src={taxPic}
          alt="Tax"
          className="ml-3 Icontax"
        />
      ),
      rolename: "Tax",
      subbtn: Tax,
    },
    {
      text: "Investment",
      icon: (
        <img
          src={loanPic}
          alt="Investment"
          className="ml-3 Iconloan"
        />
      ),
      rolename: "Investment",
      subbtn: Loan,
    },
    {
      text: "Reports",
      icon: (
        <img
          src={reportPic}
          alt="Reports"
          className="ml-3 Iconpayable"
        />
      ),
      rolename: "Reports",
      subbtn: Reports,
    },
  ]);
  // buttons state end
  useEffect(() => {
    dispatch(getallRoles());
  }, []);
  const handleDraw = () => {
    openDrawer();
  };
  const handlecloseDra = () => {
    closeDrawer();
  };

  // document.querySelector('.drawer-backdrop').addEventListener('click', function() {
  //   closeDrawer();
  // });

  function openDrawer() {
    document.getElementById("myDrawer").style.right = "0";
    document.querySelector(".drawer-backdrop").style.opacity = "1";
    document.querySelector(".drawer-backdrop").style.pointerEvents = "auto";
  }

  function closeDrawer() {
    document.getElementById("myDrawer").style.right = "-400px";
    document.querySelector(".drawer-backdrop").style.opacity = "0";
    document.querySelector(".drawer-backdrop").style.pointerEvents = "none";
  }
  console.log(notificationRow);
  const handleMarkRead = () => {
    setloading(true);
    axios({
      url: `${window.$url}Vouchers/MarkNotification?id=${notificationRow?.NotificationsId}`,
      method: "Post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    })
      .then((res) => {
        if (res.data.IsSuccess === true) {
          setloading(false);
          getAllNotification();
          setshowNotificationModal(false);
          setnotificationRow(null);
          toast.info(res.data.Message);
        } else {
          setloading(false);

          toast.info(res.data.Message);
        }
      })
      .catch((err) => {
        setloading(false);

        toast.info("Something went wrong");
      });
  };
  return (
    <>
      {/* top name bar */}
      <ToastContainer
        theme={"dark"}
        autoClose={2000}
      />
      <div className="row">
        {/* toggle button */}

        <div
          className="col-auto p-0 sidebuttonsheight main_btn_style"
          id="tog"
          style={{ width: "13em " }}
        >
          <div
            onClick={() => {
              toggleClose();
            }}
          >
            {" "}
            <img
              src={dotpic}
              alt="dots"
              className="ml-3 Icongroup"
            />
          </div>

          <div className="line_styling">
            <hr></hr>
          </div>

          <div className=" m_top_custom  pr-3 ">
            {/* buttons map function */}
            {Menu.map((btn, index) =>
              (rolesdata?.data?.Roles &&
                rolesdata?.data?.Roles[btn.rolename] !== undefined) ||
              localStorage.getItem("Designation") === "Admin" ? (
                <SideButton
                  key={index}
                  text={btn.text}
                  icon={btn.icon}
                  onClick={() => {
                    if (showAlert) {
                      changeState(true);
                    }

                    setChosen1(btn.text);
                    // CompanyClose();
                    setsubchosen2(btn);
                    setview(
                      btn.subbtn !== null ? btn.subbtn[0].component : null
                    );
                    setsubbutton(
                      btn.subbtn !== null ? btn.subbtn[0].text : null
                    );
                    setreload(!reload);
                  }}
                  active={btn.text === chosen1}
                />
              ) : null
            )}
          </div>
          <h4
            title={localStorage.getItem("ProjectName")}
            className="toggleButton cur_sor green_text projectName"
          >
            {localStorage.getItem("ProjectName")}{" "}
          </h4>
        </div>

        <div className="col">
          {/* top nav bar start */}

          <div className="row pt-1">
            <div className="col-auto pt-1">
              <span className="name_heading mt-1 green_text ">
                {localStorage.getItem("UserName")}
              </span>
              <br></br>
              <span className="designation_head green_text ">
                {localStorage.getItem("Designation")}
              </span>
            </div>
            <div className="col  pt-1">
              <div className="row pl-md-5 pl-lg-5 ">
                <div className="col-10 nav-space">
                  {rolesdata?.data?.Roles?.Admin &&
                  `${localStorage.getItem("Designation")}` === "Admin" ? (
                    <>
                      {" "}
                      <Link to="/projectSelection">
                        {" "}
                        <button className="top_menu_btn-select ">
                          Accounts
                        </button>
                      </Link>
                      <Link to="/inventory">
                        {" "}
                        <button className="top_menu_btn blue_text">
                          Inventory
                        </button>
                      </Link>
                      {localStorage.getItem("UserName") !== "Demo" && (
                        <>
                          <Link to="/hr">
                            {" "}
                            <button className="top_menu_btn blue_text">
                              Human Resource
                            </button>
                          </Link>
                          <Link to="/crm">
                            {" "}
                            <button className="top_menu_btn blue_text ">
                              CRM
                            </button>
                          </Link>
                          <Link to="/assets">
                            {" "}
                            <button className="top_menu_btn blue_text">
                              Assets
                            </button>
                          </Link>
                        </>
                      )}
                    </>
                  ) : null}
                  {rolesdata?.data?.Roles &&
                  rolesdata?.data?.Roles["Accounts"] !== undefined ? (
                    <Link to="/projectSelection">
                      {" "}
                      <button className="top_menu_btn-select ">Accounts</button>
                    </Link>
                  ) : null}
                  {rolesdata?.data?.Roles &&
                  rolesdata?.data?.Roles["Inventory"] !== undefined ? (
                    <Link to="/inventory">
                      {" "}
                      <button className="top_menu_btn blue_text">
                        Inventory
                      </button>
                    </Link>
                  ) : null}
                  {rolesdata?.data?.Roles &&
                  rolesdata?.data?.Roles["HR"] !== undefined ? (
                    <Link to="/hr">
                      {" "}
                      <button className="top_menu_btn blue_text">
                        Human Resource
                      </button>
                    </Link>
                  ) : null}
                  {rolesdata?.data?.Roles &&
                  rolesdata?.data?.Roles["CRM"] !== undefined ? (
                    <Link to="/crm">
                      {" "}
                      <button className="top_menu_btn blue_text">CRM</button>
                    </Link>
                  ) : null}
                  {rolesdata?.data?.Roles &&
                  rolesdata?.data?.Roles["Asset Module"] !== undefined ? (
                    <Link to="/assets">
                      {" "}
                      <button className="top_menu_btn blue_text">Assets</button>
                    </Link>
                  ) : null}

                  {/* <Link to="/account" className="link_class">  <button className="top_menu_btn-select ">Accounts</button></Link>


                                    <Link to="/inventory" className="link_class">  <button className="top_menu_btn blue_text ">Inventory</button></Link>

                                    <Link to="/hr" className="link_class"><button className="top_menu_btn blue_text  ">Human Resource</button></Link>

                                    <Link to="/crm">  <button className="top_menu_btn blue_text  ">CRM</button></Link>

                                    <Link to="/assets" className="link_class"> <button className="top_menu_btn blue_text  ">Assets</button></Link> */}
                </div>
                <div className=" col-md-2  ">
                  <span className="right_nav">
                    <span className=" mt-1 mr-1">
                      <Dropdown
                        onClick={handleDraw}
                        id="openDrawerBtn"
                      >
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="accountBtn p-0"
                        >
                          <img
                            src={bellPic}
                            alt="bell"
                            className="ml-3 Icontaxbell"
                          />
                          <span class="badge badge-pill badge-primary">
                            {allNotification.length}
                          </span>
                        </Dropdown.Toggle>
                        {/* <Dropdown.Menu
                          style={{
                            width: "460px",
                            maxHeight: "50vh",
                            overflow: "auto",
                          }}
                        >
                          <Dropdown.Header>
                            <h5>Notifications ({allData.length})</h5>
                            <hr />
                          </Dropdown.Header>
                          {allData?.map((item, idx) => {
                            return (
                              <Dropdown.ItemText style={{ display: "flex" }}>
                                <small style={{ width: "85%" }}>
                                  {" "}
                                  Please check {item.Description} in{" "}
                                  {item.EntryId}
                                </small>
                                <button
                                  onClick={() => {
                                    axios({
                                      // url: `${window.$url}Lenders/AddLender`,
                                      url: `${window.$url}RevertBack/Execute`,
                                      method: "Post",
                                      headers: {
                                        Authorization: `Bearer ${localStorage.getItem(
                                          "token"
                                        )}`,
                                        "content-type": "application/json",
                                      },
                                      data: JSON.stringify({
                                        RevertBackId: item.RevertBackId,
                                        ExecuteionDate:
                                          moment().format("YYYY-MM-DD"),
                                      }),
                                    })
                                      .then((res) => {
                                        if (res.data.IsSuccess === true) {
                                          toast.info(res.data.message);
                                          dispatch(
                                            getAllRevertUserVouchers(
                                              localStorage.getItem("LoginId")
                                            )
                                          );
                                        } else {
                                          toast.info(res.data.message);
                                        }
                                      })
                                      .catch((err) => {
                                        toast.info("Something went wrong");
                                      });
                                  }}
                                  className="save_btn"
                                  style={{ width: "15%", height: "25px" }}
                                >
                                  <small>Excecute</small>
                                </button>
                                <hr />
                              </Dropdown.ItemText>
                            );
                          })}
                        </Dropdown.Menu> */}
                      </Dropdown>
                    </span>
                    <span className=" ml-2">
                      <img
                        className="imageCircle"
                        src={companyInfo?.data?.Logo}
                        alt="Company logo"
                        // src={profilePic}
                        // alt="profile"
                      />
                    </span>
                    <span>
                      <Dropdown className="accountBtn  drop_icon_style">
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="accountBtn p-0"
                        >
                          <RiArrowDropDownLine className=" drop_icon_style" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              localStorage.clear();
                              sessionStorage.clear();
                              history.push({ pathname: "/" });
                            }}
                          >
                            {"  "}
                            Logout
                          </Dropdown.Item>

                          {/* {localStorage.getItem("UserName") === "Admin" && (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setview(<MainAddRoleCom />);
                                  setChosen1("");
                                }}
                              >
                                {"  "}
                                Role
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  setview(<RevertBackVouchers />);
                                  setChosen1("");
                                }}
                              >
                                {"  "}
                                Revert Back Vouchers
                              </Dropdown.Item>
                            </>
                          )} */}
                          <Dropdown.Item
                            onClick={() => {
                              setview(<Changeform />);
                              setChosen1("");
                            }}
                          >
                            {"  "}
                            Change Request
                          </Dropdown.Item>
                          {/* <Dropdown.Item
                            onClick={() => {
                              setview(<ChangeInReqMain />);
                              setChosen1("");
                            }}
                          >
                            {"  "}
                            Change Request 2
                          </Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-auto subbuttonheight pl-2 pr-2">
              {subchosen2.subbtn !== undefined && subchosen2.subbtn !== null
                ? subchosen2.subbtn.map((btn, index) =>
                    btn !== null && btn.text !== undefined ? (
                      (rolesdata?.data?.Roles &&
                        rolesdata?.data?.Roles[btn.rolename] !== undefined) ||
                      localStorage.getItem("Designation") === "Admin" ? (
                        <SideSubButton
                          key={index}
                          text={btn.text}
                          btnComponent={btn.btnComponent}
                          onClick={() => {
                            if (showAlert) {
                              changeState(true);
                            }

                            setsubbutton(btn.text);
                            setview(btn.component);
                            setreload(!reload);
                          }}
                          active={btn.text === statesubbutton}
                        />
                      ) : null
                    ) : null
                  )
                : null}
            </div>
            <div className="col overflow-auto viewcompmg">
              {/* component rendering */}
              {viewcomponent}
            </div>
          </div>
        </div>
        {/* top nav bar end */}
      </div>
      <AltertModal />
      <div
        style={{}}
        class="custom-drawer"
        id="myDrawer"
      >
        <div class="drawer-content">
          <div className="drawerHeader">
            <span style={{ fontSize: "20px" }}>
              <b>Notifications</b>
            </span>
            <span>
              <AiOutlineCloseCircle
                onClick={closeDrawer}
                style={{ cursor: "pointer" }}
                size={20}
              />
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              paddingTop: "10px",
            }}
          >
            {allNotification?.map((noti, i) => {
              return (
                <div
                  className="drawerContentBox"
                  key={i}
                >
                  <span>{noti?.Narration}</span>
                  <span>
                    <MdVisibility
                      title="see details"
                      onClick={() => {
                        setnotificationRow(noti);
                        closeDrawer();
                        setshowNotificationModal(true);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div class="drawer-backdrop"></div>
      {showNotificationModal && (
        <div
          id="mymodal"
          style={{ display: "block" }}
          class="modal"
        >
          <div
            style={{ maxWidth: "32%", height: "31%" }}
            class="modal-content-cat_voucher"
          >
            <div className="row">
              <div className="col-12  page_heading">
                <h4 className="  text-center pt-2">Details</h4>
                <span
                  class="close pr-2  "
                  onClick={() => {
                    setshowNotificationModal(false);
                  }}
                >
                  &times;
                </span>
              </div>
            </div>

            <div className="row p-4">
              <div className="col-6 py-2">
                <span>
                  <b>Voucher No:</b>{" "}
                </span>
                <span>{notificationRow?.GeneralVoucherId}</span>
              </div>
              <div className="col-6 py-2">
                <span>
                  <b>Date:</b>{" "}
                </span>
                <span>
                  {moment(notificationRow?.Date).format("DD-MMM-YYYY")}
                </span>
              </div>
              <div className="col-6 py-2">
                <span>
                  <b>Amount:</b>{" "}
                </span>
                <span>{notificationRow?.Amount}</span>
              </div>
              <div className="col-6 py-2">
                <span>
                  <b>Status:</b>{" "}
                </span>
                <span>{notificationRow?.Status}</span>
              </div>
              <div className="col-6 py-2">
                <span>
                  <b>User:</b>{" "}
                </span>
                <span> {notificationRow?.AddedBy?.UserName}</span>
              </div>
              <div className="col-12  py-2">
                <span>
                  <b>Narration:</b>{" "}
                </span>
                <span>{notificationRow?.Narration}</span>
              </div>
              <div
                style={{ display: "flex", gap: "10px", justifyContent: "end" }}
                className="col-12 text-right"
              >
                {loading && (
                  <Loader
                    type="Oval"
                    color="black"
                    height={30}
                    width={30}
                    radius={12}
                  />
                )}
                <button
                  className="print_btn float-right"
                  disabled={loading}
                  onClick={() => {
                    handleMarkRead();
                  }}
                >
                  {/* <TiPrinter size="1.3em" /> */}
                  Mark as read
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default AccountMainPage;

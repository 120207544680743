import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";

export const GetAllExternalCommission = (data, loading) => {
  return {
    type: "get_allgetexternalcommission",
    payload: data,
    loading: loading,
  };
};

export function getAllExternalCommission(
  clearData,
  commType,
  fromDate,
  toDate
) {
  return (dispatch) => {
    let pgurl = `${window.$url}Commission/ExternalCommissionGetAll`;

    if (clearData) {
      dispatch(GetAllExternalCommission([], false));
    } else {
      dispatch(GetAllExternalCommission([], true));
      axios({
        url: pgurl,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
        data: JSON.stringify(
          fromDate && toDate
            ? {
                From: moment(fromDate).format("YYYY-MM-DD"),
                To: moment(toDate).format("YYYY-MM-DD"),
                ProjectId: localStorage.getItem("ProjectId")
                  ? localStorage.getItem("ProjectId")
                  : null,
                commissiontype: commType,
              }
            : {
                ProjectId: localStorage.getItem("ProjectId")
                  ? localStorage.getItem("ProjectId")
                  : null,
                commissiontype: commType,
              }
        ),
      }).then(
        (result) => {
          if (result.data.IsSuccess === true) {
            dispatch(GetAllExternalCommission(result.data.Data, false));
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          dispatch(GetAllExternalCommission([], false));
          toast.info("Something went wrong!");
        }
      );
    }
  };
}

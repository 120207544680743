import axios from 'axios';
import { toast } from "react-toastify";
export const GetAllUnSubmittedVouchers = (data) => {

    return ({
        type: "get_unsubmittedVouch",
        payload: data,
        loader: false
    })
}
export function getUnSubmittedVouchers() {
    return (dispatch) => {
        axios({
            url: `${window.$url}Vouchers/Unsubmitted/GetAll?ProjectId=${localStorage.getItem("ProjectId")}`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {

                if (result.data.IsSuccess === true) {
                    dispatch(GetAllUnSubmittedVouchers(result.data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Loader from 'react-loader-spinner';
import { toast, ToastContainer } from "react-toastify";

const ChangeInReqForm=({setShowForm})=> {
    const [loader1, setloader1] = useState(false);
    const [userdata, setuserdata] = useState({ 
        google_docs:"",
        description:"",
        priority:"",
        project:"",
        module:"",
        submodule:""
       });
       const [allProjects,setAllProjects]=useState([])
       const [Module,setModule]=useState([])
       const [ProjectSubModule,setProjectSubModule]=useState([])
       const objectFormation = (e) => {
        // const { name, value } = e.target;
        setuserdata({
            ...userdata,
            [e.target.name]:e.target.value,
        });
      };
      const addUserData = (e) => {
        e.preventDefault()  
        let size = Object.keys(userdata).length;
        if (size < 6) {
            toast.info("Please fill all the fields!");
        } else {
            setloader1(true)
          axios
          .post("http://internalhrapi.aiksol.com/api/newchangereqdata", userdata)
          .then( (response) => {
              setloader1(false)
setShowForm(false)
              setuserdata({});
          toast.info("Change Request Submitted");


        }).catch((err) => {
          toast.info("Something Went Wrong");

            });
        }
      };
      const getProject=()=>{
        axios
              .get(`http://internalhrapi.aiksol.com/api/requestpopulate`)
              .then(function (response) {
                setAllProjects(response.data.Projects)
              })
              .catch((err) => {
                console.log(err);
    
              });
          };
    
    useEffect(()=>{
        getProject()
    },[])

  return (
    <>

<div className="bg-white changeform" style={{display:"flex",flexDirection:"column",}}>
      <div className="row" style={{paddingLeft:"100px"}}>
        
        <div class="col" >
        <div >
          <label className="input_label m-0">Description</label>
        </div>
          <input style={{maxWidth:"300px"}}
            name='description'
            type="text"
            value={userdata.description} 
            onChange={objectFormation}
            id="Description"
            className="input_styling"
            // defaultValue={moment().format("YYYY-MM-DD")}
          ></input>
        </div>

        <div class="col">
        <div>
          <label className="input_label m-0">Priority-Cell</label>
        </div>
        <select style={{maxWidth:"300px"}}  className="input_styling" id="Priority" name='priority' value={userdata.priority} onChange={objectFormation}>
            <option value="">select </option>
            <option value="Medium">Medium</option>
            <option value="Critical">Critical</option>
            <option value="High">High</option>
            <option value="Low">Low</option>
          </select>
        </div>



      </div>
      <div className="row" style={{paddingLeft:"100px"}}>
 
        <div class="col">
        <div >
          <label >Google-Docs</label>
        </div>
        <input style={{maxWidth:"300px"}} 
                name='google_docs' value={userdata.google_docs} onChange={objectFormation}
                id="GoogleDocs"
                type="text"
                className="input_styling"
              ></input>
        </div>
        
        
        <div class="col">
          <div><label className="input_label m-0">Project-Name</label></div>
        <select style={{maxWidth:"300px"}}  name='project' id="ProjectName"   className="input_styling" value={userdata.project} onChange={(e)=>{
const filterModule=allProjects?.find((projct)=>{
return projct.project_id===e.target.value
})
setModule(filterModule.module)
userdata.project=e.target.value
          }} >
            <option value="">select </option>
            {allProjects&&allProjects?.map((project)=>(

            <option key={project?.project_id} value={project?.project_id}>{project?.project_name}</option>
            ))}
   
          </select>
          <span className="estaric">*</span>
        </div>

        </div>


        <div className="row" style={{paddingLeft:"100px"}}>

        <div class="col">
        <div >
          <label className="input_label m-0">Project-Module</label>
        </div>
        <select style={{maxWidth:"300px"}} name='module' id="Module"  className="input_styling"  value={userdata.module} onChange={(evt)=>{
            const SubModuleFilter = Module?.find((mode) => {

                return mode.module_id === evt.target.value;
              });
              setProjectSubModule(SubModuleFilter.submodule);
              userdata.module=evt.target.value

          }} >
            <option value="">select </option>
            {
                Module?.map((mod)=>(
                    
                    <option key={mod?.module_id} value={mod?.module_id}>{mod?.module_name}</option>
                ))
            }
   
          </select>
        </div>
        <div class="col">
        <div >
          <label className="input_label m-0">Project-SubModule</label>
        </div>
        <select style={{maxWidth:"300px"}} name='submodule' id="SubModule"  className="input_styling"   value={userdata.submodule} onChange={objectFormation}  >
            <option value="">select </option>
            {ProjectSubModule?.map((submod) => (
                
            <option  key={submod?.submodule_id} value={submod?.submodule_id}>{submod?.submodule_name}</option>
                    ))}
         
          </select>
        </div>
        </div>
      
      {/* <div className="row ">
        <div className="col-md-1 ">
          <label className="input_label m-0">Description</label>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mt-md-2">
          <textarea
            id="changeformdescription"
            className="input_address  input_desc"
            placeholder="Enter Description"
          ></textarea>
          <span className="estaric">*</span>
        </div>
      </div> */}
      <div className="row" style={{paddingRight:"160px"}}>

        <div className="col-md-12 mt-md-2 text-right" style={{display:"flex",gap:"10px",justifyContent:"end"}}>
        {loader1 && (
                <Loader
                  type="Oval"
                  color="black"
                  height={30}
                  width={30}
                  radius={12}
                />
              )}
          <button onClick={addUserData} className="save_btn ">
            Submit
          </button>
          <button onClick={()=>{
                    setShowForm(false);

          }} className="save_btn ">
            Cancel
          </button>
        </div>
      </div>
      </div>
      <ToastContainer theme="dark" autoClose={2000} />
    </>
  )
}

export default ChangeInReqForm;
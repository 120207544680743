import React, { useEffect, useState, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { getallTaxPayableVoucherTable, GetAllTaxPaybleVoucher } from "../../../actions/accountsactions/taxpayable";
import axios from "axios";
import { FiEdit2 } from "react-icons/fi";
import Loader from "react-loader-spinner";
import { useReactToPrint } from "react-to-print";
import { TiPrinter } from "react-icons/ti";
import { toast } from "react-toastify";
import moment from "moment";
import TaxPaidPrint from "../TaxPrints/TaxPaidPrint";

const TaxPaid = () => {
    const payableVouchersData = useSelector(state => state.getAllTaxPayableVoucher);
    const dispatch = useDispatch();

    const [TaxPayableVoucherId, setTaxPayableVoucherId] = useState();


    useEffect(() => {

        let data = {}
        dispatch(getallTaxPayableVoucherTable(data));



    }, [dispatch]);
    function ongo() {
        let data = {
            ProjectId: `${localStorage.getItem("ProjectId")}`,
            From: document.getElementById("fromdate").value,
            To: document.getElementById("todate").value

        }

        dispatch(getallTaxPayableVoucherTable(data));

    }
    const AllcomponentRef = useRef();

    const AllprintPaymentRec = useReactToPrint({
        content: () => AllcomponentRef.current,
    });
    //Table Data fiter

    var suggestionlist = [];

    const onTextChange = (e) => {
        const value = e.target.value;
        if (value === "") {

            dispatch(getallTaxPayableVoucherTable({}));
        } else {
            let suggestions = [];
            if (value.length > 0) {
                const regex = new RegExp(`${value}`, "i");

                suggestions = payableVouchersData.data
                    .sort()
                    .filter((val) =>
                        (regex.test(val.TaxPayableVoucherId) || regex.test(val.Cprnumber)));

            }
            suggestionlist = suggestions;
            dispatch(GetAllTaxPaybleVoucher(suggestionlist));
        }
    };
    function showmodal(data) {
        document.getElementById("mymodal").style.display = "block";

    }
    function closemodal() {
        document.getElementById("mymodal").style.display = "none";

    }
    function savedata() {
        if (document.getElementById("date").value == "") {
            toast.info("Enter Date")
        } else if (document.getElementById("cprno").value === "") {
            toast.info("Enter Cpr")
        } else {
            let Voucher = {
                TaxPayableVoucherId: TaxPayableVoucherId,


                Cprdate: document.getElementById("date").value,

                Cprnumber: document.getElementById("cprno").value,

            };



            document.getElementById("savedata").disabled = true;
            axios({
                url: `${window.$url}Vouchers/TaxPayables/Update`,
                method: "post",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "content-type": "application/json",
                },
                data: JSON.stringify(
                    Voucher

                ),
            }).then(
                (result) => {
                    document.getElementById("savedata").disabled = false;
                    if (result.data.IsSuccess === true) {


                        toast.info(result.data.Message);
                        document.getElementById("date").value = "";
                        document.getElementById("cprno").value = "";
                        setTaxPayableVoucherId();
                        // props.canceltaxtable();
                        dispatch(getallTaxPayableVoucherTable({}));

                    } else {
                        toast.info(result.data.Message);
                    }
                },
                (error) => {
                    document.getElementById("savedata").disabled = false;
                    toast.info(
                        "Something went wrong!"
                    )
                }

            );

        }
    }
    return (
        <>
            <div hidden>
                <TaxPaidPrint
                    ref={AllcomponentRef}

                    printData={payableVouchersData}

                />
            </div>

            <div className="row  ">

                <div className="dash_background col-12 ">
                    <div className="row ">
                        <div className="col-md-12   text-left page_heading">
                            <h4 className="m-0">Tax Paid</h4>
                        </div>
                    </div>
                    <div className="row pt-3 ">
                        <div className="col-md-1 pr-0 pl-0">


                            <label className="input_label  m-0">Search:</label>
                        </div>
                        <div className="col-md-3   pr-0 pl-0">


                            <input type="text " className="input_styling " placeholder="Voucher/Cprc" onChange={onTextChange}></input>
                        </div>

                        <div className="col-md-3 text-center  pr-0 pl-0">
                            <label className="input_label m-0">From:</label>
                            <input type="date" className="input_date ml-3" id="fromdate"></input>
                        </div>
                        <div className="col-md-3 text-center pr-0 pl-0">
                            <label className="input_label m-0">To:</label>
                            <input type="date" className="input_date ml-3" id="todate"></input>
                        </div>
                        <div className="col-md-1 pr-0 pl-0">
                            <button className="btn_Go" onClick={ongo}>Go</button>
                        </div>
                        <div className="col-md-1 pr-0 pl-0">
                            {payableVouchersData.loading ? <Loader type="ThreeDots"
                                color="green"
                                height={40}
                                width={40} /> : null}
                        </div>
                    </div>
                    <div className="row ">
                        <div className=" col-12  mt-2 table_height pr-0 pl-0">
                            <div className="table-responsive ">
                                <table className="table table-borderless m-0">
                                    <thead>
                                        <tr>
                                            <th>Sr</th>
                                            <th>EntryDate</th>
                                            <th>Voucher No</th>
                                            <th>CPR Date</th>

                                            <th>CPR Number</th>


                                            <th>Pay Type</th>
                                            <th>Narration</th>
                                            <th>Amount</th>
                                            <th>Edit </th>




                                        </tr>
                                    </thead>
                                    <tbody>
                                        {payableVouchersData.data.map((val, index) => (

                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{moment(val.EntryDate.split("T")[0]).format("DD-MMM-YYYY")}</td>
                                                <td>{val.TaxPayableVoucherId}</td>
                                                <td>{val.Cprdate !== null ? moment(val.Cprdate.split("T")[0]).format("DD-MMM-YYYY") : null}</td>
                                                <td>{val.Cprnumber}</td>


                                                <td>{val.PaymentType}</td>
                                                <td>{val.Narration}</td>
                                                <td>{val.Amount.toLocaleString()}</td>

                                                <td className="" onClick={() => { showmodal(val); setTaxPayableVoucherId(val.TaxPayableVoucherId); }}>
                                                    <FiEdit2 size="1em" />
                                                </td>



                                            </tr>
                                        ))}



                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-6  text-right pr-0 pl-0">
                            <button className="print_btn float-left" onClick={() => { AllprintPaymentRec(); }}><TiPrinter size="1.3em" />Print</button>


                        </div>
                    </div>
                </div>
            </div>
            <div id="mymodal" className="modal">

                <div className="modal-content-cat">
                    <div className="row">
                        <div className="col-12  page_heading">
                            <h4 className="  text-center pt-2">CPR Detail</h4>
                            <span
                                class="close pr-2  "
                                onClick={closemodal
                                }
                            >
                                &times;
                            </span>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 mt-4">


                            <input type="date" id="date" className="input_date_styling "></input><span className="estaric">*</span>

                        </div>
                        <div className="col-6 mt-4">
                            <input type="text" id="cprno" className="input_styling  "></input><span className="estaric">*</span>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-11 mt-4 text-right">
                            <button
                                className=" save_btn   " id="savedata"
                                onClick={() => {

                                    closemodal();
                                    savedata();


                                }}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default TaxPaid;
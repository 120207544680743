import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getallProject } from "../../../actions/getprojects";
import { getallsellers } from "../../../actions/inventoryactions/addseller";

export const DealBought = (props) => {
    const resultsallproject = useSelector((state) => state.getProject);
    const resultallsellers = useSelector(state => state.getAllSellers);
    const [stateprojectid, setprojectid] = useState(0);

    const dispatch = useDispatch();
    const [state, setstate] = useState({
        DealName: null,
        TotalAmount: null,
        DealAmount: null,
        DealAmountPer: null,
        ProjectId: 0,
        AmountPaid: 0,
        AmountUsed: 0,
        SellerId: 0,
        CommissionPer: null,
        ProfitMarginPer: null

    });
    const savedata = () => {
        if (document.getElementById("project").selectedIndex === 0 || document.getElementById("seller").selectedIndex === 0) {
            toast.info("Select Project and Seller");
        }
        else if (
            document.getElementById("DealName").value === ""
        ) {
            toast.info("Enter Deal Name");
        }
        else if (
            document.getElementById("TotalAmount").value === ""
        ) {
            toast.info("Enter Total Amount");
        }
        else if (
            document.getElementById("DealAmount").value === ""
        ) {
            toast.info("Enter Deal Amount");
        }
        else if (
            parseFloat(document.getElementById("DealAmount").value.replace(/,/g, '')) > parseFloat(document.getElementById("TotalAmount").value.replace(/,/g, ''))
        ) {
            toast.info("Deal Amount  should be less or equal  to total");
        }

        else if (
            document.getElementById("DealAmountper").value > 100
        ) {
            toast.info("Deal Amount Percentage should be in between 0 to 100 ");
        }
        else if (
            document.getElementById("commission").value > 100
        ) {
            toast.info("Commission Percentage should be in between 0 to 100 ");
        }
        else if (
            document.getElementById("profitmargin").value > 100
        ) {
            toast.info("Profit Margin Percentage should be in between 0 to 100 ");
        }

        else {
            let data = state;
            data.ProjectId = parseInt(document.getElementById("project").options[document.getElementById("project").selectedIndex].dataset.id);
            data.SellerId = parseInt(document.getElementById("seller").options[document.getElementById("seller").selectedIndex].dataset.id);
            data.DealName = document.getElementById("DealName").value;
            data.TotalAmount = parseFloat(document.getElementById("TotalAmount").value.replace(/,/g, ''));

            data.DealAmount = parseFloat(document.getElementById("DealAmount").value.replace(/,/g, ''));
            data.DealAmountPer = parseFloat(document.getElementById("DealAmountper").value.replace(/,/g, ''));
            data.AmountUsed = 0;
            data.AmountPaid = 0;
            data.CommissionPer = parseFloat(document.getElementById("commission").value);
            data.ProfitMarginPer = parseFloat(document.getElementById("profitmargin").value);



            document.getElementById("projectsave").disabled = true;

            axios({
                method: "post",
                url: `${window.$url}Deals/Bought/Add`,
                data: JSON.stringify(data),
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "content-type": "application/json",
                },
            }).then(
                (result) => {
                    document.getElementById("projectsave").disabled = false;
                    if (result.data.IsSuccess === true) {

                        document.getElementById("project").selectedIndex = 0;
                        document.getElementById("seller").selectedIndex = 0;
                        document.getElementById("DealAmountper").value = "";
                        document.getElementById("DealAmount").value = "";
                        document.getElementById("TotalAmount").value = "";
                        document.getElementById("DealName").value = "";
                        document.getElementById("commission").value = "";
                        document.getElementById("profitmargin").value = "";
                        setprojectid(0);
                        setstate({
                            DealName: null,
                            TotalAmount: null,
                            DealAmount: null,
                            DealAmountPer: null,
                            ProjectId: 0,
                            AmountPaid: 0,
                            AmountUsed: 0,
                            SellerId: 0


                        });
                        toast.info(result.data.Message);
                    } else {
                        toast.info(result.data.Message);
                    }


                },
                (error) => {
                    document.getElementById("projectsave").disabled = false;



                }
            );


        }
    }
    useEffect(() => {
        dispatch(getallProject());
        dispatch(getallsellers())


    }, [dispatch]
    )
    return (
        <>
            <div className="row ">
                <div className="col-md-12  text-left page_heading">
                    <h4 className="m-0">Deal Bought</h4>
                </div>
            </div>

            <div className="row">
                <div className="col-md-2 mt-4 text-left p-0">
                    <label className="input_label m-0 " >Project Name:</label>
                </div>
                <div className="col-md-3 mt-4  p-0">
                    <select className="input_styling ml-md-3" id="project" onChange={() => { setprojectid(document.getElementById("project").options[document.getElementById("project").selectedIndex].dataset.id) }}>
                        <option>Select</option>
                        {
                            resultsallproject.data.map((val, index) => (
                                val.ProjectOwner === "OTHER" ? <option data-id={val.ProjectId}>{val.ProjectName}</option> : null
                            ))
                        }
                    </select><span className="estaric">*</span>
                </div>
                <div className="col-md-2 mt-4  p-0">
                </div>
                <div className="col-md-2 mt-4 text-left p-0">
                    <label className="input_label m-0">Seller:</label>
                </div>
                <div className="col-md-3 mt-4   p-0">

                    <select className="input_styling ml-md-3" id="seller" >
                        <option>Select</option>
                        {resultallsellers.data.map((val, index) => (
                            val.ProjectSellers.map((x) => (parseInt(x.ProjectId) == parseInt(stateprojectid) ? <option data-id={val.SellerId}>{val.SellerName}</option> : null))
                        ))}
                    </select><span className="estaric">*</span>

                </div>

            </div>
            <div className="row">
                <div className="col-md-2 mt-4 text-left p-0">
                    <label className="input_label m-0 " >Deal Name:</label>
                </div>

                <div className="col-md-3 mt-4   p-0">
                    <input type="text" className="input_styling ml-md-3 " id="DealName" /><span className="estaric">*</span>

                </div>
                <div className="col-md-2 mt-4  p-0">
                </div>
                <div className="col-md-2 mt-4 text-left p-0">
                    <label className="input_label m-0">Total Amount:</label>
                </div>
                <div className="col-md-3 mt-4   p-0">

                    <input type="text" className="input_styling ml-md-3 " id="TotalAmount"
                        onChange={(e) => { if (e.target.value.length > 0) document.getElementById("TotalAmount").value = parseInt(e.target.value.replace(/,/g, '')).toLocaleString() }}
                    /><span className="estaric">*</span>

                </div>


            </div>
            <div className="row">

                <div className="col-md-2 mt-4 text-left p-0">
                    <label className="input_label m-0 " >Advance Amount:</label>
                </div>

                <div className="col-md-3 mt-4   p-0">
                    <input type="text" className="input_styling ml-md-3 " id="DealAmount"
                        onChange={(e) => {
                            if (e.target.value.length > 0) {
                                document.getElementById("DealAmountper").value = ((parseFloat(e.target.value.replace(/,/g, '')) / parseFloat(document.getElementById("TotalAmount").value.replace(/,/g, ''))) * 100).toLocaleString();
                                document.getElementById("DealAmount").value = parseInt(e.target.value.replace(/,/g, '')).toLocaleString();
                            }
                            else {
                                document.getElementById("DealAmount").value = null;
                                document.getElementById("DealAmountper").value = null;
                            }
                        }
                        }
                    /><span className="estaric">*</span>

                </div>
                <div className="col-md-2 mt-4  p-0">
                </div>
                <div className="col-md-2 mt-4 text-left p-0">
                    <label className="input_label m-0 " >Advance%:</label>
                </div>

                <div className="col-md-3 mt-4   p-0">
                    <input type="text" className="input_styling ml-md-3 " id="DealAmountper"
                        onChange={(e) => {
                            if (e.target.value.length > 0) {
                                document.getElementById("DealAmount").value = ((parseFloat(e.target.value.replace(/,/g, '')) / 100) * parseFloat(document.getElementById("TotalAmount").value.replace(/,/g, ''))).toLocaleString();
                            } else {
                                document.getElementById("DealAmount").value = null;
                                document.getElementById("DealAmountper").value = null;
                            }
                        }
                        } /><span className="estaric">*</span>

                </div>

            </div>
            <div className="row">

                <div className="col-md-2 mt-4 text-left p-0">
                    <label className="input_label m-0 " >Commission%:</label>
                </div>

                <div className="col-md-3 mt-4   p-0">
                    <input type="number" className="input_styling ml-md-3 " id="commission"

                    /><span className="estaric">*</span>

                </div>
                <div className="col-md-2 mt-4  p-0">
                </div>
                <div className="col-md-2 mt-4 text-left p-0">
                    <label className="input_label m-0 " >Additional Margin%:</label>
                </div>

                <div className="col-md-3 mt-4   p-0">
                    <input type="number" className="input_styling ml-md-3 " id="profitmargin"
                    /><span className="estaric">*</span>

                </div>

            </div>

            <div className="row pr-md-2 pt-md-5">
                <div className=" col-6 pr-0 pl-0  ">

                </div>
                <div className=" col-6 pr-0 pl-0  text-right ">

                    <span>
                        <button
                            className="cancel_btn ml-2  "
                            onClick={props.cancelprojecttable}
                        >

                            Cancel
                        </button>
                    </span>

                    <span>
                        <button className="save_btn ml-2  " id="projectsave" onClick={savedata}>
                            <BsCloudUpload size="1.3em" />
                            Save
                        </button>
                    </span>
                </div>
            </div>





        </>
    )
}
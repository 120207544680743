import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Loader from 'react-loader-spinner';
import { toast, ToastContainer } from "react-toastify";


const ChangeInClientStatus=({setShowForm,setShowupdateForm,filterCientStatus,edit,setedit,getdata})=> {
    const [loader1, setloader1] = useState(false);
    const date=new Date()
    
    const [isDisabled,setIsDisabled]=useState(false)
    const [clientData,setClientData]=useState({
        client_status:filterCientStatus?filterCientStatus.client_status:"",
        approval_date:date.toISOString().split('T')[0],
        rejection_date:date.toISOString().split('T')[0],
        amend_status:"pending",
        description:"",

    })
    let ret=false; 
    const handleChange = (e) => {
        if(e.target.value==="rejected"){
    document.getElementById("myModal1").style.display = "block";

        }
        setClientData({
            ...clientData,
            [e.target.name]:e.target.value,
        });
      };

      useEffect(()=>{
        if(filterCientStatus?.client_status==="approved"){
            setIsDisabled(true)
        }
      },[])
      const [statusUpdate]=useState([
        {
            statusValue:"Fresh",
            statusName:"Fresh",

        },
        {
            statusValue:"approved",
            statusName:"approved",

        },
        {
            statusValue:"rejected",
            statusName:"rejected",

        },
    ])
    const addClientStatus=()=>{
        setloader1(true)

        
            axios
            .patch("http://internalhrapi.aiksol.com/api/clientstatus",{request_id:filterCientStatus.request_id,client_status:clientData.client_status,approval_date:clientData.approval_date,timeline_id:filterCientStatus.req_timeline } )
            .then( (response) => {
                setloader1(false)
                getdata()
                setShowupdateForm("Table")
      toast.info("Client Status Be Approved");

                
          }).catch((err) => {
                setloader1(false)
      toast.info("Something went wrong");


              });
          
    
}
const addRejection=()=>{
  
    setloader1(true)
       
    axios
        .patch("http://internalhrapi.aiksol.com/api/clientstatus",{request_id:filterCientStatus.request_id,client_status:clientData.client_status,rejection_date:clientData.rejection_date,amend_status:clientData.amend_status,description:clientData.description } )
        .then( (response) => {
            setloader1(false)
            getdata()
            setShowupdateForm("Table")
      toast.info("Client Status Be Rejected");

            // setshowAlertModal(false)



      }).catch((err) => {
            console.log(err);
          });

}

  return (
    <>
       {
        edit?(
        <div>
       <div style={{paddingTop:"20px",paddingLeft:"10px"}} >
        <button  type="button" style={{width:"50px",height:"30px", backgroundColor:"green", color:"white",border:"none",borderRadius:"5px",}} onClick={()=>{setShowupdateForm("Table");setedit(false)}}>Back</button>
        </div>

{
        filterCientStatus?.Amendement?.length>0?(
        <div className="col-12 changeFormTable_height pr-2 pl-2" style={{height:"500px"}}>
          <div className="table-responsive mt-2">
            <table className="table table-borderless m-0">
              <thead>
              <tr>
                <th >
                    Sr.No
                  </th>
                  <th >
                  Date
                  </th>
                  <th >
                  Description
                  </th>
                  <th >
                  Status
                  </th>
                 
                </tr>
              </thead>
              <tbody>
              {filterCientStatus?.Amendement?.map((amendData,ind)=>{
  return (
        <tr>
    <td >{ind+1}</td>
    <td>{amendData?.rejection_date}</td>
    <td>{amendData?.description}</td>
    <td>{amendData?.amend_status}</td>
  </tr>
)
})}
                <tr></tr>
              </tbody>
            </table>
          </div>
        </div>
        ):""
}

        </div>
        ):(

            <div className="bg-white changeform" >
<div style={{display:"flex",justifyContent:"center"}} >

<div class="col" style={{maxWidth:"300px"}}   >
        <div style={{paddingTop:"20px"}} >
          <label >Description</label>
        </div>
        <select className="input_styling" style={{width:"300px"}}  name='client_status' value={clientData.client_status} onChange={handleChange}>
                  <option value="">select </option>
                  {statusUpdate?.map((status,ind)=>{
                      if(status.statusValue===clientData.client_status){
      ret=true
                      }
                      if(ret===true){
      
                      }
                      return( <option key={ind} value={status.statusValue}>{status.statusName}</option>)
      
              })}
                </select>
        </div>

</div>




<div style={{ display: "none" }} id="myModal1" className="modal" >
          <div className="modal-content-Alert">
            <div className="row ">
              <div className="col-12  page_headingAlert">
                {/* <h4 className="  text-center pt-2"></h4> */}
                <span
                  class="close pr-2 closeicon"
                  id="hideMeAfter5Seconds"
                  onClick={() => {
                    document.getElementById("myModal1").style.display =
                    "none";
                  }}
                >
                  &times;
                </span>
              </div>


              <div style={{display:"flex",flexDirection:"column",width:"100%",maxWidth:"550px",}}>
            <div style={{paddingLeft:"50px"}} >
                  <div >
                <label >Description</label>
                </div>
                <textarea style={{width:"500px",}} rows="4" name='description' value={clientData.description} onChange={handleChange} ></textarea>
              </div>
        <div style={{display:"flex",justifyContent:"flex-end",padding:"10px"}}  >
              {loader1 && (
                      <Loader
                        type="Oval"
                        color="black"
                        height={30}
                        width={30}
                        radius={12}
                      />
                    )} 
      <button type="button" className="save_btn "  onClick={addRejection}>Save</button>
      </div>

              </div>

            </div>
          </div>
        </div>

















<div style={{display:"flex",justifyContent:"end",paddingRight:"200px",paddingTop:"20px"}} >

<div class="col" style={{maxWidth:"300px",display:"flex",gap:"10px",}} >
{loader1 && (
        <Loader
          type="Oval"
          color="black"
          height={30}
          width={30}
          radius={12}
        />
      )}
  <button  className="save_btn " onClick={()=>{
    addClientStatus()
  }}>
    Submit
  </button>
  <button onClick={()=>{
             setShowupdateForm("Table");
             setedit(false)

  }} className="save_btn " style={{backgroundColor:"red",color:"white"}}>
    Cancel
  </button>
</div>
</div>

                </div>
        )
       }
      <ToastContainer theme="dark" autoClose={2000} />

    </>
  )
}

export default ChangeInClientStatus
import axios from 'axios';
import { toast } from "react-toastify";
export const GetInstallmentPayment = (data) => {

    return ({
        type: "get_allinstallmentpayment",
        payload: data,
        loader: false
    })
}
export function getInstallmentPayment() {
    let pgurl = `${window.$url}Reports/InstallmentPayments?ProjectId=${localStorage.getItem("ProjectId")}`;
    if (localStorage.getItem("ProjectId") === null) {
        pgurl = `${window.$url}Reports/InstallmentPayments`
    }
    return (dispatch) => {

        axios({
            url: pgurl,
            method: "get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetInstallmentPayment(result.data.InstallmentPayments));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}
export const GetInstallmentReceipt = (data) => {

    return ({
        type: "get_allinstallmentreceipt",
        payload: data,
        loader: false
    })
}
export function getInstallmentReceipt() {
    let pgurl = `${window.$url}Reports/InstallmentReceipts?ProjectId=${localStorage.getItem("ProjectId")}`;
    if (localStorage.getItem("ProjectId") === null) {
        pgurl = `${window.$url}Reports/InstallmentReceipts`
    }
    return (dispatch) => {

        axios({
            url: pgurl,
            method: "get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetInstallmentReceipt(result.data.InstallmentReceipts));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}
import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import moment from "moment";
import IndividualPrintHeader from "../../../Prints/PrintHeader/IndividualPrintHeader";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
var converter = require('number-to-words')
class JournalVoucherPrint extends Component {
    render() {

        return (
          <div className="row m-0 p-0">
            <IndividualPrintHeader />

            <h4 className="col-12 text-center">
              <u>Journal Voucher</u>
            </h4>
            <h4 className="col-12 text-center">
              {localStorage.getItem("ProjectName") === "THE GRAND CITY"
                ? "THE GRAND ICONIC CITY"
                : localStorage.getItem("ProjectName")}
            </h4>
            <div className="col-6 mt-2">
              Voucher No:{" "}
              {this.props.printData !== undefined
                ? this.props.printData.GeneralVoucherId
                : null}
            </div>
            <div className="col-6 mt-2 text-right">
              Voucher Date:{" "}
              {this.props.printData !== undefined
                ? moment(this.props.printData.EntryDate.split("T")[0]).format(
                    "DD-MMM-YYYY"
                  )
                : null}
            </div>

            {/* <div className="col-6 mt-2">
                       Payment Type:{" "}
                       {this.props.printData !== undefined ?

                            this.props.printData.VoucherType

                            : null}
                </div>
                <div className="col-6 mt-2 text-right">
                       
                </div> */}

            {/* <div className="col-12 mt-2">
                    Received with thanks from:
                </div>
            
                <div className="col-6 mt-2">
                    Name: {this.props.printData !== undefined ?

this.props.printData.AffiliateCnicNavigation.AffiliateName

                                : null}
                </div>
                <div className="col-6 mt-2 text-right">
                    CNIC:
                    {this.props.printData !== undefined ?

                        this.props.printData.Contract.AffiliateCnic

                                : null}
                </div>
                
                <div className="col-6 mt-2">
                    Cell: {this.props.printData !== undefined ?

                        this.props.printData.Contract.AffiliateCnicNavigation.PhoneNumber

                                : null}
                </div>
                 */}
            <div className="col-6 mt-2 text-right"></div>

            <div className="col-12 mt-3">
              <table className="table">
                <thead style={{ backgroundColor: "lightgray" }}>
                  <tr>
                    <th
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      Sr.
                    </th>
                    <th
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      Project Title
                    </th>
                    <th
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      Narration
                    </th>
                    <th
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      Amount
                    </th>
                  </tr>
                </thead>

                {this.props.printData !== undefined ? (
                  <tbody>
                    <tr>
                      <td
                        style={{
                          borderLeft: "1px solid grey",
                          borderRight: "1px solid grey",
                        }}
                      >
                        1
                      </td>
                      {this.props.printData.Project !== undefined &&
                      this.props.printData.Project !== null ? (
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        >
                          {this.props.printData.Project.ProjectName ===
                          "THE GRAND CITY"
                            ? "THE GRAND ICONIC CITY"
                            : this.props.printData.Project.ProjectName}
                        </td>
                      ) : (
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        ></td>
                      )}
                      <td
                        style={{
                          borderLeft: "1px solid grey",
                          borderRight: "1px solid grey",
                        }}
                      >
                        {" "}
                        {this.props.printData.Narration}
                      </td>
                      <td
                        style={{
                          borderLeft: "1px solid grey",
                          borderRight: "1px solid grey",
                        }}
                      >
                        {parseFloat(
                          this.props.printData.Amount
                        ).toLocaleString()}
                      </td>
                    </tr>
                  </tbody>
                ) : null}
                <thead style={{ backgroundColor: "lightgray" }}>
                  <tr>
                    <th style={{ borderLeft: "1px solid grey" }}>Total</th>
                    <th></th>

                    <th></th>

                    <th
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      {this.props.printData !== undefined
                        ? parseFloat(
                            this.props.printData.Amount
                          ).toLocaleString()
                        : null}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>

            <div className="col-12 mt-2">
              Total Amount:{" "}
              {this.props.printData !== undefined
                ? parseFloat(this.props.printData.Amount).toLocaleString()
                : null}
            </div>

            <div className="col-12 mt-2">
              Amount in words:{" "}
              {this.props.printData !== undefined
                ? converter.toWords(parseFloat(this.props.printData.Amount))
                : null}
            </div>

            {/* <div className="col-12 mt-2">
                    On account payment of shop:
                </div>                          
             */}

            <div className="col-6 mt-5 text-center">
              <b>___________________</b>
            </div>
            <div className="col-6 mt-5 text-center">
              <b>___________________</b>
            </div>
            <div className="col-6 mt-4 text-center">
              <b>Received By:</b>
            </div>
            <div className="col-6 mt-4 text-center">
              <b>Authorized By:</b>
            </div>
            <div className="col-12 mt-4">
              <hr />
            </div>
          </div>
        );
    }
}

export default JournalVoucherPrint;
export class JournalGeneralModal extends Component {
    render() {

        return (
          <div style={{ backgroundColor: "white", height: "100%" }}>
            <div className="row m-0 p-0">
              <PrintHeader />
            </div>

            <div
              className="row mt-6 p-0"
              style={{ border: "5px solid darkgrey", height: "85%" }}
            >
              <div className="col-12 text-center mt-2">
                <h4 style={{ color: "red" }}>
                  <u>Journal General Transactions </u>
                </h4>
                <h5 className="text-right pt-3 ">
                  <div className="row">
                    <div className="col-8"></div>
                    <div className="col-2 text-right">
                      <b>Print Date: </b>
                    </div>

                    <div className="col-2 text-center">
                      {moment().format("DD-MMM-YYYY")}
                    </div>
                  </div>
                </h5>
                {/* <h5 className="d-flex justify-content-between" >
                            <div className="text-left">

                                <b>Voucher No:{" "}</b>
                                {this.props.Data !== undefined ?

                                    this.props.Data.GeneralVoucherId

                                    : null}</div>
                            <div className="text-right">
                                <b>Payment Type:{" "}</b>
                                {this.props.Data !== undefined ?

                                    this.props.Data.VoucherType

                                    : null}
                            </div>

                        </h5> */}

                <div className="mt-5">
                  <div class="table-responsive-print col-12">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Sr</th>
                          <th>Account</th>
                          <th>Narration</th>
                          <th>Debit</th>

                          <th>Credit</th>
                        </tr>
                      </thead>
                      {this.props.printData !== undefined
                        ? this.props.printData.map((x, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{x.Account.AccountTitle}</td>
                              <td>{x.Narration}</td>
                              <td>
                                {x.TransactionType === "DEBIT"
                                  ? x.Amount.toLocaleString()
                                  : null}
                              </td>

                              <td>
                                {x.TransactionType === "CREDIT"
                                  ? x.Amount.toLocaleString()
                                  : null}
                              </td>
                            </tr>
                          ))
                        : null}
                      <tr>
                        <td></td>
                        <td></td>

                        <td>
                          {" "}
                          <label className="input_label m-0">Total:</label>
                        </td>
                        <td>{this.props.creditTotal?.toLocaleString()}</td>
                        <td>{this.props.debitTotal.toLocaleString()}</td>
                        <td></td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="col-12 text-left mt-2">
                  Total Amount:{" "}
                  {this.props.total
                    ? parseFloat(this.props.total).toLocaleString()
                    : null}
                </div>

                <div className="col-12 text-left mt-2">
                  Amount in words:{" "}
                  {this.props.total
                    ? converter.toWords(parseFloat(this.props.total))
                    : null}
                </div>
                <div className="signature">
                  <h5 className=" mt-3 ">
                    <div className="row">
                      <div className="col-2">
                        <b>Prepared By: </b>
                      </div>
                      <div className="col-2">
                        <>----------------</>
                      </div>
                      <div className="col-2">
                        <b>Checked By: </b>
                      </div>
                      <div className="col-2">
                        <>----------------</>
                      </div>
                      <div className="col-2">
                        <b>Approved By: </b>
                      </div>

                      <div className="col-2">
                        <>----------------</>
                      </div>
                    </div>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        );
    }
}
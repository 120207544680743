import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

export default function Changeform() {
  const [formData, setformData] = useState([]);
  const [formData2, setformData2] = useState([]);
  const accountModules = [
    "Dashboard",
    "Vouchers",
    "Contract",
    "Chart Of Acc",
    "Tax",
    "Investment",
    "Reports",
  ];
  const inventoryModules = [
    "Projects",
    "Deals",
    "Availability",
    "Land & Seller",
  ];
  const hrmModules = ["Dashboard", "Attendance", "Payroll", "Employees"];
  const crmModules = [
    "Dashboard",
    "Leads",
    "Clients",
    "Tasks",
    "Teams",
    "Availability",
  ];
  const assetsModule = ["Dashboard", "Assets", "Maintainance", "Depriciation"];
  const [subModule, setsubModule] = useState(accountModules);
  const [alertdata, setalertdata] = useState("");
  const handlemodules = (e) => {
    if (e.target.value === "Accounts") {
      setsubModule(accountModules);
    } else if (e.target.value === "Inventory") {
      setsubModule(inventoryModules);
    } else if (e.target.value === "HRM") {
      setsubModule(hrmModules);
    } else if (e.target.value === "CRM") {
      setsubModule(crmModules);
    } else if (e.target.value === "Assets") {
      setsubModule(assetsModule);
    }
  };
  const getFormData = () => {
    // haseeb https://sheetdb.io/api/v1/n9t34br0th8et
    //snabil https://sheetdb.io/api/v1/futov4q2xidzh
    axios
      .get("https://sheetdb.io/api/v1/futov4q2xidzh")
      .then((res) => {
        console.log("res", res);
        setformData(res.data?.sort()?.reverse());
        setformData2(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addChange = (e) => {
    let showAlert = false;
    if (document.getElementById("changeformtitle").value === "") {
      toast.info("Please Enter Title");
    } else if (document.getElementById("changeformdescription").value === "") {
      toast.info("Please Enter Description");
    } else {
      e.target.disabled = true;
      axios
        .post("https://sheetdb.io/api/v1/futov4q2xidzh", {
          Date: document.getElementById("changeformdate").value,
          Module: document.getElementById("changeformmodule").value,
          SubModule: document.getElementById("changeformsubmodule").value,
          Title: document.getElementById("changeformtitle").value,
          Description: document.getElementById("changeformdescription").value,
          Priority: document.getElementById("changeformPriority").value,
          AddedBy: localStorage.getItem("UserName"),
          Status: "Pending",
          SerialNo: formData.length + 1,
        })
        .then(function (response) {
          e.target.disabled = false;
          document.getElementById("changeformtitle").value = "";
          document.getElementById("changeformdescription").value = "";
          toast.info("Change Request Submitted");
          getFormData();
        })
        .catch((err) => {
          e.target.disabled = false;
          toast.info("Something Went Wrong");
        });
    }
  };

  useEffect(() => {
    getFormData();
  }, []);

  const onTextChange = () => {
    if (
      document.getElementById("searchbySubmodule").value === "" &&
      document.getElementById("searchbypriority").value === "" &&
      document.getElementById("searchbyStatus").value === ""
    ) {
      setformData(formData2);
    } else {
      let suggestions = [];
      {
        const regex = new RegExp(
          document.getElementById("searchbySubmodule").value,
          "i"
        );
        const regex1 = new RegExp(
          document.getElementById("searchbypriority").value,
          "i"
        );
        const regex2 = new RegExp(
          document.getElementById("searchbyStatus").value,
          "i"
        );

        suggestions = formData2
          .sort()
          .filter(
            (val) =>
              (document.getElementById("searchbySubmodule").value === "" ||
                regex.test(val.SubModule)) &&
              (document.getElementById("searchbypriority").value === "" ||
                regex1.test(val.Priority)) &&
              (document.getElementById("searchbyStatus").value === "" ||
                regex2.test(val.Status))
          );
      }

      setformData(suggestions?.sort().reverse());
    }
  };

  console.log("formData", formData);
  return (
    <div className="bg-white changeform">
      <div className="row">
        <div className="col-md-1 mt-md-4 ">
          <label className="input_label m-0">Date</label>
        </div>
        <div className="col-md-2 mt-md-4 p-0">
          <input
            id="changeformdate"
            type="date"
            className="input_styling"
            defaultValue={moment().format("YYYY-MM-DD")}
          ></input>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1 mt-md-3 ">
          <label className="input_label m-0">Module</label>
        </div>
        <div className="col-md-2 mt-md-3 p-0">
          <select
            id="changeformmodule"
            onChange={handlemodules}
            className="input_styling"
          >
            <option value="Accounts">Accounts</option>
            <option value="Inventory">Inventory</option>
            <option value="HRM">HRM</option>
            <option value="CRM">CRM</option>
            <option value="Assets">Assets</option>
          </select>
        </div>
        <div className="col-md-1 mt-md-3 ">
          <label className="input_label m-0">Sub Module</label>
        </div>
        <div className="col-md-2 mt-md-3 p-0">
          <select
            id="changeformsubmodule"
            className="input_styling"
          >
            {subModule.map((item) => {
              return <option value={item}>{item}</option>;
            })}
          </select>
        </div>
        <div className="col-md-1 mt-md-3 ">
          <label className="input_label m-0">Title</label>
        </div>
        <div className="col-md-2 mt-md-3">
          <input
            id="changeformtitle"
            type="text"
            className="input_styling"
            placeholder="Enter Title"
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-md-3 ">
          <label className="input_label m-0">Priority</label>
        </div>
        <div className="col-md-2 mt-md-3 p-0">
          <select
            id="changeformPriority"
            className="input_styling"
          >
            <option value="Low">Low</option>
            <option value="Medium">Medium</option>
            <option value="High">High</option>
          </select>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-1 ">
          <label className="input_label m-0">Description</label>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mt-md-2">
          <textarea
            id="changeformdescription"
            className="input_address  input_desc"
            placeholder="Enter Description"
          ></textarea>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mt-md-2 text-right">
          <button
            onClick={addChange}
            className="save_btn "
          >
            Submit
          </button>
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-md-4">
          <input
            type="text"
            className="input_styling"
            placeholder="search by Sub-module"
            id="searchbySubmodule"
            onChange={onTextChange}
          ></input>
        </div>
        <div className="col-md-4">
          <input
            type="text"
            className="input_styling"
            placeholder="search by priority"
            id="searchbypriority"
            onChange={onTextChange}
          ></input>
        </div>

        <div className="col-md-4">
          <input
            type="text"
            className="input_styling"
            placeholder="search by Status"
            id="searchbyStatus"
            onChange={onTextChange}
          ></input>
        </div>
      </div>
      <div className="row m-0 p-0">
        <div className="col-12 changeFormTable_height pr-2 pl-2">
          <div className="table-responsive mt-2">
            <table className="table table-borderless m-0">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Date</th>
                  <th>Module</th>
                  <th>Sub Module</th>
                  <th>Title</th>
                  <th>Priority</th>
                  <th>Description</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {formData?.map((item, ind) => {
                  return (
                    <tr key={ind}>
                      <td>{item?.SerialNo}</td>
                      <td>{item?.Date}</td>
                      <td>{item?.Module}</td>
                      <td>{item?.SubModule}</td>
                      <td>{item?.Title}</td>
                      <td>{item?.Priority}</td>
                      <td>{item?.Description}</td>
                      <td>{item?.Status}</td>
                    </tr>
                  );
                })}
                <tr></tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ToastContainer
        theme="dark"
        autoClose={2000}
      />
    </div>
  );
}

import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BsCloudUpload } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { getallLeadCallPopulate, getallLeadCallTable } from "../../actions/Crmactions/addleads";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import { FiEdit2 } from "react-icons/fi";
import axios from "axios";
const MainCallComp = () => {

    const showtasktable = () => {
        stateshowtask("task");
    }
    const canceltasktable = () => {
        stateshowtask("taskTable");
    }
    const [showtask, stateshowtask] = useState("taskTable");

    return (

        <>
            <div className="row ">
                <div className="dash_background col-12 p-0">
                    <div className="row ">

                        <div className="col-md-12">
                            {showtask == "taskTable" ? <AddCallTable showtasktable={showtasktable} canceltasktable={canceltasktable} /> : <AddCall canceltasktable={canceltasktable} />}
                        </div>


                    </div>


                </div>
            </div>
        </>

    )
}
export default MainCallComp;
export const AddCall = (props) => {
    const populateCallTask = useSelector((state) => state.getLeadCallPopulate);
    const [statemangerclient, setmangerclient] = useState();

    let dispatch = useDispatch();
    const [stateleadid, setleadid] = useState();
    const [callstate, setcallstate] = useState({
        LeadCallId: null,// 0 for new other for edit
        LeadId: null,
        ContactName: null,
        ContactNumber: null,
        CallPurpose: null,
        CallType: null,
        CallResult: null,
        Description: null,
        HostId: null,
        Status: null,
        CallDuration: null,
        CallTime: null
    });
    const saveleadcalldata = () => {
        if (document.getElementById("CallTime").value === "") {
            toast.info("Call Time is Required");
        }




        else if (document.getElementById("callHostId").selectedIndex === 0) {
            toast.info("Select Host");

        }

        else if (document.getElementById("ContactName").value === "") {
            toast.info("Add ContactName");


        }
        else if (document.getElementById("callPurpose").value === "") {
            toast.info("Select Call Purpose");

        }
        else if (document.getElementById("ContactNo").value === "") {
            toast.info("Select ContactNo");

        }
        else if (document.getElementById("callStatus").selectedIndex === 0) {
            toast.info("Select Status");

        }
        else if (document.getElementById("CallType").selectedIndex === 0) {
            toast.info("Select Call Type");

        }
        else if (document.getElementById("calldescription").value === "") {
            toast.info("Add Description");

        }

        else {
            let Lead = callstate;
            Lead.LeadCallId = 0;
            Lead.LeadId = parseInt(stateleadid);
            Lead.CallTime = document.getElementById("CallTime").value;


            Lead.HostId = parseInt(document.getElementById("callHostId").options[document.getElementById("callHostId").selectedIndex].dataset.id);
            Lead.CallType = document.getElementById("CallType").options[document.getElementById("CallType").selectedIndex].value;

            Lead.CallPurpose = document.getElementById("callPurpose").value;
            Lead.ContactNumber = document.getElementById("ContactNo").value;
            Lead.ContactName = document.getElementById("ContactName").value;
            Lead.CallResult = document.getElementById("callresult").value;
            Lead.CallDuration = document.getElementById("CallDuration").value;




            Lead.Status = document.getElementById("callStatus").options[document.getElementById("callStatus").selectedIndex].value;
            Lead.Description = document.getElementById("calldescription").value;


            document.getElementById("leadcallsave").disabled = true;
            axios({
                method: "post",
                url: `${window.$url}Leads/Calls/Add`,
                data: JSON.stringify(
                    Lead


                ),
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "content-type": "application/json",
                },
            }).then(
                (result) => {
                    document.getElementById("leadcallsave").disabled = false;
                    if (result.data.IsSuccess === true) {

                        document.getElementById("calldescription").value = "";
                        document.getElementById("CallDuration").value = "";
                        document.getElementById("callresult").value = "";
                        document.getElementById("callStatus").selectedIndex = 0;
                        document.getElementById("callPurpose").value = "";
                        document.getElementById("CallTime").value = "";
                        // document.getElementById("ContactNo").value = "";
                        // document.getElementById("ContactName").value = "";
                        document.getElementById("CallType").selectedIndex = 0;
                        document.getElementById("callHostId").selectedIndex = 0;
                        setleadid(null);
                        toast.info(result.data.Message);
                        props.canceltasktable();

                    }
                    else {
                        toast.info(result.data.Message);
                    }


                },
                (error) => {
                    toast.info("Someting went wrong");
                    document.getElementById("leadcallsave").disabled = false;

                }
            );

        }
    };
    var suggestionlist1 = [];

    const designationSelecttion = (e) => {
        const value = e.target.value;
        if (value == "") {
            ReactDOM.render(null, document.getElementById("unitdemo"));
        } else {
            let suggestions = [];
            if (value.length > 0) {
                const regex = new RegExp(`${value}`, "i");
                suggestions = populateCallTask.data.Leads
                    .sort()
                    .filter((v) =>
                        regex.test(v.LeadIdentifier !== null ? v.LeadIdentifier : null)
                    );
            }
            suggestionlist1 = suggestions;

            if (suggestionlist1.length === 0) {
                ReactDOM.render(null, document.getElementById("unitdemo"));
            } else {
                ReactDOM.render(
                    <ul className="suggestbox">
                        {suggestionlist1.map((item) => (
                            <li className="listcss" onClick={() => suggestionselected1(item)}>
                                {item === undefined
                                    ? item
                                    : item.LeadIdentifier
                                }
                            </li>
                        ))}
                    </ul>,
                    document.getElementById("unitdemo")
                );
            }
        }
    };
    const onTextChangeEmployeeName = () => {
        var inputValue = document.getElementById("inittiate").value;
        var options = document.getElementById("employeenamelist").childNodes;
    
        let val;
        for (var i = 0; i < options.length; i++) {
          if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
            populateCallTask.data.Leads.forEach((x, idx) => {
              if (idx == options[i].attributes.index.value) {
                val = x;
              }
            });
            setleadid(val.LeadId);

            document.getElementById("ContactNo").value = val.ManagerClient.ContactNumber1;
            document.getElementById("ContactName").value = val.ManagerClient.ManagerClientName;
    
    
            break;
          }
        }
      };
    const suggestionselected1 = (val) => {

        document.getElementById("inittiate").value = val.LeadIdentifier;
        setleadid(val.LeadId);

        document.getElementById("ContactNo").value = val.ManagerClient.ContactNumber1;
        document.getElementById("ContactName").value = val.ManagerClient.ManagerClientName;


        // setUnitId(val.UnitId);


        suggestionlist1 = [];
        ReactDOM.render(null, document.getElementById("unitdemo"));
    };

    useEffect(() => {


        dispatch(getallLeadCallPopulate());



    }, [dispatch]);
    return (

        <>
            <div className="row ">
                <div className="col-12  page_heading">
                    <h4 className="  text-center ">Add Call</h4>


                </div>
            </div>
            <div className="row ">
                <div className="col-md-4 mt-md-4  text-left p-0">

                </div>


                <div className="col-md-1 mt-md-4  text-left p-0">
                    <label className="input_label m-0">Lead</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">
                    <input type="text" list="employeenamelist" className="input_styling  ml-md-3" id="inittiate" onChange={onTextChangeEmployeeName} placeholder="Enter Lead Identifier" ></input><span className="estaric">*</span>
                    <datalist id="employeenamelist">
            {populateCallTask?.data?.Leads?.map((x, idx) => (
              <option index={idx} value={x.LeadIdentifier}>
                {x.LeadId}
              </option>
            ))}
          </datalist>
                    <span id="unitdemo"></span>

                </div>
                <div className="col-md-2 mt-md-4  text-left p-0">

                </div>
            </div>
            <div className="row ">
                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0 " >Call Time :</label>
                </div>
                <div className="col-md-3 mt-md-4  p-0">
                    <input type="datetime-local" className="input_date_styling  ml-md-3" id="CallTime"></input><span className="estaric">*</span>





                </div>
                <div className="col-md-2 mt-md-4   p-0">
                </div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0 " >Host :</label>
                </div>
                <div className="col-md-3 mt-md-4  p-0">
                    <select className="input_styling ml-md-3" id="callHostId" >
                        <option>Select</option>

                        {populateCallTask.data.Data !== undefined ? populateCallTask.data.Data.map((val, index) => (
                            <option key={index} data-id={val.EmployeeId}>{val.EmpName}</option>
                        )) : null}
                    </select><span className="estaric">*</span>



                </div>
            </div>
            <div className="row ">
                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">Contact Name</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">


                    <input type="text" className="input_styling  ml-md-3" id="ContactName"></input><span className="estaric">*</span>

                </div>
                <div className="col-md-2 mt-md-4   p-0">
                </div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">Contact No.</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">


                    <input type="text" className="input_styling  ml-md-3" id="ContactNo"></input><span className="estaric">*</span>

                </div>

            </div>
            <div className="row ">
                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0 " >Call Purpose :</label>
                </div>
                <div className="col-md-3 mt-md-4  p-0">
                    <input type="text" className="input_styling  ml-md-3" id="callPurpose"></input><span className="estaric">*</span>




                </div>
                <div className="col-md-2 mt-md-4   p-0">
                </div>

                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0 " >Call Type:</label>
                </div>
                <div className="col-md-3 mt-md-4  p-0">

                    <select className="input_styling ml-md-3" id="CallType" >
                        <option>Select</option>
                        <option>Audio Call</option>
                        <option>Video Call</option>






                    </select><span className="estaric">*</span>



                </div>



            </div>
            <div className="row ">

                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0 " >Call Status :</label>
                </div>
                <div className="col-md-3 mt-md-4  p-0">

                    <select className="input_styling ml-md-3" id="callStatus" onChange={(e) => {
                        if (e.target.value === "Done") {
                            document.getElementById("hideshow").style.display = "inline";
                        } else {
                            document.getElementById("hideshow").style.display = "none";
                        }
                    }}>
                        <option>Select</option>
                        <option>Pending</option>
                        <option>Done</option>




                    </select><span className="estaric">*</span>



                </div>
                <div className="col-md-2 mt-md-4   p-0">
                </div>

                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">Description</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">

                    <textarea className="input_address ml-md-3 " id="calldescription" ></textarea><span className="estaric">*</span>

                </div>

            </div>
            <header id="hideshow" style={{ display: "none" }}>
                <div className="row " >

                    <div className="col-md-2 mt-md-4  text-left p-0">
                        <label className="input_label m-0 " >Call Duration :</label>
                    </div>
                    <div className="col-md-3 mt-md-4  p-0">
                        <input type="text" className="input_styling  ml-md-3" placeholder="1 hour 10 minutes etc" id="CallDuration"></input><span className="estaric">*</span>




                    </div>
                    <div className="col-md-2 mt-md-4   p-0">
                    </div>
                    <div className="col-md-2 mt-md-4  text-left p-0">
                        <label className="input_label m-0">Call Result</label>
                    </div>
                    <div className="col-md-3 mt-md-4   p-0">

                        <textarea className="input_address ml-md-3 " id="callresult" ></textarea><span className="estaric">*</span>

                    </div>

                </div>
            </header>
            <div className=" float-right mt-md-4 ">
                <span>
                    <button
                        className="cancel_btn ml-2  "
                        onClick={props.canceltasktable}
                    >

                        Cancel
                    </button>
                </span>

                <span>
                    <button className="save_btn ml-2  " id="leadcallsave" onClick={saveleadcalldata}>
                        <BsCloudUpload size="1.3em" />
                        Save
                    </button>
                </span>
            </div>




        </>

    )
}
export const AddCallTable = (props) => {

    const resultallcalls = useSelector(state => state.getLeadCallTable);

    const [stateeditlead, seteditlead] = useState([]);
    const [stateleadid, setleadid] = useState();
    const [filerunits, setfilterunits] = useState();

    let dispatch = useDispatch();
    // print function
    // const componentRef = useRef();

    // const printPaymentRec = useReactToPrint({
    //     content: () => componentRef.current,
    // });
    var suggestionlist = [];

    const onTextChange = (e) => {
        const value = e.target.value;
        if (value === "") {

            setfilterunits(resultallcalls.data);
        } else {
            let suggestions = [];
            if (value.length > 0) {
                const regex = new RegExp(`${value}`, "i");

                suggestions = filerunits
                    .sort()
                    .filter((val) =>
                        (regex.test(val.CallTime)) || (regex.test(val.Status)));

            }
            suggestionlist = suggestions;
            setfilterunits(suggestionlist);
        }
    };
    const [callstate, setcallstate] = useState({
        LeadCallId: null,// 0 for new other for edit
        LeadId: null,
        ContactName: null,
        ContactNumber: null,
        CallPurpose: null,
        CallType: null,
        CallResult: null,
        Description: null,
        HostId: null,
        Status: null,
        CallDuration: null,
        CallTime: null
    });
    const saveleadtaskdata = () => {




        let Lead = callstate;
        Lead.LeadCallId = parseInt(stateeditlead.LeadCallId);
        Lead.LeadId = parseInt(stateleadid);
        Lead.CallTime = document.getElementById("CallTime").value;


        Lead.HostId = null;
        Lead.CallType = null;

        Lead.CallPurpose = null;
        Lead.ContactNumber = null;
        Lead.ContactName = null;
        Lead.CallResult = document.getElementById("callresult").value;
        Lead.CallDuration = document.getElementById("CallDuration").value;



        Lead.Status = document.getElementById("Status").options[document.getElementById("Status").selectedIndex].value;
        Lead.Description = null;


        document.getElementById("leadcallsave").disabled = true;
        axios({
            method: "post",
            url: `${window.$url}Leads/Calls/Add`,
            data: JSON.stringify(
                Lead


            ),
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(

            (result) => {
                document.getElementById("leadcallsave").disabled = false;
                if (result.data.IsSuccess === true) {


                    document.getElementById("Status").selectedIndex = 0;
                    // document.getElementById("CallTime").value = "";
                    document.getElementById("CallDuration").value = "";
                    document.getElementById("callresult").value = ""


                    toast.info(result.data.Message);
                    document.getElementById("modalofleadedit").style.display =
                        "none";
                    dispatch(getallLeadCallTable());


                }
                else {
                    toast.info(result.data.Message);
                }


            },
            (error) => {
                toast.info("Someting went wrong");
                document.getElementById("leadcallsave").disabled = false;

            }
        );


    };
    useEffect(() => {
        dispatch(getallLeadCallTable());


    }, [dispatch])
    useEffect(() => {
        setfilterunits(resultallcalls.data);

    }, [resultallcalls]
    )
    const openmodalleadsedit = (x) => {

        setleadid(x.LeadId);
        seteditlead(x);
        document.getElementById("modalofleadedit").style.display = "block";

    };
    return (
        <>
            {/* <div hidden>
                <ClientPrint
                    ref={componentRef}

                    printData={resultallcalls}

                /></div> */}
            <div className="row pt-3 ">
                <div className="col-md-1 pr-0 pl-0">


                    <label className="input_label m-0">Search:</label>
                </div>
                <div className="col-md-3 pr-0 pl-0">


                    <input type="text " className="input_styling " placeholder="Time/Status" onChange={onTextChange}></input>
                </div>

                <div className="col-md-1 pr-0 pl-0">

                    {resultallcalls.loading ? <Loader type="ThreeDots"
                        color="green"
                        height={40}
                        width={40} /> : null}
                </div>
            </div>
            <div className="row ">
                <div className=" col-12  table_height pr-0 pl-0">

                    <div className="table-responsive mt-4">

                        <table className="table table-borderless table-hover m-0">
                            <thead>

                                <tr >

                                    <th>Sr</th>
                                    <th>Time</th>

                                    <th>ContactName</th>
                                    <th>Number</th>
                                    <th>Host</th>
                                    <th>Purpose</th>
                                    <th>Type</th>
                                    <th>Duration</th>
                                    <th>Result</th>


                                    <th>Status</th>





                                    <th className="text-center">
                                        <button
                                            className="save_btn"
                                            onClick={() => {
                                                props.showtasktable();
                                            }}
                                        >
                                            New+
                                        </button>

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {filerunits !== undefined ? filerunits.map((val, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{val.CallTime}</td>
                                        <td>{val.ContactName}</td>
                                        <td>{val.ContactNumber}</td>
                                        <td>{val.Host.EmpName}</td>
                                        <td>{val.CallPurpose}</td>

                                        <td>{val.CallType}</td>
                                        <td>{val.CallDuration}</td>
                                        <td>{val.CallResult}</td>



                                        <td>{val.Status}</td>

                                        <td className="text-center">

                                            {val.Status !== "Done" ?
                                                <FiEdit2 size="1em" onClick={() => {
                                                    openmodalleadsedit(val);

                                                }} /> : null}
                                        </td>

                                    </tr>
                                )) : null}


                            </tbody>
                        </table>
                    </div>
                </div>
                {/* <div className="col-6  text-right pr-0 pl-0">
                    <button className="print_btn float-left" onClick={() => { printPaymentRec(); }}><TiPrinter size="1.3em" />Print</button>


                </div> */}
            </div>

            <div id="modalofleadedit" class="modalscreen ">
                <div class="modal-content-screen1 pt-1">
                    <div className="row ">
                        <div className="col-12  page_heading">
                            <h4 className="  text-center ">Edit Call</h4>
                            <span
                                class="close pr-2  "
                                onClick={() => {
                                    document.getElementById("modalofleadedit").style.display =
                                        "none";

                                    document.getElementById("Status").selectedIndex = 0;
                                    // document.getElementById("CallTime").value = "";
                                    document.getElementById("CallDuration").value = "";
                                    document.getElementById("callresult").value = ""

                                }}
                            >
                                &times;
                            </span>

                        </div>
                    </div>
                    <div className="container">

                        <div className="row ">
                            <div className="col-md-2 mt-md-4  text-left p-0">
                                <label className="input_label m-0 " >Status :</label>
                            </div>
                            <div className="col-md-3 mt-md-4  p-0">

                                <select className="input_styling ml-md-3" id="Status" onChange={(e) => {
                                    if (e.target.value === "Done") {
                                        document.getElementById("hideshow").style.display = "inline";
                                    } else {
                                        document.getElementById("hideshow").style.display = "none";
                                    }
                                }} >
                                    {stateeditlead.Status !== null ? < option selected > {stateeditlead.Status}</option> : < option selected> Select</option>}

                                    <option>Pending</option>
                                    <option>Done</option>




                                </select>




                            </div>
                            <div className="col-md-2 mt-md-4   p-0">
                            </div>
                            <div className="col-md-2 mt-md-4  text-left p-0">
                                <label className="input_label m-0 " >Call Date :</label>
                            </div>
                            <div className="col-md-3 mt-md-4  p-0">
                                <input type="datetime-local" className="input_date_styling  ml-md-3" key={stateeditlead.CallTime} defaultValue={stateeditlead.CallTime !== undefined ? stateeditlead.CallTime : null} id="CallTime"></input>





                            </div>

                        </div>
                        <header id="hideshow" style={{ display: "none" }}>
                            <div className="row " >

                                <div className="col-md-2 mt-md-4  text-left p-0">
                                    <label className="input_label m-0 " >Call Duration :</label>
                                </div>
                                <div className="col-md-3 mt-md-4  p-0">
                                    <input type="text" className="input_styling  ml-md-3" key={stateeditlead.Comments} defaultValue={stateeditlead.CallDuration !== undefined ? stateeditlead.CallDuration : null} id="CallDuration"></input>




                                </div>
                                <div className="col-md-2 mt-md-4   p-0">
                                </div>
                                <div className="col-md-2 mt-md-4  text-left p-0">
                                    <label className="input_label m-0">Call Result</label>
                                </div>
                                <div className="col-md-3 mt-md-4   p-0">

                                    <textarea className="input_address ml-md-3 " id="callresult" key={stateeditlead.CallResult} defaultValue={stateeditlead.CallResult !== undefined ? stateeditlead.CallResult : null} ></textarea>

                                </div>

                            </div>


                        </header>


                        <div className="row ">



                            <div className=" col-12 float-right mt-md-4 ">


                                <span>
                                    <button className="save_btn ml-2  " id="leadcallsave" onClick={saveleadtaskdata} >
                                        <BsCloudUpload size="1.3em" />
                                        Save
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};

import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import background from "../assets/background.jpg";
import axios from "axios";
import Loader from "react-loader-spinner";
import { ClearRedux } from "./actions/ClearRedux";
const divStyle = {
  width: "100vw",
  height: "100vh",
  backgroundImage: `url(${background})`,
  backgroundSize: "cover",
};
const ProjectSelection = () => {
  // project data from store
  const projectData = useSelector((state) => state.getProjectDataRed);
  // all project data from api
  const [alldata, setdata] = useState([]);
  const [loader, setloader] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (projectData.projectData === undefined) {
      setloader(true);

      axios({
        method: "get",
        url: `${window.$url}Projects/GetAssigned`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result.data.IsSuccess) {
            setdata(result.data.Data);
            setloader(false);
          } else {
            alert("Error in Geting Projecs. Login Again!!");
            setloader(false);
          }
        },
        (error) => {
          alert(error);
          setloader(false);
        }
      );
    }
  }, []);
  let history = useHistory();

  const setProjectinfo = (id) => {
    var r = window.confirm("Are you Sure you want to select " + id.ProjectName);
    if (r === true) {
      dispatch(ClearRedux());
      var projectname = document.getElementById(
        "Pname" + id.ProjectId
      ).innerText;
      var projectid = document.getElementById("PId" + id.ProjectId).innerText;
      var projectowner = document.getElementById(
        "Powner".concat(id.ProjectOwner)
      ).innerText;
      localStorage.setItem("ProjectId", projectid);
      localStorage.setItem("ProjectName", projectname);
      localStorage.setItem("ProjectOwner", projectowner);
      localStorage.removeItem("COA");
      history.push({
        pathname: "/account",
      });
    }
  };

  return (
    <>
      {" "}
      <div style={divStyle}>
        <div className="container p-2 text-center">
          <h1 id="xyx" className="h1css">
            Select Project you want to work on {""}
            {loader && (
              <Loader
                type="Oval"
                color="black"
                height={30}
                width={30}
                radius={12}
              />
            )}
          </h1>

          <div className="card-list mt-2">
            {`${localStorage.getItem("Designation")}` === "Admin" ? (
              <div
                className="card-container"
                onClick={() => {
                  localStorage.setItem("COA", "yes");
                  localStorage.removeItem("ProjectId");

                  localStorage.removeItem("ProjectName");
                  localStorage.removeItem("ProjectOwner");
                  history.push({
                    pathname: "/account",
                  });
                }}
              >
                <h3>
                  Main <br></br>Chart Of Acc
                </h3>
              </div>
            ) : (
              <div></div>
            )}
            {projectData.projectData !== undefined
              ? projectData.projectData.map((x) => (
                  <div
                    className="card-container"
                    onClick={() => {
                      setProjectinfo(x);
                    }}
                  >
                    <h3 id={"Pname" + x.ProjectId}>{x.ProjectName}</h3>
                    <p
                      style={{ display: "none" }}
                      id={"Powner".concat(x.ProjectOwner)}
                    >
                      {x.ProjectOwner}
                    </p>
                    <p hidden id={"PId" + x.ProjectId}>
                      {x.ProjectId}
                    </p>
                  </div>
                ))
              : alldata.map((x) => {
                  if (
                    x.ProjectId == "33" &&
                    localStorage.getItem("Designation") == "Admin"
                  ) {
                    return (
                      <div
                        className="card-container"
                        onClick={() => {
                          setProjectinfo(x);
                        }}
                      >
                        <h3 id={"Pname" + x.ProjectId}>{x.ProjectName}</h3>
                        <p
                          style={{ display: "none" }}
                          id={"Powner".concat(x.ProjectOwner)}
                        >
                          {x.ProjectOwner}
                        </p>

                        <p hidden id={"PId" + x.ProjectId}>
                          {x.ProjectId}
                        </p>
                      </div>
                    );
                  } else if (x.ProjectId != "33") {
                    return (
                      <div
                        className="card-container"
                        onClick={() => {
                          setProjectinfo(x);
                        }}
                      >
                        <h3 id={"Pname" + x.ProjectId}>{x.ProjectName}</h3>
                        <p
                          style={{ display: "none" }}
                          id={"Powner".concat(x.ProjectOwner)}
                        >
                          {x.ProjectOwner}
                        </p>

                        <p hidden id={"PId" + x.ProjectId}>
                          {x.ProjectId}
                        </p>
                      </div>
                    );
                  }
                })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectSelection;

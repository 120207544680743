import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { BsCloudUpload, BsFillInfoSquareFill } from "react-icons/bs";
import { RiArrowGoBackFill } from "react-icons/ri";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getbalancesheet } from "../../../actions/AccountReports/balancesheet";
import { getprofitloss } from "../../../actions/AccountReports/profitandloss";
import { getrecoveryreport } from "../../../actions/AccountReports/recoveryreport";
import { getallTaxTable } from "../../../actions/accountsactions/addtax";
import { getallbanks, getallcash } from "../../../actions/getbanks";
import { getallProject } from "../../../actions/getprojects";
import { getallunitreceipt } from "../../../actions/vouchersactions/getunitreceipt";
import ScreenInfoModal from "../../../ScreenInfoModal/ScreenInfoModal";
import TaxModal from "../../TaxModal/TaxModal";
import RectifiyModal from "../../Vouchers/RectifyModal/rectifiyModal";

export const UnitReceiptForm = (props) => {
  // let vouchernumber = `UR-${Math.floor(Date.now())}`
  let vouchernumber = `UR-${Math.floor(Date.now())}`;
  const unitReceiptData = useSelector((state) => state.getAllUnitPopulate);
  const resultsallbanks = useSelector((state) => state.getAllBanksReducer);
  const resultsallcash = useSelector((state) => state.getAllCash);
  const resultalltaxes = useSelector((state) => state.getAllTaxTable);
  const [disableBtn, setdisableBtn] = useState(true);
  const [RevertModal, setRevertModal] = useState(false);
  const [stateamount, setamount] = useState("0");
  const [taxtotal, settaxtotal] = useState("0");
  const resultsproject = useSelector((state) => state.getProject);
  const [statecustomerlist, setcustomerlist] = useState({});
  const [stateunitlist, setunitlist] = useState();
  const [renderComp, setrenderComp] = useState(false);
  const [Installments, setInstallments] = useState([]);
  const [advCheck, setadvCheck] = useState(false);
  const [customerCnic, setcustomerCnic] = useState("");
  const [narration, setNarration] = useState(null);
  const [allvalues, setallvalues] = useState([]);
  const [showTaxmodal, setshowTaxmodal] = useState(false);
  const [showDetailModal, setshowDetailModal] = useState(false);
  const [loading1, setLoading1] = useState(false);

  let dispatch = useDispatch();
  // var Installments = [];

  const savedata = () => {
    if (document.getElementById("Date").value === "") {
      toast.info("Date is Required");
    } else if (document.getElementById("project").selectedIndex === 0) {
      toast.info("Select Project");
    } else if (customerCnic === "") {
      toast.info("Select Customer");
    } else if (document.getElementById("unit").selectedIndex === 0) {
      toast.info("Select Unit");
    } else if (document.getElementById("vouchertype").selectedIndex === 0) {
      toast.info("Select Type");
    } else if (
      document.getElementById("vouchertype").options[
        document.getElementById("vouchertype").selectedIndex
      ].value === "Bank Voucher" &&
      (document.getElementById("banks").options[
        document.getElementById("banks").selectedIndex
      ].value === "Select" ||
        document.getElementById("chequeno").value === "")
    ) {
      toast.info("Cheque no and Bank name are Required");
    } else if (
      document.getElementById("vouchertype").options[
        document.getElementById("vouchertype").selectedIndex
      ].value === "Cash Voucher" &&
      document.getElementById("cashs").options[
        document.getElementById("cashs").selectedIndex
      ].value === "Select"
    ) {
      toast.info("Selech Cash Account");
    } else if (
      document.getElementById("total").value === "" ||
      document.getElementById("netPaid").value === "" ||
      document.getElementById("total").value === "0" ||
      document.getElementById("netPaid").value === "0  "
    ) {
      toast.info("Total and Netpaid can't be empty ");
    } else {
      let paymentType =
        document.getElementById("vouchertype").options[
          document.getElementById("vouchertype").selectedIndex
        ].value;
      if (paymentType === "Bank Voucher") {
        paymentType = "Bank";
      } else {
        paymentType = "Cash";
      }
      let bankId =
        document.getElementById("banks").options[
          document.getElementById("banks").selectedIndex
        ].value;
      if (bankId === "Select") {
        bankId = null;
      } else {
        bankId =
          document.getElementById("banks").options[
            document.getElementById("banks").selectedIndex
          ].dataset.id;
      }
      let cashId =
        document.getElementById("cashs").options[
          document.getElementById("cashs").selectedIndex
        ].value;
      if (cashId === "Select") {
        cashId = 0;
      } else {
        cashId =
          document.getElementById("cashs").options[
            document.getElementById("cashs").selectedIndex
          ].dataset.id;
      }
      let checkNumber;

      if (paymentType === "Cash") {
        checkNumber = null;
      } else {
        checkNumber = document.getElementById("chequeno").value;
      }
      document.getElementById("unitsave").disabled = true;
      setLoading1(true);
      let UnitVoucher = {
        UnitVoucherId: document.getElementById("Voucher").value,
        ProjectId: parseInt(
          document.getElementById("project").options[
            document.getElementById("project")?.selectedIndex
          ].dataset.id
        ),
        BankId: paymentType === "Cash" ? parseInt(cashId) : parseInt(bankId),
        ContractId: stateunitlist.ContractId,
        // Amount: parseFloat(document.getElementById("netPaid").value),
        Amount: parseFloat(
          document.getElementById("netPaid").value.replace(/,/g, "")
        ),
        Narration: narration,
        ChequeNumber: checkNumber,
        EntryDate: document.getElementById("Date").value,
        PaymentType: paymentType,
        CustomerCnic: customerCnic,
      };
      let TaxList = [];
      allvalues?.map((val, i) => {
        if (val.Checked == true) {
          TaxList.push({
            TaxId: val.TaxId,
            TaxPer: Number(val.TaxPer),
            TaxAmount: Number(val.TaxAmount),
            Checked: true,
            TaxSubmitedDetailId: val.TaxSubmitedDetailId
              ? val.TaxSubmitedDetailId
              : 0,
            ProjectId: localStorage.getItem("ProjectId"),
            ReferenceId: "",
            ReferenceType: "",
          });
        }
      });

      document.getElementById("unitsave").disabled = true;
      axios({
        method: "post",
        url: `${window.$url}Vouchers/Unit/Add`,
        data: JSON.stringify({
          UnitVoucher,
          Installments,
          TaxList,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("unitsave").disabled = false;
          setLoading1(false);

          if (result.data.IsSuccess === true) {
            document.getElementById("project").selectedIndex = 0;
            document.getElementById("unit").selectedIndex = 0;
            setcustomerCnic("");
            document.getElementById("chequeno").value = "";
            document.getElementById("vouchertype").selectedIndex = 0;
            document.getElementById("Date").value = "";
            document.getElementById("total").value = "";

            document.getElementById("banks").selectedIndex = 0;
            document.getElementById("netPaid").value = "";
            setNarration(null);
            // document.getElementById("installmentcheck").checked = false;
            setunitlist();
            setcustomerlist({});
            if (document.getElementById("advCheck") !== null)
              document.getElementById("advCheck").checked = false;

            dispatch(getallunitreceipt());
            toast.info(result.data.Message);
            dispatch(getrecoveryreport());
            dispatch(getprofitloss());
            dispatch(getbalancesheet());
            props.cancelunittable();
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Someting went wrong");
          document.getElementById("unitsave").disabled = false;
          setLoading1(false);
        }
      );
    }
  };
  const submitdata = () => {
    setdisableBtn(true);
    axios({
      method: "post",
      url: `${window.$url}Vouchers/Unit/Submit?voucherId=${props.vouchData?.UnitVoucherId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          document.getElementById("project").selectedIndex = 0;
          document.getElementById("unit").selectedIndex = 0;
          document.getElementById("customer").selectedIndex = 0;
          document.getElementById("chequeno").value = "";
          document.getElementById("vouchertype").selectedIndex = 0;
          document.getElementById("Date").value = "";
          document.getElementById("total").value = "";

          document.getElementById("banks").selectedIndex = 0;
          document.getElementById("netPaid").value = "";
          setNarration(null);
          // document.getElementById("installmentcheck").checked = false;
          setunitlist({});
          setcustomerlist({});
          if (document.getElementById("advCheck") !== null)
            document.getElementById("advCheck").checked = false;

          dispatch(getallunitreceipt());
          toast.info(result.data.Message);
          dispatch(getrecoveryreport());
          dispatch(getprofitloss());
          dispatch(getbalancesheet());
          props.cancelunittable();
        } else {
          toast.info(result.data.Message);

          setdisableBtn(false);
        }
      },
      (error) => {
        toast.info("Someting went wrong");

        setdisableBtn(false);
      }
    );
  };
  useEffect(() => {
    if (props.vouchData?.VoucherTaxSubmitedDetail) {
      props.vouchData.VoucherTaxSubmitedDetail?.forEach((item) => {
        resultalltaxes.data?.forEach((item2) => {
          if (item.TaxId === item2.TaxId) {
            item2.Checked = true;
            item2.TaxPer = item.TaxPer;
            item2.TaxAmount = item.TaxAmount;
            item2.TaxSubmitedDetailId = item.TaxSubmitedDetailId;
          }
        });
      });
    }
  }, [resultalltaxes]);
  useEffect(() => {
    if (props.vouchData?.UnitVoucherId) {
      let sum = 0;
      let PreviousSelectedTax = props.vouchData.VoucherTaxSubmitedDetail.map(
        (data) => {
          sum = sum + data.TaxAmount;
          let obj = { ...data, Checked: true };
          return obj;
        }
      );
      settaxtotal(sum);

      PreviousSelectedTax = PreviousSelectedTax.map((value) => {
        let obj = value.Tax.TaxId;
        return { ...value, TaxId: obj };
      });
      setallvalues(PreviousSelectedTax);

      setdisableBtn(false);
      if (props.vouchData?.PaymentType === "CASH") {
        // document.getElementById("vouchertype").options[2].selected = true;
        document.getElementById("vouchertype").options[1].selected = true;
        document.getElementById("bankdiv").style.display = "none";
        document.getElementById("cashdiv").style.display = "block";
      } else {
        document.getElementById("vouchertype").options[2].selected = true;
        document.getElementById("bankdiv").style.display = "block";
        document.getElementById("cashdiv").style.display = "none";
        document.getElementById("chequeno").value =
          props.vouchData?.ChequeNumber;
        document.getElementById("banks").value =
          props.vouchData?.Bank?.AccountId;
      }
      setamount(props.vouchData?.Amount?.toString());
      // settaxtotal
      document.getElementById("Voucher").value = props.vouchData?.UnitVoucherId;
      document.getElementById("Date").value = props.vouchData?.EntryDate?.slice(
        0,
        10
      );
      document.getElementById("project").value =
        props.vouchData?.Project?.ProjectName;
      setInstallments(props.vouchData?.SavedInstallment);
      setunitlist(props.vouchData?.Contract);
      let totalTax = 0;
      props.vouchData?.VoucherTaxSubmitedDetail?.forEach((itm, idx) => {
        totalTax = totalTax + itm.TaxAmount;
      });
      document.getElementById("total").value =
        props.vouchData?.Amount?.toString();
      document.getElementById("netPaid").value =
        props.vouchData?.Amount?.toString();
      settaxtotal(totalTax?.toString());

      document.getElementById("unit").disabled = true;
      document.getElementById("customer").disabled = true;
      setNarration(props.vouchData?.Narration);
    }
  }, [props.vouchData]);
  useEffect(() => {
    if (props.vouchData?.UnitVoucherId) {
      console.log("UnitName", props.vouchData?.Contract?.Unit?.UnitName);
      document.getElementById("unit").value =
        props.vouchData?.Contract?.Unit?.UnitName;
    }
  }, [statecustomerlist]);
  useEffect(() => {
    if (props.vouchData?.UnitVoucherId) {
      console.log("unitState", stateunitlist);
      props.vouchData?.SavedInstallment?.Installment?.forEach((itm1) => {
        stateunitlist?.ContractInstallments?.forEach((itm2) => {
          if (itm1.Particular === "Advance payment") {
            setadvCheck(true);
          } else if (itm1.InstallmentId === itm2.ContractInstallmentId) {
            itm2.checked = true;
          }
        });
      });
    }
  }, [props.vouchData, stateunitlist]);
  useEffect(() => {
    if (props.vouchData?.UnitVoucherId) {
      props.vouchData?.SavedInstallment?.forEach((itm1) => {
        if (itm1.Particular === "Advance payment") {
          setadvCheck(true);
        }
      });
    }
  }, [props.vouchData, stateunitlist]);

  useEffect(() => {
    if (props.vouchData?.VoucherTaxSubmitedDetail) {
      props.vouchData.VoucherTaxSubmitedDetail?.forEach((item) => {
        resultalltaxes.data?.forEach((item2) => {
          if (item.TaxId === item2.TaxId) {
            item2.Checked = true;
            item2.TaxPer = item.TaxPer;
            item2.TaxAmount = item.TaxAmount;
            item2.TaxSubmitedDetailId = item.TaxSubmitedDetailId;
          }
        });
      });
    }
  }, [resultalltaxes]);
  const handleChange = (v) => {
    let taxAmt = 0;
    resultalltaxes.data?.forEach((item2) => {
      if (v === 0) {
        item2.Checked = false;
        setamount("0");
      } else if (item2.Checked === true) {
        item2.TaxAmount = parseFloat((item2.TaxPer / 100) * Number(v));
        taxAmt = taxAmt + parseFloat((item2.TaxPer / 100) * Number(v));
      }
    });
    settaxtotal(taxAmt?.toString());
  };

  useEffect(() => {
    if (props.vouchData?.UnitVoucherId) {
      document.getElementById("customer").value =
        props.vouchData?.Contract?.CustomerCnicNavigation?.CustomerName;
      setcustomerCnic(
        props.vouchData?.Contract?.CustomerCnicNavigation?.CustomerCnic
      );
      // setcustomerCnic({
      //   CNIC: props.vouchData?.Contract?.CustomerCnicNavigation?.CustomerCnic2,
      // });
      let cust = unitReceiptData.data?.find(
        (itm) =>
          itm.CustomerCnic2 ===
          props.vouchData?.Contract?.CustomerCnicNavigation?.CustomerCnic2
      );
      setcustomerlist(cust);
    }
  }, [unitReceiptData]);
  console.log("customerList", statecustomerlist);
  useEffect(() => {
    dispatch(getallunitreceipt());
    dispatch(getallProject());
    dispatch(getallbanks());
    dispatch(getallcash());
    dispatch(getallTaxTable());
  }, [dispatch]);

  const handleDisableBtn = () => {
    setdisableBtn(true);
  };
  return (
    <>
      <div className="col-md-12 text-right">
        <span>
          {resultsproject.loading ||
          unitReceiptData.loading ||
          resultsallbanks.loading ||
          resultsallcash.loading ||
          resultalltaxes.loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </span>
      </div>
      <div className="row ">
        <div className="col-md-1 mt-4  p-0">
          <label className="input_label m-0 pl-md-4">Project:</label>
        </div>
        <div className="col-md-3 mt-4  ">
          {props.vouchData?.UnitVoucherId ? (
            <input
              className="input_styling ml-md-3 "
              type="text"
              name=""
              disabled={props.vouchData?.UnitVoucherId ? true : false}
              id="project"
            />
          ) : (
            <select
              onChange={handleDisableBtn}
              className="input_styling ml-md-3 "
              id="project"
            >
              <option>Select</option>
              {resultsproject.data.map((val, index) =>
                parseInt(`${localStorage.getItem("ProjectId")}`) ===
                parseInt(val.ProjectId) ? (
                  <option
                    value={val.ProjectId}
                    key={index}
                    data-id={val.ProjectId}
                  >
                    {val.ProjectName}
                  </option>
                ) : null
              )}
            </select>
          )}
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4  p-0">
          <label className="input_label m-0 pl-md-4">Customer:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <input
            disabled={props.vouchData?.UnitVoucherId ? true : false}
            type="text"
            className="input_styling ml-md-3 "
            onChange={() => {
              var inputValue = document.getElementById("customer").value;
              var options = document.getElementById("customerList").childNodes;
              console.log(options);
              for (var i = 0; i < options.length; i++) {
                if (options[i].value === inputValue) {
                  setcustomerCnic(options[i].attributes.attributeId.value);
                  setcustomerlist(
                    unitReceiptData.data[options[i].attributes.idx.value]
                  );
                  setunitlist();

                  break;
                }
                setcustomerCnic("");
                setcustomerlist({});
                setunitlist();
              }
            }}
            list="customerList"
            id="customer"
          />
          <span className="estaric">*</span>
          <datalist id={"customerList"}>
            {unitReceiptData.data.map((val, index) => (
              <option
                value={val.CustomerName + ` (${val.CustomerCnic2})`}
                key={index}
                idx={index}
                attributeId={val.CustomerCnic}
              >
                {val.CustomerCnic2}
              </option>
            ))}
          </datalist>

          {/* <select onChange={() => {
                        handleDisableBtn()
                        setcustomerlist(unitReceiptData.data[document.getElementById("customer").options[document.getElementById("customer").selectedIndex].dataset.idx]);
                        setunitlist(); document.getElementById("unit").selectedIndex = 0
                    }}>
                        <option>Select</option>
                        {unitReceiptData.data.map((val, index) => (

                            <option value={val.CustomerCnic} data-idx={index} data-id={val.CustomerCnic}>{val.CustomerName}</option>

                        ))}
                    </select><span className="estaric">*</span> */}
        </div>
        <div className="col-md-1 mt-4 text-left p-0">
          <label className="input_label m-0 pl-md-4">Unit:</label>
        </div>
        <div className="col-md-3 mt-4  ">
          {props.vouchData?.UnitVoucherId ? (
            <input
              disabled={props.vouchData?.UnitVoucherId ? true : false}
              className="input_styling ml-md-3 "
              type="text"
              name=""
              id="unit"
            />
          ) : (
            <select
              className="input_styling ml-md-3 "
              id="unit"
              onChange={() => {
                handleDisableBtn();

                setunitlist(
                  statecustomerlist.Contract[
                    document.getElementById("unit").options[
                      document.getElementById("unit").selectedIndex
                    ].dataset.idx
                  ]
                );
              }}
            >
              <option>Select</option>
              {statecustomerlist !== undefined &&
              statecustomerlist.Contract !== undefined
                ? statecustomerlist?.Contract?.map((x, index) => (
                    <option
                      key={index}
                      value={x.UnitId}
                      data-idx={index}
                      data-id={x.Unit?.UnitId}
                    >
                      {x.Unit.UnitName}
                    </option>
                  ))
                : null}
            </select>
          )}
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Date:</label>
        </div>
        <div className="col-md-3 mt-4  ">
          <input
            disabled={props.vouchData?.UnitVoucherId ? true : false}
            type="date"
            onChange={handleDisableBtn}
            className="input_styling ml-md-3"
            id="Date"
            defaultValue={moment().format("YYYY-MM-DD")}
          ></input>
          <span className="estaric">*</span>
        </div>

        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4 ">Type:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <select
            disabled={props.vouchData?.UnitVoucherId ? true : false}
            id="vouchertype"
            onChange={(e) => {
              handleDisableBtn();
              if (e.target.value === "Bank Voucher") {
                document.getElementById("bankdiv").style.display = "block";
                document.getElementById("cashdiv").style.display = "none";
              } else if (e.target.value === "Cash Voucher") {
                document.getElementById("bankdiv").style.display = "none";
                document.getElementById("cashdiv").style.display = "block";
              } else {
                document.getElementById("bankdiv").style.display = "none";
                document.getElementById("cashdiv").style.display = "none";
              }
            }}
            type="text"
            className="input_styling ml-md-3 "
          >
            <option>Select</option>

            <option selected>Cash Voucher</option>
            <option>Bank Voucher</option>
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Voucher No:</label>
        </div>
        <div className="col-md-3 mt-4  ">
          <input
            type="text"
            disabled
            value={
              props.vouchData?.UnitVoucherId
                ? props.vouchData?.UnitVoucherId
                : vouchernumber
            }
            className="input_styling ml-md-3"
            id="Voucher"
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>

      <span id="cashdiv">
        <div className="row  ">
          <div className="col-md-4  p-0"></div>
          <div className="col-md-1 mt-4  p-0">
            <label className="input_label m-0 pl-md-4">Cash:</label>
          </div>
          <div className="col-md-3 mt-4  ">
            <select
              disabled={props.vouchData?.UnitVoucherId ? true : false}
              onChange={handleDisableBtn}
              id="cashs"
              className="input_styling  ml-md-3"
            >
              <option>Select</option>
              {resultsallcash.data.map((x) => (
                <option selected data-id={x.AccountId}>
                  {x.AccountTitle}
                </option>
              ))}
            </select>
            <span className="estaric">*</span>
          </div>
          <div className="col-md-1 mt-4  p-0">
            <label className="input_label m-0 pl-md-4">Narration:</label>
          </div>

          <div className="col-md-3 mt-4 p-0 pl-2">
            <textarea
              disabled={props.vouchData?.UnitVoucherId ? true : false}
              onChange={(e) => {
                setNarration(e.target.value);
                handleDisableBtn();
              }}
              className="input_address ml-md-3"
              value={narration}
            ></textarea>
            <span className="estaric"></span>
          </div>
        </div>
      </span>

      <span id="bankdiv" style={{ display: "none" }}>
        <div className="row  ">
          <div className="col-md-1 mt-4  p-0">
            <label className="input_label m-0 pl-md-4">Bank:</label>
          </div>
          <div className="col-md-3 mt-4  ">
            <select
              disabled={props.vouchData?.UnitVoucherId ? true : false}
              onChange={handleDisableBtn}
              id="banks"
              className="input_styling  ml-md-3"
            >
              <option>Select</option>
              {resultsallbanks.data.map((x) => (
                <option value={x.AccountId} data-id={x.AccountId}>
                  {x.AccountTitle}
                </option>
              ))}
            </select>
            <span className="estaric">*</span>
          </div>

          <div className="col-md-1 mt-4 p-0">
            <label className="input_label m-0 pl-md-4">Cheque No:</label>
          </div>
          <div className="col-md-3 mt-4  ">
            <input
              disabled={props.vouchData?.UnitVoucherId ? true : false}
              id="chequeno"
              type="text"
              onChange={handleDisableBtn}
              placeholder="Cheque No"
              className="input_styling ml-md-3"
            ></input>
            <span className="estaric">*</span>
          </div>
          <div className="col-md-1 mt-4  p-0">
            <label className="input_label m-0 pl-md-4">Narration:</label>
          </div>
          <div className="col-md-3 mt-4 p-0 ">
            <textarea
              disabled={props.vouchData?.UnitVoucherId ? true : false}
              onChange={(e) => {
                setNarration(e.target.value);
                handleDisableBtn();
              }}
              className="input_address ml-md-3"
              value={narration}
            ></textarea>
            <span className="estaric"></span>
          </div>
        </div>
      </span>

      <div className="mt-4">
        <div className=" table_height mt-4">
          <table className="table table-borderless m-0">
            <thead>
              <tr>
                <th>Payment</th>
                <th>Amount</th>
                <th> {!props.vouchData?.UnitVoucherId && "DueDate"}</th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              {props.vouchData?.SavedInstallment?.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td>{item.Particular}</td>
                    <td>{item.Amount?.toLocaleString()}</td>
                    <td></td>
                    <td></td>
                  </tr>
                );
              })}{" "}
              {stateunitlist !== undefined ? (
                stateunitlist?.AdvanceAmount -
                  stateunitlist?.AdvanceReceived !==
                0 ? (
                  <tr>
                    <td>Advance</td>
                    <td>
                      {(
                        stateunitlist?.AdvanceAmount -
                        stateunitlist?.AdvanceReceived
                      ).toLocaleString()}
                    </td>
                    <td></td>
                    <td className="text-center">
                      <input
                        id="advCheck"
                        type="checkbox"
                        checked={advCheck}
                        name="check"
                        onClick={(e) => {
                          handleDisableBtn();
                          setadvCheck(!advCheck);
                          let amount = 0;
                          let installmentlist = [];
                          if (e.target.checked === true) {
                            installmentlist.push({
                              Particular: "Advance payment",
                              Narration: "advance received",
                              UnitVoucherSavedInstallmentId: 0,
                              UnitReceiptId: "",
                              Amount:
                                parseFloat(stateunitlist.AdvanceAmount) -
                                parseFloat(stateunitlist.AdvanceReceived),
                            });

                            amount =
                              parseFloat(stateunitlist.AdvanceAmount) -
                              parseFloat(stateunitlist.AdvanceReceived);
                          } else {
                            amount = 0;
                          }
                          stateunitlist.ContractInstallments?.map((val) => {
                            if (val.checked === true)
                              amount +=
                                parseFloat(val.DueAmount) -
                                parseFloat(val.ReceivedAmount);
                            // installmentlist.push(val);
                          });
                          document.getElementById("total").value =
                            amount.toLocaleString();
                          document.getElementById("netPaid").value =
                            amount.toLocaleString();
                          handleChange(Number(amount));
                          setamount(amount?.toString());
                          setInstallments(installmentlist);
                          // Installments = [...installmentlist]
                        }}
                      />
                    </td>
                  </tr>
                ) : null
              ) : null}
              {stateunitlist !== undefined
                ? stateunitlist?.ContractInstallments?.length !== 0
                  ? stateunitlist?.ContractInstallments?.map((val, i) => {
                      if (val.InstallmentStatus === "PENDING") {
                        return (
                          <tr>
                            <td>{val.InstallmentNo}</td>
                            <td>
                              {parseFloat(
                                val.DueAmount - val.ReceivedAmount
                              ).toLocaleString()}
                            </td>
                            <td>
                              {moment(val.DueDate?.split("T")[0]).format(
                                "DD-MMM-YYYY"
                              )}
                            </td>
                            <td className="text-center">
                              {`${localStorage.getItem("ProjectOwner")}` !=
                              "PERSONAL" ? (
                                <input
                                  id="installmentcheck"
                                  type="checkbox"
                                  name="check"
                                  disabled={
                                    i !== 0
                                      ? stateunitlist?.ContractInstallments[
                                          i - 1
                                        ]?.checked === true
                                        ? false
                                        : true
                                      : false
                                  }
                                  onClick={(e) => {
                                    if (e.target.checked === true) {
                                      val.checked = true;
                                    } else {
                                      val.checked = false;
                                      if (i >= 0) {
                                        if (
                                          stateunitlist.ContractInstallments[
                                            i + 1
                                          ] &&
                                          stateunitlist.ContractInstallments[
                                            i + 1
                                          ].checked === true
                                        ) {
                                          stateunitlist.ContractInstallments.map(
                                            (itm, i2) => {
                                              if (
                                                i !== i2 &&
                                                itm.checked === true
                                              ) {
                                                console.log(itm);
                                                itm.checked = false;
                                              }
                                            }
                                          );
                                        }
                                      }
                                    }
                                    let amount = 0;
                                    let installmentlist = [];

                                    if (
                                      document.getElementById("advCheck") !==
                                        null &&
                                      document.getElementById("advCheck")
                                        .checked === true
                                    ) {
                                      installmentlist.push({
                                        Particular: "Advance payment",
                                        Narration: "advance received",
                                        UnitVoucherSavedInstallmentId: 0,
                                        UnitReceiptId: "",
                                        Amount:
                                          stateunitlist.AdvanceAmount -
                                          stateunitlist.AdvanceReceived,
                                      });
                                      amount =
                                        stateunitlist.AdvanceAmount -
                                        stateunitlist.AdvanceReceived;
                                    } else {
                                      amount = 0;
                                    }
                                    stateunitlist.ContractInstallments.map(
                                      (val) => {
                                        if (val.checked === true) {
                                          amount +=
                                            parseFloat(val.DueAmount) -
                                            parseFloat(val.ReceivedAmount);
                                          // installmentlist.push(val);
                                          installmentlist.push({
                                            InstallmentId:
                                              val.ContractInstallmentId,
                                            Particular: "Installment payment",
                                            Narration: "Installment received",
                                            UnitVoucherSavedInstallmentId: 0,
                                            UnitReceiptId: "",
                                            Amount:
                                              parseFloat(val.DueAmount) -
                                              parseFloat(val.ReceivedAmount),
                                          });
                                        }
                                      }
                                    );
                                    document.getElementById("total").value =
                                      amount.toLocaleString();
                                    document.getElementById("netPaid").value =
                                      amount.toLocaleString();
                                    setInstallments(installmentlist);

                                    // Installments = [...installmentlist]
                                  }}
                                />
                              ) : (
                                <input
                                  id="installmentcheck"
                                  type="checkbox"
                                  checked={val.checked}
                                  disabled={
                                    i !== 0
                                      ? stateunitlist?.ContractInstallments[
                                          i - 1
                                        ]?.checked === true
                                        ? false
                                        : true
                                      : false
                                  }
                                  name="check"
                                  onClick={(e) => {
                                    handleDisableBtn();
                                    if (e.target.checked === true) {
                                      val.checked = true;
                                    } else {
                                      val.checked = false;
                                      if (i >= 0) {
                                        if (
                                          stateunitlist.ContractInstallments[
                                            i + 1
                                          ] &&
                                          stateunitlist.ContractInstallments[
                                            i + 1
                                          ].checked === true
                                        ) {
                                          stateunitlist.ContractInstallments.map(
                                            (itm, i2) => {
                                              if (
                                                i !== i2 &&
                                                itm.checked === true
                                              ) {
                                                console.log(itm);
                                                itm.checked = false;
                                              }
                                            }
                                          );
                                        }
                                      }
                                    }
                                    let amount = 0;
                                    let installmentlist = [];

                                    if (
                                      document.getElementById("advCheck") !==
                                        null &&
                                      document.getElementById("advCheck")
                                        .checked === true
                                    ) {
                                      installmentlist.push({
                                        Particular: "Advance payment",
                                        Narration: "advance received",
                                        UnitReceiptId: "",
                                        Amount:
                                          stateunitlist.AdvanceAmount -
                                          stateunitlist.AdvanceReceived,
                                      });
                                      amount =
                                        stateunitlist.AdvanceAmount -
                                        stateunitlist.AdvanceReceived;
                                    } else {
                                      amount = 0;
                                    }
                                    stateunitlist.ContractInstallments.map(
                                      (val) => {
                                        if (val.checked === true) {
                                          amount +=
                                            parseFloat(val.DueAmount) -
                                            parseFloat(val.ReceivedAmount);
                                          // installmentlist.push(val);
                                          installmentlist.push({
                                            InstallmentId:
                                              val.ContractInstallmentId,
                                            Particular: "Installment payment",
                                            UnitVoucherSavedInstallmentId: 0,
                                            Narration: "Installment received",
                                            UnitReceiptId: "",
                                            Amount:
                                              parseFloat(val.DueAmount) -
                                              parseFloat(val.ReceivedAmount),
                                          });
                                        }
                                      }
                                    );
                                    document.getElementById("total").value =
                                      amount.toLocaleString();
                                    document.getElementById("netPaid").value =
                                      amount.toLocaleString();
                                    setInstallments(installmentlist);
                                    setamount(amount?.toString());
                                    handleChange(Number(amount));
                                    // Installments = [...installmentlist]
                                  }}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      }
                    })
                  : null
                : null}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row  ">
        <div className="col-md-7 p-0"></div>
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Total:</label>
        </div>
        <div className="col-md-3 mt-4    pr-0 pl-0">
          <input
            type="text "
            className="input_styling  ml-md-3"
            id="total"
            disabled
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-7 mt-3  p-0"></div>
        <div className="col-md-2 mt-2  text-left p-0">
          <label className="input_label m-0">Net Paid:</label>
        </div>
        <div className="col-md-3 mt-2   mt-3  pr-0 pl-0">
          <input
            type="text "
            className="input_styling ml-md-3"
            id="netPaid"
            disabled={props.vouchData?.UnitVoucherId ? true : false}
            onChange={(e) => {
              handleDisableBtn();
              if (
                parseFloat(e.target.value.replace(/,/g, "")) <=
                parseFloat(
                  document.getElementById("total")?.value?.replace(/,/g, "")
                )
              ) {
                setamount(e.target.value);
                let taxAmt = 0;

                resultalltaxes.data?.forEach((item2) => {
                  if (item2.Checked === true) {
                    item2.TaxAmount = parseFloat(
                      (item2.TaxPer / 100) *
                        parseFloat(e.target.value.replace(/,/g, ""))
                    );
                    taxAmt =
                      taxAmt +
                      parseFloat(
                        (item2.TaxPer / 100) *
                          parseFloat(e.target.value.replace(/,/g, ""))
                      );
                  }
                });
                settaxtotal(taxAmt?.toString());

                if (e.target.value.length > 0) {
                  document.getElementById("netPaid").value = parseInt(
                    e.target.value.replace(/,/g, "")
                  ).toLocaleString();
                }
              } else {
                setamount("0");
                settaxtotal("0");
                document.getElementById("netPaid").value = "";
                toast.info("Paid amount must be less or equal to total amount");
              }
            }}
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-7 mt-3  p-0"></div>
        <div className="col-md-2 mt-3 text-left p-0">
          <label className="input_label m-0">Net Amt:</label>
        </div>
        <div className="col-md-3 mt-3    pr-0 pl-0">
          <input
            type="text"
            className="input_styling  ml-md-3 "
            disabled
            id="taxnet"
            value={parseFloat(
              parseFloat(stateamount.replace(/,/g, "")) - parseFloat(taxtotal)
            ).toLocaleString()}
          ></input>
        </div>
      </div>

      <div className=" float-right mt-md-4 ">
        {loading1 && (
          <Loader type="ThreeDots" color="green" height={40} width={40} />
        )}
        <span>
          <button
            className="save_btn ml-2  "
            onClick={() => {
              setshowDetailModal(true);
            }}
          >
            <BsFillInfoSquareFill size="1em" className="mr-1" />
            Detail
          </button>
        </span>
        {localStorage.getItem("UserName") === "Admin" &&
          props.vouchData?.UnitVoucherId && (
            <span>
              <button
                className="save_btn ml-2  "
                id="headingsave"
                onClick={() => setRevertModal(true)}
              >
                <RiArrowGoBackFill size="1em" />
                Revert
              </button>
            </span>
          )}
        {!props.vouchData?.UnitVoucherId && (
          <span>
            <button
              className="save_btn ml-2  "
              onClick={() => {
                if (document.getElementById("netPaid").value !== "") {
                  setamount(document.getElementById("netPaid").value);
                  setshowTaxmodal(true);
                }
              }}
            >
              Add Tax
            </button>
          </span>
        )}
        <span>
          <button className="cancel_btn ml-2  " onClick={props.cancelunittable}>
            Cancel
          </button>
        </span>
        {!props.vouchData?.UnitVoucherId && (
          <span>
            <button
              disabled={!disableBtn}
              className="save_btn ml-2  "
              id="unitsave"
              onClick={savedata}
            >
              <BsCloudUpload size="1.3em" />
              Save
            </button>
          </span>
        )}
        {localStorage.getItem("UserName") === "Admin" && (
          <span>
            <button
              disabled={disableBtn}
              className="save_btn ml-2  "
              id="submitContract"
              onClick={submitdata}
            >
              {/* <RiSave3Fill size="1.3em" /> */}
              Submit
            </button>
          </span>
        )}
      </div>
      {/* <div id="mymodal" className="modal">

                <div className="modal-content-cat">
                    <div className="row">
                        <div className="col-12  page_heading">
                            <h4 className="  text-center pt-2">Select Taxes</h4>
                            <span
                                class="close pr-2  "
                                onClick={closemodal
                                }
                            >
                                &times;
                            </span>

                        </div>
                    </div>
                    <div className="row">
                        <div className=" col-12  table_height pr-0 pl-0">

                            <div className="m-2 mt-4">

                                <table className="table table-borderless m-0">
                                    <thead>

                                        <tr >


                                            <th>Select</th>
                                            <th>Name</th>

                                            <th>Percentage</th>
                                            <th>Tax Amount</th>



                                        </tr>
                                    </thead>
                                    <tbody>
                                        {resultalltaxes.data.map((val, index) => (
                                            <tr key={index}  >

                                                <td><input id={`advCheck${index}`} type="checkbox" checked={val.Checked ? val.Checked : false} name="check" onChange={(e) => {
                                                    resultalltaxes.data[index].Checked = e.target.checked;

                                                    handleDisableBtn()
                                                    if (e.target.checked == true) {
                                                        resultalltaxes.data[index].TaxAmount = parseFloat(
                                                            document
                                                                .getElementById(`taxtotal${index}`)
                                                                .value.replace(/,/g, "")
                                                        )
                                                        settaxtotal(parseFloat(parseFloat(taxtotal.replace(/,/g, '')) + parseFloat(document.getElementById(`taxtotal${index}`).value.replace(/,/g, ''))).toLocaleString());


                                                    } else {
                                                        resultalltaxes.data[index].TaxAmount = 0;
                                                        settaxtotal(parseFloat(parseFloat(taxtotal.replace(/,/g, '')) - parseFloat(document.getElementById(`taxtotal${index}`).value.replace(/,/g, ''))).toLocaleString());

                                                    }

                                                }} /></td>
                                                <td>{val.TaxName}</td>

                                                <td><input type="text" id={`taxper${index}`} defaultValue={val.TaxPer} onChange={(e) => {
                                                    handleDisableBtn()

                                                    resultalltaxes.data[index].TaxPer = e.target.value;
                                                    resultalltaxes.data[index].TaxAmount =
                                                        parseFloat(
                                                            (e.target.value / 100) *
                                                            parseFloat(
                                                                document
                                                                    .getElementById("total")
                                                                    .value.replace(/,/g, "")
                                                            )
                                                        )
                                                    document.getElementById(`taxtotal${index}`).value = parseFloat((e.target.value / 100) * parseFloat(document.getElementById("total").value.replace(/,/g, ''))).toLocaleString();

                                                }}></input></td>

                                                <td><input type="text" id={`taxtotal${index}`} value={parseFloat((parseFloat(val.TaxPer) / 100) * parseFloat(stateamount.replace(/,/g, ''))).toLocaleString()} disabled></input>
                                                </td>


                                            </tr>
                                        ))}

                                    </tbody>


                                </table>
                            </div>
                            <div className="row">

                                <div className=" col-11 text-right pt-2 pr-0 pl-0">
                                    <label className="input_label m-0">  Total Tax:</label> <input type="text" value={taxtotal.toLocaleString()} disabled></input>

                                </div></div>
                        </div>
                    </div>

                </div>
            </div> */}
      {showDetailModal ? (
        <ScreenInfoModal
          setshowModal={setshowDetailModal}
          screenName="Unit Receipt"
        />
      ) : null}
      {showTaxmodal === true ? (
        <TaxModal
          enableSaveBtn={handleDisableBtn}
          setshowTaxmodal={setshowTaxmodal}
          stateamount={stateamount}
          taxtotal={taxtotal}
          settaxtotal={settaxtotal}
          vouchData={props.vouchData}
          allvalues={allvalues}
          setallvalues={setallvalues}
        />
      ) : null}
      {RevertModal && (
        <RectifiyModal
          entryId={document.getElementById("Voucher").value}
          entryType={"Unit Receipt"}
          addedById={props.vouchData.AddedById}
          setshowModal={setRevertModal}
        />
      )}
    </>
  );
};

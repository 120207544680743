import { useEffect, useRef } from "react";
import { useState } from "react";
import { GrPrint } from "react-icons/gr";
import { RiFileEditLine } from "react-icons/ri";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { getallgeneralpay } from "../../../actions/getgeneralpay";
import GeneralPayPrint from "../../accpayablepages/vouchersprints/paymentprint";
import { MdDeleteForever } from "react-icons/md";
import axios from "axios";
import GeneralPaymentDeleteVoucherModal from "./GeneralPaymentDeleteVoucherModal";
import GeneralPaymentTransactionDetailModal from "./GeneralPaymentTransactionDetailModal";
import { CSVLink } from "react-csv";
import GeneralPaymentModal from "./GeneralPaymentModal";


export const GeneralPaymentTable = (props) => {
  const resultsgetgeneralpay = useSelector((state) => state.getAllGeneralPay);
  const [TransactionData, setTransactionData] = useState();
  const [filterdata, setfilterdata] = useState();
  const [statetoggle, settoggle] = useState(true);
  const [printData, SetPrintData] = useState();
  const [debitState, setdebitState] = useState(0);
  const [creditState, setcreditState] = useState(0);
  const [loading, setloading] = useState(false);
  const rolesdata = useSelector((state) => state.GetAllRole);
  const [selectedVouchers, setSelectedVouchers] = useState([]);
  const [rendarComp, setrendarComp] = useState(false);

  const [filterObject, setfilterObject] = useState({
    date: "",
    voucherNumber: "",
    account: "",
    status: "",
  });

  const [DeleteVoucherData, setDeleteVoucherData] = useState({
    Email: "",
    Password: "",
    Reason: "",
  });

  const handelDeleteVoucherDataChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setDeleteVoucherData({ ...DeleteVoucherData, [name]: value });
  };

  // print function
  const componentRef = useRef();

  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    if (printData !== undefined) {
      printPaymentRec();
    }
  }, [printData, statetoggle]);
  const [printDataModal, SetPrintDataModal] = useState();
  // print function

  const onTextChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setfilterObject({ ...filterObject, [name]: value });
    if (
      document.getElementById("accountfilter").value === "" &&
      document.getElementById("datefilter").value === "" &&
      document.getElementById("voucherfilter").value === "" &&
      document.getElementById("submittedId")?.value === "all"
    ) {
      setfilterdata(resultsgetgeneralpay.data);
    } else {
      let suggestions = [];
      {
        const regex = new RegExp(
          document.getElementById("accountfilter").value,
          "i"
        );
        const regex1 = new RegExp(
          document.getElementById("voucherfilter").value,
          "i"
        );
        const regex2 = new RegExp(
          document.getElementById("datefilter").value,
          "i"
        );

        suggestions = resultsgetgeneralpay.data
          .sort()
          .filter(
            (val) =>
              (document.getElementById("accountfilter").value === "" ||
                regex.test(
                  val.Transaction[0] === undefined
                    ? val.GeneralVoucherSaved?.DebitAccount?.AccountTitle
                    : val.Transaction[0]?.Account.DebitAccount
                )) &&
              (document.getElementById("voucherfilter").value === "" ||
                regex1.test(val.GeneralVoucherId)) &&
              (document.getElementById("datefilter").value === "" ||
                regex2.test(val.EntryDate)) &&
              (document.getElementById("submittedId").value === "all"
                ? val.Submitted === false || val.Submitted === true
                : document.getElementById("submittedId").value === "false"
                ? val.Submitted === false
                : val.Submitted === true)
          );
      }
      setfilterdata(suggestions);
    }
    // const tdValue = value === "true" ? "yes" : "no";
    // document.getElementById("tdId").textContent = tdValue;
  };
  const dispatch = useDispatch();
  useEffect(() => {
    let data = {};
    dispatch(getallgeneralpay(data));
  }, [dispatch]);
  function showmodal(data) {
    document.getElementById("mymodal").style.display = "block";
    setTransactionData(data);
    var credit = 0;
    var debit = 0;
    data?.map((val) =>
      val.TransactionType === "CREDIT"
        ? (credit += parseFloat(val.Amount))
        : val.TransactionType === "DEBIT"
        ? (debit += parseFloat(val.Amount))
        : null
    );
    setdebitState(debit);
    setcreditState(credit);
  }
  function closemodal() {
    document.getElementById("mymodal").style.display = "none";
  }
  function showmodal2(data) {
    document.getElementById("mymodal2").style.display = "block";
  }
  function closemodal2() {
    document.getElementById("mymodal2").style.display = "none";
    setDeleteVoucherData({
      Email: "",
      Password: "",
      Reason: "",
    });
  }
  function ongo() {
    let data = {
      ProjectId: `${localStorage.getItem("ProjectId")}`,
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
    };

    dispatch(getallgeneralpay(data));
  }
  useEffect(() => {
    setfilterdata(resultsgetgeneralpay.data);
  }, [resultsgetgeneralpay]);

  function deletegeneralPaymentVoucher() {
    if (DeleteVoucherData?.Email === "") {
      return toast.info("Email is Required");
    } else if (DeleteVoucherData?.Password === "") {
      return toast.info("Password is Required");
    } else if (DeleteVoucherData?.Reason === "") {
      return toast.info("Reason is Required");
    }
    setloading(true);

    axios({
      method: "post",
      url: `${window.$url}Scripts/DeleteAllUnsubmittedVouchers`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
      data: {
        Email: DeleteVoucherData?.Email,
        Password: DeleteVoucherData?.Password,
        Reason: DeleteVoucherData?.Reason,
        voucherId: selectedVouchers,
      },
    }).then(
      (result) => {
        console.log(result);
        // document.getElementById("headingsave").disabled = false;
        if (result.data.IsSuccess === true) {
          setDeleteVoucherData({
            Email: "",
            Password: "",
            Reason: "",
          });
          let data = {};
          dispatch(getallgeneralpay(data));
          toast.info(result.data.Message);

          setloading(false);
          setSelectedVouchers([]);
          closemodal2();
        } else {
          toast.info(result.data.Message);
          setDeleteVoucherData({
            Email: "",
            Password: "",
            Reason: "",
          });

          setloading(false);
        }
      },
      (error) => {
        toast.info("Someting went wrong");
        // document.getElementById("headingsave").disabled = false;
      }
    );
  }

  function handleCheckboxChange(i) {
    let arr = filterdata
      ?.filter((itm) => itm.checked)
      .map((itm) => itm.GeneralVoucherId);
    setSelectedVouchers(arr);
  }
  const [CsvData, setCsvData] = useState([]);
  useEffect(() => {
    let arr = [];
    var i = 1;

    filterdata?.forEach((val) => {
      let year = val.EntryDate.slice(0, 4);
      let day = val.EntryDate.slice(8, 10);
      let month = val.EntryDate.slice(5, 7);
      arr.push({
        Sr: i++,
        Date: day + "-" + month + "-" + year,
        "Voucher No": val.GeneralVoucherId,
        Account:
          val.Transaction[0] !== undefined
            ? val.Transaction[0].Account !== null &&
              val.Transaction[0].Account !== undefined
              ? val.Transaction[0].Account.AccountTitle
              : ""
            : val.GeneralVoucherSaved?.DebitAccount?.AccountTitle,
        Narration: val?.Narration,
        Amount: parseFloat(val.Amount).toLocaleString(),
        User: val?.AddedBy?.UserName,
        Submitted: val.Submitted ? "True" : "False",
      });
    });
    setCsvData(arr);
  }, [filterdata]);
  return (
    <>
      <div hidden>
        <GeneralPayPrint ref={componentRef} printData={printData} />
      </div>

      <div className="row pt-3">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            autoComplete="off"
            name="date"
            value={filterObject?.date}
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
            type="date"
            className="input_styling "
            id="datefilter"
            onChange={onTextChange}
            placeholder="Date"
          />
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            autoComplete="off"
            name="voucherNumber"
            value={filterObject?.voucherNumber}
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
            type="text"
            className="input_styling "
            id="voucherfilter"
            onChange={onTextChange}
            placeholder="Voucher"
          />
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            autoComplete="off"
            name="account"
            value={filterObject?.account}
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
            type="text"
            className="input_styling "
            id="accountfilter"
            onChange={onTextChange}
            placeholder="Account"
          ></input>
        </div>
        <div className="col-md-2 pr-0 pl-0 ">
          <select
            autoComplete="off"
            name="status"
            value={filterObject?.status}
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
            className="input_styling "
            id="submittedId"
            onChange={onTextChange}
          >
            <option value={"all"}>All</option>
            <option value={false}>Pending</option>
            <option value={true}>Submitted</option>
          </select>
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <GeneralPaymentModal />
        </div>
        <div className="col-md-1 pr-0 pl-0">
          {resultsgetgeneralpay.loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </div>
      </div>

      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0"></div>
        <div className="col-md-4  pr-0 pl-0">
          <label className="input_label m-0">From:</label>
          <input type="date" className="input_date ml-3" id="fromdate"></input>
        </div>
        <div className="col-md-4 pr-0 pl-0">
          <label className="input_label m-0">To:</label>
          <input type="date" className="input_date ml-3" id="todate"></input>
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <button className="btn_Go" onClick={ongo}>
            Go
          </button>
        </div>
        <div className="col-md-12 pr-0 pl-0">
          {selectedVouchers.length > 0 && (
            <div className="col-md-12 mt-2">
              <div className="float-right">
                {loading && (
                  <Loader
                    type="ThreeDots"
                    color="green"
                    height={40}
                    width={40}
                  />
                )}
                <span>
                  <button
                    onClick={() => {
                      showmodal2(selectedVouchers);
                    }}
                    className="cancel_btn ml-2"
                  >
                    <MdDeleteForever />
                    Delete All
                  </button>
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="table-responsive vouchers_table_height mt-2">
        <table className="table table-borderless m-0">
          <thead>
            <tr>
              <th>Sr</th>
              <th>{""}</th>
              <th>Date</th>
              <th>Voucher No.</th>
              <th>Account</th>
              <th>Narration</th>
              <th>Amount</th>
              <th>User</th>
              <th>Submitted</th>
              <th className="text-center">
                {rolesdata?.data?.Access?.includes("C") ? (
                  <button
                    className="save_btn"
                    onClick={() => {
                      props.showgeneraltable();
                    }}
                  >
                    New+
                  </button>
                ) : null}
              </th>
            </tr>
          </thead>
          <tbody>
            {filterdata !== undefined
              ? filterdata.map((val, index) => {
                  let year = val.EntryDate.slice(0, 4);
                  let day = val.EntryDate.slice(8, 10);
                  let month = val.EntryDate.slice(5, 7);
                  switch (month) {
                    case "01":
                      month = "Jan";

                      break;
                    case "02":
                      month = "Feb";

                      break;
                    case "03":
                      month = "Mar";

                      break;
                    case "04":
                      month = "Apr";

                      break;
                    case "05":
                      month = "May";

                      break;
                    case "06":
                      month = "Jun";

                      break;
                    case "07":
                      month = "Jul";

                      break;
                    case "08":
                      month = "Aug";

                      break;
                    case "09":
                      month = "Sep";

                      break;
                    case "10":
                      month = "Oct";
                      break;
                    case "11":
                      month = "Nov";
                      break;
                    case "12":
                      month = "Dec";
                      break;
                    default:
                      break;
                  }
                  return (
                    <tr
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        showmodal(val.Transaction);

                      }}
                    >
                      <td
                        className="table_data"
                      >
                        {index + 1}
                      </td>
                      <td>
                        {localStorage.getItem("UserName") === "Admin" ? (
                          <input
                            checked={val.checked ? val.checked : false}
                            key={index}
                            onClick={(e)=>{e.stopPropagation()}}
                            onChange={(e) => {
                              e.stopPropagation();
                              filterdata[index].checked = e.target.checked;
                              setrendarComp(!rendarComp);
                              handleCheckboxChange(index);
                            }}
                            type="checkbox"
                            id={`checkedVoucher${index}`}
                          />
                        ) : null}
                      </td>
                      <td
                        className="table_data"
                        // onClick={() => {
                        //   showmodal(val.Transaction);
                        //   SetPrintDataModal(val.Transaction);
                        // }}
                      >
                        {day + "-" + month + "-" + year}
                      </td>
                      <td
                        className="table_data"
                        onClick={() => {
                          showmodal(val.Transaction);
                          // SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {val.GeneralVoucherId}
                      </td>
                      {val.Transaction[0] !== undefined ? (
                        val.Transaction[0].Account !== null &&
                        val.Transaction[0].Account !== undefined ? (
                          <td
                            className="table_data"
                            onClick={() => {
                              showmodal(val.Transaction);
                              // SetPrintDataModal(val.Transaction);
                            }}
                          >
                            {val.Transaction[0].Account.AccountTitle}
                          </td>
                        ) : (
                          <td
                            className="table_data"
                            // onClick={() => {
                            //   showmodal(val.Transaction);
                            //   SetPrintDataModal(val.Transaction);
                            // }}
                          ></td>
                        )
                      ) : (
                        <td
                          className="table_data"
                          // onClick={() => {
                          //   showmodal(val.Transaction);
                          //   SetPrintDataModal(val.Transaction);
                          // }}
                        >
                          {val.GeneralVoucherSaved?.DebitAccount?.AccountTitle}
                        </td>
                      )}
                      <td
                        className="table_data"
                        // onClick={() => {
                        //   showmodal(val.Transaction);
                        //   SetPrintDataModal(val.Transaction);
                        // }}
                      >
                        {val?.Narration}
                      </td>
                      <td
                        className="table_data"
                        // onClick={() => {
                        //   showmodal(val.Transaction);
                        //   SetPrintDataModal(val.Transaction);
                        // }}
                      >
                        {parseFloat(val.Amount).toLocaleString()}
                      </td>
                      <td className="table_data">{val?.AddedBy?.UserName}</td>
                      <td className="table_data">
                        {val.Submitted ? "True" : "False"}
                      </td>
                      <td className="text-center ">
                        {rolesdata?.data?.Access?.includes("U") ? (
                          <RiFileEditLine
                            title="Edit"
                            size="1em"
                            color={
                              val.Submitted === false ? "black" : "#707070"
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              if (val.Submitted === false) {
                                props.setvoucherData(val.GeneralVoucherSaved);
                              } else {
                                toast.info("Can't edit voucher");
                              }
                              // props.showgeneraltable();
                            }}
                            className="cur_sor"
                          />
                        ) : null}
                        &nbsp;
                        <GrPrint
                          className="cur_sor"
                          onClick={(e) => {
                            e.stopPropagation();
                            SetPrintData(val);
                            settoggle(!statetoggle);
                          }}
                          size="1em"
                        />
                        &nbsp;
                        {/* {localStorage.getItem("UserName") === "Admin" ? (
                          <MdDeleteForever
                            onClick={(e) => {
                              e.stopPropagation();
                              showmodal2(val);
                            }}
                            title="Delete"
                            className="cur_sor"
                            size="1em"
                          />
                        ) : null} */}
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
      <button
        style={{ color: "black !important" }}
        className="print_btn ml-2 float-left"
      >
        <CSVLink
          style={{ color: "black" }}
          filename="General_Payment_Csv"
          data={CsvData}
        >
          Download CSV
        </CSVLink>
      </button>
      <GeneralPaymentDeleteVoucherModal
        DeleteVoucherData={DeleteVoucherData}
        handelDeleteVoucherDataChange={handelDeleteVoucherDataChange}
        deletegeneralPaymentVoucher={deletegeneralPaymentVoucher}
        loading={loading}
        closemodal2={closemodal2}
      />
      <GeneralPaymentTransactionDetailModal
        TransactionData={TransactionData}
        closemodal={closemodal}
        debitState={debitState}
        creditState={creditState}
        printDataModal={printDataModal}
      />
    </>
  );
};

import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BsCloudUpload } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { getallPaySlipTable } from "../../actions/HRactions/payslipaction";
import { getallAllowances, getallDeduction } from "../../actions/HRactions/designationaction";

import axios from "axios";
import { MdDeleteForever } from "react-icons/md";
import { FiEdit2 } from "react-icons/fi";

import { toast } from "react-toastify";




import Loader from "react-loader-spinner";
import { convertColorToString } from "material-ui/utils/colorManipulator";
const MainPaySlipCom = () => {

    const [stateeditdata, seteditdata] = useState([]);
    const showpaysliptable = () => {
        stateshowpayslip("PaySlipAdd");
    }
    const cancelpaysliptable = () => {
        stateshowpayslip("PaySlipTable");


    }

    const showeditpayslip = () => {
        stateshowpayslip("editpayslip");
    }
    const [showpayslip, stateshowpayslip] = useState("PaySlipTable");

    return (

        <>
            <div className="row ">
                <div className="dash_background col-12 p-0">
                    <div className="row ">

                        <div className="col-md-12">
                            {showpayslip == "PaySlipTable" ? <PaySlipTable showeditpayslip={showeditpayslip} showpaysliptable={showpaysliptable} cancelpaysliptable={cancelpaysliptable} seteditdata={seteditdata} /> : showpayslip == "editpayslip" ? <EditPaySlip stateeditdata={stateeditdata} cancelpaysliptable={cancelpaysliptable} /> : showpayslip == "PaySlipAdd" ? <PaySlipAdd cancelpaysliptable={cancelpaysliptable} /> : null}
                        </div>


                    </div>


                </div>
            </div>
        </>

    )
}
export default MainPaySlipCom;
export const PaySlipAdd = (props) => {
    const [payslipdata, setpayslipdata] = useState([]);
    let dispatch = useDispatch();
    const savedata = () => {
        if (document.getElementById("date").value === "") {
            toast.info("Select Month");

        }



        else {



            document.getElementById("generatesave").disabled = true;
            axios({
                method: "post",
                url: `${window.$url}Payslip/Generate?date=${document.getElementById("date").value}`,

                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "content-type": "application/json",
                },
            }).then(
                (result) => {
                    document.getElementById("generatesave").disabled = false;
                    if (result.data.IsSuccess === true) {


                        setpayslipdata(result.data.Payslips);
                        dispatch(getallPaySlipTable());

                    }
                    else {
                        toast.info(result.data.Message);
                    }


                },
                (error) => {
                    toast.info("Someting went wrong");
                    document.getElementById("generatesave").disabled = false;

                }
            );

        }
    };
    return (
        <>
            <div className="row  ">
                <div className="dash_background col-12 p-0">
                    < div className="row ">
                        <div className="col-md-12   text-left page_heading">
                            <h4 className="m-0">Generate PaySlips</h4>
                        </div>
                    </div>
                    <div className="row pt-3 ">
                        <div className="col-md-1 pr-0 pl-0">


                            <label className="input_label  m-0">Month:</label>
                        </div>
                        <div className="col-md-3   pr-0 pl-0">


                            <input type="month" className="input_styling " id="date" ></input>
                        </div>
                        <div className="col-md-7   pr-0 pl-0">


                            <button className="print_btn float-right" id="generatesave" onClick={savedata}>Generate</button>


                        </div>
                        <div className="col-md-1   ">


                            <button className="cancel_btn" onClick={() => { props.cancelpaysliptable(); }}>Back</button>


                        </div>
                        <div className="col-md-1 pr-0 pl-0">
                            {/* {designationdatastate.loading ? <Loader type="ThreeDots"
                                color="green"
                                height={40}
                                width={40} /> : null} */}
                        </div>
                    </div>




                    <div className="table-responsive mt-4">

                        <table className="table table-borderless  table-hover m-0">
                            <thead>

                                <tr >

                                    <th>Sr</th>

                                    <th>Employee</th>

                                    <th>Designation</th>
                                    <th>Allowances</th>
                                    <th>Deductions</th>
                                    <th>Leaves</th>
                                    <th>BasicSalary</th>

                                    <th>GrossPay</th>
                                    <th>NetPay</th>









                                </tr>
                            </thead>
                            <tbody>
                                {payslipdata !== undefined ? payslipdata.map((val, index) => (
                                    <tr key={index + 1}>
                                        <td className="cur_sor" >{index + 1}</td>

                                        <td className="cur_sor" >{val.Employee !== undefined && val.Employee !== null ? val.Employee.EmpName : null}</td>
                                        <td className="cur_sor" >{val.Employee !== undefined && val.Employee.EmpDesignation !== null ? val.Employee.EmpDesignation.DesignationName : null}</td>
                                        <td className="cur_sor" >{val.AllowancesAmount !== null ? val.AllowancesAmount.toLocaleString() : null}</td>
                                        <td className="cur_sor" >{val.DeductionsAmount !== null ? val.DeductionsAmount.toLocaleString() : null}</td>

                                        <td className="cur_sor" >{val.Leaves !== null ? val.Leaves : null}</td>
                                        <td className="cur_sor" >{val.EmployeeSalary !== null && val.EmployeeSalary.BasicSalary !== undefined ? val.EmployeeSalary.BasicSalary.toLocaleString() : null}</td>

                                        <td className="cur_sor" >{val.GrossPay !== null ? val.GrossPay.toLocaleString() : null}</td>


                                        <td className="cur_sor" >{val.NetPay !== null ? val.NetPay.toLocaleString() : null}</td>




                                        {/* <td><FiEdit2 title="Edit" size="1.1em" className="cur_sor" onClick={() => { setallallowances(val.EmployeeSalaryAllowances); props.setstate(val); props.setempid(val.Employee.EmployeeId); props.showdesignationtable(); props.setedit(true); }} /></td> */}



                                    </tr>
                                )) : null}


                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </>
    )
}
export const PaySlipTable = (props) => {
    const designationdatastate = useSelector(state => state.GetAllPaySlipTable);

    const [allDesignationData, setallDesignationData] = useState([]);
    const [filerunits, setfilterunits] = useState();



    let dispatch = useDispatch();

    var suggestionlist = [];

    const onTextChange = (e) => {

        if (document.getElementById("Monthfilter").value === "" && document.getElementById("employeefilter").value === "" && document.getElementById("designationfilter").value === "") {
            setfilterunits(designationdatastate.data.Payslips);

        } else {
            let suggestions = [];
            {
                const regex = new RegExp(document.getElementById("Monthfilter").value, "i");
                const regex1 = new RegExp(document.getElementById("designationfilter").value, "i");
                const regex2 = new RegExp(document.getElementById("employeefilter").value, "i");





                suggestions = designationdatastate.data.Payslips
                    .sort()
                    .filter((val) =>
                        (document.getElementById("Monthfilter").value === "" || regex.test(val.PayslipMonth.substring(0, 7))) &&
                        (document.getElementById("designationfilter").value === "" || regex1.test(val.Employee !== null && val.Employee.EmpDesignation ? val.Employee.EmpDesignation.DesignationName : null)) &&
                        (document.getElementById("employeefilter").value === "" || regex2.test(val.Employee !== null ? val.Employee.EmpName : null))



                    );

            }
            setfilterunits(suggestions);
        }
    };
    // const onTextChange = (e) => {
    //     const value = e.target.value;
    //     if (value === "") {

    //         setfilterunits(designationdatastate.data.Payslips);
    //     } else {
    //         let suggestions = [];
    //         if (value.length > 0) {
    //             const regex = new RegExp(`${value}`, "i");

    //             suggestions = filerunits
    //                 .sort()
    //                 .filter((val) =>
    //                     (regex.test(val.Employee !== null ? val.Employee.EmpName : null) || regex.test(val.Employee !== null && val.Employee.EmpDesignation ? val.Employee.EmpDesignation.DesignationName : null)));

    //         }
    //         suggestionlist = suggestions;
    //         setfilterunits(suggestionlist);
    //     }
    // };
    // const setMonth = (e) => {

    //     const value = e.target.value;
    //     console.log(value);
    //     if (value === "") {

    //         setfilterunits(designationdatastate.data.Payslips);
    //     } else {
    //         let suggestions = [];
    //         if (value.length > 0) {
    //             const regex = new RegExp(`${value}`, "i");

    //             suggestions = designationdatastate.data.Payslips
    //                 .sort()
    //                 .filter((val) =>
    //                     (val.PayslipMonth !== null && regex.test(val.PayslipMonth.substring(0, 7))));

    //         }
    //         suggestionlist = suggestions;
    //         setfilterunits(suggestionlist);
    //     }
    // };
    const openmodalallowancetable = (x) => {

        setallDesignationData(x);

        document.getElementById("modalofleadtable").style.display = "block";

    };

    useEffect(() => {

        dispatch(getallPaySlipTable());

    }, [dispatch]);
    useEffect(() => {
        setfilterunits(designationdatastate.data.Payslips);

    }, [designationdatastate]
    )
    return (
        <>

            <div className="row  ">
                <div className="dash_background col-12 p-0">
                    {/* < div className="row ">
                        <div className="col-md-12   text-left page_heading">
                            <h4 className="m-0">Salaries</h4>
                        </div>
                    </div> */}
                    <div className="row pt-3 ">
                        <div className="col-md-1 pr-0 pl-0">


                            <label className="input_label  m-0">Month:</label>
                        </div>
                        <div className="col-md-2 pr-0 pl-0">


                            <input type="month" className="input_styling " id="Monthfilter" onChange={onTextChange} ></input>
                        </div>
                        <div className="col-md-2   pr-0 pl-0">


                            <input type="text" className="input_styling " id="employeefilter" placeholder="Employee" onChange={onTextChange}></input>
                        </div>
                        <div className="col-md-2 pr-0 pl-0">


                            <input type="text " className="input_styling " id="designationfilter" onChange={onTextChange} placeholder="Designation"></input>
                        </div>



                        <div className="col-md-1 pr-0 pl-0">
                            {designationdatastate.loading ? <Loader type="ThreeDots"
                                color="green"
                                height={40}
                                width={40} /> : null}
                        </div>
                    </div>




                    <div className="table-responsive mt-4">

                        <table className="table table-borderless  table-hover m-0">
                            <thead>

                                <tr >

                                    <th>Sr</th>

                                    <th>Employee</th>

                                    <th>Designation</th>
                                    <th>Allowances</th>
                                    <th>Deductions</th>
                                    <th>Leaves</th>
                                    <th>BasicSalary</th>

                                    <th>GrossPay</th>
                                    <th>NetPay</th>
                                    <th>PayMonth</th>




                                    <th className="text-center">
                                        <button
                                            className="save_btn"
                                            onClick={() => {
                                                props.showpaysliptable();
                                            }}
                                        >
                                            New+
                                        </button>

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {filerunits !== undefined ? filerunits.map((val, index) => (
                                    <tr key={index + 1}>
                                        <td className="cur_sor" >{index + 1}</td>

                                        <td className="cur_sor" >{val.Employee !== undefined && val.Employee !== null ? val.Employee.EmpName : null}</td>
                                        <td className="cur_sor" >{val.Employee !== undefined && val.Employee.EmpDesignation !== null ? val.Employee.EmpDesignation.DesignationName : null}</td>
                                        <td className="cur_sor" >{val.AllowancesAmount !== null ? val.AllowancesAmount.toLocaleString() : null}</td>
                                        <td className="cur_sor" >{val.DeductionsAmount !== null ? val.DeductionsAmount.toLocaleString() : null}</td>

                                        <td className="cur_sor" >{val.Leaves !== null ? val.Leaves : null}</td>
                                        <td className="cur_sor" >{val.EmployeeSalary !== null && val.EmployeeSalary.BasicSalary !== undefined ? val.EmployeeSalary.BasicSalary.toLocaleString() : null}</td>

                                        <td className="cur_sor" >{val.GrossPay !== null ? val.GrossPay.toLocaleString() : null}</td>


                                        <td className="cur_sor" >{val.NetPay !== null ? val.NetPay.toLocaleString() : null}</td>
                                        <td className="cur_sor" >{val.PayslipMonth !== null ? val.PayslipMonth.substring(0, 7) : null}</td>


                                        <td><FiEdit2 title="Edit" size="1.1em" className="cur_sor" onClick={() => { props.seteditdata(val); props.showeditpayslip(); }} /></td>



                                    </tr>
                                )) : null}


                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

            {/* model for show information*/}
            <div id="modalofleadtable" class="modalscreen ">
                <div class="modal-content-screen1 pt-1">
                    <div className="row ">
                        <div className="col-12  page_heading">
                            <h4 className="  text-center ">Allowance Detail</h4>
                            <span
                                class="close pr-2  "
                                onClick={() => {
                                    document.getElementById("modalofleadtable").style.display =
                                        "none";
                                }}
                            >
                                &times;
                            </span>

                        </div>
                    </div>
                    <div className="container modal_container">



                        <div className="table-responsive mt-4">
                            <table class="table mt-2">
                                <thead class="purple whiteText">
                                    <tr>
                                        <th scope="col">Allowance Name</th>
                                        <th scope="col">Allowance Amount</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {allDesignationData !== undefined && allDesignationData.EmployeeSalaryAllowances !== undefined

                                        // allDesignationData.length == 0
                                        ?
                                        allDesignationData.EmployeeSalaryAllowances.map((x, id) => (
                                            <tr key={id}>
                                                <td>{x.Allowance.AllowanceName}</td>
                                                <td>{x.AllowanceAmount}</td>


                                            </tr>
                                        )) : null} */}
                                </tbody>
                            </table>
                        </div>







                        {/* <div className="row">
                            <div className=" col-12 text-right pr-0 pl-0">
                                <button className="print_btn float-right" ><TiPrinter size="1.3em" />Print</button>


                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

        </>
    )
}
export const EditPaySlip = (props) => {


    const resultsallallowances = useSelector(state => state.getallAllowances);
    const resultsalldeduction = useSelector(state => state.GetAllDeductionReducer);

    const [allallowances, setallallowances] = useState(props.stateeditdata.PayslipAllowances);
    const [alldeduction, setalldeduction] = useState(props.stateeditdata.PayslipDeduction);


    let dispatch = useDispatch();

    const [toggle, settoggle] = useState(false);



    function addtoarray() {
        let check = 0;

        allallowances.forEach((x) => {
            if (parseInt(x.AllowanceId) === parseInt(document.getElementById("Allowance").options[document.getElementById("Allowance").selectedIndex].dataset.id)) {
                toast.info("Multiple Allowances cannot be added!");
                check = 1;
            } else {
                check = 0
            }
        })
        if (document.getElementById("Allowance").selectedIndex === 0) {
            toast.info("Select Allowance");
        }
        else if (document.getElementById("Amount").value === "") {
            toast.info("Enter Amount");
        }
        else if (check === 1) {
            toast.info("Select Other Allowance");
        }
        else {
            let listofallallowances = {
                AllowanceId: parseInt(document.getElementById("Allowance").options[document.getElementById("Allowance").selectedIndex].dataset.id),
                Allowance: { AllowanceName: document.getElementById("Allowance").options[document.getElementById("Allowance").selectedIndex].value },
                EmployeeId: 0,
                AllowanceAmount: parseFloat(document.getElementById("Amount").value.replace(/,/g, '')).toLocaleString(),



            }


            setallallowances([...allallowances, listofallallowances]);
            document.getElementById("Allowance").selectedIndex = 0;
            document.getElementById("Amount").value = "";


        }
    }
    const deleteinstallment = (idx) => {

        const rows = [...allallowances];

        rows.splice(idx, 1);

        setallallowances(rows)

    }
    function modalclose() {
        document.getElementById("catModal").style.display = "none";
    };
    function addAllowance() {
        document.getElementById("allowancesave").disabled = true;
        axios({
            url: `${window.$url}Allowances/Add`,
            method: "post",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
            data: JSON.stringify({
                AllowanceId: 0,
                AllowanceName: document.getElementById("allowancename").value
            }),
        }).then(
            (result) => {
                document.getElementById("allowancesave").disabled = false;
                if (result.data.IsSuccess === true) {
                    document.getElementById("allowancename").value = "";
                    toast.info(result.data.Message);
                    modalclose();

                    dispatch(getallAllowances());



                } else {
                    toast.info(result.data.Message);
                }
            },
            (error) => {
                document.getElementById("allowancesave").disabled = false;
                toast.info(
                    "Something went wrong!"
                )
            }
        );
    }
    //    deduction
    function addtoarraydeduction() {
        let check = 0;

        if (alldeduction !== undefined) alldeduction.forEach((x) => {
            if (parseInt(x.DeductionId) === parseInt(document.getElementById("Deduction").options[document.getElementById("Deduction").selectedIndex].dataset.id)) {
                toast.info("Multiple Deductions cannot be added!");
                check = 1;
            } else {
                check = 0
            }
        })
        if (document.getElementById("Deduction").selectedIndex === 0) {
            toast.info("Select Deduction");
        }
        else if (document.getElementById("deductionAmount").value === "") {
            toast.info("Enter Amount");
        }
        else if (check === 1) {
            toast.info("Select Other Deduction");
        }
        else {
            let listofalldeductions = {
                DeductionId: parseInt(document.getElementById("Deduction").options[document.getElementById("Deduction").selectedIndex].dataset.id),
                Deduction: { DeductionName: document.getElementById("Deduction").options[document.getElementById("Deduction").selectedIndex].value },
                EmployeeId: 0,
                DeductionAmount: parseFloat(document.getElementById("deductionAmount").value.replace(/,/g, '')).toLocaleString(),



            }


            setalldeduction([...alldeduction, listofalldeductions]);
            document.getElementById("Deduction").selectedIndex = 0;
            document.getElementById("deductionAmount").value = "";


        }
    }
    const deleteinstallmentdeduction = (idx) => {

        const rows = [...alldeduction];

        rows.splice(idx, 1);

        setalldeduction(rows)

    }
    function modalclosededuction() {
        document.getElementById("catModaldeduction").style.display = "none";
    };
    function addDeduction() {
        document.getElementById("deductionsave").disabled = true;
        axios({
            url: `${window.$url}Deductions/Add`,
            method: "post",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
            data: JSON.stringify({

                DeductionName: document.getElementById("deductionname").value
            }),
        }).then(
            (result) => {
                document.getElementById("deductionsave").disabled = false;
                if (result.data.IsSuccess === true) {
                    document.getElementById("deductionname").value = "";
                    toast.info(result.data.Message);
                    modalclosededuction();

                    dispatch(getallDeduction());



                } else {
                    toast.info(result.data.Message);
                }
            },
            (error) => {
                document.getElementById("deductionsave").disabled = false;
                toast.info(
                    "Something went wrong!"
                )
            }
        );
    }
    const savedata = () => {
        // if (document.getElementById("Designation").value === "") {
        //     toast.info("Select Employee");
        // }

        // else if (document.getElementById("Salary").value === "") {
        //     toast.info("Enter Salary Amount");

        // }



        // else {

        // let DesignationAllowances = allallowances;
        let PayslipAllowances = [];
        allallowances.forEach((x, index) => {

            PayslipAllowances.push({
                AllowanceId: x.AllowanceId,

                PayslipId: props.stateeditdata.PayslipId,
                AllowanceAmount: parseInt(document.getElementById(`allowanceamountfield${index}`).value.replace(/,/g, '')),


            })

        })

        // let PayslipDeduction = [];
        // allallowances.forEach((x, index) => {

        //     PayslipDeduction.push({
        //         DeductionId: x.DeductionId,

        //         PayslipId: props.stateeditdata.PayslipId,
        //         DeductionAmount: document.getElementById(`deductionamountfield${index}`) !== null ? parseInt(document.getElementById(`deductionamountfield${index}`).value.replace(/,/g, '')) : 0,


        //     })

        // })
        let PayslipDeduction = [];
        alldeduction.forEach((x, index) => {
            if (document.getElementById(`deductionamountfield${index}`) !== null) {
                PayslipDeduction.push({
                    DeductionId: x.DeductionId,

                    PayslipId: props.stateeditdata.PayslipId,
                    DeductionAmount: parseInt(document.getElementById(`deductionamountfield${index}`).value.replace(/,/g, '')),


                })
            } else {
                PayslipDeduction.push(



                )
            }

        })

        document.getElementById("payslipeditsave").disabled = true;
        axios({
            method: "put",
            url: `${window.$url}Payslip/Update`,
            data: JSON.stringify({
                PayslipId: props.stateeditdata.PayslipId,
                AllowancesAmount: parseInt(document.getElementById("editAllowancesAmount").value.replace(/,/g, '')),
                DeductionsAmount: parseInt(document.getElementById("editDeductionsAmount").value.replace(/,/g, '')),
                GrossPay: parseInt(document.getElementById("editGrossPay").value.replace(/,/g, '')),
                NetPay: parseInt(document.getElementById("editNetPay").value.replace(/,/g, '')),

                PayslipAllowances,
                PayslipDeduction
            }

            ),
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                document.getElementById("payslipeditsave").disabled = false;
                if (result.data.IsSuccess === true) {


                    toast.info(result.data.Message);
                    props.cancelpaysliptable();
                }
                else {
                    toast.info(result.data.Message);
                }


            },
            (error) => {
                toast.info("Someting went wrong");
                document.getElementById("payslipeditsave").disabled = false;

            }
        );

        // }
    };
    useEffect(() => {
        dispatch(getallAllowances());
        dispatch(getallDeduction());


    }, [])
    useEffect(() => {
        let Allowancessum = 0;
        allallowances.forEach((x, index) => {
            Allowancessum += parseInt(x.AllowanceAmount.toLocaleString().replace(/,/g, ''));


        })
        document.getElementById("editAllowancesAmount").value = Allowancessum;
        document.getElementById("editGrossPay").value = parseInt(props.stateeditdata.EmployeeSalary.BasicSalary + Allowancessum).toLocaleString();

    }, [allallowances])

    useEffect(() => {
        let deductionSum = 0;
        alldeduction.forEach((x, index) => {
            deductionSum += parseInt(x.DeductionAmount.toLocaleString().replace(/,/g, ''));


        })
        document.getElementById("editDeductionsAmount").value = deductionSum;
        document.getElementById("editNetPay").value = parseInt(document.getElementById("editGrossPay").value.replace(/,/g, '')) - deductionSum;

    }, [alldeduction])

    return (
        <>
            <div className="row ">
                <div className="col-12  page_heading">
                    <h4 className="  text-center ">Edit Pay Slip</h4>


                </div>
            </div>


            <div className="row ">


                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">Total Allowances</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">
                    <input type="text" className="input_styling  ml-md-3" id="editAllowancesAmount"
                        defaultValue={props.stateeditdata !== undefined && props.stateeditdata.AllowancesAmount !== null && props.stateeditdata.AllowancesAmount !== undefined ? props.stateeditdata.AllowancesAmount.toLocaleString() : null}
                        onChange={(e) => { if (e.target.value.length > 0) document.getElementById("editAllowancesAmount").value = parseInt(e.target.value.replace(/,/g, '')).toLocaleString() }}
                    ></input><span className="estaric">*</span>

                </div>
                <div className="col-md-2 mt-md-4   p-0">
                </div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">Total Deduction</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">
                    <input type="text" className="input_styling  ml-md-3" id="editDeductionsAmount"
                        defaultValue={props.stateeditdata !== undefined && props.stateeditdata.DeductionsAmount !== null && props.stateeditdata.DeductionsAmount !== undefined ? props.stateeditdata.DeductionsAmount.toLocaleString() : null}
                        onChange={(e) => { if (e.target.value.length > 0) document.getElementById("editDeductionsAmount").value = parseInt(e.target.value.replace(/,/g, '')).toLocaleString() }}
                    ></input><span className="estaric">*</span>

                </div>
            </div>
            <div className="row ">


                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">Gross Pay</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">
                    <input type="text" className="input_styling  ml-md-3" id="editGrossPay"
                        defaultValue={props.stateeditdata !== undefined && props.stateeditdata.GrossPay !== null && props.stateeditdata.GrossPay !== undefined ? props.stateeditdata.GrossPay.toLocaleString() : null}
                        onChange={(e) => { if (e.target.value.length > 0) document.getElementById("editGrossPay").value = parseInt(e.target.value.replace(/,/g, '')).toLocaleString() }}
                    ></input><span className="estaric">*</span>

                </div>
                <div className="col-md-2 mt-md-4   p-0">
                </div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">Net Pay</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">
                    <input type="text" className="input_styling  ml-md-3" id="editNetPay"
                        defaultValue={props.stateeditdata !== undefined && props.stateeditdata.NetPay !== null && props.stateeditdata.NetPay !== undefined ? props.stateeditdata.NetPay.toLocaleString() : null}
                        onChange={(e) => { if (e.target.value.length > 0) document.getElementById("editNetPay").value = parseInt(e.target.value.replace(/,/g, '')).toLocaleString() }}
                    ></input><span className="estaric">*</span>

                </div>
            </div>
            <header style={{ border: "1px solid black", marginTop: "20px", borderRadius: "6px" }}>
                <div className="row ">


                    <div className="col-md-2 mt-md-4  text-left p-0">
                        <label className="input_label m-0 pl-2">Allowance Name</label>
                    </div>
                    <div className="col-md-3 mt-md-4   p-0">
                        <select className="input_styling  ml-md-3" id="Allowance" onChange={(e) => { e.target.value === "Add New" ? document.getElementById("catModal").style.display = "block" : document.getElementById("catModal").style.display = "none"; }} >
                            <option>Select</option>
                            <option>Add New</option>

                            {resultsallallowances.data.AllowancesList !== undefined ? resultsallallowances.data.AllowancesList.map((val, index) => (
                                <option key={index} data-id={val.AllowanceId}>{val.AllowanceName}</option>
                            )) : null}

                        </select>

                    </div>

                    <div className="col-md-2 mt-md-4  text-left p-0">
                        <label className="input_label m-0">Allowance Amount</label>
                    </div>
                    <div className="col-md-3 mt-md-4   p-0">
                        <input type="text" className="input_styling  ml-md-3" id="Amount"
                            onChange={(e) => { if (e.target.value.length > 0) document.getElementById("Amount").value = parseInt(e.target.value.replace(/,/g, '')).toLocaleString() }}
                        ></input>


                    </div>
                    <div className="col-md-2 mt-md-4   p-0">
                        <button className="save_btn" onClick={addtoarray}>Add</button>
                    </div>
                </div>

                <div className="mt-4">
                    <div className="table-responsive mt-4">

                        <table className="table table-borderless m-0">
                            <thead >
                                <tr>
                                    <th >Allowance Name </th>


                                    <th >Allowance Amount</th>
                                    <th >Dell</th>


                                </tr>
                            </thead>
                            <tbody>
                                {

                                    allallowances.map((val, index) => (
                                        <tr key={index}>
                                            <td>{val.Allowance.AllowanceName}</td>


                                            <td>
                                                <input
                                                    type="text"
                                                    key={`allowanceamountfield${index}`}
                                                    id={`allowanceamountfield${index}`}
                                                    className="input_styling"
                                                    defaultValue={val.AllowanceAmount.toLocaleString()}
                                                    onChange={(e) => { if (e.target.value.length > 0) document.getElementById(`allowanceamountfield${index}`).value = parseInt(e.target.value.replace(/,/g, '')).toLocaleString() }}
                                                    disabled

                                                />
                                            </td>

                                            <td onClick={() => { deleteinstallment(index); settoggle(!toggle) }}>
                                                <MdDeleteForever size="1.6em" />
                                            </td>
                                        </tr>
                                    ))}

                            </tbody>
                        </table>

                    </div>

                </div>
            </header>
            {/* deduction */}
            <header style={{ border: "1px solid black", marginTop: "20px", borderRadius: "6px" }}>
                <div className="row ">


                    <div className="col-md-2 mt-md-4  text-left p-0">
                        <label className="input_label m-0 pl-2">Deduction Name</label>
                    </div>
                    <div className="col-md-3 mt-md-4   p-0">
                        <select className="input_styling  ml-md-3" id="Deduction" onChange={(e) => { e.target.value === "Add New" ? document.getElementById("catModaldeduction").style.display = "block" : document.getElementById("catModaldeduction").style.display = "none"; }} >
                            <option>Select</option>
                            <option>Add New</option>

                            {resultsalldeduction.data.Deductions !== undefined ? resultsalldeduction.data.Deductions.map((val, index) => (
                                <option key={index} data-id={val.DeductionId}>{val.DeductionName}</option>
                            )) : null}

                        </select>

                    </div>

                    <div className="col-md-2 mt-md-4  text-left p-0">
                        <label className="input_label m-0">Deduction Amount</label>
                    </div>
                    <div className="col-md-3 mt-md-4   p-0">
                        <input type="text" className="input_styling  ml-md-3" id="deductionAmount"
                            onChange={(e) => { if (e.target.value.length > 0) document.getElementById("deductionAmount").value = parseInt(e.target.value.replace(/,/g, '')).toLocaleString() }}
                        ></input>


                    </div>
                    <div className="col-md-2 mt-md-4   p-0">
                        <button className="save_btn" onClick={addtoarraydeduction}>Add</button>
                    </div>
                </div>

                <div className="mt-4">
                    <div className="table-responsive mt-4">

                        <table className="table table-borderless m-0">
                            <thead >
                                <tr>
                                    <th >Deduction Name </th>


                                    <th >Deduction Amount</th>
                                    <th >Dell</th>


                                </tr>
                            </thead>
                            <tbody>
                                {

                                    alldeduction !== undefined ? alldeduction.map((val, index) => (
                                        <tr key={index}>
                                            <td>{val.Deduction.DeductionName}</td>


                                            <td>
                                                <input
                                                    type="text"
                                                    key={`deductionamountfield${index}`}
                                                    id={`deductionamountfield${index}`}
                                                    className="input_styling"
                                                    defaultValue={val.DeductionAmount.toLocaleString()}
                                                    onChange={(e) => { if (e.target.value.length > 0) document.getElementById(`deductionamountfield${index}`).value = parseInt(e.target.value.replace(/,/g, '')).toLocaleString() }}
                                                    disabled

                                                />
                                            </td>

                                            <td onClick={() => { deleteinstallmentdeduction(index); settoggle(!toggle) }}>
                                                <MdDeleteForever size="1.6em" />
                                            </td>
                                        </tr>
                                    )) : null}

                            </tbody>
                        </table>

                    </div>

                </div>
            </header>

            <div className=" float-right mt-md-4 ">
                <span>
                    <button
                        className="cancel_btn ml-2  "
                        onClick={props.cancelpaysliptable}
                    >

                        Cancel
                    </button>
                </span>

                <span>
                    <button className="save_btn ml-2  " id="payslipeditsave" onClick={savedata}>
                        <BsCloudUpload size="1.3em" />
                        Save
                    </button>
                </span>
            </div>
            {/* <!-- The Modal for cat --> */}
            <div id="catModal" className="modal">
                <div className="modal-content-cat">
                    <div className="modal_heading">
                        <span className="head_text pl-3">Add Allowance</span>
                        <span className="close pr-3" onClick={modalclose}>&times;</span>
                    </div>
                    <div className="row pt-5 pl-3">
                        <div className="col-md-4  text-left p-0">
                            <label className="input_label m-0 ">Allowance Name:</label>
                        </div>

                        <div className="col-md-8  p-0">

                            <input type="text" className="input_styling ml-md-3 " id="allowancename"></input>

                        </div>

                    </div>

                    <div className="row pr-md-2 pt-md-4">

                        <div className=" col-11 pr-0 pl-0  text-right ">

                            <span><button className="save_btn mr-md-3" id="allowancesave" onClick={addAllowance} ><BsCloudUpload size="1.3em" />Save</button></span>
                        </div>
                    </div>
                </div>

            </div>
            {/* modal close */}
            {/* <!-- The Modal for deduction --> */}
            <div id="catModaldeduction" className="modal">
                <div className="modal-content-cat">
                    <div className="modal_heading">
                        <span className="head_text pl-3">Add Deduction</span>
                        <span className="close pr-3" onClick={modalclosededuction}>&times;</span>
                    </div>
                    <div className="row pt-5 pl-3">
                        <div className="col-md-4  text-left p-0">
                            <label className="input_label m-0 ">Deduction Name:</label>
                        </div>

                        <div className="col-md-8  p-0">

                            <input type="text" className="input_styling ml-md-3 " id="deductionname"></input>

                        </div>

                    </div>

                    <div className="row pr-md-2 pt-md-4">

                        <div className=" col-11 pr-0 pl-0  text-right ">

                            <span><button className="save_btn mr-md-3" id="deductionsave" onClick={addDeduction} ><BsCloudUpload size="1.3em" />Save</button></span>
                        </div>
                    </div>
                </div>

            </div>
            {/* modal close */}
        </>
    )
}












// const MainPaySlipCom = () => {


//     const showpaysliptable = () => {
//         stateshowpayslip("PaySlipAdd");
//     }
//     const cancelpaysliptable = () => {
//         stateshowpayslip("PaySlipTable");


//     }
//     const [showpayslip, stateshowpayslip] = useState("PaySlipTable");

//     return (

//         <>
//             <div className="row ">
//                 <div className="dash_background col-12 p-0">
//                     <div className="row ">

//                         <div className="col-md-12">
//                             {showpayslip == "PaySlipTable" ? <PaySlipTable showpaysliptable={showpaysliptable} cancelpaysliptable={cancelpaysliptable} /> : <PaySlipAdd cancelpaysliptable={cancelpaysliptable} />}
//                         </div>


//                     </div>


//                 </div>
//             </div>
//         </>

//     )
// }
// export default MainPaySlipCom;
// export const PaySlipAdd = (props) => {
//     return (
//         <>
//             <h1>Pay Slip</h1>
//         </>
//     )
// }
// export const PaySlipTable = (props) => {
//     const designationdatastate = useSelector(state => state.getAllSalariesTable);

//     const [allDesignationData, setallDesignationData] = useState([]);
//     const [filerunits, setfilterunits] = useState();

//     console.log(allDesignationData);
//     console.log(allDesignationData);

//     let dispatch = useDispatch();

//     var suggestionlist = [];

//     const onTextChange = (e) => {
//         const value = e.target.value;
//         if (value === "") {

//             setfilterunits(designationdatastate.data.Payslips.Employees);
//         } else {
//             let suggestions = [];
//             if (value.length > 0) {
//                 const regex = new RegExp(`${value}`, "i");

//                 suggestions = filerunits
//                     .sort()
//                     .filter((val) =>
//                         (regex.test(val.Employee !== null ? val.Employee.EmpName : null)));

//             }
//             suggestionlist = suggestions;
//             setfilterunits(suggestionlist);
//         }
//     };

//     const openmodalallowancetable = (x) => {

//         setallDesignationData(x);

//         document.getElementById("modalofleadtable").style.display = "block";

//     };

//     // useEffect(() => {

//     //     dispatch(getallSalaryTable());

//     // }, [dispatch]);
//     useEffect(() => {
//         setfilterunits(designationdatastate.data.Payslips.Employees);

//     }, [designationdatastate]
//     )
//     return (
//         <>

//             <div className="row  ">
//                 <div className="dash_background col-12 p-0">
//                     {/* < div className="row ">
//                         <div className="col-md-12   text-left page_heading">
//                             <h4 className="m-0">Salaries</h4>
//                         </div>
//                     </div> */}
//                     <div className="row pt-3 ">
//                         <div className="col-md-1 pr-0 pl-0">


//                             <label className="input_label  m-0">Search:</label>
//                         </div>
//                         <div className="col-md-3   pr-0 pl-0">


//                             <input type="text " className="input_styling " placeholder="Employee" onChange={onTextChange} ></input>
//                         </div>

//                         <div className="col-md-1 pr-0 pl-0">
//                             {/* {designationdatastate.loading ? <Loader type="ThreeDots"
//                                 color="green"
//                                 height={40}
//                                 width={40} /> : null} */}
//                         </div>
//                     </div>




//                     <div className="table-responsive mt-4">

//                         <table className="table table-borderless  table-hover m-0">
//                             <thead>

//                                 <tr >

//                                     <th>Sr</th>

//                                     <th>Employee</th>

//                                     <th>Basic Salary</th>
//                                     <th>Paid Leaves</th>




//                                     <th className="text-center">
//                                         <button
//                                             className="save_btn"
//                                             onClick={() => {
//                                                 props.showpaysliptable();
//                                             }}
//                                         >
//                                             New+
//                                         </button>

//                                     </th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {/* {filerunits !== undefined ? filerunits.map((val, index) => (
//                                     <tr key={index + 1}>
//                                         <td className="cur_sor" onClick={() => { openmodalallowancetable(val); }}>{index + 1}</td>

//                                         <td className="cur_sor" onClick={() => { openmodalallowancetable(val); }}>{val.Employee !== undefined && val.Employee !== null ? val.Employee.EmpName : null}</td>

//                                         <td className="cur_sor" onClick={() => { openmodalallowancetable(val); }}>{val.BasicSalary !== undefined ? val.BasicSalary : null}</td>
//                                         <td className="cur_sor" onClick={() => { openmodalallowancetable(val); }}>{val.PaidLeaves !== undefined ? val.PaidLeaves : null}</td>
//                                         <td><FiEdit2 title="Edit" size="1.1em" className="cur_sor" onClick={() => { setallallowances(val.EmployeeSalaryAllowances); props.setstate(val); props.setempid(val.Employee.EmployeeId); props.showdesignationtable(); props.setedit(true); }} /></td>



//                                     </tr>
//                                 )) : null}
//  */}

//                             </tbody>
//                         </table>
//                     </div>

//                 </div>
//             </div>

//             {/* model for show information*/}
//             <div id="modalofleadtable" class="modalscreen ">
//                 <div class="modal-content-screen1 pt-1">
//                     <div className="row ">
//                         <div className="col-12  page_heading">
//                             <h4 className="  text-center ">Allowance Detail</h4>
//                             <span
//                                 class="close pr-2  "
//                                 onClick={() => {
//                                     document.getElementById("modalofleadtable").style.display =
//                                         "none";
//                                 }}
//                             >
//                                 &times;
//                             </span>

//                         </div>
//                     </div>
//                     <div className="container modal_container">



//                         <div className="table-responsive mt-4">
//                             <table class="table mt-2">
//                                 <thead class="purple whiteText">
//                                     <tr>
//                                         <th scope="col">Allowance Name</th>
//                                         <th scope="col">Allowance Amount</th>


//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {/* {allDesignationData !== undefined && allDesignationData.EmployeeSalaryAllowances !== undefined

//                                         // allDesignationData.length == 0
//                                         ?
//                                         allDesignationData.EmployeeSalaryAllowances.map((x, id) => (
//                                             <tr key={id}>
//                                                 <td>{x.Allowance.AllowanceName}</td>
//                                                 <td>{x.AllowanceAmount}</td>


//                                             </tr>
//                                         )) : null} */}
//                                 </tbody>
//                             </table>
//                         </div>







//                         {/* <div className="row">
//                             <div className=" col-12 text-right pr-0 pl-0">
//                                 <button className="print_btn float-right" ><TiPrinter size="1.3em" />Print</button>


//                             </div>
//                         </div> */}
//                     </div>
//                 </div>
//             </div>

//         </>
//     )
// }
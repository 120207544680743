import axios from 'axios';
import { toast } from "react-toastify";
export const GetHeadingAction = (data) => {

    return ({
        type: "get_headding",
        payload: data

    })
}
export const GetAllAccounts = (data) => {

    return ({
        type: "get_allaccount",
        payload: data,
        loader: false
    })
}
export const GetRecentlyAdded = (data) => {

    return ({
        type: "get_allrecentlyadded",
        payload: data,
        loader: false
    })
}


export function getheadingdata() {

    return (dispatch) => {
        let pgurl = `${window.$url}Accounts/GetHeading?projectid=${localStorage.getItem("ProjectId")}`;
        if (localStorage.getItem("ProjectId") === null) {
            pgurl = `${window.$url}Accounts/GetHeading`
        }
        axios({
            url: pgurl,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetHeadingAction(result.data.Data));

                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}


export function getallacounts() {
    return (dispatch) => {
        let pgurl = `${window.$url}Accounts/GetAll?projectid=${localStorage.getItem("ProjectId")}`;
        if (localStorage.getItem("ProjectId") === null) {
            pgurl = `${window.$url}Accounts/GetAll`
        }
        axios({
            url: pgurl,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllAccounts(result.data.Data));

                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}

export function getallrecentlyadded() {
    return (dispatch) => {
        let pgurl = `${window.$url}Accounts/GetUserAdded?ProjectId=${localStorage.getItem("ProjectId")}`;
        if (localStorage.getItem("ProjectId") === null) {
            pgurl = `${window.$url}Accounts/GetUserAdded`
        }
        axios({
            url: pgurl,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetRecentlyAdded(result.data.Data));

                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}



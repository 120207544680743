import axios from "axios";
import { toast } from "react-toastify";
export const GetAllTeamPopulate = (data) => {

    return ({
        type: "get_allteamspopulate",
        payload: data,
        loader: false
    })
}
export function getallTeamPopulate() {
    return (dispatch) => {
        axios({
            url: `${window.$url}CRMTeams/Populate`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllTeamPopulate(result.data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}
export const GetAllTeamTable = (data) => {

    return ({
        type: "get_allteamstable",
        payload: data,
        loader: false
    })
}
export function getallTeamTable() {
    return (dispatch) => {
        axios({
            url: `${window.$url}CRMTeams/GetAll`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllTeamTable(result.data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}
// memberpopulate

export const GetAllMemberPopulate = (data) => {

    return ({
        type: "get_allmemberpopulate",
        payload: data,
        loader: false
    })
}
export function getallMemberPopulate() {
    return (dispatch) => {
        axios({
            url: `${window.$url}CRMTeams/Members/Populate`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllMemberPopulate(result.data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}
export const GetAllMemberTable = (data) => {

    return ({
        type: "get_allmembertable",
        payload: data,
        loader: false
    })
}
export function getallMemberTable() {
    return (dispatch) => {
        axios({
            url: `${window.$url}CRMTeams/Members/GetAll`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllMemberTable(result.data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import "../../../App.css"
import ChangeInClientStatus from './ChangeInClientStatus'

const ChangeInReqTable=({setShowForm})=> {
    const [ChangeInReqdata,setChangeInReqData]=useState([])
    const [allChangeInReqData,setallChangeInReqData]=useState([])
const [edit,setedit]=useState(false)
// const [loader1, setloader1] = useState(false);
const [filterCientStatus,setfilterClientStatus]=useState([])
const [showUpdateForm,setShowupdateForm]=useState(
    "Table",
    "ClientForm",
    "AmendStatus"
)
const handleClientStatus=(changereqData)=>{
    setfilterClientStatus(changereqData)
    }
    const getdata = () => {
        axios
          .get(`http://internalhrapi.aiksol.com/api/getchangereqs`)
          .then(function (response) {
    
            let arr=[]
            response.data.Data.forEach((changeReqdata,ind)=>{
        let amendmentStatus  =  changeReqdata?.Amendement?.find((amend)=>{
            return amend?.amend_status === "pending"
          })
        return arr.push({
            id: ind + 1,
            description: changeReqdata?.description,
            priority: changeReqdata?.priority,
            company_status: changeReqdata?.company_status,
            client_status: changeReqdata?.client_status,
            google_docs: changeReqdata?.google_docs,
            project_name: changeReqdata?.project,
            module_name: changeReqdata?.module,
            submodule_name: changeReqdata?.submodule,
            request_id: changeReqdata?.request_id,
            req_timeline: changeReqdata?.req_timeline,
            Amendement: changeReqdata?.Amendement,
            AmendStatus:changeReqdata?.Amendement?.length > 0 ? amendmentStatus ? "pending" : "completed" : "" 
        })
        
    
    })
    setallChangeInReqData(arr)
            setChangeInReqData(arr)
    })
          .catch((err) => {
            console.log(err);
          });
      };
      useEffect(()=>{
        getdata()
      },[])

      const onTextChange = () => {
        if (
          document.getElementById("filterbyPriorty").value === "" &&
          document.getElementById("filterbyCompanyStatus").value === "" &&
          document.getElementById("filterbyClientStatus").value === "" &&
          document.getElementById("filterbyModule").value === "" &&
          document.getElementById("filterbySubModule").value === "" 
        ) {
          setallChangeInReqData(ChangeInReqdata);
        } else {
          let suggestions = [];
          {
            const regex = new RegExp(
              document.getElementById("filterbyPriorty").value,
              "i"
            );
            const regex1 = new RegExp(
              document.getElementById("filterbyCompanyStatus").value,
              "i"
            );
            const regex2 = new RegExp(
              document.getElementById("filterbyClientStatus").value,
              "i"
            );  const regex3 = new RegExp(
              document.getElementById("filterbyModule").value,
              "i"
            );  const regex4 = new RegExp(
              document.getElementById("filterbySubModule").value,
              "i"
            );
           
           
    
            suggestions = ChangeInReqdata
              .sort()
              .filter(
                (val) =>
                  (document.getElementById("filterbyPriorty").value === "" ||
                    regex.test(val?.priority)) &&
                  (document.getElementById("filterbyCompanyStatus").value === "" ||
                    regex1.test(val?.company_status)) 
                    &&
                  (document.getElementById("filterbyClientStatus").value === "" ||
                    regex2.test(val?.client_status)) 
                    &&
                    (document.getElementById("filterbyModule").value === "" ||
                      regex3.test(val?.module_name)) 
                      &&
                      (document.getElementById("filterbySubModule").value === "" ||
                        regex4.test(val?.submodule_name)) 
              );
          }
          setallChangeInReqData(suggestions);
        }
      };

  return (
<>

{ showUpdateForm==="Table"
&&
<div>
<div className="col-md-12 mt-md-2 text-right" style={{paddingRight:"30px",paddingBottom:"10px"}}>
<button className="save_btn " style={{width:"70px",backgroundColor:"#3480FA",color:"white"}}
                    onClick={() => {
                        setShowForm(true)
                    }}
                  
                  >
                    New
                  </button>
</div>
<div className="row mt-1">
        <div className="col-md-2">
          <input
               style={{width:"150px",height:"30px"}}
               id="filterbyPriorty"
                 type="text"
                 onChange={onTextChange}
                
                 placeholder="Priority"
            className="input_styling"
            // onChange={onTextChange}
          ></input>
        </div>
        <div className="col-md-2">
          <input
               style={{width:"150px",height:"30px"}}

          type="text"
          id="filterbyCompanyStatus"
            onChange={onTextChange}
            placeholder="CompanyStatus"
            className="input_styling"
            // onChange={onTextChange}
          ></input>
        </div>

        <div className="col-md-2">
          <input
               style={{width:"150px",height:"30px"}}

        type="text"
        id="filterbyClientStatus"
          onChange={onTextChange}
 
          placeholder="Client Status"
            className="input_styling"
            // onChange={onTextChange}
          ></input>
        </div>
        <div className="col-md-2">
          <input
               style={{width:"150px",height:"30px"}}

    type="text"
    id="filterbyModule"
      onChange={onTextChange}
  
      placeholder="Module"
            className="input_styling"
            // onChange={onTextChange}
          ></input>
        </div>
        <div className="col-md-4">
          <input
               style={{width:"150px",height:"30px"}}

    type="text"
    id="filterbySubModule"
      onChange={onTextChange}
  
      placeholder="SubModule"
            className="input_styling"
            // onChange={onTextChange}
          ></input>
        </div>
      </div>
        <div className="col-12 changeFormTable_height pr-2 pl-2" style={{height:"500px"}}>
          <div className="table-responsive mt-2">
            <table className="table table-borderless m-0">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Description</th>
                  <th>Priority</th>
                  <th>Company Status</th>
                  <th>  Client Status</th>
                  <th>Googlr Docs</th>
                  <th>Module</th>
                  <th>SubModule</th>
                  <th>Amend Status</th>
                  <th>Update Status</th>

                </tr>
              </thead>
              <tbody>
                {allChangeInReqData && allChangeInReqData?.map((changereqData,ind) => {
                  return (
                    <tr key={ind}>
                      <td>{changereqData?.id}</td>
                      <td>{changereqData?.description}</td>
                      <td>{changereqData?.priority}</td>
                      <td>{changereqData?.company_status}</td>
                      <td>{changereqData?.client_status}</td>
                      <td>{changereqData?.google_docs}</td>
                      <td>{changereqData?.module_name}</td>
                      <td>{changereqData?.submodule_name}</td>
                      <td>{changereqData?.AmendStatus}</td>
                      <td>{ changereqData?.client_status === "Fresh" ? 
     changereqData?.company_status === "completed"   &&   changereqData?.AmendStatus !== "pending"   ? <button type="button" className="save_btn " style={{fontSize:"14px",height:"25px",alignItems:"center",backgroundColor:"#3480FA",color:"white"}} onClick={()=>{setShowupdateForm("ClientForm");handleClientStatus(changereqData)}}>Update</button> :  "" :  <button type="button" className="save_btn "  onClick={()=>{setShowupdateForm("ClientForm");handleClientStatus(changereqData); setedit(true)}}>See Details</button> }
    </td>
                    </tr>
                  );
                })}
                <tr></tr>
              </tbody>
            </table>
          </div>
        </div>
        </div>
}
{
        showUpdateForm==="ClientForm" &&
        (<ChangeInClientStatus getdata={getdata} filterCientStatus={filterCientStatus} edit={edit} setedit={setedit}  setShowupdateForm={setShowupdateForm}/>)
    }

</>
  )
}

export default ChangeInReqTable
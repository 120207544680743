import React, { useEffect, useState } from "react";

import { Chart } from "react-google-charts";
import moment from "moment";
import axios from "axios";


const HRDashboard = (props) => {
    const [dashboardtopdata, setdashboardtopdata] = useState(null);
    const [bargraphdata, setbargraphdata] = useState([])
    const [piechartdata, setpiechartdata] = useState([]);
    const [piedataavailable, setpiedataavailable] = useState(false)

    useEffect(() => {
        const employeeinfofunction = () => {
            // setloader(true);
            axios({
                url: `${window.$url}Reports/TotalAttendance`,
                method: "get",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("Token")}`,
                    "content-type": "application/json",
                },
            }).then(
                (result) => {
                    // setloader(false);
                    setdashboardtopdata(result.data);
                },
                (error) => {
                    // setloader(false);
                }
            );
        };

        const stackedbargraph = () => {
            // setloader(true);
            axios({

                url: `${window.$url}Reports/DesignationAttendance`,
                method: "get",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("Token")}`,
                    "content-type": "application/json",
                },
            }).then(
                (result) => {
                    // setloader(false);


                    let array = [["Designation", "Present", "Absent"],];
                    if (result.data.Attendance !== undefined) {
                        result.data.Attendance.map((x) => {
                            let newarray = Object.values({
                                department: x.Designation,
                                present: x.Present,
                                absent: x.Absent
                            })
                            array.push(newarray)
                        })
                    }

                    setbargraphdata(array)

                    let array2 = [["Designation", "Employees"],];
                    result.data.Attendance.map((x) => {
                        let newarray2 = Object.values({
                            department: x.Designation,
                            present: x.Present,
                        })
                        array2.push(newarray2)
                    })


                    setpiechartdata(array2)

                    let check = 0;
                    result.data.Attendance.forEach((x) => {
                        if (x.Present !== 0) {
                            check = 1
                        }
                    })

                    if (check === 1) {
                        setpiedataavailable(true)
                    }

                },
                (error) => {
                    // setloader(false);
                }
            );
        };

        stackedbargraph();
        employeeinfofunction();
    }, []);



    return (

        <div className="row ">
            <div className=" col-12">
                <div className="dashboardtoprow  " style={{ height: "20vh" }}>
                    <div className="row m-0 mt-1 p-0">
                        <div className="col-12 sitka text-left">
                            <b>Overview</b>
                        </div>
                    </div>
                    <hr style={{ marginTop: "0rem", marginBottom: "0.8rem" }}></hr>
                    <div className="row m-0 mt-2 p-0 ml-5">
                        <div className="col-4 text-left sitka">
                            <i>Employee Present</i>
                        </div>
                        <div className="col-4 text-left sitka">
                            <i>Employee Absent</i>
                        </div>
                        <div className="col-4 text-left sitka">
                            <i>Total Employees</i>
                        </div>
                    </div>
                    <div className="row m-0 mt-2 p-0 ml-5">
                        <div className="col-4">
                            <span
                                style={{
                                    fontSize: "17pt",
                                    fontWeight: "bold",

                                    marginTop: "15px",
                                    float: "left",
                                }}
                            >
                                {dashboardtopdata !== null &&
                                    dashboardtopdata.Present !== undefined
                                    ? dashboardtopdata.Present.toLocaleString()
                                    : 0}
                            </span>
                            <svg
                                className="dashboardicon1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 532.19 773.96"
                            >
                                <title>attendance</title>
                                <path
                                    d="M256.12,371a46.45,46.45,0,1,0-46.45-46.45A46.45,46.45,0,0,0,256.12,371Z"
                                    transform="translate(-133.86 -16.45)"
                                    style={{ fill: "#5d62b4" }}
                                />
                                <path
                                    d="M347,583h-4.19v-6.89c4.92-2.15,7.75-6.54,10.47-11.1,0-47.9-.84-145.12-1-145.89C346.51,395,331,379.66,308.34,375.71a1,1,0,0,0-.42-.08h0c-9.65,0-87.82.11-94.21.18a69.53,69.53,0,0,0-10.91.95c-22.88,3.83-38.57,19.23-44.36,43.52-.18.77-1,98-1,145.89,3.16,5.31,6.47,10.41,13.07,12,12.42,3,22.4-4,23.75-16.7a86.39,86.39,0,0,0,.16-8.67q0-51.69,0-103.42v-7h8.39v8.17q0,154.51,0,309a70.89,70.89,0,0,0,1,13.51c1.84,9.22,8.86,13.79,16.75,17.35H233c14.39-5,19.07-15.47,19-30.17-.4-58.21-.18-116.43-.18-174.63V578h8.45v7.49c0,58,.3,116-.19,174-.12,14.91,4.28,25.62,18.8,30.94h12.39c14.38-5.2,18.59-16.06,18.54-30.53q-.17-68-.14-136V441.22h6.68v7q0,51.7,0,103.42a83.27,83.27,0,0,0,.17,8.66c.95,9.09,6.32,15.21,13.84,16.9V583h-4.19c-7.23,0-13.1,6.48-13.1,14.48v95.92c0,8,5.87,14.48,13.1,14.48H347c7.24,0,13.1-6.49,13.1-14.48V597.5C360.1,589.5,354.24,583,347,583ZM255.72,380.75h11.34l-6.48,16.74h-8.91l-6.49-16.74Zm.4,109.76-11.94-9.7L251.77,405h8.71l7.58,75.83Z"
                                    transform="translate(-133.86 -16.45)"
                                    style={{ fill: "#5d62b4" }}
                                />
                                <path
                                    d="M666.06,161.74V113.82H638.67a91,91,0,0,0-3.59-10.95l22.18-16.12L629.08,48,606.91,64.1a92.5,92.5,0,0,0-9.33-6.74l8.48-26.1L560.49,16.45,552,42.54c-1.89-.11-3.81-.18-5.74-.18s-3.86.07-5.75.18L532,16.45,486.46,31.26,495,57.36a95,95,0,0,0-9.34,6.74L463.43,48,435.27,86.75l22.18,16.12a96.63,96.63,0,0,0-3.58,10.95H426.48v47.92h27.39a96.63,96.63,0,0,0,3.58,10.95l-22.18,16.12,28.16,38.77,22.18-16.12a96.83,96.83,0,0,0,9.34,6.76l-8.49,26.09L532,259.12,540.52,233c1.89.12,3.81.19,5.75.19s3.85-.07,5.74-.19l8.48,26.09,45.57-14.81-8.48-26.09a95.61,95.61,0,0,0,9.34-6.76l22.16,16.12,28.18-38.77-22.18-16.13a90.29,90.29,0,0,0,3.59-10.94Zm-119.79,4.8A28.75,28.75,0,1,1,575,137.78,28.76,28.76,0,0,1,546.27,166.54Z"
                                    transform="translate(-133.86 -16.45)"
                                    style={{ fill: "#5d62b4" }}
                                />
                                <path
                                    d="M248.77,170.83h0a13.33,13.33,0,0,0,2.72-.3c.25-.05.52-.15.78-.22a12.49,12.49,0,0,0,1.81-.62c.31-.15.6-.32.9-.46a14,14,0,0,0,1.5-.95,8.19,8.19,0,0,0,.87-.71,5,5,0,0,0,.58-.45c.27-.26.46-.56.71-.82s.37-.34.5-.53l39-50.09a13.16,13.16,0,1,0-20.75-16.2L247.7,137.67l-13.82-14.2A13.16,13.16,0,0,0,215,141.81l24.35,25.06c.21.21.46.38.67.58s.44.44.7.64a3.74,3.74,0,0,0,.42.27c.27.19.52.35.8.53.46.27.89.51,1.35.73.3.14.57.26.85.36a15.92,15.92,0,0,0,1.57.49c.24.05.48.12.73.16A14,14,0,0,0,248.77,170.83Z"
                                    transform="translate(-133.86 -16.45)"
                                    style={{ fill: "#5d62b4" }}
                                />
                                <path
                                    d="M268.28,70.06a11,11,0,1,0-11,11A11,11,0,0,0,268.28,70.06Z"
                                    transform="translate(-133.86 -16.45)"
                                    style={{ fill: "#5d62b4" }}
                                />
                                <path
                                    d="M176.21,137.46a11,11,0,1,0,11-11A11,11,0,0,0,176.21,137.46Z"
                                    transform="translate(-133.86 -16.45)"
                                    style={{ fill: "#5d62b4" }}
                                />
                                <path
                                    d="M321.36,148.44a11,11,0,1,0-11-11.05A11,11,0,0,0,321.36,148.44Z"
                                    transform="translate(-133.86 -16.45)"
                                    style={{ fill: "#5d62b4" }}
                                />
                                <path
                                    d="M335.94,46.39a122.26,122.26,0,0,0-202.08,91.4,122.27,122.27,0,0,0,244.52,0A122,122,0,0,0,335.94,46.39ZM256.12,228.84A92.28,92.28,0,0,1,164,137.78a92.16,92.16,0,0,1,184.31,0A92.26,92.26,0,0,1,256.12,228.84Z"
                                    transform="translate(-133.86 -16.45)"
                                    style={{ fill: "#5d62b4" }}
                                />
                                <path
                                    d="M257.28,215.88a11,11,0,1,0-11-11A11,11,0,0,0,257.28,215.88Z"
                                    transform="translate(-133.86 -16.45)"
                                    style={{ fill: "#5d62b4" }}
                                />
                                <path
                                    d="M543.64,371a46.45,46.45,0,1,0-46.45-46.45A46.45,46.45,0,0,0,543.64,371Z"
                                    transform="translate(-133.86 -16.45)"
                                    style={{ fill: "#5d62b4" }}
                                />
                                <path
                                    d="M634.51,583h-4.19v-6.89c4.92-2.15,7.75-6.54,10.47-11.1,0-47.9-.83-145.12-1-145.89C634,395,618.49,379.66,595.86,375.71a1.13,1.13,0,0,0-.44-.08h0c-9.66,0-87.84.11-94.23.18a69.5,69.5,0,0,0-10.9.95c-22.88,3.83-38.56,19.23-44.36,43.52-.18.77-1,98-1,145.89,3.16,5.31,6.46,10.41,13.07,12,12.43,3,22.41-4,23.74-16.7a83.52,83.52,0,0,0,.18-8.67V442.37h8.38v8.17q0,154.51,0,309a70.09,70.09,0,0,0,1,13.51c1.84,9.22,8.86,13.79,16.75,17.35h12.39c14.38-5,19.07-15.47,19-30.17-.39-58.21-.16-116.43-.16-174.63V578h8.45v7.49c0,58,.28,116-.2,174-.12,14.91,4.28,25.62,18.79,30.94h12.39c14.39-5.2,18.59-16.06,18.56-30.53q-.16-68-.16-136V441.22h6.69v7q0,51.7,0,103.42a86.14,86.14,0,0,0,.16,8.66c1,9.09,6.33,15.21,13.86,16.9V583h-4.2c-7.23,0-13.1,6.48-13.1,14.48v95.92c0,8,5.87,14.48,13.1,14.48h20.88c7.23,0,13.1-6.49,13.1-14.48V597.5C647.61,589.5,641.74,583,634.51,583ZM543.23,380.75h11.34l-6.48,16.74h-8.92l-6.48-16.74Zm.41,109.76-11.95-9.7L539.28,405H548l7.57,75.83Z"
                                    transform="translate(-133.86 -16.45)"
                                    style={{ fill: "#5d62b4" }}
                                />
                            </svg>
                        </div>
                        <div className="col-4">
                            <span
                                style={{
                                    fontSize: "17pt",
                                    fontWeight: "bold",

                                    marginTop: "15px",
                                    float: "left",
                                }}
                            >
                                {dashboardtopdata !== null &&
                                    dashboardtopdata.Absent !== undefined
                                    ?
                                    parseInt(dashboardtopdata.Absent).toLocaleString()
                                    : 0}
                            </span>

                            <svg
                                className="dashboardicon1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 643.4 816.6"
                            >
                                <title>meeting_21</title>
                                <path
                                    d="M320.66,83c0,37.76,29.88,68.38,66.76,68.38S454.15,120.78,454.15,83c0-28.62-26.77-61.39-51.12-71.56l8.64-6.34,22.47-22.75s9.42-30.3-17.29-6.06S341.51-35.92,339.73-30,366,8.27,366,8.27l7,7.22C345.18,23.71,320.66,51.8,320.66,83ZM388,76c-12-3.38-20.14-8.11-20.14-17.27,0-8.3,6.95-14.81,18.92-16.79V33h11v8.28a46.25,46.25,0,0,1,16.2,3.13L410.7,55.06a42.38,42.38,0,0,0-16.11-3.21c-7.27,0-9.61,2.63-9.61,5.28,0,3.09,3.9,5.1,13.41,8.11,13.3,4,18.68,9.15,18.68,17.65s-7.05,15.57-19.9,17.48V110h-11v-9c-7.5-.28-14.77-2-19-4.05l3.34-11.06A45.3,45.3,0,0,0,389.1,90c6.36,0,10.74-2.06,10.74-5.84C399.84,80.65,396.26,78.37,388,76Z"
                                    transform="translate(-68.23 33.09)"
                                    style={{ fill: "#027652" }}
                                />
                                <path
                                    d="M683.4,267.17l-115.61-8.89-90.92-91.87a30.2,30.2,0,0,0-14.53-8.18,30.37,30.37,0,0,0-10.66-.67q-22.77-.36-45.56-.42v7.3l-9.88,20.09-1.56,4.13,9.55,163-16.8,18.83-.24-.26-.24.26-16.81-18.83,9.56-163-1.57-4.13-9.86-20.09v-7.32q-18.37,0-36.73.21c-2.38,0-4.77,0-7.16,0-2.8,0-5.54.17-8.21.4a30.28,30.28,0,0,0-8.2,3.77,31.68,31.68,0,0,0-5,4.07l-90.9,91.86L96.48,266.37a30.6,30.6,0,0,0,2.31,61.1c.79,0,1.58,0,2.38-.09l127-9.77a30.57,30.57,0,0,0,19.39-9l60.42-61V736.91a106.28,106.28,0,0,0,1.54,20.42c2.77,13.9,13.37,20.81,25.3,26.18h18.71C375.24,776,382.3,760.15,382.15,738c-.59-87.9-.25-175.81-.25-263.73V462.71h12.75V474c0,87.6.44,175.2-.28,262.8-.19,22.5,6.45,38.68,28.37,46.69h18.7c21.75-7.84,28.08-24.24,28-46.1q-.57-233.26-.2-466.53V245.72l63,63.7a30.53,30.53,0,0,0,19.4,9l127,9.76c.8.07,1.59.1,2.38.1a30.6,30.6,0,0,0,2.32-61.1Z"
                                    transform="translate(-68.23 33.09)"
                                    style={{ fill: "#027652" }}
                                />
                            </svg>
                        </div>
                        <div className="col-4">
                            <span
                                style={{
                                    fontSize: "17pt",
                                    fontWeight: "bold",

                                    marginTop: "15px",
                                    float: "left",
                                }}
                            >
                                {dashboardtopdata !== null &&
                                    dashboardtopdata.TotalEmployees !== undefined
                                    ? dashboardtopdata.TotalEmployees.toLocaleString()
                                    : 0}
                            </span>

                            <svg
                                className="dashboardicon1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 778.15 772.63"
                            >
                                <title>total</title>
                                <path
                                    d="M399.24,126.61a52.1,52.1,0,1,0-52.1-52.1A52.1,52.1,0,0,0,399.24,126.61Z"
                                    transform="translate(-13.69 -21.85)"
                                    style={{
                                        fill: "#54c3be",
                                        stroke: "#000002",
                                        strokeMiterlimit: "10",
                                        strokeWidth: "1.121737634636446px",
                                    }}
                                />
                                <path
                                    d="M501.19,364.42h-4.7V356.7c5.52-2.42,8.7-7.34,11.74-12.45,0-53.74-.94-162.79-1.14-163.66-6.45-27.07-23.87-44.28-49.27-48.72a1.22,1.22,0,0,0-.47-.09h0c-10.83,0-98.52.13-105.68.2a78.73,78.73,0,0,0-12.24,1.07c-25.67,4.3-43.26,21.57-49.76,48.83-.2.85-1.13,109.91-1.13,163.65,3.54,6,7.25,11.67,14.65,13.45,13.94,3.34,25.14-4.46,26.64-18.74a92.4,92.4,0,0,0,.18-9.71q0-58,0-116v-7.87h9.42v9.17q0,173.33,0,346.64a78.2,78.2,0,0,0,1.14,15.15c2.07,10.34,9.94,15.47,18.79,19.46h13.9c16.14-5.61,21.38-17.35,21.27-33.85-.44-65.28-.19-130.59-.19-195.88v-8.56h9.48v8.4c0,65.07.32,130.13-.22,195.19-.13,16.72,4.8,28.73,21.08,34.7h13.9c16.14-5.83,20.85-18,20.8-34.25q-.18-76.29-.16-152.6V205.36h7.5v7.87q0,58,0,116a92.39,92.39,0,0,0,.19,9.71c1.06,10.2,7.09,17.06,15.52,19v6.51h-4.7c-8.11,0-14.68,7.28-14.68,16.24V488.27c0,9,6.57,16.24,14.68,16.24h23.43c8.11,0,14.69-7.28,14.69-16.24V380.66C515.88,371.7,509.3,364.42,501.19,364.42ZM398.79,137.53h12.72l-7.26,18.78h-10L387,137.53Zm.45,123.12-13.39-10.87,8.51-85.07h9.77l8.5,85.07Z"
                                    transform="translate(-13.69 -21.85)"
                                    style={{
                                        fill: "#54c3be",
                                        stroke: "#000002",
                                        strokeMiterlimit: "10",
                                        strokeWidth: "1.121737634636446px",
                                    }}
                                />
                                <path
                                    d="M674.65,126.61a52.1,52.1,0,1,0-52.11-52.1A52.11,52.11,0,0,0,674.65,126.61Z"
                                    transform="translate(-13.69 -21.85)"
                                    style={{
                                        fill: "#54c3be",
                                        stroke: "#000002",
                                        strokeMiterlimit: "10",
                                        strokeWidth: "1.121737634636446px",
                                    }}
                                />
                                <path
                                    d="M776.58,364.42h-4.7V356.7c5.52-2.42,8.7-7.34,11.74-12.45,0-53.74-.92-162.79-1.13-163.66-6.46-27.07-23.87-44.28-49.26-48.72a1.31,1.31,0,0,0-.49-.09h0c-10.83,0-98.52.13-105.69.2a78.71,78.71,0,0,0-12.23,1.07c-25.67,4.3-43.26,21.57-49.76,48.83-.2.85-1.14,109.91-1.14,163.65,3.55,6,7.25,11.67,14.66,13.45,13.94,3.34,25.14-4.46,26.63-18.74a92.39,92.39,0,0,0,.21-9.71V206.65h9.4v9.17q0,173.33,0,346.64A79.8,79.8,0,0,0,616,577.61c2.08,10.34,10,15.47,18.79,19.46h13.91c16.13-5.61,21.38-17.35,21.27-33.85-.45-65.28-.19-130.59-.19-195.88v-8.56h9.48v8.4c0,65.07.32,130.13-.23,195.19-.12,16.72,4.81,28.73,21.08,34.7H714c16.14-5.83,20.86-18,20.82-34.25q-.18-76.29-.17-152.6V205.36h7.49v7.87q0,58,0,116a95.18,95.18,0,0,0,.19,9.71c1.07,10.2,7.1,17.06,15.54,19v6.51h-4.71c-8.11,0-14.69,7.28-14.69,16.24V488.27c0,9,6.58,16.24,14.69,16.24h23.42c8.12,0,14.7-7.28,14.7-16.24V380.66C791.28,371.7,784.7,364.42,776.58,364.42ZM674.19,137.53h12.72l-7.27,18.78h-10l-7.27-18.78Zm.46,123.12-13.41-10.87,8.52-85.07h9.77l8.5,85.07Z"
                                    transform="translate(-13.69 -21.85)"
                                    style={{
                                        fill: "#54c3be",
                                        stroke: "#000002",
                                        strokeMiterlimit: "10",
                                        strokeWidth: "1.121737634636446px",
                                    }}
                                />
                                <path
                                    d="M125,126.61a52.1,52.1,0,1,0-52.11-52.1A52.1,52.1,0,0,0,125,126.61Z"
                                    transform="translate(-13.69 -21.85)"
                                    style={{
                                        fill: "#54c3be",
                                        stroke: "#000002",
                                        strokeMiterlimit: "10",
                                        strokeWidth: "1.121737634636446px",
                                    }}
                                />
                                <path
                                    d="M226.92,364.42h-4.7V356.7c5.52-2.42,8.7-7.34,11.74-12.45,0-53.74-.94-162.79-1.14-163.66-6.45-27.07-23.87-44.28-49.27-48.72a1.22,1.22,0,0,0-.47-.09h0c-10.83,0-98.52.13-105.68.2a78.73,78.73,0,0,0-12.24,1.07c-25.67,4.3-43.26,21.57-49.76,48.83-.2.85-1.13,109.91-1.13,163.65,3.54,6,7.25,11.67,14.65,13.45,14,3.34,25.14-4.46,26.64-18.74a92.4,92.4,0,0,0,.18-9.71q0-58,0-116v-7.87h9.42v9.17q0,173.33,0,346.64a78.2,78.2,0,0,0,1.14,15.15c2.07,10.34,9.94,15.47,18.79,19.46H99c16.14-5.61,21.38-17.35,21.27-33.85-.44-65.28-.19-130.59-.19-195.88v-8.56h9.48v8.4c0,65.07.32,130.13-.22,195.19-.13,16.72,4.8,28.73,21.08,34.7h13.9c16.14-5.83,20.85-18,20.8-34.25q-.18-76.29-.16-152.6V205.36h7.5v7.87q0,58,0,116a92.39,92.39,0,0,0,.19,9.71c1.07,10.2,7.1,17.06,15.52,19v6.51h-4.7c-8.1,0-14.68,7.28-14.68,16.24V488.27c0,9,6.58,16.24,14.68,16.24h23.43c8.11,0,14.69-7.28,14.69-16.24V380.66C241.61,371.7,235,364.42,226.92,364.42ZM124.52,137.53h12.72L130,156.31H120l-7.27-18.78ZM125,260.65l-13.4-10.87,8.51-85.07h9.77l8.5,85.07Z"
                                    transform="translate(-13.69 -21.85)"
                                    style={{
                                        fill: "#54c3be",
                                        stroke: "#000002",
                                        strokeMiterlimit: "10",
                                        strokeWidth: "1.121737634636446px",
                                    }}
                                />
                                <path
                                    d="M277.9,235.41a52.11,52.11,0,1,0-52.1-52.11A52.11,52.11,0,0,0,277.9,235.41Z"
                                    transform="translate(-13.69 -21.85)"
                                    style={{
                                        fill: "#54c3be",
                                        stroke: "#000002",
                                        strokeMiterlimit: "10",
                                        strokeWidth: "1.121737634636446px",
                                    }}
                                />
                                <path
                                    d="M379.84,473.21h-4.7v-7.72c5.52-2.42,8.7-7.34,11.74-12.45,0-53.74-.93-162.79-1.14-163.65-6.45-27.08-23.87-44.29-49.26-48.73a1.22,1.22,0,0,0-.47-.09h0c-10.83,0-98.53.13-105.69.2a80,80,0,0,0-12.23,1.07c-25.67,4.31-43.27,21.57-49.76,48.83-.21.85-1.14,109.91-1.14,163.65,3.54,5.95,7.25,11.67,14.66,13.45,13.94,3.34,25.13-4.46,26.64-18.73a95.44,95.44,0,0,0,.18-9.72q0-58,0-116v-7.87h9.42v9.17q0,173.33,0,346.64a78.36,78.36,0,0,0,1.14,15.16c2.07,10.33,9.94,15.46,18.79,19.45h13.9c16.14-5.61,21.39-17.35,21.28-33.84-.45-65.29-.2-130.6-.2-195.89v-8.56h9.48V476c0,65.07.33,130.13-.22,195.19-.13,16.72,4.81,28.73,21.09,34.7h13.9c16.13-5.83,20.85-18,20.79-34.25q-.17-76.29-.16-152.6V314.15h7.51V322q0,58,0,116a95.19,95.19,0,0,0,.18,9.71c1.07,10.2,7.1,17.06,15.53,18.95v6.51h-4.71c-8.1,0-14.68,7.28-14.68,16.24V597.06c0,9,6.58,16.24,14.68,16.24h23.43c8.12,0,14.7-7.28,14.7-16.24V489.45C394.54,480.49,388,473.21,379.84,473.21ZM277.44,246.32h12.73L282.9,265.1h-10l-7.27-18.78Zm.46,123.13-13.39-10.88L273,273.5h9.77l8.5,85.07Z"
                                    transform="translate(-13.69 -21.85)"
                                    style={{
                                        fill: "#54c3be",
                                        stroke: "#000002",
                                        strokeMiterlimit: "10",
                                        strokeWidth: "1.121737634636446px",
                                    }}
                                />
                                <path
                                    d="M553.3,235.41a52.11,52.11,0,1,0-52.1-52.11A52.11,52.11,0,0,0,553.3,235.41Z"
                                    transform="translate(-13.69 -21.85)"
                                    style={{
                                        fill: "#54c3be",
                                        stroke: "#000002",
                                        strokeMiterlimit: "10",
                                        strokeWidth: "1.121737634636446px",
                                    }}
                                />
                                <path
                                    d="M655.24,473.21h-4.7v-7.72c5.52-2.42,8.69-7.34,11.74-12.45,0-53.74-.93-162.79-1.14-163.65-6.45-27.08-23.87-44.29-49.26-48.73a1.26,1.26,0,0,0-.49-.09h0c-10.83,0-98.52.13-105.69.2a80.06,80.06,0,0,0-12.23,1.07c-25.67,4.31-43.25,21.57-49.76,48.83-.2.85-1.13,109.91-1.13,163.65,3.54,5.95,7.25,11.67,14.66,13.45,13.94,3.34,25.13-4.46,26.63-18.73a92.64,92.64,0,0,0,.2-9.72q0-58,0-116v-7.87h9.4v9.17q0,173.33,0,346.64a80,80,0,0,0,1.15,15.16c2.07,10.33,9.94,15.46,18.79,19.45h13.9c16.13-5.61,21.39-17.35,21.27-33.84-.44-65.29-.18-130.6-.18-195.89v-8.56h9.47V476c0,65.07.32,130.13-.22,195.19-.13,16.72,4.8,28.73,21.08,34.7h13.9c16.13-5.83,20.85-18,20.81-34.25q-.18-76.29-.17-152.6V314.15h7.5V322q0,58,0,116c0,3.24-.14,6.5.18,9.71,1.08,10.2,7.1,17.06,15.54,18.95v6.51h-4.71c-8.11,0-14.68,7.28-14.68,16.24V597.06c0,9,6.57,16.24,14.68,16.24h23.43c8.11,0,14.69-7.28,14.69-16.24V489.45C669.93,480.49,663.35,473.21,655.24,473.21ZM552.85,246.32h12.72L558.3,265.1h-10L541,246.32Zm.45,123.13-13.4-10.88,8.52-85.07h9.77l8.49,85.07Z"
                                    transform="translate(-13.69 -21.85)"
                                    style={{
                                        fill: "#54c3be",
                                        stroke: "#000002",
                                        strokeMiterlimit: "10",
                                        strokeWidth: "1.121737634636446px",
                                    }}
                                />
                                <rect
                                    x="385.55"
                                    y="271.74"
                                    width="52.1"
                                    height="151.44"
                                    style={{
                                        fill: "#fcfcfc",
                                        stroke: "#000002",
                                        strokeMiterlimit: "10",
                                        strokeWidth: "1.121737634636446px",
                                    }}
                                />
                                <path
                                    d="M418.12,287.9a56,56,0,1,0-56-56A56,56,0,0,0,418.12,287.9Z"
                                    transform="translate(-13.69 -21.85)"
                                    style={{
                                        fill: "#54c3be",
                                        stroke: "#000002",
                                        strokeMiterlimit: "10",
                                        strokeWidth: "1.121737634636446px",
                                    }}
                                />
                                <path
                                    d="M527.77,543.68h-5v-8.3c5.94-2.6,9.35-7.9,12.62-13.39,0-57.81-1-175.1-1.22-176-6.94-29.12-25.68-47.63-53-52.4a1.32,1.32,0,0,0-.51-.1h0c-11.65,0-106,.13-113.67.22a84.36,84.36,0,0,0-13.17,1.15c-27.61,4.62-46.53,23.2-53.52,52.51-.22.92-1.22,118.22-1.22,176,3.81,6.41,7.8,12.56,15.77,14.48,15,3.59,27-4.8,28.65-20.16a101.13,101.13,0,0,0,.19-10.45q0-62.37,0-124.78V374h10.13v9.86q0,186.43.05,372.84A85.44,85.44,0,0,0,355,773c2.22,11.12,10.69,16.63,20.21,20.92H390.2c17.36-6,23-18.66,22.89-36.4-.49-70.23-.22-140.47-.22-210.69v-9.21h10.2v9c0,70,.35,140-.24,209.94-.14,18,5.17,30.91,22.69,37.32h14.94c17.36-6.27,22.43-19.37,22.37-36.83q-.18-82.07-.17-164.15V372.6h8.07v8.47q0,62.39,0,124.78a101.08,101.08,0,0,0,.2,10.45c1.15,11,7.63,18.35,16.7,20.39v7h-5.06c-8.72,0-15.79,7.83-15.79,17.48V676.89c0,9.65,7.07,17.47,15.79,17.47h25.2c8.73,0,15.81-7.82,15.81-17.47V561.16C543.58,551.51,536.5,543.68,527.77,543.68Zm-110.14-244h13.69l-7.82,20.2H412.75l-7.83-20.2Zm.49,132.43-14.4-11.7,9.15-91.5h10.51l9.14,91.5Z"
                                    transform="translate(-13.69 -21.85)"
                                    style={{
                                        fill: "#54c3be",
                                        stroke: "#000002",
                                        strokeMiterlimit: "10",
                                        strokeWidth: "1.121737634636446px",
                                    }}
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" col-6">
                <div className="dashboardtoprow " style={{ height: "50vh" }}>
                    <div className="row mt-2 pl-2">
                        <div className="col-12 sitka text-left">
                            <b>Total Employess/Department</b>
                        </div>

                    </div>
                    <hr style={{ marginTop: "0rem", marginBottom: "0.8rem" }}></hr>
                    <Chart
                        width={'40vw'}
                        height={'40vh'}
                        chartType="BarChart"
                        loader={<div>Loading Chart</div>}
                        data={
                            bargraphdata
                        }
                        options={{
                            chartArea: { width: "50%" },
                            isStacked: true,
                            hAxis: {
                                title: "Total Employees",
                                minValue: 0,
                            },
                            vAxis: {
                                title: "Department",
                            },
                            backgroundColor: '#FFFFFF'
                        }}
                        // For tests
                        rootProps={{ "data-testid": "3" }}
                    />
                </div>
            </div>
            <div className=" col-6">
                <div className="dashboardtoprow " style={{ height: "50vh" }}>
                    <div className="row  mt-2 pl-2">
                        <div className="col-12 sitka text-left">
                            <b>Total Attendance Today</b>
                        </div>

                    </div>
                    <hr style={{ marginTop: "0rem", marginBottom: "0.8rem" }}></hr>
                    <Chart
                        width={'40vw'}
                        height={'40vh'}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}

                        data={
                            piechartdata
                        }
                        options={{
                            title: `${piedataavailable ? "Employee Present Today" : "NO DATA AVAILABLE!"}`,
                            chartArea: { left: 170, top: 80 },
                            backgroundColor: '#FFFFFF'
                        }}
                        rootProps={{ "data-testid": "1" }}
                    />

                </div>
            </div>
        </div>

    );
};
export default HRDashboard;

// const HRDashboard = () => {

//     return (
//         <>
//             <div className="row mr-2">
//                 <div className="col-12 dash_background">
//                     <div className="row">

//                         <div className=" col-md-3">
//                             <div className="dashboardboxsmall ">

//                             </div>
//                         </div>
//                         <div className=" col-md-3">
//                             <div className="dashboardboxsmall ">

//                             </div>
//                         </div>
//                         <div className=" col-md-3">
//                             <div className="dashboardboxsmall ">

//                             </div>
//                         </div>
//                         <div className=" col-md-3">
//                             <div className="dashboardboxsmall ">

//                             </div>
//                         </div>
//                     </div>
//                     <div className="row ">

//                         <div className=" col-md-6">
//                             <div className="dashboardtoprow ">

//                             </div>
//                         </div>
//                         <div className=" col-md-6">
//                             <div className="dashboardtoprow ">

//                             </div>
//                         </div>
//                         <div className=" col-md-6">
//                             <div className="dashboardtoprow ">

//                             </div>
//                         </div>
//                         <div className=" col-md-6">
//                             <div className="dashboardtoprow ">

//                             </div>
//                         </div>
//                     </div>



//                 </div>
//             </div>
//         </>

//     )
// }
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { GrPrint } from "react-icons/gr";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getallAdjustmentpopulatetable, GetAllAdjustmentTableData } from "../../../actions/vouchersactions/generaladjustment";
import GeneralAdjustmentPrint, { GeneralAdjModal, GeneralAdjustmentIndividualPrint } from "../../accpayablepages/vouchersprints/adjustmententryprint";
import { CSVLink } from "react-csv";


export const GeneralAdjTable = (props) => {
    const resultsgetGeneralAdj = useSelector((state) => state.getGeneralAdjTable);
    const dispatch = useDispatch();
    const [statetoggle, settoggle] = useState(true);
    const [printadjustmentData, SetprintadjustmentData] = useState();
    const [TransactionData, setTransactionData] = useState();
    const rolesdata = useSelector((state) => state.GetAllRole);

    const [debitState, setdebitState] = useState(0);
    const [creditState, setcreditState] = useState(0);
  
    // print function
    const componentRef = useRef();
  
    const printPaymentRec = useReactToPrint({
      content: () => componentRef.current,
    });
    //Table Data fiter
    const individualadjustmentRef = useRef();
  
    const printOfficalContract = useReactToPrint({
      content: () => individualadjustmentRef.current,
    });
    useEffect(() => {
      if (printadjustmentData !== undefined) {
        printOfficalContract();
      }
    }, [printadjustmentData, statetoggle]);
    var suggestionlist = [];
  
    const onTextChange = (e) => {
      const value = e.target.value;
      if (value === "") {
        dispatch(getallAdjustmentpopulatetable({}));
      } else {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
  
          suggestions = resultsgetGeneralAdj.data
            .sort()
            .filter((val) => regex.test(val.AdjustmentVoucherId));
        }
        suggestionlist = suggestions;
        dispatch(GetAllAdjustmentTableData(suggestionlist));
      }
    };
    useEffect(() => {
      let data = {};
  
      dispatch(getallAdjustmentpopulatetable(data));
    }, [dispatch]);
    function ongo() {
      let data = {
        ProjectId: `${localStorage.getItem("ProjectId")}`,
        From: document.getElementById("fromdate").value,
        To: document.getElementById("todate").value,
      };
  
      dispatch(getallAdjustmentpopulatetable(data));
    }
    function showmodal(data) {
      document.getElementById("mymodal").style.display = "block";
      setTransactionData(data);
      var credit = 0;
      var debit = 0;
      data?.map((val) =>
        val.TransactionType === "CREDIT" ? credit += parseFloat(val.Amount) : val.TransactionType === "DEBIT" ? debit += parseFloat(val.Amount) : null
      );
      setdebitState(debit)
      setcreditState(credit)
    }
    function closemodal() {
      document.getElementById("mymodal").style.display = "none";
    }
    const componentRefModal = useRef();
  
    const printGenrealRecModal = useReactToPrint({
      content: () => componentRefModal.current,
    });

    const [CsvData, setCsvData] = useState([]);
    useEffect(() => {
      let arr = [];

      resultsgetGeneralAdj.data.forEach((val, index) => {
        let year = val.EntryDate.slice(0, 4);
        let day = val.EntryDate.slice(8, 10);
        let month = val.EntryDate.slice(5, 7);
        arr.push({
          Sr: index+1,
          Date: day + "-" + month + "-" + year,
          "Voucher No.": val.AdjustmentVoucherId,
          "Payment Type":
            val.PaymentType === "SELECT" ? "WRITE OFF" : val.PaymentType,
          "Receipt Type": val.ReceiptType,
          Amount: val.Amount !== undefined ? val.Amount.toLocaleString() : null,
          Narration: val.Narration,
        });
      });
      setCsvData(arr);
    }, [resultsgetGeneralAdj]);

    return (
      <>
        <div hidden>
          <GeneralAdjustmentPrint
            ref={componentRef}
            printData={resultsgetGeneralAdj}
          />
        </div>
        <div hidden>
          <GeneralAdjustmentIndividualPrint
            ref={individualadjustmentRef}
            printData={printadjustmentData}
          />
        </div>
        <div hidden>
          <GeneralAdjModal
            ref={componentRefModal}
            total={debitState + creditState}
            debitTotal={debitState}
            creditTotal={creditState}
            printData={TransactionData}
          />
        </div>
        <div className="row  app_font">
          <div className="dash_background col-12 pt-2">
            <div className="row pt-3 ">
              <div className="col-md-1 pr-0 pl-0">
                <label className="input_label m-0">Search:</label>
              </div>
              <div className="col-md-3 pr-0 pl-0">
                <input
                  type="text "
                  className="input_styling "
                  placeholder="Voucher"
                  onChange={onTextChange}
                ></input>
              </div>
              <div className="col-md-3 text-center  pr-0 pl-0">
                <label className="input_label m-0">From:</label>
                <input
                  type="date"
                  className="input_date ml-3"
                  id="fromdate"
                ></input>
              </div>
              <div className="col-md-3 text-center pr-0 pl-0">
                <label className="input_label m-0">To:</label>
                <input
                  type="date"
                  className="input_date ml-3"
                  id="todate"
                ></input>
              </div>
              <div className="col-md-1 pr-0 pl-0">
                <button className="btn_Go" onClick={ongo}>
                  Go
                </button>
              </div>
              <div className="col-md-1 pr-0 pl-0">
                {resultsgetGeneralAdj.loading ? (
                  <Loader
                    type="ThreeDots"
                    color="green"
                    height={40}
                    width={40}
                  />
                ) : null}
              </div>
            </div>

            <div className=" col-12  table_height pr-0 pl-0">
              <div className="table-responsive mt-2">
                <table className="table table-borderless m-0">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Date</th>
                      <th>Voucher No.</th>
                      <th>Payment Type</th>
                      <th>Receipt Type</th>
                      <th>Amount</th>
                      <th>Narration</th>

                      <th className="text-center">
                        {rolesdata?.data?.Access?.includes("C") ? (
                          <button
                            className="save_btn"
                            onClick={() => {
                              props.showadjustmenttable();
                            }}
                          >
                            New+
                          </button>
                        ) : null}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {resultsgetGeneralAdj.data.map((val, index) => {
                      let year = val.EntryDate.slice(0, 4);
                      let day = val.EntryDate.slice(8, 10);
                      let month = val.EntryDate.slice(5, 7);
                      switch (month) {
                        case "01":
                          month = "Jan";

                          break;
                        case "02":
                          month = "Feb";

                          break;
                        case "03":
                          month = "Mar";

                          break;
                        case "04":
                          month = "Apr";

                          break;
                        case "05":
                          month = "May";

                          break;
                        case "06":
                          month = "Jun";

                          break;
                        case "07":
                          month = "Jul";

                          break;
                        case "08":
                          month = "Aug";

                          break;
                        case "09":
                          month = "Sep";

                          break;
                        case "10":
                          month = "Oct";
                          break;
                        case "11":
                          month = "Nov";
                          break;
                        case "12":
                          month = "Dec";
                          break;
                        default:
                          break;
                      }
                      return (
                        <tr
                          key={index}
                          onClick={() => {
                            showmodal(val.Transactions);
                          }}
                        >
                          <td
                            className="table_data"
                            onClick={() => {
                            }}
                          >
                            {index + 1}
                          </td>
                          <td
                            className="table_data"
                            onClick={() => {
                            }}
                          >
                            {day + "-" + month + "-" + year}
                          </td>
                          <td
                            className="table_data"
                            onClick={() => {
                            }}
                          >
                            {val.AdjustmentVoucherId}
                          </td>
                          <td
                            className="table_data"
                            onClick={() => {
                            }}
                          >
                            {val.PaymentType === "SELECT"
                              ? "WRITE OFF"
                              : val.PaymentType}
                          </td>
                          <td
                            className="table_data"
                            onClick={() => {
                            }}
                          >
                            {val.ReceiptType}
                          </td>
                          <td
                            className="table_data"
                            onClick={() => {
                            }}
                          >
                            {val.Amount !== undefined
                              ? val.Amount.toLocaleString()
                              : null}
                          </td>
                          <td
                            className="table_data"
                            onClick={() => {
                            }}
                          >
                            {val.Narration}
                          </td>

                          <td
                            className="text-center cur_sor"
                            onClick={() => {
                              SetprintadjustmentData(val);
                              settoggle(!statetoggle);
                            }}
                          >
                            <GrPrint size="1.3em" />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-6  pt-2 text-right pr-0 pl-0">
              <button
                className="print_btn float-left"
                onClick={() => {
                  printPaymentRec();
                }}
              >
                <TiPrinter size="1.3em" />
                Print
              </button>
              <button
                style={{ color: "black !important" }}
                className="print_btn ml-2 float-left"
              >
                <CSVLink
                  style={{ color: "black" }}
                  filename="General_Adjustment_Csv"
                  data={CsvData}
                >
                  Download CSV
                </CSVLink>
              </button>
            </div>
          </div>
        </div>
        <div id="mymodal" class="modal">
          <div class="modal-content-cat_voucher">
            <div className="row">
              <div className="col-12  page_heading">
                <h4 className="  text-center pt-2">Transaction Detail</h4>
                <span class="close pr-2  " onClick={closemodal}>
                  &times;
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="table-responsive_modal mt-4 table_height-voucher">
                  <table className="table table-borderless m-0 ">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th>Account</th>
                        <th>Narration</th>
                        <th>Debit</th>

                        <th>Credit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {TransactionData !== undefined
                        ? TransactionData.map((x, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{x.Account.AccountTitle}</td>
                              <td>{x.Narration}</td>
                              <td>
                                {x.TransactionType === "DEBIT"
                                  ? x.Amount.toLocaleString()
                                  : null}
                              </td>

                              <td>
                                {x.TransactionType === "CREDIT"
                                  ? x.Amount.toLocaleString()
                                  : null}
                              </td>
                            </tr>
                          ))
                        : null}
                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          {" "}
                          <label className="input_label m-0">Total:</label>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="input_total_styling "
                            placeholder="Debit"
                            disabled
                            defaultValue={parseFloat(
                              debitState
                            ).toLocaleString()}
                            key={debitState}
                          ></input>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="input_total_styling "
                            placeholder="Credit"
                            disabled
                            defaultValue={parseFloat(
                              creditState
                            ).toLocaleString()}
                            key={creditState}
                          ></input>
                        </td>
                        {/* <td><input type="text" className="input_total_styling " placeholder="Balance" disabled value={stateledgerdata[stateledgerdata.length - 1]?.AccountBalance?.toLocaleString()}></input></td> */}
                        <td></td>{" "}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-12 mt-2">
                <button
                  className="print_btn float-left"
                  onClick={() => {
                    printGenrealRecModal();
                  }}
                >
                  <TiPrinter size="1.3em" />
                  Print
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getallbanks, getallcash } from "../../../actions/getbanks";

export const TaxReceivableForm = (props) => {
    const payableVouchersData = useSelector(state => state.GetAllTaxReceivable);
    let vouchernumber = `TR-${Math.floor(Date.now())}`;
    const resultsallbanks = useSelector((state) => state.getAllBanksReducer);
    const resultsallcash = useSelector((state) => state.getAllCash);
    const dispatch = useDispatch();


    let TaxReceivableIds = props.statetaxList2;
    let TaxAmounts = props.statetaxList;
    let totalsum = 0;

    TaxAmounts?.map((val) => {
        totalsum += val.Amount

    })
    function savedata() {
        if (document.getElementById("date").value === "") {
            toast.info("Date is Required");
        }
        else if (document.getElementById("CPRdate").value === "") {
            toast.info(" Crp Date is Required");
        }
        else if (document.getElementById("Amount").value === "") {
            toast.info("Amount is Required");

        }
        else if (document.getElementById("CPRno").value === "") {
            toast.info("CRP No is Required");

        }
        else if (document.getElementById("narration").value === "") {
            toast.info("Narration is Required");

        }

        else if (document.getElementById("vouchertype").selectedIndex === 0) {
            toast.info("Select Type");

        }

        else if (document.getElementById("vouchertype").options[
            document.getElementById("vouchertype").selectedIndex
        ].value === "Bank Voucher" && (document.getElementById("banks").options[
            document.getElementById("banks").selectedIndex
        ].value === "Select" || document.getElementById("chequeno").value === "")) {
            toast.info("Cheque no and Bank name are Required");
        }
        else if (document.getElementById("vouchertype").options[
            document.getElementById("vouchertype").selectedIndex
        ].value === "Cash Voucher" && document.getElementById("cashs").options[
            document.getElementById("cashs").selectedIndex
        ].value === "Select") {
            toast.info("Selech Cash Account");
        } else {
            let paymentType = document.getElementById("vouchertype").options[
                document.getElementById("vouchertype").selectedIndex
            ].value;
            if (paymentType === "Bank Voucher") {
                paymentType = "Bank";
            } else {
                paymentType = "Cash";
            }
            let bankId = document.getElementById("banks").options[
                document.getElementById("banks").selectedIndex
            ].value;
            if (bankId === "Select") {

                bankId = 0;
            }
            else {
                bankId = document.getElementById("banks").options[
                    document.getElementById("banks").selectedIndex
                ].dataset.id;
            }
            let cashId = document.getElementById("cashs").options[
                document.getElementById("cashs").selectedIndex
            ].value;
            if (cashId === "Select") {

                cashId = 0;

            }
            else {
                cashId = document.getElementById("cashs").options[
                    document.getElementById("cashs").selectedIndex
                ].dataset.id;
            }
            let checkNumber;

            if (paymentType === "Cash") {
                checkNumber = null;
            } else {
                checkNumber = document.getElementById("chequeno").value;
            }

            let Voucher = {
                TaxReceivableVoucherId: document.getElementById("voucherno").value,

                BankAccountId: paymentType === "Cash" ? parseInt(cashId) : parseInt(bankId),
                Amount: parseFloat(document.getElementById("Amount").value),
                ChequeNumber: checkNumber,
                EntryDate: document.getElementById("date").value,
                PaymentType: paymentType,
                Narration: document.getElementById("narration").value,
                Cprdate: document.getElementById("CPRdate").value,
                Cprnumber: document.getElementById("CPRno").value

            };



            document.getElementById("savedata").disabled = true;
            axios({
                url: `${window.$url}Vouchers/TaxReceivables/Pay`,
                method: "post",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "content-type": "application/json",
                },
                data: JSON.stringify({
                    Voucher, TaxAmounts, TaxReceivableIds
                }
                ),
            }).then(
                (result) => {
                    document.getElementById("savedata").disabled = false;
                    if (result.data.IsSuccess === true) {


                        toast.info(result.data.Message);
                        props.canceltaxtable();


                    } else {
                        toast.info(result.data.Message);
                    }
                },
                (error) => {
                    document.getElementById("savedata").disabled = false;
                    toast.info(
                        "Something went wrong!"
                    )
                }

            );

        }
    }
    useEffect(() => {

        dispatch(getallbanks());

        dispatch(getallcash());



    }, [dispatch]);
    return (
        <>
            <div className="row ">
                <div className="col-md-12   text-left page_heading">
                    <h4 className="m-0">Tax Receivable Voucher</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-md-2 mt-4  text-left p-0">
                    <label className="input_label m-0 ">Date:</label>
                </div>
                <div className="col-md-3 mt-4  p-0">
                    <input type="date" className="input_date_styling  " defaultValue={moment().format("YYYY-MM-DD")} id="date"></input><span className="estaric">*</span>
                </div>
                <div className="col-md-2 mt-4   p-0"></div>

                <div className="col-md-2 mt-4  text-left p-0">
                    <label className="input_label m-0">Voucher No:</label>
                </div>
                <div className="col-md-3 mt-4   p-0">
                    <input
                        type="text"
                        disabled
                        value={vouchernumber}
                        className="input_styling  "
                        id="voucherno"
                    ></input><span className="estaric">*</span>
                </div>
            </div>
            <div className="row">
                <div className="col-md-2 mt-4  text-left p-0">
                    <label className="input_label m-0 ">CPR Date:</label>
                </div>
                <div className="col-md-3 mt-4  p-0">
                    <input type="date" className="input_date_styling  " id="CPRdate"></input><span className="estaric">*</span>
                </div>
                <div className="col-md-2 mt-4   p-0"></div>

                <div className="col-md-2 mt-4  text-left p-0">
                    <label className="input_label m-0">CPR No:</label>
                </div>
                <div className="col-md-3 mt-4   p-0">
                    <input
                        type="text"


                        className="input_styling  "
                        id="CPRno"
                    ></input><span className="estaric">*</span>
                </div>
            </div>

            <div className="row">
                <div className="col-md-2 mt-4  text-left p-0">
                    <label className="input_label m-0 ">Type:</label>
                </div>
                <div className="col-md-3 mt-4  p-0">
                    <select
                        id="vouchertype"
                        onChange={(e) => {
                            if (e.target.value === "Bank Voucher") {
                                document.getElementById("bankdiv").style.display = "block";
                                document.getElementById("cashdiv").style.display = "none";

                            } else if (e.target.value === "Cash Voucher") {
                                document.getElementById("bankdiv").style.display = "none";
                                document.getElementById("cashdiv").style.display = "block";

                            }
                            else {
                                document.getElementById("bankdiv").style.display = "none";
                                document.getElementById("cashdiv").style.display = "none";

                            }
                        }}
                        type="text"
                        className="input_styling  "
                    >
                        <option>Select</option>

                        <option>Cash Voucher</option>
                        <option>Bank Voucher</option>
                    </select><span className="estaric">*</span>
                </div>
                <div className="col-md-2 mt-4   p-0"></div>

                <div className="col-md-2 mt-4  text-left p-0">
                    <label className="input_label m-0">Amount:</label>
                </div>
                <div className="col-md-3 mt-4   p-0">
                    <input
                        type="number"
                        className="input_styling  "
                        id="Amount"
                        disabled
                        defaultValue={totalsum}
                    ></input><span className="estaric">*</span>
                </div>
            </div>
            <div className="row">



                {/* <div className="col-md-2 mt-4   p-0"></div> */}

                <div className="col-md-2 mt-4  text-left p-0">
                    <label className="input_label m-0">Narration:</label>
                </div>
                <div className="col-md-3 mt-4   p-0">
                    <textarea className="input_address " id="narration"></textarea><span className="estaric">*</span>
                </div>
            </div>
            <span id="cashdiv" style={{ display: "none" }}>
                <div className="row  ">
                    <div className="col-md-2 mt-4 text-left p-0">
                        <label className="input_label m-0">Cash:</label>
                    </div>
                    <div className="col-md-3 mt-4  p-0">
                        <select id="cashs" className="input_styling  ">
                            <option>Select</option>
                            {resultsallcash.data.map(x => (<option data-id={x.AccountId}>{x.AccountTitle}</option>))

                            }

                        </select><span className="estaric">*</span>
                    </div>


                </div>
            </span>

            <span id="bankdiv" style={{ display: "none" }}>
                <div className="row ">
                    <div className="col-md-2 mt-4  text-left p-0">
                        <label className="input_label m-0">Bank:</label>
                    </div>
                    <div className="col-md-3 mt-4   p-0">
                        <select id="banks" className="input_styling  ">
                            <option>Select</option>
                            {resultsallbanks.data.map(x => (<option data-id={x.AccountId}>{x.AccountTitle}</option>))

                            }

                        </select><span className="estaric">*</span>
                    </div>

                    <div className="col-md-2 mt-4   p-0"></div>

                    <div className="col-md-2 mt-4  text-left p-0">
                        <label className="input_label m-0">Cheque No:</label>
                    </div>
                    <div className="col-md-3 mt-4   p-0">
                        <input
                            id="chequeno"
                            type="text"
                            placeholder="Cheque No"
                            className="input_styling "
                        ></input><span className="estaric">*</span>
                    </div>
                </div>
            </span>
            <div className="row  ">
                <div className=" col-12 pt-2  pl-0 pr-md-2 mt-2">
                    <div className=" float-right mt-md-4 ">
                        <span>
                            <button
                                className="cancel_btn ml-2  "
                                onClick={props.canceltaxtable}
                            >

                                Cancel
                            </button>
                        </span>

                        <span>
                            <button className="save_btn ml-2  " id="savedata" onClick={savedata}>
                                <BsCloudUpload size="1.3em" />
                                Save
                            </button>
                        </span>
                    </div>
                </div>
            </div>


        </>
    )
}
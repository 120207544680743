import moment from "moment";
import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";

class MainTransferPrint extends Component {
    render() {

        return (
          <div style={{ backgroundColor: "white", height: "100%" }}>
            <div className="row m-0 p-0">
              <PrintHeader />
            </div>
            <div
              className="row m-3 p-0"
              // style={{ border: "5px solid darkgrey", height: "auto" }}
            >
              <div className="col-12 text-center mt-2">
                <h4 style={{ color: "red" }}>
                  <u>Transfer Contracts</u>
                </h4>

                <div className="mt-5">
                  <div className="col-12">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Sr</th>
                          <th>date</th>

                          <th> Buyer</th>

                          <th> Unit</th>
                          <th> SaleRate</th>
                          <th> Total</th>
                          <th> Received</th>
                          <th> Status</th>
                        </tr>
                      </thead>

                      <tbody>
                        {this.props.printData.data !== undefined
                          ? this.props.printData.data.map((val, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  {moment(
                                    val.NewContract.EntryDate.split("T")[0]
                                  ).format("DD-MMM-YYYY")}
                                </td>

                                <td>
                                  {
                                    val.NewContract.CustomerCnicNavigation
                                      .CustomerName
                                  }
                                </td>

                                <td>{val.NewContract.Unit.UnitName}</td>
                                <td>
                                  {val.NewContract.SaleRate.toLocaleString()}
                                </td>
                                <td>
                                  {val.NewContract.TotalPrice.toLocaleString()}
                                </td>
                                <td>
                                  {val.NewContract.CustomerReceivable.toLocaleString()}
                                </td>
                                <td>{val.NewContract.ContractStatus}</td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default MainTransferPrint;
import axios from 'axios';
import { toast } from "react-toastify";
export const GetAllSellers = (data) => {

    return ({
        type: "get_allseller",
        payload: data,
        loader: false
    })
}
export const GetSellerData = (data) => {

    return ({
        type: "get_sellerdata",
        payload: data,

    })
}
export function getallsellers() {
    return (dispatch) => {
        // let pgurl = `${window.$url}Sellers/GetAll?ProjectId=${localStorage.getItem("ProjectId")}`;
        // if (localStorage.getItem("ProjectId") === null) {
        //     pgurl = `${window.$url}Sellers/GetAll`
        // }
        axios({
            url: `${window.$url}Sellers/GetAll`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllSellers(result.data.Data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}
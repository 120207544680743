import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getalllenders } from "../../../actions/getlender";
import { getallProject } from "../../../actions/getprojects";

export const Investment = (props) => {
    const LenderData = useSelector(state => state.getAllLenders);
    const ProjectData = useSelector(state => state.getProject);
    const [stateSectionList, setSectionList] = useState([]);
    const [stateUnitList, setUnitList] = useState([]);
    const [tabledata, settabledata] = useState([]);
    const [loanAgainstLands, setloanAgainstLands] = useState([]);
    const [stateprojectid, setprojectid] = useState();
    const [state, setstate] = useState({
        LoanId: 0,
        LoanName: null,
        LenderId: 0,
        Amount: 0,
        EntryDate: null,
        DueDate: null,
        NetProfit: 0,
        ProjectId: 0


    });
    function addtoarray() {
        if (document.getElementById("project").selectedIndex === 0) {
            toast.info("Select Project");
        }
        else if (document.getElementById("Block").selectedIndex === 0) {
            toast.info("Select Block");

        }
        else if (document.getElementById("unit").selectedIndex === 0) {
            toast.info("Select Unit");

        } else {
            // let listofprojects = [];
            let listofprojects = {
                project: document.getElementById("project").options[document.getElementById("project").selectedIndex].value,
                section: document.getElementById("Block").options[document.getElementById("Block").selectedIndex].value,
                Unit: document.getElementById("unit").options[document.getElementById("unit").selectedIndex].value
            }
            let listofIDs = {
                LoanAgainstLandId: 0,
                LoanId: 0,
                ProjectId: parseInt(document.getElementById("project").options[document.getElementById("project").selectedIndex].dataset.id),
                SectionId: parseInt(document.getElementById("Block").options[document.getElementById("Block").selectedIndex].dataset.id),
                UnitId: parseInt(document.getElementById("unit").options[document.getElementById("unit").selectedIndex].dataset.id)
            }
            // console.log(listofprojects);
            settabledata([...tabledata, listofprojects]);
            setloanAgainstLands([...loanAgainstLands, listofIDs]);
            document.getElementById("project").selectedIndex = 0;
            document.getElementById("Block").selectedIndex = 0;
            document.getElementById("unit").selectedIndex = 0;
        }
    }
    const savedata = () => {
        if (document.getElementById("Date").value === "") {
            toast.info("Date is Required");
        }
        else if (document.getElementById("dueDate").value === "") {
            toast.info("Due Date is Required");

        }
        else if (document.getElementById("Loanname").value === "") {
            toast.info("Enter the loan Name");

        }
        else if (document.getElementById("Loanamounts").value === "") {
            toast.info("Enter the loan amount");

        }
        else if (parseFloat(document.getElementById("percent").value) > 100 && document.getElementById("percent").value === "") {
            toast.info("Percentage Should be less than 100");

        }

        else if (document.getElementById("lendor").selectedIndex === 0) {
            toast.info("Select Lender");

        } else {
            let Loans = state;
            // Loans.LoanId = parseInt(0);
            Loans.LenderId = document.getElementById("lendor").options[document.getElementById("lendor").selectedIndex].dataset.id;
            Loans.LoanName = document.getElementById("Loanname").value;
            Loans.Amount = parseInt(document.getElementById("Loanamounts").value.replace(/,/g, ''));
            Loans.EntryDate = document.getElementById("Date").value;
            Loans.DueDate = document.getElementById("dueDate").value;
            Loans.NetProfit = parseInt(document.getElementById("percent").value);
            Loans.TermsAndConditions = document.getElementById("Terms").value;
            Loans.ProjectId = parseInt(document.getElementById("lenderproject").options[document.getElementById("lenderproject").selectedIndex].dataset.id);





            document.getElementById("loansave").disabled = true;
            axios({
                method: "post",
                // url: `${window.$url}Lenders/AddLoan`,
                url: `${window.$url}Investment/Add`,
                data: JSON.stringify({
                    Loans, loanAgainstLands
                }

                ),
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "content-type": "application/json",
                },
            }).then(
                (result) => {
                    document.getElementById("loansave").disabled = false;
                    if (result.data.IsSuccess === true) {
                        document.getElementById("Date").value = "";
                        document.getElementById("dueDate").value = "";
                        document.getElementById("percent").value = "";
                        document.getElementById("Terms").value = "";
                        document.getElementById("Loanamounts").value = "";
                        document.getElementById("Loanname").value = "";
                        document.getElementById("lendor").selectedIndex = 0;
                        document.getElementById("againstproject").checked = false;
                        document.getElementById("hideshow").style.display = "none";
                        document.getElementById("unit").selectedIndex = 0;
                        document.getElementById("Block").selectedIndex = 0;
                        document.getElementById("lenderproject").selectedIndex = 0;
                        document.getElementById("project").selectedIndex = 0;
                        settabledata([]);
                        setloanAgainstLands([]);
                        setprojectid();
                        setUnitList([]);
                        setSectionList([]);
                        setstate({
                            LoanId: 0,
                            LoanName: null,
                            LenderId: 0,
                            Amount: 0,
                            EntryDate: null,
                            DueDate: null,
                            NetProfit: 0,
                            ProjectId: 0
                        })

                        toast.info(result.data.Message);
                        props.cancelloantable();
                    }
                    else {
                        toast.info(result.data.Message);
                    }


                },
                (error) => {
                    toast.info("Someting went wrong");
                    document.getElementById("loansave").disabled = false;

                }
            );

        }
    };

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(getalllenders());
        dispatch(getallProject());
    }, [])
    return (
        <>
            <div className="row ">
                <div className="col-md-12   text-left page_heading">
                    <h4 className="m-0">Add Investment</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-md-1 mt-4 p-0">
                    <label className="input_label m-0 pl-md-4">Date:</label>
                </div>
                <div className="col-md-3 mt-4 ">
                    <input type="date" className="input_date_styling  ml-md-3" id="Date" defaultValue={moment().format("YYYY-MM-DD")}></input><span className="estaric">*</span>

                </div>
                <div className="col-md-1 mt-4 text-left p-0">
                    <label className="input_label m-0 pl-md-4">Project:</label>
                </div>
                <div className="col-md-3 mt-4 ">

                    <select id="lenderproject" className="input_styling  ml-md-3" onChange={() => { setprojectid(document.getElementById("lenderproject").options[document.getElementById("lenderproject").selectedIndex].dataset.id) }}>
                        <option>Select</option>
                        {ProjectData.data.map((x, index) => <option data-id={x.ProjectId} key={index}>{x.ProjectName}</option>)

                        }

                    </select><span className="estaric">*</span>
                </div>
                <div className="col-md-1 mt-4 text-left p-0">
                    <label className="input_label m-0 pl-md-4">Investment Name:</label>
                </div>
                <div className="col-md-3 mt-4 ">

                    <input type="text" className="input_styling ml-md-3 " id="Loanname"></input><span className="estaric">*</span>

                </div>


            </div>
            <div className="row">
                <div className="col-md-1 mt-4 p-0">
                    <label className="input_label m-0 pl-md-4">Due Date:</label>
                </div>
                <div className="col-md-3 mt-4 ">
                    <input type="date" className="input_date_styling  ml-md-3" id="dueDate"></input><span className="estaric">*</span>

                </div>
                <div className="col-md-1 mt-4  p-0">
                    <label className="input_label m-0 pl-md-4" >Investor:</label>
                </div>
                <div className="col-md-3 mt-4">
                    <select id="lendor" className="input_styling  ml-md-3" >
                        <option>Select</option>
                        {LenderData.data.map(x => <option data-id={x.LenderId}>{x.LenderName}</option>)

                        }

                    </select><span className="estaric">*</span>

                </div>
                <div className="col-md-1 mt-4 text-left p-0">
                    <label className="input_label m-0 pl-md-4">Inv Amt:</label>
                </div>
                <div className="col-md-3 mt-4 ">

                    <input type="text" className="input_styling ml-md-3 " id="Loanamounts"
                        onChange={(e) => { if (e.target.value.length > 0) document.getElementById("Loanamounts").value = parseInt(e.target.value.replace(/,/g, '')).toLocaleString() }}
                    ></input><span className="estaric">*</span>

                </div>



            </div>
            <div className="row">
                <div className="col-md-3 mt-4 p-0">
                    <label className="input_label m-0 pl-md-4">Against Units of Project ? :</label>
                </div>
                <div className="col-md-1 mt-4 ">
                    <input type="checkbox" id="againstproject" onChange={() => { if (document.getElementById("againstproject").checked === true) { document.getElementById("hideshow").style.display = "block"; settabledata([]); setloanAgainstLands([]); } else { document.getElementById("hideshow").style.display = "none" } settabledata([]); setloanAgainstLands([]); }}></input>

                </div>
                <div className="col-md-4 mt-4 text-left p-0">

                </div>
                <div className="col-md-1 mt-4 text-left p-0">
                    <label className="input_label m-0 pl-md-4">Profit Perc%:</label>
                </div>
                <div className="col-md-3 mt-4 ">
                    <input type="number" className="input_styling  ml-md-3" id="percent"></input>

                </div>


            </div>
            <span id="hideshow" style={{ display: "none" }}>
                <div className="row" >
                    <div className="col-md-1 mt-4 text-left p-0">
                        <label className="input_label m-0 pl-md-4">Project:</label>
                    </div>
                    <div className="col-md-3 mt-4 ">

                        <select id="project" className="input_styling  ml-md-3" onChange={() => { setSectionList(ProjectData.data[document.getElementById("project").options[document.getElementById("project").selectedIndex].dataset.idx]); document.getElementById("Block").selectedIndex = 0; document.getElementById("unit").selectedIndex = 0; }}>
                            <option>Select</option>
                            {ProjectData.data.map((x, index) =>
                                parseInt(stateprojectid) === parseInt(x.ProjectId) ? <option data-id={x.ProjectId} data-idx={index} key={index}>{x.ProjectName}</option> : null)

                            }

                        </select>
                    </div>


                    <div className="col-md-1 mt-4 text-left p-0">
                        <label className="input_label m-0 pl-md-4">Block:</label>
                    </div>
                    <div className="col-md-3 mt-4 ">

                        <select id="Block" className="input_styling  ml-md-3" onChange={() => { setUnitList(stateSectionList.Sections[document.getElementById("Block").options[document.getElementById("Block").selectedIndex].dataset.idx]) }}>
                            <option>Select</option>
                            {stateSectionList !== undefined && stateSectionList.Sections !== undefined ? stateSectionList.Sections.map((x, index) => <option data-id={x.SectionId} data-idx={index} key={index}>{x.SectionName}</option>) : null

                            }

                        </select>

                    </div>
                    <div className="col-md-1 mt-4 text-left p-0">
                        <label className="input_label m-0 pl-md-4">Unit:</label>
                    </div>
                    <div className="col-md-3 mt-4 ">

                        <select id="unit" className="input_styling  ml-md-3" >
                            <option>Select</option>
                            {stateUnitList !== undefined && stateUnitList.Units !== undefined ? stateUnitList.Units.map((x, index) => <option data-id={x.UnitId} key={index}>{x.UnitName}</option>) : null

                            }

                        </select>

                    </div>

                </div>
                <div className="row ">
                    <div className="col-md-10"></div>

                    <div className="col-md-1 text-right pt-2 "><button className="save_btn " onClick={() => {
                        settabledata([]); setloanAgainstLands([]);
                    }}>Remove</button></div>
                    <div className="col-md-1 text-left pt-2  "><button className="save_btn " onClick={addtoarray}>Add</button></div>

                </div>

                <div className="mt-4">
                    <div className="table-responsive mt-4">
                        <table className="table table-borderless m-0">
                            <thead>
                                <tr>
                                    <th>Project</th>
                                    <th>Block</th>
                                    <th>Unit</th>

                                </tr>
                            </thead>
                            <tbody>
                                {tabledata.map((val, index) => (
                                    <tr>
                                        <td>{val.project}</td>
                                        <td>{val.section}</td>
                                        <td>{val.Unit}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>

                </div>
            </span>
            <div className="row">
                <div className="col-md-7 mt-4 text-left p-0">

                </div>



                <div className="col-md-2 mt-4 text-left p-0">
                    <label className="input_label m-0 pl-md-4">Terms and Condition:</label>
                </div>
                <div className="col-md-3 mt-4 ">

                    <textarea className="input_address ml-md-3 " id="Terms"></textarea>

                </div>

            </div>
            <div className=" float-right mt-md-4 ">
                <span>
                    <button
                        className="cancel_btn ml-2  "
                        onClick={props.cancelloantable}
                    >

                        Cancel
                    </button>
                </span>

                <span>
                    <button className="save_btn ml-2  " id="loansave" onClick={savedata} >
                        <BsCloudUpload size="1.3em" />
                        Save
                    </button>
                </span>
            </div>
        </>
    )
}
import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import { getallbanks, getallcash } from "../../../../actions/getbanks";
import { getallProject } from "../../../../actions/getprojects";
import { getallpaymentinstalltable } from "../../../../actions/vouchersactions/getpaymentinstall";
import { useEffect } from "react";
import { BsCloudUpload, BsFillInfoSquareFill } from "react-icons/bs";
import ScreenInfoModal from "../../../../ScreenInfoModal/ScreenInfoModal";

export const ReceiptInstallmentsForm = (props) => {
  const resultsallbanks = useSelector((state) => state.getAllBanksReducer);
  const resultsreceiptpopulate = useSelector(
    (state) => state.getReceiptInstallPopulate
  );
  const resultsproject = useSelector((state) => state.getProject);
  const resultsallcash = useSelector((state) => state.getAllCash);
  const resultspaymenttable = useSelector(
    (state) => state.getPaymentInstallTable
  );
  const [showDetailModal, setshowDetailModal] = useState(false);

  const [InstalmentPaymentVoucherId, setInstalmentPaymentVoucherId] =
    useState();

  const [paymentInstallment, setpaymentInstall] = useState([]);
  const [projectlist, setprojectlist] = useState({});
  const [isreceived, setisreceived] = useState(false);

  let dispatch = useDispatch();
  let vouchernumber = `IRV-${Math.floor(Date.now())}`;
  var suggestionlist1 = [];

  const UnitonTextChange = (e) => {
    const value = e.target.value;
    if (value == "") {
      ReactDOM.render(null, document.getElementById("unitdemo"));
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = resultspaymenttable.data
          .sort()
          .filter((v) =>
            regex.test(
              isreceived === true
                ? v.Received === false
                  ? v.Unit !== null
                    ? v.Unit
                    : null
                  : null
                : null
            )
          );
      }
      suggestionlist1 = suggestions;

      if (suggestionlist1.length === 0) {
        ReactDOM.render(null, document.getElementById("unitdemo"));
      } else {
        ReactDOM.render(
          <ul className="suggestbox">
            {suggestionlist1.map((item) => (
              <li className="listcss" onClick={() => suggestionselected1(item)}>
                {item === undefined ? item : item.Unit}
              </li>
            ))}
          </ul>,
          document.getElementById("unitdemo")
        );
      }
    }
  };

  const onTextChangeUnit = () => {
    var inputValue = document.getElementById("unit").value;
    var options = document.getElementById("mylist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        resultspaymenttable.data.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });
        document.getElementById("Customers").value = val.CustomerName;
        document.getElementById("Amount").value = parseFloat(
          parseFloat(val.Amount) - parseFloat(val.ReceivedAmount)
        ).toLocaleString();
        setInstalmentPaymentVoucherId(val.InstalmentPaymentVoucherId);

        break;
      }
    }
  };

  const suggestionselected1 = (val) => {
    document.getElementById("unit").value = val.Unit;
    document.getElementById("Customers").value = val.CustomerName;
    document.getElementById("Amount").value = parseFloat(
      parseFloat(val.Amount) - parseFloat(val.ReceivedAmount)
    ).toLocaleString();
    setInstalmentPaymentVoucherId(val.InstalmentPaymentVoucherId);
    // setUnitId(val.UnitId);

    suggestionlist1 = [];
    ReactDOM.render(null, document.getElementById("unitdemo"));
  };
  const savedata = () => {
    if (document.getElementById("Date").value === "") {
      toast.info("Date is Required");
    } else if (document.getElementById("Customers").value === "") {
      toast.info("Select Customer");
    } else if (document.getElementById("project").selectedIndex === 0) {
      toast.info("project can't be empty ");
    } else if (document.getElementById("unit").value === "") {
      toast.info("unit can't be empty ");
    } else if (document.getElementById("vouchertype").selectedIndex === 0) {
      toast.info("Select Type");
    } else if (
      document.getElementById("vouchertype").options[
        document.getElementById("vouchertype").selectedIndex
      ].value === "Bank Voucher" &&
      (document.getElementById("banks").options[
        document.getElementById("banks").selectedIndex
      ].value === "Select" ||
        document.getElementById("chequeno").value === "")
    ) {
      toast.info("Cheque no and Bank name are Required");
    } else if (
      document.getElementById("vouchertype").options[
        document.getElementById("vouchertype").selectedIndex
      ].value === "Cash Voucher" &&
      document.getElementById("cashs").options[
        document.getElementById("cashs").selectedIndex
      ].value === "Select"
    ) {
      toast.info("Selech Cash Account");
    } else if (document.getElementById("Amount").value === "") {
      toast.info("Amount can't be empty ");
    } else if (document.getElementById("Reference").value === "") {
      toast.info("Reference can't be empty ");
    } else if (document.getElementById("narration").value === "") {
      toast.info(" Narration is mandatory");
    } else {
      let paymentType =
        document.getElementById("vouchertype").options[
          document.getElementById("vouchertype").selectedIndex
        ].value;
      if (paymentType === "Bank Voucher") {
        paymentType = "Bank";
      } else {
        paymentType = "Cash";
      }
      let bankId =
        document.getElementById("banks").options[
          document.getElementById("banks").selectedIndex
        ].value;
      if (bankId === "Select") {
        bankId = null;
      } else {
        bankId =
          document.getElementById("banks").options[
            document.getElementById("banks").selectedIndex
          ].dataset.id;
      }
      let cashId =
        document.getElementById("cashs").options[
          document.getElementById("cashs").selectedIndex
        ].value;
      if (cashId === "Select") {
        cashId = 0;
      } else {
        cashId =
          document.getElementById("cashs").options[
            document.getElementById("cashs").selectedIndex
          ].dataset.id;
      }
      let checkNumber;

      if (paymentType === "Cash") {
        checkNumber = null;
      } else {
        checkNumber = document.getElementById("chequeno").value;
      }

      let Voucher = {
        InstalmentReceiptVoucherId: document.getElementById("voucherno").value,
        EntryDate: document.getElementById("Date").value,
        // ProjectId: parseInt(projectlist.ProjectId),
        ProjectId:
          document.getElementById("project").options[
            document.getElementById("project").selectedIndex
          ].dataset.id,

        Narration: document.getElementById("narration").value,
        Reference: document.getElementById("Reference").value,
        // UnitId: parseInt(projectlist.UnitId),
        Unit: document.getElementById("unit").value,

        CustomerName: document.getElementById("Customers").value,
        // InstalmentPaymentVoucherId: document.getElementById("PaymentVoucher").options[
        //     document.getElementById("PaymentVoucher").selectedIndex
        // ].dataset.id,

        ReceivedAmount: parseFloat(
          document.getElementById("Amount").value.replace(/,/g, "")
        ),
        BankAccountId:
          paymentType === "Cash" ? parseInt(cashId) : parseInt(bankId),
        PaymentType: paymentType,
        ChequeNo: checkNumber,
        NewOrOldReceipt: isreceived === false ? "New" : "Old",
        InstalmentPaymentVoucherId:
          isreceived === true ? InstalmentPaymentVoucherId : null,
      };
      document.getElementById("receiptsave").disabled = true;
      axios({
        method: "post",
        url: `${window.$url}Vouchers/InstalmentReceipt/Add`,
        data: JSON.stringify({ Voucher }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("receiptsave").disabled = false;
          if (result.data.IsSuccess === true) {
            // document.getElementById("PaymentVoucher").selectedIndex = 0;
            document.getElementById("Customers").value = "";
            document.getElementById("Reference").value = "";
            document.getElementById("banks").selectedIndex = 0;
            document.getElementById("project").selectedIndex = 0;

            document.getElementById("unit").value = "";
            document.getElementById("chequeno").value = "";
            document.getElementById("vouchertype").selectedIndex = 0;
            document.getElementById("Date").value = "";
            document.getElementById("Amount").value = "";
            document.getElementById("narration").value = "";
            setpaymentInstall([]);
            setprojectlist({});
            // dispatch(getallreceiptinstall());

            toast.info(result.data.Message);
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          document.getElementById("receiptsave").disabled = false;
        }
      );
    }
  };
  useEffect(() => {
    // dispatch(getallreceiptinstall());
    dispatch(getallbanks());
    dispatch(getallProject());
    dispatch(getallcash());
    // if (isreceived === true) {
    //     dispatch(getallpaymentinstalltable());
    // }
    dispatch(getallpaymentinstalltable());
  }, [dispatch]);
  useEffect(() => {
    if (document.getElementById("PaymentVoucher") !== null) {
      if (document.getElementById("PaymentVoucher").selectedIndex !== 0) {
        document.getElementById("Amount").value = parseFloat(
          projectlist.Amount - projectlist.ReceivedAmount
        ).toLocaleString();

        document.getElementById("section").value =
          projectlist.Section.SectionName;
        document.getElementById("project").value =
          projectlist.Project.ProjectName;
        document.getElementById("unit").value = projectlist.Unit.UnitName;
      }
    }
  }, [projectlist]);

  return (
    <>
      <div className="row ">
        <div className="col-md-12 text-center">
          Already Paid{" "}
          <input
            type="checkbox"
            onChange={(e) => {
              if (e.target.checked === true) {
                setisreceived(true);
              } else {
                setisreceived(false);
              }
            }}
          ></input>
        </div>
      </div>

      <div className="row ">
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0 ">Date:</label>
        </div>
        <div className="col-md-3 mt-4 p-0">
          <input
            type="date"
            className="input_date_styling  ml-md-3"
            id="Date"
            defaultValue={moment().format("YYYY-MM-DD")}
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-4  p-0"></div>

        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Project:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          {/* <input type="text" id="project" data-idx={projectlist.ProjectId} disabled className="input_styling ml-md-3 " ></input> */}
          {/* defaultValue={projectlist.Project !== undefined ? projectlist.Project.ProjectName : null}  */}

          <select id="project" className="input_styling  ml-md-3">
            <option>Select</option>
            {resultsproject.data.map((val, index) =>
              parseInt(`${localStorage.getItem("ProjectId")}`) ===
              parseInt(val.ProjectId) ? (
                <option key={index} data-id={val.ProjectId}>
                  {val.ProjectName}
                </option>
              ) : null
            )}
          </select>
          <span className="estaric">*</span>
        </div>
      </div>

      <div className="row ">
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Voucher No:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text "
            disabled
            value={vouchernumber}
            className="input_styling ml-md-3 "
            id="voucherno"
          ></input>
        </div>
        <div className="col-md-2 mt-4  p-0"></div>
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Unit:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          {/* <input type="text" id="unit" className="input_styling ml-md-3 " disabled data-idx={projectlist.UnitId} ></input> */}
          <input
            type="text"
            id="unit"
            list="mylist"
            className="input_styling ml-md-3 "
            onChange={onTextChangeUnit}
          ></input>
          <span className="estaric">*</span>
          <span id="unitdemo"></span>
          <datalist id="mylist">
            {resultspaymenttable?.data?.map((x, idx) =>
              isreceived ? (
                x.Received === false ? (
                  x.Unit !== null ? (
                    <option index={idx} value={x.Unit}>
                      {x.CustomerName}
                    </option>
                  ) : null
                ) : null
              ) : null
            )}
          </datalist>
          {/* defaultValue={projectlist.Unit !== undefined ? projectlist.Unit.UnitName : null} */}
          {/* <select id="unit" className="input_styling  ml-md-3" >
                          <option>Select</option>
                          {unitlist !== undefined && unitlist.Units !== undefined ? unitlist.Units.map((x, index) => <option data-id={x.UnitId} key={index}>{x.UnitName}</option>) : null
  
                          }
  
                      </select> */}
        </div>
      </div>

      <div className="row ">
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Customer:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text "
            className="input_styling ml-md-3 "
            id="Customers"
          ></input>
          <span className="estaric">*</span>

          {/* <select id="Customers" className="input_styling  ml-md-3" onChange={() => {
                          if (resultsreceiptpopulate.data !== null) { setpaymentInstall(resultsreceiptpopulate.data[document.getElementById("Customers").options[document.getElementById("Customers").selectedIndex].dataset.idx]); document.getElementById("PaymentVoucher").selectedIndex = 0 } else { setpaymentInstall([]); document.getElementById("PaymentVoucher").selectedIndex = 0 } if (paymentInstallment !== undefined && paymentInstallment.InstalmentPaymentVoucher !== undefined) { setprojectlist(paymentInstallment.InstalmentPaymentVoucher[document.getElementById("PaymentVoucher").options[document.getElementById("PaymentVoucher").selectedIndex].dataset.idx]) } else { setprojectlist({}); } if (document.getElementById("PaymentVoucher").selectedIndex === 0) {
                              document.getElementById("section").value = "";
                              document.getElementById("project").value = "";
                              document.getElementById("unit").value = "";
                          }
                      }} >
                          <option data-idx={0}>Select</option>
                          {resultsreceiptpopulate.data.map((x, index) => (<option data-id={x.CustomerCnic} data-idx={index}>{x.CustomerName}</option>))
  
                          }
  
                      </select> */}
        </div>

        <div className="col-md-2 mt-4  p-0"></div>
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Amount:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="Amount"
            onChange={(e) => {
              if (e.target.value.length > 0)
                document.getElementById("Amount").value = parseInt(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
            }}
          ></input>
          <span className="estaric">*</span>
        </div>
        {/* <div className="col-md-2 mt-4 text-left p-0">
                      <label className="input_label m-0">Section:</label>
                  </div>
                  <div className="col-md-3 mt-4  p-0">
                      <input type="text" id="section" data-idx={projectlist.SectionId} disabled className="input_styling ml-md-3 " ></input>
                     
  
                  </div> */}
      </div>
      <div className="row ">
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0 ">Payment Type:</label>
        </div>
        <div className="col-md-3 mt-4 p-0">
          <select
            id="vouchertype"
            onChange={(e) => {
              if (e.target.value === "Bank Voucher") {
                document.getElementById("bankdiv").style.display = "block";
                document.getElementById("cashdiv").style.display = "none";
              } else if (e.target.value === "Cash Voucher") {
                document.getElementById("bankdiv").style.display = "none";
                document.getElementById("cashdiv").style.display = "block";
              } else {
                document.getElementById("bankdiv").style.display = "none";
                document.getElementById("cashdiv").style.display = "none";
              }
            }}
            type="text"
            className="input_styling ml-md-3 "
          >
            <option>Select</option>
            <option selected>Cash Voucher</option>
            <option>Bank Voucher</option>
          </select>
          <span className="estaric">*</span>
        </div>

        <div className="col-md-2 mt-4  p-0"></div>
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Reference:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text "
            className="input_styling ml-md-3 "
            id="Reference"
          ></input>
          <span className="estaric">*</span>
        </div>

        {/* {isreceived === true ? <>
                      <div className="col-md-2 mt-4 text-left p-0">
                          <label className="input_label m-0">Payment Voucher:</label>
                      </div>
                      <div className="col-md-3 mt-4  p-0">
  
                          <select id="PaymentVoucher" className="input_styling  ml-md-3" onChange={() => {
                              if (paymentInstallment !== undefined && paymentInstallment.InstalmentPaymentVoucher !== undefined) { setprojectlist(paymentInstallment.InstalmentPaymentVoucher[document.getElementById("PaymentVoucher").options[document.getElementById("PaymentVoucher").selectedIndex].dataset.idx]) } else { setprojectlist({}); } if (document.getElementById("PaymentVoucher").selectedIndex === 0) {
                                  document.getElementById("section").value = "";
                                  document.getElementById("project").value = "";
                                  document.getElementById("unit").value = "";
                              }
                          }}>
                              <option data-idx={0}>Select</option>
                              {paymentInstallment.InstalmentPaymentVoucher !== undefined && paymentInstallment !== undefined ? paymentInstallment.InstalmentPaymentVoucher.map((x, index) => <option data-id={x.InstalmentPaymentVoucherId} data-idx={index} key={index}>{x.InstalmentPaymentVoucherId}</option>) : null
  
                              }
  
                          </select>
  
                      </div></> : null} */}
      </div>

      <div className="row ">
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Narration:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <textarea className="input_address ml-md-3" id="narration"></textarea>
          <span className="estaric">*</span>
        </div>

        <div className="col-md-2 mt-4  p-0"></div>
      </div>
      <span id="cashdiv" style={{ display: "none" }}>
        <div className="row  ">
          <div className="col-md-2 mt-4 text-left p-0">
            <label className="input_label m-0 pl-md-4">Cash:</label>
          </div>
          <div className="col-md-3 mt-4 p-0 ">
            <select id="cashs" className="input_styling  ml-md-3 ">
              <option>Select</option>
              {resultsallcash.data.map((x) => (
                <option selected data-id={x.AccountId}>
                  {x.AccountTitle}
                </option>
              ))}
            </select>
            <span className="estaric">*</span>
          </div>
        </div>
      </span>

      <span id="bankdiv" style={{ display: "none" }}>
        <div className="row  ">
          <div className="col-md-2 mt-4 text-left p-0">
            <label className="input_label m-0">Bank:</label>
          </div>
          <div className="col-md-3 mt-4  p-0">
            <select id="banks" className="input_styling  ml-md-3">
              <option>Select</option>
              {resultsallbanks.data.map((x) => (
                <option data-id={x.AccountId}>{x.AccountTitle}</option>
              ))}
            </select>
            <span className="estaric">*</span>
          </div>

          <div className="col-md-2 mt-4  p-0"></div>

          <div className="col-md-2 mt-4 text-left p-0">
            <label className="input_label m-0">Cheque No:</label>
          </div>
          <div className="col-md-3 mt-4  p-0">
            <input
              id="chequeno"
              type="text"
              placeholder="Cheque No"
              className="input_styling ml-md-3"
            ></input>
            <span className="estaric">*</span>
          </div>
        </div>
      </span>

      <div className=" float-right mt-md-4 mr-md-3">
        <span>
          <button
            className="save_btn ml-2  "
            onClick={() => {
              setshowDetailModal(true);
            }}
          >
            <BsFillInfoSquareFill size="1em" className="mr-1" />
            Detail
          </button>
        </span>
        <span>
          <button
            className="cancel_btn ml-2  "
            onClick={props.cancelreceipttable}
          >
            Cancel
          </button>
        </span>

        <span>
          <button
            className="save_btn ml-2 "
            id="receiptsave"
            onClick={savedata}
          >
            <BsCloudUpload size="1.3em" />
            Save
          </button>
        </span>
      </div>

      {showDetailModal ? (
        <ScreenInfoModal
          setshowModal={setshowDetailModal}
          screenName="General Payment"
        />
      ) : null}
    </>
  );
};

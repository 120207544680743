import axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react'
import { BsCloudUpload } from 'react-icons/bs'
import { toast } from 'react-toastify';

export default function RectifiyModal({ setshowModal, addedById, entryType, entryId }) {
    const [description, setdescription] = useState("");
    const [disabledBtn, setdisabledBtn] = useState(false);
    const AddRevertBack = () => {
        if (description === "") {
            toast.info("Please Enter Description")
            return
        }
        setdisabledBtn(true);
        axios({
            method: "post",
            url: `${window.$url}RevertBack/Add`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
            data: {
                RevertOnDate: moment().format("YYYY-MM-DD"),
                EntryId: entryId,
                EntryType: entryType,
                Description: description,
                RevertBackToId: addedById,
                ReferById: localStorage.getItem("LoginId"),
                ProjectId: localStorage.getItem("ProjectId")
            }
        }).then((res) => {
            setdisabledBtn(false);
            if (res.data.IsSuccess === true) {
                setshowModal(false)
                toast.info(res.data.Message);

            } else {
                toast.info(res.data.Message);
            }
        }).catch((err) => {
            setdisabledBtn(false);
        })

    }
    return (
        <div style={{ display: "block" }} class="modal">

            <div class="revertModal">
                <div className="row">
                    <div className="col-12  page_heading">
                        <h4 className="  text-center pt-2">Revert Back Entry</h4>
                        <span
                            class="close pr-2  "
                            onClick={() => setshowModal(false)
                            }
                        >
                            &times;
                        </span>

                    </div>
                </div>
                <div className="row p-2">
                    <div className="col-md-12  pt-2">
                        <label className="input_label m-0">Description:</label>
                    </div>
                    <div className="col-md-12">

                        <textarea className='input_address' onChange={(e) => {
                            setdescription(e.target.value);
                        }} placeholder='Enter Revert Back Issues' />


                    </div>

                </div>
                <div className=" float-right  mr-4 mb-3">
                    <span>
                        <button disabled={disabledBtn} onClick={AddRevertBack} className="save_btn ml-2  " >

                            <BsCloudUpload size="1.3em" />
                            Save
                        </button>
                    </span>
                </div>


            </div>
        </div>
    )
}


import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BsCloudUpload } from 'react-icons/bs';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getSubmittedVouchers } from '../../../actions/vouchersactions/GetSubmittedVouchers';
function SubmittedVouchers() {
    const [apiLoading, setapiloading] = useState(false);
    const dispatch = useDispatch();
    const [allData, setallData] = useState([]);
    const VouchersData = useSelector((state) => state.getAllSubmittedVouchers);

    useEffect(() => {
        dispatch(getSubmittedVouchers());
    }, [dispatch]);
    useEffect(() => {
        if (VouchersData?.data?.Vouchers) {
            setallData(JSON.parse(JSON.stringify(VouchersData?.data?.Vouchers)));
        }

    }, [VouchersData]);
    const onTextChange = (e) => {

        if (document.getElementById("accountfilter").value === "" &&
            document.getElementById("voucherfilter").value === ""
        ) {
            setallData(VouchersData?.data?.Vouchers);

        } else {
            let suggestions = [];
            {
                const regex = new RegExp(document.getElementById("accountfilter").value, "i");
                const regex1 = new RegExp(document.getElementById("voucherfilter").value, "i");





                suggestions = VouchersData?.data?.Vouchers
                    .sort()
                    .filter((val) =>
                        (document.getElementById("accountfilter").value === "" || regex.test(val.VoucherName)) &&
                        (document.getElementById("voucherfilter").value === "" || regex1.test(val.VoucherId)) 





                    );

            }


            setallData(suggestions);
        }
    };

    return (
        <div className="row">
            <div className="dash_background col-12 p-0">
                <div className="row ">
                    <div className="col-md-12">
                        <>

                            <div className="row pt-3">
                                <div className="col-md-1 pr-0 pl-0">


                                    <label className="input_label m-0">Search:</label>
                                </div>
                                {/* <div className="col-md-3 pr-0 pl-0">

                                    <input type="text " className="input_styling " id="datefilter" onChange={onTextChange} placeholder="User"></input>

                                </div> */}
                                <div className="col-md-3 pr-0 pl-0">


                                    <input type="text " className="input_styling " id="voucherfilter" onChange={onTextChange} placeholder="Voucher No"></input>
                                </div>
                                <div className="col-md-3 pr-0 pl-0">

                                    <input type="text " className="input_styling " id="accountfilter" onChange={onTextChange} placeholder="Type"></input>

                                </div>

                                <div className="col-md-1 pr-0 pl-0">
                                    {VouchersData?.loading ? <Loader type="ThreeDots"
                                        color="green"
                                        height={40}
                                        width={40} /> : null}
                                </div>
                          


                            </div>
                            {/* <div className="row pt-3 ">
                                <div className="col-md-1 pr-0 pl-0">



                                </div>
 
                                <div className="col-md-3   pr-0 pl-0">
                                    <label className="input_label m-0">From:</label>
                                    <input type="date" className="input_date ml-3" id="fromdate"></input>
                                </div>
                                <div className="col-md-3  pr-0 pl-0">
                                    <label className="input_label m-0">To:</label>
                                    <input type="date" className="input_date ml-3" id="todate"></input>
                                </div>

                                <div className="col-md-1 pr-0 pl-0">
                                    <button className="btn_Go"
                                    //  onClick={ongo}
                                    >Go</button>
                                </div>
                                <div className="col-md-1 pr-0 pl-0">


                                    {apiLoading && <Loader type="ThreeDots"
                                        color="green"
                                        height={40}
                                        width={40} />}
                                </div>
                            </div> */}


                            <div className="table-responsive mt-2">
                                <table className="table table-borderless m-0">
                                    <thead>
                                        <tr >
                                            <th>Sr</th>
                                            <th>Date</th>
                                            <th>Voucher No.</th>
                                            <th>Type</th>
                                            <th>Amount</th>
                                            <th>Narration</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allData?.map((itm, idx) => {
                                            return <tr key={idx}>
                                                <td className="table_data" >{idx + 1}</td>
                                                <td className="table_data">{moment(itm.VoucherDate).format("DD-MMM-YYYY")}</td>
                                                <td className="table_data">{itm.VoucherId}</td>
                                                <td className="table_data" >{itm.VoucherName}</td>
                                                <td  className="table_data">{itm.Amount?.toLocaleString()}</td>

                     
                                                <td  className="table_data">{itm.Narration}</td>

                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>

                        </>
                    </div>
                    <div className="col-md-12">

                    </div>
                </div>
            </div>
        </div >

    )
}

export default SubmittedVouchers

import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { GrPrint } from "react-icons/gr";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getallreceiptinstalltable } from "../../../../actions/vouchersactions/getreceiptinstallment";
import InstallmentRecPrint from "../../../accpayablepages/vouchersprints/installmentrecprint";
import { CSVLink } from "react-csv";


export const ReceiptInstallmentsTable = (props) => {
    const resultsreceipttable = useSelector(
      (state) => state.getReceiptInstallTable
    );
    const [printData, SetPrintData] = useState();
    const [filterunits, setfilterunits] = useState();
    const [statetoggle, settoggle] = useState(true);
    // print function
    const componentRef = useRef();
    const rolesdata = useSelector((state) => state.GetAllRole);

    const printPaymentRec = useReactToPrint({
      content: () => componentRef.current,
    });
    useEffect(() => {
      if (printData !== undefined) {
        printPaymentRec();
      }
    }, [printData, statetoggle]);
    //Table Data fiter
  
    var suggestionlist = [];
  
    // const onTextChange = (e) => {
    //     const value = e.target.value;
    //     if (value === "") {
  
    //         dispatch(getallreceiptinstalltable());
    //     } else {
    //         let suggestions = [];
    //         if (value.length > 0) {
    //             const regex = new RegExp(`${value}`, "i");
  
    //             suggestions = resultsreceipttable.data
    //                 .sort()
    //                 .filter((val) =>
    //                     (regex.test(val.InstalmentReceiptVoucherId) || regex.test(val.CustomerCnicNavigation !== undefined && val.CustomerCnicNavigation !== null ? val.CustomerCnicNavigation.CustomerName : null)));
  
    //         }
    //         suggestionlist = suggestions;
    //         dispatch(GetAllReceiptInstallTableData(suggestionlist));
    //     }
    // };
    const onTextChange = (e) => {
      if (
        document.getElementById("projectfilter").value === "" &&
        document.getElementById("unitfilter").value === "" &&
        document.getElementById("referencefilter").value === "" &&
        document.getElementById("statusfilter").value === ""
      ) {
        setfilterunits(resultsreceipttable.data);
      } else {
        let suggestions = [];
        {
          const regex = new RegExp(
            document.getElementById("projectfilter").value,
            "i"
          );
          const regex1 = new RegExp(
            document.getElementById("referencefilter").value,
            "i"
          );
          const regex2 = new RegExp(
            document.getElementById("unitfilter").value,
            "i"
          );
          const regex3 = new RegExp(
            document.getElementById("statusfilter").value,
            "i"
          );
  
          suggestions = resultsreceipttable.data
            .sort()
            .filter(
              (val) =>
                (document.getElementById("projectfilter").value === "" ||
                  regex.test(val.Project.ProjectName)) &&
                (document.getElementById("referencefilter").value === "" ||
                  regex1.test(val.Reference)) &&
                (document.getElementById("unitfilter").value === "" ||
                  regex2.test(val.Unit)) &&
                (document.getElementById("statusfilter").value === "" ||
                  regex3.test(val.Paid !== false ? "Paid" : "Pending"))
            );
        }
        setfilterunits(suggestions);
      }
    };
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(getallreceiptinstalltable());
    }, [dispatch]);
    useEffect(() => {
      setfilterunits(resultsreceipttable.data);
    }, [resultsreceipttable]);

    const [CsvData, setCsvData] = useState([]);
    useEffect(() => {
      let arr = [];

      filterunits?.forEach((val, index) => {
        let year = val.EntryDate.slice(0, 4);
        let day = val.EntryDate.slice(8, 10);
        let month = val.EntryDate.slice(5, 7);
        arr.push({
          Sr: index + 1,
          Date: day + "-" + month + "-" + year,
          "Rec No.": val.InstalmentReceiptVoucherId,
          Customer:
            val.CustomerName !== undefined && val.CustomerName !== null
              ? val.CustomerName
              : "",
          Reference: val.Reference,
          // "Pay No." : val.InstalmentPaymentVoucherId,
          // Project:
          //   val.Project !== undefined && val.Project !== null
          //     ? val.Project.ProjectName
          //     : "",
          Unit: val.Unit !== undefined && val.Unit !== null ? (val.Unit) : (''),
          "Rec Amount": parseFloat(val.ReceivedAmount).toLocaleString(),
          Status: val.Paid !== false ? "Paid" : "Pending",
        });
      });
      setCsvData(arr);
    }, [filterunits]);

    return (
      <>
        <div hidden>
          <InstallmentRecPrint ref={componentRef} printData={printData} />
        </div>
        {/* <div className="row pt-3 ">
                  <div className="col-md-1 pr-0 pl-0">
  
  
                      <label className="input_label m-0">Search:</label>
                  </div>
                  <div className="col-md-3 pr-0 pl-0">
  
  
                      <input type="text " className="input_styling " onChange={onTextChange} placeholder=" Voucher/Customer"></input><span className="estaric">*</span>
                  </div>
                 <div className="col-md-3 text-center  pr-0 pl-0">
                      <label className="input_label m-0">From:</label>
                      <input type="date" className="input_date ml-3" id="from date"></input>
                  </div>
                  <div className="col-md-3 text-center pr-0 pl-0">
                      <label className="input_label m-0">To:</label>
                      <input type="date" className="input_date ml-3" id="todate"></input>
                  </div>
                  <div className="col-md-1 pr-0 pl-0">
                      <button className="btn_Go">Go</button>
                  </div>
                  <div className="col-md-1 pr-0 pl-0">
                      {resultsreceipttable.loading ? <Loader type="ThreeDots"
                          color="green"
                          height={40}
                          width={40} /> : null}
                  </div>
              </div>
  
              */}
        <div className="row pt-3 ">
          <div className="col-md-1 pr-0 pl-0">
            <label className="input_label m-0">Search:</label>
          </div>
          <div className="col-md-2 pr-0 pl-0">
            <input
              type="text "
              className="input_styling "
              id="projectfilter"
              onChange={onTextChange}
              placeholder="Project "
            ></input>
          </div>
          <div className="col-md-2 pr-0 pl-0">
            <input
              type="text "
              className="input_styling "
              id="unitfilter"
              onChange={onTextChange}
              placeholder="Unit "
            ></input>
          </div>
          <div className="col-md-2 pr-0 pl-0">
            <input
              type="text "
              className="input_styling "
              id="referencefilter"
              onChange={onTextChange}
              placeholder="Reference"
            ></input>
          </div>
          <div className="col-md-2 pr-0 pl-0">
            <input
              type="text "
              className="input_styling "
              id="statusfilter"
              onChange={onTextChange}
              placeholder="Status "
            ></input>
          </div>
  
          <div className="col-md-1 pr-0 pl-0">
            {resultsreceipttable.loading ? (
              <Loader type="ThreeDots" color="green" height={40} width={40} />
            ) : null}
          </div>
        </div>
  
        <div className="table-responsive mt-2">
          <table className="table table-borderless m-0">
            <thead>
              <tr>
                <th>Sr</th>
                <th>Date</th>
                <th>Rec No.</th>
                <th>Customer </th>
                <th>Reference</th>
                {/*<th>Pay No.</th> */}
                {/* <th>Project </th> */}
                <th>Unit</th>
                <th>Rec Amount</th>
                <th>Status</th>
                <th className="text-center">
                {
                  rolesdata?.data?.Access?.includes("C")?
                  <button
                    className="save_btn"
                    onClick={() => {
                      props.showreceipttable();
                    }}
                  >
                    New+
                  </button>:null}
                </th>
              </tr>
            </thead>
            <tbody>
              {filterunits !== undefined
                ? filterunits.map((val, index) => {
                  let year = val.EntryDate.slice(0, 4);
                  let day = val.EntryDate.slice(8, 10);
                  let month = val.EntryDate.slice(5, 7);
                  switch (month) {
                    case "01":
                      month = "Jan";
  
                      break;
                    case "02":
                      month = "Feb";
  
                      break;
                    case "03":
                      month = "Mar";
  
                      break;
                    case "04":
                      month = "Apr";
  
                      break;
                    case "05":
                      month = "May";
  
                      break;
                    case "06":
                      month = "Jun";
  
                      break;
                    case "07":
                      month = "Jul";
  
                      break;
                    case "08":
                      month = "Aug";
  
                      break;
                    case "09":
                      month = "Sep";
  
                      break;
                    case "10":
                      month = "Oct";
                      break;
                    case "11":
                      month = "Nov";
                      break;
                    case "12":
                      month = "Dec";
                      break;
                    default:
                      break;
                  }
                return <tr key={index}>
                      <td
                       className="table_data"
                      >{index + 1}</td>
                      <td
                       className="table_data"
                      >{day + "-" + month + "-" + year}</td>
                        <td
                       className="table_data"
                      >{val.InstalmentReceiptVoucherId}</td>
                      {/*  <td>{val.InstalmentPaymentVoucherId}</td> */}
  
                      {/* {val.Project !== undefined && val.Project !== null ? (
                        <td
                        className="table_data"
                        >{val.Project.ProjectName}</td>
                      ) : (
                        <td></td>
                      )} */}
                      {val.CustomerName !== undefined &&
                      val.CustomerName !== null ? (
                        <td  className="table_data"
                        >{val.CustomerName}</td>
                      ) : (
                        <td></td>
                      )}
                       <td  className="table_data">{val.Reference}</td>
                    
                      {val.Unit !== undefined && val.Unit !== null ? (
                        <td
                        className="table_data"
                        >{val.Unit}</td>
                      ) : (
                        <td></td>
                      )}
  
                      <td  className="table_data">{parseFloat(val.ReceivedAmount).toLocaleString()}</td>
                      <td  className="table_data">{val.Paid !== false ? "Paid" : "Pending"}</td>
  
                      <td
                        className="text-center cur_sor"
                        onClick={() => {
                          SetPrintData(val);
                          settoggle(!statetoggle);
                        }}
                      >
                        <GrPrint size="1.3em" />
                      </td>
                    </tr>
                })
                : null}
            </tbody>
          </table>
        </div>
        <button
          style={{ color: "black !important" }}
          className="print_btn ml-2 float-left"
        >
          <CSVLink
            style={{ color: "black" }}
            filename="Receipt_Installment_Csv"
            data={CsvData}
          >
            Download CSV
          </CSVLink>
        </button>
      </>
    );
  };
import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { BsCloudUpload, BsFillInfoSquareFill } from "react-icons/bs";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getbalancesheet } from "../../../actions/AccountReports/balancesheet";
import { getprofitloss } from "../../../actions/AccountReports/profitandloss";
import { getrecoveryreport } from "../../../actions/AccountReports/recoveryreport";
import { getallTaxTable } from "../../../actions/accountsactions/addtax";
import { getallbanks, getallcash } from "../../../actions/getbanks";
import { getallProject } from "../../../actions/getprojects";
import { getallcommissionreceipt } from "../../../actions/vouchersactions/getcomreceipt";
import ScreenInfoModal from "../../../ScreenInfoModal/ScreenInfoModal";
import TaxModal from "../../TaxModal/TaxModal";

export const CommissionReceiptForm = (props) => {
  let vouchernumber = `CR-${Math.floor(Date.now())}`;
  const CommissionPaymentData = useSelector((state) => state.getComReceipt);
  const resultsproject = useSelector((state) => state.getProject);
  const resultsallbanks = useSelector((state) => state.getAllBanksReducer);
  const resultsallcash = useSelector((state) => state.getAllCash);
  const [showDetailModal, setshowDetailModal] = useState(false);

  const [stateamount, setamount] = useState("0");
  const [taxtotal, settaxtotal] = useState("0");
  const [allvalues, setallvalues] = useState([]);
  const [showTaxmodal, setshowTaxmodal] = useState(false);
  let dispatch = useDispatch();
  const [CommissionPaymentList, setCommissionPaymentList] = useState([]);
  const [UnitReceiptList, setUnitReceiptList] = useState([]);

  const [statecompay, setstatecompay] = useState({});
  const [statecontractid, setstatecontract] = useState();
  const [statetoshowtotal, setstatetoshowtotal] = useState();

  const [projectid, setprojectid] = useState();
  const [sellerid, setsellerid] = useState();
  const [dealid, setdealid] = useState();

  const savedata = () => {
    if (document.getElementById("Date").value === "") {
      toast.info("Date is Required");
    } else if (document.getElementById("project").value === "") {
      toast.info("Select Project");
    } else if (document.getElementById("manager").value === "") {
      toast.info("Select Manager");
    } else if (document.getElementById("deal").value === "") {
      toast.info("Select deal");
    } else if (document.getElementById("unit").value === "") {
      toast.info("Select Unit");
    } else if (document.getElementById("narration").value === "") {
      toast.info("Narration can't be empty");
    } else if (document.getElementById("vouchertype").selectedIndex === 0) {
      toast.info("Select Type");
    } else if (
      document.getElementById("vouchertype").options[
        document.getElementById("vouchertype").selectedIndex
      ].value === "Bank Voucher" &&
      (document.getElementById("banks").options[
        document.getElementById("banks").selectedIndex
      ].value === "Select" ||
        document.getElementById("chequeno").value === "")
    ) {
      toast.info("Cheque no and Bank name are Required");
    } else if (
      document.getElementById("vouchertype").options[
        document.getElementById("vouchertype").selectedIndex
      ].value === "Cash Voucher" &&
      document.getElementById("cashs").options[
        document.getElementById("cashs").selectedIndex
      ].value === "Select"
    ) {
      toast.info("Selech Cash Account");
    } else if (document.getElementById("total").value === "") {
      toast.info("Total can't be empty ");
    }
    // else if (parseFloat(document.getElementById("total").value.replace(/,/g, '')) > parseFloat(statecompay.DealAmount) - parseFloat(statecompay.AmountPaid)) {
    //     toast.info(`Amount should be less than ${statecompay !== undefined ? parseFloat(statecompay.DealAmount) - parseFloat(statecompay.AmountPaid) : null}`);
    // }
    else {
      let paymentType =
        document.getElementById("vouchertype").options[
          document.getElementById("vouchertype").selectedIndex
        ].value;
      if (paymentType === "Bank Voucher") {
        paymentType = "Bank";
      } else {
        paymentType = "Cash";
      }
      let bankId =
        document.getElementById("banks").options[
          document.getElementById("banks").selectedIndex
        ].value;
      if (bankId === "Select") {
        bankId = null;
      } else {
        bankId =
          document.getElementById("banks").options[
            document.getElementById("banks").selectedIndex
          ].dataset.id;
      }
      let cashId =
        document.getElementById("cashs").options[
          document.getElementById("cashs").selectedIndex
        ].value;
      if (cashId === "Select") {
        cashId = 0;
      } else {
        cashId =
          document.getElementById("cashs").options[
            document.getElementById("cashs").selectedIndex
          ].dataset.id;
      }
      let checkNumber;

      if (paymentType === "Cash") {
        checkNumber = null;
      } else {
        checkNumber = document.getElementById("chequeno").value;
      }

      // let recoveryBookingId = 0;

      let receiptContractId = 0;
      // recoveryBookingId = statecompay.RecoveryBookingVoucherId;
      receiptContractId = statecompay.ContractId;
      // //
      // "CommissionReceiptVoucherId":"CRV-001",
      // "EntryDate":"02-01-2021",
      // "ProjectId":8,
      // "SellerId":4,
      // "UnitId":9,
      // "DealBoughtId":3,
      // "PaymentType":"Cash",
      // "Amount":1000,
      // "Narration":"First Commission Receipt Voucher",
      // "InstalmentPaymentVoucherId":"IPV-001",
      // "ContractId":16
      //  "BankAccountId":1,
      //  "ChequeNo":"abce

      let TaxList = [];

      allvalues?.map((val, i) => {
        if (val.Checked == true) {
          TaxList.push({
            TaxId: Number(val.TaxId),
            TaxPer: Number(val.TaxPer),
            // TaxAmount: val.TaxAmount,
            // Checked: true,
            // TaxSubmitedDetailId: val.TaxSubmitedDetailId
            //   ? val.TaxSubmitedDetailId
            //   : 0,
            // ProjectId: localStorage.getItem("ProjectId"),
            // ReferenceId: "",
            // ReferenceType: "",
          });
        }
      });
      let Voucher = {
        EntryDate: document.getElementById("Date").value,
        BankAccountId:
          paymentType === "Cash" ? parseInt(cashId) : parseInt(bankId),
        PaymentType: paymentType,
        ChequeNo: checkNumber,
        Narration: document.getElementById("narration").value,

        ContractId: statecontractid,
        SellerId: parseInt(sellerid),

        Amount: parseFloat(
          document.getElementById("total").value.replace(/,/g, "")
        ),
        ProjectId: parseInt(projectid),
        UnitId: parseInt(unitid),
        DealBoughtId: parseInt(dealid),
        // ReceivedAmount: parseFloat(document.getElementById("total").value),
        CommissionReceiptVoucherId: document.getElementById("Voucher").value,
        // RecoveryBookingId: recoveryBookingId,
      };
      document.getElementById("recoverysave").disabled = true;
      axios({
        method: "post",
        url: `${window.$url}Vouchers/CommissionReceipt/Add`,
        data: JSON.stringify({ Voucher, TaxList }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("recoverysave").disabled = false;
          if (result.data.IsSuccess === true) {
            setprojectid();
            setsellerid();
            setdealid();
            setunitid();
            document.getElementById("project").value = "";
            document.getElementById("deal").value = "";
            document.getElementById("unit").value = "";
            document.getElementById("manager").value = "";
            document.getElementById("banks").selectedIndex = 0;
            document.getElementById("chequeno").value = "";
            document.getElementById("vouchertype").selectedIndex = 0;

            document.getElementById("total").value = "";
            document.getElementById("narration").value = "";

            dispatch(getallcommissionreceipt());

            toast.info(result.data.Message);
            dispatch(getrecoveryreport());
            dispatch(getprofitloss());
            dispatch(getbalancesheet());
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Someting went wrong");
          document.getElementById("recoverysave").disabled = false;
        }
      );
      // }
    }
  };
  useEffect(() => {
    // if (statecompay.AmountPaid!==undefined && statecompay.DealAmount !== undefined)

    //     document.getElementById("total").value = parseFloat(statecompay.DealAmount) - parseFloat(statecompay.AmountPaid)
    if (
      statetoshowtotal !== undefined &&
      statetoshowtotal.Contract[0].AdditionalMarginAmount !== undefined &&
      statetoshowtotal.Contract[0].CompanyCommissionAmount !== undefined &&
      statetoshowtotal.Contract[0].CompnayCommissionAndMarginReceived !==
        undefined
    ) {
      document.getElementById("total").value = (
        parseFloat(statetoshowtotal.Contract[0].AdditionalMarginAmount) +
        parseFloat(statetoshowtotal.Contract[0].CompanyCommissionAmount) -
        parseFloat(
          statetoshowtotal.Contract[0].CompnayCommissionAndMarginReceived
        )
      ).toLocaleString();
    }
  }, [statetoshowtotal]);
  useEffect(() => {
    dispatch(getallProject());
    dispatch(getallcommissionreceipt());
    dispatch(getallbanks());
    dispatch(getallcash());
    dispatch(getallTaxTable());
  }, [dispatch]);

  const [unitid, setunitid] = useState();
  const onUnitDatalistChange = () => {
    var inputValue = document.getElementById("unit").value;
    var options = document.getElementById("unitdatalist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        UnitReceiptList?.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
            setunitid(val?.UnitId);
            setstatecontract(val?.Contract[0]?.ContractId);
            setstatetoshowtotal(val);
          }
        });
        break;
      }
    }
  };
  // const onTextChangeProjectDatalist = () => {
  //   var inputValue = document.getElementById("project").value;
  //   var options = document.getElementById("projectdatalist").childNodes;

  //   let val;
  //   for (var i = 0; i < options.length; i++) {
  //     if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
  //       resultsproject?.data?.forEach((x, idx) => {
  //         if (
  //           parseInt(`${localStorage.getItem("ProjectId")}`) ===
  //           parseInt(x.ProjectId)
  //         ) {
  //           if (idx == options[i].attributes.index.value) {
  //             val = x;
  //             setprojectid(val?.ProjectId);
  //           }
  //         }
  //       });
  //       break;
  //     }
  //   }
  // };

  const onTextChangeSellerDatalist = () => {
    var inputValue = document.getElementById("manager").value;
    var options = document.getElementById("managerdatalist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        CommissionPaymentData?.data?.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
            setsellerid(val?.SellerId);
            setCommissionPaymentList(val?.DealBought);
          }
        });
        break;
      }
    }
  };

  const onTextChangeDealDatalist = () => {
    var inputValue = document.getElementById("deal").value;
    var options = document.getElementById("dealdatalist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        CommissionPaymentList?.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
            setdealid(val?.DealBoughtId);
            setstatecompay(val);
            setUnitReceiptList(val?.Units);
          }
        });
        break;
      }
    }
  };
  console.log(resultsproject);
  useEffect(() => {

    resultsproject.data?.map((x) => {
      if (x.ProjectId == localStorage.getItem("ProjectId")) {
        console.log(x);
        setprojectid(x?.ProjectId)
        // setDuration(x.Installementduration);
        // if (x.Units != null) {
        //   setunitlist(x.Units);
        // } else setunitlist([]);
      }
    });
  }, [resultsproject]);
  console.log(projectid);
  return (
    <>
      <div className="row">
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Project:</label>
        </div>
        <div className="col-md-3 mt-4">
          <select 
            className="input_styling ml-md-3 "
            id="project"
            disabled
            autoComplete="off"
            // onChange={onTextChangeProjectDatalist}
          >
          {/* <input
            className="input_styling ml-md-3 "
            id="project"
            list="projectdatalist"
            autoComplete="off"
            onChange={onTextChangeProjectDatalist}
          /> */}
          {/* <datalist id="projectdatalist"> */}
            {resultsproject.data.map((val, index) =>
              parseInt(`${localStorage.getItem("ProjectId")}`) ===
              parseInt(val.ProjectId) ? (
                <option selected index={index} value={val.ProjectId}>
                  {val.ProjectName}
                </option>
              ) : null
            )}
            </select>
          {/* </datalist> */}
          {/* <select className="input_styling ml-md-3 " id="project">
              <option>Select</option>
              {resultsproject.data.map((val, index) =>
                parseInt(`${localStorage.getItem("ProjectId")}`) ===
                parseInt(val.ProjectId) ? (
                  <option key={index} data-id={val.ProjectId}>
                    {val.ProjectName}
                  </option>
                ) : null
              )}
            </select>
            <span className="estaric">*</span> */}
        </div>
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Seller:</label>
        </div>
        <div className="col-md-3 mt-4">
          <input
            className="input_styling ml-md-3 "
            id="manager"
            list="managerdatalist"
            onChange={onTextChangeSellerDatalist}
          />
          <datalist id="managerdatalist">
            {CommissionPaymentData.data.map((val, index) => (
              <option index={index} value={val?.SellerName}>
                {val?.SellerId}
              </option>
            ))}
          </datalist>
          {/* <select
              className="input_styling ml-md-3 "
              id="manager"
              onChange={() => {
                setCommissionPaymentList(
                  CommissionPaymentData.data[
                    document.getElementById("manager").options[
                      document.getElementById("manager").selectedIndex
                    ].dataset.idx
                  ] !== undefined
                    ? CommissionPaymentData.data[
                        document.getElementById("manager").options[
                          document.getElementById("manager").selectedIndex
                        ].dataset.idx
                      ].DealBought
                    : []
                );
              }}
            >
              <option>Select</option>
              {CommissionPaymentData.data.map((val, index) => (
                <option data-idx={index} data-id={val.SellerId}>
                  {val.SellerName !== undefined ? val.SellerName : null}
                </option>
              ))}
            </select> */}
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4 text-left p-0">
          <label className="input_label m-0 pl-md-4">Deal:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <input
            className="input_styling ml-md-3 "
            id="deal"
            list="dealdatalist"
            onChange={onTextChangeDealDatalist}
          />
          <datalist id="dealdatalist">
            {CommissionPaymentList?.map((x, index) => (
              <option index={index} value={x.DealName}>
                {x.DealBoughtId}
              </option>
            ))}
          </datalist>
          {/* <select
              className="input_styling ml-md-3 "
              id="deal"
              onChange={() => {
                setstatecompay(
                  CommissionPaymentList[
                    document.getElementById("deal").options[
                      document.getElementById("deal").selectedIndex
                    ].dataset.idx
                  ]
                );
                setUnitReceiptList(
                  CommissionPaymentList[
                    document.getElementById("deal").options[
                      document.getElementById("deal").selectedIndex
                    ].dataset.idx
                  ] !== undefined
                    ? CommissionPaymentList[
                        document.getElementById("deal").options[
                          document.getElementById("deal").selectedIndex
                        ].dataset.idx
                      ].Units
                    : null
                );
              }}
            >
              <option>Select</option>
              {CommissionPaymentList !== undefined &&
              CommissionPaymentList !== undefined
                ? CommissionPaymentList.map((x, index) => (
                    <option key={index} data-idx={index} data-id={x.DealBoughtId}>
                      {x.DealName}
                    </option>
                  ))
                : null}
            </select> */}
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1 mt-4 text-left p-0">
          <label className="input_label m-0 pl-md-4">Unit:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <input
            type={"text"}
            id="unit"
            className="input_styling ml-md-3"
            list="unitdatalist"
            onChange={onUnitDatalistChange}
          />
          <datalist id="unitdatalist">
            {UnitReceiptList?.map((x, index) => (
              <option key={index} index={index} value={x.UnitName}>
                {x.UnitName}
              </option>
            ))}
          </datalist>

          {/* <select className="input_styling ml-md-3 " id="unit" onChange={() => {
                          setstatecontract(UnitReceiptList[document.getElementById("unit").options[document.getElementById("unit").selectedIndex].dataset.idx].Contract !== undefined ? UnitReceiptList[document.getElementById("unit").options[document.getElementById("unit").selectedIndex].dataset.idx].Contract[0].ContractId : null);
                          setstatetoshowtotal(UnitReceiptList[document.getElementById("unit").options[document.getElementById("unit").selectedIndex].dataset.idx].Contract !== undefined ? UnitReceiptList[document.getElementById("unit").options[document.getElementById("unit").selectedIndex].dataset.idx] : null)
                      }}>
                          <option>Select</option>
                          {UnitReceiptList !== undefined && UnitReceiptList !== undefined ? UnitReceiptList.map((x, index) =>
                              <option key={index} data-idx={index} data-id={x.UnitId}>{x.UnitName}</option>) : null
                          }
                      </select> */}
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Date:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <input
            type="date"
            className="input_styling ml-md-3 "
            defaultValue={moment().format("YYYY-MM-DD")}
            id="Date"
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Voucher No:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <input
            type="text"
            disabled
            value={vouchernumber}
            className="input_styling ml-md-3 "
            id="Voucher"
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4 ">Type:</label>
        </div>
        <div className="col-md-3 mt-4">
          <select
            id="vouchertype"
            onChange={(e) => {
              if (e.target.value === "Bank Voucher") {
                document.getElementById("bankdiv").style.display = "block";
                document.getElementById("cashdiv").style.display = "none";
              } else if (e.target.value === "Cash Voucher") {
                document.getElementById("bankdiv").style.display = "none";
                document.getElementById("cashdiv").style.display = "block";
              } else {
                document.getElementById("bankdiv").style.display = "none";
                document.getElementById("cashdiv").style.display = "none";
              }
            }}
            type="text"
            className="input_styling ml-md-3  "
          >
            <option>Select</option>
            <option selected>Cash Voucher</option>
            <option>Bank Voucher</option>
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Amount:</label>
        </div>
        <div className="col-md-3 mt-4">
          <input
            type="text "
            className="input_styling ml-md-3 "
            id="total"
            onChange={(e) => {
              if (e.target.value.length > 0)
                document.getElementById("total").value = parseInt(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
            }}
          ></input>
          <span className="estaric">*</span>
        </div>

        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Net Amount:</label>
        </div>
        <div className="col-md-3 mt-4">
          <input
            type="text"
            className="input_styling ml-md-3 "
            disabled
            id="taxnet"
            value={parseFloat(
              parseFloat(stateamount.replace(/,/g, "")) - parseFloat(taxtotal)
            ).toLocaleString()}
          ></input>
        </div>
      </div>
      <span id="cashdiv">
        <div className="row  ">
          <div className="col-md-4  p-0"></div>
          <div className="col-md-1 mt-4  p-0">
            <label className="input_label m-0 pl-md-4">Cash:</label>
          </div>
          <div className="col-md-3 mt-4  ">
            <select id="cashs" className="input_styling  ml-md-3">
              <option>Select</option>
              {resultsallcash.data.map((x) => (
                <option selected data-id={x.AccountId}>
                  {x.AccountTitle}
                </option>
              ))}
            </select>
            <span className="estaric">*</span>
          </div>
        </div>
      </span>
      <span id="bankdiv" style={{ display: "none" }}>
        <div className="row ">
          <div className="col-md-4  p-0"></div>
          <div className="col-md-1 mt-4 p-0">
            <label className="input_label m-0 pl-md-4">Bank:</label>
          </div>
          <div className="col-md-3 mt-4 ">
            <select id="banks" className="input_styling  ml-md-3">
              <option>Select</option>
              {resultsallbanks.data.map((x) => (
                <option data-id={x.AccountId}>{x.AccountTitle}</option>
              ))}
            </select>
            <span className="estaric">*</span>
          </div>

          <div className="col-md-1 mt-4 p-0">
            <label className="input_label m-0 pl-md-4">Cheque No:</label>
          </div>
          <div className="col-md-3 mt-4 ">
            <input
              id="chequeno"
              type="number"
              placeholder="Cheque No"
              className="input_styling ml-md-3 "
            ></input>
            <span className="estaric">*</span>
          </div>
        </div>
      </span>
      <div className="row">
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Narration:</label>
        </div>
        <div className="col-md-3 mt-4">
          <textarea className="input_address ml-md-3" id="narration"></textarea>
          <span className="estaric">*</span>
        </div>
      </div>

      <div className=" float-right mt-md-4 mr-md-3">
        <span>
          <button
            className="save_btn ml-2  "
            onClick={() => {
              setshowDetailModal(true);
            }}
          >
            <BsFillInfoSquareFill size="1em" className="mr-1" />
            Detail
          </button>
        </span>
        <span>
          <button
            className="save_btn ml-2  "
            onClick={() => {
              if (document.getElementById("total").value !== "") {
                setamount(document.getElementById("total").value);
                setshowTaxmodal(true);
              }
            }}
          >
            Add Tax
          </button>
        </span>
        <span>
          <button
            className="cancel_btn ml-2  "
            onClick={props.cancelcommissiontable}
          >
            Cancel
          </button>
        </span>

        <span>
          <button
            className="save_btn ml-2 "
            id="recoverysave"
            onClick={savedata}
          >
            <BsCloudUpload size="1.3em" />
            Save
          </button>
        </span>
      </div>
      {/* <div id="mymodal" className="modal">
          <div className="modal-content-cat">
            <div className="row">
              <div className="col-12  page_heading">
                <h4 className="  text-center pt-2">Select Taxes</h4>
                <span class="close pr-2  " onClick={closemodal}>
                  &times;
                </span>
              </div>
            </div>
            <div className="row">
              <div className=" col-12  table_height pr-0 pl-0">
                <div className="m-2 mt-4">
                  <table className="table table-borderless m-0">
                    <thead>
                      <tr>
                        <th>Select</th>
                        <th>Name</th>
  
                        <th>Percentage</th>
                        <th>Tax Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {resultalltaxes.data.map((val, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              id={`advCheck${index}`}
                              type="checkbox"
                              name="check"
                              onChange={(e) => {
                                resultalltaxes.data[index].Checked =
                                  e.target.checked;
  
                                if (e.target.checked == true) {
                                  settaxtotal(
                                    parseFloat(
                                      parseFloat(taxtotal.replace(/,/g, "")) +
                                      parseFloat(
                                        document
                                          .getElementById(`taxtotal${index}`)
                                          .value.replace(/,/g, "")
                                      )
                                    ).toLocaleString()
                                  );
                                } else {
                                  settaxtotal(
                                    parseFloat(
                                      parseFloat(taxtotal.replace(/,/g, "")) -
                                      parseFloat(
                                        document
                                          .getElementById(`taxtotal${index}`)
                                          .value.replace(/,/g, "")
                                      )
                                    ).toLocaleString()
                                  );
                                }
                              }}
                            />
                          </td>
                          <td>{val.TaxName}</td>
  
                          <td>
                            <input
                              type="text"
                              id={`taxper${index}`}
                              defaultValue={val.TaxPer}
                              onChange={(e) => {
                                resultalltaxes.data[index].TaxPer =
                                  e.target.value;
                                document.getElementById(
                                  `taxtotal${index}`
                                ).value = parseFloat(
                                  (e.target.value / 100) *
                                  parseFloat(
                                    document
                                      .getElementById("total")
                                      .value.replace(/,/g, "")
                                  )
                                ).toLocaleString();
                              }}
                            ></input>
                          </td>
  
                          <td>
                            <input
                              type="text"
                              id={`taxtotal${index}`}
                              value={parseFloat(
                                (parseFloat(val.TaxPer) / 100) *
                                parseFloat(stateamount.replace(/,/g, ""))
                              ).toLocaleString()}
                              disabled
                            ></input>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className=" col-11 text-right pt-2 pr-0 pl-0">
                    <label className="input_label m-0"> Total Tax:</label>{" "}
                    <input
                      type="text"
                      value={taxtotal.toLocaleString()}
                      disabled
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      {showDetailModal ? (
        <ScreenInfoModal
          setshowModal={setshowDetailModal}
          screenName="Commission Receipt"
        />
      ) : null}

      {showTaxmodal === true ? (
        <TaxModal
          setshowTaxmodal={setshowTaxmodal}
          stateamount={stateamount}
          taxtotal={taxtotal}
          settaxtotal={settaxtotal}
          vouchData={props.vouchData}
          allvalues={allvalues}
          setallvalues={setallvalues}
        />
      ) : null}
    </>
  );
};

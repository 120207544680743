import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getallcreditors } from "../../../actions/getcreditors";
import { getallProject } from "../../../actions/getprojects";
import { getallmeasurment } from "../../../actions/getunit";

export const LandForm = (props) => {
  const resultsmeasurement = useSelector((state) => state.getAllMeasureUnit);
  const resultsproject = useSelector((state) => state.getProject);
  const resultallcreditors = useSelector((state) => state.getAllCreditors);
  const [checktotal, setchecktotal] = useState(0);
  const [installmentdifference, setinstallmentdifference] = useState(0);

  const [state, setstate] = useState({
    EntryDate: null,
    LandCreditorId: 0,
    ProjectId: 0,
    LandValue: null,
    AdvancePaid: 0,
    Advance: null,
    LandValuePaid: 0,
    FullPaid: false,
    MeasureUnitId: null,
    MeasureValue: null,
    LandName: null,
    PaymentPlan: null,
    SalePaymentPer: null,
    NetMarla: null,
    KhewatNumber: null,
    SqaNumber: null,
  });
  const [installments, setinstallments] = useState([]);

  function addtoarray() {
    if (parseInt(installmentdifference) <= 0) {
      toast.info(
        "Remaining Amount is 0 / less then 0 , you can't create installments"
      );
    } else if (
      parseFloat(
        document.getElementById("InstallmentAmount").value.replace(/,/g, "")
      ) >
      parseFloat(document.getElementById("Landvalue").value.replace(/,/g, "")) -
        parseFloat(
          document.getElementById("totaladvvalue").value.replace(/,/g, "")
        )
    ) {
      toast.info("Installment amount should be less than Land value");
    } else if (document.getElementById("InstallmentAmount").value === "") {
      toast.info("Enter Installment Amount");
    } else if (document.getElementById("DueDate").value === "") {
      toast.info("Date is Required");
    } else {
      let listofinstallments = {
        InstallmentAmount: parseFloat(
          document.getElementById("InstallmentAmount").value.replace(/,/g, "")
        ),
        PaidAmount: 0,
        DueDate: document.getElementById("DueDate").value,
      };

      setinstallments([...installments, listofinstallments]);
      document.getElementById("InstallmentAmount").value = "";
      document.getElementById("DueDate").value = "";
    }
  }
  function addCategory() {
    document.getElementById("catsave").disabled = true;
    axios({
      url: `${window.$url}Projects/AreaMeasureUnits/Add?Unit=Sarsai`,
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
      data: JSON.stringify({
        MeasureUnitId: 0,
        Unit: document.getElementById("catname").value,
      }),
    }).then(
      (result) => {
        document.getElementById("catsave").disabled = false;
        if (result.data.IsSuccess === true) {
          document.getElementById("catname").value = "";
          toast.info(result.data.Message);
          modalclose();
          dispatch(getallmeasurment());
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        document.getElementById("catsave").disabled = false;
        toast.info("Something went wrong!");
      }
    );
  }
  function modalclose() {
    document.getElementById("catModal").style.display = "none";
  }
  let totalinstallment = 0;
  useEffect(() => {
    installments.map(
      (val, index) => (totalinstallment += parseFloat(val.InstallmentAmount))
    );
    setchecktotal(totalinstallment);
  }, [installments]);
  const deleteinstallment = (idx) => {
    const rows = [...installments];
    rows.splice(idx, 1);

    setinstallments(rows);
  };

  useEffect(() => {
    // if (NetAmount !== 0) {
    setinstallmentdifference(
      (
        parseFloat(
          document.getElementById("Landvalue").value.replace(/,/g, "")
        ) -
        parseFloat(
          document.getElementById("totaladvvalue").value.replace(/,/g, "")
        ) -
        parseFloat(checktotal)
      ).toLocaleString()
    );
    // document.getElementById("remainingamt").value = NetTotalAmount - totalinstallment;
    // }
  }, [checktotal]);
  const savedata = () => {
    if (document.getElementById("Date").value === "") {
      toast.info("Date is Required");
    } else if (document.getElementById("projectname").selectedIndex === 0) {
      toast.info("Select Project");
    } else if (document.getElementById("Creditor").selectedIndex === 0) {
      toast.info("Select Creditor");
    } else if (document.getElementById("Landvalue").value === "") {
      toast.info("Land Value field in Mandatory");
    } else if (document.getElementById("areavalue").value === "") {
      toast.info("Enter the Area");
    } else if (
      document.getElementById("GenerateInstallments").selectedIndex === 0
    ) {
      toast.info("Select Payment Plan");
    } else if (document.getElementById("LandName").value === "") {
      toast.info("Enter the land name");
    } else if (document.getElementById("NetMarla").value === "") {
      toast.info("Net Marla can't be empty");
    } else if (document.getElementById("areasize").selectedIndex === 0) {
      toast.info("Select area measurment unit");
    } else if (
      parseFloat(
        document.getElementById("totaladvvalue").value.replace(/,/g, "")
      ) >
      parseFloat(document.getElementById("Landvalue").value.replace(/,/g, ""))
    ) {
      toast.info("Advance should be less than Land value");
    }
    // else if (parseFloat(checktotal) + parseFloat(document.getElementById("remainingamt").value) > parseFloat(document.getElementById("Landvalue").value)) {
    //     toast.info(`Installments total amount should be less than the sum of  Land value and Advance which is ${parseFloat(document.getElementById("Landvalue").value) - parseFloat(document.getElementById("totaladvvalue").value)}PkR`)
    // }
    else if (parseFloat(document.getElementById("perofsold").value) > 100) {
      toast.info("Percentage should be less than 100");
    } else if (
      document.getElementById("GenerateInstallments").selectedIndex === 2 &&
      (document.getElementById("perofsold").value === "0" ||
        document.getElementById("perofsold").value === "" ||
        document.getElementById("perofsold").value === null)
    ) {
      toast.info("Please Enter Return  Percentage");
    } else if (
      document.getElementById("remainingamt").value.replace(/,/g, "") !== "0" &&
      document.getElementById("GenerateInstallments").selectedIndex == 1
    ) {
      // toast.info("Installment emaining amount should be zero");
      toast.info(
        `Installments total amount should be equal to ${
          parseFloat(
            document.getElementById("Landvalue").value.replace(/,/g, "")
          ) -
          parseFloat(
            document.getElementById("totaladvvalue").value.replace(/,/g, "")
          )
        }PkR`
      );
    } else {
      let LandPurchased = state;

      LandPurchased.LandCreditorId =
        document.getElementById("Creditor").options[
          document.getElementById("Creditor").selectedIndex
        ].dataset.id;
      LandPurchased.FullPaid = false;
      LandPurchased.LandValuePaid = 0;
      LandPurchased.EntryDate = document.getElementById("Date").value;
      LandPurchased.Advance = parseFloat(
        document.getElementById("totaladvvalue").value.replace(/,/g, "")
      );
      LandPurchased.AdvancePaid = 0;
      LandPurchased.LandValue = parseFloat(
        document.getElementById("Landvalue").value.replace(/,/g, "")
      );
      LandPurchased.ProjectId = parseInt(
        document.getElementById("projectname").options[
          document.getElementById("projectname").selectedIndex
        ].dataset.id
      );
      LandPurchased.MeasureUnitId = parseInt(
        document.getElementById("areasize").options[
          document.getElementById("areasize").selectedIndex
        ].dataset.id
      );
      LandPurchased.MeasureValue = document.getElementById("areavalue").value;
      LandPurchased.LandName = document.getElementById("LandName").value;
      LandPurchased.PaymentPlan = document.getElementById(
        "GenerateInstallments"
      ).options[
        document.getElementById("GenerateInstallments").selectedIndex
      ].value;
      LandPurchased.SalePaymentPer = document.getElementById("perofsold").value;
      LandPurchased.NetMarla = document.getElementById("NetMarla").value;
      LandPurchased.KhewatNumber =
        document.getElementById("KhewatNumber").value;
      LandPurchased.SqaNumber = document.getElementById("SqaNumber").value;

      document.getElementById("landsave").disabled = true;
      axios({
        method: "post",
        url: `${window.$url}Land/AddLand`,
        data: JSON.stringify({
          LandPurchased,
          installments,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("landsave").disabled = false;
          if (result.data.IsSuccess === true) {
            document.getElementById("areavalue").value = "";
            document.getElementById("Landvalue").value = "";
            document.getElementById("Advance").value = "";
            document.getElementById("LandName").value = "";

            document.getElementById("Creditor").selectedIndex = 0;
            // document.getElementById("GenerateInstallments").checked = false;
            document.getElementById("areasize").selectedIndex = 0;
            document.getElementById("projectname").selectedIndex = 0;
            document.getElementById("GenerateInstallments").selectedIndex = 0;
            document.getElementById("DueDate").value = "";
            document.getElementById("InstallmentAmount").value = "";
            document.getElementById("perofsold").value = "";
            setinstallments([]);
            setchecktotal(0);
            setstate({
              EntryDate: null,
              LandCreditorId: 0,
              ProjectId: 0,
              LandValue: null,
              AdvancePaid: 0,
              Advance: null,
              LandValuePaid: 0,
              FullPaid: false,
              MeasureUnitId: null,
              MeasureValue: null,
              LandName: null,
              PaymentPlan: null,
              SalePaymentPer: null,
            });

            toast.info(result.data.Message);
            props.cancellandtable();
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Someting went wrong");
          document.getElementById("landsave").disabled = false;
        }
      );
    }
  };
  function measureunitchange(e) {
    let unit = e.target.value;
    if (unit === "MARLA") {
      document.getElementById("NetMarla").value =
        document.getElementById("areavalue").value;
    } else if (unit === "KANAL") {
      document.getElementById("NetMarla").value =
        document.getElementById("areavalue").value * 20;
    } else if (unit === "ACRE") {
      document.getElementById("NetMarla").value =
        document.getElementById("areavalue").value * 160;
    } else if (unit === "KILLA") {
      document.getElementById("NetMarla").value =
        document.getElementById("areavalue").value * 160;
    } else if (unit === "MRABBA") {
      document.getElementById("NetMarla").value =
        document.getElementById("areavalue").value * 4000;
    }
  }
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getallProject());
    dispatch(getallmeasurment());
    dispatch(getallcreditors());
  }, [dispatch]);
  return (
    <>
      <div className="row ">
        <div className="col-md-12   text-left page_heading">
          <h4 className="m-0">Add Land</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 ">Date:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <input
            type="date"
            className="input_date_styling  ml-md-3"
            defaultValue={moment().format("YYYY-MM-DD")}
            id="Date"
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4  p-0">
          <label className="input_label m-0 ">Seller:</label>
        </div>
        <div className="col-md-3 mt-4">
          <select
            id="Creditor"
            className="input_styling  ml-md-3"
          >
            <option>Select</option>
            {resultallcreditors.data.map((x) => (
              <option data-id={x.LandCreditorId}>{x.LandCreditorName}</option>
            ))}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4  p-0">
          <label className="input_label m-0 ">Project:</label>
        </div>
        <div className="col-md-3 mt-4">
          <select
            id="projectname"
            className="input_styling  ml-md-3"
          >
            <option>Select</option>
            {resultsproject.data.map((x, index) =>
              x.ProjectOwner === "PERSONAL" ? (
                <option
                  data-id={x.ProjectId}
                  key={index}
                >
                  {x.ProjectName}
                </option>
              ) : null
            )}
          </select>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1 mt-4 text-left p-0">
          <label className="input_label m-0 ">Land Val:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="Landvalue"
            placeholder="Enter Amount"
            onChange={(e) => {
              if (e.target.value.length > 0) {
                document.getElementById("totaladvvalue").value = parseInt(
                  Math.round(
                    (parseFloat(e.target.value.replace(/,/g, "")) / 100) *
                      document.getElementById("Advance").value
                  )
                ).toLocaleString();
                document.getElementById("Landvalue").value = parseInt(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
              } else {
                document.getElementById("Landvalue").value = "";
              }
            }}
          ></input>
          <span className="estaric">*</span>
        </div>

        <div className="col-md-1 mt-4 text-left p-0">
          <label className="input_label m-0 ">Advance%:</label>
        </div>
        <div className="col-md-1 mt-4 ">
          <input
            type="number"
            className="input_styling ml-md-3 "
            id="Advance"
            onChange={(e) => {
              if (e.target.value.length > 0) {
                document.getElementById("totaladvvalue").value = parseInt(
                  Math.round(
                    (document
                      .getElementById("Landvalue")
                      .value.replace(/,/g, "") /
                      100) *
                      parseFloat(e.target.value)
                  )
                ).toLocaleString();
              } else {
                document.getElementById("Advance").value = "";
                document.getElementById("totaladvvalue").value = "";
              }
            }}
          ></input>
        </div>
        <div className="col-md-2 mt-4 ">
          <input
            type="text"
            className="input_styling  "
            id="totaladvvalue"
            placeholder="Total Advance "
            onChange={(e) => {
              if (e.target.value.length > 0) {
                document.getElementById("Advance").value = parseInt(
                  Math.round(
                    (parseFloat(e.target.value.replace(/,/g, "")) * 100) /
                      document
                        .getElementById("Landvalue")
                        .value.replace(/,/g, "")
                  )
                ).toLocaleString();
                document.getElementById("totaladvvalue").value = parseInt(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
              } else {
                document.getElementById("totaladvvalue").value = "";
              }
            }}
          ></input>
          <span className="estaric">*</span>
        </div>
        {/* <div className="col-md-3 mt-4 p-0">
                    <label className="input_label m-0 ">Generate Installments ? :</label>
                </div>
                <div className="col-md-1 mt-4 ">
                    <input type="checkbox" id="GenerateInstallments" onChange={() => { 
                        if (document.getElementById("GenerateInstallments").checked === true) { document.getElementById("hideshow").style.display = "block"; setinstallments([]); setchecktotal(0); }
                         else { document.getElementById("hideshow").style.display = "none"; setinstallments([]); setchecktotal(0); } }}></input>

                </div> */}
        <div className="col-md-1 mt-4 text-left p-0">
          <label className="input_label m-0 ">Payment Plan:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <select
            type="number"
            className="input_styling ml-md-3 "
            id="GenerateInstallments"
            onChange={(e) => {
              if (e.target.value === "INSTALLMENT") {
                document.getElementById("hideshow").style.display = "block";
                setinstallments([]);
                setchecktotal(0);
                document.getElementById("payonsale").style.display = "none";
                setinstallmentdifference("");
              } else if (e.target.value === "PAY ON SALE") {
                document.getElementById("payonsale").style.display = "block";
                setinstallments([]);
                setchecktotal(0);
                document.getElementById("hideshow").style.display = "none";
              } else {
                document.getElementById("hideshow").style.display = "none";
                setinstallments([]);
                setchecktotal(0);
                document.getElementById("payonsale").style.display = "none";
              }
            }}
          >
            <option>Select</option>
            <option>INSTALLMENT</option>
            <option>PAY ON SALE</option>
          </select>
          <span className="estaric">*</span>
        </div>
      </div>

      <div className="row">
        <div className="col-md-1 mt-4  text-left p-0">
          <label className="input_label m-0">Land Name:</label>
        </div>

        <div className="col-md-3 mt-4   ">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="LandName"
          />
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4  text-left p-0">
          <label className="input_label m-0">Area Val:</label>
        </div>

        <div className="col-md-3 mt-4   ">
          <input
            type="number"
            className="input_styling ml-md-3 "
            id="areavalue"
            placeholder="e.g 10"
          />
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4  text-left p-0">
          <label className="input_label m-0">Measure Unit:</label>
        </div>

        <div className="col-md-3 mt-4  ">
          <select
            className="input_styling ml-md-3"
            id="areasize"
            onChange={measureunitchange}
          >
            <option>Select</option>
            {/* <option>Add New</option> */}
            {resultsmeasurement.data.map((val, index) => (
              <option
                key={index}
                data-id={val.MeasureUnitId}
              >
                {val.Unit}
              </option>
            ))}
          </select>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1 mt-4  text-left p-0">
          <label className="input_label m-0">Net Marla:</label>
        </div>

        <div className="col-md-3 mt-4   ">
          <input
            type="number"
            className="input_styling ml-md-3 "
            id="NetMarla"
            disabled
          />
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4  text-left p-0">
          <label className="input_label m-0">Sq No:</label>
        </div>

        <div className="col-md-3 mt-4   ">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="SqaNumber"
          />
        </div>

        <div className="col-md-1 mt-4  text-left p-0">
          <label className="input_label m-0">Kh No:</label>
        </div>

        <div className="col-md-3 mt-4   ">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="KhewatNumber"
          />
        </div>
      </div>
      <span
        id="hideshow"
        style={{ display: "none" }}
      >
        <div className="row">
          <div className="col-md-1 mt-4 text-left p-0">
            <label className="input_label m-0 ">Installment Amount:</label>
          </div>
          <div className="col-md-3 mt-4 ">
            <input
              type="text"
              className="input_styling ml-md-3 "
              id="InstallmentAmount"
              onChange={(e) => {
                if (e.target.value.length > 0)
                  document.getElementById("InstallmentAmount").value = parseInt(
                    e.target.value.replace(/,/g, "")
                  ).toLocaleString();
              }}
            />
            <span className="estaric">*</span>
          </div>

          <div className="col-md-1 mt-4 text-left p-0">
            <label className="input_label m-0 ">Due Date:</label>
          </div>
          <div className="col-md-3 mt-4 ">
            <input
              type="date"
              className="input_styling ml-md-3 "
              id="DueDate"
            />
            <span className="estaric">*</span>
          </div>
          {/* <div className="col-md-1 text-right pt-2 pt-4"><button className="save_btn " onClick={() => { setinstallments([]); setchecktotal(0); }}>Remove</button></div> */}
          <div className="col-md-1 text-left pt-2 pt-4">
            <button
              className="save_btn "
              onClick={addtoarray}
            >
              Add
            </button>
          </div>
          <div className="col-md-3 mt-4 ">
            <input
              type="text"
              className="input_styling "
              placeholder="Remaining Amount"
              id="remainingamt"
              value={installmentdifference}
            />
          </div>
        </div>

        <div className="mt-4">
          <div className="table-responsive mt-4">
            <table className="table table-borderless m-0">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>InstallmentAmount</th>

                  <th>DueDate</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {installments.map((val, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{val.InstallmentAmount.toLocaleString()}</td>
                    {/* <td>{val.PaidAmount}</td> */}
                    <td>{moment(val.DueDate).format("DD-MMM-YYYY")}</td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteinstallment(index)}
                    >
                      <MdDeleteForever size="1.6em" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </span>
      <span
        id="payonsale"
        style={{ display: "none" }}
      >
        <div className="row">
          <div className="col-md-1 mt-4 text-left p-0">
            <label className="input_label m-0 ">Return %:</label>
          </div>
          <div className="col-md-3 mt-4 ">
            <input
              type="number"
              className="input_styling ml-md-3 "
              id="perofsold"
            />
          </div>
        </div>
      </span>
      <div className=" float-right mt-md-4 ">
        <span>
          <button
            className="cancel_btn ml-2  "
            onClick={props.cancellandtable}
          >
            Cancel
          </button>
        </span>

        <span>
          <button
            className="save_btn ml-2  "
            id="landsave"
            onClick={savedata}
          >
            <BsCloudUpload size="1.3em" />
            Save
          </button>
        </span>
      </div>
      {/* <!-- The Modal for cat --> */}
      <div
        id="catModal"
        className="modal"
      >
        <div className="modal-content-cat">
          <div className="modal_heading">
            <span className="head_text pl-3">Add Measurment Unit</span>
            <span
              className="close pr-3"
              onClick={modalclose}
            >
              &times;
            </span>
          </div>
          <div className="row pt-5 pl-3">
            <div className="col-md-4  text-left p-0">
              <label className="input_label m-0 ">Measure Unit:</label>
            </div>

            <div className="col-md-8  p-0">
              <input
                type="text"
                className="input_styling ml-md-3 "
                id="catname"
              ></input>
              <span className="estaric">*</span>
            </div>
          </div>

          <div className="row pr-md-2 pt-md-4">
            <div className=" col-11 pr-0 pl-0  text-right ">
              <span>
                <button
                  className="save_btn mr-md-3"
                  id="catsave"
                  onClick={addCategory}
                >
                  <BsCloudUpload size="1.3em" />
                  Save
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* modal close */}
    </>
  );
};

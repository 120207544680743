import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactDOM from "react-dom";
import { BsCloudUpload } from "react-icons/bs";

const AddNominee = (props) => {

  
    const [statenomnieeid, setnomnieeid] = useState(0);
    const [statecity, setcity] = useState("");
    const [statecountry, setcountry] = useState();
    const resultallclients = useSelector((state) => state.getAllCustomersRed);
  
   
    function emptyfields() {
      document.getElementById("City").selectedIndex = 0;
      document.getElementById("Country").selectedIndex = 0;
  
      document.getElementById("Address").value = "";
  
      document.getElementById("clientName").value = "";
      document.getElementById("CNIC").value = "";
      document.getElementById("Cell1").value = "";
      document.getElementById("Guardian").value = "";
    }
    function addNominee() {
      let regexp = new RegExp("^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$");
  
      let regexphone = new RegExp("^[0-9+]{4}-[0-9+]{7}$");
  
      let check = document.getElementById("CNIC").value;
      let checkcell1 = document.getElementById("Cell1").value;
  
      if (document.getElementById("clientName").value === "") {
        toast.info("Name is required");
      } else if (document.getElementById("CNIC").value === "") {
        toast.info("CNIC is required");
      } else if (!regexp.test(check)) {
        toast.info("Invalid CNIC. Format should be like xxxxx-xxxxxxx-x");
      } else if (document.getElementById("Address").value === "") {
        toast.info("Address is required");
      }
  
      // else if (document.getElementById("Occupation").value === "") { toast.info("Occupation  is required") }
      // else if (document.getElementById("Rating").value === "") { toast.info("Rating  is required") }
      else if (document.getElementById("Cell1").value === "") {
        toast.info("cell no.1  is required");
      } else if (!regexphone.test(checkcell1)) {
        toast.info("Invalid Cell No. 1 Format should be like xxxx-xxxxxxx");
      } else if (
        document.getElementById("City").options[
          document.getElementById("City").selectedIndex
        ].value === "Select" ||
        document.getElementById("City").options[
          document.getElementById("City").selectedIndex
        ].value === ""
      ) {
        toast.info("Select any city");
      } else if (
        document.getElementById("Country").options[
          document.getElementById("Country").selectedIndex
        ].value === "Select" ||
        document.getElementById("Country").options[
          document.getElementById("Country").selectedIndex
        ].value === ""
      ) {
        toast.info("Select  country");
      } else {
        document.getElementById("nomineesave").disabled = true;
  
        axios({
          url: `${window.$url}Nominees/Add`,
          method: "Post",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "content-type": "application/json",
          },
          data: JSON.stringify({
            NomineeName: document.getElementById("clientName").value,
            NomineeGuardianName: document.getElementById("Guardian").value,
            NomineeCnic: document.getElementById("CNIC").value,
            NomineeCity:
              document.getElementById("City").options[
                document.getElementById("City").selectedIndex
              ].value,
            NomineeCountry:
              document.getElementById("Country").options[
                document.getElementById("Country").selectedIndex
              ].value,
            NomineeAddress: document.getElementById("Address").value,
            NomineeCellNoOne: document.getElementById("Cell1").value,
          }),
        }).then(
          (result) => {
            document.getElementById("nomineesave").disabled = false;
            if (result.data.IsSuccess === true) {
              // emptyfields();
              setnomnieeid(result.data.NomineeId);
  
              toast.info(result.data.Message);
            } else {
              toast.info(result.data.Message);
              setnomnieeid(result.data.NomineeId);
            }
          },
          (error) => {
            toast.info("Something went wrong!");
            document.getElementById("nomineesave").disabled = false;
          }
        );
      }
    }
    const savecontract = () => {
      if (statenomnieeid !== 0) {
        props.statecontractdata.Contract.NomineeId = statenomnieeid;
        document.getElementById("contractsave").disabled = true;
        axios({
          method: "post",
          url: `${window.$url}Contracts/Add`,
          data: props.statecontractdata,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "content-type": "application/json",
          },
        }).then(
          (result) => {
            if (result.data.IsSuccess === true) {
              toast.info(result.data.Message);
              document.getElementById("contractsave").disabled = false;
              props.cancelcontracttable();
            } else {
              toast.info(result.data.Message);
              document.getElementById("contractsave").disabled = false;
            }
          },
          (error) => {
            toast.info(error);
            console.log(error);
            document.getElementById("contractsave").disabled = false;
          }
        );
      } else {
        toast.info("Add nominee before saving contract ");
      }
    };
    //    auto suggestion
    let Clientsuggestionlist = [];
    const bclientonTextChange = (e) => {
      if (document.getElementById("jClientnominee").value == "") {
        const value = e.target.value;
        if (value === "") {
          ReactDOM.render(null, document.getElementById("bclientdemonominee"));
          emptyfield();
        } else {
        let suggestions = [];
          if (value.length > 0) {
            const regex = new RegExp(`${value}`, "i");
            suggestions = props.stateCustomersNomineeRecord
              .sort()
              .filter((v) =>
                regex.test(v.Nominee !== undefined ? v.Nominee.NomineeCnic : null)
              );
          }
  
          Clientsuggestionlist = suggestions;
          // suggestionlist.push("Add New");
          if (Clientsuggestionlist.length === 0) {
            ReactDOM.render(null, document.getElementById("bclientdemonominee"));
          } else {
            ReactDOM.render(
              <ul className="suggestbox">
                {Clientsuggestionlist.map((item) => (
                  <li
                    className="listcss"
                    onClick={() => bClientsuggestionselected(item)}
                  >
                    {item === undefined ? item : item.Nominee.NomineeCnic}
                  </li>
                ))}
              </ul>,
              document.getElementById("bclientdemonominee")
            );
          }
        }
      }
    };
  
    const onTextChangeNomineeCnic = () => {
      var inputValue = document.getElementById("jBuyernominee").value;
      var options = document.getElementById("buyernominee").childNodes;
  
      let val;
      for (var i = 0; i < options.length; i++) {
        if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
          props.stateCustomersNomineeRecord.forEach((x, idx) => {
            if (idx == options[i].attributes.index.value) {
              val = x;
            }
          });
          document.getElementById("clientName").value = val.Nominee.NomineeName;
  
          document.getElementById("CNIC").value = val.Nominee.NomineeCnic;
          // document.getElementById("City").value = val.Nominee.NomineeCity;
          // document.getElementById("Country").value = val.Nominee.NomineeCountry;
  
          setcountry(val.Nominee.NomineeCountry);
          setcity(val.Nominee.NomineeCity);
          document.getElementById("Cell1").value = val.Nominee.NomineeCellNoOne;
  
          document.getElementById("Address").value = val.Nominee.NomineeAddress;
          document.getElementById("Guardian").value =
            val.Nominee.NomineeGuardianName;
  
          setnomnieeid(val.Nominee.NomineeId);
          break;
        }
      }
    };
  
    const bClientsuggestionselected = (val) => {
      document.getElementById("jBuyernominee").value = val.Nominee.NomineeCnic;
      document.getElementById("clientName").value = val.Nominee.NomineeName;
  
      document.getElementById("CNIC").value = val.Nominee.NomineeCnic;
      // document.getElementById("City").value = val.Nominee.NomineeCity;
      // document.getElementById("Country").value = val.Nominee.NomineeCountry;
  
      setcountry(val.Nominee.NomineeCountry);
      setcity(val.Nominee.NomineeCity);
      document.getElementById("Cell1").value = val.Nominee.NomineeCellNoOne;
  
      document.getElementById("Address").value = val.Nominee.NomineeAddress;
      document.getElementById("Guardian").value = val.Nominee.NomineeGuardianName;
  
      setnomnieeid(val.Nominee.NomineeId);
  
      Clientsuggestionlist = [];
      ReactDOM.render(null, document.getElementById("bclientdemonominee"));
    };
    let Customersuggestionlist = [];
    const customeronTextChange = (e) => {
      if (document.getElementById("jBuyernominee").value == "") {
        const value = e.target.value;
        if (value === "") {
          ReactDOM.render(null, document.getElementById("bcustomerdemonominee"));
          emptyfield();
        } else {
          let suggestions = [];
          if (value.length > 0) {
            const regex = new RegExp(`${value}`, "i");
            suggestions = resultallclients.data
              .sort()
              .filter((v) => regex.test(v.CustomerCnic));
          }
  
          Customersuggestionlist = suggestions;
          // suggestionlist.push("Add New");
          if (Customersuggestionlist.length === 0) {
            ReactDOM.render(
              null,
              document.getElementById("bcustomerdemonominee")
            );
          } else {
            ReactDOM.render(
              <ul className="suggestbox">
                {Customersuggestionlist.map((item) => (
                  <li
                    className="listcss"
                    onClick={() => Customersuggestionselected(item)}
                  >
                    {item === undefined ? item : item.CustomerCnic}
                  </li>
                ))}
              </ul>,
              document.getElementById("bcustomerdemonominee")
            );
          }
        }
      }
    };
  
    const onTextChangeCustomerCnic = () => {
      var inputValue = document.getElementById("jClientnominee").value;
      var options = document.getElementById("clientnominee").childNodes;
  
      let val;
      for (var i = 0; i < options.length; i++) {
        if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
          resultallclients.data.forEach((x, idx) => {
            if (idx == options[i].attributes.index.value) {
              val = x;
            }
          });
          document.getElementById("clientName").value = val.CustomerName;
  
          document.getElementById("CNIC").value = val.CustomerCnic;
          // document.getElementById("City").value = val.Nominee.NomineeCity;
          // document.getElementById("Country").value = val.Nominee.NomineeCountry;
          setcountry(val.Country);
          setcity(val.City);
          document.getElementById("Cell1").value = val.ContactNumber1;
  
          document.getElementById("Address").value = val.CustomerAddress;
          document.getElementById("Guardian").value = val.GuardianName;
          break;
        }
      }
    };
  
    const Customersuggestionselected = (val) => {
      document.getElementById("jClientnominee").value = val.CustomerCnic;
      document.getElementById("clientName").value = val.CustomerName;
  
      document.getElementById("CNIC").value = val.CustomerCnic;
      // document.getElementById("City").value = val.Nominee.NomineeCity;
      // document.getElementById("Country").value = val.Nominee.NomineeCountry;
      setcountry(val.Country);
      setcity(val.City);
      document.getElementById("Cell1").value = val.ContactNumber1;
  
      document.getElementById("Address").value = val.CustomerAddress;
      document.getElementById("Guardian").value = val.GuardianName;
  
      // setnomnieeid(val.Nominee.NomineeId);
  
      Customersuggestionlist = [];
      ReactDOM.render(null, document.getElementById("bcustomerdemonominee"));
    };
    function emptyfield() {
      document.getElementById("Guardian").value = "";
      document.getElementById("Address").value = "";
      document.getElementById("Cell1").value = "";
      document.getElementById("CNIC").value = "";
      document.getElementById("clientName").value = "";
      // document.getElementById("City").selectedIndex = 0;
      // document.getElementById("Country").selectedIndex = 0;
      setcountry("");
      setcity("");
      setnomnieeid("");
    }
    return (
      <>
        {/* <div className="row ">
                    <div className="col-md-12   text-left page_heading">
                        <h4 className="m-0">Add Nominee</h4>
                    </div>
                </div> */}
        <div className="row ">
          <div className="col-md-2 mt-md-4   p-0">
            <label className="input_label m-0">Customer CNIC :</label>
          </div>
          <div className="col-md-3 mt-md-4  p-0">
            <input
              className="input_styling ml-md-3"
              id="jClientnominee"
              autoComplete="off"
              placeholder="Search By CNIC "
              list="clientnominee"
              onChange={onTextChangeCustomerCnic}
            />
            <datalist id="clientnominee">
              {resultallclients?.data?.map((x, idx) => (
                <option index={idx} value={x.CustomerCnic}>
                  {x.CustomerName}
                </option>
              ))}
            </datalist>
            <span id="bcustomerdemonominee"></span>
          </div>
          <div className="col-md-2 mt-md-4   p-0"></div>
          <div className="col-md-2 mt-md-4 p-0">
            <label className="input_label m-0">Nominee CNIC :</label>
          </div>
          <div className="col-md-3 mt-md-4  p-0">
            <input
              type="text"
              autoComplete="off"
              className="input_styling ml-md-3"
              id="jBuyernominee"
              placeholder="Search By CNIC "
              onChange={onTextChangeNomineeCnic}
              list="buyernominee"
            />
            <datalist id="buyernominee">
              {props?.stateCustomersNomineeRecord?.map((x, idx) => (
                <option index={idx} value={x.Nominee?.NomineeCnic}>
                  {x.Nominee?.NomineeName}
                </option>
              ))}
            </datalist>
            <span id="bclientdemonominee"></span>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-2 mt-md-4  text-left p-0">
            <label className="input_label m-0 ">Name :</label>
          </div>
          <div className="col-md-3 mt-md-4  p-0">
            <input
              autoComplete="off"
              type="text"
              className="input_styling ml-md-3"
              id="clientName"
            />
            <span className="estaric">*</span>
          </div>
          <div className="col-md-2 mt-md-4   p-0"></div>
          <div className="col-md-2 mt-md-4  text-left p-0">
            <label className="input_label m-0 ">Guardian :</label>
          </div>
          <div className="col-md-3 mt-md-4  p-0">
            <input type="text" className="input_styling ml-md-3" id="Guardian" />
            <span className="estaric">*</span>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-2 mt-md-4  text-left p-0">
            <label className="input_label m-0">CNIC</label>
          </div>
          <div className="col-md-3 mt-md-4   p-0">
            <input
              type="text"
              className="input_styling ml-md-3 "
              id="CNIC"
              placeholder="xxxxx-xxxxxxx-x"
            />
            <span className="estaric">*</span>
          </div>
  
          <div className="col-md-2 mt-md-4   p-0"></div>
          <div className="col-md-2 mt-md-4  text-left p-0">
            <label className="input_label m-0 ">Cell No 1:</label>
          </div>
          <div className="col-md-3 mt-md-4  p-0">
            <input
              type="text"
              className="input_styling ml-md-3"
              id="Cell1"
              placeholder="xxxx-xxxxxxx"
            />
            <span className="estaric">*</span>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-2 mt-md-4  text-left p-0">
            <label className="input_label m-0 ">City</label>
          </div>
          <div className="col-md-3 mt-md-4  p-0">
            <select className="input_styling ml-md-3" id="City">
              <option>Select</option>
              {statecity !== "" ? <option selected> {statecity}</option> : null}
  
              <option value="Islamabad">Islamabad</option>
              <option value="" disabled>
                Punjab Cities
              </option>
              <option value="Ahmed Nager Chatha">Ahmed Nager Chatha</option>
              <option value="Ahmadpur East">Ahmadpur East</option>
              <option value="Ali Khan Abad">Ali Khan Abad</option>
              <option value="Alipur">Alipur</option>
              <option value="Arifwala">Arifwala</option>
              <option value="Attock">Attock</option>
              <option value="Bhera">Bhera</option>
              <option value="Bhalwal">Bhalwal</option>
              <option value="Bahawalnagar">Bahawalnagar</option>
              <option value="Bahawalpur">Bahawalpur</option>
              <option value="Bhakkar">Bhakkar</option>
              <option value="Burewala">Burewala</option>
              <option value="Chillianwala">Chillianwala</option>
              <option value="Chakwal">Chakwal</option>
              <option value="Chichawatni">Chichawatni</option>
              <option value="Chiniot">Chiniot</option>
              <option value="Chishtian">Chishtian</option>
              <option value="Daska">Daska</option>
              <option value="Darya Khan">Darya Khan</option>
              <option value="Dera Ghazi Khan">Dera Ghazi Khan</option>
              <option value="Dhaular">Dhaular</option>
              <option value="Dina">Dina</option>
              <option value="Dinga">Dinga</option>
              <option value="Dipalpur">Dipalpur</option>
              <option value="Faisalabad">Faisalabad</option>
              <option value="Ferozewala">Ferozewala</option>
              <option value="Fateh Jhang">Fateh Jang</option>
              <option value="Ghakhar Mandi">Ghakhar Mandi</option>
              <option value="Gojra">Gojra</option>
              <option value="Gujranwala">Gujranwala</option>
              <option value="Gujrat">Gujrat</option>
              <option value="Gujar Khan">Gujar Khan</option>
              <option value="Hafizabad">Hafizabad</option>
              <option value="Haroonabad">Haroonabad</option>
              <option value="Hasilpur">Hasilpur</option>
              <option value="Haveli Lakha">Haveli Lakha</option>
              <option value="Jatoi">Jatoi</option>
              <option value="Jalalpur">Jalalpur</option>
              <option value="Jattan">Jattan</option>
              <option value="Jampur">Jampur</option>
              <option value="Jaranwala">Jaranwala</option>
              <option value="Jhang">Jhang</option>
              <option value="Jhelum">Jhelum</option>
              <option value="Kalabagh">Kalabagh</option>
              <option value="Karor Lal Esan">Karor Lal Esan</option>
              <option value="Kasur">Kasur</option>
              <option value="Kamalia">Kamalia</option>
              <option value="Kamoke">Kamoke</option>
              <option value="Khanewal">Khanewal</option>
              <option value="Khanpur">Khanpur</option>
              <option value="Kharian">Kharian</option>
              <option value="Khushab">Khushab</option>
              <option value="Kot Addu">Kot Addu</option>
              <option value="Jauharabad">Jauharabad</option>
              <option value="Lahore">Lahore</option>
              <option value="Lalamusa">Lalamusa</option>
              <option value="Layyah">Layyah</option>
              <option value="Liaquat Pur">Liaquat Pur</option>
              <option value="Lodhran">Lodhran</option>
              <option value="Malakwal">Malakwal</option>
              <option value="Mamoori">Mamoori</option>
              <option value="Mailsi">Mailsi</option>
              <option value="Mandi Bahauddin">Mandi Bahauddin</option>
              <option value="Mian Channu">Mian Channu</option>
              <option value="Mianwali">Mianwali</option>
              <option value="Multan">Multan</option>
              <option value="Murree">Murree</option>
              <option value="Muridke">Muridke</option>
              <option value="Mianwali Bangla">Mianwali Bangla</option>
              <option value="Muzaffargarh">Muzaffargarh</option>
              <option value="Narowal">Narowal</option>
              <option value="Nankana Sahib">Nankana Sahib</option>
              <option value="Okara">Okara</option>
              <option value="Renala Khurd">Renala Khurd</option>
              <option value="Pakpattan">Pakpattan</option>
              <option value="Pattoki">Pattoki</option>
              <option value="Pir Mahal">Pir Mahal</option>
              <option value="Qaimpur">Qaimpur</option>
              <option value="Qila Didar Singh">Qila Didar Singh</option>
              <option value="Rabwah">Rabwah</option>
              <option value="Raiwind">Raiwind</option>
              <option value="Rajanpur">Rajanpur</option>
              <option value="Rahim Yar Khan">Rahim Yar Khan</option>
              <option value="Rawalpindi">Rawalpindi</option>
              <option value="Sadiqabad">Sadiqabad</option>
              <option value="Safdarabad">Safdarabad</option>
              <option value="Sahiwal">Sahiwal</option>
              <option value="Sangla Hill">Sangla Hill</option>
              <option value="Sarai Alamgir">Sarai Alamgir</option>
              <option value="Sargodha">Sargodha</option>
              <option value="Shakargarh">Shakargarh</option>
              <option value="Sheikhupura">Sheikhupura</option>
              <option value="Sialkot">Sialkot</option>
              <option value="Sohawa">Sohawa</option>
              <option value="Soianwala">Soianwala</option>
              <option value="Siranwali">Siranwali</option>
              <option value="Talagang">Talagang</option>
              <option value="Taxila">Taxila</option>
              <option value="Toba Tek Singh">Toba Tek Singh</option>
              <option value="Vehari">Vehari</option>
              <option value="Wah Cantonment">Wah Cantonment</option>
              <option value="Wazirabad">Wazirabad</option>
              <option value="" disabled>
                Sindh Cities
              </option>
              <option value="Badin">Badin</option>
              <option value="Bhirkan">Bhirkan</option>
              <option value="Rajo Khanani">Rajo Khanani</option>
              <option value="Chak">Chak</option>
              <option value="Dadu">Dadu</option>
              <option value="Digri">Digri</option>
              <option value="Diplo">Diplo</option>
              <option value="Dokri">Dokri</option>
              <option value="Ghotki">Ghotki</option>
              <option value="Haala">Haala</option>
              <option value="Hyderabad">Hyderabad</option>
              <option value="Islamkot">Islamkot</option>
              <option value="Jacobabad">Jacobabad</option>
              <option value="Jamshoro">Jamshoro</option>
              <option value="Jungshahi">Jungshahi</option>
              <option value="Kandhkot">Kandhkot</option>
              <option value="Kandiaro">Kandiaro</option>
              <option value="Karachi">Karachi</option>
              <option value="Kashmore">Kashmore</option>
              <option value="Keti Bandar">Keti Bandar</option>
              <option value="Khairpur">Khairpur</option>
              <option value="Kotri">Kotri</option>
              <option value="Larkana">Larkana</option>
              <option value="Matiari">Matiari</option>
              <option value="Mehar">Mehar</option>
              <option value="Mirpur Khas">Mirpur Khas</option>
              <option value="Mithani">Mithani</option>
              <option value="Mithi">Mithi</option>
              <option value="Mehrabpur">Mehrabpur</option>
              <option value="Moro">Moro</option>
              <option value="Nagarparkar">Nagarparkar</option>
              <option value="Naudero">Naudero</option>
              <option value="Naushahro Feroze">Naushahro Feroze</option>
              <option value="Naushara">Naushara</option>
              <option value="Nawabshah">Nawabshah</option>
              <option value="Nazimabad">Nazimabad</option>
              <option value="Qambar">Qambar</option>
              <option value="Qasimabad">Qasimabad</option>
              <option value="Ranipur">Ranipur</option>
              <option value="Ratodero">Ratodero</option>
              <option value="Rohri">Rohri</option>
              <option value="Sakrand">Sakrand</option>
              <option value="Sanghar">Sanghar</option>
              <option value="Shahbandar">Shahbandar</option>
              <option value="Shahdadkot">Shahdadkot</option>
              <option value="Shahdadpur">Shahdadpur</option>
              <option value="Shahpur Chakar">Shahpur Chakar</option>
              <option value="Shikarpaur">Shikarpaur</option>
              <option value="Sukkur">Sukkur</option>
              <option value="Tangwani">Tangwani</option>
              <option value="Tando Adam Khan">Tando Adam Khan</option>
              <option value="Tando Allahyar">Tando Allahyar</option>
              <option value="Tando Muhammad Khan">Tando Muhammad Khan</option>
              <option value="Thatta">Thatta</option>
              <option value="Umerkot">Umerkot</option>
              <option value="Warah">Warah</option>
              <option value="" disabled>
                Khyber Cities
              </option>
              <option value="Abbottabad">Abbottabad</option>
              <option value="Adezai">Adezai</option>
              <option value="Alpuri">Alpuri</option>
              <option value="Akora Khattak">Akora Khattak</option>
              <option value="Ayubia">Ayubia</option>
              <option value="Banda Daud Shah">Banda Daud Shah</option>
              <option value="Bannu">Bannu</option>
              <option value="Batkhela">Batkhela</option>
              <option value="Battagram">Battagram</option>
              <option value="Birote">Birote</option>
              <option value="Chakdara">Chakdara</option>
              <option value="Charsadda">Charsadda</option>
              <option value="Chitral">Chitral</option>
              <option value="Daggar">Daggar</option>
              <option value="Dargai">Dargai</option>
              <option value="Darya Khan">Darya Khan</option>
              <option value="Dera Ismail Khan">Dera Ismail Khan</option>
              <option value="Doaba">Doaba</option>
              <option value="Dir">Dir</option>
              <option value="Drosh">Drosh</option>
              <option value="Hangu">Hangu</option>
              <option value="Haripur">Haripur</option>
              <option value="Karak">Karak</option>
              <option value="Kohat">Kohat</option>
              <option value="Kulachi">Kulachi</option>
              <option value="Lakki Marwat">Lakki Marwat</option>
              <option value="Latamber">Latamber</option>
              <option value="Madyan">Madyan</option>
              <option value="Mansehra">Mansehra</option>
              <option value="Mardan">Mardan</option>
              <option value="Mastuj">Mastuj</option>
              <option value="Mingora">Mingora</option>
              <option value="Nowshera">Nowshera</option>
              <option value="Paharpur">Paharpur</option>
              <option value="Pabbi">Pabbi</option>
              <option value="Peshawar">Peshawar</option>
              <option value="Saidu Sharif">Saidu Sharif</option>
              <option value="Shorkot">Shorkot</option>
              <option value="Shewa Adda">Shewa Adda</option>
              <option value="Swabi">Swabi</option>
              <option value="Swat">Swat</option>
              <option value="Tangi">Tangi</option>
              <option value="Tank">Tank</option>
              <option value="Thall">Thall</option>
              <option value="Timergara">Timergara</option>
              <option value="Tordher">Tordher</option>
              <option value="" disabled>
                Balochistan Cities
              </option>
              <option value="Awaran">Awaran</option>
              <option value="Barkhan">Barkhan</option>
              <option value="Chagai">Chagai</option>
              <option value="Dera Bugti">Dera Bugti</option>
              <option value="Gwadar">Gwadar</option>
              <option value="Harnai">Harnai</option>
              <option value="Jafarabad">Jafarabad</option>
              <option value="Jhal Magsi">Jhal Magsi</option>
              <option value="Kacchi">Kacchi</option>
              <option value="Kalat">Kalat</option>
              <option value="Kech">Kech</option>
              <option value="Kharan">Kharan</option>
              <option value="Khuzdar">Khuzdar</option>
              <option value="Killa Abdullah">Killa Abdullah</option>
              <option value="Killa Saifullah">Killa Saifullah</option>
              <option value="Kohlu">Kohlu</option>
              <option value="Lasbela">Lasbela</option>
              <option value="Lehri">Lehri</option>
              <option value="Loralai">Loralai</option>
              <option value="Mastung">Mastung</option>
              <option value="Musakhel">Musakhel</option>
              <option value="Nasirabad">Nasirabad</option>
              <option value="Nushki">Nushki</option>
              <option value="Panjgur">Panjgur</option>
              <option value="Pishin Valley">Pishin Valley</option>
              <option value="Quetta">Quetta</option>
              <option value="Sherani">Sherani</option>
              <option value="Sibi">Sibi</option>
              <option value="Sohbatpur">Sohbatpur</option>
              <option value="Washuk">Washuk</option>
              <option value="Zhob">Zhob</option>
              <option value="Ziarat">Ziarat</option>
            </select>
            <span className="estaric">*</span>
          </div>
          <div className="col-md-2 mt-md-4   p-0"></div>
          <div className="col-md-2 mt-md-4  text-left p-0">
            <label className="input_label m-0">Country</label>
          </div>
          <div className="col-md-3 mt-md-4   p-0">
            <select id="Country" className="input_styling ml-md-3" name="country">
              <option>Select</option>
  
              {statecountry !== "" ? (
                <option selected> {statecountry}</option>
              ) : null}
  
              <option value="Pakistan">Pakistan</option>
            </select>
            <span className="estaric">*</span>
          </div>
        </div>
  
        <div className="row ">
          <div className="col-md-2 mt-md-4  text-left p-0">
            <label className="input_label m-0 ">Address:</label>
          </div>
          <div className="col-md-3 mt-4   p-0">
            <textarea className="input_address ml-md-3 " id="Address"></textarea>
            <span className="estaric">*</span>
          </div>
          <div className="col-md-2 mt-md-4   p-0"></div>
        </div>
  
        <div className="row pr-md-2 pt-md-4">
          <div className=" col-12 pr-0 pl-0  text-right ">
            <span>
              <button
                className="cancel_btn ml-2  "
                // onClick={() => { props.cancelcontracttable(); emptyfields(); }}
                onClick={() => {
                  document.getElementById("modalofaddnominee").style.display =
                    "none";
                }}
              >
                Back
              </button>
            </span>
  
            <span>
              <button
                className="save_btn  ml-2"
                id="nomineesave"
                onClick={addNominee}
              >
                <BsCloudUpload size="1.3em" />
                Add Nominee
              </button>
            </span>
            <span>
              <button
                className="save_btn  ml-2"
                id="contractsave"
                onClick={savecontract}
              >
                <BsCloudUpload size="1.3em" />
                Save Contract
              </button>
            </span>
          </div>
        </div>
      </>
    );
  };
  export default AddNominee
import axios from 'axios';
import { toast } from "react-toastify";
export const GetAllTableLead = (data) => {

    return ({
        type: "get_alltableleads",
        payload: data,
        loader: false
    })
}

export const GetAllPopulateLead = (data) => {

    return ({
        type: "get_allpopulateleads",
        payload: data,
        loader: false
    })
}
export const GetAllLeadTaskPopulate = (data) => {

    return ({
        type: "get_allleadtaskpopulate",
        payload: data,
        loader: false
    })
}
export const GetAllLeadTaskTable = (data) => {

    return ({
        type: "get_allleadtasktable",
        payload: data,
        loader: false
    })
}
export const GetAllLeadMeetingPopulate = (data) => {

    return ({
        type: "get_allleadmeetingpopulate",
        payload: data,
        loader: false
    })
}
export const GetAllLeadMeetingTable = (data) => {

    return ({
        type: "get_allleadmeetingtable",
        payload: data,
        loader: false
    })
}
export const GetAllLeadCallPopulate = (data) => {

    return ({
        type: "get_allleadcallpopulate",
        payload: data,
        loader: false
    })
}
export const GetAllLeadCallTable = (data) => {

    return ({
        type: "get_allleadcalltable",
        payload: data,
        loader: false
    })
}
export function getallTableLead() {
    return (dispatch) => {
        axios({
            url: `${window.$url}Leads/GetAll?LoginId=${localStorage.getItem("LoginId")}`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllTableLead(result.data.Data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}

export function getallPopulateLead() {
    return (dispatch) => {

        axios({
            url: `${window.$url}Leads/Populate?LoginId=${localStorage.getItem("LoginId")}`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllPopulateLead(result.data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}
export function getallLeadTaskPopulate() {
    return (dispatch) => {

        axios({
            url: `${window.$url}Leads/Tasks/Populate`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllLeadTaskPopulate(result.data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}
export function getallLeadTaskTable() {
    return (dispatch) => {

        axios({
            url: `${window.$url}Leads/Tasks/GetAll`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllLeadTaskTable(result.data.Data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}
export function getallLeadMeetingPopulate() {
    return (dispatch) => {

        axios({
            url: `${window.$url}Leads/Meeting/Populate`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllLeadMeetingPopulate(result.data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}
export function getallLeadMeetingTable() {
    return (dispatch) => {

        axios({
            url: `${window.$url}Leads/Meetings/GetAll`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllLeadMeetingTable(result.data.Data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}
export function getallLeadCallPopulate() {
    return (dispatch) => {

        axios({
            url: `${window.$url}Leads/Calls/Populate`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllLeadCallPopulate(result.data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                                toast.info("Something went wrong!");

            }
        );
    }
}
export function getallLeadCallTable() {
    return (dispatch) => {

        axios({
            url: `${window.$url}Leads/Calls/GetAll`,
            method: "Get",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type": "application/json",
            },
        }).then(
            (result) => {
                if (result.data.IsSuccess === true) {
                    dispatch(GetAllLeadCallTable(result.data.Data));


                }
                else {
                    toast.info(result.data.Message);
                }

            },
            (error) => {
                toast.info("Something went wrong!");

            }
        );
    }
}